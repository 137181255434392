import React, { ReactElement, useState, useEffect } from 'react';
import { Box, Flex } from 'rebass';
import { Button, Checkbox, DataTable, DataTableWrapper } from 'ui/components';
import { SlidePanel, Loader } from 'ui/patterns';
import {
  useGetBrokenLinksForDismissal,
  useDismissBrokenLinks,
} from '../../../hooks/index';
import { showErrorToast, showSuccessToast } from 'utils/showToast';
import { ProcedureBrokenLinksType } from '../../../types/procedureBuilder';
import NoDataImage from 'assets/images/NoDataImage';

type BrokenLinksProps = {
  showPanel: boolean;
  togglePanel: () => void;
  accountId: number;
  selectedProcedures: Array<number> | undefined;
  autoSaveContent: any;
};

function BrokenLinks({
  showPanel,
  togglePanel: toggle,
  accountId,
  selectedProcedures,
  autoSaveContent,
}: BrokenLinksProps): ReactElement {
  const [selectedLinks, setSelectedLinks] = useState<Array<number> | undefined>(
    [],
  );
  const [selectedLinksData, setSelectedLinksData] = useState<
    Array<ProcedureBrokenLinksType> | undefined
  >([]);

  const [linkState, setLinkState] = useState(0);
  const [payloadString, setPayloadString] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(showPanel);
  }, [showPanel]);

  function togglePanel() {
    setIsVisible(false);
    setTimeout(() => {
      toggle();
    }, 500);
  }

  useEffect(() => {
    setPayload();
  }, [selectedProcedures]);

  const setPayload = () => {
    let payload = '';
    selectedProcedures &&
      selectedProcedures.forEach(procedure => {
        payload = payload + ('procedureIds=' + procedure + '&');
      });
    setPayloadString(payload);
  };

  const { data: linkData, isLoading: isLinksLoading } =
    useGetBrokenLinksForDismissal(accountId, showPanel, payloadString);

  const [dismissBrokenLinks, { isLoading: isLinkDismissedLoading }] =
    useDismissBrokenLinks();

  useEffect(() => {
    checkLinkStatus();
  }, [selectedLinksData]);

  const brokenLinksActions = () => (
    <Button
      variant="secondary"
      onClick={() => {
        togglePanel();
        resetAll();
      }}
    >
      Close
    </Button>
  );
  const handleLinkDismissal = (data: ProcedureBrokenLinksType) => {
    const links = selectedLinks ? selectedLinks : [];
    const linksData = selectedLinksData ? selectedLinksData : [];
    const index = links && links.findIndex(item => item === data.LinkId);
    if (index === -1) {
      const selected = [...links, data.LinkId];
      const selectedData = [...linksData, data];
      setSelectedLinks(selected);
      setSelectedLinksData(selectedData);
    } else {
      const selected = selectedLinks?.filter(item => item !== data.LinkId);
      const selectedData = selectedLinksData?.filter(
        item => item.LinkId !== data.LinkId,
      );
      setSelectedLinks(selected);
      setSelectedLinksData(selectedData);
    }
  };

  const handleStatusChange = () => {
    dismissBrokenLinks(
      {
        accountId: accountId,
        model: {
          ProcedureLinkIds: selectedLinks,
          IgnoreIsBroken: linkState === 1 ? false : true,
        },
      },
      {
        onSuccess: (mutationResult: any) => {
          if (mutationResult && mutationResult instanceof Array)
            showErrorToast({
              message: mutationResult[0],
            });
          else {
            // autoSaveContent();
            showSuccessToast({
              message: 'Changes Updated Successfully.',
            });
            resetAll();
          }
        },
        onError: e => {
          showErrorToast({
            message: `${'An error occurred trying to save your changes, please try again.'} ${e}`,
          });
        },
      },
    );
  };

  const checkLinkStatus = () => {
    if (
      selectedLinksData?.length &&
      selectedLinksData?.every(
        (links: ProcedureBrokenLinksType) => links.Link.IgnoreIsBroken === true,
      )
    ) {
      setLinkState(1);
    } else if (
      selectedLinksData?.length &&
      selectedLinksData?.every(
        (links: ProcedureBrokenLinksType) =>
          links.Link.IgnoreIsBroken === false,
      )
    ) {
      setLinkState(2);
    } else {
      setLinkState(0);
    }
  };

  const resetAll = () => {
    setLinkState(0);
    setSelectedLinksData([]);
    setSelectedLinks([]);
  };

  const isChecked = (id: number) => {
    const response =
      selectedLinks &&
      selectedLinks.length > 0 &&
      selectedLinks.find(item => {
        if (item === id) {
          return item;
        }
      });

    if (response) {
      return true;
    } else {
      return false;
    }
  };

  const isAllChecked = () => {
    const links = linkData?.map(link => link.ProcedureLinks).flat();
    if (
      selectedLinks?.length ===
      (links && links.length > 0 ? links.length : null)
    )
      return true;
    else return false;
  };

  const handleSelectAll = () => {
    const links = linkData
      ? linkData.map(link => link?.ProcedureLinks).flat()
      : [];

    const ids = links ? links.map(x => x.LinkId) : [];
    const selected = selectedLinks?.length === links.length ? [] : ids;
    const selectedData =
      selectedLinksData?.length === links.length ? [] : links;
    setSelectedLinks(selected);
    setSelectedLinksData(selectedData);
  };

  const stripHtml = (html: string): string =>
    new DOMParser().parseFromString(html, 'text/html').body.textContent || '';
  return (
    <SlidePanel
      title="Broken Links"
      open={isVisible}
      onClose={() => {
        togglePanel();
        resetAll();
      }}
      size="lg"
      actions={brokenLinksActions()}
    >
      <Flex flexDirection="column" height="100%">
        <Flex justifyContent="flex-end" mb={3}>
          <Button
            variant="primary"
            onClick={() => handleStatusChange()}
            disabled={
              selectedLinksData?.length === 0 || linkState === 0 ? true : false
            }
          >
            {linkState === 2 || linkState === 0 ? 'Dismiss' : 'Activate'}
          </Button>
        </Flex>
        <Box flex="1 0 auto" height="1px" overflowY="auto">
          <DataTableWrapper style={{ height: '100%' }}>
            <DataTable className="choice-qn-dependency">
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>
                    {' '}
                    <Checkbox
                      label=""
                      name="selectAllLinks"
                      checked={isAllChecked()}
                      handleChange={() => handleSelectAll()}
                    />
                  </th>
                  <th>Procedure</th>
                  <th>Text to Display</th>
                  <th>URL</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {linkData &&
                  linkData.map((link: any) => {
                    return (
                      link &&
                      link.ProcedureLinks.map((data: any) => (
                        <tr
                          key={data.LinkId}
                          onClick={() => {
                            handleLinkDismissal(data);
                          }}
                        >
                          <td>
                            <div style={{ pointerEvents: 'none' }}>
                              <Checkbox
                                label=""
                                name={data.LinkId}
                                checked={isChecked(data.LinkId)}
                                handleChange={(e: any) => {
                                  e.stopPropagation();
                                  handleLinkDismissal(data);
                                }}
                              />
                            </div>
                          </td>
                          <td>{data.Procedure.Name}</td>
                          <td>{stripHtml(data.TextToDisplay)}</td>
                          <td>{data.Link.Url}</td>
                          <td>
                            {data.Link.IgnoreIsBroken ? 'Dismissed' : 'Active'}
                          </td>
                        </tr>
                      ))
                    );
                  })}
              </tbody>
            </DataTable>
            {linkData && linkData.length <= 0 && (
              <NoDataImage height="100%" feedback="No data available" />
            )}
          </DataTableWrapper>
        </Box>
      </Flex>
      {isLinksLoading && isLinkDismissedLoading && <Loader />}
    </SlidePanel>
  );
}

export default BrokenLinks;
