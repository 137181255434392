import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { layout } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { ProcedureDetailsType } from 'types/procedureBuilder';

export async function postSaveLayout({
  params,
  saveOption,
}: TArguments): Promise<TResult> {
  const response = await fetch(layout(), {
    method: saveOption ? 'POST' : 'PUT',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = ProcedureDetailsType & ValidationError;

type ParamsType = {
  title: string;
  content: string;
  container: string;
};

export type TError = { Message: string };
export type TArguments = {
  params: ParamsType;
  saveOption: boolean;
};

export type TVariables = {
  params: ParamsType;
  saveOption: boolean;
};
export type TSnapshot = unknown;

function useSaveLayout(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async ({ params, saveOption }: TVariables) => {
      const postResult = await postSaveLayout({
        params: {
          title: params.title,
          content: params.content,
          container: params.container,
        },
        saveOption,
      });
      if (postResult) {
        queryCache.invalidateQueries(['get_procedure'], {
          refetchInactive: true,
        });
        queryCache.invalidateQueries(['getExistingLayouts', true], {
          refetchInactive: true,
        });
      }
      if (postResult.Message) throw postResult;
      else return postResult;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveLayout };
