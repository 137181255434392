import React from 'react';
import { Column, TableInstance, ColumnInstance } from 'react-table';
import { Account } from 'types/accounts';
import Header from '../../../tableHeader';
import SearchSnippetColumn from '../../searchSnippet';

function contains(array: Array<string>, string: string): boolean {
  const index = array.findIndex(item => item === string);
  return index !== -1;
}

export default function useProcedureSearchColumns(
  columnsList?: Array<string>,
): Array<Column<Account>> {
  return React.useMemo((): Array<Column<Account>> => {
    if (columnsList) {
      const columns = [];
      if (contains(columnsList, 'CID')) columns.push(getCIDHeader(Header));
      if (contains(columnsList, 'SID')) columns.push(getSIDHeader(Header));
      if (contains(columnsList, 'PID')) columns.push(getPIDHeader(Header));
      if (contains(columnsList, 'Procedure'))
        columns.push(getProcedureHeader(Header));
      if (contains(columnsList, 'Account Name'))
        columns.push(getNameHeader(Header));
      if (contains(columnsList, 'Rel Manager'))
        columns.push(getRelManagerHeader(Header));
      if (contains(columnsList, 'Search Snippet'))
        columns.push(getSearchSnippetHeader(Header));
    }
    return [
      getCIDHeader(Header),
      getSIDHeader(Header),
      getPIDHeader(Header),
      getProcedureHeader(Header),
      getNameHeader(Header),
      getRelManagerHeader(Header),
      getSearchSnippetHeader(Header),
    ];
  }, [columnsList]);
}

function getCIDHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: 'CID',
    id: 'CID',
  };
}

function getSIDHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: 'SID',
    id: 'SID',
  };
}

function getPIDHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: 'PID',
    id: 'PID',
  };
}

function getProcedureHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Procedure',
    Header: Header,
    accessor: (row: Account) =>
      row.ProcedureName ? `${row.ProcedureName || ''}` : '',
  };
}

function getNameHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Account Name',
    Header: Header,
    accessor: (row: Account) =>
      row.AccountName ? `${row.AccountName || ''}` : '',
  };
}

function getRelManagerHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Rel Manager',
    Header: Header,
    accessor: (row: Account) =>
      row.RelationshipManagerName ? `${row.RelationshipManagerName || ''}` : '',
  };
}

function getSearchSnippetHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Search Snippet',
    Header: Header,
    Cell: SearchSnippetColumn,
  };
}
