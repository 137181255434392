import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { Contact } from 'types/contacts';
import { addContact, contactItem as updateContact } from 'utils/urls';
import { getTokenWithType } from './useAuth';

export async function postContact({
  params,
}: {
  params: Contact;
}): Promise<TResult> {
  const response = await fetch(
    params.Id === 0
      ? addContact(params.AccountId)
      : updateContact(params.AccountId, params.Id),
    {
      method: params.Id === 0 ? 'POST' : 'PUT',
      headers: {
        Authorization: getTokenWithType(),
        'content-type': 'application/json',
      },
      body: JSON.stringify(params),
    },
  );
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = Contact & ValidationError;
export type TVariables = Contact;
export type TError = {
  Message: string;
};
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;
// async function wait(millis: number) {
//   return new Promise(resolve => {
//     setTimeout(resolve, millis);
//   });
// }

function useSaveAccountsContact(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: Contact) => {
      const newContact = await postContact({
        params: params,
      });
      if (newContact.Message) throw newContact;
      queryCache.invalidateQueries(['contact_list']);

      queryCache.invalidateQueries(['get_contact_info']);
      // await wait(2000);
      return newContact;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveAccountsContact };
