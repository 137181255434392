import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { undoProcedures } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { ProcedureInfoType } from '../types/procedureBuilder';

export async function discardProcedure({
  params,
}: {
  params: TVariables;
}): Promise<TResult> {
  if (params.accountId && params.procedureId) {
    const response = await fetch(
      undoProcedures(params.accountId, params.procedureId),
      {
        method: 'PUT',
        headers: {
          Authorization: getTokenWithType(),
          'content-type': 'application/json',
        },
      },
    );
    return response.json();
  }
}
export type ValidationError = { Message?: string };

export type TResult = (ProcedureInfoType & ValidationError) | undefined;

export type TError = {
  Message: string;
};

export type TVariables = {
  accountId: number | undefined;
  procedureId: number | undefined;
};

export type TSnapshot = unknown;

function useDiscardProcedureContent(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: TVariables) => {
      const response = await discardProcedure({
        params: params,
      });
      if (response && response.Message) throw response;
      if (response) {
        queryCache.invalidateQueries(
          ['get_procedure_builder_procedures', params.accountId],
          {
            refetchInactive: true,
          },
        );
        queryCache.invalidateQueries(['get_procedure', params.accountId], {
          refetchInactive: true,
        });
      }
      return response;
    },
    {
      throwOnError: true,
    },
  );
}

export { useDiscardProcedureContent };
