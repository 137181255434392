import React, { ReactElement, useMemo, useState } from 'react';
import { ReactSelect } from 'ui/patterns/reactSelect';
import { Box } from 'rebass/styled-components';
import { SlidePanel, FormInput, Loader } from 'ui/patterns';
import { Button, Stack } from 'ui/components';
import { SegmentedButtons } from 'ui/patterns';
import { useGetExistingTables } from 'hooks';
import { confirmAlert } from 'utils/confirm-alert';
import { showErrorToast } from 'utils/showToast';
import { BlobFile } from 'types/procedureBuilder';

type SaveTableProps = {
  showPanel: boolean;
  togglePanel: () => void;
  onSavePress: (title: string, saveOption: boolean) => void;
};

function SaveTable({
  showPanel,
  togglePanel,
  onSavePress,
}: SaveTableProps): ReactElement {
  const [title, setTitle] = useState<string>();
  const [saveOption, setSaveOption] = useState(true);

  const { data: tableData, isLoading: tableDataLoading } =
    useGetExistingTables(showPanel);

  const exisitingTableOptions = useMemo(() => {
    return (
      tableData &&
      tableData[0] &&
      tableData[0].BlobFile.filter(
        (data: BlobFile) => data?.FileType === 'html',
      ).map((item, index) => {
        return {
          value: index,
          label: item.FileName,
        };
      })
    );
  }, [tableData]);

  const selectExistingTable = (value: number) => {
    const selected = exisitingTableOptions?.filter(item => {
      if (item.value === value) return item;
    });
    if (selected) {
      setTitle(selected[0].label);
    }
  };

  const existingTableCheck = (title: string | undefined) => {
    const check = title?.toLowerCase().includes('.html');
    let value: string | undefined;
    if (check) value = title?.split('.')[0]?.trim();
    else value = title?.trim();
    const checkedValue = exisitingTableOptions?.filter(item => {
      if (item.label.split('.')[0] === value) return item;
    });
    if (checkedValue?.length) return true;
    else return false;
  };

  const saveTableActions = () => (
    <>
      <Button variant="secondary" onClick={() => togglePanel()}>
        Cancel
      </Button>
      <Button
        onClick={() => {
          if (!title || !title.trim() || title.includes(',')) {
            showErrorToast({
              message: 'Please enter a valid title',
            });
            return;
          } else if (existingTableCheck(title)) {
            if (saveOption) {
              confirmAlert({
                message:
                  'Table with the same name exists. Do you want to overwrite the current table?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
                      const check = title?.toLowerCase().includes('.html');
                      let value: string | undefined;
                      if (!check) value = `${title?.trim()}.html`;
                      else value = `${title?.split('.')[0]?.trim()}.html`;
                      onSavePress(value || '', false);
                    },
                    className: 'pr-btn-primary',
                  },
                  {
                    label: 'No',
                    onClick: () => {
                      //
                    },
                  },
                ],
              });
            } else {
              const check = title?.toLowerCase().includes('.html');
              let value: string | undefined;
              if (!check) value = `${title?.trim()}.html`;
              else value = `${title?.split('.')[0]?.trim()}.html`;
              onSavePress(value || '', false);
            }
          } else {
            const check = title?.toLowerCase().includes('.html');
            let value: string = title?.trim() || '';
            if (!check) value = `${value}.html`;
            onSavePress(value || '', saveOption);
          }
        }}
        variant="primary"
      >
        Save Table
      </Button>
    </>
  );

  return (
    <SlidePanel
      open={showPanel}
      onClose={() => {
        togglePanel();
      }}
      size="sm"
      title="Save Table"
      actions={saveTableActions()}
    >
      <Stack direction="y" gap={5} padding={3}>
        <SegmentedButtons width="100%" equalWidthChildren>
          <Button
            variant={saveOption ? 'primary' : 'outline'}
            onClick={() => setSaveOption(true)}
          >
            Save
          </Button>
          <Button
            variant={!saveOption ? 'primary' : 'outline'}
            onClick={() => setSaveOption(false)}
          >
            Save as
          </Button>
        </SegmentedButtons>
        {saveOption ? (
          <FormInput onTextChange={setTitle} label="Enter Table name" />
        ) : null}
        {!saveOption ? (
          <Box>
            <ReactSelect
              name="tableOptions"
              label="Select Table"
              isSearchable={true}
              isClearable={true}
              options={exisitingTableOptions ? exisitingTableOptions : []}
              onChange={(e: any) => {
                if (e) selectExistingTable(e.value);
              }}
              classNamePrefix="pr-select"
            />
          </Box>
        ) : null}
      </Stack>
      {tableDataLoading && !saveOption && <Loader />}
    </SlidePanel>
  );
}

export default SaveTable;
