import React, { ReactElement } from 'react';
import StyledPrimaryNav from './styledPrimaryNav';
import { useRouteMatch } from 'react-router-dom';

type PrimaryNavProps = {
  links: Array<link>;
};

type link = { label: string; route: string };

function NavItem({ link }: { link: link }) {
  const match = useRouteMatch({
    path: link.route,
    exact: true,
  });
  return (
    <li>
      <a
        href={link.route}
        className={match ? 'active' : ''}
        onClick={() => sessionStorage.setItem('tabId', '1')}
      >
        {link.label}
      </a>
    </li>
  );
}

function PrimaryNav({ links }: PrimaryNavProps): ReactElement {
  return (
    <StyledPrimaryNav>
      {links.map(link => (
        <NavItem key={link.label} link={link} />
      ))}
    </StyledPrimaryNav>
  );
}

export { PrimaryNav };
