import React, { ReactElement } from 'react';
import { Flex, Text, Box } from 'rebass/styled-components';
import { Stack, Grid, Checkbox } from 'ui/components';
import { Loader } from 'ui/patterns';
import { SectionTypeInput } from 'types/forms';
import SectionItem from '../SectionList/SectionItem';
import NoDataImage from 'assets/images/NoDataImage';
import { Forms } from 'types/forms';

export default function SectionsPanel({
  isFormDetailsLoading,
  formDetails,
  onSelectSectionItem,
  selectedSectionItems,
  openCloseSection,
  expandAll,
  isCopyQuestion,
  onMultiSelect,
}: {
  isFormDetailsLoading: boolean;
  formDetails?: Forms;
  onSelectSectionItem: (sectionItem: SectionTypeInput) => void;
  selectedSectionItems?: Array<SectionTypeInput>;
  openCloseSection: (id: number) => void;
  expandAll: Record<number, boolean>;
  isCopyQuestion?: boolean;
  onMultiSelect?: (sectionItem: Array<SectionTypeInput>) => void;
}): ReactElement {
  let sections = formDetails?.Sections;
  // if (isCopyQuestion === true) {
  sections =
    sections &&
    sections.filter(
      item =>
        item.$type ===
        'Proteus2.Api.Domain.Surveys.Section, Proteus2.Api.Domain',
    );
  // }
  return (
    <Stack direction="y" gap={3} style={{ flex: 1, overflowY: 'auto' }}>
      {isFormDetailsLoading ? (
        <Grid minColWidth="220px" gap={4}>
          <Loader />
        </Grid>
      ) : (
        <>
          {!sections ||
            (sections.length === 0 && (
              <Flex height="500px" flex="1" justifyContent="center">
                <NoDataImage feedback="No data available" />
              </Flex>
            ))}
          {sections && (
            <>
              <Text mb={2} fontSize={1} color="text.body">
                {sections && sections?.length > 0
                  ? `Total ${sections?.length} Sections`
                  : ''}
              </Text>
              {sections.map((sectionItem: SectionTypeInput, index) => (
                <Stack direction="x" gap={3} key={sectionItem.Id}>
                  {!isCopyQuestion && (
                    <Checkbox
                      checked={
                        selectedSectionItems &&
                        selectedSectionItems.find(
                          (item: SectionTypeInput) =>
                            sectionItem.Id === item?.Id,
                        )
                      }
                      handleChange={() => {
                        const item =
                          selectedSectionItems &&
                          selectedSectionItems.find(
                            (item: SectionTypeInput) =>
                              sectionItem.Id === item?.Id,
                          );
                        if (!!item && selectedSectionItems) {
                          const newSelectedSections =
                            selectedSectionItems.filter(
                              item => sectionItem.Id !== item?.Id,
                            );
                          onMultiSelect &&
                            onMultiSelect([...newSelectedSections]);
                        } else {
                          const newSelectedSections =
                            selectedSectionItems || [];
                          onMultiSelect &&
                            onMultiSelect([
                              ...newSelectedSections,
                              { ...sectionItem },
                            ]);
                        }
                      }}
                      name={sectionItem.Id}
                    />
                  )}
                  <Box flex={1}>
                    <SectionItem
                      key={sectionItem.Id}
                      sectionItem={sectionItem}
                      index={index}
                      onSelectSectionItem={() =>
                        onSelectSectionItem(sectionItem)
                      }
                      isSelected={
                        selectedSectionItems &&
                        selectedSectionItems.find(
                          (item: SectionTypeInput) =>
                            sectionItem.Id === item?.Id,
                        )
                      }
                      hideExpander={isCopyQuestion}
                      openCloseSection={
                        !isCopyQuestion ? openCloseSection : undefined
                      }
                      expandedState={!isCopyQuestion ? expandAll : undefined}
                    />
                  </Box>
                </Stack>
              ))}
            </>
          )}
        </>
      )}
    </Stack>
  );
}
