import styled, { css } from 'styled-components';
import {
  TextStyleProps,
  textStyle,
  FontWeightProps,
  fontWeight,
  LayoutProps,
  layout,
  SpaceProps,
  space,
  FlexboxProps,
  flexbox,
  PositionProps,
  position,
  ColorProps,
  color,
} from 'styled-system';
import { ButtonReset, Truncate } from 'ui/utils';

type ssProps = LayoutProps &
  SpaceProps &
  FlexboxProps &
  PositionProps &
  ColorProps;

const TreeWrapper = styled.div`
  --bg: var(--color-neutral-contrast-00);
  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[3]};
  background: var(--bg);
  border-radius: ${({ theme }) => theme.radii.soft};
  border: solid 1px ${({ theme }) => theme.colors.border.default};
  width: 350px;
  overflow-y: auto;

  html[data-color-mode='dark'] & {
    background: ${({ theme }) => theme.baseColors.gray[9]};
  }

  html[data-color-mode='dark'] & {
    --bg: var(--color-neutral-contrast-01);
  }
`;

type TreeListProps = {
  nested?: boolean;
};

const TreeList = styled.ul<TreeListProps>`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    bottom: 0;
    width: 1px;
    height: calc(100% - 10px);
    border-left: dashed 1px var(--color-neutral-contrast-03);
  }
`;
const TreeListItem = styled.li``;

const StyledTree = styled.ul<ssProps>`
  list-style: none;

  ${color}
  ${layout}
  ${space}
	${flexbox}
	${position}

	 & ${TreeList} ${TreeListItem} {
    list-style: none;
    padding-left: 1.5rem;
  }

  .chevron {
    ${ButtonReset};
    padding: 0;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-neutral-contrast-06);

    &:focus {
      outline: solid 1px ${({ theme }) => theme.baseColors.gray[4]};
      outline-offset: -2px;
    }

    & > .pr-icon {
      transition: transform 100ms ease-out;
      transform: rotate(0deg);
    }

    &.rotate > .pr-icon {
      transform: rotate(90deg);
    }
  }

  .icon-folder,
  .icon-file {
    color: ${({ theme }) => theme.baseColors.blue[3]};
    margin-right: ${({ theme }) => theme.space[2]};
    margin-left: ${({ theme }) => theme.space[1]};
  }

  .icon-file {
    color: var(--color-neutral-contrast-05);
  }

  ${TreeList},
  ${TreeListItem} {
    list-style: none;
  }
`;

type FileNameProps = TextStyleProps &
  FontWeightProps & {
    isFolder?: boolean;
  };

const FileName = styled.span<FileNameProps>`
  flex: 1;
  color: var(--color-neutral-contrast-07);

  ${Truncate}
  ${textStyle}
  ${fontWeight}

  ${({ isFolder }) =>
    isFolder &&
    css`
      color: ${({ theme }) => theme.colors.text.body};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
    `}
`;

const FileActions = styled.div`
  display: none;

  button {
    ${ButtonReset}
    padding: 0;
    margin-left: ${({ theme }) => theme.space[2]};
    margin-right: ${({ theme }) => theme.space[1]};
    line-height: 1;
    color: var(--color-neutral-contrast-07);

    &:active {
      transform: translateY(1px);
    }

    &:focus {
      outline: solid 1px ${({ theme }) => theme.baseColors.gray[4]};
      outline-offset: -2px;
    }
  }
`;

type TreeItemProps = {
  haveFiles?: boolean;
  active?: boolean;
  selectMode?: boolean;
};

const TreeItem = styled.div<TreeItemProps>`
  display: flex;
  align-items: center;
  min-width: 0;
  min-height: 40px;
  border-radius: ${({ theme }) => theme.radii.soft};
  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
  transition: background 200ms ease-out;
  cursor: pointer;

  &:hover {
    background: var(--color-neutral-contrast-03);

    ${FileActions} {
      display: flex;
    }
  }

  ${({ haveFiles }) =>
    !haveFiles &&
    css`
      &:hover {
        cursor: initial;
        background: none;
      }

      .icon-folder,
      ${FileName} {
        opacity: 0.4;
      }
    `}

  ${({ active }) =>
    active &&
    css`
      background: var(--color-neutral-contrast-03);

      ${FileActions} {
        display: flex;
      }
    `}

  ${({ selectMode }) =>
    selectMode &&
    css`
      &&& ${FileActions}, &&& .dd-listitem-options {
        display: none;
      }
    `}
`;

export {
  TreeWrapper,
  StyledTree,
  TreeItem,
  FileActions,
  TreeList,
  TreeListItem,
  FileName,
};
