import { useQuery, QueryResult } from 'react-query';
import { ftpSettings } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { FTPSettings } from 'types/ftpSettings';

async function fetchFtpSettings({ accountId }: TVariables): Promise<TResult> {
  const response = await fetch(ftpSettings(accountId), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = FTPSettings | undefined;

export type TError = { message: string };
export type TVariables = {
  accountId: number;
};

function useGetFtpSettings(accountId: number): QueryResult<TResult, TError> {
  return useQuery(
    ['get_ftp_settings', accountId],
    async () => {
      if (accountId) {
        return await fetchFtpSettings({
          accountId,
        });
      }
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetFtpSettings };
