import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { OfficeFactsInput } from 'types/accounts';
import { officeFacts } from 'utils/urls';
import { getTokenWithType } from './useAuth';

export async function postOfficeFacts({
  params,
}: {
  params: OfficeFactsInput;
}): Promise<TResult> {
  const response = await fetch(officeFacts(params.AccountId), {
    method: 'PUT',
    headers: {
      Authorization: getTokenWithType(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = OfficeFactsInput & ValidationError;
export type TVariables = OfficeFactsInput;
export type TError = {
  message: string;
};
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;
async function wait(millis: number) {
  return new Promise(resolve => {
    setTimeout(resolve, millis);
  });
}

function useSaveOfficeFacts(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: OfficeFactsInput) => {
      const newOfficeFacts = await postOfficeFacts({
        params: params,
      });
      if (newOfficeFacts.Message) throw newOfficeFacts;
      queryCache.invalidateQueries(['get_officeFacts', params.AccountId], {
        refetchActive: true,
        refetchInactive: true,
      });
      await wait(2000);
      return newOfficeFacts;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveOfficeFacts };
