import styled, { css } from 'styled-components';
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  ColorProps,
  color,
} from 'styled-system';

type SeparatorProps = LayoutProps &
  SpaceProps &
  ColorProps & {
    direction: 'x' | 'y';
  };

const Separator = styled.div<SeparatorProps>`
  background-color: ${({ theme }) => theme.colors.border.default};

  ${({ direction }) =>
    direction === 'x' &&
    css`
      width: 100%;
      height: 1px;
    `}

  ${({ direction }) =>
    direction === 'y' &&
    css`
      height: auto;
      min-height: 2.5em;
      width: 1px;
    `}
  ${layout}
  ${space}
  ${color}
`;

export { Separator };
