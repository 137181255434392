import React, {
  ReactElement,
  // useContext,
  SyntheticEvent,
  ReactNode,
} from 'react';
import { Flex } from 'rebass/styled-components';
import { SpaceProps, FlexProps, space, flex } from 'styled-system';
import { PageTitle, PageSearch } from '../../components';
import { PageActions } from '../index';
import styled, { css } from 'styled-components';
import * as Icon from '../../../assets/icons';

type StyledPageHeaderProps = SpaceProps & FlexProps;

// const StyledPageHeader = (props: FlexProps) => {
//   // const theme = useContext(ThemeContext);
//   return <Flex className="page-header" px={props.px} />;
// };

const StyledPageHeader = styled.div<StyledPageHeaderProps>`
  ${space}
  ${flex}

  --bg: var(--color-neutral-contrast-07);

  display: flex;
  border-top: solid 1px transparent;
  background: var(--bg);
  min-height: 40px;

  html[data-color-mode='dark'] & {
    --bg: var(--color-neutral-contrast-01);
    border-top: solid 1px var(--color-neutral-contrast-02);
  }

  & .pr-page-utils {
    > *,
    > .pr-page-actions > * + * {
      --border-color: var(--color-neutral-contrast-06);
      border-left: solid 1px var(--border-color);

      html[data-color-mode='dark'] & {
        --border-color: var(--color-neutral-contrast-02);
      }
    }
  }

  & .addons {
    display: flex;
    .button-group {
      align-self: center;
      margin: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};

      button {
        padding: ${({ theme }) => theme.space[1]}
          ${({ theme }) => theme.space[2]};

        &:focus {
          box-shadow: none;
          border-color: transparent;
        }

        & > .pr-button-label {
          line-height: 1.3;
        }
      }
    }
  }

  & .pr-page-search {
    position: relative;

    & .pr-icon {
      position: absolute;
      top: 50%;
      left: ${({ theme }) => theme.space[3]};
      transform: translateY(-45%);
      z-index: 100;
      pointer-events: none;

      svg {
        width: 1.1em;
        height: 1.1em;

        path {
          fill: #fff !important;
        }
      }
    }
  }
`;

const BackNavButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: none;
  background: hsla(0, 0%, 100%, 10%);
  border-radius: none;
  color: #fff;
  margin-right: ${({ theme }) => theme.space[3]};
  margin-left: calc(${({ theme }) => theme.space[3]} * -1);
  cursor: pointer;

  &:hover {
    background: hsla(0, 0%, 100%, 10%);
  }

  &:focus {
    outline: none;
    background: hsla(0, 0%, 100%, 50%);
  }
`;

type SubtitleProps = {
  onClick?: () => void;
};

const Subtitle = styled.span<SubtitleProps>`
  display: inline-block;
  padding: 6px 8px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.15);
  font-size: 11px;
  margin-left: ${({ theme }) => theme.space[2]};
  margin-right: ${({ theme }) => theme.space[2]};
  font-weight: 500;
  align-self: center;
  color: white;
  line-height: 1;
  transform: none;
  transition: background 150ms;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 440px;

  /* Accessibe fixes */
  body.acsb-vision-profile & {
    max-width: 15%;
  }

  &:active {
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
  }

  ${({ onClick }) =>
    onClick &&
    css`
      &:hover {
        cursor: pointer;
        background: rgba(255, 255, 255, 0.4);
      }

      &:active {
        transform: translate3d(0, 1px, 0);
        background: rgba(255, 255, 255, 0.3);
      }
    `}
`;

type PageHeaderProps = {
  title: string;
  subTitle?: string | null;
  actions?: Array<action>;
  hasSearch?: boolean;
  searchValue?: string;
  handleChange?: (value: string) => void;
  addons?: ReactElement;
  hasBackNav?: boolean;
  backNavAction?: () => void;
  backNavTooltip?: string;
  SubtitleAction?: () => void;
  SubtitleTootip?: string;
  className?: string;
};

type action = {
  action: string;
  icon: ReactNode;
  handleButtonClick?: () => void;
};

function PageHeader({
  title,
  subTitle,
  actions,
  hasSearch,
  searchValue,
  handleChange,
  addons,
  hasBackNav,
  backNavAction,
  backNavTooltip = 'Back',
  SubtitleAction,
  SubtitleTootip,
  className,
}: PageHeaderProps): ReactElement {
  return (
    <StyledPageHeader px={3} className={className}>
      {hasBackNav ? (
        <BackNavButton onClick={backNavAction} title={backNavTooltip}>
          <Icon.ChevronLeft title={backNavTooltip} />
        </BackNavButton>
      ) : null}
      <PageTitle title={title} />
      {subTitle ? (
        <Subtitle title={SubtitleTootip} onClick={SubtitleAction}>
          {subTitle}
        </Subtitle>
      ) : null}
      <Flex ml="auto" className="pr-page-utils">
        {addons ? addons : null}
        {actions ? <PageActions actions={actions} /> : null}
        {hasSearch ? (
          <Flex className="pr-page-search">
            <Icon.Search title="Search" />
            <PageSearch
              type="search"
              placeholder="Search"
              value={searchValue}
              onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                handleChange && handleChange(e.currentTarget.value)
              }
            ></PageSearch>
          </Flex>
        ) : null}
      </Flex>
    </StyledPageHeader>
  );
}

export { PageHeader };
