import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { AccordionHeader, AccordionBody } from 'ui/patterns';

export const Editor = styled.span`
  --edited-by-width: 350px;
  display: block;
  color: var(--color-neutral-contrast-05);
  font-size: 0.86rem;

  &.pr-current-version {
    width: var(--edited-by-width);
  }

  span {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: var(--edited-by-width);
    display: inline-block;
    vertical-align: bottom;
  }

  &.pr-current-version span {
    display: block;
    margin-top: 0.25rem;
  }
`;

export const Timestamp = styled.span`
  display: block;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.text.body};
`;

export const CurrentVersion = styled.div`
  /* align-self: flex-start; */
  margin-left: auto;
  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
  font-size: 0.75rem;
  /* text-transform: uppercase; */
  text-align: center;
  line-height: 1;
  color: ${({ theme }) => theme.baseColors.blue[7]};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  background-color: ${({ theme }) =>
    transparentize(0.2, theme.baseColors.blue[1])};
  border: solid 1px ${({ theme }) => theme.colors.border.info};
  border-radius: 100px;
  white-space: nowrap;

  &.pr-current-version {
    margin-left: -150px;
  }
`;

export const Separator = styled.span`
  display: inline-block;
  margin-left: ${({ theme }) => theme.space[1]};
  margin-right: ${({ theme }) => theme.space[1]};
  width: 3px;
  height: 3px;
  background-color: var(--color-neutral-contrast-05);
  border-radius: 50%;
`;

type VersionProps = {
  active?: boolean;
};

export const Version = styled.a<VersionProps>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[3]};
  border-bottom: solid 1px ${({ theme }) => theme.colors.border.default};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text.body};
  transform: none;

  &:hover {
    background: var(--color-neutral-contrast-02);
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
  }

  .ab-version-accordion & {
    border-bottom: none;
  }

  .ab-version-accordion ${AccordionHeader} & {
    padding-inline-end: ${({ theme }) => theme.space[9]};
  }

  /* html[data-color-mode='dark'] .ab-version-accordion ${AccordionHeader} & {
    background: var(--color-neutral-contrast-01);
  }

  html[data-color-mode='dark']
    .ab-version-accordion
    ${AccordionHeader}
    &:hover {
    background: var(--color-neutral-contrast-02);
  } */

  .ab-version-accordion ${AccordionBody} &:not(:first-child) {
    border-top: solid 1px ${({ theme }) => theme.colors.border.default};
  }

  ${({ active }) =>
    active &&
    css`
      background: var(--color-neutral-contrast-03);
    `}
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: solid 1px ${({ theme }) => theme.colors.border.default};
  border-radius: 6px;
  overflow: hidden;
`;

export default {
  Version,
  CurrentVersion,
  Editor,
  Timestamp,
  Separator,
  Iframe,
};
