import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { editQuestionInRepository as deleteQuestion } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function deleteQuestions({ questionId }: TArguments): Promise<TResult> {
  const response = await fetch(deleteQuestion(questionId), {
    method: 'DELETE',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/json',
    },
  });

  return new Promise((resolve, reject) => {
    if (response.status === 204) resolve(true);
    else reject(false);
  });
}

export type TResult = boolean;
export type TError = { message: string };
export type TArguments = {
  questionId: number;
};
export type TVariables = {
  questionId: number;
};

function useDeleteQuestions(): MutationResultPair<
  TResult,
  TError,
  number,
  unknown
> {
  return useMutation(async (questionId: number) => {
    const deleteQuestionResult = await deleteQuestions({
      questionId,
    });
    queryCache.invalidateQueries(['questions_list'], {
      refetchInactive: true,
    });
    return deleteQuestionResult;
  });
}

export { useDeleteQuestions };
