import { useQuery, QueryResult } from 'react-query';
import { versionHistory } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { ProcedureInfoType } from 'types/procedureBuilder';

async function fetchVersionHistory({
  account,
  procedureId,
}: TVariables): Promise<TResult> {
  const response = await fetch(versionHistory(account, procedureId), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = ProcedureInfoType | undefined;

export type TError = { message: string };
export type TVariables = {
  account: number;
  procedureId: number | undefined;
};

function useVersionHistory(
  account: number,
  procedureId: number | undefined,
): QueryResult<TResult, TError> {
  return useQuery(
    ['get_procedure_version_history', account, procedureId],
    async () => {
      if (procedureId)
        return await fetchVersionHistory({
          procedureId,
          account,
        });
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: true,
    },
  );
}

export { useVersionHistory };
