import { useMemo, useState } from 'react';
import { Profile } from 'oidc-client';
import {
  AUTH_TOKEN,
  AUTH_EXPIRY_VISIBLE,
} from 'utils/authenticationHelper/authConstant';
import { showTokenExpiryConfirmDialog } from 'utils/authenticationHelper/showTokenConfirmation';
import useInterval from './useIntervel';

export type AuthInfo = {
  userInfo: Profile | undefined;
  token: string;
  token_type: string;
};

const token_type = 'Bearer';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function retrieveAndParseToken(showDialog = true) {
  const auth = localStorage.getItem(AUTH_TOKEN);
  const parsed = auth ? JSON.parse(auth) : null;

  const delay = 2 * 60 * 1000;

  if (
    parsed?.expires_at &&
    parsed?.expires_at * 1000 < new Date().getTime() + delay
  ) {
    if (showDialog) {
      showTokenExpiryConfirmDialog();
    }
    // localStorage.removeItem(AUTH_TOKEN);
    return null;
  } else {
    sessionStorage.removeItem(AUTH_EXPIRY_VISIBLE);
  }
  return parsed;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getTokenWithType() {
  return `${token_type} ${getToken()}`;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getTokenData() {
  return { token_type, token: getToken() };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getToken() {
  const parsedAuth = retrieveAndParseToken();
  return parsedAuth?.access_token;
}

function useAuth(): AuthInfo {
  const [oidc, setOidc] = useState<any>(() => {
    return retrieveAndParseToken(false);
  });

  useInterval(() => {
    const data = retrieveAndParseToken();

    if (data?.access_token !== oidc?.access_token) setOidc(data);
  }, 5 * 1000);
  // const oidc = useReactOidc();
  const userInformation = useMemo(() => {
    return {
      userInfo: oidc?.profile,
      token: oidc?.access_token,
      token_type: token_type,
    };
  }, [oidc?.access_token, oidc?.profile]);
  return userInformation;
}

export { useAuth };
