import { useQuery, QueryResult } from 'react-query';
import { getTokenWithType } from './useAuth';
import { getLinksForDismissal } from '../utils/urls';
import { BrokenLinkArrayType } from '../types/procedureBuilder';

async function fetchBrokenLinksForDismissal({
  accountId,
  showPanel,
  payloadString,
}: {
  accountId: number;
  showPanel: boolean;
  payloadString: string | undefined;
}): Promise<TResult> {
  if (!showPanel) {
    return new Promise((resolve, reject) => {
      reject();
    });
  }
  const response = await fetch(getLinksForDismissal(accountId, payloadString), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = Array<BrokenLinkArrayType> | undefined;

export type TError = {
  message: string;
};
function useGetBrokenLinksForDismissal(
  accountId: number,
  showPanel: boolean,
  payloadString: string | undefined,
): QueryResult<TResult, TError> {
  return useQuery(
    ['getBrokenLinksForDismissal', accountId, showPanel, payloadString],
    async () => {
      return await fetchBrokenLinksForDismissal({
        accountId,
        showPanel,
        payloadString,
      });
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetBrokenLinksForDismissal };
