import React from 'react';
import styled from 'styled-components';

export const AccountList = styled.div`
  & > *.pb-link-listitem + *.pb-link-listitem {
    border-top: solid 1px ${({ theme }) => theme.colors.border.default};
  }

  & > *.pb-link-listitem:last-child {
    border-bottom: solid 1px ${({ theme }) => theme.colors.border.default};
  }
`;

export const ProcedureList = styled.div``;

export const SelectedProcedure = styled.div`
  display: flex;
`;

export const ProcedureStyle = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space[2]};
  padding-left: ${({ theme }) => theme.space[7]};
  min-width: 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.border.default};

  &:first-child {
    border-top: solid 1px ${({ theme }) => theme.colors.border.default};
  }
`;

export default { AccountList, ProcedureList, ProcedureStyle };
