import React, { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import * as Icon from '../../../assets/icons';

type StyledTagProps = {
  width?: string;
  notTruncate?: boolean;
};

const StyledTag = styled.span<StyledTagProps>`
  display: inline-flex;
  align-items: center;
  min-height: 2rem;
  border-radius: ${({ theme }) => theme.radii.rounded};
  background: hsla(var(--color-neutral-contrast-03-hsl), 80%);

  & .pr-tag-content {
    display: inline-block;
    align-items: center;
    flex-grow: 1;
    padding: 0 0.6rem;
    max-width: ${({ width }) => (width ? width : '100px')};
    white-space: nowrap;
    ${({ notTruncate }) =>
      !notTruncate &&
      css`
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      `}

    line-height: 1;
    color: ${({ theme }) => theme.colors.text.body};
  }

  & .pr-tag-close {
    align-self: stretch;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    padding: 0 0.3em 0 0.1em;
    background: none;
    border: none;
    opacity: 0.65;

    &:hover,
    &:hover:focus {
      opacity: 1;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.2rem hsla(var(--upper-low-contrast-neutral-hsl), 90%);
    }

    svg {
      width: 1em;
      height: 1em;
    }
  }
`;

type TagProps = {
  children: ReactNode;
  onClose?: () => void;
  width?: string;
  notTruncate?: boolean;
};

function Tag({
  children,
  onClose,
  width,
  notTruncate,
}: TagProps): ReactElement {
  return (
    <StyledTag width={width} notTruncate={notTruncate}>
      <span className="pr-tag-content">{children}</span>
      {onClose ? (
        <button
          data-testId="tag-close"
          onClick={onClose}
          className="pr-tag-close"
        >
          <Icon.X />
        </button>
      ) : null}
    </StyledTag>
  );
}

export { Tag };
