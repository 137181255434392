/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { confirmAlert } from 'utils/confirm-alert';
import { AUTH_EXPIRY_VISIBLE, AUTH_TOKEN } from './authConstant';

export function showTokenExpiryConfirmDialog() {
  const authExpiryVisible = sessionStorage.getItem(AUTH_EXPIRY_VISIBLE);
  if (!!authExpiryVisible) {
    return;
  }
  sessionStorage.setItem(AUTH_EXPIRY_VISIBLE, 'true');
  confirmAlert({
    // title: 'Proteus',
    message: 'Your token has expired. Click continue to refresh.',
    buttons: [
      {
        label: 'Continue',
        onClick: async (close: () => void) => {
          window?.open('/refreshToken', '_blank')?.focus();
          await isValidToken();
          close();
        },
        className: 'pr-btn-primary',
      },
    ],
    closeOnClickOutside: false,
    closeOnButtonClick: false,
  });
}

export function isValidToken() {
  return new Promise((resolve, reject) => {
    setInterval(() => {
      const auth = localStorage.getItem(AUTH_TOKEN);
      const parsed = auth ? JSON.parse(auth) : null;

      const delay = 2 * 60 * 1000;

      if (
        parsed?.expires_at &&
        parsed?.expires_at * 1000 > new Date().getTime() + delay
      ) {
        resolve('Success');
      }
    }, 1000);
  });
}
