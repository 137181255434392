/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, ReactElement } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { StyledFloatLabelInput } from 'ui/patterns';

type DatepickerType = {
  id?: any;
  placeholder?: string;
  value?: any;
  format?: string;
  onChange?: (e: any) => void;
  inputSize?: any;
  hasMaxDate?: boolean;
  disabled?: boolean;
  container?: any;
  label: string;
};

function CustomDatePicker({
  value,
  onChange,
  disabled = false,
  container,
  label,
}: DatepickerType): ReactElement {
  //to close date picker on scroll
  const datePickerRef = useRef<any>();
  useEffect(() => {
    const handleScroll = () => {
      if (container && container?.current && datePickerRef?.current) {
        datePickerRef?.current?.setOpen(false);
      }
    };

    if (container && container?.current) {
      container?.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container && container?.current) {
        container?.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const fieldID = label.replace(/\s/g, '').toLowerCase();

  return (
    <StyledFloatLabelInput>
      <DatePicker
        selected={value}
        onChange={(e: any) => {
          onChange && onChange(e);
        }}
        dateFormat="MM/dd/yyyy"
        placeholderText={label}
        disabled={disabled}
        className="floating-input"
        id={fieldID}
        minDate={new Date('01/01/1900')}
      />
      <label htmlFor={fieldID} className="floating-label" data-content={label}>
        <span className="sr-only">{label}</span>
      </label>
    </StyledFloatLabelInput>
  );
}

export { CustomDatePicker };
