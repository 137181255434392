import React, { ReactElement, useState, useCallback, useMemo } from 'react';
import { Text } from 'rebass/styled-components';
import { MainContent, PageHeader, Table, PageContent } from 'ui/patterns';
import { useDocumentTitle } from 'hooks';
import * as Icon from '../../assets/icons';
import { useQuestions } from 'hooks';
import useQuestionColumns from './useQuestionColumns';
import AddEditQuestion from '../AddEditQuestion';

const sortItem = { sortKey: 'Field Name', sortOrder: 'asc' };

const pageTitle = 'Question Repository';
export default function WelcomePage(): ReactElement {
  useDocumentTitle('Question Repository', true);
  const [showQuestionPanel, setQuestionPanel] = useState<boolean>(false);
  const [columnsSearch, setColumnsSearch] = useState({}); // table header search
  const [currentSortItem, setCurrentSortItem] = useState(sortItem);
  const [keywordSearch, setKeywordSearch] = useState(''); //keyword search
  const [questionId, setQuestionId] = useState(0);
  const [mode, setMode] = useState<string>('');

  const pageActions = [
    {
      action: 'Reset',
      icon: <Icon.Refresh title="" ariaHidden="true" />,
      handleButtonClick: () => handleReset(),
    },
    {
      action: 'Add Question',
      icon: <Icon.Add title="" ariaHidden="true" />,
      handleButtonClick: () => {
        setQuestionId(0);
        setQuestionPanel(true);
        setMode('Add');
      },
    },
  ];

  const {
    data,
    fetchMore,
    canFetchMore,
    isFetchingMore,
    isLoading,
    isFetching,
  } = useQuestions({
    columnsSearch,
    sortItem: currentSortItem,
    keywordSearch,
    fromQuestionGrid: true,
  });
  const toggleQuestionPanel = () => {
    setQuestionPanel(!showQuestionPanel);
    setQuestionId(0);
  };

  const columns = useQuestionColumns();

  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch],
  );

  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (
        currentSortItem.sortKey === item &&
        currentSortItem.sortOrder === 'asc'
      ) {
        sortOrder = 'desc';
      }
      setCurrentSortItem({
        sortKey: item,
        sortOrder,
      });
    },
    [setCurrentSortItem, currentSortItem],
  );
  const handleKeywordSearch = (value: string): void => {
    setKeywordSearch(value);
  };

  const handleReset = (): void => {
    setKeywordSearch('');
    setColumnsSearch({});
    setCurrentSortItem(sortItem);
  };

  const tableData = useMemo(() => {
    if (data) {
      return data.map(dataItem => dataItem.data).flat();
    }
  }, [data]);

  const totalItems = useMemo(() => {
    if (data) {
      return data[0].recordsFiltered;
    }
    return 0;
  }, [data]);

  const onEndReached = () => {
    if (tableData) {
      if (canFetchMore && tableData?.length < totalItems) fetchMore();
    }
  };

  const handleEditQuestion = (row: any): void => {
    setQuestionId(row.original.Id);
    setQuestionPanel(true);
    setMode('Edit');
  };

  const onEditPress = (id: number): void => {
    setQuestionId(id);
    setQuestionPanel(true);
    setMode('Edit');
  };

  return (
    <>
      <MainContent flexDirection="column">
        <PageHeader
          title={pageTitle}
          actions={pageActions}
          hasSearch
          searchValue={keywordSearch}
          handleChange={handleKeywordSearch}
        />
        <PageContent>
          <Text mb={2} fontSize={1} color="text.body" sx={{ height: '20px' }}>
            {tableData && tableData.length > 0 && (
              <Text mb={2}>{`Showing ${tableData.length} of ${totalItems}${
                isFetching ? ' | Processing' : ''
              }`}</Text>
            )}
          </Text>
          <Table
            columns={columns}
            data={tableData ? tableData : undefined}
            isLoading={isLoading}
            headerUpdateCallBack={headerUpdateCallBack}
            handleSortChange={handleSortChange}
            sortedItem={currentSortItem}
            columnsSearch={columnsSearch}
            height="300px"
            onEndReached={onEndReached}
            isFetching={!!isFetchingMore}
            onRowDoubleClick={handleEditQuestion}
            onEditPress={onEditPress}
          />
        </PageContent>
      </MainContent>
      <AddEditQuestion
        showPanel={showQuestionPanel}
        togglePanel={toggleQuestionPanel}
        questionId={questionId}
        mode={mode}
      />
    </>
  );
}
