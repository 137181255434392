import styled from 'styled-components';
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  flex,
  FlexProps,
} from 'styled-system';
import { Truncate } from 'ui/utils';
type ssProps = LayoutProps & SpaceProps & FlexProps;

const ListSearch = styled.div<ssProps>`
  position: relative;
  border-bottom: solid 1px ${({ theme }) => theme.colors.border.default};

  & > input {
    display: block;
    width: 100%;
    padding: 0.5em;
    padding-left: 2.5em;
    border: none;
    color: ${({ theme }) => theme.colors.text.body};
    border-top-left-radius: ${({ theme }) => theme.radii.soft};
    border-top-right-radius: ${({ theme }) => theme.radii.soft};
    background: var(--color-neutral-contrast-02);

    &:focus {
      outline: solid 1px var(--color-neutral-contrast-05);
      outline-offset: -4px;
    }
  }

  & > .pr-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: var(--color-neutral-contrast-04);
    pointer-events: none;
  }
`;

const ListBody = styled.div<ssProps>`
  position: relative;
  flex: 1 0 auto;
  height: 1px;
  overflow-y: auto;
  border-bottom-left-radius: ${({ theme }) => theme.radii.rounded};
  border-bottom-right-radius: ${({ theme }) => theme.radii.rounded};
  ${layout}
  ${space}
	${flex}
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  & > li {
    ${Truncate}
    padding: 0.5em;
    color: ${({ theme }) => theme.colors.text.body};
    transition: background 250ms ease-in-out;

    &:hover {
      cursor: pointer;
      background: var(--color-neutral-contrast-03);
    }

    &:active {
      transform: translateY(1px);
    }

    &.active {
      color: var(--color-on-primary);
      background: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const SearchableList = styled.div<ssProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  border: solid 1px ${({ theme }) => theme.colors.border.default};
  border-radius: ${({ theme }) => theme.radii.soft};
  ${layout}
  ${space}
	${flex}
`;

export { SearchableList, ListBody, ListSearch, List };
