import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

type ProgressWrapperProps = {
  children?: string | ReactNode;
  progress?: number;
};

const ProgressWrapper = styled.div<ProgressWrapperProps>`
  --bg: ${({ theme }) => theme.baseColors.grey};
  --pr: ${({ theme }) => theme.baseColors.primary};
  --wt: ${({ theme }) => theme.baseColors.primary};
  height: 20;
  width: '100%';
  background-color: var(--bg);
  border-radius: 50;
  margin: 20;

  & .filler {
    height: '100%';
    width: ${({ progress }) => `${progress}%`};
    background-color: var(--pr);
    border-radius: 'inherit';
    text-align: 'right';
  }

  & .label {
    padding: 5;
    color: var(--wt);
    font-weight: 'bold';
  }
`;

type ProgressBarProps = { progress?: number };

const ProgressBar = ({ progress = 0 }: ProgressBarProps): ReactElement => {
  return (
    <ProgressWrapper>
      <div className="filler">
        <span className="label">{`${progress}%`}</span>
      </div>
    </ProgressWrapper>
  );
};

export { ProgressBar };
