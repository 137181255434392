import React, { ReactNode, ReactElement, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';
import { space, layout, SpaceProps, LayoutProps } from 'styled-system';
import { getHSL } from 'utils/getHSL';

type StyledButtonProps = SpaceProps &
  LayoutProps & {
    variant?:
      | 'primary'
      | 'secondary'
      | 'success'
      | 'warning'
      | 'danger'
      | 'subtle'
      | 'ghost'
      | 'outline'
      | 'ghostTransparent'
      | 'ghostDanger'
      | 'link'
      | undefined;
    size?: 'lg' | 'sm';
    style?: React.CSSProperties;
    disabled?: boolean;
    fullWidth?: boolean;
    isActive?: boolean;
    iconBefore?: any;
    iconAfter?: any;
  };

export const StyledButton = styled.button<StyledButtonProps>`
  ${layout}
  ${space}

  --px: 0.8em;
  --py: 0.571em;

  --h: var(--color-neutral-contrast-03-h);
  --s: var(--color-neutral-contrast-03-s);
  --l: var(--color-neutral-contrast-03-l);
  --a: 100%;

  --switch: calc((var(--l) - var(--contrast-threshold)) * -100);

  --color: ${({ theme }) => theme.colors.text.body};

  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  padding: var(--py) var(--px);
  font-family: inherit;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 1;
  text-align: center;
  color: var(--color);
  background: hsla(var(--h), var(--s), var(--l), var(--a));
  border: solid 1px transparent;
  border-radius: ${({ theme }) => theme.radii.rounded};
  cursor: pointer;
  transition: background 200ms ease-in-out;
  /* text-shadow: 1px 1px 1px rgba(0,0,0,0.1); */
  width: auto;

  &:hover,
  &:active {
    background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
  }

  &:active {
    transform: translate3d(0px, 1px, 0px);
  }

  &:focus {
    outline: none;
    background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
    border: 1px solid hsl(var(--h), var(--s), calc(var(--l) - 15%));
    box-shadow: 0 0 0 0.2rem hsla(var(--h), var(--s), var(--l), 30%);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;

      &:hover {
        background: hsl(var(--h), var(--s), var(--l));
      }
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      background: hsl(var(--h), var(--s), calc(var(--l) - 15%));
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      display: block;
      width: 100%;
      max-width: 100%;
    `}

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      --color: hsl(0, 0%, var(--switch));
      --h: var(--primary-h);
      --s: var(--primary-s);
      --l: var(--primary-l);
    `}

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      --color: var(--color-neutral-contrast-01);
      --h: var(--color-neutral-contrast-08-h);
      --s: var(--color-neutral-contrast-08-s);
      --l: var(--color-neutral-contrast-08-l);
    `}

${({ variant }) =>
    variant === 'subtle' &&
    css`
      /* --color: hsl(0, 0%, var(--switch)); */
      color: var(--color-neutral-contrast-07);
      --a: 0%;
    `}

${({ variant }) =>
    variant === 'outline' &&
    css`
      border-color: var(--color-neutral-contrast-04);
    `}

  ${({ variant }) =>
    variant === 'success' &&
    css`
      --color: hsl(0, 0%, var(--switch));
      --h: ${({ theme }) => getHSL('h', theme.colors.state.success)};
      --s: ${({ theme }) => getHSL('s', theme.colors.state.success)};
      --l: ${({ theme }) => getHSL('l', theme.colors.state.success)};
    `}

  ${({ variant }) =>
    variant === 'danger' &&
    css`
      --color: hsl(0, 0%, var(--switch));
      --h: ${({ theme }) => getHSL('h', theme.colors.state.error)};
      --s: ${({ theme }) => getHSL('s', theme.colors.state.error)};
      --l: ${({ theme }) => getHSL('l', theme.colors.state.error)};
    `}

  ${({ variant }) =>
    variant === 'warning' &&
    css`
      --color: hsl(0, 0%, var(--switch));
      --h: ${({ theme }) => getHSL('h', theme.colors.state.warning)};
      --s: ${({ theme }) => getHSL('s', theme.colors.state.warning)};
      --l: ${({ theme }) => getHSL('l', theme.colors.state.warning)};
    `}

		${({ variant }) =>
    variant === 'ghostDanger' &&
    css`
      --color: ${({ theme }) => theme.colors.state.error};
      --h: var(--color-neutral-contrast-03-h);
      --s: var(--color-neutral-contrast-03-s);
      --l: var(--color-neutral-contrast-03-l);
      --a: 0%;

      &:hover {
        --a: 100%;
      }
    `}

    ${({ variant }) =>
    variant === 'ghost' &&
    css`
      --color: hsla(0, 0%, var(--switch), 60%);
      --h: var(--color-neutral-contrast-03-h);
      --s: var(--color-neutral-contrast-03-s);
      --l: var(--color-neutral-contrast-03-l);
      --a: 0%;

      &:hover {
        --a: 100%;
      }
    `}

		${({ variant }) =>
    variant === 'ghostTransparent' &&
    css`
      background: transparent;
      --color: hsla(0, 0%, var(--switch), 60%);

      &:hover {
        background: ${({ theme }) => theme.colors.transparent.black75};
      }

      html[data-color-mode='dark'] &:hover {
        background: ${({ theme }) => theme.colors.transparent.white70};
      }
    `}

		${({ variant }) =>
    variant === 'link' &&
    css`
      background: transparent;
      --color: ${({ theme }) => theme.colors.state.info};

      &:hover {
        background: ${({ theme }) => theme.colors.border.default};
      }

      html[data-color-mode='dark'] &:hover {
        background: ${({ theme }) => theme.colors.transparent.white70};
      }
    `}

  ${({ size }) =>
    size === 'lg' &&
    css`
      font-size: ${({ theme }) => theme.fontSizes[3]};
    `}

  ${({ size }) =>
    size === 'sm' &&
    css`
      font-size: ${({ theme }) => theme.fontSizes[0]};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      text-transform: uppercase;

      .pr-icon svg {
        width: 16px;
        height: 16px;
      }
    `}



  & .pr-button-label {
    line-height: 1;
  }

  & .pr-button-label:nth-child(1):not(:only-child) {
    margin-right: 0.4em;
  }

  & .pr-button-label:nth-child(2) {
    margin-left: 0.4em;
  }

  & .pr-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin: -0.2em;
  }
`;

export type ButtonProps = {
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'subtle'
    | 'ghost'
    | 'outline'
    | 'ghostTransparent'
    | 'ghostDanger'
    | 'link'
    | undefined;
  size?: 'lg' | 'sm';
  style?: React.CSSProperties;
  disabled?: boolean;
  fullWidth?: boolean;
  isActive?: boolean;
  children?: ReactNode;
  iconAfter?: ReactNode;
  iconBefore?: ReactNode;
  // onClick?: () => void;
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  title?: string;
  testId?: string;
  tabIndex?: number;
};

function Button({
  fullWidth,
  disabled,
  style,
  size,
  variant,
  children,
  isActive,
  iconAfter,
  iconBefore,
  onClick,
  className,
  type,
  title,
  testId,
  tabIndex,
}: ButtonProps): ReactElement {
  return (
    <StyledButton
      fullWidth={fullWidth}
      disabled={disabled}
      style={style}
      size={size}
      variant={variant}
      isActive={isActive}
      onClick={onClick}
      className={className}
      type={type || 'button'}
      title={title}
      data-testid={testId}
      tabIndex={tabIndex}
    >
      {iconBefore && iconBefore}
      {children && <span className="pr-button-label">{children}</span>}
      {iconAfter && iconAfter}
    </StyledButton>
  );
}

export { Button };
