import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useCreateFolder,
  useDeleteFile,
  useDocumentTitle,
  useGetAccountsListForImportFiles,
  useGetFileTree,
  useImportBlobFiles,
  useUploadFile,
} from 'hooks';
import { Flex, Text } from 'rebass/styled-components';
import {
  MainContent,
  PageHeader,
  Tree,
  EmptyState,
  Card,
  CardHeader,
  CardBody,
  SlidePanel,
  SearchableList,
  ListBody,
  ListSearch,
  List,
  FormInput,
  Loader,
} from 'ui/patterns';
import { Button, FormLabel, Dropzone } from 'ui/components';
import * as Icon from 'assets/icons';
import NoDataImage from 'assets/images/NoDataImage';
import { FilePreviewWrapper } from './styles';
import { BlobFileEntity } from 'types/fileTypes';
import { showErrorToast, showSuccessToast } from 'utils/showToast';
import { apiBaseUrl } from 'utils/urls';
import { Account } from 'types/accounts';
import { isValidFile, getEncodedFilePath, accept } from 'utils/fileUtils';

const toBase64 = (file: File): any => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export default function FileBrowser({
  showPanel,
  togglePanel: toggle,
  account,
}: {
  showPanel: boolean;
  togglePanel: () => void;
  account: string;
}): ReactElement {
  useDocumentTitle('Procedure Builder', true);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(showPanel);
    }, 10);
  }, [showPanel]);

  function togglePanel() {
    setIsVisible(false);
    setTimeout(() => {
      toggle();
    }, 500);
  }

  const history = useHistory<{ ID: number; account?: string }>();
  const params = useParams<{
    accountId: string;
    tabId?: string;
  }>();
  const accountId = parseInt(params.accountId);
  useEffect(() => {
    if (accountId === undefined || account === undefined) {
      history.push('/home');
    }
  }, [accountId, account, history]);
  const [createFolder, { isLoading: isCreateFolderLoading }] =
    useCreateFolder();
  const [uploadFile, { isLoading: isuploadFileLoading }] = useUploadFile();
  const [deleteFile, { isLoading: isDeleteFileLoading }] = useDeleteFile();

  const { data: fileTree, isLoading: isFilesLoading } = useGetFileTree(account);

  const [openImportFile, setOpenImportFile] = useState<BlobFileEntity>();
  function toggleOpenImportFile(folder?: BlobFileEntity) {
    setOpenImportFile(folder);
  }

  const [selectedFile, setSelectedFile] = useState<BlobFileEntity>();
  const [folderName, setFolderName] = useState<string>('');
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [showAddFolder, setShowAddFolder] = useState<BlobFileEntity>();
  const toggleAddFolder = (folder?: BlobFileEntity) => {
    setShowAddFolder(folder);
    setShowFeedback(false);
  };

  const [selectedFiles, setSelectedFiles] = useState<Array<BlobFileEntity>>();
  function updatedSelectedFiles(files: Array<BlobFileEntity>) {
    setSelectedFiles((selectedFiles?: Array<BlobFileEntity>) => {
      if (selectedFiles) {
        const newselectedFiles = selectedFiles.filter(item => {
          const isExists = files.find(i => item.FilePath === i.FilePath);
          return !isExists;
        });
        if (newselectedFiles.length === selectedFiles?.length) {
          return [...selectedFiles, ...files];
        } else return newselectedFiles;
      } else {
        return [...files];
      }
    });
  }

  const [importBlobFiles, { isLoading: isImportLoading }] =
    useImportBlobFiles();

  const [showFileUpload, setShowFileUpload] = useState<BlobFileEntity>();
  const toggleFileUpload = (folder?: BlobFileEntity) => {
    setShowFileUpload(folder);
  };
  const [searchAccounts, setSearchAccounts] = useState<string>('');

  const {
    data: accountsList,
    fetchMore,
    isLoading: isAccountsListForImportFilesLoading,
    isFetching,
  } = useGetAccountsListForImportFiles(accountId, searchAccounts);

  const allAccountsList = useMemo(() => {
    return accountsList?.flat();
  }, [accountsList]);

  const [selectedAccount, setSelectedAccount] = useState<Account>();
  const {
    data: selectedAccoutFileTree,
    isLoading: isSelectedAccoutFileTreeLoading,
  } = useGetFileTree(
    selectedAccount
      ? `${selectedAccount?.CID}${'-'}${selectedAccount?.SID}${'-'}${
          selectedAccount?.PID
        }`
      : undefined,
  );

  const scrollRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleScroll = useCallback(
    (e: any) => {
      if (scrollRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom) {
          fetchMore();
        }
      }
    },
    [fetchMore, scrollRef],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  const pageActions = [
    {
      action: 'Download File',
      icon: <Icon.Download />,
      disabled:
        !selectedFile || selectedFile?.FileType === 'folder' ? true : false,
      handleButtonClick: () => {
        if (selectedFile)
          window.open(
            `${apiBaseUrl}${'procedure-builder/procedures/download-blob-files?filePath='}${
              selectedFile?.FilePath
            }`,
            '_blank',
          );
      },
    },
  ];

  function onFileChange(file?: File): void {
    if (!file) {
      setSelectedFile(undefined);
      setFileToUpload(file);
      return;
    }
    const regex = /^[a-zA-Z0-9\s_\\.\-\(\)~'@$;,!=:\[\]]+$/;
    if (!regex.test(file.name)) {
      // console.log('!regex.test(file.name):', !regex.test(file.name));
      showErrorToast({
        message: 'Please choose a valid file to upload',
      });
      return;
    } else {
      const fileModules = file.name.split('.');
      const fileExtension = fileModules[fileModules.length - 1];
      // console.log('fileModules:', fileModules, fileExtension);
      if (isValidFile(fileExtension)) {
        // console.log('sValidFile(fileExtension):', isValidFile(fileExtension));
        setSelectedFile(undefined);
        setFileToUpload(file);
      } else {
        // console.log('else:', isValidFile(fileExtension));
        showErrorToast({
          message: 'Please choose a valid file to upload',
        });
      }
    }
  }

  const importActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          toggleOpenImportFile(undefined);
          setOpenImportFile(undefined);
          setSelectedFiles([]);
          setSelectedAccount(undefined);
          setSearchAccounts('');
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          // console.log('selectedFiles:', selectedFiles);
          if (selectedFiles && selectedFiles.length > 0 && openImportFile) {
            importBlobFiles(
              {
                DestinationPath: openImportFile.FilePath,
                FileListForImport: [...selectedFiles],
              },
              {
                onSuccess: () => {
                  setOpenImportFile(undefined);
                  setSelectedFiles([]);
                  setSelectedAccount(undefined);
                  toggleOpenImportFile(undefined);
                  setSearchAccounts('');
                },
              },
            );
          }
        }}
        variant="primary"
      >
        Import
      </Button>
    </>
  );

  const [showFeedback, setShowFeedback] = useState(false);

  const addFolderActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          setShowAddFolder(undefined);
          setFolderName('');
          setShowFeedback(false);
        }}
      >
        Cancel
      </Button>
      <Button
        disabled={(() => {
          if (showFeedback) {
            const folderNameAlreadyExists = showAddFolder?.BlobFile?.find(
              item =>
                item.FileType === 'folder' && item.FileName === folderName,
            );
            if (folderNameAlreadyExists) {
              return true;
            }
          }
          return false;
        })()}
        onClick={() => {
          if (showAddFolder && folderName) {
            const folderNameAlreadyExists = showAddFolder?.BlobFile?.find(
              item =>
                item.FileType === 'folder' && item.FileName === folderName,
            );
            if (folderNameAlreadyExists) {
              setShowFeedback(true);
              return;
            }
            createFolder(
              {
                Title: `${showAddFolder?.FilePath}${folderName}/do-not-delete-this-file.txt`,
                Content: `${showAddFolder?.FilePath}${folderName}`,
                Container: `root`,
                streamContent: '',
              },
              {
                onSuccess: () => {
                  showSuccessToast({
                    message: 'Folder created successfully',
                  });
                  toggleAddFolder(undefined);
                  setFolderName('');
                },
              },
            );
          }
        }}
        variant="primary"
      >
        Add folder
      </Button>
    </>
  );

  const uploadFilesActions = () => (
    <>
      <Button variant="secondary" onClick={() => setShowFileUpload(undefined)}>
        Cancel
      </Button>
      <Button
        onClick={async () => {
          if (fileToUpload) {
            const base64 = await toBase64(fileToUpload);
            uploadFile(
              {
                Title: `${showFileUpload?.FilePath}${fileToUpload.name}`,
                Container: `root`,
                Content: `C:\\fakepath\\${showFileUpload?.FilePath}`,
                streamContent: base64,
              },
              {
                onSuccess: () => {
                  showSuccessToast({
                    message: 'File uploaded successfully',
                  });
                  toggleFileUpload(undefined);
                  setFolderName('');
                  setFileToUpload(undefined);
                },
                onError: error => {
                  showErrorToast({
                    message: error.message,
                  });
                },
              },
            );
            // toggleFileUpload();
          }
        }}
        variant="primary"
      >
        Upload
      </Button>
    </>
  );

  const panelActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          togglePanel && togglePanel();
          // reset();
        }}
      >
        Cancel
      </Button>
      <Button
        disabled={
          !selectedFile || selectedFile?.FileType === 'folder' ? true : false
        }
        onClick={() => {
          if (selectedFile)
            window.open(
              `${apiBaseUrl}${'procedure-builder/procedures/download-blob-files?filePath='}${
                selectedFile?.FilePath
              }`,
              '_blank',
            );
        }}
        variant="primary"
      >
        {'Download File'}
      </Button>
    </>
  );
  const isLoading =
    isFilesLoading ||
    isImportLoading ||
    isuploadFileLoading ||
    // isAccountsListForImportFilesLoading ||
    isSelectedAccoutFileTreeLoading ||
    isCreateFolderLoading ||
    isDeleteFileLoading;

  return (
    <SlidePanel
      open={isVisible}
      onClose={() => {
        togglePanel && togglePanel();
        // reset();
      }}
      size="lg"
      title="File Browser"
      actions={panelActions()}
    >
      <MainContent flexDirection="column">
        {/* <PageHeader
        title="File Browser"
        actions={pageActions}
        hasBackNav
        backNavAction={() => {
          history.push('/home/procedure-builder', { ID: accountId });
        }}
        backNavTooltip="Go back to procedure editing"
      /> */}
        <Flex height="600px" flex="1 1 auto" p={3}>
          <Card
            width="350px"
            flexDirection="column"
            style={{ display: 'flex' }}
          >
            <CardHeader
              p={3}
              bg="var(--color-neutral-contrast-02)"
              alignItems="center"
              style={{ display: 'flex' }}
            >
              <Text
                as="h4"
                fontWeight="medium"
                lineHeight={1}
                color="text.body"
              >
                Files
              </Text>
            </CardHeader>
            <CardBody p={2} flex="1 0 auto" overflowY="auto" height="1px">
              <Tree
                account={account}
                setSelectedFile={setSelectedFile}
                files={fileTree}
                selectedFile={selectedFile}
                toggleAddFolder={toggleAddFolder}
                toggleFileUpload={toggleFileUpload}
                toggleOpenImportFile={toggleOpenImportFile}
                deleteFile={(file: BlobFileEntity) => {
                  deleteFile(
                    {
                      Container: 'root',
                      Title:
                        file.FileType === 'folder'
                          ? `${file?.FilePath}do-not-delete-this-file.txt`
                          : file.FilePath,
                    },
                    {
                      onSuccess: () => {
                        showSuccessToast({
                          message:
                            file.FileType === 'folder'
                              ? 'Folder deleted successfully'
                              : 'File deleted successfully',
                        });
                        setSelectedFile(undefined);
                      },
                    },
                  );
                }}
              />
            </CardBody>
          </Card>
          <FilePreviewWrapper>
            <EmptyState>
              {!selectedFile ? (
                <NoDataImage feedback="Please select a file to preview" />
              ) : null}
              {selectedFile && selectedFile.FileType === 'image' ? (
                <img src={selectedFile.FileUri} />
              ) : null}
              {selectedFile &&
              selectedFile.FileType !== 'image' &&
              selectedFile.FileType !== 'folder' ? (
                <iframe
                  src={`${apiBaseUrl}${'procedures/'}${getEncodedFilePath(
                    selectedFile.FileUri,
                  )}/`}
                  style={{ width: '100%', height: '100%' }}
                ></iframe>
              ) : null}
            </EmptyState>
          </FilePreviewWrapper>
        </Flex>

        {/* Import Files */}
        <SlidePanel
          title="Import Files"
          open={!!openImportFile}
          onClose={() => {
            toggleOpenImportFile(undefined);
            setOpenImportFile(undefined);
            setSelectedFiles([]);
            setSelectedAccount(undefined);
            setSearchAccounts('');
          }}
          size="lg"
          actions={importActions()}
        >
          <Flex height="100%">
            <Flex flexDirection="column">
              <FormLabel>Accounts</FormLabel>
              <SearchableList width="400px" flex={1}>
                <ListSearch>
                  <input
                    value={searchAccounts}
                    onChange={e => {
                      setSearchAccounts(e.currentTarget.value);
                    }}
                    type="search"
                    placeholder="Search account name / CID"
                  />
                  <Icon.Search />
                  {/* {isFetching ? <Loader noBg /> : null} */}
                </ListSearch>
                {allAccountsList && allAccountsList.length > 0 ? (
                  <ListBody ref={scrollRef}>
                    <List>
                      {allAccountsList.map((item?: Account) => (
                        <li
                          key={item?.AccountNameAndNumber}
                          onClick={() => {
                            if (item) {
                              setSelectedAccount({ ...item });
                              setSelectedFiles([]);
                            }
                          }}
                          className={
                            selectedAccount && item?.Id === selectedAccount.Id
                              ? 'active'
                              : ''
                          }
                        >
                          {item?.AccountNameAndNumber}
                        </li>
                      ))}
                      {/* {isFetching ? <Loader noBg /> : null} */}
                    </List>
                  </ListBody>
                ) : (
                  <>
                    {isAccountsListForImportFilesLoading ? (
                      <Card
                        flex="1"
                        position="relative"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Loader />
                      </Card>
                    ) : (
                      <Card
                        flex="1"
                        position="relative"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <EmptyState>
                          <NoDataImage feedback="No data available" />
                        </EmptyState>
                      </Card>
                    )}
                  </>
                )}
              </SearchableList>
            </Flex>

            <Flex ml={3} flex="1" flexDirection="column">
              <FormLabel>Files</FormLabel>
              <Card
                flex="1 0 auto"
                p={3}
                height="1px"
                overflowX="auto"
                bg="var(--color-neutral-contrast-02)"
              >
                {/* <TreeNew files={selectedAccoutFileTree} selectMode /> */}
                <Tree
                  selectMode={true}
                  updatedSelectedFiles={updatedSelectedFiles}
                  selectedFiles={selectedFiles}
                  files={selectedAccoutFileTree}
                  toggleOpenImportFile={toggleOpenImportFile}
                  account={account}
                />
              </Card>
            </Flex>
          </Flex>
        </SlidePanel>

        {/* Add Folder */}
        <SlidePanel
          title="Add New Folder"
          open={!!showAddFolder}
          onClose={() => {
            setFolderName('');
            setShowAddFolder(undefined);
            setShowFeedback(false);
          }}
          size="sm"
          actions={addFolderActions()}
        >
          <FormInput
            value={folderName}
            onTextChange={setFolderName}
            label="Enter folder name"
            feedback={(() => {
              if (showFeedback) {
                const folderNameAlreadyExists = showAddFolder?.BlobFile?.find(
                  item =>
                    item.FileType === 'folder' && item.FileName === folderName,
                );
                if (folderNameAlreadyExists) {
                  return 'Folder name is already exist, Please try with another name';
                }
              }
              return '';
            })()}
          />
        </SlidePanel>

        {/* Upload Files */}
        <SlidePanel
          title="Upload Files"
          open={!!showFileUpload}
          onClose={() => setShowFileUpload(undefined)}
          size="md"
          actions={uploadFilesActions()}
        >
          <Dropzone
            accept={accept}
            onChange={(file?: File) => {
              // console.log('file');
              onFileChange(file);
            }}
            value={fileToUpload?.name}
            flex={1}
            alignSelf="center"
            height="100%"
            info="Special characters not allowed in fileName(+, #, {, }, `, %, &, /, *, ^)"
          />
        </SlidePanel>
        {isLoading && <Loader />}
      </MainContent>
    </SlidePanel>
  );
}
