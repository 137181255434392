import React, { ReactElement, ReactNode, useRef } from 'react';
import { Button } from 'ui/components';
import * as Icon from 'assets/icons';
import {
  AccordionWrapper,
  AccordionHeader,
  AccordionBody,
  AccordionHeaderContent,
} from './styles';
import { Text } from 'rebass';

type AccordionProps = {
  title?: string;
  headerContent?: ReactNode;
  children?: ReactNode;
  opened?: boolean;
  style?: React.CSSProperties;
  className?: string;
  toggleButtonVariant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'subtle'
    | 'ghost'
    | undefined;
  setExpanded?: (opened: boolean) => void;
};

function Accordion({
  headerContent,
  opened,
  setExpanded,
  title,
  children,
  style,
  className,
  toggleButtonVariant = 'secondary',
}: AccordionProps): ReactElement {
  // const [expanded, setExpanded] = useState(!!opened);

  const collapseRef = useRef<HTMLDivElement>();
  return (
    <AccordionWrapper className={className} style={style}>
      <AccordionHeader opened={opened}>
        {headerContent ? (
          <AccordionHeaderContent>{headerContent}</AccordionHeaderContent>
        ) : null}
        {title ? (
          <Text flex="1" fontWeight="bold" lineHeight="1">
            {title}
          </Text>
        ) : null}
        <Button
          testId="expand"
          variant={toggleButtonVariant}
          size="sm"
          onClick={() => setExpanded && setExpanded(!opened)}
        >
          {opened ? <Icon.ChevronUp title="" /> : <Icon.ChevronDown title="" />}
        </Button>
      </AccordionHeader>
      <AccordionBody
        style={
          opened
            ? { height: collapseRef.current?.scrollHeight + 'px' }
            : { height: '0px' }
        }
        ref={collapseRef}
      >
        {children}
      </AccordionBody>
    </AccordionWrapper>
  );
}

export {
  Accordion,
  AccordionWrapper,
  AccordionHeader,
  AccordionBody,
  AccordionHeaderContent,
};
