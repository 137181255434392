import { useInfiniteQuery, InfiniteQueryResult } from 'react-query';
import { Forms } from 'types/forms';
import { formsList } from '../utils/urls';
import { getTokenWithType } from './useAuth';

const pageLength = 100;

function getColumnFromId(id: string) {
  const idsList = [
    'Id',
    'CID',
    'Account',
    'Last Updated By',
    'Date Last Updated',
    'Form Status',
    'Form Notes',
  ];
  const index = idsList.findIndex(item => item === id);
  return `${index}`;
}

async function fetchForms({ requestData }: TVariables): Promise<TResult> {
  const response = await fetch(formsList, {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

type resultType = {
  data: Array<Forms>;
  draw: number;
  isAutoRefreshEnabled: boolean;
  error: Error;
  recordsFiltered: number;
  recordsTotal: number;
};

export type TResult = {
  message: string;
  isSuccess: boolean;
  data: Array<resultType>;
  recordsFiltered: number;
  recordsTotal: number;
  draw: number;
  error: Error | null;
};

export type TError = { message: string };
export type TVariables = {
  requestData: any;
};

type SortItemType = {
  sortKey: string;
  sortOrder: string;
};

function formatRequestData(
  accountId: number,
  page: number,
  keywordSearch: string,
  columnsSearch: Record<string, string>,
  sortedColumn: string,
  sortOrder: string,
  sortedColumn1?: string,
  sortOrder1?: string,
): FormData {
  const formData = new URLSearchParams();
  formData.append('draw', '1');
  formData.append('columns[0][data]', 'Id');
  formData.append('columns[0][name]', '');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append(
    'columns[0][search][value]',
    columnsSearch && columnsSearch['Id'] ? columnsSearch['Id'] : '',
  );
  formData.append('columns[0][search][regex]', 'false');

  formData.append('columns[1][data]', 'Account.CID');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append(
    'columns[1][search][value]',
    columnsSearch && columnsSearch['CID'] ? columnsSearch['CID'] : '',
  );
  formData.append('columns[1][search][regex]', 'false');
  formData.append('columns[2][data]', 'Account.Name');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append(
    'columns[2][search][value]',
    columnsSearch && columnsSearch['Account'] ? columnsSearch['Account'] : '',
  );
  formData.append('columns[2][search][regex]', 'false');
  formData.append('columns[3][data]', 'LastModifiedBy');
  formData.append('columns[3][name]', '');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append(
    'columns[3][search][value]',
    columnsSearch && columnsSearch['Last Updated By']
      ? columnsSearch['Last Updated By']
      : '',
  );
  formData.append('columns[3][search][regex]', 'false');
  formData.append('columns[4][data]', 'LastModified');
  formData.append('columns[4][name]', 'LastModified');
  formData.append('columns[4][searchable]', 'true');
  formData.append('columns[4][orderable]', 'true');
  formData.append(
    'columns[4][search][value]',
    columnsSearch && columnsSearch['Date Last Updated']
      ? columnsSearch['Date Last Updated']
      : '',
  );
  formData.append('columns[4][search][regex]', 'false');
  formData.append('columns[5][data]', 'SurveyStatus.Description');
  formData.append('columns[5][name]', '');
  formData.append('columns[5][searchable]', 'true');
  formData.append('columns[5][orderable]', 'true');
  formData.append(
    'columns[5][search][value]',
    columnsSearch && columnsSearch['Form Status']
      ? columnsSearch['Form Status']
      : '',
  );
  formData.append('columns[5][search][regex]', 'false');
  formData.append('columns[5][data]', 'Notes');
  formData.append('columns[5][name]', '');
  formData.append('columns[5][searchable]', 'true');
  formData.append('columns[5][orderable]', 'true');
  formData.append(
    'columns[5][search][value]',
    columnsSearch && columnsSearch['Form Notes']
      ? columnsSearch['Form Notes']
      : '',
  );
  formData.append('columns[5][search][regex]', 'false');

  if (sortedColumn1 && sortOrder1) {
    formData.append('order[0][column]', sortedColumn1); // for initial fetch and reset
    formData.append('order[0][dir]', sortOrder1);
    formData.append('order[1][column]', sortedColumn);
    formData.append('order[1][dir]', sortOrder);
  } else {
    formData.append('order[0][column]', sortedColumn);
    formData.append('order[0][dir]', sortOrder);
  }

  formData.append('search[value]', `${keywordSearch}`);
  formData.append('search[regex]', 'false');
  formData.append('start', `${page * 100}`);
  formData.append('length', `${100}`);
  formData.append('accountId', `${accountId}`);
  formData.append('timezoneOffset', '-330');
  return formData;
}

function useGetFormsList({
  accountId,
  page,
  keywordSearch,
  columnsSearch,
  sortItem,
}: {
  accountId: number;
  page: number;
  keywordSearch: string;
  columnsSearch: Record<string, string>;
  sortItem: SortItemType | Array<SortItemType>;
}): InfiniteQueryResult<TResult, TError> {
  let requestData = {};
  if (sortItem instanceof Array) {
    requestData = formatRequestData(
      accountId,
      page,
      keywordSearch,
      columnsSearch,
      getColumnFromId(sortItem[0].sortKey),
      sortItem[0].sortOrder,
      getColumnFromId(sortItem[1].sortKey),
      sortItem[1].sortOrder,
    );
  } else if (sortItem.sortKey && sortItem.sortOrder) {
    requestData = formatRequestData(
      accountId,
      page,
      keywordSearch,
      columnsSearch,
      getColumnFromId(sortItem.sortKey),
      sortItem.sortOrder,
    );
  }

  return useInfiniteQuery(
    ['forms_list', page, keywordSearch, columnsSearch, sortItem, accountId],
    () => fetchForms({ requestData }),
    {
      getFetchMore: (lastGroup, allGroups) => {
        // console.log('lastGroup: ', lastGroup.recordsFiltered / pageLength);
        // console.log(
        //   'allGroups: ',
        //   allGroups.length,
        //   allGroups.length < lastGroup.recordsFiltered / pageLength,
        // );
        return allGroups.length < lastGroup.recordsFiltered / pageLength
          ? true
          : null;
      },
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetFormsList };
