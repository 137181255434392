import { useQuery, QueryResult } from 'react-query';
import { getAccountStatus } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchAccountStatus(): Promise<APIResult> {
  const response = await fetch(getAccountStatus, {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type AccountStatusItem = {
  Id: number;
  Description: string;
};
export type APIResult = Array<AccountStatusItem>;

export type ResultItem = {
  id: number;
  name: string;
};
export type TResult = Array<ResultItem>;

export type TError = { message: string };

function useAccountStatus(): QueryResult<TResult, TError> {
  return useQuery(
    'get_account_status',
    async () => {
      const fetchResponse = await fetchAccountStatus();
      const result = fetchResponse.map(item => ({
        id: item.Id,
        name: item.Description,
      }));
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useAccountStatus };
