import React, { ReactElement } from 'react';
import { Flex, Text, Heading } from 'rebass/styled-components';
import { MainContent, PageHeader } from 'ui/patterns';
import { Link } from 'react-router-dom';

export default function NotFound(): ReactElement {
  return (
    <MainContent flexDirection="column">
      <PageHeader title="Page Not Found" />
      <Flex
        flex="1 1 auto"
        height="1px"
        overflowY="auto"
        p={5}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{ gap: '.5rem' }}
      >
        <Flex width="100px" color="border.grayDark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
              clipRule="evenodd"
            />
          </svg>
        </Flex>

        <Heading color="text.body">There’s no page at this address!</Heading>
        <Text color="text.muted">
          Please check the URL and try again. Alternatively you can go to{' '}
          <Link to="/">home</Link>.
        </Text>
      </Flex>
    </MainContent>
  );
}
