import React, { ReactElement } from 'react';
import { FormLabel, StyledFormFeedback } from '../../components';
import { FormGroup } from '../index';
import { ValidationRules } from 'react-hook-form';
import { FormattedInput } from '@buttercup/react-formatted-input';
import styled, { css } from 'styled-components';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';
import { getHSL } from 'utils/getHSL';

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

// type InputProps = React.DetailedHTMLProps<
//   React.InputHTMLAttributes<HTMLInputElement>,
//   HTMLInputElement
// > & {
//   label: string;
//   register: ({ required }: { required?: boolean }) => RefReturn;
// };

export type InputProps = {
  name?: string;
  label?: string | any;
  value?: string | number;
  type?: 'email' | 'number' | 'password' | 'search' | 'text' | 'tel' | 'url';
  placeholder?: string;
  disabled?: boolean;
  validation?: 'error' | 'success' | 'warning';
  feedback?: string;
  required?: boolean;
  register?: (params: ValidationRules) => RefReturn;
  maxLength?: number;
  // validate?: { pattern?: { value?: string; message?: string } };
  validate?: ValidationRules;
  style?: React.CSSProperties;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (formattedText: string, raw: string) => void;
  format: any;
};

const FormattedCustomInput = ({
  name,
  label,
  value,
  placeholder,
  disabled,
  validation,
  feedback,
  required,
  style,
  onChange,
  format,
}: InputProps): ReactElement => {
  const id = `toggle-${label.replace(/ /g, '').toLowerCase()}${name}`;
  return (
    <FormGroup style={style}>
      {label ? (
        <FormLabel htmlFor={id} required={required} disabled={disabled}>
          {label}
        </FormLabel>
      ) : null}
      <FormattedStyledInput
        value={value}
        placeholder={placeholder}
        format={format}
        onChange={onChange}
      />
      {feedback ? (
        <StyledFormFeedback validation={validation} disabled={disabled}>
          {feedback}
        </StyledFormFeedback>
      ) : null}
    </FormGroup>
  );
};

type InputPropsN = LayoutProps &
  SpaceProps & {
    label?: string | any;
    value?: string | number;
    // onTextChange?: (text: string) => void;
    type?: 'email' | 'number' | 'password' | 'search' | 'text' | 'tel' | 'url';
    placeholder?: string;
    disabled?: boolean;
    validation?: string;
    feedback?: string;
    inline?: boolean;
    ref?: any;
  };

const FormattedStyledInput = styled(FormattedInput)<InputPropsN>`
  ${layout};
  ${space};

  --px: 0.571em;
  --py: 0.571em;
  --a: 80%;
  --h: var(--primary-h);
  --s: var(--primary-s);
  --l: var(--primary-l);
  --input-bg: hsla(var(--color-neutral-contrast-03-hsl), 50%);
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: var(--py) var(--px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.text.body};
  background-color: var(--input-bg);
  background-clip: padding-box;
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  border-radius: ${({ theme }) => theme.radii.rounded};
  transition: all 200ms ease-in-out;

  html[data-color-mode='dark'] & {
    --input-bg: var(--color-neutral-contrast-02);
  }

  &:focus {
    background-color: var(--color-neutral-contrast-00);
    border-color: var(--primary-color);
    outline: 0;
    box-shadow: 0 0 0 0.2rem hsla(var(--h), var(--s), var(--l), 30%);

    html[data-color-mode='dark'] & {
      background-color: var(--color-neutral-contrast-00);
    }
  }

  html[data-color-mode='dark'] & {
    --a: 40%;

    ::placeholder {
      color: var(--reduced-high-contrast-neutral);
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  ${({ validation }) =>
    validation === 'error' &&
    css`
      border-color: ${({ theme }) => theme.colors.border.error};
      --h: ${({ theme }) => getHSL('h', theme.colors.state.error)};
      --s: ${({ theme }) => getHSL('s', theme.colors.state.error)};
      --l: ${({ theme }) => getHSL('l', theme.colors.state.error)};

      &:focus {
        border-color: ${({ theme }) => theme.colors.border.error};
      }
    `}

    ${({ validation }) =>
    validation === 'success' &&
    css`
      border-color: ${({ theme }) => theme.colors.border.success};
      --h: ${({ theme }) => getHSL('h', theme.colors.state.success)};
      --s: ${({ theme }) => getHSL('s', theme.colors.state.success)};
      --l: ${({ theme }) => getHSL('l', theme.colors.state.success)};

      &:focus {
        border-color: ${({ theme }) => theme.colors.border.success};
      }
    `}

    ${({ validation }) =>
    validation === 'warning' &&
    css`
      border-color: ${({ theme }) => theme.colors.border.warning};
      --h: ${({ theme }) => getHSL('h', theme.colors.state.warning)};
      --s: ${({ theme }) => getHSL('s', theme.colors.state.warning)};
      --l: ${({ theme }) => getHSL('l', theme.colors.state.warning)};

      &:focus {
        border-color: ${({ theme }) => theme.colors.border.warning};
      }
    `}

  ${({ inline }) =>
    inline &&
    css`
      width: auto;
    `}
`;

export { FormattedCustomInput };
