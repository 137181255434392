import { useQuery, QueryResult } from 'react-query';
import { externalLinksForAccount } from '../utils/urls';
import { ExternalLinksforAccountType } from '../types/procedureBuilder';
import { getTokenWithType } from './useAuth';

async function fetchExternalLinksForAccount({
  accountId,
}: {
  accountId: number;
}): Promise<TResult> {
  if (!accountId)
    return new Promise((resolve, reject) => {
      reject();
    });
  const response = await fetch(externalLinksForAccount(accountId), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = ExternalLinksforAccountType | undefined;

export type TError = {
  message: string;
};

function useGetExternalLinksForAccount(
  accountId: number,
  callExternalLinks?: boolean,
): QueryResult<TResult, TError> {
  return useQuery(
    ['getExternalLinksForAccount', accountId, callExternalLinks],
    async () => {
      // if (callExternalLinks === true)
      return await fetchExternalLinksForAccount({
        accountId,
      });
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetExternalLinksForAccount };
