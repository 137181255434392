import { useEffect, useRef } from 'react';

export default function useInterval(callback: () => void, delay: number): null {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      if (savedCallback.current) savedCallback.current();
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
  return null;
}

let globalIndex = 0;

export const lazyRun = function (fn: () => void, timeout: number): void {
  timeout = timeout ? timeout : 500;
  globalIndex++;
  const localIndex = globalIndex;
  setTimeout(() => {
    if (localIndex === globalIndex && fn) {
      fn();
    }
  }, timeout);
};
