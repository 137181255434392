import styled from 'styled-components';
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
} from 'styled-system';

type FilePreviewWrapperType = LayoutProps &
  SpaceProps &
  SpaceProps &
  ColorProps &
  PositionProps &
  FlexboxProps;

export const FilePreviewWrapper = styled.div<FilePreviewWrapperType>`
  flex: 1;
  position: relative;
  margin-left: ${({ theme }) => theme.space[3]};
  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
  background: var(--color-neutral-contrast-02);
  border: solid 1px ${({ theme }) => theme.colors.border.default};
  border-radius: ${({ theme }) => theme.radii.soft};
  ${space}
  ${layout}
  ${color}
  ${flexbox}
  ${position}
`;
