import React, { ReactElement, useEffect } from 'react';
import { SlidePanel, FormInput, FormTextarea } from 'ui/patterns';
import { Button, Stack, Toggle } from 'ui/components';
import { useForm } from 'react-hook-form';
import { useSaveAccountsContact, useGetContactInfo } from 'hooks';
import { showErrorToast } from 'utils/showToast';
import { Contact } from 'types/contacts';

type ContactProps = {
  showPanel: boolean;
  togglePanel: () => void;
  contactId: number;
  accountId: number;
  mode: string;
};

export default function AddEditContact({
  showPanel,
  togglePanel,
  contactId,
  accountId,
  mode,
}: ContactProps): ReactElement {
  const { data: contactInfo } = useGetContactInfo({
    account: accountId,
    id: contactId,
  });
  const { register, handleSubmit, reset, errors } = useForm();
  useEffect(() => {
    reset({
      ...contactInfo,
    });
  }, [contactInfo, reset]);
  const [saveContact, { isLoading }] = useSaveAccountsContact();

  function resetForm() {
    reset({
      Name: '',
      PhoneNumber: '',
      Notes: '',
      IsUsedByCallCenter: false,
      IsForwardedToProtoCall: false,
      IsOkToGiveOutNumber: false,
    });
  }

  function onSubmit(data: Contact) {
    saveContact(
      {
        ...data,
        AccountId: accountId,
        Id: contactId,
      },
      {
        onSuccess: () => {
          togglePanel();
          resetForm();
        },
        onError: ({ Message }: { Message: string }) => {
          showErrorToast({ message: Message });
        },
      },
    );
  }
  const addContactActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          togglePanel();
          resetForm();
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={handleSubmit(onSubmit)}
        type="submit"
        variant="primary"
        disabled={isLoading}
      >
        Save
      </Button>
    </>
  );
  return (
    <>
      <SlidePanel
        title={`${mode === 'Edit' ? 'Edit Contact' : 'Add New Contact'}`}
        actions={addContactActions()}
        onClose={() => {
          togglePanel();
          resetForm();
        }}
        open={showPanel}
      >
        <Stack direction="y" gap={4}>
          <FormInput
            register={register}
            name="Name"
            label="Name"
            type="text"
            required
          />
          <FormInput
            register={register}
            name="PhoneNumber"
            label="Phone Number"
            type="tel"
          />
          <FormTextarea
            name="Notes"
            register={register}
            label="Notes"
            resize="vertical"
          />

          <Toggle
            name={'IsUsedByCallCenter'}
            register={register}
            label="Used by Call Center"
          />
          <Toggle
            name={'IsForwardedToProtoCall'}
            register={register}
            label="Forwarded to ProtoCall"
          />
          <Toggle
            name={'IsOkToGiveOutNumber'}
            register={register}
            label="Ok to give out number"
          />
        </Stack>
      </SlidePanel>
    </>
  );
}
