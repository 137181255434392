import styled, { css } from 'styled-components';
import { SpaceProps, space, LayoutProps, layout } from 'styled-system';

type StyledMainContentProps = SpaceProps &
  LayoutProps & {
    contentCentered?: boolean;
    contentStacked?: boolean;
    flexDirection?: string;
  };

const MainContent = styled.main<StyledMainContentProps>`
  flex: 1 0 auto;
  display: flex;

  ${props =>
    props.contentCentered &&
    css`
      justify-content: center;
      align-items: center;
    `};

  ${({ flexDirection }) =>
    flexDirection === 'row' &&
    css`
      flex-direction: row;
    `};

  ${({ flexDirection }) =>
    flexDirection === 'column' &&
    css`
      flex-direction: column;
    `};

  ${space}
  ${layout}
`;

export { MainContent };
