import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { Forms } from 'types/forms';
import { saveNewForm, getFormDetails as updateForm } from 'utils/urls';
import { getTokenWithType } from './useAuth';

export async function postForm({
  params,
}: {
  params: Forms;
}): Promise<TResult> {
  const response = await fetch(
    params.Id === 0 ? saveNewForm : updateForm(params.Id),
    {
      method: params.Id === 0 ? 'POST' : 'PUT',
      headers: {
        Authorization: getTokenWithType(),
        'content-type': 'application/json',
      },
      body: JSON.stringify(params),
    },
  );
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = Forms & ValidationError;
export type TVariables = Forms;
export type TError = {
  Message: string;
};
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;
// async function wait(millis: number) {
//   return new Promise(resolve => {
//     setTimeout(resolve, millis);
//   });
// }

function useSaveForm(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: Forms) => {
      const newForm = await postForm({
        params: params,
      });
      if (newForm.Message) throw newForm;
      if (newForm) {
        queryCache.invalidateQueries(['get_form_details']);
        queryCache.invalidateQueries(['forms_list'], {
          refetchInactive: true,
        });
      }
      // await wait(2000);
      return newForm;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveForm };
