import React, {
  ReactElement,
  SyntheticEvent,
  useMemo,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { Box } from 'rebass/styled-components';
import { useForm, Controller } from 'react-hook-form';
import {
  SlidePanel,
  FormInput,
  FormSelect,
  Alert,
  Loader,
  FormTextarea,
  FormReactSelect,
} from 'ui/patterns';
import { Button, Stack, Checkbox, RadioButton } from 'ui/components';
import * as Icon from 'assets/icons';
import {
  useGetQuestionTypes,
  useGetQuestionSpecifications,
  useSaveQuestion,
  useGetQuestionDetail,
  useTextFormats,
  useUploadPDFQuestion,
  useGetUrlValidation,
} from 'hooks';
import { AddEditQuestionType } from 'types/questions';
import { showSuccessToast, showErrorToast } from 'utils/showToast';
import { formatQuestionData } from './questionData';
import LookupQuestion from './LookupQuestion';
import ChoiceQuestion from './ChoiceQuestion';
import PDFQuestion from './PDFQuestion';
import { PdfDetails } from 'types/questions';
import { FormattedCustomInput } from 'ui/patterns/formInput/FormattedCustomInput';
import {
  phonePattern,
  numberOnlyPattern,
  ssnPattern,
  militaryTimePattern,
  getValidations,
} from 'utils/textFormatting';

type QuestionProps = {
  showPanel: boolean;
  togglePanel: () => void;
  questionId: number;
  mode: string;
};

type QuestionTemplateItemType = {
  Id: number;
  IncludeByDefault: boolean;
  IsDefault: boolean;
  ItemLabel: string;
  ItemName: string;
  Order: number;
  QuestionTemplateId: number;
};

const urlPrefixOptions = [
  { id: 1, name: 'http://' },
  { id: 2, name: 'https://' },
];

export default function AddEditQuestion({
  showPanel,
  togglePanel,
  questionId,
  mode,
}: QuestionProps): ReactElement {
  const [url, setUrl] = useState<string>('');
  const [submit, setSubmit] = useState<boolean>(false);
  const [urlPrefix, setUrlPrefix] = useState<number>(2);

  // look up question
  const [lookupFieldName, setLookupFieldName] = useState<string>();
  const [lookupFields, setLookupFields] = useState<Array<string> | undefined>(
    [],
  );

  // choice type question
  const [questionTemplateOptions, setQuestionTemplateOptions] = useState<
    Array<QuestionTemplateItemType>
  >([]);

  const [pdfData, setPdfData] = useState<any>();
  const [superAdminPermission, setSuperAdminPermission] =
    useState<boolean>(false);

  const [uploadPDF, { isLoading: isPDFUploading }] = useUploadPDFQuestion();

  const personAssociationOptions = [
    { id: 0, name: 'None' },
    { id: 1, name: 'Caller' },
    { id: 2, name: 'Person of concern' },
  ];
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    errors,
    getValues,
    setValue,
  } = useForm();
  const { data: questionTypes } = useGetQuestionTypes();

  const { data: questionDetails, isLoading: isQuestionDetailsLoading } =
    useGetQuestionDetail(questionId);
  const { data: textFormat } = useTextFormats();

  const {
    data: validatedResult,
    refetch: geUrlValidation,
    isFetching,
  } = useGetUrlValidation(url);

  const questionTypeId = watch('QuestionTypeId');
  const type = watch('Type'); // link ,text and choice type question

  const textQuestionTypeFormatId = watch('TextQuestionTypeFormatId');

  const errorMessage = useMemo(() => {
    if (validatedResult) {
      if (validatedResult?.StatusCode === 200 && submit && !isFetching)
        return 'valid';
      if (validatedResult?.StatusCode == null && submit && !isFetching)
        return 'invalid';
    }
    return '';
  }, [validatedResult, submit, isFetching]);

  useEffect(() => {
    const permissions = localStorage.getItem('userPermissions');
    if (permissions) {
      const temp = JSON.parse(permissions);
      setSuperAdminPermission(temp.isSuperAdmin);
    }
  }, []);

  const questionTypeName = useMemo(() => {
    if (questionTypes) {
      const typeObj = questionTypes?.find(item => item.id === questionTypeId);
      if (typeObj?.name === 'Date Of Birth') return 'dob';
      if (typeObj?.name === 'Display Only') return 'display-only';
      if (typeObj?.name === 'Outbound Call') return 'outbound-call';
      if (typeObj?.name === 'Preliminary Report') return 'preliminary-report';
      if (typeObj?.name === 'Scheduler') return 'scheduler';
      if (typeObj?.name === 'Lookup') return 'lookup';
      if (typeObj?.name === 'Tracker') return 'on-call';
      if (typeObj?.name === 'Text') return 'text';
      if (typeObj?.name === 'Resources') return 'resource';
      if (typeObj?.name === 'Bed Request') return 'bed-request';
      if (typeObj?.name === 'Date Of Birth With Comment') return 'dob-comments';

      return typeObj?.name;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionTypeId]);

  const { data: questionSpec, isLoading: isQuestionSpecLoading } =
    useGetQuestionSpecifications(questionTypeName);

  const [saveQuestion, { isLoading: isSaveQuestionLoading }] =
    useSaveQuestion();

  const isLoading =
    isQuestionSpecLoading ||
    isSaveQuestionLoading ||
    isQuestionDetailsLoading ||
    isPDFUploading ||
    isFetching;

  useEffect(() => {
    if (questionDetails) {
      let personAssociationId = -1;
      if (questionDetails?.PersonAssociation === null) personAssociationId = 0;
      if (questionDetails?.PersonAssociation === 0) personAssociationId = 1;
      if (questionDetails?.PersonAssociation === 1) personAssociationId = 2;

      reset({
        ...questionDetails,
        ...questionDetails?.QuestionTypeOptions,
        PersonAssociation: personAssociationId,
        QuestionTypeId: questionDetails?.QuestionTypeId,
        Type: questionDetails?.QuestionTypeOptions?.Type, // link , text  and choice type question
      });
      if (
        questionDetails.QuestionTypeOptions?.$type ===
        'Proteus2.Api.Domain.Surveys.QuestionTypes.PdfQuestionTypeOptions, Proteus2.Api.Domain'
      ) {
        setPdfData({ ...questionDetails.QuestionTypeOptions });
      }
    }
  }, [questionDetails, reset]);

  useEffect(() => {
    if (questionDetails) {
      setLookupFields(questionDetails?.QuestionTypeOptions?.FieldNames);
    }
  }, [questionDetails]);

  useEffect(() => {
    if (questionId === 0 && questionSpec) {
      reset({
        ...getValues(),
        Type: questionSpec?.Type, // link text and choice type question
        Url: '',
        LinkDescription: '',
        TextQuestionTypeFormatId: null,
        DefaultValue: '',
      });
      resetState();
    }
  }, [questionId, questionSpec, reset, getValues]);

  useEffect(() => {
    if (questionDetails && questionDetails?.QuestionTemplateOptions) {
      setQuestionTemplateOptions(
        questionDetails?.QuestionTemplateOptions.sort(
          (a: { Order: number }, b: { Order: number }) => a.Order - b.Order,
        ).map((item: QuestionTemplateItemType) => {
          return {
            ...item,
          };
        }),
      );
    }
  }, [questionDetails]);

  const questionDes = useMemo(() => {
    if (questionTypes) {
      const val = questionTypes?.map((item: any) => {
        const returnValue: any = {};
        returnValue.label = (
          <div className="label">
            <span>{item.name}</span>
            <p> {item?.description}</p>
          </div>
        );
        returnValue.value = item?.id;
        return returnValue;
      });
      return val;
    }
  }, [questionTypes]);

  useEffect(() => {
    if (questionDetails) {
      if (
        questionDetails.QuestionTypeId === 1 &&
        questionDetails.QuestionTypeOptions?.TextQuestionTypeFormatId === 7
      ) {
        let link = '';
        if (
          questionDetails.QuestionTypeOptions?.DefaultValue?.includes('http://')
        ) {
          link = questionDetails.QuestionTypeOptions?.DefaultValue.replace(
            'http://',
            '',
          ).trim();
          setUrl(link);
          setUrlPrefix(1);
        } else if (
          questionDetails.QuestionTypeOptions?.DefaultValue?.includes(
            'https://',
          )
        ) {
          link = questionDetails.QuestionTypeOptions?.DefaultValue.replace(
            'https://',
            '',
          ).trim();
          setUrl(link);
          setUrlPrefix(2);
        }
      }
    }
  }, [questionDetails]);

  function resetForm() {
    reset({
      FieldName: '',
      FieldLabel: '',
      IsDisabled: false,
      Description: '',
      ShouldBeAutoRedacted: false,
      PersonAssociation: '',
      QuestionTypeId: '',
      Type: undefined,
      Url: '',
      LinkDescription: '',
      TextQuestionTypeFormatId: null,
      DefaultValue: '',
    });
  }

  function resetState() {
    setLookupFields([]);
    setQuestionTemplateOptions([]);
    setPdfData(undefined);
    setLookupFieldName('');
    setUrl('');
  }

  const saveQuestionHandler = (questionDataParams: any, basicFeilds: any) => {
    saveQuestion(
      {
        ...basicFeilds,
        QuestionTemplateOptions:
          questionDataParams?.questionTemplateOptionsToSave,
        QuestionTypeOptions: questionDataParams?.questionTypeOptions,
        QuestionType: questionDetails?.QuestionType,
        Id: questionId,
        PersonAssociation:
          questionDataParams?.personAssociation === 2
            ? null
            : questionDataParams?.personAssociation,
      },
      {
        onSuccess: () => {
          showSuccessToast({
            message: 'Your changes were successfully saved.',
            autoClose: 3000,
          });
          togglePanel();
          resetState();
          reset({
            FieldName: '',
            FieldLabel: '',
            IsDisabled: false,
            Description: '',
            ShouldBeAutoRedacted: false,
            PersonAssociation: '',
            QuestionTypeId: null,
            Type: undefined,
            Url: '',
            LinkDescription: '',
            TextQuestionTypeFormatId: null,
            DefaultValue: '',
          });
        },
        onError: e => {
          showErrorToast({ message: e.Message, autoClose: 10000 });
        },
      },
    );
  };

  function onSubmit(data: AddEditQuestionType) {
    setSubmit(true);
    let questionDataParams = formatQuestionData(
      data,
      questionSpec,
      personAssociationOptions,
      questionId,
      questionDetails?.QuestionTypeOptions,
      lookupFields,
      questionTemplateOptions,
    );

    let defaultUrl = '';
    if (url.includes('http://')) {
      defaultUrl = url.replace('http://', '').trim();
    } else if (url.includes('https://')) {
      defaultUrl = url.replace('https://', '').trim();
    }
    if (
      questionDataParams.questionTypeOptions?.$type ===
      'Proteus2.Api.Domain.Surveys.QuestionTypes.PdfQuestionTypeOptions, Proteus2.Api.Domain'
    ) {
      questionDataParams = {
        ...questionDataParams,
        questionTypeOptions: {
          ...questionDataParams.questionTypeOptions,
          ...pdfData,
        },
      };
    }
    if (
      questionDataParams.questionTypeOptions?.$type ===
        'Proteus2.Api.Domain.Surveys.QuestionTypes.TextQuestionTypeOptions, Proteus2.Api.Domain' &&
      data.TextQuestionTypeFormatId === 7
    ) {
      questionDataParams = {
        ...questionDataParams,
        questionTypeOptions: {
          ...questionDataParams.questionTypeOptions,
          DefaultValue: defaultUrl.length ? url : '',
        },
      };
    }

    const basicFeilds = {
      Description: data?.Description,
      FieldLabel: data?.FieldLabel,
      FieldName: data?.FieldName,
      IsDisabled: data?.IsDisabled,
      QuestionTypeId: data?.QuestionTypeId,
      ShouldBeAutoRedacted: data?.ShouldBeAutoRedacted,
    };

    if (data.TextQuestionTypeFormatId === 7 && defaultUrl.length) {
      geUrlValidation().then(message => {
        if (message.StatusCode === 200) {
          saveQuestionHandler(questionDataParams, basicFeilds);
        }
      });
    } else {
      saveQuestionHandler(questionDataParams, basicFeilds);
    }
  }

  const handlePDF = (file?: File) => {
    if (file) {
      uploadPDF(
        { file },
        {
          onSuccess: (uploadResult: PdfDetails) => {
            setPdfData({ ...uploadResult });
          },
        },
      );
    }
  };

  const addQuestionActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          togglePanel();
          resetForm();
          resetState();
        }}
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        onClick={handleSubmit(onSubmit)}
        type="submit"
        disabled={isSaveQuestionLoading || isQuestionSpecLoading}
      >
        Save
      </Button>
    </>
  );

  const disablePersonAssociation = useCallback(() => {
    const disablePersonAssociationField = questionTypes?.find(
      item => item.id === questionTypeId,
    );
    return disablePersonAssociationField;
  }, [questionTypeId, questionTypes]);

  return (
    <SlidePanel
      title={`${mode === 'Edit' ? 'Edit Question' : 'Add New Question'}`}
      actions={addQuestionActions()}
      open={showPanel}
      onClose={() => {
        togglePanel();
        resetForm();
        resetState();
      }}
      size="md"
    >
      <Stack direction="y" gap={4}>
        <Checkbox
          label="Disable this question?"
          name="IsDisabled"
          register={register}
        />
        <FormInput
          label="Field Name"
          required
          name="FieldName"
          type="text"
          register={register}
          feedback={errors.FieldName?.message}
          validation={errors.FieldName ? 'error' : undefined}
          readOnly={
            mode === 'Edit' ? (superAdminPermission ? false : true) : false
          }
          className={
            mode === 'Edit' ? (superAdminPermission ? '' : 'falseDisabled') : ''
          }
        />
        <FormInput
          label=" Original Field Label"
          required
          name="FieldLabel"
          type="text"
          register={register}
          feedback={errors.FieldLabel?.message}
          validation={errors.FieldLabel ? 'error' : undefined}
        />
        <FormInput
          label="Description of Use"
          type="text"
          register={register}
          name="Description"
        />
        <Checkbox
          label="Should Auto-Redact"
          name="ShouldBeAutoRedacted"
          register={register}
        />

        <Controller
          name="QuestionTypeId"
          defaultValue={''}
          control={control}
          rules={{
            required: 'The Question Type is required.',
            validate: {
              invalid: value => {
                if (Number.isNaN(value))
                  return 'The Question Type field is required.';
              },
            },
          }}
          render={(props: {
            onChange: (value: number) => void;
            value: number;
          }) => {
            const value = questionTypes?.filter(
              item => item.id === props.value,
            );
            const selected = {
              label: value && value[0]?.name,
              value: value && value[0]?.id,
            };

            return (
              <div className="pr-select--question-type" style={{ zIndex: 100 }}>
                <FormReactSelect
                  value={
                    selected.label === undefined
                      ? { label: 'Select One', value: 'select one' }
                      : selected
                  }
                  label="Question Type"
                  feedback={errors.QuestionTypeId?.message}
                  validation={
                    errors.QuestionTypeId?.message ? 'error' : undefined
                  }
                  required
                  disabled={
                    questionId !== 0 || mode === 'Edit'
                      ? superAdminPermission
                        ? false
                        : true
                      : false
                  }
                  options={questionDes}
                  onChange={(e: any) => {
                    props.onChange(parseInt(e.value, 10));
                    setValue('PersonAssociation', 0, {
                      shouldDirty: false,
                    });
                  }}
                />
              </div>
            );
          }}
        />
        <Controller
          render={(props: {
            onChange: (value: number) => void;
            value: number;
          }) => (
            <FormSelect
              disabled={!disablePersonAssociation()?.IsQuestionAssociable}
              value={props.value}
              label="Person Association"
              noSelectOption
              options={personAssociationOptions}
              onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                props.onChange(parseInt(e.currentTarget.value, 10));
              }}
            />
          )}
          defaultValue={''}
          name="PersonAssociation"
          control={control}
        />

        {/* Question Type: Look up */}
        {questionTypeName === 'lookup' && (
          <LookupQuestion
            lookupFieldName={lookupFieldName}
            setLookupFieldName={setLookupFieldName}
            lookupFields={lookupFields}
            setLookupFields={setLookupFields}
          />
        )}

        {/* / Question Type: Link */}
        {questionTypeName === 'Link' && (
          <Box as="form" height="100%" mb="50px">
            <Stack direction="y" gap={3} mt={6}>
              <Stack direction="x" gap={5}>
                <Controller
                  render={(props: {
                    onChange: (value: number) => void;
                    value: number;
                  }) => {
                    return (
                      <RadioButton
                        onChange={() => {
                          props.onChange(0);
                        }}
                        inline
                        label="iFrame"
                        name="Type"
                        checked={type === 0}
                      />
                    );
                  }}
                  defaultValue={1}
                  name="Type"
                  control={control}
                />
                <Controller
                  render={(props: {
                    onChange: (value: number) => void;
                    value: number;
                  }) => {
                    return (
                      <RadioButton
                        onChange={() => {
                          props.onChange(1);
                        }}
                        inline
                        label="New Tab"
                        name="Type"
                        checked={type === 1}
                      />
                    );
                  }}
                  defaultValue={1}
                  name="Type"
                  control={control}
                />
              </Stack>
              {type === 0 && (
                <Alert variant="warning" icon={<Icon.Warning />}>
                  Make sure to test the form to ensure the iFrame works
                  properly.
                </Alert>
              )}
              <FormInput
                label="Link Description"
                type="text"
                name="LinkDescription"
                register={register}
              />
              <FormInput
                label="URL"
                type="text"
                name="Url"
                register={register}
              />
            </Stack>
          </Box>
        )}

        {/* Question Type: Text */}
        {questionTypeName === 'text' && (
          <Box as="form" height="100%" mb="50px">
            <Stack direction="y" gap={3} mt={6}>
              <Stack direction="x" gap={5}>
                <Controller
                  render={(props: {
                    onChange: (value: number) => void;
                    value: number;
                  }) => {
                    return (
                      <RadioButton
                        onChange={() => {
                          props.onChange(0);
                          reset({
                            ...getValues(),
                            DefaultValue: '',
                            TextQuestionTypeFormatId: null,
                          });
                        }}
                        inline
                        label="Text"
                        name="Type"
                        checked={type === 0}
                      />
                    );
                  }}
                  defaultValue={1}
                  name="Type"
                  control={control}
                />
                <Controller
                  render={(props: {
                    onChange: (value: number) => void;
                    value: number;
                  }) => {
                    return (
                      <RadioButton
                        onChange={() => {
                          props.onChange(1);
                          reset({
                            ...getValues(),
                            DefaultValue: '',
                            TextQuestionTypeFormatId: null,
                          });
                        }}
                        inline
                        label="Memo"
                        name="Type"
                        checked={type === 1}
                      />
                    );
                  }}
                  defaultValue={1}
                  name="Type"
                  control={control}
                />
              </Stack>
              {type === 0 && (
                <FormattedDefaultValueInputs
                  setUrl={setUrl}
                  setSubmit={setSubmit}
                  textQuestionTypeFormatId={textQuestionTypeFormatId}
                  errorMessage={errorMessage}
                  textFormat={textFormat}
                  control={control}
                  errors={errors}
                  register={register}
                  getValidations={getValidations}
                  reset={reset}
                  getValues={getValues}
                  urlPrefix={urlPrefix}
                  urlPrefixOptions={urlPrefixOptions}
                  setUrlPrefix={setUrlPrefix}
                  url={url}
                />
              )}
              {type === 1 && (
                <FormTextarea
                  label="Default Value"
                  register={register}
                  name="DefaultValue"
                  resize="vertical"
                />
              )}
            </Stack>
          </Box>
        )}

        {/* Question Type: Choice */}
        {questionTypeName === 'Choice' && (
          <ChoiceQuestion
            questionTemplateOptions={questionTemplateOptions}
            setQuestionTemplateOptions={setQuestionTemplateOptions}
            Controller={Controller}
            control={control}
            type={type}
            mode={mode}
          />
        )}

        {/* Question Type: PDF */}
        {questionTypeName === 'PDF' && (
          <PDFQuestion
            updateField={pdfDetails => {
              setPdfData({ ...pdfDetails });
            }}
            handlePDF={handlePDF}
            pdfData={pdfData}
          />
        )}
      </Stack>
      {isLoading && <Loader />}
    </SlidePanel>
  );
}

function FormattedDefaultValueInputs({
  textQuestionTypeFormatId,
  setUrl,
  setSubmit,
  textFormat,
  control,
  errors,
  register,
  getValidations,
  reset,
  getValues,
  errorMessage,
  urlPrefix,
  urlPrefixOptions,
  setUrlPrefix,
  url,
}: {
  textQuestionTypeFormatId: number;
  textFormat?: Array<{
    id: number;
    name: string;
  }>;
  control: any;
  errors: any;
  register: any;
  getValidations: (id: number) => any;
  reset: any;
  getValues: any;
  urlPrefixOptions?: Array<{
    id: number;
    name: string;
  }>;
  setUrl: any;
  setSubmit: any;
  setUrlPrefix: any;
  errorMessage: string;
  urlPrefix: number;
  url: any;
}) {
  const removePrefix = () => {
    let value = '';
    if (url.includes('http') || url.includes('https')) {
      if (url.includes('http')) {
        value = url.replace('http://', '').trim();
        value = value.replace('https://', '').trim();
      } else if (url.includes('https')) {
        value = url.replace('https://', '').trim();
        value = value.replace('http://', '').trim();
      }
    } else {
      value = url;
    }
    return value;
  };
  return (
    <>
      <Controller
        render={(props: {
          onChange: (value: number) => void;
          value: number;
        }) => {
          return (
            <FormSelect
              value={props.value}
              label="Text Format"
              noSelectOption
              options={textFormat}
              onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                const value = parseInt(e.currentTarget.value, 10);

                props.onChange(value);
                reset({
                  ...getValues(),
                  DefaultValue: '',
                });
              }}
            />
          );
        }}
        defaultValue={''}
        name="TextQuestionTypeFormatId"
        control={control}
      />
      {textQuestionTypeFormatId === null || textQuestionTypeFormatId === 1 ? (
        <FormInput
          label="Default Value"
          register={register}
          name="DefaultValue"
          placeholder="None"
          validate={{
            pattern: {
              value: getValidations(textQuestionTypeFormatId),
              message: 'Enter a valid default value for text.',
            },
          }}
          validation={errors?.DefaultValue && 'error'}
          feedback={errors?.DefaultValue?.message}
        />
      ) : null}

      {textQuestionTypeFormatId === 2 && (
        <Controller
          render={(props: {
            onChange: (value: string) => void;
            value: string;
          }) => {
            return (
              <FormattedCustomInput
                label="Default Value"
                placeholder="Telephone (US)"
                value={props.value}
                onChange={(formattedValue: string, raw: string) => {
                  props.onChange(formattedValue);
                }}
                format={phonePattern}
                validation={errors?.DefaultValue && 'error'}
                feedback={errors?.DefaultValue?.message}
              />
            );
          }}
          defaultValue={''}
          name="DefaultValue"
          control={control}
          rules={{
            pattern: {
              value: getValidations(textQuestionTypeFormatId),
              message: 'Enter a valid Telephone (US) number.',
            },
          }}
        />
      )}
      {textQuestionTypeFormatId === 3 && (
        <Controller
          render={(props: {
            onChange: (value: string) => void;
            value: string;
          }) => {
            return (
              <FormattedCustomInput
                label="Default Value"
                placeholder="Numbers Only"
                format={numberOnlyPattern}
                value={props.value}
                onChange={(formattedValue: string, raw: string) => {
                  props.onChange(formattedValue);
                }}
                validation={errors?.DefaultValue && 'error'}
                feedback={errors?.DefaultValue?.message}
              />
            );
          }}
          defaultValue={''}
          name="DefaultValue"
          control={control}
          rules={{
            pattern: {
              value: getValidations(textQuestionTypeFormatId),
              message: 'Enter a valid Number.',
            },
          }}
        />
      )}
      {textQuestionTypeFormatId === 4 && (
        <Controller
          render={(props: {
            onChange: (value: string) => void;
            value: string;
          }) => {
            return (
              <FormattedCustomInput
                label="Default Value"
                placeholder="SSN"
                value={props.value}
                format={ssnPattern}
                onChange={(formattedValue: string, raw: string) => {
                  props.onChange(formattedValue);
                }}
                validation={errors?.DefaultValue && 'error'}
                feedback={errors?.DefaultValue?.message}
              />
            );
          }}
          defaultValue={''}
          name="DefaultValue"
          control={control}
          rules={{
            pattern: {
              value: getValidations(textQuestionTypeFormatId),
              message: 'Enter a valid SSN.',
            },
          }}
        />
      )}
      {textQuestionTypeFormatId === 5 && (
        <Controller
          render={(props: {
            onChange: (value: string) => void;
            value: string;
          }) => {
            return (
              <FormattedCustomInput
                label="Default Value"
                placeholder="Military time"
                value={props.value}
                format={militaryTimePattern}
                onChange={(formattedValue: string, raw: string) => {
                  const x = raw.split('');
                  if (x.length === 2) {
                    if (parseInt(x[0]) === 2) {
                      if (parseInt(x[1]) <= 3) {
                        props.onChange(formattedValue);
                      } else {
                        props.onChange(formattedValue.replace(x[1], ' '));
                      }
                    }
                  } else props.onChange(formattedValue);
                }}
                validation={errors?.DefaultValue && 'error'}
                feedback={errors?.DefaultValue?.message}
              />
            );
          }}
          defaultValue={''}
          name="DefaultValue"
          control={control}
          rules={{
            pattern: {
              value: getValidations(textQuestionTypeFormatId),
              message: 'Enter a valid Time',
            },
          }}
        />
      )}
      {textQuestionTypeFormatId === 6 && (
        <FormInput
          label="Default Value"
          register={register}
          name="DefaultValue"
          placeholder="Email"
          validate={{
            pattern: {
              value: getValidations(textQuestionTypeFormatId),
              message: 'Enter a valid email.',
            },
          }}
          validation={errors?.DefaultValue && 'error'}
          feedback={errors?.DefaultValue?.message}
        />
      )}
      {textQuestionTypeFormatId === 7 && (
        <>
          <Stack direction="x" gap={1}>
            <FormSelect
              value={urlPrefix}
              label="URL Prefix"
              noSelectOption
              options={urlPrefixOptions}
              onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                setUrlPrefix(JSON.parse(e.currentTarget.value));
                let value = '';
                value = removePrefix();
                if (JSON.parse(e.currentTarget.value) === 2) {
                  const link = `https://${value}`;
                  setUrl(link);
                } else {
                  const link = `http://${value}`;
                  setUrl(link);
                }
              }}
            />
            <FormInput
              style={{ flexGrow: 1 }}
              label="Default Value"
              value={removePrefix()}
              register={register}
              name="DefaultValue"
              placeholder="URL"
              onTextChange={(text: string) => {
                if (text.includes('http') || text.includes('https')) {
                  setUrl(text);
                  if (text.includes('http://')) {
                    setUrlPrefix(1);
                  } else if (text.includes('https://')) {
                    setUrlPrefix(2);
                  }
                } else {
                  if (urlPrefix === 2) {
                    const link = `https://${text}`;
                    setUrl(link);
                  } else {
                    const link = `http://${text}`;
                    setUrl(link);
                  }
                }
                setSubmit(false);
              }}
              // prefix={'https://'}
              validation={
                errorMessage && errorMessage === 'valid'
                  ? 'success'
                  : errorMessage === 'invalid'
                  ? 'error'
                  : 'success'
              }
              feedback={
                errorMessage === 'valid'
                  ? ''
                  : errorMessage === 'invalid'
                  ? 'Enter a valid URL.'
                  : ''
              }
            />
          </Stack>
        </>
      )}
    </>
  );
}
