import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass/styled-components';
import { confirmAlert } from 'utils/confirm-alert';
import {
  Accordion,
  Card,
  CardHeader,
  CardBody,
  Loader,
  SlidePanel,
} from 'ui/patterns';
import { Button } from 'ui/components';
import { Version, CurrentVersion, Timestamp, Editor } from './styles';
import {
  useVersionHistory,
  useGetProcedureVersionContent,
  useRestoreVersions,
} from 'hooks';
import { showErrorToast, showSuccessToast } from 'utils/showToast';
import { ProcedureInfoType } from 'types/procedureBuilder';
import jstz from 'jstz';
import moment from 'moment-timezone';

type VersionType = {
  CreatedBy: string;
  CreatedOn: string;
  Id: number;
  ProcedureId: number;
  Version: number;
};
type Result = {
  CreatedBy: string;
  CreatedOn: string;
  Id: number;
  ProcedureId: number;
  subArray: Array<Result>;
  timeValue: string;
  Version: number;
};

export default function VersionHistory({
  showPanel,
  togglePanel: toggle,
  procedureId,
}: {
  showPanel: boolean;
  togglePanel: () => void;
  procedureId: number;
}): ReactElement {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(showPanel);
    }, 10);
  }, [showPanel]);

  function togglePanel() {
    setIsVisible(false);
    setTimeout(() => {
      toggle();
    }, 500);
  }

  const params = useParams<{
    accountId: string;
    tabId?: string;
  }>();

  const [selectedVersion, setSelectedVersion] = useState<VersionType>();
  const [expandVersions, setExpandVersions] = useState<Record<number, boolean>>(
    {},
  );

  const accountId = parseInt(params.accountId);
  const { data: accountProcedure, isLoading: isAccountProcedureLoading } =
    useVersionHistory(accountId, procedureId);

  const { data: versionContent, isLoading: isVersionContentLoading } =
    useGetProcedureVersionContent(
      accountId,
      procedureId,
      selectedVersion?.Version,
    );

  const [restoreVersions, { isLoading: isRestoreVersionLoading }] =
    useRestoreVersions();

  const isLoading =
    isAccountProcedureLoading ||
    isVersionContentLoading ||
    isRestoreVersionLoading;

  useEffect(() => {
    if (accountProcedure) {
      const versions = accountProcedure.AccountProcedure?.Procedure?.Versions;
      const sorted =
        versions &&
        versions.length > 0 &&
        versions
          .sort(
            (a: { Version: number }, b: { Version: number }) =>
              a.Version - b.Version,
          )
          .reverse();
      if (sorted) setSelectedVersion(sorted[0]);
    }
  }, [accountProcedure]);

  const versionList = useMemo(() => {
    if (accountProcedure) {
      return accountProcedure?.AccountProcedure?.Procedure?.Versions.sort(
        (a: { Version: number }, b: { Version: number }) =>
          a.Version - b.Version,
      ).reverse();
    }
  }, [accountProcedure]);

  const createMarkup = () => {
    return { __html: versionContent?.Content || '' };
  };

  function handleRestoreVersion() {
    confirmAlert({
      message: 'Are you sure you want to restore this as current version?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            restoreVersions(
              {
                accountId: accountId,
                fileId:
                  versionContent?.AccountProcedure?.Procedure?.FileId || '',
                version: selectedVersion?.Version || 0,
              },
              {
                onSuccess: (mutationResult: ProcedureInfoType) => {
                  if (mutationResult) {
                    showSuccessToast({
                      message: 'Your changes were saved successfully.',
                    });
                    togglePanel && togglePanel();
                  }
                },
                onError: (error: { Message: string }) => {
                  showErrorToast({
                    message: `${error.Message} Please try again later.`,
                  });
                },
              },
            );
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  }

  function isRestoreDisabled() {
    if (
      accountProcedure?.AccountProcedure?.Procedure?.Name ===
      '0000_POLICIESMENU.html'
    )
      return true;
    if (!selectedVersion) return true;
    if (!versionList) return true;
    if (versionList[0].Version === selectedVersion.Version) return true;
    return false;
  }

  const panelActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          togglePanel && togglePanel();
          // reset();
        }}
      >
        Cancel
      </Button>
      <Button
        disabled={isRestoreDisabled()}
        onClick={handleRestoreVersion}
        variant="primary"
      >
        {'Restore this version'}
      </Button>
    </>
  );

  //time manipulation for displaying
  const timeStampedArray = versionList?.map(item => {
    const timezone = jstz.determine();
    const ts = moment(item.CreatedOn + 'Z');
    const x = ts.tz(timezone.name());
    const timeValue = x.format('YYYY MMM, DD ddd hh:mm A z');
    const arr: any = [];
    return { ...item, timeValue, subArray: arr };
  });

  //final grouping - based on timestamps and users
  const final = useMemo(() => {
    const resultArray: Array<Result> = [];
    if (timeStampedArray?.length) {
      let baseTime = timeStampedArray[0]?.CreatedOn;
      resultArray.push(timeStampedArray[0]);
      resultArray[0].subArray = [];
      for (let i = 1, j = 0; i < timeStampedArray.length; i++) {
        if (baseTime !== null) {
          const duration = Math.abs(
            new Date(timeStampedArray[i].CreatedOn).valueOf() -
              new Date(baseTime).valueOf(),
          );
          const result = Math.floor(duration / 1000 / 60);

          if (
            result < 60 &&
            timeStampedArray[i].CreatedBy === timeStampedArray[i - 1].CreatedBy
          ) {
            resultArray[j].subArray.push(timeStampedArray[i]);
          } else {
            resultArray.push(timeStampedArray[i]);
            j++;
            baseTime = timeStampedArray[i].CreatedOn;
          }
        }
      }
      return resultArray;
    }
  }, [timeStampedArray]);

  function expandGroupedVersions(id: number) {
    const expandedState = { ...expandVersions };
    expandedState[id] = expandedState[id] ? false : true;
    setExpandVersions({ ...expandedState });
  }
  return (
    <SlidePanel
      open={isVisible}
      onClose={() => {
        togglePanel && togglePanel();
      }}
      size="lg"
      title="Version History"
      actions={panelActions()}
    >
      <Flex height="100%" flex="1 1 auto">
        <Card width="400px" flexDirection="column" style={{ display: 'flex' }}>
          <CardHeader
            p={3}
            bg="var(--color-neutral-contrast-02)"
            alignItems="center"
            style={{ display: 'flex' }}
          >
            <Text as="h4" fontWeight="medium" lineHeight={1} color="text.body">
              {accountProcedure &&
                accountProcedure?.AccountProcedure?.Procedure?.Name}
            </Text>
          </CardHeader>
          <CardBody
            flex="1 0 auto"
            overflowY="auto"
            overflowX="hidden"
            height="1px"
          >
            {final &&
              final.map((item: Result, index: number) => {
                return item?.subArray?.length > 0 ? (
                  <Accordion
                    key={item.Id}
                    toggleButtonVariant="subtle"
                    className="ab-version-accordion"
                    headerContent={
                      <Version
                        key={item.Id}
                        onClick={() => {
                          setSelectedVersion(item);
                        }}
                        active={item.Id === selectedVersion?.Id}
                      >
                        <Box>
                          <Timestamp>{item?.timeValue}</Timestamp>
                          <Editor
                            className={index === 0 ? 'pr-current-version' : ''}
                          >
                            {item.CreatedBy === 'Auto Generated'
                              ? ' Edited -'
                              : 'Edited by'}{' '}
                            <span title={index === 0 ? item.CreatedBy : ''}>
                              {item.CreatedBy}
                            </span>
                          </Editor>
                        </Box>
                        {index === 0 && (
                          <CurrentVersion
                            className={index === 0 ? 'pr-current-version' : ''}
                          >
                            Current Published Version
                          </CurrentVersion>
                        )}
                      </Version>
                    }
                    setExpanded={() => expandGroupedVersions(item.Id)}
                    opened={expandVersions && expandVersions[item.Id]}
                  >
                    {item?.subArray?.map((subItem: Result) => {
                      return (
                        <Version
                          key={subItem.Id}
                          onClick={() => {
                            setSelectedVersion(subItem);
                          }}
                          active={subItem.Id === selectedVersion?.Id}
                        >
                          <Box>
                            <Timestamp>{subItem.timeValue}</Timestamp>
                            <Editor>
                              {subItem.CreatedBy === 'Auto Generated'
                                ? ' Edited -'
                                : 'Edited by'}{' '}
                              <span title={index === 0 ? item.CreatedBy : ''}>
                                {subItem.CreatedBy}
                              </span>
                            </Editor>
                          </Box>
                        </Version>
                      );
                    })}
                  </Accordion>
                ) : (
                  <Version
                    onClick={() => {
                      setSelectedVersion(item);
                    }}
                    active={item.Id === selectedVersion?.Id}
                  >
                    <Box>
                      <Timestamp>{item.timeValue}</Timestamp>
                      <Editor
                        className={index === 0 ? 'pr-current-version' : ''}
                      >
                        {item.CreatedBy === 'Auto Generated'
                          ? ' Edited -'
                          : 'Edited by'}{' '}
                        <span>{item.CreatedBy}</span>
                      </Editor>
                    </Box>
                    {index === 0 && (
                      <CurrentVersion
                        className={index === 0 ? 'pr-current-version' : ''}
                      >
                        Current Published Version
                      </CurrentVersion>
                    )}
                  </Version>
                );
              })}
          </CardBody>
        </Card>
        <Card
          flex="1"
          p={3}
          ml={3}
          position="relative"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Flex flex="1 0 auto" height="1px" overflow="auto">
            <div dangerouslySetInnerHTML={createMarkup()} />
          </Flex>
        </Card>
      </Flex>
      {isLoading && <Loader />}
    </SlidePanel>
  );
}
