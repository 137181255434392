import React, { ReactElement, useState, useCallback } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Table, SearchInput, Pagination } from 'ui/patterns';
import { Button, Stack } from 'ui/components';
import * as Icon from 'assets/icons';
import AddEditContact from '../AddEditContact';
import useContactColumns from './useContactColumns';
import { useContacts } from 'hooks';

const pageLength = 100;
const sortItem = { sortKey: 'Name', sortOrder: 'asc' };

export default function ContactList(): ReactElement {
  const history = useHistory<{ ID: number }>();
  const [showPanel, setShowPanel] = useState(false);
  const [contactId, setContactId] = useState(0);
  const [columnsSearch, setColumnsSearch] = useState({}); // table header search
  const [currentSortItem, setCurrentSortItem] = useState(sortItem);
  const [keywordSearch, setKeywordSearch] = useState(''); //keyword search
  const [mode, setMode] = useState('');

  const togglePanel = () => {
    setShowPanel(false);
    setContactId(0);
  };

  const [page, setPage] = useState(0);
  let numberOfPages = 0;
  const params = useParams<{
    accountId: string;
    tabId?: string;
  }>();
  const accountId = parseInt(params.accountId);

  const {
    resolvedData: data,
    latestData,
    isLoading,
    isFetching,
  } = useContacts(
    page,
    columnsSearch,
    currentSortItem,
    keywordSearch,
    accountId,
  );

  if (data && data.recordsFiltered) {
    numberOfPages = Math.floor(data.recordsFiltered / pageLength);
  }
  const setPageNumber = useCallback(
    (newPage: number) => {
      if (newPage >= 0 && newPage <= numberOfPages) setPage(newPage);
    },
    [setPage, numberOfPages],
  );

  const columns = useContactColumns();

  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch],
  );

  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (
        currentSortItem.sortKey === item &&
        currentSortItem.sortOrder === 'asc'
      ) {
        sortOrder = 'desc';
      }
      setCurrentSortItem({
        sortKey: item,
        sortOrder,
      });
    },
    [setCurrentSortItem, currentSortItem],
  );

  const handleKeywordSearch = (value: string): void => {
    setKeywordSearch(value);
  };

  const handleReset = (): void => {
    setKeywordSearch('');
    setColumnsSearch({});
    setCurrentSortItem(sortItem);
  };

  const handleEditContact = (row: any): void => {
    setContactId(row.original.Id);
    setShowPanel(true);
    setMode('Edit');
  };

  const onEditPress = (id: number): void => {
    setContactId(id);
    setShowPanel(true);
    setMode('Edit');
  };

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text fontSize={1} color="text.body" sx={{ height: '20px' }}>
          {data &&
            data.recordsTotal !== 0 &&
            !isFetching &&
            `Showing ${pageLength * page + 1} to ${
              pageLength * page + (latestData?.data?.length || 0)
            } of ${data?.recordsFiltered}`}
          {isFetching && 'Processing'}
        </Text>
        <Stack direction="x" gap={2}>
          <SearchInput
            placeholder="Search Contacts"
            handleChange={handleKeywordSearch}
            value={keywordSearch}
          />
          <Button
            iconBefore={<Icon.Refresh title="" ariaHidden="true" />}
            variant="secondary"
            onClick={handleReset}
          >
            Reset All
          </Button>
          <Button
            iconBefore={<Icon.Add title="" ariaHidden="true" />}
            variant="primary"
            onClick={() => {
              setContactId(0);
              setShowPanel(true);
              setMode('Add');
            }}
          >
            Add New Contact
          </Button>
        </Stack>
      </Flex>
      <Table
        columns={columns}
        data={data ? data.data : undefined}
        isLoading={isLoading || isFetching}
        headerUpdateCallBack={headerUpdateCallBack}
        handleSortChange={handleSortChange}
        sortedItem={currentSortItem}
        columnsSearch={columnsSearch}
        onRowDoubleClick={handleEditContact}
        onEditPress={onEditPress}
        alignVertical={true}
      />

      <Flex mt={4} justifyContent="flex-end">
        <Pagination
          page={page}
          numberOfPages={numberOfPages}
          onClick={setPageNumber}
        />
      </Flex>
      <AddEditContact
        showPanel={showPanel}
        togglePanel={togglePanel}
        contactId={contactId}
        accountId={accountId}
        mode={mode}
      />
    </>
  );
}
