import React, { ReactElement, useEffect, useState } from 'react';
import { Box } from 'rebass/styled-components';
import { SlidePanel, EmptyState, Card, FormInput } from 'ui/patterns';
import { Tree as TreeNew } from 'ui/patterns/tree';
import { Button, Dropzone, Stack } from 'ui/components';
import NoDataImage from 'assets/images/NoDataImage';
import * as Icon from 'assets/icons';
import { Backdrop, CloseButton } from './styles';
import { useGetFileTree } from 'hooks';
import { BlobFileEntity } from 'types/fileTypes';
import { showErrorToast, showSuccessToast } from 'utils/showToast';
import { CreateFolderParams } from 'hooks/useCreateFolder';
import { UploadFileParams } from 'hooks/useUploadFile';
import { DeleteFileParams } from 'hooks/useDeleteFile';
import { MutateFunction } from 'react-query';
import { apiBaseUrl } from 'utils/urls';
import { isValidFile, getEncodedFilePath, accept } from 'utils/fileUtils';
import { confirmAlert } from 'utils/confirm-alert';

export type TError = { message: string };

type FileImageProps = {
  showPanel: boolean;
  togglePanel: () => void;
  account: string;
  createFolder: MutateFunction<string, TError, CreateFolderParams, unknown>;
  uploadFile: MutateFunction<string, TError, UploadFileParams, unknown>;
  deleteFile: MutateFunction<string, TError, DeleteFileParams, unknown>;
  addFileToEditor: (folder: BlobFileEntity) => void;
  buttonText?: string;
  filesOnly?: boolean;
};

const toBase64 = (file: File): any => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

function FileImage({
  showPanel,
  togglePanel: toggle,
  account,
  createFolder,
  uploadFile,
  deleteFile,
  addFileToEditor,
  buttonText,
  filesOnly,
}: FileImageProps): ReactElement {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(showPanel);
    }, 10);
  }, [showPanel]);

  function togglePanel() {
    setIsVisible(false);
    setTimeout(() => {
      toggle();
    }, 500);
  }

  const [showAddFolder, setShowAddFolder] = useState<BlobFileEntity>();
  const toggleAddFolder = (folder?: BlobFileEntity) => {
    setShowAddFolder(folder);
  };
  const [showFeedback, setShowFeedback] = useState(false);

  const [showFileUpload, setShowFileUpload] = useState<BlobFileEntity>();
  const toggleFileUpload = (folder?: BlobFileEntity) => {
    setShowFileUpload(folder);
  };
  const { data: fileTree, isLoading: isFilesLoading } = useGetFileTree(account);

  const [selectedFile, setSelectedFile] = useState<BlobFileEntity>();
  const [folderName, setFolderName] = useState<string>('');
  const [fileToUpload, setFileToUpload] = useState<File>();

  function reset() {
    setFolderName('');
    setFileToUpload(undefined);
    setShowFileUpload(undefined);
    setSelectedFile(undefined);
    setShowAddFolder(undefined);
  }

  function onFileChange(file?: File): void {
    // console.log('FILE:', file);
    if (!file) {
      setSelectedFile(undefined);
      setFileToUpload(file);
      return;
    }
    const regex = /^[a-zA-Z0-9\s_\\.\-\(\)~'@$;,!=:\[\]]+$/;
    if (!regex.test(file.name)) {
      // console.log('!regex.test(file.name):', file.name);
      showErrorToast({
        message: 'Please choose a valid file to upload',
      });
      return;
    } else {
      const fileModules = file.name.split('.');
      const fileExtension = fileModules[fileModules.length - 1];
      // console.log('fileModules', fileModules, fileExtension);
      if (isValidFile(fileExtension)) {
        // console.log('isValidFile(fileExtension)', isValidFile(fileExtension));
        setSelectedFile(undefined);
        setFileToUpload(file);
      } else {
        // console.log('isValidFile(fileExtension)', isValidFile(fileExtension));
        setSelectedFile(undefined);
        showErrorToast({
          message: 'Please choose a valid file to upload',
        });
      }
    }
  }

  const fileimageActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          togglePanel();
          reset();
        }}
      >
        Cancel
      </Button>
      <Button
        disabled={(() => {
          if (filesOnly) {
            if (selectedFile && selectedFile.FileType !== 'folder') {
              return false;
            } else return true;
          } else return !selectedFile;
        })()}
        onClick={() => {
          if (selectedFile) {
            addFileToEditor(selectedFile);
            setFolderName('');
            togglePanel();
          }
        }}
        variant="primary"
      >
        {buttonText || 'Add to procedure'}
      </Button>
    </>
  );

  return (
    <SlidePanel
      open={isVisible}
      onClose={() => {
        togglePanel();
        reset();
      }}
      size="lg"
      title="Select Files"
      actions={fileimageActions()}
    >
      <Stack direction="x" gap={3} height="100%">
        <Card p={3} overflow="hidden auto" width="350px" position="relative">
          <TreeNew
            isHidden={!!showAddFolder || !!showFileUpload}
            account={account}
            setSelectedFile={setSelectedFile}
            files={fileTree}
            selectedFile={selectedFile}
            toggleAddFolder={toggleAddFolder}
            toggleFileUpload={toggleFileUpload}
            deleteFile={(file: BlobFileEntity) => {
              deleteFile(
                {
                  Container: 'root',
                  Title:
                    file.FileType === 'folder'
                      ? `${file?.FilePath}do-not-delete-this-file.txt`
                      : file.FilePath,
                },
                {
                  onSuccess: () => {
                    showSuccessToast({
                      message:
                        file.FileType === 'folder'
                          ? 'Folder deleted successfully'
                          : 'File deleted successfully',
                    });
                    setSelectedFile(undefined);
                  },
                },
              );
            }}
          />

          {/* Add Folder */}
          <Backdrop show={!!showAddFolder}>
            <CloseButton
              onClick={() => {
                setFolderName('');
                toggleAddFolder(undefined);
                setShowFeedback(false);
              }}
            >
              <Icon.X />
            </CloseButton>
            <Box
              sx={{
                display: 'grid',
                gridGap: 3,
                gridTemplateColumns: '1fr minmax(50px, 15%)',
                alignItems: 'start',
                width: '300px',
              }}
            >
              <FormInput
                onTextChange={setFolderName}
                value={folderName}
                label="Enter folder name"
                type="text"
                feedback={(() => {
                  if (showFeedback) {
                    const folderNameAlreadyExists =
                      showAddFolder?.BlobFile?.find(
                        item =>
                          item.FileType === 'folder' &&
                          item.FileName === folderName,
                      );
                    if (folderNameAlreadyExists) {
                      return 'Folder name is already exist, Please try with another name';
                    }
                  }
                  return '';
                })()}
              />
              <Button
                variant="primary"
                style={{ height: '35px', marginTop: '20px' }}
                title="Add new folder"
                iconBefore={<Icon.Check title="Add new folder" />}
                disabled={(() => {
                  if (showFeedback) {
                    const folderNameAlreadyExists =
                      showAddFolder?.BlobFile?.find(
                        item =>
                          item.FileType === 'folder' &&
                          item.FileName === folderName,
                      );
                    if (folderNameAlreadyExists) {
                      return true;
                    }
                  }
                  return false;
                })()}
                onClick={() => {
                  if (showAddFolder && folderName) {
                    const folderNameAlreadyExists =
                      showAddFolder?.BlobFile?.find(
                        item =>
                          item.FileType === 'folder' &&
                          item.FileName === folderName,
                      );
                    if (folderNameAlreadyExists) {
                      setShowFeedback(true);
                      return;
                    }
                    createFolder(
                      {
                        Title: `${showAddFolder?.FilePath}${folderName}/do-not-delete-this-file.txt`,
                        Content: `${showAddFolder?.FilePath}${folderName}`,
                        Container: `root`,
                        streamContent: '',
                      },
                      {
                        onSuccess: () => {
                          showSuccessToast({
                            message: 'Folder created successfully',
                          });
                          toggleAddFolder(undefined);
                          setFolderName('');
                          setShowFeedback(false);
                        },
                      },
                    );
                  }
                }}
              />
            </Box>
          </Backdrop>

          {/* Upload File */}
          <Backdrop show={!!showFileUpload}>
            <CloseButton onClick={() => toggleFileUpload(undefined)}>
              <Icon.X />
            </CloseButton>
            <Stack direction="y" gap={3} height="100%">
              <Dropzone
                accept={accept}
                onChange={onFileChange}
                value={fileToUpload?.name}
                flex={1}
                info="Special characters not allowed in fileName(+, #, {, }, `, %, &, /, *, ^)"
              />
              <Button
                variant="primary"
                onClick={async () => {
                  if (fileToUpload) {
                    const base64 = await toBase64(fileToUpload);
                    uploadFile(
                      {
                        Title: `${showFileUpload?.FilePath}${fileToUpload.name}`,
                        Container: `root`,
                        Content: `C:\\fakepath\\${showFileUpload?.FilePath}`,
                        streamContent: base64,
                      },
                      {
                        onSuccess: () => {
                          showSuccessToast({
                            message: 'File uploaded successfully',
                          });
                          toggleAddFolder(undefined);
                          setShowFeedback(false);
                          setFolderName('');
                          toggleFileUpload();
                          setFileToUpload(undefined);
                        },
                        onError: error => {
                          showErrorToast({
                            message: error.message,
                          });
                        },
                      },
                    );
                    // toggleFileUpload();
                  }
                }}
              >
                Add file
              </Button>
            </Stack>
          </Backdrop>
        </Card>
        <Card
          flex="1"
          position="relative"
          bg="var(--color-neutral-contrast-02)"
        >
          <EmptyState>
            {!selectedFile ? (
              <NoDataImage feedback="Please select a file to preview" />
            ) : null}
            {selectedFile && selectedFile.FileType === 'image' ? (
              <img src={selectedFile.FileUri} />
            ) : null}
            {selectedFile &&
            selectedFile.FileType !== 'image' &&
            selectedFile.FileType !== 'folder' ? (
              <iframe
                src={`${apiBaseUrl}${'procedures/'}${getEncodedFilePath(
                  selectedFile.FileUri,
                )}/`}
                style={{ width: '100%', height: '100%' }}
              ></iframe>
            ) : null}
          </EmptyState>
          {/* <EmptyState>
            {!selectedFile || selectedFile.FileType !== 'image' ? (
              <NoDataImage feedback="Please select a file to preview" />
            ) : null}
            {selectedFile && selectedFile.FileType === 'image' ? (
              <img
                // src={`https://proteus2dev.blob.core.windows.net/root/${selectedFile.FilePath}`}
                src={selectedFile.FileUri}
              />
            ) : null}
          </EmptyState> */}
        </Card>
      </Stack>
    </SlidePanel>
  );
}

export default FileImage;
