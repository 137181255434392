import React, { ReactElement } from 'react';
import { confirmAlert } from 'utils/confirm-alert';
import { Dropdown, Loader } from 'ui/patterns';
import { Button } from 'ui/components';
import { ColumnInstance, Row } from 'react-table';
import * as Icon from 'assets/icons';
import { Questions } from 'types/questions';
import { useDeleteQuestions } from 'hooks';
import { showSuccessToast, showErrorToast } from 'utils/showToast';

export default function TableHeader(props: {
  column: ColumnInstance<Questions>;
  row: Row<Questions>;
  onEditPress?: (questionId: number) => void;
}): ReactElement {
  const questionId = props.row.original.Id;
  const [deleteQuestions, { isLoading }] = useDeleteQuestions();

  const questionListOptions = [
    {
      value: 'Edit Question',
      icon: <Icon.Edit title="" ariaHidden="true" />,
      groupEnd: true,
      action: () => props.onEditPress && props.onEditPress(questionId),
    },
    {
      value: 'Delete Question',
      icon: <Icon.Delete title="" ariaHidden="true" />,
      action: () => handleDeleteQuestion(),
    },
  ];

  const handleDeleteQuestion = () => {
    confirmAlert({
      // title: 'Proteus',
      message: 'Are you sure you want to delete this question?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteQuestions(questionId, {
              onSuccess: () => {
                showSuccessToast({
                  message: 'Question has been deleted successfully.',
                });
              },
              onError: () => {
                showErrorToast({
                  message: 'This question is already used in a form',
                });
              },
            });
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  const questionListDropdown = () => (
    <Button size="sm">
      <Icon.DotsVertical title="Options" />
    </Button>
  );

  return (
    <>
      <Dropdown
        trigger={questionListDropdown()}
        menuList={questionListOptions}
        position="left-top"
        className="dd-listitem-options"
      />
      {isLoading && <Loader />}
    </>
  );
}
