import { useQuery, QueryResult } from 'react-query';
import { getAccount } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { AccountFormInputs, AccountInfoReturn } from 'types/accounts';

async function fetchAccountInfo({ id }: TVariables): Promise<APIResult> {
  if (!id)
    return new Promise((resolve, reject) => {
      reject();
    });
  const response = await fetch(getAccount(id), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type APIResult = AccountInfoReturn;
export type TResult =
  | {
      accountInfoReturn: AccountInfoReturn;
      accountFormInputs: AccountFormInputs;
    }
  | undefined;

export type TError = { message: string };
export type TVariables = {
  id: number | string;
};

function useGetAccountInfo(id?: number | string): QueryResult<TResult, TError> {
  return useQuery(
    ['get_account', id],
    async () => {
      if (!id) return;
      const fetchResponse = await fetchAccountInfo({ id });
      // const result = JSON.parse(fetchResponse['Accounts-Grid-Coloumns']);
      const result: AccountFormInputs = {
        AccountServiceId: fetchResponse.AccountServiceId,
        AnswerScript: fetchResponse.AnswerScript,
        AnswerScriptDetailType: fetchResponse.AnswerScriptDetailType,
        AnswerScriptDetails: fetchResponse.AnswerScriptDetails,
        AutoPurgeDeadlineInHours: fetchResponse.AutoPurgeDeadlineInHours,
        CID: fetchResponse.CID,
        City: fetchResponse.City,
        Id: fetchResponse.Id,
        IsAutoPurgeEnabled: fetchResponse.IsAutoPurgeEnabled,
        IsLive: fetchResponse.IsLive,
        IsPriorityFinalization: fetchResponse.IsPriorityFinalization,
        Name: fetchResponse.Name,
        PID: fetchResponse.PID,
        SID: fetchResponse.SID,
        ShortAnswerScript: fetchResponse.ShortAnswerScript,
        VDN: fetchResponse.VDN,
        StateProvinceId: fetchResponse.StateProvinceId,
        StatusId: fetchResponse.StatusId,
        TimeZoneId: fetchResponse.TimeZoneId,
        RelationshipManagerId: fetchResponse.RelationshipManagerId,
      };
      return { accountFormInputs: result, accountInfoReturn: fetchResponse };
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetAccountInfo };
