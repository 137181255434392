import React, { ReactNode, ReactElement } from 'react';
import { Box } from 'rebass/styled-components';
import {
  LayoutProps,
  SpaceProps,
  ColorProps,
  FlexboxProps,
  PositionProps,
} from 'styled-system';

type ssProps = LayoutProps &
  SpaceProps &
  FlexboxProps &
  ColorProps &
  PositionProps;

type ButtonGroupProps = ssProps & {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
};

function ButtonGroup({
  children,
  className,
  onClick,
  ...ssProps
}: ButtonGroupProps): ReactElement {
  return (
    <Box
      onClick={onClick}
      className={className}
      sx={{
        position: 'relative',
        display: 'inline-flex',
        '& > button': {
          minWidth: '50px',
        },
        '& > button:not(:last-child)': {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
        '& > button:not(:first-child)': {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      }}
      {...ssProps}
    >
      {children}
    </Box>
  );
}

export { ButtonGroup };
