import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { importProcedure, importProcedureInvisible } from 'utils/urls';
import { getTokenWithType } from './useAuth';
import { Forms } from 'types/forms';

export async function postImportProcedures({
  accountId,
  checkedProcedures,
  invisible,
}: {
  accountId: number;
  checkedProcedures: any;
  invisible: boolean;
}): Promise<TResult> {
  const url = invisible
    ? importProcedureInvisible(accountId)
    : importProcedure(accountId);
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(checkedProcedures),
  });
  if (response.status !== 200) {
    const error = await response.json();
    throw error;
  }
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = Forms & ValidationError;
export type TVariables = importProceduresInput;
export type TError = string;
export type TSnapshot = unknown;

export type importProceduresInput = {
  accountId: number;
  checkedProcedures: any;
  invisible: boolean;
};

function useImportProcedures(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: importProceduresInput) => {
      const formData = await postImportProcedures({
        accountId: params.accountId,
        checkedProcedures: params.checkedProcedures,
        invisible: params.invisible,
      });
      queryCache.invalidateQueries(['get_procedure_builder_procedures'], {
        refetchInactive: true,
      });
      queryCache.invalidateQueries(['get_global_procedures'], {
        refetchInactive: true,
      });
      queryCache.invalidateQueries(['get_archived_procedures'], {
        refetchInactive: true,
      });
      queryCache.invalidateQueries(['get_procedure'], {
        refetchInactive: true,
      });
      queryCache.invalidateQueries(['get_broken_links', params.accountId], {
        refetchInactive: true,
      });

      return formData;
    },
    {
      throwOnError: true,
    },
  );
}

export { useImportProcedures };
