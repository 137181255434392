import styled, { css } from 'styled-components';
import { ButtonReset } from 'ui/utils/buttonReset';

type ProcedureActionProps = {
  noAction?: boolean;
};

const ProcedureAction = styled.button<ProcedureActionProps>`
  ${ButtonReset}
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px var(--color-neutral-contrast-03);

  html[data-color-mode='dark'] & {
    border-bottom: solid 1px var(--color-neutral-contrast-02);
  }

  &:focus,
  &:hover {
    outline: none;
    background: var(--color-neutral-contrast-02);
  }

  &:active {
    transform: translateY(1px);
  }

  ${({ noAction }) =>
    noAction &&
    css`
      &:hover {
        cursor: initial;
        background: transparent;
      }
      &:active {
        transform: none;
      }
    `}
`;

export { ProcedureAction };
