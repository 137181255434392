import { useQuery, QueryResult } from 'react-query';
import { getCustomTableWithRecords } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { CustomTable } from 'types/customTables';

async function fetchCustomTableDetails({
  accountId,
  lookupId,
}: TVariables): Promise<TResult> {
  const response = await fetch(getCustomTableWithRecords(accountId, lookupId), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = CustomTable | undefined;

export type TError = { message: string };
export type TVariables = {
  accountId: number;
  lookupId: number;
};

function useGetCustomTableWithRecords({
  accountId,
  lookupId,
}: {
  accountId: number;
  lookupId: number;
}): QueryResult<TResult, TError> {
  return useQuery(
    ['get_custom_table_with_records', accountId, lookupId],
    async () => {
      if (lookupId)
        return await fetchCustomTableDetails({
          accountId,
          lookupId,
        });
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetCustomTableWithRecords };
