import React, { ReactElement, ReactNode, AllHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { getHSL } from 'utils/getHSL';
import getCssVar from 'utils/getCssVar';
import {
  space,
  SpaceProps,
  flex,
  FlexProps,
  layout,
  LayoutProps,
  color,
  ColorProps,
} from 'styled-system';
import { Button } from 'ui/components';
import * as Icon from 'assets/icons';

type NativeProps = AllHTMLAttributes<HTMLElement>;

type StyledAlertProps = SpaceProps &
  LayoutProps &
  FlexProps &
  ColorProps & {
    variant?: 'info' | 'success' | 'warning' | 'danger';
    style?: NativeProps['style'];
  };

const StyledAlert = styled.div<StyledAlertProps>`
  ${space}
  ${layout}
    ${color}
    ${flex}

--h: ${() => getHSL('h', getCssVar('--color-neutral-contrast-02'))};
  --s: ${() => getHSL('s', getCssVar('--color-neutral-contrast-02'))};
  --l: ${() => getHSL('l', getCssVar('--color-neutral-contrast-02'))};
  --a: 30%;
  --color-alpha: 15%;

  display: flex;
  align-items: flex-start;
  border: solid 1px hsla(var(--h), var(--s), var(--l), 60%);
  border-radius: ${({ theme }) => theme.radii.soft};
  padding: ${({ theme }) => theme.space[3]};
  background: hsla(var(--h), var(--s), calc(var(--l) + 5%), var(--a));
  color: hsl(var(--h), var(--s), calc(var(--l) - var(--color-alpha)));

  html[data-color-mode='dark'] & {
    --a: 10%;
    --color-alpha: 5%;
  }

  & > .pr-icon {
    margin-top: 0.15em;
    align-self: flex-start;
  }

  & > .pr-icon svg {
    fill: hsla(var(--h), var(--s), calc(var(--l) - 5%), 80%);
    margin-right: ${({ theme }) => theme.space[2]};
  }

  & .pr-alert-close {
    background: none;
    align-self: flex-start;
    fill: hsla(var(--h), var(--s), calc(var(--l) - 5%), 60%);
    margin-left: ${({ theme }) => theme.space[2]};
    transition: opacity 200ms ease-in-out;
    opacity: 0.5;

    &:hover {
      opacity: 0.85;
    }

    /* svg {
      width: 1.5em;
      height: 1.5em;
    } */
  }

  ${({ variant }) =>
    variant === 'success' &&
    css`
      --h: ${({ theme }) => getHSL('h', theme.colors.state.success)};
      --s: ${({ theme }) => getHSL('s', theme.colors.state.success)};
      --l: ${({ theme }) => getHSL('l', theme.colors.state.success)};
    `}

  ${({ variant }) =>
    variant === 'danger' &&
    css`
      --h: ${({ theme }) => getHSL('h', theme.colors.state.error)};
      --s: ${({ theme }) => getHSL('s', theme.colors.state.error)};
      --l: ${({ theme }) => getHSL('l', theme.colors.state.error)};
    `}

  ${({ variant }) =>
    variant === 'warning' &&
    css`
      --h: ${({ theme }) => getHSL('h', theme.colors.state.warning)};
      --s: ${({ theme }) => getHSL('s', theme.colors.state.warning)};
      --l: ${({ theme }) => getHSL('l', theme.colors.state.warning)};
    `}

    ${({ variant }) =>
    variant === 'info' &&
    css`
      --h: ${({ theme }) => getHSL('h', theme.colors.state.info)};
      --s: ${({ theme }) => getHSL('s', theme.colors.state.info)};
      --l: ${({ theme }) => getHSL('l', theme.colors.state.info)};
    `}
`;

export type AlertProps = {
  variant?: 'info' | 'success' | 'warning' | 'danger';
  children: ReactNode;
  icon?: ReactNode;
  onClose?: () => void;
  style?: NativeProps['style'];
};

function Alert({
  children,
  icon,
  onClose,
  variant,
  style,
}: AlertProps): ReactElement {
  return (
    <StyledAlert variant={variant} style={style}>
      {icon ? icon : null}
      <span>{children}</span>
      {onClose && (
        <Button size="sm" className="pr-alert-close" onClick={onClose}>
          <Icon.X />
        </Button>
      )}
    </StyledAlert>
  );
}

export { Alert };
