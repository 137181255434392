export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
  post_logout_redirect_uri: process.env.REACT_APP_LOGOFF_REDIRECT_URL,
  response_type: 'id_token token',
  automaticSilentRenew: true,
  loadUserInfo: false,
  scope: 'openid profile roles api all_claims',
  silentRequestTimeout: 2 * 60 * 1000,
};

export const METADATA_OIDC = {
  issuer: process.env.REACT_APP_AUTHORITY,
  jwks_uri:
    process.env.REACT_APP_AUTHORITY + '/.well-known/openid-configuration/jwks',
  authorization_endpoint:
    process.env.REACT_APP_AUTHORITY + '/connect/authorize',
  token_endpoint: process.env.REACT_APP_AUTHORITY + '/connect/token',
  userinfo_endpoint: process.env.REACT_APP_AUTHORITY + '/connect/userinfo',
  end_session_endpoint: process.env.REACT_APP_AUTHORITY + '/connect/endsession',
  check_session_iframe:
    process.env.REACT_APP_AUTHORITY + '/connect/checksession',
  revocation_endpoint: process.env.REACT_APP_AUTHORITY + '/connect/revocation',
  introspection_endpoint:
    process.env.REACT_APP_AUTHORITY + '/connect/introspect',
};

export const AUTH_TOKEN = 'AUTH_TOKEN';
export const LOGIN_TYPE = 'LOGIN_TYPE';
export const LOGIN_TYPES = {
  FIRST_LOGIN: 'FIRST_LOGIN', // user landed on any page without token
  REFRESH_LOGIN: 'REFRESH_LOGIN', // token expired while user was working in a page
};
export const AUTH_EXPIRY_VISIBLE = 'AUTH_EXPIRY_VISIBLE';

// idle time & silen signin
export const SILENT_SIGN_IN_LOADING = 'SILENT_SIGN_IN_LOADING';
