import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import { SlidePanel, Loader, FormGroup } from 'ui/patterns';
import {
  Button,
  Stack,
  Separator,
  VisuallyHidden,
  StyledFormInput,
} from 'ui/components';
import { ProcedureAccordion } from 'ui/patterns';
import { ReactSelect } from 'ui/patterns/reactSelect';
import { Heading } from 'rebass/styled-components';
import {
  useGetExistingLayouts,
  useDeleteTemplate,
  useEditTemplateName,
  useSaveLayout,
  useDeleteTemplateGroup,
  useEditTemplateGroupName,
} from 'hooks';
import { showErrorToast, showSuccessToast } from 'utils/showToast';
import { confirmAlert } from 'utils/confirm-alert';
import * as Icon from 'assets/icons';
import { EmptyState } from 'ui/patterns';
import NoDataImage from 'assets/images/NoDataImage';
import { BlobFile } from 'types/procedureBuilder';

type ExistingLayoutProps = {
  showPanel: boolean;
  togglePanel: () => void;
};

type Group = {
  label: string;
  value: string;
};

function ExistingLayout({
  showPanel,
  togglePanel: toggle,
}: ExistingLayoutProps): ReactElement {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedLayout, setSelectedLayout] = useState('');
  const [insertDisable, setInsertDisable] = useState(false);

  /*Grouping */
  const [isGroupEdited, setIsGroupEdited] = useState(false); // to handle changes in dropdown after group name change
  const [editGroupName, setEditGroupName] = useState(false); // group name edit state
  const [groupName, setGroupName] = useState<string>('All'); // group name & header
  const [selectedGroupValue, setSelectedGroupValue] = useState<Group>({
    label: ' All',
    value: 'All',
  }); // drop down selection
  const [selectedGroupLayoutList, setSelectedGroupLayoutList] = useState<
    Array<BlobFile>
  >([]); //selected list
  /*Grouping */

  const [deleteFile, { isLoading: isDeleteLoading }] = useDeleteTemplate();
  const [editFile, { isLoading: isEditLoading }] = useEditTemplateName();

  const [deleteGroup, { isLoading: isDeleteLayoutGroupLoading }] =
    useDeleteTemplateGroup();

  const [editGroupTitle, { isLoading: isEditGroupNameLoading }] =
    useEditTemplateGroupName();

  const { data: layoutData, isLoading: layoutDataLoading } =
    useGetExistingLayouts(showPanel);

  const [saveLayoutApi, { isLoading: isSaveLayoutLoading }] = useSaveLayout();

  const folderOptions = useMemo(() => {
    return (
      layoutData &&
      layoutData[0] &&
      layoutData[0]?.BlobFile?.filter(item => item.FileType === 'folder').map(
        group => {
          return { label: group.FileName, value: group.FileName };
        },
      )
    );
  }, [layoutData]);

  const layoutTemplateData = useMemo(() => {
    return layoutData && layoutData[0] && layoutData[0]?.BlobFile;
  }, [layoutData]);

  const updatedGroups = useMemo(() => {
    return layoutTemplateData?.map(item => {
      if (item.FileType === 'html') {
        item.mappedGroups = [];
      }
      return item;
    });
  }, [layoutTemplateData]);

  const mappedGrouping = () => {
    const ungrouped = updatedGroups?.filter(item => item.FileType === 'html');
    const grouped = updatedGroups?.filter(item => item.FileType !== 'html');
    if (grouped && ungrouped) {
      for (let j = 0; j < ungrouped.length; j++) {
        for (let i = 0; i < grouped?.length; i++) {
          grouped[i]?.BlobFile?.map(subItem => {
            if (ungrouped[j].FileName === subItem.FileName) {
              if (!ungrouped[j]?.mappedGroups?.length)
                ungrouped[j]?.mappedGroups?.push(grouped[i]?.FileName);
              else {
                const isGroupPresent = ungrouped[j]?.mappedGroups?.filter(
                  item => item === grouped[i]?.FileName,
                );
                if (!isGroupPresent?.length)
                  ungrouped[j]?.mappedGroups?.push(grouped[i]?.FileName);
              }
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(showPanel);
    }, 10);
  }, [showPanel]);

  //handing group changes in dropdown
  useEffect(() => {
    if (layoutTemplateData) {
      if (selectedGroupLayoutList.length >= 0 && selectedGroupValue !== null) {
        if (layoutTemplateData.length !== 0) {
          const temp: Array<BlobFile> = [];
          if (selectedGroupValue.value === 'All') {
            layoutTemplateData?.map((tables: BlobFile) => {
              if (tables.FileType === 'html') {
                temp.push(tables);
              } else if (selectedGroupValue.value === tables.FileName) {
                tables.BlobFile.map((item: BlobFile) => {
                  temp.push(item);
                });
              }
              setSelectedGroupLayoutList(temp);
            });
          } else {
            layoutTemplateData?.map((tables: BlobFile) => {
              if (selectedGroupValue.value === tables.FileName) {
                tables.BlobFile.map((item: BlobFile) => {
                  temp.push(item);
                });
                setSelectedGroupLayoutList(temp);
              }
            });
          }
        } else {
          setSelectedGroupLayoutList([]);
        }
      }
    }
  }, [
    folderOptions,
    selectedGroupLayoutList.length,
    selectedGroupValue,
    layoutTemplateData,
  ]);

  //reset header and dropdown selection to All after deleting group or last entry
  useEffect(() => {
    const value = folderOptions?.filter(
      item => item.label === selectedGroupValue.label,
    );
    if (
      value?.length === 0 &&
      selectedGroupValue.label !== 'All' &&
      !isGroupEdited
    ) {
      setGroupName('All');
      setSelectedGroupValue({ label: 'All', value: 'All' });
    }
  }, [folderOptions, isGroupEdited, selectedGroupValue.label]);

  function togglePanel() {
    setIsVisible(false);
    setTimeout(() => {
      toggle();
    }, 500);
  }

  const insertLayout = () => {
    let layoutInsert: Array<BlobFile> | undefined = [];
    if (selectedGroupValue.value !== 'All') {
      const layoutGroup = layoutTemplateData?.filter(
        data =>
          data.FileType === 'folder' &&
          data.FileName === selectedGroupValue.label,
      );
      layoutInsert =
        layoutGroup &&
        layoutGroup[0]?.BlobFile?.filter(
          (item: BlobFile) => item.FileName === selectedLayout,
        );
    } else {
      layoutInsert = layoutTemplateData
        ?.filter(data => data.FileType === 'html')
        ?.filter(item => item.FileName === selectedLayout);
    }
    const content = layoutInsert && layoutInsert[0]?.Content;
    if (content) (window as any)?.tinymce?.activeEditor?.insertContent(content);
  };

  //Table deletion affects only the respective folder
  const deleteLayout = (fileTitle: string) => {
    let isDeleteFromAllFolder: boolean;
    confirmAlert({
      message:
        selectedGroupValue.label === 'All'
          ? 'Are you sure you want to delete this layout?'
          : selectedGroupLayoutList.length === 1
          ? `As you are removing the last layout of group [${groupName}], it will be removed from the list of groups. Are you sure you want to remove this layout from the group?`
          : 'Are you sure you want to remove this layout from the group?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            if (groupName !== 'All') {
              fileTitle = `${groupName}/${fileTitle}`;
              isDeleteFromAllFolder = false;
            } else isDeleteFromAllFolder = true;
            if (fileTitle) {
              deleteFile(
                {
                  Container: 'layouts',
                  Title: fileTitle,
                  IsDeleteFromAllFolder: isDeleteFromAllFolder,
                },
                {
                  onSuccess: () => {
                    setIsGroupEdited(false);
                    showSuccessToast({
                      message: 'Your changes were saved successfully.',
                    });
                  },
                  onError: error => {
                    showErrorToast({ message: `${error.message}` });
                  },
                },
              );
            }
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  //Table name edit affects all tables with same name
  const editLayoutTitle = (
    oldFileName: string,
    newFileName: string,
    force: boolean,
  ) => {
    let newLayoutName = '';
    if (oldFileName !== newFileName) {
      if (!newFileName.includes('.html'))
        newLayoutName = `${newFileName.trim()}.html`;
      else newLayoutName = newFileName.trim();
      editFile(
        {
          Title: oldFileName,
          Container: 'layouts',
          ReplaceName: newLayoutName,
          force: force,
        },
        {
          onSuccess: message => {
            if (typeof message === 'string') {
              showErrorToast({ message: `${message}` });
            } else {
              showSuccessToast({
                message: 'Your changes were saved successfully.',
              });
            }
          },
          onError: error => {
            showErrorToast({ message: `${error.message}` });
          },
        },
      );
    }
  };

  const procedureActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          togglePanel();
          setSelectedLayout('');
        }}
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        onClick={() => {
          insertLayout();
          togglePanel();
          setSelectedLayout('');
        }}
        disabled={
          selectedLayout === '' || insertDisable === true ? true : false
        }
      >
        Insert
      </Button>
    </>
  );

  const existingLayoutCheck = (title: string | undefined) => {
    let checkedValue: Array<BlobFile> | undefined = [];
    const check = title?.toLowerCase().includes('.html');
    let value: string | undefined;
    if (check) value = title?.split('.')[0]?.trim();
    else value = title?.trim();
    const layoutGroup = layoutTemplateData?.filter(
      data => data.FileType === 'html',
    );
    checkedValue = layoutGroup?.filter(item => {
      if (item.FileName.split('.')[0] === value) return item;
    });
    if (checkedValue?.length) {
      return true;
    } else return false;
  };

  const updateLayout = (title: string, content: string | undefined) => {
    saveLayoutApi(
      {
        params: {
          title: title.trim(),
          content: content || '',
          container: 'layouts',
        },
        saveOption: false,
      },
      {
        onSuccess: () => {
          showSuccessToast({ message: 'Layout saved successfully' });
        },
      },
    );
  };

  const deleteLayoutGroup = (title: string) => {
    setIsGroupEdited(true);
    confirmAlert({
      message: 'Are you sure you want to delete the group?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            if (title) {
              deleteGroup(
                {
                  Container: 'layouts',
                  FolderName: title,
                },
                {
                  onSuccess: () => {
                    setIsGroupEdited(false);
                    showSuccessToast({
                      message: 'Your changes were saved successfully.',
                    });
                  },
                  onError: error => {
                    showErrorToast({ message: `${error.message}` });
                  },
                },
              );
            }
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  const handleGroupChange = (e: { label: string; value: string }) => {
    setIsGroupEdited(false);
    setSelectedGroupValue(e);
    setGroupName(e.value);
  };

  const handleGroupInsertion = (
    selectedGroups: Array<Group>,
    title: string,
    content: string | undefined,
    unselectedGroups: Array<Group>,
  ) => {
    const insertTitleTemp = selectedGroups.map((group: Group) => {
      return `${group.label}/${title}`;
    });
    const insertTitleString = insertTitleTemp.join(',');
    const removeTitleTemp = unselectedGroups?.map((group: Group) => {
      return `${group.label}/${title}`;
    });
    const removeTitleString = removeTitleTemp.join(',');
    saveLayoutApi({
      params: {
        title: insertTitleString.trim(),
        content: content || '',
        container: 'layouts',
      },
      saveOption: true,
    });
    if (unselectedGroups.length !== 0) {
      deleteFile(
        {
          Container: 'layouts',
          Title: removeTitleString.trim(),
          IsDeleteFromAllFolder: false,
        },
        {
          onSuccess: () => {
            setIsGroupEdited(false);
          },
          onError: error => {
            showErrorToast({ message: `${error.message}` });
          },
        },
      );
    }
  };

  const handleLayoutGroupNameEdit = (
    oldGroupName: string,
    newGroupName: string,
  ) => {
    const isExists = folderOptions?.filter(item => item.label === newGroupName);
    if (isExists?.length) {
      showErrorToast({
        message: 'Group name already exists.Please enter a different name',
      });
    }
    editGroupTitle(
      {
        title: oldGroupName,
        container: 'layouts',
        replaceName: newGroupName.trim(),
      },
      {
        onSuccess: message => {
          if (typeof message === 'string') {
            showErrorToast({ message: `${message}` });
          } else {
            setIsGroupEdited(true);
            setSelectedGroupValue({
              label: `${newGroupName.trim()}`,
              value: `${newGroupName.trim()}`,
            });
            showSuccessToast({
              message: 'Your changes were saved successfully.',
            });
          }
        },
        onError: error => {
          showErrorToast({ message: `${error.message}` });
        },
      },
    );
  };

  return (
    <SlidePanel
      open={isVisible}
      onClose={() => {
        togglePanel();
        setSelectedLayout('');
      }}
      size="md"
      title="Select Layout"
      actions={procedureActions()}
    >
      <FormGroup>
        <ReactSelect
          name="layoutOptions"
          label="Group"
          isSearchable={true}
          isClearable={false}
          options={
            folderOptions !== undefined
              ? [{ label: 'All', value: 'All' }, ...folderOptions]
              : []
          }
          value={selectedGroupValue}
          classNamePrefix="pr-select"
          onChange={(e: any) => handleGroupChange(e)}
          onMenuOpen={() => setSelectedLayout('')}
        />
      </FormGroup>
      <Separator direction="x" my={5} />
      <Stack direction="x" gap={1} alignItems="center" mb={3}>
        {editGroupName ? (
          <Stack direction="x" gap={1} alignItems="center" mb={3}>
            <StyledFormInput
              placeholder="Group name"
              style={{ paddingInline: '0.75rem', width: '300px' }}
              value={groupName}
              onChange={e => setGroupName(e.target.value)}
            />
            <Button
              size="sm"
              variant="ghost"
              iconBefore={<Icon.Check />}
              title="Save"
              onClick={() => {
                if (
                  !groupName ||
                  !groupName.trim() ||
                  groupName.toLowerCase() === 'all' ||
                  groupName.includes(',')
                ) {
                  showErrorToast({
                    message: 'Please enter a valid name',
                  });
                  return;
                } else {
                  setEditGroupName(false);
                  handleLayoutGroupNameEdit(
                    selectedGroupValue.label,
                    groupName,
                  );
                }
              }}
            />
            <Button
              size="sm"
              variant="ghost"
              iconBefore={<Icon.X />}
              title="Cancel"
              onClick={() => {
                setEditGroupName(false);
                setGroupName(selectedGroupValue.label);
              }}
            />
          </Stack>
        ) : (
          <>
            <Heading as="h3" fontSize={3} fontWeight="medium">
              {groupName}
            </Heading>
            {groupName.toLowerCase() === 'all' ? null : (
              <>
                <Button
                  variant="ghost"
                  size="sm"
                  title="Edit group name"
                  onClick={() => setEditGroupName(true)}
                >
                  <Icon.Edit ariaHidden="true" />
                  <VisuallyHidden>Edit Group Name</VisuallyHidden>
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  title="Remove group"
                  onClick={() => deleteLayoutGroup(groupName)}
                >
                  <Icon.Delete />
                  <VisuallyHidden>Remove Group.</VisuallyHidden>
                </Button>
              </>
            )}
          </>
        )}
      </Stack>
      <Stack direction="y" gap={3}>
        {selectedGroupLayoutList?.map((layout, i) => (
          <ProcedureAccordion
            key={i}
            title={layout.FileName}
            content={layout.Content}
            open={selectedLayout}
            container="layouts"
            setOpen={setSelectedLayout}
            deleteTemplate={deleteLayout}
            editTemplate={editLayoutTitle}
            templateExistingCheck={existingLayoutCheck}
            updateTemplate={updateLayout}
            setInsertDisable={setInsertDisable}
            templateGroups={folderOptions}
            handleGroupInsertion={handleGroupInsertion}
            mappedGrouping={mappedGrouping}
            ungroupedSet={updatedGroups?.filter(
              item => item.FileType === 'html',
            )}
            selectedGroupDropDown={selectedGroupValue}
          />
        ))}
      </Stack>
      {selectedGroupLayoutList?.length === 0 ? (
        <EmptyState>
          <NoDataImage feedback="No Layouts Available" />
        </EmptyState>
      ) : null}
      {(layoutDataLoading ||
        isDeleteLoading ||
        isEditLoading ||
        isDeleteLayoutGroupLoading ||
        isSaveLayoutLoading ||
        isEditGroupNameLoading) && <Loader />}
    </SlidePanel>
  );
}

export default ExistingLayout;
