import { useMutation, MutationResultPair } from 'react-query';
import { cleanProcedureTemplate } from 'utils/urls';
import { getTokenWithType } from './useAuth';

export async function cleanProcedure({
  params,
}: {
  params: TVariables;
}): Promise<TResult> {
  const response = await fetch(cleanProcedureTemplate(params.accountId), {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'content-type': 'application/json',
    },
    body: JSON.stringify({ id: params.id, content: params.content }),
  });
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = ValidationError & string;
export type TVariables = {
  id: number | undefined;
  accountId: number;
  content: string | undefined;
};
export type TError = {
  Message: string;
};
export type TSnapshot = unknown;

function useCleanProcedure(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: TVariables) => {
      const fetchResponse = await cleanProcedure({
        params: params,
      });
      if (fetchResponse.Message) throw fetchResponse;
      return fetchResponse;
    },
    {
      throwOnError: true,
    },
  );
}

export { useCleanProcedure };
