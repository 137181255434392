import React from 'react';
import { Column, TableInstance, ColumnInstance } from 'react-table';
import { Account } from 'types/accounts';
import Header from '../AccountsList/tableHeader';

function contains(array: Array<string>, string: string): boolean {
  const index = array.findIndex(item => item === string);
  return index !== -1;
}

export default function useAccountsColumns(
  columnsList?: Array<string>,
): Array<Column<Account>> {
  return React.useMemo((): Array<Column<Account>> => {
    if (columnsList) {
      const columns = [];
      if (contains(columnsList, 'Phone Display'))
        columns.push(getPhoneHeader(Header));
      if (contains(columnsList, 'CID')) columns.push(getCIDHeader(Header));
      if (contains(columnsList, 'SID')) columns.push(getSIDHeader(Header));
      if (contains(columnsList, 'PID')) columns.push(getPIDHeader(Header));
      if (contains(columnsList, 'Answer Script'))
        columns.push(getAnswerScriptHeader(Header));
      if (contains(columnsList, 'Account Name'))
        columns.push(getNameHeader(Header));
      if (contains(columnsList, 'Rel Manager'))
        columns.push(getRelManagerHeader(Header));
      if (contains(columnsList, 'City')) columns.push(getCityHeader(Header));
      if (contains(columnsList, 'State')) columns.push(getStateHeader(Header));
      if (contains(columnsList, 'Time')) columns.push(getTimeHeader(Header));
      if (contains(columnsList, 'Status'))
        columns.push(getStatusHeader(Header));
      if (contains(columnsList, 'Service'))
        columns.push(getServiceHeader(Header));
      return columns;
    }
    return [
      getPhoneHeader(Header),
      getCIDHeader(Header),
      getSIDHeader(Header),
      getPIDHeader(Header),
      getAnswerScriptHeader(Header),
      getNameHeader(Header),
      getRelManagerHeader(Header),
      getCityHeader(Header),
      getStateHeader(Header),
      getTimeHeader(Header),
      getStatusHeader(Header),
      getServiceHeader(Header),
    ];
  }, [columnsList]);
  // return React.useMemo((): Array<Column<Account>> => gridColumns, [
  //   gridColumns,
  // ]);
}

function getPhoneHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: (row: Account) =>
      `${row.VDN || ''} ${row.ShortAnswerScript || ''}`,
    id: 'Phone Display',
  };
}

function getCIDHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: 'CID',
    id: 'CID',
  };
}

function getSIDHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: 'SID',
    id: 'SID',
  };
}

function getPIDHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: 'PID',
    id: 'PID',
  };
}

function getAnswerScriptHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: 'AnswerScript',
    width: 1000,
    id: 'Answer Script',
  };
}

function getNameHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: 'Name',
    id: 'Account Name',
  };
}

function getRelManagerHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Rel Manager',
    Header: Header,
    accessor: (row: Account) =>
      row.RelationshipManager
        ? `${row.RelationshipManager?.FirstName || ''} ${
            row.RelationshipManager?.LastName || ''
          }`
        : '',
  };
}

function getCityHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'City',
    Header: Header,
    accessor: 'City',
  };
}

function getStateHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'State',
    Header: Header,
    accessor: (row: Account) => `${row.StateProvince?.Abbreviation || ''}`,
  };
}

function getTimeHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Time',
    Header: Header,
    accessor: (row: Account) => `${row.TimeZone?.Abbreviation || ''}`,
  };
}

function getStatusHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Status',
    Header: Header,
    accessor: (row: Account) => `${row.Status?.Description || ''}`,
  };
}

function getServiceHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Service',
    Header: Header,
    accessor: (row: Account) => `${row.AccountService?.Description || ''}`,
  };
}
