import React, { ReactElement } from 'react';
import { Checkbox, Cluster } from 'ui/components';
import { Box } from 'rebass';
import { QuestionOptionsType } from 'types/accountsSearch';

export default function QuestionSearchOptions({
  questionOptions,
  toggleQuestionOption,
}: {
  questionOptions: QuestionOptionsType;
  toggleQuestionOption: any;
}): ReactElement {
  return (
    <Cluster gap={3} flex="1" p={2}>
      <Box>
        <Checkbox
          name="IncludeGlobalQuestions"
          label="Include Global Questions"
          checked={questionOptions.includeGlobal}
          handleChange={toggleQuestionOption('includeGlobal')}
        />
        <Checkbox
          name="IncludeInactive."
          label="Include Inactive"
          checked={questionOptions.includeInactive}
          handleChange={toggleQuestionOption('includeInactive')}
        />
        <Checkbox
          name="IncludeHidden."
          label="Include Hidden Accounts"
          checked={questionOptions.includeHidden}
          handleChange={toggleQuestionOption('includeHidden')}
        />
      </Box>
    </Cluster>
  );
}
