import styled from 'styled-components';

const StyledHeader = styled.header`
  --space: ${({ theme }) => theme.space[3]};
  --bg: var(--color-neutral-contrast-00);
  padding-left: var(--space);
  padding-right: var(--space);
  /* overflow: hidden; */
  flex-shrink: 0;
  background: var(--bg);
  color: ${({ theme }) => theme.colors.text.body};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  z-index: 200; /* changed for Accessibe trigger visibility, initially 1000 */

  html[data-color-mode='dark'] & {
    --bg: var(--color-neutral-contrast-02);
  }

  & > * {
    min-height: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* align-items: center; */
    margin: calc(var(--space) / 2 * -1);
  }

  & > * > * {
    margin: calc(var(--space) / 2);
  }

  & .pr-header-avatar {
    width: 35px;
    height: 35px;
  }
`;

export default StyledHeader;
