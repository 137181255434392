import { useQuery, QueryResult } from 'react-query';
import { getTokenWithType } from './useAuth';
import { table } from '../utils/urls';
import { TemplateResult } from '../types/procedureBuilder';

async function fetchExistingTables({
  showPanel,
}: {
  showPanel: boolean;
}): Promise<TResult> {
  if (!showPanel)
    return new Promise((resolve, reject) => {
      reject();
    });
  const response = await fetch(table(), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = Array<TemplateResult> | undefined;

export type TError = {
  message: string;
};

function useGetExistingTables(
  showPanel: boolean,
): QueryResult<TResult, TError> {
  return useQuery(
    ['getExistingTables', showPanel],
    async () => {
      return await fetchExistingTables({
        showPanel,
      });
    },
    {
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetExistingTables };
