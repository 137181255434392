import React, {
  ReactElement,
  useEffect,
  SyntheticEvent,
  useState,
  useMemo,
} from 'react';
import { Box } from 'rebass/styled-components';
import { FormSelect, FormInput, FormTextarea } from 'ui/patterns';
import { RadioButton, Stack, Grid, Checkbox } from 'ui/components';
import { useTextFormats } from 'hooks';
import { TextQuestionType } from '../questionTypes';
import { FormattedCustomInput } from 'ui/patterns/formInput/FormattedCustomInput';
import {
  phonePattern,
  numberOnlyPattern,
  ssnPattern,
  militaryTimePattern,
  getValidations,
} from 'utils/textFormatting';

const urlPrefixOptions = [
  { id: 1, name: 'http://' },
  { id: 2, name: 'https://' },
];

export default function TextQuestion(
  TextQuestionProps: TextQuestionType,
): ReactElement {
  const {
    selectedQuestionType,
    associatedPersons,
    register,
    control,
    Controller,
    watch,
    setValue,
    errors,
    getValues,
    reset,
    url,
    setUrl,
    errorMessage,
  } = TextQuestionProps;

  const { data: textFormat } = useTextFormats();

  const textType = watch('Type');
  const textQuestionTypeFormatId = watch('TextQuestionTypeFormatId');
  const [urlPrefix, setUrlPrefix] = useState<number>(2);

  useEffect(() => {
    if (selectedQuestionType?.QuestionTypeOptions?.Type === textType)
      setValue('Type', selectedQuestionType?.QuestionTypeOptions?.Type, {
        shouldDirty: false,
      });
  }, [selectedQuestionType, textType, setValue]);

  useEffect(() => {
    if (selectedQuestionType) {
      if (
        selectedQuestionType.QuestionTypeId === 1 &&
        selectedQuestionType.QuestionTypeOptions?.TextQuestionTypeFormatId === 7
      ) {
        setUrl(selectedQuestionType.QuestionTypeOptions?.DefaultValue);
        if (
          selectedQuestionType.QuestionTypeOptions?.DefaultValue?.includes(
            'http://',
          )
        ) {
          setUrlPrefix(1);
        } else if (
          selectedQuestionType.QuestionTypeOptions?.DefaultValue?.includes(
            'https://',
          )
        ) {
          setUrlPrefix(2);
        }
      }
    }
  }, [selectedQuestionType, setUrl]);

  return (
    <Box as="form" height="100%" mb="50px">
      <Stack direction="y" gap={7}>
        <Grid minColWidth="300px" gap={3}>
          <FormInput
            label="Field Name"
            disabled
            register={register}
            name="FieldName"
          />
          <FormInput
            label="Original Field Label"
            disabled
            register={register}
            name="FieldLabel"
          />
          <FormInput
            label="Description of Use"
            disabled
            register={register}
            name="Description"
          />
          <FormInput
            label="Field Label"
            register={register}
            name="UpdatedFieldLabel"
          />
          <Controller
            render={(props: {
              onChange: (value: number) => void;
              value: number;
            }) => {
              return (
                <FormSelect
                  value={props.value}
                  label="Person Association"
                  noSelectOption
                  options={associatedPersons}
                  onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                    const value = parseInt(e.currentTarget.value, 10);
                    props.onChange(value);
                  }}
                />
              );
            }}
            defaultValue={''}
            name="PersonAssociation"
            control={control}
          />
        </Grid>
        <Grid minColWidth="250px" gap={3}>
          <Checkbox label="Required" register={register} name="IsRequired" />
          <Checkbox
            label="Never send to Account"
            register={register}
            name="IsNeverSentToAccount"
          />
          <Checkbox
            label="If no answer, don't send to Account"
            register={register}
            name="IsNotSentToAccountIfNoAnswer"
          />
        </Grid>

        <Stack direction="y" gap={3}>
          <Controller
            render={(props: {
              onChange: (value: number) => void;
              value: number;
            }) => {
              return (
                <RadioButton
                  onChange={() => {
                    props.onChange(0);
                    reset({
                      ...getValues(),
                      DefaultValue: '',
                      TextQuestionTypeFormatId: null,
                    });
                  }}
                  inline
                  label="Text"
                  name="Type"
                  checked={textType === 0}
                />
              );
            }}
            defaultValue={1}
            name="Type"
            control={control}
          />
          <Controller
            render={(props: {
              onChange: (value: number) => void;
              value: number;
            }) => {
              return (
                <RadioButton
                  onChange={() => {
                    props.onChange(1);
                    reset({
                      ...getValues(),
                      DefaultValue: '',
                      TextQuestionTypeFormatId: null,
                    });
                  }}
                  inline
                  label="Memo"
                  name="Type"
                  checked={textType === 1}
                />
              );
            }}
            defaultValue={1}
            name="Type"
            control={control}
          />
          <Grid minColWidth="300px" gap={3}>
            {textType === 0 && (
              <>
                <FormattedDefaultValueInputs
                  textQuestionTypeFormatId={textQuestionTypeFormatId}
                  textFormat={textFormat}
                  control={control}
                  errors={errors}
                  register={register}
                  getValidations={getValidations}
                  reset={reset}
                  getValues={getValues}
                  Controller={Controller}
                  setUrl={setUrl}
                  errorMessage={errorMessage}
                  urlPrefix={urlPrefix}
                  urlPrefixOptions={urlPrefixOptions}
                  setUrlPrefix={setUrlPrefix}
                  url={url}
                />
              </>
            )}
            {textType === 1 && (
              <FormTextarea
                label="Default Value"
                register={register}
                name="DefaultValue"
                resize="vertical"
              />
            )}
          </Grid>
        </Stack>
      </Stack>
    </Box>
  );
}

function FormattedDefaultValueInputs({
  textQuestionTypeFormatId,
  textFormat,
  control,
  errors,
  register,
  getValidations,
  reset,
  getValues,
  Controller,
  errorMessage,
  urlPrefix,
  urlPrefixOptions,
  setUrlPrefix,
  setUrl,
  url,
}: {
  textQuestionTypeFormatId: number;
  textFormat?: Array<{
    id: number;
    name: string;
  }>;
  control: any;
  errors: any;
  register: any;
  getValidations: (id: number) => any;
  reset: any;
  getValues: any;
  Controller: any;
  urlPrefixOptions?: Array<{
    id: number;
    name: string;
  }>;
  setUrl: any;
  setUrlPrefix: any;
  errorMessage: string;
  urlPrefix: number;
  url: any;
}) {
  const removePrefix = () => {
    let value = '';
    if (url.includes('http') || url.includes('https')) {
      if (url.includes('http')) {
        value = url.replace('http://', '').trim();
        value = value.replace('https://', '').trim();
      } else if (url.includes('https')) {
        value = url.replace('https://', '').trim();
        value = value.replace('http://', '').trim();
      }
    } else {
      value = url;
    }
    return value;
  };
  return (
    <>
      <Controller
        render={(props: {
          onChange: (value: number) => void;
          value: number;
        }) => {
          return (
            <FormSelect
              value={props.value}
              label="Text Format"
              noSelectOption
              options={textFormat}
              onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                const value = parseInt(e.currentTarget.value, 10);
                props.onChange(value);
                reset({
                  ...getValues(),
                  DefaultValue: '',
                });
              }}
            />
          );
        }}
        defaultValue={''}
        name="TextQuestionTypeFormatId"
        control={control}
      />
      {textQuestionTypeFormatId === null || textQuestionTypeFormatId === 1 ? (
        <FormInput
          label="Default Value"
          register={register}
          name="DefaultValue"
          placeholder="None"
          validate={{
            pattern: {
              value: getValidations(textQuestionTypeFormatId),
              message: 'Enter a valid default value for text.',
            },
          }}
          validation={errors?.DefaultValue && 'error'}
          feedback={errors?.DefaultValue?.message}
        />
      ) : null}

      {textQuestionTypeFormatId === 2 && (
        <Controller
          render={(props: {
            onChange: (value: string) => void;
            value: string;
          }) => {
            return (
              <FormattedCustomInput
                label="Default Value"
                placeholder="Telephone (US)"
                value={props.value}
                onChange={(formattedValue: string, raw: string) => {
                  props.onChange(formattedValue);
                }}
                format={phonePattern}
                validation={errors?.DefaultValue && 'error'}
                feedback={errors?.DefaultValue?.message}
              />
            );
          }}
          defaultValue={''}
          name="DefaultValue"
          control={control}
          rules={{
            pattern: {
              value: getValidations(textQuestionTypeFormatId),
              message: 'Enter a valid Telephone (US) number.',
            },
          }}
        />
      )}
      {textQuestionTypeFormatId === 3 && (
        <Controller
          render={(props: {
            onChange: (value: string) => void;
            value: string;
          }) => {
            return (
              <FormattedCustomInput
                label="Default Value"
                placeholder="Numbers Only"
                format={numberOnlyPattern}
                value={props.value}
                onChange={(formattedValue: string, raw: string) => {
                  props.onChange(formattedValue);
                }}
                validation={errors?.DefaultValue && 'error'}
                feedback={errors?.DefaultValue?.message}
              />
            );
          }}
          defaultValue={''}
          name="DefaultValue"
          control={control}
          rules={{
            pattern: {
              value: getValidations(textQuestionTypeFormatId),
              message: 'Enter a valid Number.',
            },
          }}
        />
      )}
      {textQuestionTypeFormatId === 4 && (
        <Controller
          render={(props: {
            onChange: (value: string) => void;
            value: string;
          }) => {
            return (
              <FormattedCustomInput
                label="Default Value"
                placeholder="SSN"
                value={props.value}
                format={ssnPattern}
                onChange={(formattedValue: string, raw: string) => {
                  props.onChange(formattedValue);
                }}
                validation={errors?.DefaultValue && 'error'}
                feedback={errors?.DefaultValue?.message}
              />
            );
          }}
          defaultValue={''}
          name="DefaultValue"
          control={control}
          rules={{
            pattern: {
              value: getValidations(textQuestionTypeFormatId),
              message: 'Enter a valid SSN.',
            },
          }}
        />
      )}
      {textQuestionTypeFormatId === 5 && (
        <Controller
          render={(props: {
            onChange: (value: string) => void;
            value: string;
          }) => {
            return (
              <FormattedCustomInput
                label="Default Value"
                placeholder="Military time"
                value={props.value}
                format={militaryTimePattern}
                onChange={(formattedValue: string, raw: string) => {
                  const x = raw.split('');
                  if (x.length === 2) {
                    if (parseInt(x[0]) === 2) {
                      if (parseInt(x[1]) <= 3) {
                        props.onChange(formattedValue);
                      } else {
                        props.onChange(formattedValue.replace(x[1], ' '));
                      }
                    }
                  } else props.onChange(formattedValue);
                }}
                validation={errors?.DefaultValue && 'error'}
                feedback={errors?.DefaultValue?.message}
              />
            );
          }}
          defaultValue={''}
          name="DefaultValue"
          control={control}
          rules={{
            pattern: {
              value: getValidations(textQuestionTypeFormatId),
              message: 'Enter a valid Time',
            },
          }}
        />
      )}
      {textQuestionTypeFormatId === 6 && (
        <FormInput
          label="Default Value"
          register={register}
          name="DefaultValue"
          placeholder="Email"
          validate={{
            pattern: {
              value: getValidations(textQuestionTypeFormatId),
              message: 'Enter a valid email.',
            },
          }}
          validation={errors?.DefaultValue && 'error'}
          feedback={errors?.DefaultValue?.message}
        />
      )}
      {textQuestionTypeFormatId === 7 && (
        <>
          <Stack direction="x" gap={1}>
            <FormSelect
              value={urlPrefix}
              label="URL Prefix"
              noSelectOption
              options={urlPrefixOptions}
              onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                setUrlPrefix(JSON.parse(e.currentTarget.value));
                let value = '';
                value = removePrefix();
                if (JSON.parse(e.currentTarget.value) === 2) {
                  const link = `https://${value}`;
                  setUrl(link);
                } else {
                  const link = `http://${value}`;
                  setUrl(link);
                }
              }}
            />
            <FormInput
              style={{ flexGrow: 1 }}
              label="Default Value"
              value={removePrefix()}
              register={register}
              name="DefaultValue"
              placeholder="URL"
              onTextChange={(text: string) => {
                if (text.includes('http') || text.includes('https')) {
                  setUrl(text);
                  if (text.includes('http://')) {
                    setUrlPrefix(1);
                  } else if (text.includes('https://')) {
                    setUrlPrefix(2);
                  }
                } else {
                  if (urlPrefix === 2) {
                    const link = `https://${text}`;
                    setUrl(link);
                  } else {
                    const link = `http://${text}`;
                    setUrl(link);
                  }
                }
              }}
              validation={
                errorMessage && errorMessage === 'valid'
                  ? 'success'
                  : errorMessage === 'invalid'
                  ? 'error'
                  : 'success'
              }
              feedback={
                errorMessage === 'valid'
                  ? ''
                  : errorMessage === 'invalid'
                  ? 'Enter a valid URL.'
                  : ''
              }
            />
          </Stack>
        </>
      )}
    </>
  );
}
