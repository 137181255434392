import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import jstz from 'jstz';
import { useParams } from 'react-router-dom';
import { Flex, Text, Box } from 'rebass/styled-components';
import { Tooltip } from 'react-tippy';
import {
  Card,
  CardHeader,
  Tabs,
  Accordion,
  Loader,
  SlidePanel,
  SearchInput,
  Alert,
} from 'ui/patterns';
import {
  Button,
  Checkbox,
  StickyListWrap,
  StickyList,
  StickyListTitle,
  StickyListItem,
  Stack,
  DataTable,
  DataTableWrapper,
} from 'ui/components';
import {
  useDeleteProcedure,
  useGetAccountListForEmbedProcedures,
  useGetArchivedProcedures,
  useGetGlobalProcedures,
  useGetLinkedAccounts,
  useGetReferenceForProcedures,
  useImportProcedures,
  useImportedProcedureDuplicate,
} from 'hooks';
import * as Icon from 'assets/icons';
import { ProcedureSearch } from '../styles';
import { AccountList, ProcedureList } from '../LinkProcedure/styles';
import { ProcedureDetailsType } from 'types/procedureBuilder';
import {
  showErrorToast,
  showSuccessToast,
  showWarningToast,
} from 'utils/showToast';
import PreviewPanel from '../LinkProcedure/PreviewPanel';
import { ProcedureAction } from './styles';
import { confirmAlert } from 'utils/confirm-alert';
import { queryCache } from 'react-query';
import { Account } from 'types/accounts';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

const pageLength = 20;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
export default function ImportProcedure({
  showPanel,
  togglePanel: toggle,
}: // accountId,
{
  showPanel: boolean;
  togglePanel: () => void;
  // accountId: number;
}): ReactElement {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(showPanel);
    }, 10);
  }, [showPanel]);

  function togglePanel() {
    setIsVisible(false);
    setTimeout(() => {
      toggle();
    }, 500);
  }
  const params = useParams<{
    accountId: string;
  }>();
  const accountId = parseInt(params.accountId);
  const [globalSearch, setGlobalSearch] = useState('');
  const { data: globals, isLoading: isGlobalLoading } =
    useGetGlobalProcedures(accountId);
  const globalProcedures = globals?.filter((item: ProcedureDetailsType) =>
    item.Name.toLocaleLowerCase().includes(globalSearch.toLocaleLowerCase()),
  );
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [invisibleState, setInvisibleState] = useState(false);
  const {
    data: results,
    fetchMore: existingAccountsFecthMore,
    // canFetchMore: canExistingAccountsFecthMore,
    isLoading: isGetAccountListLoading,
  } = useGetAccountListForEmbedProcedures(accountId, searchKeyword, true);
  const existingAccounts = useMemo(() => {
    if (results) {
      return results?.flat();
    } else return [];
  }, [results]);
  // const existingAccounts = results && results?.flat();

  const [archiveSearch, setArchiveSearch] = useState('');
  const { data: archives, isLoading: isGetArchivesLoading } =
    useGetArchivedProcedures(accountId);
  const archivedProcedures = archives?.filter((item: any) =>
    item?.Procedure?.Name?.toLocaleLowerCase()?.includes(
      archiveSearch.toLocaleLowerCase(),
    ),
  );
  const [opendAccountIds, setOpenedAccountIds] = useState<Array<number>>([]);

  const [selectedGlobalProcedures, setSelectedGlobal] = useState<Array<number>>(
    [],
  );
  // const [allGlobalSelected, setAllGlobalSelected] = useState<boolean>(false);
  const [allAccountsOpenend, setAllAccountsOpened] = useState<boolean>(false);
  const [selectedExistingProcedures, setSelectedExisting] = useState<
    Array<number>
  >([]);

  const [allAccountsSelected, setAllAccountsSelected] =
    useState<boolean>(false);

  const [selectedArchivedProcedures, setSelectedArchivedProcedures] = useState<
    Array<number>
  >([]);

  const [duplicateProcedures, setDuplicateProcedures] = useState<
    Array<any> | undefined
  >([]);
  const [isDuplicateModal, setIsDuplicateModal] = useState(false);
  const [selectedDuplicates, setSelectedDuplicates] = useState<
    Array<any> | undefined
  >([]);
  const [selectedSkips, setSelectedSkips] = useState<Array<any> | undefined>(
    [],
  );

  const [showPreview, setShowPreview] = useState<
    | {
        accountId: number;
        procedureId: number;
      }
    | undefined
  >();

  const [importProcedures, { isLoading: isImportLoading }] =
    useImportProcedures();

  const [deleteProcedure, { isLoading: isDeleteLoading }] =
    useDeleteProcedure();

  const [getProcedureReference, { isLoading: isProcedureReferenceLoading }] =
    useGetReferenceForProcedures();

  const [duplicateImportedProcedure, { isLoading: isDuplicateImportsLoading }] =
    useImportedProcedureDuplicate();

  const isLoading =
    isGetArchivesLoading ||
    isImportLoading ||
    isGlobalLoading ||
    isGetAccountListLoading ||
    isDeleteLoading ||
    isProcedureReferenceLoading;

  const existingAccountsRef =
    useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleScroll = useCallback(
    (e: any) => {
      if (existingAccountsRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom) {
          const localData: Array<Array<Account>> | undefined =
            queryCache.getQueryData([
              'getAccountForEmbedProcedures',
              accountId,
              searchKeyword,
            ]);
          if (
            localData &&
            localData[localData.length - 1].length === pageLength
          )
            existingAccountsFecthMore();
        }
      }
    },
    [accountId, searchKeyword, existingAccountsFecthMore],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  function getAllSelectedGlobalProcedures() {
    const globalSelected = selectedGlobalProcedures.map(i => {
      const procedure = globalProcedures?.find(
        (item: ProcedureDetailsType) => i === item.Id,
      );
      return procedure;
    });
    return globalSelected;
  }

  function getAllSelectedArchivedProcedures() {
    const archiveSelected = selectedArchivedProcedures.map(i => {
      const procedure = archivedProcedures?.find(
        (item: any) => i === item.ProcedureId,
      );
      return procedure;
    });
    return archiveSelected;
  }

  function getAllSelectedAccounts() {
    let selectedExisting: Array<{
      accountName: string;
      procedures: Array<ProcedureDetailsType>;
    }> = [];
    if (allAccountsSelected) {
      existingAccounts?.forEach((item: any) => {
        if (item?.AccountProcedures) {
          const procedures = item.AccountProcedures.map(
            (value: any) => value?.Procedure,
          );
          selectedExisting = [
            ...selectedExisting,
            {
              accountName: item.AccountNameAndNumber,
              procedures: [...procedures],
            },
          ];
        }
      });
    } else {
      existingAccounts?.forEach((item: any) => {
        if (item?.AccountProcedures) {
          const procedures = item.AccountProcedures.map(
            (item: any) => item?.Procedure,
          );
          const selectedProcedures = procedures.filter((value: any) => {
            const isExists = selectedExistingProcedures.find(
              i => i === value.Id,
            );
            return !!isExists;
          });
          if (selectedProcedures.length > 0)
            selectedExisting = [
              ...selectedExisting,
              {
                accountName: item.AccountNameAndNumber,
                procedures: [...selectedProcedures],
              },
            ];
        }
      });
    }

    return selectedExisting;
  }

  function getAllSelectedProcedures() {
    const globalSelected = selectedGlobalProcedures.map(i => {
      const procedure = globalProcedures?.find((item: any) => i === item.Id);
      return procedure;
    });
    const archivedSelected = selectedArchivedProcedures.map(i => {
      const procedure = archivedProcedures?.find(
        (item: any) => i === item.ProcedureId,
      )?.Procedure;
      return procedure;
    });
    let selectedExisting: Array<ProcedureDetailsType> = [];
    if (allAccountsSelected) {
      existingAccounts?.forEach((item: any) => {
        if (item.AccountProcedures) {
          const procedures = item.AccountProcedures.map(
            (item: any) => item?.Procedure,
          );
          selectedExisting = [...selectedExisting, ...procedures];
        }
      });
    } else {
      existingAccounts?.forEach((item: any) => {
        if (item.AccountProcedures) {
          const procedures = item.AccountProcedures.map(
            (item: any) => item?.Procedure,
          );
          const selectedProcedures = procedures.filter((item: any) => {
            const isExists = selectedExistingProcedures.find(
              i => i === item.Id,
            );
            return !!isExists;
          });
          selectedExisting = [...selectedExisting, ...selectedProcedures];
        }
      });
    }

    const allSelections = [
      ...globalSelected,
      ...selectedExisting,
      ...archivedSelected,
    ];
    return allSelections;
  }

  const pageActions = [
    {
      action: 'Import',
      icon: <Icon.Import />,
      handleButtonClick: () => {
        //
      },
    },
  ];

  const allGlobalsSelected =
    globalProcedures &&
    globalProcedures?.length > 0 &&
    globalProcedures?.length === selectedGlobalProcedures.length;
  const allArchivedSelected =
    archivedProcedures &&
    archivedProcedures?.length > 0 &&
    archivedProcedures?.length === selectedArchivedProcedures.length;
  const [linkedAccounts, setLinkedAccounts] = useState<
    ProcedureDetailsType | undefined
  >();
  const [linkedAccountsSearch, setLinkedAccountsSearch] = useState<string>('');
  const { data: linkedAccountsList, isLoading: isLinkedAccountsLoading } =
    useGetLinkedAccounts({ id: linkedAccounts?.Id });
  const linkedAccountsArray =
    linkedAccountsList &&
    linkedAccountsList.filter((item: any) =>
      item.toLowerCase().includes(linkedAccountsSearch.toLowerCase()),
    );
  // const toggleLinkedAccounts = () => setLinkedAccounts(!linkedAccounts);

  const importProcedureTabs = [
    {
      id: 'globalProcedures',
      label: 'Global',
      content: (
        <Flex flexDirection="column" height="100%">
          <ProcedureSearch>
            <Checkbox
              handleChange={() => {
                if (allGlobalsSelected) {
                  setSelectedGlobal([]);
                } else if (globalProcedures) {
                  setSelectedGlobal([
                    ...globalProcedures.map((i: any) => i.Id),
                  ]);
                }
              }}
              checked={allGlobalsSelected}
              label=" "
              name="selectAllGlobalForImport"
            />
            <div className="search-input">
              <Icon.Search />
              <input
                value={globalSearch}
                onChange={e => setGlobalSearch(e.target.value)}
                type="search"
                placeholder="Search procedure name or Id"
              />
            </div>
          </ProcedureSearch>
          <Box flex="1 0 auto" height="1px" overflow="auto">
            <ProcedureList>
              {globalProcedures &&
                globalProcedures.map((item: any) => {
                  const isSelected = selectedGlobalProcedures?.find(
                    i => i === item.Id,
                  );
                  return (
                    <Flex key={`Global_${item?.AccountId}_${item?.Id}`}>
                      <Checkbox
                        handleChange={() => {
                          if (isSelected) {
                            setSelectedGlobal([
                              ...selectedGlobalProcedures.filter(
                                i => i !== item.Id,
                              ),
                            ]);
                          } else {
                            setSelectedGlobal([
                              ...selectedGlobalProcedures,
                              item.Id,
                            ]);
                          }
                        }}
                        checked={!!isSelected}
                        key={`Global_${item?.AccountId}_${item?.Id}`}
                        label={item.Name}
                        title={item.Name}
                        name={`Global_${item?.AccountId}_${item?.Id}`}
                        className="pb-cb__import-procedure"
                      />

                      <ProcedureAction
                        onClick={() => {
                          setLinkedAccounts(item);
                        }}
                        title="View linked accounts"
                      >
                        <Icon.Globe
                          fill="var(--color-state-success)"
                          title="View linked accounts"
                        />
                      </ProcedureAction>
                    </Flex>
                  );
                })}
            </ProcedureList>
          </Box>
        </Flex>
      ),
    },
    {
      id: 'exsitingAccounts',
      label: 'Existing Accounts',
      content: (
        <Flex flexDirection="column" height="100%">
          <ProcedureSearch>
            <Checkbox
              handleChange={() => {
                setSelectedExisting([]);
                setAllAccountsSelected(!allAccountsSelected);
              }}
              checked={allAccountsSelected}
              label=" "
              name="selectAllExistingAccounts"
            />
            <div className="search-input">
              <Icon.Search />
              <input
                onChange={e => {
                  setSearchKeyword(e.target.value);
                  // setSelectedExisting([]);
                  setAllAccountsOpened(false);
                }}
                value={searchKeyword}
                type="search"
                placeholder="Search account name or CID"
              />
            </div>
            <Box ml="auto" mr="-7px">
              <Button
                variant="subtle"
                size="sm"
                iconBefore={
                  allAccountsOpenend ? (
                    <Icon.ChevronUp title="Expand All / Collapse All" />
                  ) : (
                    <Icon.ChevronDown title="Expand All / Collapse All" />
                  )
                }
                title="Expand All / Collapse All"
                // onClick={expandOrCollapseAll}
                onClick={() => {
                  setAllAccountsOpened(!allAccountsOpenend);
                  setOpenedAccountIds([]);
                }}
              />
            </Box>
          </ProcedureSearch>
          <Box
            flex="1 0 auto"
            height="1px"
            overflowY="auto"
            overflowX="hidden"
            ref={existingAccountsRef}
          >
            <AccountList>
              {existingAccounts &&
                existingAccounts.map((account: any) => {
                  const isOpnened = opendAccountIds.find(
                    id => id === account.Id,
                  );
                  let isAllSelected = false;
                  const filtered = account.AccountProcedures?.filter(
                    (item: any) => {
                      const isExists = selectedExistingProcedures.find(
                        i => i === item.ProcedureId,
                      );
                      return !!isExists;
                    },
                  );
                  isAllSelected = !!(
                    filtered &&
                    filtered.length > 0 &&
                    account &&
                    account?.AccountProcedures &&
                    filtered?.length === account?.AccountProcedures.length
                  );
                  return (
                    <Accordion
                      key={account.AccountNameAndNumber}
                      opened={allAccountsOpenend || !!isOpnened}
                      setExpanded={(opened: boolean) => {
                        setOpenedAccountIds(
                          (ids: Array<number>): Array<number> => {
                            if (allAccountsOpenend) {
                              setAllAccountsOpened(false);
                              return [
                                ...existingAccounts
                                  .map((item: any) => item.Id)
                                  .filter((id: any) => id !== account.Id),
                              ];
                            }
                            if (opened) {
                              return [...ids, account.Id];
                            } else {
                              return [...ids.filter(id => id !== account.Id)];
                            }
                          },
                        );
                      }}
                      toggleButtonVariant="subtle"
                      className="pb-link-listitem"
                      headerContent={
                        <Checkbox
                          handleChange={() => {
                            if (allAccountsSelected) {
                              let selectedExisting: Array<number> = [];
                              existingAccounts?.forEach((item: any) => {
                                if (
                                  item.AccountProcedures &&
                                  item.Id !== account.Id
                                ) {
                                  const procedures = item.AccountProcedures.map(
                                    (proc: any) => proc?.ProcedureId,
                                  );
                                  selectedExisting = [
                                    ...selectedExisting,
                                    ...procedures,
                                  ];
                                }
                              });
                              setSelectedExisting([...selectedExisting]);
                              setAllAccountsSelected(false);
                              return;
                            }
                            if (isAllSelected) {
                              setSelectedExisting([
                                ...selectedExistingProcedures.filter(i => {
                                  const isExists =
                                    account?.AccountProcedures?.find(
                                      (item: any) => i === item.ProcedureId,
                                    );
                                  return !isExists;
                                }),
                              ]);
                            } else {
                              // remove all selected from this account
                              const newSelected = [
                                ...selectedExistingProcedures.filter(i => {
                                  const isExists =
                                    account?.AccountProcedures?.find(
                                      (item: any) => i === item.ProcedureId,
                                    );
                                  return !isExists;
                                }),
                              ];
                              const accountPIDs =
                                account?.AccountProcedures?.map(
                                  (item: any) => item.ProcedureId,
                                );
                              if (accountPIDs)
                                setSelectedExisting([
                                  ...newSelected,
                                  ...accountPIDs,
                                ]);
                            }
                          }}
                          checked={allAccountsSelected || isAllSelected}
                          label={account.AccountNameAndNumber}
                          title={account.AccountNameAndNumber}
                          name={account.AccountNameAndNumber}
                          className="pb-cb__link-account"
                        />
                      }
                    >
                      <ProcedureList>
                        {account.AccountProcedures?.map(
                          (accountProcedure: any) => {
                            const isSelected = selectedExistingProcedures?.find(
                              i => i === accountProcedure.ProcedureId,
                            );
                            return (
                              <Flex
                                key={`account.AccountProcedures${accountProcedure.ProcedureId}`}
                              >
                                <Checkbox
                                  handleChange={() => {
                                    if (allAccountsSelected) {
                                      let selectedExisting: Array<number> = [];
                                      existingAccounts?.forEach((item: any) => {
                                        if (item.AccountProcedures) {
                                          const procedures =
                                            item?.AccountProcedures?.map(
                                              (proc: any) => proc?.ProcedureId,
                                            );
                                          selectedExisting = [
                                            ...selectedExisting,
                                            ...procedures.filter(
                                              (i: any) =>
                                                i !==
                                                accountProcedure.ProcedureId,
                                            ),
                                          ];
                                        }
                                      });
                                      setSelectedExisting([
                                        ...selectedExisting,
                                      ]);
                                      setAllAccountsSelected(false);
                                      return;
                                    }
                                    if (isSelected) {
                                      setSelectedExisting([
                                        ...selectedExistingProcedures.filter(
                                          i =>
                                            i !== accountProcedure.ProcedureId,
                                        ),
                                      ]);
                                    } else {
                                      setSelectedExisting([
                                        ...selectedExistingProcedures,
                                        accountProcedure.ProcedureId,
                                      ]);
                                    }
                                  }}
                                  checked={allAccountsSelected || !!isSelected}
                                  key={
                                    account.AccountNameAndNumber +
                                    accountProcedure.ProcedureId
                                  }
                                  label={accountProcedure.Procedure.Name}
                                  title={accountProcedure.Procedure.Name}
                                  name={
                                    account.AccountNameAndNumber +
                                    accountProcedure.ProcedureId
                                  }
                                  className="pb-cb__import_existing-procedure"
                                />
                                <ProcedureAction
                                  noAction
                                  title={
                                    accountProcedure?.IsVisible
                                      ? 'Active'
                                      : 'Hidden'
                                  }
                                >
                                  {accountProcedure?.IsVisible ? (
                                    <Icon.Eye
                                      fill="var(--color-neutral-contrast-05)"
                                      title="Active"
                                    />
                                  ) : (
                                    <Icon.EyeOff
                                      fill="var(--color-neutral-contrast-05)"
                                      title="Hidden"
                                    />
                                  )}
                                </ProcedureAction>
                              </Flex>
                            );
                          },
                        )}
                      </ProcedureList>
                    </Accordion>
                  );
                })}
            </AccountList>
          </Box>
        </Flex>
      ),
    },
    {
      id: 'archivedProcedures',
      label: 'Archived',
      content: (
        <Flex flexDirection="column" height="100%">
          <ProcedureSearch>
            <Checkbox
              handleChange={() => {
                if (allArchivedSelected) {
                  setSelectedArchivedProcedures([]);
                } else if (archivedProcedures) {
                  setSelectedArchivedProcedures([
                    ...archivedProcedures.map((i: any) => i.ProcedureId),
                  ]);
                }
              }}
              checked={allArchivedSelected}
              label=" "
              name="selectAllArchived"
            />
            <div className="search-input">
              <Icon.Search />
              <input
                value={archiveSearch}
                onChange={e => setArchiveSearch(e.target.value)}
                type="search"
                placeholder="Search procedure name or Id"
              />
            </div>
          </ProcedureSearch>
          <Box flex="1 0 auto" height="1px" overflow="auto">
            <ProcedureList>
              {archivedProcedures &&
                archivedProcedures.map((item: any) => {
                  const isSelected = selectedArchivedProcedures?.find(
                    i => i === item.ProcedureId,
                  );
                  return (
                    <Flex
                      key={
                        'archivedProcedures' +
                        item.ProcedureId +
                        item?.Procedure?.Name
                      }
                    >
                      <Checkbox
                        handleChange={() => {
                          if (isSelected) {
                            setSelectedArchivedProcedures([
                              ...selectedArchivedProcedures.filter(
                                i => i !== item.ProcedureId,
                              ),
                            ]);
                          } else {
                            setSelectedArchivedProcedures([
                              ...selectedArchivedProcedures,
                              item.ProcedureId,
                            ]);
                          }
                        }}
                        checked={!!isSelected}
                        key={item.Procedure.Name}
                        label={item.Procedure.Name}
                        title={item.Procedure.Name}
                        name={item.Procedure.Name}
                        className="pb-cb__import-procedure"
                      />
                      <ProcedureAction
                        title="Remove procedure"
                        onClick={() => {
                          confirmAlert({
                            message:
                              'Are you sure you want to delete the procedure(s)?',
                            buttons: [
                              {
                                label: 'Yes',
                                onClick: () => {
                                  getProcedureReference(
                                    {
                                      procedureIds: [item?.ProcedureId],
                                      accountId: item.AccountId,
                                      isRemove: true,
                                    },
                                    {
                                      onSuccess: (
                                        mutationResult: Array<string>,
                                      ) => {
                                        if (
                                          mutationResult &&
                                          mutationResult.length &&
                                          (mutationResult.length > 1 ||
                                            !mutationResult[0].includes(
                                              '0000_POLICIESMENU.html',
                                            ))
                                        ) {
                                          confirmAlert({
                                            message:
                                              'This procedure has been linked to other procedures. Are you sure you want to delete the procedure?',
                                            buttons: [
                                              {
                                                label: 'Yes',
                                                onClick: () => {
                                                  deleteProcedure(
                                                    {
                                                      procedureIds: [
                                                        item?.ProcedureId,
                                                      ],
                                                      accountId: item.AccountId,
                                                      isHardDelete: true,
                                                    },
                                                    {
                                                      onSuccess: () => {
                                                        setSelectedArchivedProcedures(
                                                          [
                                                            ...selectedArchivedProcedures.filter(
                                                              i =>
                                                                i !==
                                                                item?.ProcedureId,
                                                            ),
                                                          ],
                                                        );
                                                      },
                                                    },
                                                  );
                                                },
                                                className: 'pr-btn-primary',
                                              },
                                              {
                                                label: 'No',
                                                onClick: () => {
                                                  //
                                                },
                                              },
                                            ],
                                          });
                                        } else {
                                          deleteProcedure(
                                            {
                                              procedureIds: [item.ProcedureId],
                                              accountId: item.AccountId,
                                              isHardDelete: true,
                                            },
                                            {
                                              onSuccess: () => {
                                                setSelectedArchivedProcedures([
                                                  ...selectedArchivedProcedures.filter(
                                                    i => i !== item.ProcedureId,
                                                  ),
                                                ]);
                                              },
                                            },
                                          );
                                        }
                                      },
                                      onError: (error: { message: string }) => {
                                        showErrorToast({
                                          message: error.message,
                                        });
                                      },
                                    },
                                  );
                                },
                                className: 'pr-btn-primary',
                              },
                              {
                                label: 'No',
                                onClick: () => {
                                  //
                                },
                              },
                            ],
                          });
                        }}
                      >
                        {/* <Button
                          variant="subtle"
                          size="sm"
                          title="Remove procedure"

                        > */}
                        <Icon.Delete
                          title="Remove procedure"
                          fill="var(--color-neutral-contrast-06)"
                        />
                        {/* </Button> */}
                      </ProcedureAction>
                    </Flex>
                  );
                })}
            </ProcedureList>
          </Box>
        </Flex>
      ),
    },
  ];

  //To clear out all selected procedures
  const resetSelectedProcedures = () => {
    setSelectedGlobal([]);
    setSelectedExisting([]);
    setSelectedArchivedProcedures([]);
    setOpenedAccountIds([]);
    setAllAccountsSelected(false);
  };

  //To clear out all searched values
  const clearSearches = () => {
    setGlobalSearch('');
    setSearchKeyword('');
    setArchiveSearch('');
  };

  //Import Panel
  const panelActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          togglePanel && togglePanel();
          resetSelectedProcedures();
          clearSearches();
        }}
      >
        Cancel
      </Button>

      <Tooltip
        animation="fade"
        // title="Procedures will be imported as invisible. Don't forget to mark them as visible when you're done!"
        position="top"
        arrow={true}
        arrowSize="small"
        html={
          <div style={{ textAlign: 'left', width: '240px' }}>
            Procedures will be imported as invisible. Don&apos;t forget to mark
            them as visible when you&apos;re done!
          </div>
        }
      >
        <Button
          disabled={(() => {
            const allSelections = getAllSelectedProcedures();
            if (allSelections.length > 0) return false;
            return true;
          })()}
          onClick={() => {
            setInvisibleState(true);
            const allSelections = getAllSelectedProcedures();
            if (allSelections.length > 0) {
              importProcedures(
                {
                  accountId,
                  checkedProcedures: allSelections,
                  invisible: true,
                },
                {
                  onSuccess: (e: any) => {
                    if (e && e.length > 0 && e instanceof Array) {
                      setDuplicateProcedures(e);
                      setIsDuplicateModal(true);
                      return;
                    }
                    showSuccessToast({
                      message: `Procedure${
                        allSelections.length > 1 ? '(s)' : ''
                      } Imported Successfully`,
                    });
                    resetSelectedProcedures();
                    togglePanel();
                  },
                  onError: (e: any) => {
                    if (e && e[0])
                      showErrorToast({
                        message: e[0],
                      });
                  },
                },
              );
            } else {
              showWarningToast({
                message: 'Please select atleast one procedure to proceed.',
              });
            }
          }}
          variant="primary"
        >
          Import as Invisible
        </Button>
      </Tooltip>

      <Tooltip
        animation="fade"
        position="top"
        arrow={true}
        arrowSize="small"
        html={
          <div style={{ textAlign: 'left', width: '220px' }}>
            Procedures will be accessible to users right away.
          </div>
        }
      >
        <Button
          variant="danger"
          disabled={(() => {
            const allSelections = getAllSelectedProcedures();
            if (allSelections.length > 0) return false;
            return true;
          })()}
          onClick={() => {
            setInvisibleState(false);
            const allSelections = getAllSelectedProcedures();
            if (allSelections.length > 0) {
              importProcedures(
                {
                  accountId,
                  checkedProcedures: allSelections,
                  invisible: false,
                },
                {
                  onSuccess: (e: any) => {
                    if (e && e.length > 0 && e instanceof Array) {
                      setDuplicateProcedures(e);
                      setIsDuplicateModal(true);
                      return;
                    }
                    showSuccessToast({
                      message: `Procedure${
                        allSelections.length > 1 ? '(s)' : ''
                      } Imported Successfully`,
                    });
                    resetSelectedProcedures();
                    togglePanel();
                  },
                  onError: (e: any) => {
                    if (e && e[0])
                      showErrorToast({
                        message: e[0],
                      });
                  },
                },
              );
            } else {
              showWarningToast({
                message: 'Please select atleast one procedure to proceed.',
              });
            }
          }}
        >
          Import &amp; Publish
        </Button>
      </Tooltip>
    </>
  );

  //Clear import duplication panel
  const resetDuplicatePanel = () => {
    setIsDuplicateModal && setIsDuplicateModal(false);
    setSelectedDuplicates([]);
    setSelectedSkips([]);
    setInvisibleState(false);
  };

  //Import duplication panel
  const duplicatePanelActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          resetDuplicatePanel();
        }}
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        disabled={
          (selectedSkips && selectedSkips.length > 0) ||
          (selectedDuplicates && selectedDuplicates.length > 0)
            ? false
            : true
        }
        onClick={() => {
          if (selectedDuplicates && selectedDuplicates.length > 0) {
            duplicateImportedProcedure(
              {
                accountId,
                selectedDuplicates,
                invisible: invisibleState,
              },
              {
                onSuccess: (e: any) => {
                  showSuccessToast({
                    message: `Procedure${
                      selectedDuplicates.length > 1 ? '(s)' : ''
                    } Imported Successfully`,
                  });
                  resetDuplicatePanel();
                  resetSelectedProcedures();
                  togglePanel();
                },
                onError: (e: any) => {
                  if (e && e instanceof Array) {
                    showErrorToast({
                      message: e[0],
                    });
                  } else {
                    showErrorToast({
                      message: e,
                    });
                  }
                },
              },
            );
          } else {
            showSuccessToast({
              message: 'Updated Successfully',
            });
            resetDuplicatePanel();
            resetSelectedProcedures();
            togglePanel();
          }
        }}
      >
        Continue
      </Button>
    </>
  );

  const globalProceduresSelectedList = getAllSelectedGlobalProcedures();
  const archiveProceduresSelectedList = getAllSelectedArchivedProcedures();
  const selectedAccounts = getAllSelectedAccounts();

  //duplicate checkbox for each entry
  const handleDuplicate = (data: any) => {
    const selected = selectedDuplicates ? selectedDuplicates : [];
    const index =
      selected && selected.findIndex((item: any) => item.Id === data.Id);
    if (index === -1) {
      data.IsCopy = true;
      const duplicate = [...selected, data];
      setSelectedDuplicates(duplicate);
    } else {
      data.IsCopy = null;
      const duplicate = selectedDuplicates?.filter(
        (item: any) => item.Id !== data.Id,
      );
      setSelectedDuplicates(duplicate);
    }
  };

  //skip checkbox for each entry
  const handleSkip = (data: any) => {
    const selected = selectedSkips ? selectedSkips : [];
    const index =
      selected && selected.findIndex((item: any) => item.Id === data.Id);
    if (index === -1) {
      data.IsSkip = true;
      const skip = [...selected, data];
      setSelectedSkips(skip);
    } else {
      data.IsSkip = null;
      const skip = selectedSkips?.filter((item: any) => item.Id !== data.Id);
      setSelectedSkips(skip);
    }
  };

  //check if all rows are  selected to be duplicated
  const isAllDuplicateChecked = () => {
    const selectedData = duplicateProcedures?.filter((item: any) => {
      if (item.IsCopy === true) return item;
    });
    if (
      selectedData?.length ===
      (duplicateProcedures && duplicateProcedures.length > 0
        ? duplicateProcedures.length
        : null)
    )
      return true;
    else return false;
  };

  //select all to duplicate
  const handleSelectAllDuplicates = () => {
    let selected: any[] | undefined = [];
    const selectedState = isAllDuplicateChecked();
    if (!selectedState) {
      selected = duplicateProcedures?.map((item: any) => {
        item.IsCopy = true;
        return item;
      });
      setSelectedDuplicates(selected);
    } else {
      selected = duplicateProcedures?.map((item: any) => {
        item.IsCopy = false;
        return item;
      });
      setSelectedDuplicates([]);
    }
  };

  //check if all rows are selected to be skipped
  const isAllSkipChecked = () => {
    const selectedData = duplicateProcedures?.filter(
      (item: any) => item.IsSkip === true,
    );
    if (
      selectedData?.length ===
      (duplicateProcedures && duplicateProcedures.length > 0
        ? duplicateProcedures.length
        : null)
    )
      return true;
    else return false;
  };

  //select all to skip
  const handleSelectAllSkips = () => {
    let selected: any[] | undefined = [];
    const selectedState = isAllSkipChecked();
    if (!selectedState) {
      selected = duplicateProcedures?.map((item: any) => {
        item.IsSkip = true;
        return item;
      });
      setSelectedSkips(selected);
    } else {
      selected = duplicateProcedures?.map((item: any) => {
        item.IsSkip = false;
        return item;
      });
      setSelectedSkips([]);
    }
  };

  const getArchivedTime = (archivedDate: any) => {
    const timezone = jstz.determine();
    const ts = dayjs(archivedDate + 'Z');
    const x = ts.tz(timezone.name());
    // const timeValue = x.format('YYYY MMM, DD ddd hh:mm A z');
    const timeValue = x.format('MMM DD, YYYY [at] hh:mm A');
    return timeValue;
  };

  return (
    <>
      <SlidePanel
        open={isVisible}
        onClose={() => {
          togglePanel && togglePanel();
          resetSelectedProcedures();
          clearSearches();
        }}
        size="lg"
        title="Import Procedure"
        actions={panelActions()}
      >
        <Flex flexDirection="column" style={{ height: '100%' }}>
          <Flex flex="1 1 auto">
            <Card
              width="345px"
              flexDirection="column"
              style={{ display: 'flex' }}
            >
              <CardHeader p={3} bg="var(--color-neutral-contrast-02)">
                <Text
                  as="h4"
                  fontWeight="medium"
                  lineHeight={1}
                  color="text.body"
                >
                  Procedures
                </Text>
              </CardHeader>
              <Tabs tabs={importProcedureTabs} importProcedure />
            </Card>

            <Card
              flex="1"
              ml={3}
              position="relative"
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
              // bg="var(--color-neutral-contrast-01)"
            >
              {/* <EmptyState>
            <NoDataImage feedback="Please select a procedure to get started" />
          </EmptyState> */}
              <CardHeader p={3} bg="var(--color-neutral-contrast-02)">
                <Text
                  as="h4"
                  fontWeight="medium"
                  lineHeight={1}
                  color="text.body"
                >
                  {`${getAllSelectedProcedures().length} procedures selected`}
                </Text>
              </CardHeader>

              <StickyListWrap overflowY="auto" flex="1 0 auto" height="1px">
                <StickyList>
                  {globalProceduresSelectedList.length > 0 && (
                    <>
                      <StickyListTitle borderTop="none">
                        Global Procedures
                      </StickyListTitle>
                      {globalProceduresSelectedList.map(item => (
                        <StickyListItem
                          key={`Global_${item?.AccountId}_${item?.Id}`}
                        >
                          {item?.Name}
                          <Button
                            variant="subtle"
                            size="sm"
                            title="Preview procedure"
                            onClick={() => {
                              if (item)
                                setShowPreview({
                                  accountId: item.AccountId,
                                  procedureId: item.Id,
                                });
                            }}
                          >
                            <Icon.Document title="Preview procedure" />
                          </Button>
                          <Button
                            variant="subtle"
                            size="sm"
                            title="Remove procedure"
                            onClick={() => {
                              setSelectedGlobal([
                                ...selectedGlobalProcedures.filter(
                                  i => i !== item?.Id,
                                ),
                              ]);
                            }}
                          >
                            <Icon.X title="Remove procedure" />
                          </Button>
                        </StickyListItem>
                      ))}
                    </>
                  )}
                </StickyList>
                <StickyList>
                  {selectedAccounts.length > 0 && (
                    <>
                      {selectedAccounts.map(({ accountName, procedures }) => (
                        <>
                          <StickyListTitle>{accountName}</StickyListTitle>
                          {procedures.map(item => (
                            <>
                              <StickyListItem key={item?.Id}>
                                {item?.Name}
                                <Button
                                  variant="subtle"
                                  size="sm"
                                  title="Preview procedure"
                                  onClick={() => {
                                    if (item)
                                      setShowPreview({
                                        accountId: item.AccountId,
                                        procedureId: item.Id,
                                      });
                                  }}
                                >
                                  <Icon.Document title="Preview procedure" />
                                </Button>
                                <Button
                                  variant="subtle"
                                  size="sm"
                                  title="Remove procedure"
                                  onClick={() => {
                                    if (allAccountsSelected) {
                                      let selectedExisting: Array<number> = [];
                                      existingAccounts?.forEach(
                                        (acItem: any) => {
                                          if (acItem.AccountProcedures) {
                                            const procedures =
                                              acItem.AccountProcedures.map(
                                                (proc: any) =>
                                                  proc?.ProcedureId,
                                              );
                                            selectedExisting = [
                                              ...selectedExisting,
                                              ...procedures.filter(
                                                (i: any) => i !== item.Id,
                                              ),
                                            ];
                                          }
                                        },
                                      );
                                      setSelectedExisting([
                                        ...selectedExisting,
                                      ]);
                                      setAllAccountsSelected(false);
                                      return;
                                    } else {
                                      setSelectedExisting([
                                        ...selectedExistingProcedures.filter(
                                          i => i !== item.Id,
                                        ),
                                      ]);
                                    }
                                  }}
                                >
                                  <Icon.X title="Remove procedure" />
                                </Button>
                              </StickyListItem>
                            </>
                          ))}
                        </>
                      ))}
                    </>
                  )}
                </StickyList>
                <StickyList>
                  {archiveProceduresSelectedList.length > 0 && (
                    //LIST ON THE RIGHT SIDE
                    <>
                      <StickyListTitle>Archived procedures</StickyListTitle>
                      {archiveProceduresSelectedList.map(item => (
                        <StickyListItem key={item?.Id}>
                          <Stack direction="y" gap={1}>
                            <Text as="span">{item?.Procedure?.Name}</Text>
                            <Flex alignItems="center">
                              <Text
                                as="small"
                                fontSize="0.75rem"
                                fontWeight="medium"
                                color="var(--color-neutral-contrast-05)"
                              >{`Archived on ${getArchivedTime(
                                item?.LastUpdatedOn,
                              )} by ${item?.LastUpdatedBy}`}</Text>
                              {/* <Text
                                as="small"
                                fontSize={1}
                                fontWeight="medium"
                                color="var(--color-neutral-contrast-03)"
                                mx={2}
                              >
                                |
                              </Text>
                              <Text
                                as="small"
                                fontSize="0.75rem"
                                fontWeight="medium"
                                color="var(--color-neutral-contrast-05)"
                              >
                                {`Archived on: ${getArchivedTime(
                                  item?.LastUpdatedOn,
                                )}`}
                              </Text> */}
                            </Flex>
                          </Stack>
                          <Button
                            variant="subtle"
                            size="sm"
                            title="Preview procedure"
                            onClick={() => {
                              if (item)
                                setShowPreview({
                                  accountId: item.AccountId,
                                  procedureId: item.ProcedureId,
                                });
                            }}
                          >
                            <Icon.Document title="Preview procedure" />
                          </Button>
                          <Button
                            variant="subtle"
                            size="sm"
                            title="Remove procedure"
                            onClick={() => {
                              setSelectedArchivedProcedures([
                                ...selectedArchivedProcedures.filter(
                                  i => i !== item?.ProcedureId,
                                ),
                              ]);
                            }}
                          >
                            <Icon.X title="Remove procedure" />
                          </Button>
                        </StickyListItem>
                        //LIST ON THE RIGHT SIDE
                      ))}
                    </>
                  )}
                </StickyList>
              </StickyListWrap>
            </Card>
          </Flex>
          <PreviewPanel
            previewProcedure={!!showPreview}
            closePreviewProcedure={() => {
              setShowPreview(undefined);
            }}
            selectedIds={showPreview}
          />

          {/* Linked Accounts */}
          <SlidePanel
            title={linkedAccounts && linkedAccounts?.Name}
            size="sm"
            open={!!linkedAccounts}
            onClose={() => setLinkedAccounts(undefined)}
          >
            <Flex flexDirection="column" height="100%">
              <Box>
                <SearchInput
                  value={linkedAccountsSearch}
                  handleChange={(value: any) => setLinkedAccountsSearch(value)}
                  placeholder="Search linked accounts"
                  mb={3}
                />
              </Box>
              <Stack
                direction="y"
                gap={3}
                flex="1 0 auto"
                height="1px"
                overflowY="auto"
              >
                {isLinkedAccountsLoading && <Loader />}
                {linkedAccountsArray &&
                  linkedAccountsArray.map((item: any) => (
                    <Text key={item}>{item}</Text>
                  ))}
              </Stack>
            </Flex>
          </SlidePanel>
          {isLoading && <Loader />}
        </Flex>
      </SlidePanel>
      <SlidePanel
        open={isDuplicateModal}
        onClose={() => {
          setIsDuplicateModal && setIsDuplicateModal(!isDuplicateModal);
          setInvisibleState(false);
        }}
        size="lg"
        title="Import Procedure"
        actions={duplicatePanelActions()}
      >
        <Flex flexDirection="column" height="100%">
          <Stack direction="y" gap={3}>
            <Alert variant="warning" icon={<Icon.Warning />}>
              Below procedures cannot be imported as the procedure name already
              exists
            </Alert>
            <DataTableWrapper>
              <DataTable className="choice-qn-dependency without-search">
                <thead>
                  <tr>
                    <th>Procedures</th>
                    <th>
                      <Checkbox
                        label="Duplicate All"
                        name="selectAllDuplicate"
                        checked={isAllDuplicateChecked()}
                        handleChange={() => handleSelectAllDuplicates()}
                        disabled={
                          selectedSkips && selectedSkips.length > 0
                            ? true
                            : false
                        }
                      />
                    </th>
                    <th>
                      <Checkbox
                        label="Skip All"
                        name="selectAllSkip"
                        checked={isAllSkipChecked()}
                        handleChange={() => handleSelectAllSkips()}
                        disabled={
                          selectedDuplicates && selectedDuplicates.length > 0
                            ? true
                            : false
                        }
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {duplicateProcedures &&
                    duplicateProcedures.map((procedure: any) => {
                      return (
                        <tr key={procedure.Id}>
                          <td>{`${procedure.Name} (${
                            procedure.IsGlobal
                              ? 'Global'
                              : procedure.AccountName
                          })`}</td>
                          <td>
                            <Checkbox
                              label="Duplicate"
                              name={`duplicate-${procedure.Id}`}
                              checked={procedure.IsCopy ? true : false}
                              handleChange={() => handleDuplicate(procedure)}
                              disabled={procedure.IsSkip ? true : false}
                            />
                          </td>
                          <td>
                            <Checkbox
                              label="Skip"
                              name={`skip-${procedure.Id}`}
                              checked={procedure.IsSkip ? true : false}
                              handleChange={() => handleSkip(procedure)}
                              disabled={procedure.IsCopy ? true : false}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </DataTable>
            </DataTableWrapper>
          </Stack>
        </Flex>
        {isDuplicateImportsLoading && <Loader />}
      </SlidePanel>
    </>
  );
}
