import { useQuery, QueryResult } from 'react-query';
import { getCallTypes } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchCallTypes(): Promise<APIResult> {
  const response = await fetch(getCallTypes, {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type CallTypeResult = {
  Abbreviation: string;
  Description: string;
  Id: number;
  Priority: number;
};
export type APIResult = Array<CallTypeResult>;

export type ResultItem = {
  id: number;
  name: string;
};
export type TResult = Array<ResultItem>;

export type TError = { message: string };

function useCallTypes(): QueryResult<TResult, TError> {
  return useQuery(
    'get_call_types',
    async () => {
      const fetchResponse = await fetchCallTypes();
      const result = fetchResponse.map(item => ({
        id: item.Id,
        name: item.Description,
      }));
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useCallTypes };
