import { useQuery, QueryResult } from 'react-query';
import { linkedProcedures } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchLinkedAccounts({ id }: TVariables): Promise<TResult> {
  const response = await fetch(linkedProcedures(id), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = Array<string> | undefined;

export type TError = { message: string };
export type TVariables = {
  id: number;
};

function useGetLinkedAccounts({
  id,
}: {
  id?: number;
}): QueryResult<TResult, TError> {
  return useQuery(
    ['get_linked_accounts', id],
    async () => {
      if (id)
        return await fetchLinkedAccounts({
          id,
        });
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetLinkedAccounts };
