import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Text, Flex } from 'rebass/styled-components';
import { Card, CardHeader, CardBody, Accordion } from 'ui/patterns';
import * as Icon from 'assets/icons';
import { Checkbox } from 'ui/components';
import {
  ProcedureList as ProcedureType,
  SelectedAccounts,
  AccountProcedures,
} from 'types/procedureBuilder';
import NoDataImage from 'assets/images/NoDataImage';
import { ProcedureSearch as SearchStyle } from '../styles';
import {
  AccountList as AccountListStyle,
  ProcedureList as ProcedureListStyle,
  ProcedureStyle,
} from './styles';

type AccountListType = {
  procedureListData: Array<ProcedureType>;
  expanded: undefined | Array<boolean>;
  keywordSearch: string;
  setProcedureHeaderData: (
    value:
      | AccountProcedures
      | ((prevState: AccountProcedures) => AccountProcedures),
  ) => void;
  setProcedureHeader: (
    value: boolean | ((prevState: boolean) => boolean),
  ) => void;
  setKeywordSearch: (value: string | ((prevState: string) => string)) => void;
  checkedAccounts: Array<SelectedAccounts>;
  setCheckedAccounts: (
    value:
      | Array<SelectedAccounts>
      | ((prevState: Array<SelectedAccounts>) => Array<SelectedAccounts>),
  ) => void;
  setExpanded: (
    value: Array<boolean> | ((prevState: Array<boolean>) => Array<boolean>),
  ) => void;
  onEndReached: () => void;
  onClickExpanded: (index: number, opened: boolean) => void;
  collapseAll: () => void;
  checkState: boolean;
  setCheckState: (value: boolean | ((prevState: boolean) => boolean)) => void;
};

export default function AccountsList({
  procedureListData,
  checkedAccounts,
  setCheckedAccounts,
  onEndReached,
  setProcedureHeader,
  setProcedureHeaderData,
  setKeywordSearch,
  keywordSearch,
  expanded,
  setExpanded,
  onClickExpanded,
  collapseAll,
  checkState,
  setCheckState,
}: AccountListType): ReactElement {
  // console.log('lastGroup, allGroups: procedureListData', procedureListData);
  const [isAllSelected, setIsAllSelected] = useState(false);
  useEffect(() => {
    if (checkState) {
      setCheckState(false);
      handleSelectAllAccounts();
    }
  }, [procedureListData]);

  useEffect(() => {
    const state = isAllChecked();
    setIsAllSelected(state);
  }, [checkedAccounts]);

  //Procedure Selection
  const isProcedureChecked = (procedureId: number, accountId: number) => {
    const selectedAccount = checkedAccounts?.find(
      account => account.id === accountId,
    );
    const selectedProcedures = selectedAccount?.accountProcedures?.find(
      (procedure: { ProcedureId: number }) =>
        procedure.ProcedureId === procedureId,
    );

    if (selectedProcedures?.isChecked) {
      return true;
    } else return false;
  };

  const handleSelectedProcedure = (
    selectedProcedure: AccountProcedures,
    selectedAccount: ProcedureType,
  ) => {
    setProcedureHeader(false);
    const accountIndex =
      checkedAccounts && checkedAccounts.length > 0
        ? checkedAccounts?.findIndex(
            (account: { id: number }) =>
              account.id === selectedProcedure.AccountId,
          )
        : -1;

    if (accountIndex === -1) {
      selectedAccount?.AccountProcedures?.map(
        (item: { ProcedureId: number; isChecked: boolean }) => {
          if (item.ProcedureId === selectedProcedure.ProcedureId) {
            item.isChecked = true;
          } else {
            item.isChecked = false;
          }
        },
      );
      checkedAccounts &&
        checkedAccounts.push({
          id: selectedAccount.Id,
          name: selectedAccount.Name,
          accountProcedures: selectedAccount?.AccountProcedures,
          accountNameAndNumber: selectedAccount?.AccountNameAndNumber,
          isAccountChecked: selectedAccount?.AccountProcedures?.every(
            (x: { isChecked: boolean }) => x.isChecked === true,
          ),
          policiesSelected: true,
        });
    } else {
      checkedAccounts[accountIndex]?.accountProcedures?.forEach(item => {
        if (item.ProcedureId === selectedProcedure.ProcedureId) {
          item.isChecked = !item.isChecked;
        }
      });
      let selectedProcedureCount = 0;
      checkedAccounts[accountIndex]?.accountProcedures?.forEach(procedure => {
        if (procedure.isChecked) {
          selectedProcedureCount += 1;
        }
      });

      checkedAccounts[accountIndex] = {
        id: checkedAccounts[accountIndex].id,
        name: checkedAccounts[accountIndex].name,
        accountProcedures: checkedAccounts[accountIndex]?.accountProcedures,
        accountNameAndNumber:
          checkedAccounts[accountIndex]?.accountNameAndNumber,
        isAccountChecked: checkedAccounts[
          accountIndex
        ]?.accountProcedures?.every(x => x.isChecked === true),
        policiesSelected: selectedProcedureCount ? true : false,
      };
    }

    const state = checkedAccounts[accountIndex]?.accountProcedures?.filter(
      x => x.isChecked === true,
    );

    if (
      state?.length !== checkedAccounts[accountIndex]?.accountProcedures?.length
    ) {
      setCheckState(false);
    }
    if (checkedAccounts) setCheckedAccounts([...checkedAccounts]);
  };
  //Procedure Selection

  //Account Selection
  const isAccountChecked = (item: ProcedureType) => {
    const selectedAccounts =
      checkedAccounts &&
      checkedAccounts.length > 0 &&
      checkedAccounts.find((account: SelectedAccounts) => {
        if (account.id === item.Id) {
          if (account.isAccountChecked === true) return account;
        }
      });

    if (selectedAccounts) {
      return true;
    } else {
      return false;
    }
  };

  const handleSelectedAccounts = (item: ProcedureType) => {
    setProcedureHeader(false);
    const index =
      checkedAccounts && checkedAccounts.length > 0
        ? checkedAccounts.findIndex(
            (account: { id: number }) => account.id === item.Id,
          )
        : -1;
    if (index === -1) {
      const procedures = item?.AccountProcedures?.map(element => {
        return {
          ...element,
          isChecked: true,
        };
      });
      checkedAccounts &&
        checkedAccounts.push({
          id: item.Id,
          name: item.Name,
          accountProcedures: procedures,
          accountNameAndNumber: item.AccountNameAndNumber,
          isAccountChecked: true,
          policiesSelected: true,
        });
      if (checkedAccounts.length === procedureListData.length) {
        setCheckState(true);
      }
    } else if (index >= 0) {
      setCheckState(false);
      checkedAccounts &&
        checkedAccounts.map((account: SelectedAccounts) => {
          if (account.id === item.Id) {
            account.isAccountChecked = !account.isAccountChecked;
            account.policiesSelected = account.isAccountChecked;
          }
          account?.accountProcedures?.map((element: AccountProcedures) => {
            if (item.Id === element.AccountId) {
              element.isChecked = account.isAccountChecked;
            }
          });
        });
    }

    if (checkedAccounts) setCheckedAccounts([...checkedAccounts]);
  };
  //Account Selection

  //Select All //
  const isAllChecked = () => {
    const selectedData = checkedAccounts?.filter(
      (x: SelectedAccounts) => x.isAccountChecked === true,
    );
    if (
      selectedData?.length ===
      (procedureListData && procedureListData.length > 0
        ? procedureListData.length
        : null)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSelectAllAccounts = () => {
    setProcedureHeader(false);
    let allDatas: SelectedAccounts[] = [];
    const isSelect = isAllChecked();
    if (!isSelect) {
      allDatas =
        procedureListData && procedureListData.length > 0
          ? procedureListData.map(item => {
              item?.AccountProcedures?.map(item => (item.isChecked = true));
              return {
                id: item.Id,
                name: item.Name,
                accountProcedures: item.AccountProcedures,
                accountNameAndNumber: item.AccountNameAndNumber,
                isAccountChecked: true,
                policiesSelected: true,
              };
            })
          : [];
      setCheckState(true);
    } else {
      allDatas = [];
      setCheckState(false);
    }
    setCheckedAccounts([...allDatas]);
  };
  //Select All //

  const handleKeywordSearch = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setKeywordSearch(value);
    collapseAll();
  };

  const tbodyRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleScroll = useCallback(
    (e: any) => {
      if (tbodyRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom) {
          onEndReached && onEndReached();
        }
      }
    },
    [onEndReached, tbodyRef],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  const isAnyProcedureChecked = (accountId: number) => {
    const indeterminedAccounts = checkedAccounts.find(accounts => {
      if (accounts.id === accountId) {
        if (
          accounts.policiesSelected === true &&
          accounts.isAccountChecked === false
        ) {
          return accounts;
        }
      }
    });
    if (indeterminedAccounts) {
      return true;
    } else return false;
  };

  const handleProcedureHeaderClick = (
    selectedProcedure: AccountProcedures,
    selectedAccount: ProcedureType,
  ) => {
    setProcedureHeader(true);
    setProcedureHeaderData({ ...selectedProcedure });
    const accountIndex =
      checkedAccounts && checkedAccounts.length > 0
        ? checkedAccounts?.findIndex(
            (account: { id: number }) =>
              account.id === selectedProcedure.AccountId,
          )
        : -1;
    if (accountIndex === -1) {
      selectedAccount?.AccountProcedures?.map(
        (item: {
          AccountId: number;
          ProcedureId: number;
          isChecked: boolean;
        }) => {
          if (item.ProcedureId === selectedProcedure.ProcedureId) {
            item.isChecked = true;
          } else item.isChecked = false;
        },
      );
      checkedAccounts &&
        checkedAccounts.push({
          id: selectedAccount.Id,
          name: selectedAccount.Name,
          accountProcedures: selectedAccount?.AccountProcedures,
          accountNameAndNumber: selectedAccount?.AccountNameAndNumber,
          isAccountChecked: selectedAccount?.AccountProcedures?.every(
            (x: { isChecked: boolean }) => x.isChecked === true,
          ),
          policiesSelected: true,
        });

      checkedAccounts?.map(accounts =>
        accounts?.accountProcedures?.map(procedure => {
          if (accounts.id !== selectedAccount.Id) {
            procedure.isChecked = false;
            accounts.isAccountChecked = false;
            accounts.policiesSelected = false;
          }
        }),
      );
    } else {
      checkedAccounts?.map(accounts =>
        accounts?.accountProcedures?.map(procedure => {
          if (procedure.ProcedureId === selectedProcedure.ProcedureId) {
            procedure.isChecked = true;
            accounts.policiesSelected = true;
            accounts.isAccountChecked =
              selectedAccount?.AccountProcedures?.length === 1 ? true : false;
          } else {
            if (selectedProcedure.AccountId !== accounts.id) {
              accounts.policiesSelected = false;
            }
            procedure.isChecked = false;
            accounts.isAccountChecked = false;
          }
        }),
      );
    }
    setCheckedAccounts([...checkedAccounts]);
  };

  return (
    <Card width="345px" flexDirection="column" style={{ display: 'flex' }}>
      <CardHeader
        p={3}
        bg="var(--color-neutral-contrast-01)"
        alignItems="center"
        style={{ display: 'flex' }}
      >
        <Text as="h4" fontWeight="medium" lineHeight={1} color="text.body">
          Accounts
        </Text>
      </CardHeader>
      <SearchStyle>
        <Checkbox
          label=" "
          key="selectAll"
          name="selectAllLinkProcedures"
          checked={isAllSelected}
          handleChange={() => {
            handleSelectAllAccounts();
          }}
        />
        <div className="search-input">
          <Icon.Search />
          <input
            type="search"
            placeholder="Search account name or CID"
            value={keywordSearch}
            onChange={(e: SyntheticEvent<HTMLInputElement>) =>
              handleKeywordSearch(e)
            }
          />
        </div>
      </SearchStyle>
      <CardBody
        flex="1 0 auto"
        overflowY="auto"
        overflowX="hidden"
        height="1px"
        ref={tbodyRef}
      >
        <AccountListStyle>
          {procedureListData &&
            procedureListData.length > 0 &&
            procedureListData.map((accounts, accountIndex) => (
              <Accordion
                key={`AccountsList-Accordion-${accounts.AccountNameAndNumber}`}
                toggleButtonVariant="subtle"
                className="pb-link-listitem"
                opened={expanded && expanded[accountIndex]}
                setExpanded={(opened: boolean) =>
                  onClickExpanded(accountIndex, opened)
                }
                headerContent={
                  <Checkbox
                    // key={accountIndex}
                    label={accounts.AccountNameAndNumber}
                    title={accounts.AccountNameAndNumber}
                    name={`${accounts.AccountNameAndNumber} - ${accounts.Name}`}
                    className="pb-cb__link-account"
                    checked={isAccountChecked(accounts)}
                    indeterminate={isAnyProcedureChecked(accounts.Id)}
                    handleChange={() => {
                      handleSelectedAccounts(accounts);
                    }}
                  />
                }
              >
                <ProcedureListStyle>
                  {accounts && accounts.AccountProcedures
                    ? accounts.AccountProcedures?.map(
                        (accountProcedures, procedureIndex) => (
                          <ProcedureStyle key={procedureIndex}>
                            <Checkbox
                              key={procedureIndex}
                              label=""
                              title={
                                accounts.Id + accountProcedures?.Procedure?.Name
                              }
                              name={
                                accounts.Id + accountProcedures?.ProcedureId
                              }
                              handleChange={() =>
                                handleSelectedProcedure(
                                  accountProcedures,
                                  accounts,
                                )
                              }
                              checked={isProcedureChecked(
                                accountProcedures?.ProcedureId,
                                accounts.Id,
                              )}
                            />

                            <Text
                              className="truncate"
                              title={accountProcedures?.Procedure?.Name}
                              style={{
                                position: 'relative',
                                top: '-1px',
                                cursor: 'pointer',
                                flex: 1,
                              }}
                              onClick={() =>
                                handleProcedureHeaderClick(
                                  accountProcedures,
                                  accounts,
                                )
                              }
                            >
                              {accountProcedures?.Procedure?.Name}
                            </Text>
                            <Flex
                              alignItems="center"
                              justifyContent="center"
                              mx={2}
                            >
                              {accountProcedures?.IsVisible ? (
                                <Icon.Eye
                                  fill="var(--color-neutral-contrast-05)"
                                  title="Active"
                                />
                              ) : (
                                <Icon.EyeOff
                                  fill="var(--color-neutral-contrast-05)"
                                  title="Hidden"
                                />
                              )}
                            </Flex>
                          </ProcedureStyle>
                        ),
                      )
                    : ''}
                </ProcedureListStyle>
              </Accordion>
            ))}
        </AccountListStyle>
        {procedureListData && procedureListData.length <= 0 && (
          <NoDataImage height="100%" feedback="No data available" />
        )}
      </CardBody>
    </Card>
  );
}
