import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { resetPolicyMenu } from '../utils/urls';
import { getTokenWithType } from './useAuth';

export async function resetPolciyMenu({
  params,
}: {
  params: TVariables;
}): Promise<TResult> {
  const response = await fetch(
    resetPolicyMenu(params.accountId, params.procedureId),
    {
      method: 'PUT',
      headers: {
        Authorization: getTokenWithType(),
        'Content-Type': 'application/json',
      },
    },
  );
  return response.json();
}

export type ValidationError = { Message?: string };

export type TResult = ValidationError & string;

export type TError = {
  Message: string;
};
export type TVariables = {
  procedureId: number | undefined;
  accountId: number | undefined;
};
export type TSnapshot = unknown;

function useResetPolicyMenu(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: TVariables) => {
      const result = await resetPolciyMenu({
        params: params,
      });
      if (result) {
        queryCache.invalidateQueries(
          ['get_procedure', params.accountId, params.procedureId],
          {
            refetchInactive: true,
          },
        );
      }
      if (result.Message) throw result;

      return result;
    },
    {
      throwOnError: true,
    },
  );
}

export { useResetPolicyMenu };
