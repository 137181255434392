import React, { ReactElement, SyntheticEvent, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { SlidePanel, FormInput, FormSelect } from 'ui/patterns';
import { Button, Stack, Checkbox } from 'ui/components';
import { useCallTypes } from 'hooks';
import { SectionTypeInput } from 'types/forms';
import { AccountInfoReturn } from 'types/accounts';

type SectionProps = {
  showPanel: boolean;
  togglePanel: () => void;
  handleAddEditSections: (sectionItem: SectionTypeInput) => void;
  currentSection: SectionTypeInput;
  accountInfoReturn: AccountInfoReturn | undefined;
  isNewForm: any;
};

export default function AddEditSection({
  showPanel,
  togglePanel,
  handleAddEditSections,
  currentSection,
  accountInfoReturn,
  isNewForm,
}: SectionProps): ReactElement {
  const { data: callTypes } = useCallTypes();
  const { register, handleSubmit, reset, control, errors } = useForm({
    defaultValues: {
      Title: 'New Section',
      IsActive: true,
    },
  });

  useEffect(() => {
    reset({
      Title: currentSection?.Title || 'New Section',
      IsActive: currentSection?.IsActive,
      IsClinicianAllowedToAddToCall:
        currentSection?.IsClinicianAllowedToAddToCall,
      CallTypeId: currentSection?.CallTypeId,
    });
  }, [currentSection, reset]);

  function resetForm() {
    reset({
      Title: 'New Section',
      IsActive: true,
      IsClinicianAllowedToAddToCall: false,
      CallTypeId: '',
    });
  }

  function onSubmit(data: SectionTypeInput) {
    let tempObj;
    if (data.CallTypeId !== '') {
      tempObj =
        callTypes && callTypes.find(item => item.id === data.CallTypeId);
    } else {
      tempObj = callTypes && callTypes.find(item => item.name === '');
    }

    const { id: Id, name: Description } = { ...tempObj };
    let callTypeObj;
    if (tempObj) {
      callTypeObj = Object.assign({}, { Id, Description });
    } else callTypeObj = null;

    const sectionParams = {
      $type: 'Proteus2.Api.Domain.Surveys.Section, Proteus2.Api.Domain',
      ...currentSection,
      ...data,
      CallTypeId: callTypeObj?.Id || null,
      Questions: currentSection ? [...currentSection.Questions] : [],
      CallType: callTypeObj,
      Id: currentSection ? currentSection?.Id : new Date().getTime(),
      Type: currentSection ? currentSection?.Type : undefined,
    };
    handleAddEditSections(sectionParams);
    resetForm();
    togglePanel();
  }

  const addSectionActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          togglePanel();
          resetForm();
        }}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit(onSubmit)}
        disabled={!showPanel}
      >
        Save
      </Button>
    </>
  );
  return (
    <>
      <SlidePanel
        title={`${
          currentSection === undefined ? 'Add New Section' : 'Edit Section'
        }`}
        actions={addSectionActions()}
        onClose={() => {
          togglePanel();
          resetForm();
        }}
        open={showPanel}
      >
        <Stack direction="y" gap={4}>
          <FormInput
            label="Section Title"
            type="text"
            register={register}
            name="Title"
            required
            feedback={errors.Title?.message}
            validation={errors.Title ? 'error' : undefined}
          />
          <Checkbox
            label="Make it Active?"
            register={register}
            name="IsActive"
          />
          {accountInfoReturn?.CID === '9000' && !isNewForm ? (
            <Checkbox
              label="Call taker can add to call?"
              register={register}
              name="IsClinicianAllowedToAddToCall"
            />
          ) : null}

          <Controller
            render={(props: {
              onChange: (value: number) => void;
              value: number;
            }) => {
              return (
                <FormSelect
                  value={props.value}
                  label="Call Type"
                  options={callTypes}
                  onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                    const value = parseInt(e.currentTarget.value, 10);
                    props.onChange(value);
                  }}
                />
              );
            }}
            defaultValue={''}
            name="CallTypeId"
            control={control}
          />
        </Stack>
      </SlidePanel>
    </>
  );
}
