import { useQuery, QueryResult } from 'react-query';
import { archivedProcedures } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { ProcedureListType } from 'types/procedureBuilder';

async function fetchAllAccounts({ accountId }: TVariables): Promise<TResult> {
  const response = await fetch(archivedProcedures(accountId), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = Array<ProcedureListType> | undefined;

export type TError = { message: string };
export type TVariables = {
  accountId: number;
};

function useGetArchivedProcedures(
  accountId: number,
): QueryResult<TResult, TError> {
  return useQuery(
    ['get_archived_procedures', accountId],
    async () => {
      if (accountId)
        return await fetchAllAccounts({
          accountId,
        });
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetArchivedProcedures };
