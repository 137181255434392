/* eslint-disable react/display-name */
import React from 'react';
import { Column } from 'react-table';
import { TagMappings } from 'types/tagMapping';
import { Button } from 'ui/components';
import * as Icon from 'assets/icons';

function contains(array: Array<string>, string: string): boolean {
  const index = array.findIndex(item => item === string);
  return index !== -1;
}

export default function useXmlTagColumns(
  columnsList?: Array<string>,
  removeTagMapping?: (fieldName: string) => void,
): Array<Column<TagMappings>> {
  return React.useMemo((): Array<Column<TagMappings>> => {
    if (columnsList) {
      const columns = [];
      if (contains(columnsList, 'Field Name'))
        columns.push(getFieldNameHeader());
      if (contains(columnsList, 'Mapped Name'))
        columns.push(getMappedNameHeader());
      columns.push(getOptionsHeader(removeTagMapping));
      return columns;
    }
    return [
      getFieldNameHeader(),
      getMappedNameHeader(),
      getOptionsHeader(removeTagMapping),
    ];
  }, [columnsList, removeTagMapping]);
}

function getFieldNameHeader(): Column<TagMappings> {
  return {
    Header: 'Field Name',
    accessor: 'FieldName',
    id: 'Field Name',
  };
}

function getMappedNameHeader(): Column<TagMappings> {
  return {
    Header: 'Mapped Name',
    accessor: 'MappedName',
    id: 'Mapped Name',
  };
}

function getOptionsHeader(removeTagMapping: any): Column<TagMappings> {
  return {
    id: 'Options',
    Header: 'Action',
    Cell: (rowInfo: any) => {
      return (
        <Button
          variant="primary"
          style={{ height: '34px' }}
          iconBefore={<Icon.Delete />}
          onClick={() =>
            removeTagMapping && removeTagMapping(rowInfo.row.original.FieldName)
          }
        />
      );
    },
  };
}
