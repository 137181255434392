import React, { ReactElement, AllHTMLAttributes } from 'react';
import { SpaceProps, LayoutProps, PositionProps } from 'styled-system';
import { IconWrapper } from '../../ui/components';

type ssProps = SpaceProps & LayoutProps & PositionProps;
type NativeSpanProps = AllHTMLAttributes<HTMLSpanElement>;

type IconProps = ssProps & {
  fill?: string;
  title?: string;
  className?: string;
  ariaHidden?: NativeSpanProps['aria-hidden'];
};

// ---------------------------------------- //
// ------------ Material Icons ------------ //
// ---------------------------------------- //
export function PDF({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {title ? <title>{title}</title> : ''}
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function ContactList({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path d="M480-800v-80h400v80H480Zm0 160v-80h400v80H480Zm0 160v-80h400v80H480ZM758-80q-125 0-247-54.5T289-289Q189-389 134.5-511T80-758q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T347-346q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T630-350l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM201-560l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM201-560Zm358 358Z" />
      </svg>
    </IconWrapper>
  );
}

// ------------------------------------------- //
// ------------ Fontawesome Icons ------------ //
// ------------------------------------------- //

export function Sort({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden="true"
        className="pr-icon-fa"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fill={fill}
          d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"
        />
      </svg>
    </IconWrapper>
  );
}

export function SortUp({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden="true"
        className="pr-icon-fa"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fill={fill}
          d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"
        />
      </svg>
    </IconWrapper>
  );
}

export function SortDown({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden="true"
        className="pr-icon-fa"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fill={fill}
          d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"
        />
      </svg>
    </IconWrapper>
  );
}

// -------------------------------------------- //
// ------------ Hero Icons - Large ------------ //
// -------------------------------------------- //

export function LgBriefcase({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        className="large icon-outline"
        fill="none"
        viewBox="0 0 24 24"
        stroke={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      </svg>
    </IconWrapper>
  );
}

export function LgClipboardCheck({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        className="large icon-outline"
        fill="none"
        viewBox="0 0 24 24"
        stroke={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
        />
      </svg>
    </IconWrapper>
  );
}

export function LgLocation({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        className="large icon-outline"
        fill="none"
        viewBox="0 0 24 24"
        stroke={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    </IconWrapper>
  );
}

export function LgCall({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        className="large icon-outline"
        fill="none"
        viewBox="0 0 24 24"
        stroke={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
        />
      </svg>
    </IconWrapper>
  );
}

export function LgTable({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        className="large icon-outline"
        fill="none"
        viewBox="0 0 24 24"
        stroke={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
        />
      </svg>
    </IconWrapper>
  );
}

export function LgTag({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        className="large icon-outline"
        fill="none"
        viewBox="0 0 24 24"
        stroke={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
        />
      </svg>
    </IconWrapper>
  );
}

export function LgServer({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        className="large icon-outline"
        fill="none"
        viewBox="0 0 24 24"
        stroke={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"
        />
      </svg>
    </IconWrapper>
  );
}

export function LgList({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        className="large icon-outline"
        fill="none"
        viewBox="0 0 24 24"
        stroke={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 6h16M4 10h16M4 14h16M4 18h16"
        />
      </svg>
    </IconWrapper>
  );
}

export function LgDocument({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        className="large icon-outline"
        fill="none"
        viewBox="0 0 24 24"
        stroke={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
    </IconWrapper>
  );
}

// ------------------------------------ //
// ------------ Hero Icons ------------ //
// ------------------------------------ //

export function Refresh({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 2a1 1 0 0 1 1 1v2.101a7.002 7.002 0 0 1 11.601 2.566 1 1 0 1 1-1.885.666A5.002 5.002 0 0 0 5.999 7H9a1 1 0 0 1 0 2H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zm.008 9.057a1 1 0 0 1 1.276.61A5.002 5.002 0 0 0 14.001 13H11a1 1 0 1 1 0-2h5a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-2.101a7.002 7.002 0 0 1-11.601-2.566 1 1 0 0 1 .61-1.276z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function ChevronLeft({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.707 5.293a1 1 0 0 1 0 1.414L9.414 10l3.293 3.293a1 1 0 0 1-1.414 1.414l-4-4a1 1 0 0 1 0-1.414l4-4a1 1 0 0 1 1.414 0z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function ChevronRight({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.293 14.707a1 1 0 0 1 0-1.414L10.586 10 7.293 6.707a1 1 0 0 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function ChevronDown({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.293 7.293a1 1 0 0 1 1.414 0L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function DotsVertical({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title ? <title>{title}</title> : ''}
        <path
          d="M10 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM10 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM10 18a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function Document({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 4a2 2 0 0 1 2-2h4.586A2 2 0 0 1 12 2.586L15.414 6A2 2 0 0 1 16 7.414V16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4zm2 6a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1zm1 3a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H7z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function List({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4C17 4.55228 16.5523 5 16 5H4C3.44772 5 3 4.55228 3 4ZM3 8C3 7.44772 3.44772 7 4 7H16C16.5523 7 17 7.44772 17 8C17 8.55228 16.5523 9 16 9H4C3.44772 9 3 8.55228 3 8ZM3 12C3 11.4477 3.44772 11 4 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H4C3.44772 13 3 12.5523 3 12ZM3 16C3 15.4477 3.44772 15 4 15H16C16.5523 15 17 15.4477 17 16C17 16.5523 16.5523 17 16 17H4C3.44772 17 3 16.5523 3 16Z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function Import({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function Add({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function Search({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function Edit({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title ? <title>{title}</title> : ''}
        <path
          d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
          fill={fill}
        />
        <path
          d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function Copy({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title ? <title>{title}</title> : ''}
        <path
          d="M7 9C7 7.89543 7.89543 7 9 7H15C16.1046 7 17 7.89543 17 9V15C17 16.1046 16.1046 17 15 17H9C7.89543 17 7 16.1046 7 15V9Z"
          fill={fill}
        />
        <path
          d="M5 3C3.89543 3 3 3.89543 3 5V11C3 12.1046 3.89543 13 5 13L5 5H13C13 3.89543 12.1046 3 11 3H5Z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function Settings({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4892 3.17094C11.1102 1.60969 8.8898 1.60969 8.51078 3.17094C8.26594 4.17949 7.11045 4.65811 6.22416 4.11809C4.85218 3.28212 3.28212 4.85218 4.11809 6.22416C4.65811 7.11045 4.17949 8.26593 3.17094 8.51078C1.60969 8.8898 1.60969 11.1102 3.17094 11.4892C4.17949 11.7341 4.65811 12.8896 4.11809 13.7758C3.28212 15.1478 4.85218 16.7179 6.22417 15.8819C7.11045 15.3419 8.26594 15.8205 8.51078 16.8291C8.8898 18.3903 11.1102 18.3903 11.4892 16.8291C11.7341 15.8205 12.8896 15.3419 13.7758 15.8819C15.1478 16.7179 16.7179 15.1478 15.8819 13.7758C15.3419 12.8896 15.8205 11.7341 16.8291 11.4892C18.3903 11.1102 18.3903 8.8898 16.8291 8.51078C15.8205 8.26593 15.3419 7.11045 15.8819 6.22416C16.7179 4.85218 15.1478 3.28212 13.7758 4.11809C12.8896 4.65811 11.7341 4.17949 11.4892 3.17094ZM10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function Logout({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 3C2.44772 3 2 3.44772 2 4V16C2 16.5523 2.44772 17 3 17C3.55228 17 4 16.5523 4 16V4C4 3.44772 3.55228 3 3 3ZM13.2929 12.2929C12.9024 12.6834 12.9024 13.3166 13.2929 13.7071C13.6834 14.0976 14.3166 14.0976 14.7071 13.7071L17.7071 10.7071C17.8946 10.5196 18 10.2652 18 10C18 9.73478 17.8946 9.48043 17.7071 9.29289L14.7071 6.29289C14.3166 5.90237 13.6834 5.90237 13.2929 6.29289C12.9024 6.68342 12.9024 7.31658 13.2929 7.70711L14.5858 9L7 9C6.44771 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H14.5858L13.2929 12.2929Z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function Delete({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
          clipRule="evenodd"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function CheckCircle({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function X({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function Warning({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
          clipRule="evenodd"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function Info({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function ExclamationCircle({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function Export({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 1C4.89543 1 4 1.89543 4 3V15C4 16.1046 4.89543 17 6 17H14C15.1046 17 16 16.1046 16 15V6.41421C16 5.88378 15.7893 5.37507 15.4142 5L12 1.58579C11.6249 1.21071 11.1162 1 10.5858 1H6ZM11 13C11 13.5523 10.5523 14 10 14C9.44772 14 9 13.5523 9 13V9.41418L7.70711 10.7071C7.31658 11.0976 6.68342 11.0976 6.29289 10.7071C5.90237 10.3166 5.90237 9.68338 6.29289 9.29288L9.29289 6.29287C9.68342 5.90237 10.3166 5.90237 10.7071 6.29287L13.7071 9.29288C14.0976 9.68338 14.0976 10.3166 13.7071 10.7071C13.3166 11.0976 12.6834 11.0976 12.2929 10.7071L11 9.41418V13Z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function Preview({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
        <path
          fillRule="evenodd"
          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function Check({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function Cancel({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function Link({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fill={fill}
          fillRule="evenodd"
          d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function AddCircle({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function ArrowDown({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function ArrowUp({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function ChevronDownDouble({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M15.707 4.293a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L10 8.586l4.293-4.293a1 1 0 011.414 0zm0 6a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L10 14.586l4.293-4.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function ChevronUpDouble({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M4.293 15.707a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414 0zm0-6a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function ChevronUp({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function Download({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function Ban({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function Eye({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        {title ? <title>{title}</title> : ''}
        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" fill={fill} />
        <path
          fill={fill}
          fillRule="evenodd"
          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function Xcircle({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
          clipRule="evenodd"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function CheckSquare({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 2c-1.1046 0-2 .8954-2 2v12c0 1.1046.8954 2 2 2h12c1.1046 0 2-.8954 2-2V4c0-1.1046-.8954-2-2-2H4zm9.7071 5.2929c.3905.3905.3905 1.0237 0 1.4142l-4 4c-.3905.3905-1.0237.3905-1.4142 0l-2-2c-.3905-.3905-.3905-1.0237 0-1.4142.3905-.3905 1.0237-.3905 1.4142 0L9 10.5858l3.2929-3.293c.3905-.3904 1.0237-.3904 1.4142 0z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function Call({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path d="M17.924 2.617a.997.997 0 00-.215-.322l-.004-.004A.997.997 0 0017 2h-4a1 1 0 100 2h1.586l-3.293 3.293a1 1 0 001.414 1.414L16 5.414V7a1 1 0 102 0V3a.997.997 0 00-.076-.383z" />
        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
      </svg>
    </IconWrapper>
  );
}

export function ExternalLink({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
        <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
      </svg>
    </IconWrapper>
  );
}

export function Code({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function Globe({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fill={fill}
          fillRule="evenodd"
          d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function Folder({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
      </svg>
    </IconWrapper>
  );
}

export function DocumentAdd({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V8z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function DocumentLink({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 4c0-1.1046.8954-2 2-2h4.5858A2 2 0 0112 2.5858L15.4142 6c.3751.375.5858.8838.5858 1.4142V16c0 1.1046-.8954 2-2 2H6c-1.1046 0-2-.8954-2-2V4zm3 4c-.5523 0-1 .4477-1 1v1c0 .5523.4477 1 1 1h1c.5523 0 1-.4477 1-1h2c0 .5523.4477 1 1 1v2c-.5523 0-1 .4477-1 1v1c0 .5523.4477 1 1 1h1c.5523 0 1-.4477 1-1v-1c0-.5523-.4477-1-1-1v-2c.5523 0 1-.4477 1-1V9c0-.5523-.4477-1-1-1h-1c-.5523 0-1 .4477-1 1H9c0-.5523-.4477-1-1-1H7z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function Filter({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function EyeOff({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fill={fill}
          fillRule="evenodd"
          d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
          clipRule="evenodd"
        />
        <path
          fill={fill}
          d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z"
        />
      </svg>
    </IconWrapper>
  );
}

export function LinkedProcedure({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 4c0-.5523.4477-1 1-1h3c.5523 0 1 .4477 1 1v1h4V4c0-.5523.4477-1 1-1h3c.5523 0 1 .4477 1 1v3c0 .5523-.4477 1-1 1h-1v4h1c.5523 0 1 .4477 1 1v3c0 .5523-.4477 1-1 1h-3c-.5523 0-1-.4477-1-1v-3c0-.5523.4477-1 1-1h1V8h-1c-.5523 0-1-.4477-1-1V6H8v1c0 .5523-.4477 1-1 1H4c-.5523 0-1-.4477-1-1V4z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function DocumentRestore({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z" />
        <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z" />
      </svg>
    </IconWrapper>
  );
}

export function User({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function Backspace({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M6.707 4.879A3 3 0 018.828 4H15a3 3 0 013 3v6a3 3 0 01-3 3H8.828a3 3 0 01-2.12-.879l-4.415-4.414a1 1 0 010-1.414l4.414-4.414zm4 2.414a1 1 0 00-1.414 1.414L10.586 10l-1.293 1.293a1 1 0 101.414 1.414L12 11.414l1.293 1.293a1 1 0 001.414-1.414L13.414 10l1.293-1.293a1 1 0 00-1.414-1.414L12 8.586l-1.293-1.293z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function Save({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 4c0-1.1046.8954-2 2-2h8.2984c.4542 0 .8948.1546 1.2494.4383l3.7016 2.9612A2 2 0 0118 6.9612V16c0 1.1046-.8954 2-2 2H4c-1.1046 0-2-.8954-2-2V4zm2 1c0-.5523.4477-1 1-1h7c.5523 0 1 .4477 1 1v1c0 .5523-.4477 1-1 1H5c-.5523 0-1-.4477-1-1V5zm6 11c1.6569 0 3-1.3431 3-3s-1.3431-3-3-3-3 1.3431-3 3 1.3431 3 3 3z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function Print({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function Layout({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 4c0-1.1046.8954-2 2-2h12c1.1046 0 2 .8954 2 2v2H2V4zm6 4h10v8c0 1.1046-.8954 2-2 2H8V8zM6 8H2v8c0 1.1046.8954 2 2 2h2V8z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function FolderAdd({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 4c-1.1046 0-2 .8954-2 2v8c0 1.1046.8954 2 2 2h12c1.1046 0 2-.8954 2-2V8c0-1.1046-.8954-2-2-2h-5L9 4H4zm7 5c0-.5523-.4477-1-1-1s-1 .4477-1 1v1H8c-.5523 0-1 .4477-1 1s.4477 1 1 1h1v1c0 .5523.4477 1 1 1s1-.4477 1-1v-1h1c.5523 0 1-.4477 1-1s-.4477-1-1-1h-1V9z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function Upload({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function File({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function DocumentPlain({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function ConvertToLocal({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5622 9.25h1.4593c.0669-1.1598.2877-2.2282.6283-3.0883C3.5631 6.8285 2.7814 7.944 2.5622 9.25zM7 4c-3.3137 0-6 2.6863-6 6s2.6863 6 6 6 6-2.6863 6-6-2.6863-6-6-6zm0 1.5c-.057 0-.1741.0238-.349.1964-.178.1758-.3729.4673-.5526.8867-.2915.6802-.5047 1.606-.5741 2.6669h2.9514c-.0694-1.0608-.2825-1.9867-.574-2.6669-.1798-.4194-.3746-.711-.5527-.8867C7.174 5.5238 7.057 5.5 7 5.5zm2.9786 3.75c-.067-1.1598-.2878-2.2282-.6284-3.0883 1.0867.6668 1.8685 1.7822 2.0875 3.0883H9.9786zm-1.5029 1.5H5.5243c.0694 1.0608.2826 1.9867.574 2.6669.1798.4194.3746.711.5527.8868.1749.1725.292.1963.349.1963.057 0 .174-.0238.349-.1963.1781-.1758.3729-.4674.5526-.8868.2916-.6802.5047-1.6061.5741-2.6669zm.8745 3.0883c.3406-.8601.5615-1.9285.6284-3.0883h1.4591c-.219 1.3061-1.0008 2.4215-2.0875 3.0883zm-4.7004 0c-.3406-.8601-.5614-1.9285-.6283-3.0883H2.5622c.2192 1.3061 1.001 2.4215 2.0876 3.0883z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2929 5.2929c.3905-.3905 1.0237-.3905 1.4142 0l4 4c.3905.3905.3905 1.0237 0 1.4142l-4 4c-.3905.3905-1.0237.3905-1.4142 0-.3905-.3905-.3905-1.0237 0-1.4142L16.5858 11H2c-.5523 0-1-.4477-1-1s.4477-1 1-1h14.5858l-2.2929-2.2929c-.3905-.3905-.3905-1.0237 0-1.4142z"
          fill={fill}
        />
      </svg>
    </IconWrapper>
  );
}

export function Image({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function FolderOpened({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M2 6a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1H8a3 3 0 00-3 3v1.5a1.5 1.5 0 01-3 0V6z"
          clipRule="evenodd"
        />
        <path d="M6 12a2 2 0 012-2h8a2 2 0 012 2v2a2 2 0 01-2 2H2h2a2 2 0 002-2v-2z" />
      </svg>
    </IconWrapper>
  );
}

export function Archive({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
        <path
          fillRule="evenodd"
          d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function ChevronLeftDouble({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function ChevronRightDouble({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        />
        <path
          fillRule="evenodd"
          d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function ClocK({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg
        aria-hidden={ariaHidden}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={fill}
      >
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export function minusCircle({
  fill = 'currentColor',
  title,
  className,
  ariaHidden,
  ...ssProps
}: IconProps): ReactElement {
  return (
    <IconWrapper className={className} aria-hidden={ariaHidden} {...ssProps}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill={fill}>
        {title ? <title>{title}</title> : ''}
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
}

export default {
  PDF,
  Refresh,
  Sort,
  SortUp,
  SortDown,
  DotsVertical,
  ChevronLeft,
  ChevronRight,
  Document,
  List,
  Import,
  Add,
  Search,
  Edit,
  Copy,
  Settings,
  Logout,
  Delete,
  X,
  Warning,
  Export,
  Preview,
  Check,
  Cancel,
  Link,
  AddCircle,
  ArrowDown,
  ArrowUp,
  ChevronDownDouble,
  ChevronUpDouble,
  ChevronUp,
  Download,
  CheckCircle,
  Ban,
  ExclamationCircle,
  Info,
  ChevronDown,
  Eye,
  Xcircle,
  CheckSquare,
  Call,
  ExternalLink,
  Code,
  Globe,
  Folder,
  DocumentAdd,
  DocumentLink,
  Filter,
  EyeOff,
  DocumentRestore,
  User,
  Save,
  Backspace,
  Layout,
  Print,
  FolderAdd,
  Upload,
  DocumentPlain,
  ConvertToLocal,
  Image,
  FolderOpened,
  Archive,
  LgBriefcase,
  LgClipboardCheck,
  LgLocation,
  LgCall,
  LgTable,
  LgTag,
  LgServer,
  LgList,
  LgDocument,
  ChevronLeftDouble,
  ChevronRightDouble,
  LinkedProcedure,
  File,
  ClocK,
  minusCircle,
  ContactList,
};
