import React, { ReactElement, useLayoutEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';

// default plugins list
// 'advlist autolink lists link image charmap print preview anchor',
// 'searchreplace visualblocks code fullscreen',
// 'insertdatetime media table paste code help wordcount',
// initialValue="<p>This is the initial content of the editor</p>"

function TinyMCE({
  onChange,
  value,
  // initialValue,
  name,
  height,
  setLoading,
  toggleFileImage,
}: {
  onChange: (content: string, editor: any) => void;
  value: any;
  initialValue: any;
  name: string;
  height: number;
  toggleFileImage?: () => void;
  setLoading?: (isLoading: boolean) => void;
}): ReactElement {
  //   const handleEditorChange = (content: any, editor: any) => {
  //     console.log('Content was updated:', content);
  //   };

  useLayoutEffect(() => {
    // console.log('isLoading tiny');
    setLoading && setLoading(true);
    return () => {
      setLoading && setLoading(false);
    };
  }, [setLoading]);

  const toolbar = `undo redo | blocks | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | image ${
    toggleFileImage ? '| FileImage' : ''
  } | a11ycheck | formatpainter | permanentpen | checklist | casechange | showcomments |`;
  // if (toggleFileImage) toolbar = `${toolbar} FileImage |`;
  return (
    <Editor
      onInit={() => {
        // console.log('isLoading Complete');
        setLoading && setLoading(false);
      }}
      textareaName={name}
      // initialValue={initialValue}
      value={value}
      init={{
        autolink_pattern:
          /^(https?:\/\/|ssh:\/\/|ftp:\/\/|file:\/|www\.)(.+)$/i,
        height: height || 500,
        menubar: false,
        plugins: [
          'link',
          'searchreplace visualblocks',
          'paste',
          'image',
          'a11ychecker',
          'formatpainter',
          'permanentpen',
          'checklist',
          'casechange',
          'tinycomments',
          'lists',
          'spellchecker',
          'tinymcespellchecker',
        ],
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author',
        toolbar: toolbar,
        link_title: false,
        spellchecker_active: true,
        forced_root_block_attrs: {
          style: 'margin: 0',
        },
        setup: editor => {
          if (toggleFileImage)
            editor.ui.registry.addButton('FileImage', {
              icon: undefined,
              tooltip: 'File/Image',
              text: 'File/Image',
              onAction: function () {
                // handleNotImplemented();
                toggleFileImage && toggleFileImage();
              },
            });
        },
      }}
      onEditorChange={onChange}
    />
  );
}

export { TinyMCE };

// init={{
//   height: height || 500,
//   menubar: false,
//   plugins: [
//     'advlist autolink lists link image charmap print preview anchor',
//     'searchreplace visualblocks code fullscreen',
//     'insertdatetime media table paste code help wordcount spellchecker',
//   ],
//   toolbar:
//     'undo redo | formatselect | bold italic backcolor | \
//   alignleft aligncenter alignright alignjustify | \
//   bullist numlist outdent indent | removeformat | help',
// }}
