import { useQuery, QueryResult } from 'react-query';
import { getProcedures } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchProcedure({ account }: TVariables): Promise<TResult> {
  const response = await fetch(getProcedures(account), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = Array<string>;

export type TError = { message: string };
export type TVariables = {
  account: number;
};

function useGetProcedures(account: number): QueryResult<TResult, TError> {
  return useQuery(
    ['get_procedures', account],
    async () =>
      await fetchProcedure({
        account,
      }),
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetProcedures };
