import { useInfiniteQuery, InfiniteQueryResult, queryCache } from 'react-query';
import { ProcedureList } from 'types/procedureBuilder';
import { searchProcedureLinks } from '../utils/urls';
import { getTokenWithType } from './useAuth';
const pageLength = 25;

async function fetchProcedureLinks({
  accountId,
  start,
  pageLength,
  keywordSearch,
}: TVariables): Promise<TResult> {
  const response = await fetch(
    searchProcedureLinks(accountId, start, pageLength, keywordSearch),
    {
      method: 'GET',
      headers: {
        Authorization: getTokenWithType(),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return response.json();
}

export type TResult = Array<ProcedureList>;

export type TError = { message: string };
export type TVariables = {
  accountId: number;
  pageLength: number;
  start: number;
  keywordSearch: string;
};

function useProcedureLinks(
  accountId: number,
  keywordSearch: string,
): InfiniteQueryResult<TResult, TError> {
  const data: Array<TResult> | undefined = queryCache.getQueryData([
    'procedureLinks_list',
    accountId,
    keywordSearch,
  ]);
  const start = data ? data.length * pageLength : 0;
  return useInfiniteQuery(
    ['procedureLinks_list', accountId, keywordSearch],
    () =>
      fetchProcedureLinks({
        accountId,
        start,
        pageLength,
        keywordSearch,
      }),
    {
      getFetchMore: (lastGroup, allGroups) => {
        // console.log('lastGroup, allGroups:', lastGroup, allGroups);
        if (
          !lastGroup ||
          lastGroup.length === 0 ||
          lastGroup.length < pageLength
        )
          return false;
        return true;
      },
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useProcedureLinks };
