import React, { ReactElement, useMemo } from 'react';
import { ColumnInstance, Row } from 'react-table';
import { ThemeProvider } from 'styled-components';
import { Box } from 'rebass/styled-components';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { theme } from '../../../assets/styles';
import { SearchSnippet } from '../../../ui/patterns';
import { Button, Stack } from '../../../ui/components';

export default function SearchSnippetColumn(props: {
  column: ColumnInstance<any>;
  row: Row<any>;
  setAccounts?: (row: any, type: string) => void;
  tooltipHidden?: boolean;
  onRedirect?: (path: string, params: Record<string, number | string>) => void;
  getHighlightedText?: (content: string) => string;
}): ReactElement {
  const redirectPage = () => {
    let route = '';
    const accountId = props?.row?.original?.AccountId;
    if ('HelpText' in props.row.original) {
      const formId = props?.row?.original?.FormId;
      route = `/home/accounts/edit/${accountId}/EditForm/${formId}`;
    } else {
      route = `/home/accounts/edit/${accountId}/Procedures`;
    }
    const params = {
      Pid: props?.row?.original?.ProcedureId,
    };
    props.onRedirect && props.onRedirect(route, params);
    // history.push(route, params);
  };
  const stripHtml = (html: string): string =>
    new DOMParser().parseFromString(html, 'text/html').body.textContent || '';

  const contentText = useMemo(() => {
    if (props.row.original.Content) {
      return stripHtml(props.row.original.Content);
    } else {
      return stripHtml(props.row.original.HelpText);
    }
  }, [props.row.original.Content, props.row.original.HelpText]);

  return 'HelpText' in props.row.original && !contentText.length ? (
    <></>
  ) : (
    <>
      <Tooltip
        animation="fade"
        position="right"
        theme="light"
        interactive={true}
        trigger="mouseenter"
        interactiveBorder={20}
        unmountHTMLWhenHide={true}
        disabled={props.tooltipHidden}
        html={
          <ThemeProvider theme={theme}>
            <Box maxHeight="200px" overflow="auto" mt={2} mb={3} pr={2}>
              <Stack direction="y" gap={3}>
                <SearchSnippet>
                  {props.getHighlightedText &&
                    props.getHighlightedText(contentText)}
                </SearchSnippet>
              </Stack>
            </Box>
            <Button
              variant="outline"
              size="sm"
              fullWidth
              style={{ marginBottom: '8px' }}
              onClick={() => redirectPage()}
            >
              {'HelpText' in props.row.original
                ? 'View Form'
                : 'View Procedure'}{' '}
            </Button>
          </ThemeProvider>
        }
      >
        <SearchSnippet numberOfLines={1} width="180px">
          {contentText}
        </SearchSnippet>
      </Tooltip>
    </>
  );
}
