import React, { SyntheticEvent, ReactElement, useCallback } from 'react';
import styled from 'styled-components';

type Props = {
  active: boolean;
};

const StyledPagination = styled.nav`
  /* display: flex; */
  /* padding-left: ${({ theme }) => theme.space[3]};
  padding-right: ${({ theme }) => theme.space[3]}; */

  ul {
    margin: 0;
    margin-left: auto;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: ${({ theme }) => theme.radii.rounded};
  }

  /* ul > li > a {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.orange800};
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.gray200};
  } */
`;

type AnchorProps = {
  active?: boolean;
  disabled?: boolean;
};

const StyledAnchor = styled.a<AnchorProps>`
  --border-color: var(--color-neutral-contrast-03);
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  text-decoration: none;
  color: ${({ active, disabled, theme }) => {
    if (disabled) return `var(--color-neutral-contrast-03)`;
    return active ? theme.colors.white : theme.colors.primary;
  }};
  background-color: ${({ active, theme }) =>
    active ? `var(--primary-color)` : `var(--super-low-contrast-neutral)`};
  border: solid 1px var(--border-color);
  cursor: ${({ disabled }) => {
    if (disabled) return 'not-allowed';
  }};

  html[data-color-mode='dark'] & {
    --border-color: var(--color-neutral-contrast-03);
  }

  .pr-pagination > li:first-child & {
    border-top-left-radius: ${({ theme }) => theme.radii.rounded};
    border-bottom-left-radius: ${({ theme }) => theme.radii.rounded};
  }

  .pr-pagination > li:last-child & {
    border-top-right-radius: ${({ theme }) => theme.radii.rounded};
    border-bottom-right-radius: ${({ theme }) => theme.radii.rounded};
  }
`;

function Pagination({
  onClick,
  page,
  numberOfPages = 0,
}: {
  onClick: (page: number) => void;
  page: number;
  numberOfPages: number;
}): ReactElement {
  const onClickPrevious = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      if (page > 0) onClick(page - 1);
    },
    [page, onClick],
  );
  const onClickNext = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      if (page <= numberOfPages - 1) onClick(page + 1);
    },
    [page, numberOfPages, onClick],
  );
  const pageNumbers = React.useMemo(() => {
    return [
      ...Array(numberOfPages + 1).map(function (x, i) {
        return i;
      }),
    ];
  }, [numberOfPages]);

  return (
    <StyledPagination aria-label="Pagination">
      <ul className="pr-pagination">
        <li onClick={onClickPrevious}>
          <StyledAnchor disabled={page === 0} href="#">
            Previous
          </StyledAnchor>
        </li>
        {pageNumbers.map(
          (_: any, index: number): ReactElement => (
            <li
              key={index}
              onClick={(e: SyntheticEvent<HTMLLIElement>) => {
                e.preventDefault();
                onClick(index);
              }}
            >
              <StyledAnchor active={index === page} href="#">
                {index + 1}
              </StyledAnchor>
            </li>
          ),
        )}
        <li onClick={onClickNext}>
          <StyledAnchor disabled={page === numberOfPages} href="#">
            Next
          </StyledAnchor>
        </li>
      </ul>
    </StyledPagination>
  );
}

export { Pagination };
