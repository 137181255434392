import { useQuery, QueryResult } from 'react-query';
import { procedureVersions } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { ProcedureInfoType } from 'types/procedureBuilder';

async function fetchProcedureVersionContent({
  account,
  procedureId,
  version,
}: TVariables): Promise<TResult> {
  const response = await fetch(
    procedureVersions(account, procedureId, version),
    {
      method: 'GET',
      headers: {
        Authorization: getTokenWithType(),
      },
    },
  );
  return response.json();
}

export type TResult = ProcedureInfoType | undefined;

export type TError = { message: string };
export type TVariables = {
  account: number;
  procedureId: number;
  version: number | undefined;
};

function useGetProcedureVersionContent(
  account: number,
  procedureId: number,
  version: number | undefined,
): QueryResult<TResult, TError> {
  return useQuery(
    ['get_procedure_version_content', account, procedureId, version],
    async () => {
      if (procedureId && version)
        return await fetchProcedureVersionContent({
          procedureId,
          account,
          version,
        });
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetProcedureVersionContent };
