import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { dismissLinks } from '../utils/urls';
import { getTokenWithType } from './useAuth';

export async function dismissBrokenLinks({
  params,
}: {
  params: TVariables;
}): Promise<TResult> {
  const response = await fetch(dismissLinks(params.accountId), {
    method: 'PUT',
    headers: {
      Authorization: getTokenWithType(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(params.model),
  });
  return response.json();
}

export type TResult = string | undefined;
export type TVariables = {
  accountId: number;
  model: any;
};
export type TError = {
  Message: string;
};
export type TSnapshot = unknown;

function useDismissBrokenLinks(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: TVariables) => {
      const response = await dismissBrokenLinks({
        params: params,
      });
      if (response) {
        queryCache.invalidateQueries([
          'getBrokenLinksForDismissal',
          params.accountId,
        ]);
        queryCache.invalidateQueries(['get_broken_links', params.accountId]);
      }
      return response;
    },
    {
      throwOnError: true,
    },
  );
}

export { useDismissBrokenLinks };
