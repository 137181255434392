import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { customTable as saveCustomTable } from 'utils/urls';
import { getTokenWithType } from './useAuth';

type CustomTableInput = {
  AccountId: number;
  Columns: Array<string>;
  Id: number;
  TableName: string;
};

export async function postTableInfo({
  params,
}: {
  params: CustomTableInput;
}): Promise<TResult> {
  const response = await fetch(saveCustomTable(params.AccountId), {
    method: params.Id === 0 ? 'POST' : 'PUT',
    headers: {
      Authorization: getTokenWithType(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = CustomTableInput & ValidationError & Array<string>;
export type TVariables = CustomTableInput;
export type TError = string;
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;

// async function wait(millis: number) {
//   return new Promise(resolve => {
//     setTimeout(resolve, millis);
//   });
// }

function useSaveCustomTable(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: CustomTableInput) => {
      const newTableInfo = await postTableInfo({
        params: params,
      });
      if (newTableInfo.Message) throw newTableInfo.Message;
      //   else if (newTableInfo.length) throw newTableInfo[0];
      else if (newTableInfo.Id) {
        queryCache.invalidateQueries(['custom_table']);
        queryCache.invalidateQueries(['custom_records'], {
          refetchInactive: true,
        });
      }
      return newTableInfo;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveCustomTable };
