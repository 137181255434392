import React, { ReactElement } from 'react';
import {
  Textarea,
  FormLabel,
  StyledFormFeedback,
  InputWrapper,
} from '../../components';
import { FormGroup } from '../index';

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

export type FormTextareaProps = {
  label?: string | any;
  name?: string;
  value?: string;
  onTextChange?: (text: string) => void;
  placeholder?: string;
  disabled?: boolean;
  validation?: 'error' | 'success' | 'warning';
  feedback?: string;
  required?: boolean;
  rows?: number;
  cols?: number;
  register?: ({ required }: { required?: boolean | string }) => RefReturn;
  onBlur?: () => void;
  style?: React.CSSProperties;
  className?: string;
  resize?: 'both' | 'horizontal' | 'vertical' | 'none';
};

const FormTextarea = ({
  label,
  name,
  value,
  onTextChange,
  placeholder,
  disabled,
  validation,
  feedback,
  required,
  rows,
  cols,
  register,
  onBlur,
  style,
  className,
  resize,
}: FormTextareaProps): ReactElement => {
  // const id = `input-${label.replace(' ', '').toLowerCase()}`;
  return (
    <FormGroup style={style} className={className}>
      {label ? (
        <FormLabel htmlFor={name} required={required} disabled={disabled}>
          {label}
        </FormLabel>
      ) : null}
      <InputWrapper>
        <Textarea
          placeholder={placeholder}
          value={value}
          id={name}
          disabled={disabled}
          validation={validation}
          register={register}
          rows={rows}
          cols={cols}
          onChange={e => onTextChange && onTextChange(e.currentTarget.value)}
          onBlur={onBlur}
          resize={resize}
        />
      </InputWrapper>
      {feedback ? (
        <StyledFormFeedback validation={validation} disabled={disabled}>
          {feedback}
        </StyledFormFeedback>
      ) : null}
    </FormGroup>
  );
};

export { FormTextarea };
