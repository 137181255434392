import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

const StyledDropdownButton = styled.summary`
  /* position: relative;
  display: inline-block;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border-radius: ${({ theme }) => theme.radii.rounded};
  appearance: none;
  text-decoration: none;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  color: var(--text-color-normal);
  background-color: ${({ theme }) => theme.colors.gray[1]};
  border: 1px solid rgba(27, 31, 35, 0.12);
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
    rgba(255, 255, 255, 0.25) 0px 2px 0px inset;
  list-style: none; */

  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  appearance: none;
  text-decoration: none;
  color: var(--text-color-normal);

  &::-webkit-details-marker {
    display: none;
  }

  .procedure-filter-dropdown & {
    color: ${({ theme }) => theme.colors.text.body};
    padding: 1px 3px;
    line-height: 1;
  }

  .dropdown-profile & {
    padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[3]};
    border: 0;
    border-radius: ${({ theme }) => theme.radii.hard};
    box-shadow: none;
    background: none;
    cursor: pointer;
    transition: background 220ms ease-in-out;

    &:hover {
      background-color: var(--color-neutral-contrast-02);

      html[data-color-mode='dark'] & {
        background-color: var(--color-neutral-contrast-03);
      }
    }

    &:focus {
      outline: none;
      box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
      /* background-color: ${({ theme }) => theme.colors.gray[1]}; */
    }
  }
`;

type DropdownButtonProps = {
  children?: string | ReactNode;
  role?: string;
  onClick?: () => void;
  tabIndex?: number;
  className?: string;
  title?: string;
};

function DropdownButton({
  children,
  role,
  onClick,
  tabIndex,
  className,
  title,
}: DropdownButtonProps): ReactElement {
  return (
    <StyledDropdownButton
      tabIndex={tabIndex}
      as="summary"
      onClick={onClick}
      role={role}
      className={className}
      title={title}
    >
      {children}
    </StyledDropdownButton>
  );
}

export default DropdownButton;
