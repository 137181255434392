import { useQuery, QueryResult } from 'react-query';
import { getBranchOutGroup } from '../utils/urls';
import { getTokenWithType } from './useAuth';

import { ApiResult } from './useGetRoutingSubGroup';

async function fetchBranchOutGroup(): Promise<ApiResult> {
  const response = await fetch(getBranchOutGroup, {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TError = { message: string };

export type TResult = Array<{
  id: number;
  name: string;
  description: string;
}>;

function useGetBranchOutGroup(): QueryResult<TResult, TError> {
  return useQuery(
    'get_branchout_group',
    async () => {
      const fetchResponse = await fetchBranchOutGroup();
      const result = fetchResponse.map(item => ({
        id: item.Id,
        name: `${item.Name}`,
        description: `${item.Description}`,
      }));
      return result;
    },

    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetBranchOutGroup };
