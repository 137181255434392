import React from 'react';
import styled from 'styled-components';
import {
  FlexProps,
  flex,
  SpaceProps,
  space,
  LayoutProps,
  layout,
} from 'styled-system';
import { TableWrapper, StyledTable } from 'ui/patterns';

type SsProps = FlexProps & SpaceProps & LayoutProps;

const DataTableWrapper = styled(props => <TableWrapper {...props} />)<SsProps>`
  &&& {
    height: auto;
    ${flex}
    ${space}
	${layout}
  }
`;

const DataTable = styled(props => <StyledTable {...props} />)<SsProps>`
  ${flex}
  ${space}
	${layout}

	thead > tr > th,
	tbody > tr > td {
    :last-child {
      text-align: initial;
      width: auto;
    }
  }

  &.without-search {
    thead > tr > th {
      padding: 1em;
    }
  }

  &.choice-qn-dependency {
    tbody > tr {
      background-color: var(--color-neutral-contrast-00);

      html[data-color-mode='dark'] & {
        background-color: var(--color-neutral-contrast-01);
      }
    }

    tbody > tr > td {
      vertical-align: middle;
    }
  }

  &.equal-width-columns {
    table-layout: fixed;
    border-collapse: collapse;
  }

  &&& input[type='checkbox'],
  &&& input[type='radio'] {
    & + label {
      line-height: 1.6;
    }
  }
`;

export { DataTableWrapper, DataTable };
