import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { showSuccessToast, showErrorToast } from 'utils/showToast';
import { FormTextarea } from 'ui/patterns';
import { Stack, Button } from 'ui/components';
import { Loader } from 'ui/patterns';
import { OfficeFactsInput } from 'types/accounts';
import { useGetOfficeFacts, useSaveOfficeFacts } from 'hooks';
import usePrompt from 'hooks/usePrompt';

const empty = {
  officeFactsReturn: undefined,
  officeFactsInputs: undefined,
};

const formDefaults = {
  Account: null,
  AccountId: 0,
  CallbackProcedures: '',
  Coverage: '',
  Hours: '',
  Id: 0,
  Notes: '',
};

export default function OfficeFacts(): ReactElement {
  const [isPageDirty, setDirty] = useState<boolean>();
  const history = useHistory<{ ID: number }>();
  const params = useParams<{
    accountId: string;
    tabId?: string;
  }>();
  const accountId = parseInt(params.accountId);
  const [saveOfficeFacts, { isLoading: isSaveOfficeFactsLoading }] =
    useSaveOfficeFacts();
  const { data, isLoading: isGetOfficeFactsLoading } =
    useGetOfficeFacts(accountId);
  const { officeFactsInputs } = data || empty;
  const isLoading = isGetOfficeFactsLoading || isSaveOfficeFactsLoading;

  const onSubmit = async (data: OfficeFactsInput) => {
    setDirty(false);
    await saveOfficeFacts(
      {
        ...data,
        Id: officeFactsInputs?.Id ? officeFactsInputs.Id : 0,
        AccountId: accountId,
        Account: null,
      },
      {
        onSuccess: (mutationResult, data) => {
          if (!data.Id && mutationResult.Id) {
            reset(); // clear the form
          } else if (data?.Id && mutationResult.Id) {
            showSuccessToast({
              message: 'Your changes were successfully saved.',
              autoClose: 3000,
            });
          } else if (mutationResult.Message) {
            showErrorToast({
              message: mutationResult.Message,
            });
          } else {
            showErrorToast({
              message: 'Something went wrong',
            });
          }
        },
        onError: e => {
          showErrorToast({ message: e.message, autoClose: 10000 });
        },
      },
    );
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<OfficeFactsInput>({
    defaultValues: {
      ...formDefaults,
    },
  });

  useEffect(() => {
    if (officeFactsInputs && !isLoading) {
      reset({ ...officeFactsInputs });
    }
  }, [officeFactsInputs, reset, isLoading]);

  useEffect(() => {
    if (isDirty) setDirty(isDirty);
  }, [setDirty, isDirty]);

  usePrompt(
    isPageDirty,
    'Are you sure you want to leave? Changes that you made may not be saved.',
  );

  return (
    <>
      <Flex
        as="form"
        flexDirection="column"
        height="100%"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          style={{
            flex: '1 1 auto',
            flexGrow: 1,
            overflow: 'auto',
            height: '1px',
          }}
        >
          <Box p={1}>
            <Stack direction="y" gap={4}>
              <FormTextarea
                label="Opening Hours"
                placeholder="Enter Opening Hours Eg: Monday-Friday 8am-5pm"
                name="Hours"
                register={register}
                rows={5}
                resize="none"
              />
              <FormTextarea
                label="Coverage"
                register={register}
                name="Coverage"
                rows={5}
                resize="none"
              />
              <FormTextarea
                label="Callback Procedures"
                name="CallbackProcedures"
                register={register}
                rows={5}
                resize="none"
              />
              <FormTextarea
                label="Notes"
                name="Notes"
                register={register}
                rows={5}
                resize="none"
              />
            </Stack>
          </Box>
        </Box>
        <Flex justifyContent="flex-end" mt={3}>
          <Stack direction="x" gap={2}>
            <Button
              variant="secondary"
              onClick={() => history.push('/home/accounts')}
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Stack>
        </Flex>
      </Flex>
      {isLoading ? <Loader /> : null}
    </>
  );
}
