/* eslint-disable react/display-name */
import React from 'react';
import { Column, TableInstance, ColumnInstance } from 'react-table';
import { CustomRecords } from 'types/customTables';
import Header from './tableHeader';
import CustomRecordOptions from './tableCustomRecordsOptions';

export default function useCustomTableColumns(
  columnsList?: Array<string>,
): Array<Column<CustomRecords>> {
  return React.useMemo((): Array<Column<CustomRecords>> => {
    if (columnsList) {
      const columns = [];
      columnsList.map((item: any, index: number) =>
        columns.push(getHeaders(Header, item, index)),
      );

      columns.push(getOptionsHeader());
      return columns;
    }
    return [];
  }, [columnsList]);
}

function getHeaders(
  Header: (
    props: TableInstance<CustomRecords> & {
      column: ColumnInstance<CustomRecords>;
    },
  ) => JSX.Element,
  Id: string,
  index: number,
): Column<CustomRecords> {
  return {
    Header: Header,
    accessor: (row: CustomRecords) => row.Values[index],
    id: Id,
  };
}

function getOptionsHeader(): Column<CustomRecords> {
  return {
    id: 'Options',
    Header: 'Action',
    Cell: CustomRecordOptions,
  };
}
