import React, { ReactElement, SyntheticEvent, useEffect } from 'react';
import { SlidePanel, FormInput, FormTextarea, FormSelect } from 'ui/patterns';
import { Button, Stack } from 'ui/components';
import { useGetLocation, useSaveLocation, useStateProvince } from 'hooks';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { LocationInput } from 'types/locations';
import { showErrorToast } from 'utils/showToast';

type EditLocationProps = {
  showPanel: boolean;
  togglePanel: () => void;
  locationId?: number;
  mode: string;
};

const formDefaults = {
  City: '',
  Country: '',
  County: '',
  Name: '',
  Notes: '',
  PostalCode: '',
  StateProvinceId: 0,
  Street: '',
};

export default function EditLocation({
  showPanel,
  togglePanel,
  locationId,
  mode,
}: EditLocationProps): ReactElement {
  const history = useHistory<{ ID: number }>();
  const { accountId } = useParams<{
    accountId: string;
    tabId?: string;
  }>();
  const { data: selectedLocation } = useGetLocation({
    account: parseInt(accountId),
    id: locationId,
  });

  const { data: stateProvince } = useStateProvince();
  const [saveLocation, { isLoading: isSaveLocationLoading }] =
    useSaveLocation();
  const addLocationActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          reset({ ...formDefaults });
          togglePanel();
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={handleSubmit(onSubmit)}
        type="submit"
        variant="primary"
        disabled={isSaveLocationLoading ? true : false}
      >
        Save
      </Button>
    </>
  );

  const { register, handleSubmit, reset, control } = useForm<
    Partial<LocationInput>
  >({
    defaultValues: { ...formDefaults },
  });

  function onSubmit(data: any) {
    saveLocation(
      {
        ...data,
        AccountId: accountId,
        Id: selectedLocation?.Id || 0,
      },
      {
        onSuccess: () => {
          togglePanel();
          reset({ ...formDefaults });
        },
        onError: ({ Message }: { Message: string }) => {
          showErrorToast({ message: Message });
        },
      },
    );
  }

  useEffect(() => {
    if (selectedLocation && !isSaveLocationLoading) {
      reset({ ...selectedLocation });
    }
  }, [selectedLocation, reset, isSaveLocationLoading]);

  return (
    <>
      <SlidePanel
        title={`${mode === 'Edit' ? 'Edit Location' : 'Add New Location'}`}
        actions={addLocationActions()}
        onClose={() => {
          reset({ ...formDefaults });
          togglePanel();
        }}
        open={showPanel}
      >
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <Stack direction="y" gap={4}>
          <FormInput register={register} label="Name" type="text" required />
          <FormInput register={register} label="Street" type="text" />
          <FormInput
            register={register}
            name="PostalCode"
            label="Zip Code"
            type="text"
          />
          <FormInput register={register} label="City" type="text" />
          <Controller
            render={(props: {
              onChange: (value: number) => void;
              value: number;
            }) => (
              <FormSelect
                value={props.value}
                label="State"
                options={stateProvince}
                onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                  props.onChange(parseInt(e.currentTarget.value, 10));
                }}
              />
            )}
            defaultValue={''}
            name="StateProvinceId"
            control={control}
          />
          <FormInput register={register} label="Country" type="text" />
          <FormInput register={register} label="County" type="text" />
          {/* <FormInput register={register} label="Notes" name="Notes" /> */}
          <FormTextarea
            register={register}
            label="Notes"
            name="Notes"
            resize="vertical"
          />
        </Stack>
        {/* </form> */}
      </SlidePanel>
    </>
  );
}
