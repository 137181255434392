import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import { SlidePanel, Loader, FormGroup } from 'ui/patterns';
import {
  Button,
  Stack,
  Separator,
  VisuallyHidden,
  StyledFormInput,
} from 'ui/components';
import { ProcedureAccordion } from 'ui/patterns';
import { ReactSelect } from 'ui/patterns/reactSelect';
import { Heading } from 'rebass/styled-components';
import {
  useGetExistingTables,
  useDeleteTemplate,
  useEditTemplateName,
  useSaveTable,
  useDeleteTemplateGroup,
  useEditTemplateGroupName,
} from 'hooks';
import { showErrorToast, showSuccessToast } from 'utils/showToast';
import { confirmAlert } from 'utils/confirm-alert';
import * as Icon from 'assets/icons';
import { EmptyState } from 'ui/patterns';
import NoDataImage from 'assets/images/NoDataImage';
import { BlobFile } from 'types/procedureBuilder';

type ExistingTableProps = {
  showPanel: boolean;
  togglePanel: () => void;
};

type Group = {
  label: string;
  value: string;
};

function ExistingTable({
  showPanel,
  togglePanel: toggle,
}: ExistingTableProps): ReactElement {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedTable, setSelectedTable] = useState('');
  const [insertDisable, setInsertDisable] = useState(false);

  /*Grouping */
  const [isGroupEdited, setIsGroupEdited] = useState(false); // to handle changes in dropdown after group name change
  const [editGroupName, setEditGroupName] = useState(false); // group name edit state
  const [groupName, setGroupName] = useState<string>('All'); // group name & header
  const [selectedGroupValue, setSelectedGroupValue] = useState<Group>({
    label: ' All',
    value: 'All',
  }); // drop down selection
  const [selectedGroupTableList, setSelectedGroupTableList] = useState<
    Array<BlobFile>
  >([]); //selected list
  /*Grouping */

  const [deleteFile, { isLoading: isDeleteLoading }] = useDeleteTemplate();
  const [editFile, { isLoading: isEditLoading }] = useEditTemplateName();

  const [deleteGroup, { isLoading: isDeleteTableGroupLoading }] =
    useDeleteTemplateGroup();

  const [editGroupTitle, { isLoading: isEditGroupNameLoading }] =
    useEditTemplateGroupName();

  const { data: tableData, isLoading: tableDataLoading } =
    useGetExistingTables(showPanel);

  const [saveTable, { isLoading: isSaveTableLoading }] = useSaveTable();

  const folderOptions = useMemo(() => {
    return (
      tableData &&
      tableData[0] &&
      tableData[0]?.BlobFile?.filter(item => item.FileType === 'folder').map(
        group => {
          return { label: group.FileName, value: group.FileName };
        },
      )
    );
  }, [tableData]);

  const tableTemplateData = useMemo(() => {
    return tableData && tableData[0] && tableData[0]?.BlobFile;
  }, [tableData]);

  const updatedGroups = useMemo(() => {
    return tableTemplateData?.map(item => {
      if (item.FileType === 'html') {
        item.mappedGroups = [];
      }
      return item;
    });
  }, [tableTemplateData]);

  const mappedGrouping = () => {
    const ungrouped = updatedGroups?.filter(item => item.FileType === 'html');
    const grouped = updatedGroups?.filter(item => item.FileType !== 'html');
    if (grouped && ungrouped) {
      for (let j = 0; j < ungrouped.length; j++) {
        for (let i = 0; i < grouped?.length; i++) {
          grouped[i]?.BlobFile?.map(subItem => {
            if (ungrouped[j].FileName === subItem.FileName) {
              if (!ungrouped[j]?.mappedGroups?.length)
                ungrouped[j]?.mappedGroups?.push(grouped[i]?.FileName);
              else {
                const isGroupPresent = ungrouped[j]?.mappedGroups?.filter(
                  item => item === grouped[i]?.FileName,
                );
                if (!isGroupPresent?.length)
                  ungrouped[j]?.mappedGroups?.push(grouped[i]?.FileName);
              }
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(showPanel);
    }, 10);
  }, [showPanel]);

  //handing group changes in dropdown
  useEffect(() => {
    if (tableTemplateData) {
      if (selectedGroupTableList.length >= 0 && selectedGroupValue !== null) {
        if (tableTemplateData.length !== 0) {
          const temp: Array<BlobFile> = [];
          if (selectedGroupValue.value === 'All') {
            tableTemplateData?.map((tables: BlobFile) => {
              if (tables.FileType === 'html') {
                temp.push(tables);
              } else if (selectedGroupValue.value === tables.FileName) {
                tables.BlobFile.map((item: BlobFile) => {
                  temp.push(item);
                });
              }
              setSelectedGroupTableList(temp);
            });
          } else {
            tableTemplateData?.map((tables: BlobFile) => {
              if (selectedGroupValue.value === tables.FileName) {
                tables.BlobFile.map((item: BlobFile) => {
                  temp.push(item);
                });
                setSelectedGroupTableList(temp);
              }
            });
          }
        } else {
          setSelectedGroupTableList([]);
        }
      }
    }
  }, [
    folderOptions,
    selectedGroupTableList.length,
    selectedGroupValue,
    tableTemplateData,
  ]);

  //reset header and dropdown selection to All after deleting group or last entry
  useEffect(() => {
    const value = folderOptions?.filter(
      item => item.label === selectedGroupValue.label,
    );
    if (
      value?.length === 0 &&
      selectedGroupValue.label !== 'All' &&
      !isGroupEdited
    ) {
      setGroupName('All');
      setSelectedGroupValue({ label: 'All', value: 'All' });
    }
  }, [folderOptions, isGroupEdited, selectedGroupValue.label]);

  function togglePanel() {
    setIsVisible(false);
    setTimeout(() => {
      toggle();
    }, 500);
  }

  const insertTable = () => {
    let table: Array<BlobFile> | undefined = [];
    if (selectedGroupValue.value !== 'All') {
      const tableGroup = tableTemplateData?.filter(
        data =>
          data.FileType === 'folder' &&
          data.FileName === selectedGroupValue.label,
      );
      table =
        tableGroup &&
        tableGroup[0]?.BlobFile?.filter(
          (item: BlobFile) => item.FileName === selectedTable,
        );
    } else {
      table = tableTemplateData
        ?.filter(data => data.FileType === 'html')
        ?.filter(item => item.FileName === selectedTable);
    }

    const content = table && table[0]?.Content;
    const parser = new DOMParser();
    if (content) {
      const document = parser.parseFromString(content, 'text/html');
      const docTable = document.querySelector('body table:first-of-type');
      const docTableToString = docTable?.outerHTML;
      (window as any)?.tinymce?.activeEditor?.insertContent(docTableToString);
    }
  };

  //Table deletion affects only the respective folder
  const deleteTable = (fileTitle: string) => {
    let isDeleteFromAllFolder: boolean;
    confirmAlert({
      message:
        selectedGroupValue.label === 'All'
          ? 'Are you sure you want to delete this table?'
          : selectedGroupTableList.length === 1
          ? `As you are removing the last table of group [${groupName}], it will be removed from the list of groups. Are you sure you want to remove this table from the group?`
          : 'Are you sure you want to remove this table from the group?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            if (groupName !== 'All') {
              fileTitle = `${groupName}/${fileTitle}`;
              isDeleteFromAllFolder = false;
            } else isDeleteFromAllFolder = true;
            if (fileTitle) {
              deleteFile(
                {
                  Container: 'tables',
                  Title: fileTitle,
                  IsDeleteFromAllFolder: isDeleteFromAllFolder,
                },
                {
                  onSuccess: () => {
                    setIsGroupEdited(false);
                    showSuccessToast({
                      message: 'Your changes were saved successfully.',
                    });
                  },
                  onError: error => {
                    showErrorToast({ message: `${error.message}` });
                  },
                },
              );
            }
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  //Table name edit affects all tables with same name
  const editTableTitle = (
    oldFileName: string,
    newFileName: string,
    force: boolean,
  ) => {
    let newTableName = '';
    if (oldFileName !== newFileName) {
      if (!newFileName.includes('.html'))
        newTableName = `${newFileName.trim()}.html`;
      else newTableName = newFileName.trim();
      editFile(
        {
          Title: oldFileName,
          Container: 'tables',
          ReplaceName: newTableName,
          force: force,
        },
        {
          onSuccess: message => {
            if (typeof message === 'string') {
              showErrorToast({ message: `${message}` });
            } else {
              showSuccessToast({
                message: 'Your changes were saved successfully.',
              });
            }
          },
          onError: error => {
            showErrorToast({ message: `${error.message}` });
          },
        },
      );
    }
  };

  const procedureActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          togglePanel();
          setSelectedTable('');
        }}
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        onClick={() => {
          insertTable();
          togglePanel();
          setSelectedTable('');
        }}
        disabled={selectedTable === '' || insertDisable === true ? true : false}
      >
        Insert
      </Button>
    </>
  );

  const existingTableCheck = (title: string | undefined) => {
    let checkedValue: Array<BlobFile> | undefined = [];
    const check = title?.toLowerCase().includes('.html');
    let value: string | undefined;
    if (check) value = title?.split('.')[0]?.trim();
    else value = title?.trim();
    const tableGroup = tableTemplateData?.filter(
      data => data.FileType === 'html',
    );
    checkedValue = tableGroup?.filter(item => {
      if (item.FileName.split('.')[0] === value) return item;
    });
    if (checkedValue?.length) {
      return true;
    } else return false;
  };

  const updateTable = (title: string, content: string | undefined) => {
    saveTable(
      {
        params: {
          title: title.trim(),
          content: content || '',
          container: 'tables',
        },
        saveOption: false,
      },
      {
        onSuccess: () => {
          showSuccessToast({ message: 'Table saved successfully' });
        },
      },
    );
  };

  const deleteTableGroup = (title: string) => {
    setIsGroupEdited(true);
    confirmAlert({
      message: 'Are you sure you want to delete the group?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            if (title) {
              deleteGroup(
                {
                  Container: 'tables',
                  FolderName: title,
                },
                {
                  onSuccess: () => {
                    setIsGroupEdited(false);
                    showSuccessToast({
                      message: 'Your changes were saved successfully.',
                    });
                  },
                  onError: error => {
                    showErrorToast({ message: `${error.message}` });
                  },
                },
              );
            }
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  const handleGroupChange = (e: { label: string; value: string }) => {
    setIsGroupEdited(false);
    setSelectedGroupValue(e);
    setGroupName(e.value);
  };

  const handleGroupInsertion = (
    selectedGroups: Array<Group>,
    title: string,
    content: string | undefined,
    unselectedGroups: Array<Group>,
  ) => {
    const insertTitleTemp = selectedGroups.map((group: Group) => {
      return `${group.label}/${title}`;
    });
    const insertTitleString = insertTitleTemp.join(',');
    const removeTitleTemp = unselectedGroups?.map((group: Group) => {
      return `${group.label}/${title}`;
    });
    const removeTitleString = removeTitleTemp.join(',');
    saveTable({
      params: {
        title: insertTitleString.trim(),
        content: content || '',
        container: 'tables',
      },
      saveOption: true,
    });
    if (unselectedGroups.length !== 0) {
      deleteFile(
        {
          Container: 'tables',
          Title: removeTitleString.trim(),
          IsDeleteFromAllFolder: false,
        },
        {
          onSuccess: () => {
            setIsGroupEdited(false);
          },
          onError: error => {
            showErrorToast({ message: `${error.message}` });
          },
        },
      );
    }
  };

  const handleTableGroupNameEdit = (
    oldGroupName: string,
    newGroupName: string,
  ) => {
    const isExists = folderOptions?.filter(item => item.label === newGroupName);
    if (isExists?.length) {
      showErrorToast({
        message: 'Group name already exists.Please enter a different name',
      });
    }
    editGroupTitle(
      {
        title: oldGroupName,
        container: 'tables',
        replaceName: newGroupName.trim(),
      },
      {
        onSuccess: message => {
          if (typeof message === 'string') {
            showErrorToast({ message: `${message}` });
          } else {
            setIsGroupEdited(true);
            setSelectedGroupValue({
              label: `${newGroupName.trim()}`,
              value: `${newGroupName.trim()}`,
            });
            showSuccessToast({
              message: 'Your changes were saved successfully.',
            });
          }
        },
        onError: error => {
          showErrorToast({ message: `${error.message}` });
        },
      },
    );
  };

  return (
    <SlidePanel
      open={isVisible}
      onClose={() => {
        togglePanel();
        setSelectedTable('');
      }}
      size="md"
      title="Select Table"
      actions={procedureActions()}
    >
      <FormGroup>
        <ReactSelect
          name="tableOptions"
          label="Group"
          isSearchable={true}
          isClearable={false}
          options={
            folderOptions !== undefined
              ? [{ label: 'All', value: 'All' }, ...folderOptions]
              : []
          }
          value={selectedGroupValue}
          classNamePrefix="pr-select"
          onChange={(e: any) => handleGroupChange(e)}
          onMenuOpen={() => setSelectedTable('')}
        />
      </FormGroup>
      <Separator direction="x" my={5} />
      <Stack direction="x" gap={1} alignItems="center" mb={3}>
        {editGroupName ? (
          <Stack direction="x" gap={1} alignItems="center" mb={3}>
            <StyledFormInput
              placeholder="Group name"
              style={{ paddingInline: '0.75rem', width: '300px' }}
              value={groupName}
              onChange={e => setGroupName(e.target.value)}
            />
            <Button
              size="sm"
              variant="ghost"
              iconBefore={<Icon.Check />}
              title="Save"
              onClick={() => {
                if (
                  !groupName ||
                  !groupName.trim() ||
                  groupName.toLowerCase() === 'all' ||
                  groupName.includes(',')
                ) {
                  showErrorToast({
                    message: 'Please enter a valid name',
                  });
                  return;
                } else {
                  setEditGroupName(false);
                  handleTableGroupNameEdit(selectedGroupValue.label, groupName);
                }
              }}
            />
            <Button
              size="sm"
              variant="ghost"
              iconBefore={<Icon.X />}
              title="Cancel"
              onClick={() => {
                setEditGroupName(false);
                setGroupName(selectedGroupValue.label);
              }}
            />
          </Stack>
        ) : (
          <>
            <Heading as="h3" fontSize={3} fontWeight="medium">
              {groupName}
            </Heading>
            {groupName.toLowerCase() === 'all' ? null : (
              <>
                <Button
                  variant="ghost"
                  size="sm"
                  title="Edit group name"
                  onClick={() => setEditGroupName(true)}
                >
                  <Icon.Edit ariaHidden="true" />
                  <VisuallyHidden>Edit Group Name</VisuallyHidden>
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  title="Remove group"
                  onClick={() => deleteTableGroup(groupName)}
                >
                  <Icon.Delete />
                  <VisuallyHidden>Remove Group</VisuallyHidden>
                </Button>
              </>
            )}
          </>
        )}
      </Stack>
      <Stack direction="y" gap={3}>
        {selectedGroupTableList?.map((table, i) => (
          <ProcedureAccordion
            key={i}
            title={table.FileName}
            content={table.Content}
            open={selectedTable}
            container="tables"
            setOpen={setSelectedTable}
            deleteTemplate={deleteTable}
            editTemplate={editTableTitle}
            templateExistingCheck={existingTableCheck}
            updateTemplate={updateTable}
            setInsertDisable={setInsertDisable}
            templateGroups={folderOptions}
            handleGroupInsertion={handleGroupInsertion}
            mappedGrouping={mappedGrouping}
            ungroupedSet={updatedGroups?.filter(
              item => item.FileType === 'html',
            )}
            selectedGroupDropDown={selectedGroupValue}
          />
        ))}
      </Stack>
      {selectedGroupTableList?.length === 0 ? (
        <EmptyState>
          <NoDataImage feedback="No Tables Available" />
        </EmptyState>
      ) : null}
      {(tableDataLoading ||
        isDeleteLoading ||
        isEditLoading ||
        isDeleteTableGroupLoading ||
        isSaveTableLoading ||
        isEditGroupNameLoading) && <Loader />}
    </SlidePanel>
  );
}

export default ExistingTable;
