import styled, { css } from 'styled-components';
import { ButtonReset } from 'ui/utils/buttonReset';
import { transparentize } from 'polished';

type VerticalNavWrapperProps = {
  collapsed?: boolean;
};

const VerticalNavWrapper = styled.div<VerticalNavWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: ${({ theme }) => theme.space[3]};
  padding-bottom: 0; */
  background: ${({ theme }) => theme.baseColors.gray[8]};
  border-right: solid 1px transparent;
  border-top: solid 1px transparent;
  width: 220px;
  min-width: 220px;
  flex-shrink: 0;
  /* flex-basis: 220px;
  will-change: flex-basis;
  transition: flex-basis 350ms cubic-bezier(0.22, 0.61, 0.36, 1); */

  html[data-color-mode='dark'] & {
    background: ${({ theme }) => theme.baseColors.gray[9]};
    border-right: solid 1px var(--color-neutral-contrast-02);
    border-top: solid 1px var(--color-neutral-contrast-02);
  }

  ${({ collapsed }) =>
    collapsed &&
    css`
      width: 64px;
      min-width: 64px;
      flex-shrink: 1;
      /* flex-basis: 64px;
      transition: flex-basis 250ms cubic-bezier(0.22, 0.61, 0.36, 1); */
    `}
`;

type NavItemInnerProps = {
  collapsed?: boolean;
};

const NavItemInner = styled.button<NavItemInnerProps>`
  ${ButtonReset};
  width: 100%;
  --h: ${({ theme }) => theme.colors.black};
  --s: ${({ theme }) => theme.colors.black};
  --l: ${({ theme }) => theme.colors.black};
  --a: 0%;

  --switch: calc((50% - var(--contrast-threshold)) * -100);

  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  text-decoration: none;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  color: hsl(0, 0%, var(--switch));
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  line-height: 1;
  border-radius: ${({ theme }) => theme.radii.rounded};
  transition: background 200ms ease-in-out;
  background: hsla(var(--h), var(--s), var(--l), var(--a));
  text-align: left;
  cursor: pointer;

  /* Accessibe fixes */
  body.acsb-vision-profile & {
    padding-block: ${({ theme }) => theme.space[1]};
  }

  & > .pr-icon:first-of-type {
    opacity: 0.8;
  }

  .pr-v-nav-label {
    line-height: 1;
    margin-left: ${({ theme }) => theme.space[2]};
    white-space: nowrap;
  }

  .arrow-icon {
    opacity: 0;
    transform: translateX(-0.4rem);
    transition: all 350ms ease-in-out;
    margin-left: auto;
  }

  &:hover {
    --a: 50%;
    .arrow-icon {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 3px
      ${({ theme }) => theme.colors.transparent.black50};
  }

  &.active,
  &.active:hover {
    --h: var(--primary-h);
    --s: var(--primary-s);
    --l: var(--primary-l);
    --a: 100%;
    color: var(--color-on-primary);
    .arrow-icon {
      transform: translateX(0);
      opacity: 1;
    }
  }

  ${({ collapsed }) =>
    collapsed &&
    css`
      .pr-v-nav-label,
      .arrow-icon {
        display: none;
      }
    `}
`;

const StyledVerticalNav = styled.div`
  padding: ${({ theme }) => theme.space[2]} !important;
  margin-top: ${({ theme }) => theme.space[4]} !important;
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  z-index: 1;
  gap: ${({ theme }) => theme.space[2]};

  /* Accessibe fixes */
  body.acsb-vision-profile & {
    padding-top: ${({ theme }) => theme.space[1]} !important;
  }

  /* & > * + * {
    margin-top: ${({ theme }) => theme.space[2]};
  } */

  & ${NavItemInner}:not(.active) {
    opacity: 0.65;
    transition: opacity 200ms ease-in-out;
  }

  &:hover ${NavItemInner}:not(.active) {
    opacity: 1;
  }
`;

const CollapseToggle = styled.button`
  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
  ${ButtonReset};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.1;
  width: 100%;
  color: ${({ theme }) => theme.baseColors.gray[0]};
  background: ${({ theme }) => transparentize(0.6, theme.baseColors.gray[7])};

  &:hover {
    background: ${({ theme }) => transparentize(0.4, theme.baseColors.gray[7])};
  }

  &:active,
  &:focus {
    outline: none;
    background: ${({ theme }) => transparentize(0.2, theme.baseColors.gray[7])};
  }

  html[data-color-mode='dark'] & {
    background: ${({ theme }) => transparentize(0.6, theme.baseColors.gray[8])};

    &:hover {
      background: ${({ theme }) =>
        transparentize(0.4, theme.baseColors.gray[8])};
    }

    &:active,
    &:focus {
      outline: none;
      background: ${({ theme }) =>
        transparentize(0.2, theme.baseColors.gray[8])};
    }
  }
`;

export { VerticalNavWrapper, NavItemInner, StyledVerticalNav, CollapseToggle };
