import React, {
  ReactElement,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { confirmAlert } from 'utils/confirm-alert';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useHistory } from 'react-router-dom';
import { Stack, Grid, Checkbox, Button } from 'ui/components';
import {
  SlidePanel,
  Alert,
  SearchInput,
  Card,
  CardHeader,
  CardBody,
  Table,
  EmptyState,
  Loader,
} from 'ui/patterns';
import NoDataImage from 'assets/images/NoDataImage';
import * as Icon from 'assets/icons';
import {
  useAccounts,
  useGetPublishedProcedures,
  useSaveImportAccount,
  useGetUserSettings,
  useGetFtpSettings,
} from 'hooks';
import useAccountsColumns from './useAccountsColumns';
import { checkBoxList } from '../CopyAccount/checkBoxList';
import { showErrorToast, showSuccessToast } from 'utils/showToast';
import { ImportAccountToggles } from 'types/accounts';

type ImportAccountProps = {
  showPanel: boolean;
  togglePanel: () => void;
  account: string;
  importAccountId: number;
};

type ProcedureItemType = {
  ProcedureId: number;
  ProcedureName: string;
};

type ProcedureType = Array<ProcedureItemType> | undefined;

type CopiedDataItemType = {
  id: number;
  label: string;
  name: string;
};

type CopiedDataType = Array<CopiedDataItemType>;

const sortItem = { sortKey: 'Phone Display', sortOrder: 'asc' };

export default function ImportAccount({
  showPanel,
  togglePanel,
  account,
  importAccountId,
}: ImportAccountProps): ReactElement {
  const history = useHistory<{ ID: number }>();
  const [columnsSearch, setColumnsSearch] = useState({}); // header search
  const [currentSortItem, setCurrentSortItem] = useState(sortItem);
  const [page] = useState(0);
  const [keywordSearch, setKeywordSearch] = useState(''); // keyword search
  const accountStatus = 'Live';
  const [accountId, setAccountId] = useState(0); // selected account id from table

  const {
    data,
    fetchMore,
    canFetchMore,
    // isFetchingMore,
    isLoading,
    isFetching,
  } = useAccounts(
    page,
    accountStatus,
    keywordSearch,
    columnsSearch,
    currentSortItem,
  );

  const { data: userSettings } = useGetUserSettings();

  const columns = useAccountsColumns(userSettings);
  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch],
  );
  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (
        currentSortItem.sortKey === item &&
        currentSortItem.sortOrder === 'asc'
      ) {
        sortOrder = 'desc';
      }
      setCurrentSortItem({
        sortKey: item,
        sortOrder,
      });
    },
    [setCurrentSortItem, currentSortItem],
  );

  const handleKeywordSearch = (value: string): void => {
    setKeywordSearch(value);
  };

  const handleReset = (): void => {
    setKeywordSearch('');
    setColumnsSearch({});
    setCurrentSortItem(sortItem);
    setAccountId(0);
  };

  const handleRowClick = (row: any): void => {
    if (row.original.Id === importAccountId) {
      confirmAlert({
        message: 'Account cannot be imported to the same account.',
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
              //
            },
            className: 'pr-btn-primary',
          },
        ],
      });
    } else {
      setSelectedProcedure([]);
      setSelectedCopiedData([]);
      setAccountId(row.original.Id);
    }
  };

  const { data: publishedProcedures, isLoading: isProcedureLoading } =
    useGetPublishedProcedures(accountId);

  const [selectedProcedure, setSelectedProcedure] = useState<ProcedureType>([]);
  const [procedures, setProcedures] = useState<ProcedureType>([]);

  const [saveImportAccount, { isLoading: isSaveImportAccountLoading }] =
    useSaveImportAccount();
  const { data: ftpInfo, isLoading: isGetLoading } =
    useGetFtpSettings(accountId);

  useEffect(() => {
    if (publishedProcedures && publishedProcedures?.length > 0)
      setProcedures([...publishedProcedures]);
    else setProcedures([]);
  }, [publishedProcedures]);

  const [selectedCopiedData, setSelectedCopiedData] = useState<CopiedDataType>(
    [],
  );
  const [copiedData, setCopiedData] = useState<CopiedDataType>([
    ...checkBoxList,
  ]);

  const [searchValue, setSearchValue] = useState<string>();

  const tableData = useMemo(() => {
    if (data) {
      return data.map(dataItem => dataItem.data).flat();
    }
  }, [data]);

  const totalItems = useMemo(() => {
    if (data) {
      return data[0].recordsFiltered;
    }
    return 0;
  }, [data]);

  const onEndReached = () => {
    if (tableData) {
      if (canFetchMore && tableData?.length < totalItems) fetchMore();
    }
  };

  const isDataToCopyChecked = (id: number) => {
    const item =
      selectedCopiedData && selectedCopiedData.find(item => item.id === id);
    if (item) {
      return true;
    } else return false;
  };

  const handleSelectDataToCopy = (dataItem: CopiedDataItemType) => {
    const index =
      selectedCopiedData && selectedCopiedData.length > 0
        ? selectedCopiedData.findIndex(item => item.id === dataItem.id)
        : -1;
    if (index === -1) {
      selectedCopiedData &&
        selectedCopiedData.push({
          id: dataItem.id,
          label: dataItem.label,
          name: dataItem.name,
        });
    } else if (index >= 0) {
      selectedCopiedData && selectedCopiedData.splice(index, 1);
    }

    if (selectedCopiedData) setSelectedCopiedData([...selectedCopiedData]);
  };

  const isCheckedAllData = () => {
    if (
      selectedCopiedData?.length ===
      (copiedData && copiedData.length > 0 && copiedData.length)
    )
      return true;
    else return false;
  };

  const handleSelectAllData = () => {
    let allDatas: CopiedDataItemType[] = [];
    const isSelect = isCheckedAllData();
    if (!isSelect) {
      allDatas =
        copiedData && copiedData.length > 0
          ? copiedData.map(item => {
              return {
                id: item.id,
                label: item.label,
                name: item.name,
              };
            })
          : [];
    } else {
      allDatas = [];
    }
    setSelectedCopiedData([...allDatas]);
  };

  const isProcedureChecked = (id: number) => {
    const item =
      selectedProcedure &&
      selectedProcedure.find(item => item.ProcedureId === id);
    if (item) {
      return true;
    } else return false;
  };

  const handleSelectProcedure = (procItem: {
    ProcedureId: number;
    ProcedureName: string;
  }) => {
    const index =
      selectedProcedure && selectedProcedure.length > 0
        ? selectedProcedure.findIndex(
            item => item.ProcedureId === procItem.ProcedureId,
          )
        : -1;
    if (index === -1) {
      selectedProcedure &&
        selectedProcedure.push({
          ProcedureId: procItem.ProcedureId,
          ProcedureName: procItem.ProcedureName,
        });
    } else if (index >= 0) {
      selectedProcedure && selectedProcedure.splice(index, 1);
    }

    if (selectedProcedure) setSelectedProcedure([...selectedProcedure]);
  };

  const isCheckedAllProcedures = () => {
    if (
      selectedProcedure?.length ===
      (procedures && procedures.length > 0 && procedures.length)
    )
      return true;
    else return false;
  };

  const handleSelectAllProcedures = () => {
    let allprocedures: { ProcedureId: number; ProcedureName: string }[] = [];
    const isSelect = isCheckedAllProcedures();
    if (!isSelect) {
      allprocedures =
        procedures && procedures.length > 0
          ? procedures.map(item => {
              return {
                ProcedureId: item.ProcedureId,
                ProcedureName: item.ProcedureName,
              };
            })
          : [];
    } else {
      allprocedures = [];
    }
    if (allprocedures) setSelectedProcedure([...allprocedures]);
  };

  const handleProcedureSearch = (value: string) => {
    let newProcedures:
      | { ProcedureId: number; ProcedureName: string }[]
      | undefined = [];
    newProcedures =
      publishedProcedures &&
      publishedProcedures.filter(item => {
        if (item.ProcedureName.toLowerCase().includes(value.toLowerCase()))
          return item;
        return false;
      });

    if (newProcedures) setProcedures([...newProcedures]);
    if (newProcedures?.length === 0) setSelectedProcedure([...newProcedures]);
    setSearchValue(value);
  };

  function resetForm() {
    setSelectedProcedure([]);
    setProcedures(publishedProcedures);
    setSelectedCopiedData([]);
    setCopiedData(checkBoxList);
    handleReset();
  }

  const startInterval = () => {
    let index = 0;
    interval = setInterval(() => {
      if (index < selectedCopiedData.length) {
        setLoadingText(`Copying ${selectedCopiedData[index].label}`);
      } else if (
        selectedProcedure &&
        selectedProcedure.length > 0 &&
        loadingText !== 'Finalizing Account Copy'
      ) {
        setLoadingText('Copying Procedures');
      } else {
        setLoadingText('Finalizing Account Copy');
      }
      index = index + 1;
    }, 500);
  };

  const importData = () => {
    const procedureList =
      (selectedProcedure && selectedProcedure.map(item => item.ProcedureId)) ||
      [];

    const initialList: ImportAccountToggles = {
      CopyOfficeFacts: false,
      CopyLocationList: false,
      CopyContactList: false,
      CopyCustomTablesAndForms: false,
      CopyTagMappings: false,
      CopyFtpSettings: false,
    };

    if (
      selectedCopiedData.findIndex(
        selectedItem => selectedItem.name === 'CopyOfficeFacts',
      ) >= 0
    ) {
      initialList.CopyOfficeFacts = true;
    }
    if (
      selectedCopiedData.findIndex(
        selectedItem => selectedItem.name === 'CopyLocationList',
      ) >= 0
    ) {
      initialList.CopyLocationList = true;
    }

    if (
      selectedCopiedData.findIndex(
        selectedItem => selectedItem.name === 'CopyContactList',
      ) >= 0
    ) {
      initialList.CopyContactList = true;
    }
    if (
      selectedCopiedData.findIndex(
        selectedItem => selectedItem.name === 'CopyCustomTablesAndForms',
      ) >= 0
    ) {
      initialList.CopyCustomTablesAndForms = true;
    }
    if (
      selectedCopiedData.findIndex(
        selectedItem => selectedItem.name === 'CopyTagMappings',
      ) >= 0
    ) {
      initialList.CopyTagMappings = true;
    }
    if (
      selectedCopiedData.findIndex(
        selectedItem => selectedItem.name === 'CopyFtpSettings',
      ) >= 0
    ) {
      initialList.CopyFtpSettings = true;
    }

    startInterval();
    saveImportAccount(
      {
        ...initialList,
        AccountId: accountId,
        ProcedureIds: procedureList,
        AccountIdImported: importAccountId,
      },
      {
        onSuccess: mutationResult => {
          clearInterval(interval);
          setLoadingText('Finalizing Account Import');
          setTimeout(() => {
            setLoadingText('');
            history.replace(
              `/home/accounts/edit/${mutationResult.Id}/accountInfo`,
              { ID: mutationResult.Id },
            );
            togglePanel();
            resetForm();
            showSuccessToast({
              message: 'Your changes were successfully saved.',
              autoClose: 3000,
            });
          }, 500);
        },
        onError: e => {
          clearInterval(interval);
          setLoadingText('');
          showErrorToast({ message: e.Message, autoClose: 10000 });
        },
      },
    );
  };

  function handleSubmit() {
    const ftpChecked = selectedCopiedData.find(
      item => item.label === 'FTP Settings',
    );
    if (ftpChecked && ftpInfo?.IsEnable) {
      confirmAlert({
        buttonOrder: true,
        message:
          'WARNING: This process will copy SFTP settings to the new account. All documents finalized in the new account will send to the same server. Are you sure you want to include SFTP Settings in this account copy process?',
        buttons: [
          {
            label: 'Cancel',
            onClick: () => {
              //
            },
            className: 'pr-btn-secondary',
          },
          {
            label: 'No',
            onClick: () => {
              const selected = copiedData.find(item => item.id === 6);
              if (selected) handleSelectDataToCopy(selected);
              importData();
            },
          },
          {
            label: 'Yes',
            onClick: () => {
              importData();
            },
            className: 'pr-btn-primary',
          },
        ],
      });
    } else {
      importData();
    }
  }

  const [loadingText, setLoadingText] = useState<string>('');

  let interval: ReturnType<typeof setTimeout>;

  const importAccountActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          togglePanel();
          resetForm();
        }}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        disabled={
          accountId &&
          ((selectedCopiedData && selectedCopiedData?.length > 0) ||
            (selectedProcedure && selectedProcedure?.length > 0))
            ? false
            : true
        }
      >
        Save
      </Button>
    </>
  );

  return (
    <>
      <SlidePanel
        title={`Import Account Data to - ${account}`}
        actions={importAccountActions()}
        onClose={() => {
          togglePanel();
          resetForm();
        }}
        open={showPanel}
        size="lg"
      >
        <Stack direction="y" gap={6}>
          <Alert variant="warning" icon={<Icon.Warning />}>
            <strong>Warning!</strong> When importing data, existing data in
            selected areas will be overwritten.
          </Alert>

          <Box>
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
              <Text fontSize={2} color="text.body" fontWeight="medium">
                Select Account to Import From
              </Text>
              <Stack direction="x" gap={2}>
                <SearchInput
                  placeholder="Search Accounts"
                  handleChange={handleKeywordSearch}
                />
                <Button
                  iconBefore={<Icon.Refresh title="" ariaHidden="true" />}
                  variant="secondary"
                  onClick={handleReset}
                >
                  Reset All
                </Button>
              </Stack>
            </Flex>

            <Table
              columns={columns}
              data={tableData}
              isLoading={isLoading} //|| isFetching
              headerUpdateCallBack={headerUpdateCallBack}
              handleSortChange={handleSortChange}
              onRowClick={handleRowClick}
              sortedItem={currentSortItem}
              columnsSearch={columnsSearch}
              height="350px"
              noOptions
              selectedRowId={accountId}
              onEndReached={onEndReached}
              // isFetching={!!isFetchingMore}
            />

            <Flex mt={4} justifyContent="space-between" alignItems="center">
              <Text fontSize={1} color="text.body" sx={{ height: '20px' }}>
                {tableData && tableData.length > 0 && (
                  <Text mb={2}>{`Showing ${tableData.length} of ${totalItems}${
                    isFetching ? ' | Processing' : ''
                  }`}</Text>
                )}
                {/* {data &&
                  !isFetching &&
                  `Showing ${pageLength * page + 1} to ${
                    pageLength * page + (latestData?.data?.length || 0)
                  } of ${data?.recordsFiltered}`}
                {isFetching && 'Processing'} */}
              </Text>
              {/* <Pagination
                page={page}
                numberOfPages={numberOfPages}
                onClick={setPageNumber}
              /> */}
            </Flex>
          </Box>
          <Card>
            <CardHeader px={3} py={2}>
              <Flex justifyContent="space-between" alignItems="center">
                <Text
                  as="h5"
                  fontSize={2}
                  color="text.body"
                  fontWeight="medium"
                >
                  Data to Import
                </Text>
                <Checkbox
                  label="Select All"
                  name="allDatas"
                  checked={isCheckedAllData()}
                  handleChange={handleSelectAllData}
                />
              </Flex>
            </CardHeader>
            <CardBody p={3}>
              <Grid minColWidth="200px" gap={4}>
                {checkBoxList &&
                  checkBoxList.map(dataItem => (
                    <Checkbox
                      key={dataItem.id}
                      label={dataItem.label}
                      name={dataItem.label}
                      checked={isDataToCopyChecked(dataItem.id)}
                      handleChange={() => handleSelectDataToCopy(dataItem)}
                    />
                  ))}
              </Grid>
            </CardBody>
          </Card>
          <Card>
            <CardHeader px={3} py={2}>
              <Flex justifyContent="space-between" alignItems="center">
                <Text
                  as="h5"
                  fontSize={2}
                  color="text.body"
                  fontWeight="medium"
                >
                  Select Procedures to Import to this Account{' '}
                  <Text as="small" fontWeight="regular">
                    {`(You have selected ${selectedProcedure?.length} out of ${
                      procedures?.length || 0
                    } procedures)`}
                  </Text>
                </Text>
                <Stack direction="x" gap={3}>
                  <SearchInput
                    placeholder="Search Procedures"
                    value={searchValue}
                    handleChange={handleProcedureSearch}
                  />
                  <Checkbox
                    label="Select All"
                    name="allProcedures"
                    checked={isCheckedAllProcedures()}
                    handleChange={handleSelectAllProcedures}
                  />
                </Stack>
              </Flex>
            </CardHeader>
            <CardBody p={3} minHeight="200px" style={{ position: 'relative' }}>
              <Grid minColWidth="220px" gap={4}>
                {isProcedureLoading ? (
                  <Loader />
                ) : procedures && procedures.length > 0 ? (
                  procedures.map(procItem => (
                    <Checkbox
                      key={procItem.ProcedureId}
                      label={procItem.ProcedureName}
                      name={procItem.ProcedureName}
                      checked={isProcedureChecked(procItem.ProcedureId)}
                      handleChange={() => handleSelectProcedure(procItem)}
                    />
                  ))
                ) : (
                  <EmptyState>
                    <NoDataImage feedback="No data available" />
                  </EmptyState>
                )}
              </Grid>
            </CardBody>
          </Card>
        </Stack>
      </SlidePanel>
      {(isSaveImportAccountLoading || loadingText) && (
        <Loader text={`${loadingText}`} />
      )}
    </>
  );
}
