import { useQuery, QueryResult } from 'react-query';
import { getProceduresInProcedureBuilder } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { ProcedureListType } from 'types/procedureBuilder';

async function fetchProcedure({
  account,
}: TVariables): Promise<TResult & TError> {
  const response = await fetch(getProceduresInProcedureBuilder(account), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = Array<ProcedureListType> | undefined;

export type TError = { Message: string };
export type TVariables = {
  account: number;
};

function useGetProcedureBuilderProcedures(
  account?: number,
): QueryResult<TResult, TError> {
  return useQuery(
    ['get_procedure_builder_procedures', account],
    async () => {
      if (!account) return;
      const procedureList = await fetchProcedure({
        account,
      });
      if (procedureList?.Message) throw procedureList;
      return procedureList;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetProcedureBuilderProcedures };
