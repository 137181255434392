import { useQuery, QueryResult } from 'react-query';
import { getTextFormats } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchTextFormats(): Promise<APIResult> {
  const response = await fetch(getTextFormats, {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TextFormatResult = {
  Description: string;
  Id: number;
  Pattern: string;
};
export type APIResult = Array<TextFormatResult>;

export type ResultItem = {
  id: number;
  name: string;
};
export type TResult = Array<ResultItem>;

export type TError = { message: string };

function useTextFormats(): QueryResult<TResult, TError> {
  return useQuery(
    'get_text_formats',
    async () => {
      const fetchResponse = await fetchTextFormats();
      const result = fetchResponse.map(item => ({
        id: item.Id,
        name: item.Description,
      }));
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useTextFormats };
