import React, { ReactNode, useState, ReactElement } from 'react';
import styled, { css } from 'styled-components';

type TabsWrapperProps = {
  importProcedure?: boolean;
};

const TabsWrapper = styled.div<TabsWrapperProps>`
  --yellow: ${({ theme }) => theme.colors.primary};

  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr;

  & > [role='tablist'] {
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 10px; */

    display: flex;

    & > * + * {
      margin-left: ${({ theme }) => theme.space[2]};
    }

    ${({ importProcedure }) =>
      importProcedure &&
      css`
        justify-content: space-around;
      `}
  }

  & > [role='tablist'] > button {
    --bs-color: rgba(0, 0, 0, 0.1);
    --bg: var(--color-neutral-contrast-03);

    margin-bottom: -1px;
    padding: ${({ theme }) => theme.space[2]};
    color: ${({ theme }) => theme.colors.text.body};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    border-radius: ${({ theme }) => theme.radii.soft}
      ${({ theme }) => theme.radii.soft} 0 0;
    box-shadow: inset 0 -2px 5px var(--bs-color);
    background: var(--bg);
    border: solid 1px var(--color-neutral-contrast-04);
    cursor: pointer;

    html[data-color-mode='dark'] & {
      --bg: var(--color-neutral-contrast-02);
    }

    ${({ importProcedure }) =>
      importProcedure &&
      css`
        background: none;
        border: none;
        border-bottom: solid 3px transparent;
        box-shadow: none;
        color: var(--color-neutral-contrast-06);
      `}
  }

  & > [role='tablist'] > button[aria-selected='true'] {
    background: var(--color-neutral-contrast-00);
    --border-bottom-color: ${({ theme }) => theme.colors.white};
    border: solid 1px ${({ theme }) => theme.colors.border.default};
    border-bottom: solid 1px var(--border-bottom-color);
    box-shadow: none;
    z-index: 1;

    html[data-color-mode='dark'] & {
      --border-bottom-color: var(--color-neutral-contrast-00);
    }

    ${({ importProcedure }) =>
      importProcedure &&
      css`
        border: none;
        border-bottom: solid 3px ${({ theme }) => theme.colors.primary};
        border-radius: 0;
        color: ${({ theme }) => theme.colors.text.body};
      `}
  }

  & > [role='tablist'] > button:focus {
    outline: 0;
    --bs-color: rgba(0, 0, 0, 0.4);
  }

  & > [role='tabpanel'] {
    position: relative;
    padding: ${({ theme }) => theme.space[4]};
    opacity: 1;
    border: solid 1px ${({ theme }) => theme.colors.border.default};
    border-bottom-left-radius: ${({ theme }) => theme.radii.soft};
    border-bottom-right-radius: ${({ theme }) => theme.radii.soft};
    overflow: auto;

    ${({ importProcedure }) =>
      importProcedure &&
      css`
        padding: 0;
        border-left: none;
        border-right: none;
        border-bottom: none;
      `}
  }

  & > [role='tabpanel'][hidden] {
    opacity: 0;
    transition: all 0.3s ease-in;
  }
`;

const TabContent = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  height: 1px;
  padding: ${({ theme }) => theme.space[4]};
`;

const TabFooter = styled.footer`
  --bg: var(--color-neutral-contrast-03);
  --border: var(--color-neutral-contrast-04);

  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
  background-color: var(--bg);
  border-top: 1px solid var(--border);

  html[data-color-mode='dark'] & {
    --bg: var(--color-neutral-contrast-01);
    --border: var(--color-neutral-contrast-02);
  }
`;

type TabsProps = {
  tabs: Array<tabcontentitem>;
  tabDescription?: string;
  tabActions?: ReactNode;
  importProcedure?: boolean;
};

type tabcontentitem = {
  id: string;
  label: string | ReactNode;
  content: string | ReactNode;
  defaultSelected?: any;
};

function AboutTabs({
  tabs,
  tabDescription,
  importProcedure,
}: TabsProps): ReactElement {
  const value = sessionStorage.getItem('tabId');

  const [selectedTab, setSelectedTab] = useState(value ? value : tabs[0]?.id);
  function handleOnClick(e: any) {
    setSelectedTab(e.target.id);
    sessionStorage.setItem('tabId', e.target.id);
  }

  return (
    <TabsWrapper importProcedure={importProcedure}>
      <div role="tablist" aria-label={tabDescription}>
        {tabs.map(({ id, label }) => (
          <button
            key={id}
            role="tab"
            aria-selected={selectedTab === id ? true : false}
            onClick={handleOnClick}
            id={id}
          >
            {label}
          </button>
        ))}
      </div>
      {tabs.map(({ id, content }) => (
        <div
          key={id}
          role="tabpanel"
          aria-labelledby={id}
          hidden={selectedTab === id ? false : true}
        >
          {content}
        </div>
      ))}
    </TabsWrapper>
  );
}

export { AboutTabs, TabContent, TabFooter };
