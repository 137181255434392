import React, { Component, ReactNode } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Checkbox } from 'ui/components';
import './confirm-alert.css';

type ButtonItem = {
  label?: string;
  onClick?: (cb?: () => void) => void;
  className?: string;
};

type Props = {
  title: string;
  message: string;
  buttons: Array<ButtonItem>;
  childrenElement: () => void;
  customUI?: (event: any) => ReactNode;
  closeOnClickOutside: boolean;
  closeOnEscape: boolean;
  willUnmount: () => void;
  afterClose: () => void;
  onClickOutside: () => void;
  onKeypressEscape: (event: any) => void;
  overlayClassName: string;
  buttonOrder: boolean;
  dontShowAgain: boolean;
  handleModalVisibility: (event: any) => void;
  closeOnButtonClick?: boolean;
};

export default class ReactConfirmAlert extends Component<Props> {
  //   static propTypes = {
  //     title: PropTypes.string,
  //     message: PropTypes.string,
  //     buttons: PropTypes.array.isRequired,
  //     childrenElement: PropTypes.func,
  //     customUI: PropTypes.func,
  //     closeOnClickOutside: PropTypes.bool,
  //     closeOnEscape: PropTypes.bool,
  //     willUnmount: PropTypes.func,
  //     afterClose: PropTypes.func,
  //     onClickOutside: PropTypes.func,
  //     onKeypressEscape: PropTypes.func,
  //     overlayClassName: PropTypes.string
  //   }

  static defaultProps = {
    buttons: [
      {
        label: 'Cancel',
        onClick: () => null,
        className: null,
      },
      {
        label: 'Confirm',
        onClick: () => null,
        className: null,
      },
    ],
    childrenElement: () => null,
    closeOnClickOutside: true,
    closeOnEscape: true,
    willUnmount: () => null,
    afterClose: () => null,
    onClickOutside: () => null,
    onKeypressEscape: () => null,
    closeOnButtonClick: true,
  };

  overlay: HTMLDivElement | null = null;

  handleClickButton = (button: ButtonItem) => {
    if (button.onClick) button.onClick(this.close);
    if (this.props.closeOnButtonClick) this.close();
  };

  handleClickOverlay = (e: React.MouseEvent<HTMLDivElement>) => {
    const { closeOnClickOutside, onClickOutside } = this.props;
    const isClickOutside = e?.target === this.overlay;

    if (closeOnClickOutside && isClickOutside) {
      onClickOutside();
      this.close();
    }
  };

  close = () => {
    const { afterClose } = this.props;
    removeBodyClass();
    removeElementReconfirm();
    removeSVGBlurReconfirm(afterClose);
  };

  keyboardClose = (event: KeyboardEvent): void => {
    const { closeOnEscape, onKeypressEscape } = this.props;
    const isKeyCodeEscape = event.keyCode === 27;

    if (closeOnEscape && isKeyCodeEscape) {
      onKeypressEscape(event);
      this.close();
    }
  };

  componentDidMount = () => {
    document.addEventListener('keydown', this.keyboardClose, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.keyboardClose, false);
    this.props.willUnmount();
  };

  renderCustomUI = () => {
    const { title, message, buttons, customUI } = this.props;
    const dataCustomUI = {
      title,
      message,
      buttons,
      onClose: this.close,
    };

    return customUI ? customUI(dataCustomUI) : null;
  };

  render() {
    const {
      title,
      message,
      buttons,
      childrenElement,
      customUI,
      overlayClassName,
      buttonOrder,
      dontShowAgain,
      handleModalVisibility,
    } = this.props;

    return (
      <div
        className={`react-confirm-alert-overlay ${overlayClassName}`}
        ref={dom => (this.overlay = dom)}
        onClick={this.handleClickOverlay}
      >
        <div className="react-confirm-alert">
          {customUI ? (
            this.renderCustomUI()
          ) : (
            <div className="react-confirm-alert-body">
              {title && <h1>{title}</h1>}
              {message}
              {childrenElement()}

              {dontShowAgain && (
                <label htmlFor="dontShowAgain" className="dont-show-checkbox">
                  <input
                    type="checkbox"
                    id="dontShowAgain"
                    onClick={e => handleModalVisibility(e)}
                  />{' '}
                  <span>Do not show this again</span>
                </label>
              )}

              <div
                className={
                  buttonOrder
                    ? 'button-order react-confirm-alert-button-group'
                    : dontShowAgain
                    ? 'with-dontShow-checkbox react-confirm-alert-button-group'
                    : 'react-confirm-alert-button-group'
                }
              >
                {buttons.map((button, i) => (
                  <button
                    autoFocus={i === 0}
                    key={i}
                    onClick={() => this.handleClickButton(button)}
                    className={button.className}
                  >
                    {button.label}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function createSVGBlurReconfirm() {
  // If has svg ignore to create the svg
  const svg = document.getElementById('react-confirm-alert-firm-svg');
  if (svg) return;
  const svgNS = 'http://www.w3.org/2000/svg';
  const feGaussianBlur = document.createElementNS(svgNS, 'feGaussianBlur');
  feGaussianBlur.setAttribute('stdDeviation', '0.3');

  const filter = document.createElementNS(svgNS, 'filter');
  filter.setAttribute('id', 'gaussian-blur');
  filter.appendChild(feGaussianBlur);

  const svgElem = document.createElementNS(svgNS, 'svg');
  svgElem.setAttribute('id', 'react-confirm-alert-firm-svg');
  svgElem.setAttribute('class', 'react-confirm-alert-svg');
  svgElem.appendChild(filter);

  document.body.appendChild(svgElem);
}

function removeSVGBlurReconfirm(afterClose: () => void) {
  const svg = document.getElementById('react-confirm-alert-firm-svg');
  if (svg) {
    svg?.parentNode?.removeChild(svg);
  }
  document.body.children[0].classList.remove('react-confirm-alert-blur');
  afterClose();
}

function createElementReconfirm(properties: any) {
  let divTarget = document.getElementById('react-confirm-alert');
  if (divTarget) {
    // Rerender - the mounted ReactConfirmAlert
    render(<ReactConfirmAlert {...properties} />, divTarget);
  } else {
    // Mount the ReactConfirmAlert component
    document.body.children[0].classList.add('react-confirm-alert-blur');
    divTarget = document.createElement('div');
    divTarget.id = 'react-confirm-alert';
    document.body.appendChild(divTarget);
    render(<ReactConfirmAlert {...properties} />, divTarget);
  }
}

function removeElementReconfirm() {
  const target = document.getElementById('react-confirm-alert');
  if (target) {
    unmountComponentAtNode(target);
    target?.parentNode?.removeChild(target);
  }
}

function addBodyClass() {
  document.body.classList.add('react-confirm-alert-body-element');
}

function removeBodyClass() {
  document.body.classList.remove('react-confirm-alert-body-element');
}

export function confirmAlert(properties: any) {
  addBodyClass();
  createSVGBlurReconfirm();
  createElementReconfirm(properties);
}
