import styled from 'styled-components';
// import {
//   space,
//   SpaceProps,
//   flex,
//   FlexProps,
//   layout,
//   LayoutProps,
// } from 'styled-system';

type SlidePanelBackDropProps = {
  open?: boolean;
};

export const SlidePanelBackDrop = styled.div<SlidePanelBackDropProps>`
  --bg: ${({ theme }) => theme.colors.transparent.black15};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  /* -webkit-backdrop-filter: ${({ open }) => (open ? 'blur(3px)' : 'blur(0)')};
  backdrop-filter: ${({ open }) => (open ? 'blur(3px)' : 'blur(0)')}; */
  opacity: ${({ open }) => (open ? '1' : '0')};
  background: var(--bg);
  transition: opacity 640ms cubic-bezier(0.65, 0.05, 0.36, 1);
  pointer-events: ${({ open }) => (open ? 'initial' : 'none')};

  html[data-color-mode='dark'] & {
    --bg: hsla(var(--color-neutral-contrast-03-hsl), 70%);
  }
`;

type StyledSlidePanelProps = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  maxWidth?: string;
  open?: boolean;
  // ref?: React.RefObject<HTMLDivElement>;
  ref?: any;
};

export const StyledSlidePanel = styled.div<StyledSlidePanelProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--color-neutral-contrast-00);
  min-width: 30vw;
  height: 100vh;
  text-align: left;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 540ms cubic-bezier(0.65, 0.05, 0.36, 1);
  /* box-shadow: ${({ theme }) => theme.shadows.xLarge}; */
  box-shadow: ${({ open, theme }) => (open ? theme.shadows.xLarge : 'none')};
  z-index: 1061;
  width: ${({ size }) => {
    if (size === 'sm') return '30vw';
    if (size === 'md') return '50vw';
    if (size === 'lg') return '80vw';
    if (size === 'xl') return '90vw';
  }};
  max-width: ${({ maxWidth }) => maxWidth};

  & ${SlidePanelBackDrop} {
    width: 150%;
    transform: ${({ open }) => (open ? 'translateX(-50%)' : 'translateX(0)')};
    transition: opacity 640ms cubic-bezier(0.65, 0.05, 0.36, 1),
      transform 540ms cubic-bezier(0.65, 0.05, 0.36, 1);
  }

  /* Accessibe fixes */
  body.acsb-vision-profile & {
    height: 100%;
  }

  body.acsb-emphasize-links.acsb-emphasize-titles &,
  body.acsb-reading-mask & {
    height: 96%;
  }
`;

export const SlidePanelHeader = styled.h5`
  --bg: var(--color-neutral-contrast-03);
  --border: var(--color-neutral-contrast-04);
  color: ${({ theme }) => theme.colors.text.body};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
  margin: 0;
  background-color: var(--bg);
  border-bottom: 1px solid var(--border);

  html[data-color-mode='dark'] & {
    --bg: var(--color-neutral-contrast-01);
    --border: var(--color-neutral-contrast-02);
  }
`;

export const SlidePanelBody = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  /* max-height: calc(100vh - 106px); */
  padding: ${({ theme }) => theme.space[4]};
`;

export const SlidePanelFooter = styled.div`
  --bg: var(--color-neutral-contrast-03);
  --border: var(--color-neutral-contrast-04);

  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
  background-color: var(--bg);
  border-top: 1px solid var(--border);

  html[data-color-mode='dark'] & {
    --bg: var(--color-neutral-contrast-01);
    --border: var(--color-neutral-contrast-02);
  }
`;

type SlidePanelCloseProps = {
  onClick?: () => void;
  open?: boolean;
};

export const SlidePanelClose = styled.button<SlidePanelCloseProps>`
  --bg: var(--color-neutral-contrast-00);
  position: absolute;
  right: 100%;
  top: 5px;
  margin-right: ${({ theme }) => theme.space[2]};
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  appearance: none;
  border-radius: 50%;
  background: var(--bg);
  color: #222;
  font-size: 16px;
  border: 0;
  box-shadow: ${({ theme }) => theme.shadows.large};
  opacity: ${({ open }) => (open ? '1' : '0')};
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(150%)')};
  transition: transform 640ms cubic-bezier(0.65, 0.05, 0.36, 1),
    opacity 500ms cubic-bezier(0.65, 0.05, 0.36, 1);

  html[data-color-mode='dark'] & {
    --bg: var(--color-neutral-contrast-05);
  }

  & .pr-icon svg {
    fill: ${({ theme }) => theme.colors.text.body};
  }
`;

export default {
  StyledSlidePanel,
  SlidePanelHeader,
  SlidePanelBody,
  SlidePanelFooter,
  SlidePanelClose,
};
