import { useQuery, QueryResult } from 'react-query';
import { getStateProvince } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchStateProvince(): Promise<APIResult> {
  const response = await fetch(getStateProvince, {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type StateResultItem = {
  Abbreviation: string;
  Country?: string;
  CountryId: number;
  Id: number;
  Name: string;
};

export type APIResult = Array<StateResultItem>;

export type ResultItem = {
  id: number;
  name: string;
};
export type TResult = Array<ResultItem>;

export type TError = { message: string };

function useStateProvince(): QueryResult<TResult, TError> {
  return useQuery(
    'get_state_province',
    async () => {
      const fetchResponse = await fetchStateProvince();
      const result = fetchResponse.map(item => ({
        id: item.Id,
        name: item.Name,
      }));
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useStateProvince };
