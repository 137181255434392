import React, { ReactElement, useCallback } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { MainContent, EmptyState } from 'ui/patterns';
import { Button } from 'ui/components';
import UnauthorizedImage from 'assets/images/unauthorized';
import { useReactOidc } from 'utils/authenticationHelper/useReactIodc';

export default function Unauthorized(): ReactElement {
  const { logout } = useReactOidc();

  const onSignOut = useCallback(() => {
    //clearing(meant for user permissions) of specific keys in localStorage to preserve certain user selections
    localStorage.removeItem('userPermissions');
    localStorage.removeItem('GlobalState');
    logout();
  }, []);
  return (
    <MainContent>
      <EmptyState>
        <Flex flexDirection={'column'} alignItems={'center'}>
          <UnauthorizedImage />
          <Text
            fontSize={6}
            textAlign={'center'}
            color="text.error"
            fontWeight={'medium'}
          >
            Unauthorized
          </Text>
          <Text
            fontSize={3}
            mt={2}
            mb={4}
            textAlign={'center'}
            color="text.muted"
          >
            You are not authorized to view the page you are requesting. <br />{' '}
            If you need to sign in with different credentials, use the sign out
            button below.
          </Text>
          <Button variant="danger" size="lg" onClick={() => onSignOut()}>
            Sign out
          </Button>
        </Flex>
      </EmptyState>
    </MainContent>
  );
}
