import React from 'react';
import { Column, TableInstance, ColumnInstance } from 'react-table';
import { Account } from 'types/accounts';
import Header from '../../../tableHeader';

function contains(array: Array<string>, string: string): boolean {
  const index = array.findIndex(item => item === string);
  return index !== -1;
}

export default function useQuestionSearchColumns(
  columnsList?: Array<string>,
): Array<Column<Account>> {
  return React.useMemo((): Array<Column<Account>> => {
    if (columnsList) {
      const columns = [];
      if (contains(columnsList, 'CID')) columns.push(getCIDHeader(Header));
      if (contains(columnsList, 'SID')) columns.push(getSIDHeader(Header));
      if (contains(columnsList, 'PID')) columns.push(getPIDHeader(Header));
      if (contains(columnsList, 'Account Name'))
        columns.push(getNameHeader(Header));
      if (contains(columnsList, 'Section Title'))
        columns.push(getSectionTitleHeader(Header));
      if (contains(columnsList, 'Question Type'))
        columns.push(getQuestionTypeHeader(Header));
      if (contains(columnsList, 'Field Name'))
        columns.push(getFieldNameHeader(Header));
      if (contains(columnsList, 'Field Label'))
        columns.push(getFieldLabelHeader(Header));
    }
    return [
      getCIDHeader(Header),
      getSIDHeader(Header),
      getPIDHeader(Header),
      getNameHeader(Header),
      getSectionTitleHeader(Header),
      getQuestionTypeHeader(Header),
      getFieldNameHeader(Header),
      getFieldLabelHeader(Header),
    ];
  }, [columnsList]);
}

function getCIDHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: 'CID',
    id: 'CID',
  };
}

function getSIDHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: 'SID',
    id: 'SID',
  };
}

function getPIDHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: 'PID',
    id: 'PID',
  };
}

function getNameHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Account Name',
    Header: Header,
    accessor: (row: Account) =>
      row.AccountName ? `${row.AccountName || ''}` : '',
  };
}

function getSectionTitleHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Section Title',
    Header: Header,
    accessor: (row: Account) =>
      row.SectionTitle ? `${row.SectionTitle || ''}` : '',
  };
}

function getQuestionTypeHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Question Type',
    Header: Header,
    accessor: (row: Account) =>
      row.QuestionType ? `${row.QuestionType || ''}` : '',
  };
}

function getFieldNameHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Field Name',
    Header: Header,
    accessor: (row: Account) => (row.FieldName ? `${row.FieldName || ''}` : ''),
  };
}

function getFieldLabelHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Field Label',
    Header: Header,
    accessor: (row: Account) =>
      row.FieldLabel ? `${row.FieldLabel || ''}` : '',
  };
}
