import styled, { css } from 'styled-components';
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
} from 'styled-system';
import { StyledFormInput } from 'ui/components';

type CardProps = LayoutProps &
  SpaceProps &
  FlexboxProps &
  ColorProps &
  PositionProps & {
    active?: boolean;
    missingCustomTable?: boolean;
    hasDependency?: boolean;
    selected?: boolean;
    procedureEditor?: boolean;
  };

const Card = styled.div<CardProps>`
  ${layout}
  ${space}
	${flexbox}
	${position}

  --bg: var(--color-neutral-contrast-00);
  --border-color: ${({ theme }) => theme.colors.border.default};

  background: var(--bg);
  border-radius: ${({ theme }) => theme.radii.soft};
  border: solid 1px var(--border-color);
  box-shadow: ${({ theme }) => theme.shadows.medium};

  html[data-color-mode='dark'] & {
    --bg: var(--color-neutral-contrast-01);
  }
  ${color}

  ${({ active }) =>
    active &&
    css`
      --border-color: ${({ theme }) => theme.colors.primary};

      ${CardHeader} {
        --bg: hsla(var(--primary-color-hsl), 10%);
        --border-color: ${({ theme }) => theme.colors.primary};
      }
    `}

  ${({ selected }) =>
    selected &&
    css`
      --border-color: ${({ theme }) => theme.colors.primary};

      ${CardHeader} {
        --bg: hsla(var(--primary-color-hsl), 10%);
        --border-color: ${({ theme }) => theme.colors.primary};
      }
    `}

  ${({ hasDependency }) =>
    hasDependency &&
    css`
      --border-color: ${({ theme }) => theme.baseColors.blue[5]};
      --bg: ${({ theme }) => theme.colors.bg.lightBlue};

      html[data-color-mode='dark'] &&& {
        --bg: ${({ theme }) => theme.baseColors.blue[9]};
      }

      ${CardHeader} {
        --bg: ${({ theme }) => theme.colors.bg.lightBlue};
        --border-color: ${({ theme }) => theme.baseColors.blue[5]};

        html[data-color-mode='dark'] &&& {
          --bg: ${({ theme }) => theme.baseColors.blue[9]};
        }
      }
    `}

	${({ missingCustomTable }) =>
    missingCustomTable &&
    css`
      --border-color: ${({ theme }) => theme.baseColors.red[3]};
      --bg: ${({ theme }) => theme.colors.bg.lightRed};

      html[data-color-mode='dark'] & {
        /* --bg: ${({ theme }) => theme.baseColors.red[9]}; */
        background: theme.baseColors.red[9];
      }

      ${CardHeader} {
        --border-color: ${({ theme }) => theme.baseColors.red[3]};
        --bg: ${({ theme }) => theme.colors.bg.lightRed};

        html[data-color-mode='dark'] & {
          /* --bg: ${({ theme }) => theme.baseColors.red[9]} !important; */
          background: theme.baseColors.red[9];
        }
      }
    `}

	${({ procedureEditor }) =>
    procedureEditor &&
    css`
      --bg: var(--color-neutral-contrast-02);
      --bg-dots: var(--color-neutral-contrast-03);
      display: flex;
      position: relative;
      flex-direction: column;
      background-color: var(--bg);
      /* background-image: radial-gradient(var(--bg-dots) 1px, var(--bg) 1px); */
      background-size: 12px 12px;
    `}
`;

type CardHeaderProps = LayoutProps &
  SpaceProps &
  FlexboxProps &
  ColorProps & {
    active?: boolean;
    selected?: boolean;
  };

const CardHeader = styled.div<CardHeaderProps>`
  ${layout}
  ${space}
	${flexbox}

  --bg: var(--color-neutral-contrast-03);

  --border-color: ${({ theme }) => theme.colors.border.default};

  background-color: var(--bg);
  border-top-left-radius: ${({ theme }) => theme.radii.soft};
  border-top-right-radius: ${({ theme }) => theme.radii.soft};
  border-bottom: solid 1px var(--border-color);

  ${color}

  html[data-color-mode='dark'] & {
    --bg: var(--color-neutral-contrast-02);
  }

  &&& ${StyledFormInput} {
    --input-bg: var(--color-neutral-contrast-00);

    html[data-color-mode='dark'] & {
      --input-bg: var(--color-neutral-contrast-02);
    }
  }
`;

const CardBody = styled.div<CardProps>`
  ${layout}
  ${space}
  ${flexbox}
	--bg: var(--color-neutral-contrast-00);
  background: var(--bg);
  html[data-color-mode='dark'] & {
    --bg: var(--color-neutral-contrast-01);
  }
  border-bottom-left-radius: ${({ theme }) => theme.radii.soft};
  border-bottom-right-radius: ${({ theme }) => theme.radii.soft};
  ${color}
`;

const CardFooter = styled.div<CardProps>`
  ${layout}
  ${space}
  ${flexbox}

  --bg: var(--color-neutral-contrast-02);

  background-color: var(--bg);
  border-bottom-left-radius: ${({ theme }) => theme.radii.soft};
  border-bottom-right-radius: ${({ theme }) => theme.radii.soft};
  border-top: solid 1px ${({ theme }) => theme.colors.border.default};
  ${color}/* html[data-color-mode='dark'] & {
    --bg: var(--color-neutral-contrast-03);
  } */
`;

export { Card, CardHeader, CardBody, CardFooter };
