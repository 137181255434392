import React, { ReactElement } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { confirmAlert } from 'utils/confirm-alert';
import { Tooltip } from 'react-tippy';
import { Draggable } from 'react-beautiful-dnd';
import { Card } from 'ui/patterns';
import { Button, Stack, Badge } from 'ui/components';
import * as Icon from 'assets/icons';
import { SectionTypeInput } from 'types/forms';
import { QuestionItemTypes } from './sectionTypes';

export default function QuestionItem({
  sectionItem,
  index,
  questionItem,
  toggleQuestionPanel,
  handleCurrentSection,
  handleCurrentQuestion,
  handleDeleteQuestion,
  handleQuestionsOrder,
  dependentQuestions,
  dependentQnToolTip,
  selectQuestionItem,
  isSelected,
  isCopyQuestion,
}: QuestionItemTypes): ReactElement {
  const showDeleteQuestionAlert = (
    questionItem: any,
    sectionItem: SectionTypeInput,
    handleDeleteQuestion?: (
      questionItem: any,
      sectionItem: SectionTypeInput,
    ) => void,
  ) => {
    confirmAlert({
      message: 'Are you sure you wish to remove this question?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            handleDeleteQuestion &&
              handleDeleteQuestion(questionItem, sectionItem);
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  const dependentToolTip =
    dependentQnToolTip &&
    dependentQnToolTip[questionItem.Id]?.map(item => item.FieldLabel).join(',');

  function moveToTop() {
    handleQuestionsOrder && handleQuestionsOrder(index, 0);
  }

  function moveUp() {
    if (index > 0)
      handleQuestionsOrder && handleQuestionsOrder(index, index - 1);
  }
  function moveDown() {
    if (index < sectionItem.Questions.length - 1)
      handleQuestionsOrder && handleQuestionsOrder(index, index + 1);
  }
  function moveToBottom() {
    handleQuestionsOrder &&
      handleQuestionsOrder(
        index,
        sectionItem?.Questions?.length ? sectionItem.Questions.length - 1 : 0,
      );
  }

  const isRequired = questionItem?.QuestionTypeOptions?.IsRequired;

  return (
    <Draggable
      draggableId={`${questionItem.Id}`}
      index={index}
      isDragDisabled={!handleQuestionsOrder}
    >
      {provided => (
        <Card
          p={3}
          my={1}
          key={questionItem.Id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          hasDependency={dependentQuestions?.includes(questionItem.Id)}
          missingCustomTable={
            isCopyQuestion === false || isCopyQuestion === undefined
              ? questionItem?.QuestionTypeOptions?.$type ===
                  'Proteus2.Api.Domain.Surveys.QuestionTypes.LookupQuestionTypeOptions, Proteus2.Api.Domain' &&
                questionItem?.QuestionTypeOptions?.CustomLookupId === null
              : false
          }
          onClick={() => {
            selectQuestionItem && selectQuestionItem({ ...questionItem });
          }}
          selected={isSelected}
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            sx={{ gap: '0.25rem' }}
          >
            <Box>
              <Text
                color="text.body"
                fontWeight="bold"
                css={{ wordBreak: 'break-all' }}
              >
                {questionItem?.FieldLabel}
              </Text>
              <Text color="text.body" fontSize={2}>
                {!isRequired
                  ? questionItem?.QuestionTemplate?.QuestionType?.Description
                  : `${
                      questionItem?.QuestionTemplate?.QuestionType?.Description
                    } ${'( required )'}`}
              </Text>
            </Box>
            <Stack direction="x" gap={3}>
              {dependentQuestions?.includes(questionItem.Id) && (
                <Button
                  variant="ghostTransparent"
                  size="sm"
                  title={`Dependent on ${dependentToolTip}`}
                >
                  <Icon.Link title={`Dependent on ${dependentToolTip}`} />
                </Button>
              )}
              {handleQuestionsOrder && (
                <>
                  {questionItem?.QuestionTemplate?.ShouldBeAutoRedacted ? (
                    <Tooltip
                      animation="fade"
                      title="Field will be blank in 'Redact Report' View mode"
                      position="top"
                      arrow={true}
                      style={{
                        alignSelf: 'center',
                      }}
                    >
                      <Badge alignSelf="center" variant="info">
                        Redacts
                      </Badge>
                    </Tooltip>
                  ) : null}

                  <Button
                    onClick={moveToTop}
                    variant="ghostTransparent"
                    size="sm"
                    title="Move question to top"
                  >
                    <Icon.ChevronUpDouble title="Move question to top" />
                  </Button>
                  <Button
                    onClick={moveUp}
                    variant="ghostTransparent"
                    size="sm"
                    title="Move question up"
                  >
                    <Icon.ArrowUp title="Move question up" />
                  </Button>
                  <Button
                    onClick={moveDown}
                    variant="ghostTransparent"
                    size="sm"
                    title="Move question down"
                  >
                    <Icon.ArrowDown title="Move question down" />
                  </Button>
                  <Button
                    onClick={moveToBottom}
                    variant="ghostTransparent"
                    size="sm"
                    title="Move question to bottom"
                  >
                    <Icon.ChevronDownDouble title="Move question to bottom" />
                  </Button>
                </>
              )}
              {handleCurrentQuestion && (
                <Button
                  variant="ghostTransparent"
                  size="sm"
                  title="Edit question"
                  onClick={() => {
                    toggleQuestionPanel && toggleQuestionPanel();
                    handleCurrentQuestion &&
                      handleCurrentQuestion(questionItem);
                    handleCurrentSection && handleCurrentSection(sectionItem);
                  }}
                >
                  <Icon.Edit title="Edit question" />
                </Button>
              )}
              {handleDeleteQuestion && (
                <Button
                  variant="ghostTransparent"
                  size="sm"
                  title="Remove question"
                  onClick={() =>
                    showDeleteQuestionAlert(
                      questionItem,
                      sectionItem,
                      handleDeleteQuestion,
                    )
                  }
                >
                  <Icon.Delete title="Remove question" />
                </Button>
              )}
            </Stack>
          </Flex>
        </Card>
      )}
    </Draggable>
  );
}
