import { useQuery, QueryResult } from 'react-query';
import { brokenLinks } from '../utils/urls';
import { ProcedureDetailsType } from '../types/procedureBuilder';
import { getTokenWithType } from './useAuth';

export async function brokenLinksForProcedures({
  accountId,
}: {
  accountId: number;
}): Promise<TResult> {
  const response = await fetch(brokenLinks(accountId), {
    method: 'PUT',
    headers: {
      Authorization: getTokenWithType(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(null),
  });
  return response.json();
}

export type TResult = Array<ProcedureDetailsType>;

export type TError = {
  message: string;
};

export type TVariables = {
  accountId: number;
};

export type TSnapshot = {
  snapshot: unknown;
};

function useBrokenLinksForProcedures(
  accountId: number,
): QueryResult<TResult, TError> {
  return useQuery(
    ['get_broken_links', accountId],
    async () => {
      const response = await brokenLinksForProcedures({
        accountId,
      });
      return response;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useBrokenLinksForProcedures };
