/* eslint-disable react/display-name */
import React from 'react';
import { Column, TableInstance, ColumnInstance } from 'react-table';
import { CustomTable } from 'types/customTables';
import Header from './tableHeader';
import CustomTableOptions from './tableCustomTableOptions';
import { getFormatDate } from 'utils/dateHelper';

type ColumnSearchType = {
  'Table Name': string;
  'Last Updated': string;
  'Last Updated By': string;
};

type ColumnNames = keyof ColumnSearchType;

function contains(array: Array<string>, string: string): boolean {
  const index = array.findIndex(item => item === string);
  return index !== -1;
}

export default function useCustomTableColumns(
  columnsList?: Array<string>,
): Array<Column<CustomTable>> {
  return React.useMemo((): Array<Column<CustomTable>> => {
    if (columnsList) {
      const columns = [];
      if (contains(columnsList, 'Table Name'))
        columns.push(getTableNameHeader(Header));
      if (contains(columnsList, 'Last Updated'))
        columns.push(getLastUpdatedHeader(Header));
      if (contains(columnsList, 'Last Updated By'))
        columns.push(getLastUpdatedByHeader(Header));

      columns.push(getOptionsHeader());
      return columns;
    }
    return [
      getTableNameHeader(Header),
      getLastUpdatedHeader(Header),
      getLastUpdatedByHeader(Header),
      getOptionsHeader(),
    ];
  }, [columnsList]);
}

function getTableNameHeader(
  Header: (
    props: TableInstance<CustomTable> & {
      column: ColumnInstance<CustomTable>;
    },
  ) => JSX.Element,
): Column<CustomTable> {
  return {
    Header: Header,
    accessor: 'TableName',
    id: 'Table Name',
  };
}

function getLastUpdatedHeader(
  Header: (
    props: TableInstance<CustomTable> & {
      column: ColumnInstance<CustomTable>;
    },
  ) => JSX.Element,
): Column<CustomTable> {
  return {
    Header: Header,
    accessor: row => getFormatDate(row.LastUpdated),
    id: 'Last Updated',
  };
}

function getLastUpdatedByHeader(
  Header: (
    props: TableInstance<CustomTable> & {
      column: ColumnInstance<CustomTable>;
    },
  ) => JSX.Element,
): Column<CustomTable> {
  return {
    id: 'Last Updated By',
    Header: Header,
    accessor: 'LastUpdatedBy',
  };
}

function getOptionsHeader(): Column<CustomTable> {
  return {
    id: 'Options',
    Header: 'Action',
    Cell: CustomTableOptions,
  };
}
