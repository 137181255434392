import { useInfiniteQuery, InfiniteQueryResult, queryCache } from 'react-query';
import { getQuestionSearchList } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import {
  QuestionSearchParams,
  SearchHeader,
  SortItem,
} from 'types/accountsSearch';
const pageLength = 100;

function getSortKey(sortKey?: string) {
  if (!sortKey) return '';
  const selected = sortKey?.toLowerCase();
  let key = '';
  if (selected === 'cid' || selected === 'pid' || selected === 'sid') {
    key = `${sortKey}`;
  } else if (selected === 'field name') {
    key = 'FieldName';
  } else if (selected === 'field label') {
    key = 'FieldLabel';
  } else if (selected === 'account name') {
    key = 'AccountName';
  } else if (selected === 'section title') {
    key = 'SectionTitle';
  } else if (selected === 'question type') {
    key = 'QuestionType';
  }
  return key;
}

async function fetchAdvancedQuestionSearches({
  requestData,
}: TVariables): Promise<TResult> {
  const response = await fetch(getQuestionSearchList, {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });
  return response.json();
}

type resultType = {
  data: Array<SearchHeader>;
  draw: number;
  isAutoRefreshEnabled: boolean;
  error: Error;
  recordsFiltered: number;
  recordsTotal: number;
};

export type TResult = {
  message: string;
  isSuccess: boolean;
  data: Array<resultType> | undefined;
  recordsFiltered: number;
  recordsTotal: number;
  draw: number;
  error: Error | null;
  pageNumber: number;
};

export type TError = { message: string };
export type TVariables = {
  requestData: any;
};

function prepareData({
  start,
  searchList,
  includeGlobal,
  includeInactive,
  includeHidden,
  columnsSearch,
  currentSortItem,
}: { start: number } & QuestionSearchParams & {
    columnsSearch?: Record<string, string>;
    currentSortItem?: SortItem;
  }) {
  return {
    PageNumber: start,
    draw: 1,
    PageSize: 100,
    columns: [
      {
        data: 'Id',
        name: 'Id',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: '',
        },
      },
      {
        data: 'CID',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['CID'] ? columnsSearch['CID'] : ''
          }`,
        },
      },
      {
        data: 'SID',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['SID'] ? columnsSearch['SID'] : ''
          }`,
        },
      },
      {
        data: 'PID',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['PID'] ? columnsSearch['PID'] : ''
          }`,
        },
      },
      {
        data: 'FieldName',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['Field Name']
              ? columnsSearch['Field Name']
              : ''
          }`,
        },
      },
      {
        data: 'FieldLabel',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['Field Label']
              ? columnsSearch['Field Label']
              : ''
          }`,
        },
      },
      {
        data: 'AccountName',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['Account Name']
              ? columnsSearch['Account Name']
              : ''
          }`,
        },
      },
      {
        data: 'SectionTitle',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['Section Title']
              ? columnsSearch['Section Title']
              : ''
          }`,
        },
      },
      {
        data: 'QuestionType',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['Question Type']
              ? columnsSearch['Question Type']
              : ''
          }`,
        },
      },
      {
        data: 'HelpText',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['Search Snippet']
              ? columnsSearch['Search Snippet']
              : ''
          }`,
        },
      },
    ],
    order: [
      {
        columnName: getSortKey(currentSortItem && currentSortItem.sortKey),
        dir: `${currentSortItem?.sortOrder}`,
      },
    ],
    search: searchList,
    IncludeGlobal: includeGlobal,
    IncludeInactive: includeInactive,
    IncludeHiddenAccount: includeHidden,
  };
}

function useGetAdvancedQuestionSearch(
  params?: QuestionSearchParams,
  columnsSearch?: Record<string, string>,
  currentSortItem?: SortItem,
): InfiniteQueryResult<TResult | undefined, TError> {
  const { searchList, includeGlobal, includeInactive, includeHidden } =
    params || {};
  const data: Array<TResult> | undefined = queryCache.getQueryData([
    'advancedQuestion_search',
    {
      searchList,
      includeGlobal,
      includeInactive,
      includeHidden,
      columnsSearch,
      currentSortItem,
    },
  ]);

  let start = data ? data.length : 1;

  if (start > 0 && data && data[0] !== undefined) {
    const totalPages = data[0]?.recordsFiltered / pageLength;

    if (totalPages >= 1) {
      start = start + 1;
    } else {
      start = 1;
    }
  }
  return useInfiniteQuery(
    [
      'advancedQuestion_search',
      {
        searchList,
        includeGlobal,
        includeInactive,
        includeHidden,
        columnsSearch,
        currentSortItem,
      },
    ],
    () => {
      if (!params) return;
      const requestData = prepareData({
        start,
        ...params,
        columnsSearch,
        currentSortItem,
      });
      return fetchAdvancedQuestionSearches({
        requestData,
      });
    },
    {
      getFetchMore: lastGroup => {
        if (lastGroup)
          return lastGroup?.data?.length && lastGroup?.recordsFiltered === 100
            ? true
            : false;
      },
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetAdvancedQuestionSearch };
