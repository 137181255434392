/* eslint-disable react/display-name */
import React from 'react';
import { Column, TableInstance, ColumnInstance } from 'react-table';
import { GlobalSurveyType } from 'types/forms';
import Header from './tableHeader';

function contains(array: Array<string>, string: string): boolean {
  const index = array.findIndex(item => item === string);
  return index !== -1;
}

export default function useGlobalSurveyColumns(
  columnsList?: Array<string>,
): Array<Column<GlobalSurveyType>> {
  return React.useMemo((): Array<Column<GlobalSurveyType>> => {
    if (columnsList) {
      const columns = [];
      if (contains(columnsList, 'Survey'))
        columns.push(getSurveyHeader(Header));
      if (contains(columnsList, 'Title')) columns.push(getTitleHeader(Header));
      if (contains(columnsList, 'Call taker can add to call'))
        columns.push(getCallTakerHeader(Header));

      return columns;
    }
    return [
      getSurveyHeader(Header),
      getTitleHeader(Header),
      getCallTakerHeader(Header),
    ];
  }, [columnsList]);
}

function getSurveyHeader(
  Header: (
    props: TableInstance<GlobalSurveyType> & {
      column: ColumnInstance<GlobalSurveyType>;
    },
  ) => JSX.Element,
): Column<GlobalSurveyType> {
  return {
    Header: Header,
    accessor: (row: GlobalSurveyType) => `${row.Survey?.Notes || ''}`,
    id: 'Survey',
  };
}

function getTitleHeader(
  Header: (
    props: TableInstance<GlobalSurveyType> & {
      column: ColumnInstance<GlobalSurveyType>;
    },
  ) => JSX.Element,
): Column<GlobalSurveyType> {
  return {
    Header: Header,
    accessor: (row: GlobalSurveyType) => `${row.Title || ''}`,
    id: 'Title',
  };
}

function getCallTakerHeader(
  Header: (
    props: TableInstance<GlobalSurveyType> & {
      column: ColumnInstance<GlobalSurveyType>;
    },
  ) => JSX.Element,
): Column<GlobalSurveyType> {
  return {
    id: 'Call taker can add to call',
    Header: Header,
    accessor: (row: GlobalSurveyType) => `${row.IsClinicianAllowedToAddToCall}`,
  };
}
