import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { deleteTemplate } from '../utils/urls';
import { getTokenWithType } from './useAuth';

export async function postDeleteTemplate({
  params,
}: TArguments): Promise<TResult> {
  const response = await fetch(deleteTemplate, {
    method: 'DELETE',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  return response.json();
}

export type TResult = string;
export type DeleteTemplateParams = {
  Title: string;
  Container: string | undefined;
  IsDeleteFromAllFolder: boolean;
};
export type TError = { message: string };
export type TArguments = {
  params: DeleteTemplateParams;
};
export type TVariables = DeleteTemplateParams;
export type TSnapshot = unknown;

function useDeleteTemplate(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: DeleteTemplateParams) => {
      const renamedResult = await postDeleteTemplate({
        params: params,
      });

      if (renamedResult) {
        if (params.Container === 'layouts') {
          queryCache.invalidateQueries(['getExistingLayouts'], {
            refetchInactive: true,
          });
        } else if (params.Container === 'tables') {
          queryCache.invalidateQueries(['getExistingTables'], {
            refetchInactive: true,
          });
        }
      }
      return renamedResult;
    },
    {
      throwOnError: true,
    },
  );
}

export { useDeleteTemplate };
