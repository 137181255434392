import { useMutation, MutationResultPair } from 'react-query';
import { getQuestionSpecifications } from 'utils/urls';
import { getTokenWithType } from './useAuth';
import { PdfDetails } from 'types/questions';
import { showErrorToast } from 'utils/showToast';

export type TResult = PdfDetails;
export type TVariables = {
  file: File;
};
export type TError = {
  Message: string;
};
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;

function useUploadPDFQuestion(
  setProgress?: (progress: number) => void,
): MutationResultPair<TResult, TError, TVariables, TSnapshot> {
  // const [progress, setProgress] = useState(0);

  function updateProgress(evt: any) {
    console.log('evt,evt', evt);
    if (evt.lengthComputable) {
      // evt.loaded the bytes the browser received
      // evt.total the total bytes set by the header
      // jQuery UI progress bar to show the progress on screen
      const percentComplete = (evt.loaded / evt.total) * 100;
      setProgress && setProgress(percentComplete);
    }
  }

  return useMutation(
    async ({ file }: TVariables) => {
      // await postAccountInfo({
      //   ...params,
      // });
      const uploadResult: PdfDetails = await new Promise((res, rej) => {
        const formData: FormData = new FormData();
        formData.append('file', file);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', getQuestionSpecifications('pdf'));
        // for (var k in opts.headers || {})
        xhr.setRequestHeader('Authorization', getTokenWithType());
        // xhr.setRequestHeader('content-type', 'multipart/form-data');
        xhr.onreadystatechange = function () {
          //   if (xhr.readyState === 4) {
          //     res();
          //   }
          if (xhr.readyState === xhr.DONE) {
            if (xhr.status === 200) {
              console.log(xhr.response);
              res(JSON.parse(xhr.response));
              //   console.log(xhr.responseText);
            } else {
              const error = JSON.parse(xhr.response);
              showErrorToast({
                message: 'Please upload a valid PDF.',
              });
              rej(error);
            }
          }
        };
        // req.send();
        xhr.onprogress = updateProgress;
        xhr.upload.addEventListener('progress', updateProgress);
        // xhr.onload = e => e.target.responseText;
        xhr.onerror = e => rej(e);
        // if (xhr.upload) xhr.upload.onprogress = setProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
        xhr.send(formData);
      });
      return uploadResult;
    },
    {
      throwOnError: true,
    },
  );
}

export { useUploadPDFQuestion };
