/* eslint-disable react/display-name */
import React from 'react';
import { Column, TableInstance, ColumnInstance } from 'react-table';
import { Questions } from 'types/questions';
import Header from './tableHeader';
import QuestionOptions from './tableQuestionOptions';

function contains(array: Array<string>, string: string): boolean {
  const index = array.findIndex(item => item === string);
  return index !== -1;
}

export default function useQuestionColumns(
  columnsList?: Array<string>,
  skipOptions?: boolean,
): Array<Column<Questions>> {
  return React.useMemo((): Array<Column<Questions>> => {
    if (columnsList) {
      const columns = [];
      if (contains(columnsList, 'Question Type'))
        columns.push(getQuestionTypeHeader(Header));
      if (contains(columnsList, 'Field Name'))
        columns.push(getFieldNameHeader(Header));
      if (contains(columnsList, 'Field Label'))
        columns.push(getFieldLabelHeader(Header));

      if (!skipOptions) columns.push(getOptionsHeader());

      return columns;
    }
    return [
      getQuestionTypeHeader(Header),
      getFieldNameHeader(Header),
      getFieldLabelHeader(Header),
      getOptionsHeader(),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsList]);
}

function getQuestionTypeHeader(
  Header: (
    props: TableInstance<Questions> & {
      column: ColumnInstance<Questions>;
    },
  ) => JSX.Element,
): Column<Questions> {
  return {
    Header: Header,
    accessor: (row: Questions) => `${row.QuestionType?.Description || ''}`,
    id: 'Question Type',
  };
}

function getFieldNameHeader(
  Header: (
    props: TableInstance<Questions> & {
      column: ColumnInstance<Questions>;
    },
  ) => JSX.Element,
): Column<Questions> {
  return {
    Header: Header,
    accessor: 'FieldName',
    id: 'Field Name',
  };
}

function getFieldLabelHeader(
  Header: (
    props: TableInstance<Questions> & {
      column: ColumnInstance<Questions>;
    },
  ) => JSX.Element,
): Column<Questions> {
  return {
    id: 'Original Field Label',
    Header: Header,
    accessor: 'FieldLabel',
  };
}

function getOptionsHeader(): Column<Questions> {
  return {
    id: 'Options',
    Header: 'Action',
    Cell: QuestionOptions,
  };
}
