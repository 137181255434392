import { useInfiniteQuery, InfiniteQueryResult, queryCache } from 'react-query';
import { Questions } from 'types/questions';
import { getQuestionsList } from '../utils/urls';
import { getTokenWithType } from './useAuth';
const pageLength = 100;

function getColumnFromId(id: string) {
  const idsList = ['Question Type', 'Field Name', 'Field Label'];
  const index = idsList.findIndex(item => item === id);
  return `${index}`;
}

async function fetchQuestionsList({
  requestData,
}: TVariables): Promise<TResult> {
  const response = await fetch(getQuestionsList, {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

type resultType = {
  data: Array<Questions>;
  draw: number;
  isAutoRefreshEnabled: boolean;
  error: Error;
  recordsFiltered: number;
  recordsTotal: number;
};

export type TResult = {
  message: string;
  isSuccess: boolean;
  data: Array<Questions>;
  recordsFiltered: number;
  recordsTotal: number;
  draw: number;
  error: Error | null;
};

export type TError = { message: string };
export type TVariables = {
  requestData: any;
};

function formatRequestData(
  page: number,
  columnsSearch: Record<string, string>,
  sortedColumn: string,
  sortOrder: string,
  keywordSearch: string,
  fromQuestionGrid?: boolean,
): FormData {
  const formData = new URLSearchParams();
  if (fromQuestionGrid) {
    formData.append('draw', '1');

    formData.append('columns[0][data]', 'QuestionType.Description');
    formData.append('columns[0][name]', '');
    formData.append('columns[0][searchable]', 'true');
    formData.append('columns[0][orderable]', 'true');
    formData.append(
      'columns[0][search][value]',
      columnsSearch && columnsSearch['Question Type']
        ? columnsSearch['Question Type']
        : '',
    );
    formData.append('columns[0][search][regex]', 'false');
    formData.append('columns[1][data]', 'FieldName');
    formData.append('columns[1][name]', '');
    formData.append('columns[1][searchable]', 'true');
    formData.append('columns[1][orderable]', 'true');
    formData.append(
      'columns[1][search][value]',
      columnsSearch && columnsSearch['Field Name']
        ? columnsSearch['Field Name']
        : '',
    );
    formData.append('columns[1][search][regex]', 'false');
    formData.append('columns[2][data]', 'FieldLabel');
    formData.append('columns[2][name]', '');
    formData.append('columns[2][searchable]', 'true');
    formData.append('columns[2][orderable]', 'true');
    formData.append(
      'columns[2][search][value]',
      columnsSearch && columnsSearch['Field Label']
        ? columnsSearch['Field Label']
        : '',
    );
    formData.append('columns[2][search][regex]', 'false');
    formData.append('order[0][column]', sortedColumn);
    formData.append('order[0][dir]', sortOrder);
    formData.append('search[value]', `${keywordSearch}`);
    formData.append('search[regex]', 'false');
    formData.append('start', `${page * 100}`);
    formData.append('length', `${100}`);
    formData.append('timezoneOffset', '-330');
    return formData;
  } else {
    formData.append('draw', '1');
    formData.append('columns[0][data]', 'IsDisabled');
    formData.append('columns[0][name]', '');
    formData.append('columns[0][searchable]', 'true');
    formData.append('columns[0][orderable]', 'true');
    formData.append('columns[0][search][value]', 'false');
    formData.append('columns[0][search][regex]', 'false');
    formData.append('columns[1][data]', 'QuestionType.Description');
    formData.append('columns[1][name]', '');
    formData.append('columns[1][searchable]', 'true');
    formData.append('columns[1][orderable]', 'true');
    formData.append(
      'columns[1][search][value]',
      columnsSearch && columnsSearch['Question Type']
        ? columnsSearch['Question Type']
        : '',
    );
    formData.append('columns[1][search][regex]', 'false');
    formData.append('columns[2][data]', 'FieldName');
    formData.append('columns[2][name]', '');
    formData.append('columns[2][searchable]', 'true');
    formData.append('columns[2][orderable]', 'true');
    formData.append(
      'columns[2][search][value]',
      columnsSearch && columnsSearch['Field Name']
        ? columnsSearch['Field Name']
        : '',
    );
    formData.append('columns[2][search][regex]', 'false');
    formData.append('columns[3][data]', 'FieldLabel');
    formData.append('columns[3][name]', '');
    formData.append('columns[3][searchable]', 'true');
    formData.append('columns[3][orderable]', 'true');
    formData.append(
      'columns[3][search][value]',
      columnsSearch && columnsSearch['Field Label']
        ? columnsSearch['Field Label']
        : '',
    );
    formData.append('columns[3][search][regex]', 'false');
    formData.append('order[0][column]', sortedColumn);
    formData.append('order[0][dir]', sortOrder);
    formData.append('search[value]', `${keywordSearch}`);
    formData.append('search[regex]', 'false');
    formData.append('start', `${page * 100}`);
    formData.append('length', `${100}`);
    formData.append('timezoneOffset', '-330');
    return formData;
  }
}

function useQuestions({
  columnsSearch,
  sortItem,
  keywordSearch,
  fromQuestionGrid,
}: {
  columnsSearch: Record<string, string>;
  sortItem: { sortKey: string; sortOrder: string };
  keywordSearch: string;
  fromQuestionGrid?: boolean;
}): InfiniteQueryResult<TResult, TError> {
  const data: Array<TResult> | undefined = queryCache.getQueryData([
    'questions_list',
    keywordSearch,
    columnsSearch,
    sortItem,
  ]);
  // let nextPage = data ? data.length : 0;
  // if (nextPage > 0 && data) {
  //   const totalPages = data[0].recordsFiltered / pageLength;
  //   nextPage = nextPage > totalPages ? totalPages : nextPage;
  // }

  let start = data ? data.length : 0;
  if (start > 0 && data) {
    const totalPages = data[0].recordsFiltered / pageLength;
    if (totalPages > 1) {
      start = start > totalPages ? totalPages : start;
    } else {
      start = 0;
    }
  }
  const requestData = formatRequestData(
    start,
    columnsSearch,
    getColumnFromId(sortItem.sortKey),
    sortItem.sortOrder,
    keywordSearch,
    fromQuestionGrid,
  );

  return useInfiniteQuery(
    ['questions_list', keywordSearch, columnsSearch, sortItem],
    () => fetchQuestionsList({ requestData }),
    {
      getFetchMore: (lastGroup, allGroups) => {
        return allGroups.length < lastGroup.recordsFiltered / pageLength
          ? true
          : null;
      },
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useQuestions };
