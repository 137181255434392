import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useReducer,
  ReactElement,
} from 'react';
import useProcedureSearchColumns from './useProcedureSearchColumns';
import {
  useGetAdvancedProcedureSearch,
  useSaveAdvanceProcedureSearch,
} from 'hooks';
import {
  ProcedureSearchParams,
  SearchRuleItem,
  ProcedureOptionsType,
} from 'types/accountsSearch';
import { showErrorToast } from 'utils/showToast';
import useAdvancedSearchStore from 'store/useAdvancedSearchStore';
import { HighlightTextWrapper } from 'ui/patterns';

const procedureColumnData = [
  'CID',
  'PID',
  'SID',
  'Name',
  'RelationshipManager',
  'Procedure',
  'SearchSnippet',
];

const searchItem: SearchRuleItem = {
  value: '',
  expression: 'Contains',
  operator: 'AND',
  regex: false,
  orderable: true,
};

const sortItem = { sortKey: '', sortOrder: '' };

const procedureInitialSate: ProcedureOptionsType = {
  procedureText: true,
  isGlobal: false,
  procedureTitle: false,
  isInvisible: false,
  includeHidden: false,
};

function reducer(
  state: ProcedureOptionsType,
  action: {
    type: keyof ProcedureOptionsType | 'reset';
    newState?: ProcedureOptionsType;
  },
) {
  switch (action.type) {
    case 'procedureText': {
      return {
        ...state,
        procedureText: !state.procedureText,
      };
    }
    case 'procedureTitle': {
      return {
        ...state,
        procedureTitle: !state.procedureTitle,
      };
    }
    case 'isGlobal':
      return { ...state, isGlobal: !state.isGlobal };
    case 'isInvisible':
      return { ...state, isInvisible: !state.isInvisible };
    case 'includeHidden':
      return { ...state, includeHidden: !state.includeHidden };
    case 'reset':
      if (action.newState) return { ...action.newState };
      return { ...procedureInitialSate };
    default:
      throw new Error();
  }
}

const initialSearchList: Array<SearchRuleItem> = [{ ...searchItem }];

export default function useProcedureSearch() {
  // search block states
  const [searchList, setSearchList] = useState<Array<SearchRuleItem>>([
    ...initialSearchList.map(item => item),
  ]);

  //checkbox states
  const [procedureOptions, setProcedureOptions] = useReducer(reducer, {
    ...procedureInitialSate,
  });
  const [searchParams, setSearchParams] = useState<ProcedureSearchParams>();
  const [columnsSearch, setColumnsSearch] = useState({}); // header search
  const [currentSortItem, setCurrentSortItem] = useState(sortItem);

  const {
    data,
    fetchMore,
    canFetchMore,
    isFetching,
    isLoading: advancedSearchloading,
    isFetchingMore,
  } = useGetAdvancedProcedureSearch(
    searchParams,
    columnsSearch,
    currentSortItem,
  );

  const pageNumber = data && data[data?.length - 1]?.pageNumber;

  const [saveInterAccountSearchList] = useSaveAdvanceProcedureSearch();

  const handleDownloadSearchResult = () => {
    saveInterAccountSearchList({
      searchParams,
      columnsSearch,
      currentSortItem,
      pageNumber,
    });
  };

  const tableData = useMemo(() => {
    if (data) {
      return data
        .map(dataItem => dataItem?.data)
        .flat()
        .filter(item => !!item);
    }
    return;
  }, [data]);

  const onEndReached = () => {
    if (canFetchMore) fetchMore();
  };

  const columnList = useProcedureSearchColumns([
    ...procedureColumnData.slice(),
  ]);

  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch],
  );

  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (
        currentSortItem.sortKey === item &&
        currentSortItem.sortOrder === 'asc'
      ) {
        sortOrder = 'desc';
      }
      setCurrentSortItem({
        sortKey: item,
        sortOrder,
      });
    },
    [setCurrentSortItem, currentSortItem],
  );

  const resetProcedureSearch = () => {
    setSearchList([{ ...searchItem }]);
    setColumnsSearch({});
    setCurrentSortItem(sortItem);
    setProcedureOptions({ type: 'reset' });
    setSearchParams(undefined);
  };

  const toggleProcedureOption = (key?: keyof ProcedureOptionsType) => () => {
    if (!key) return setProcedureOptions({ type: 'reset' });
    return setProcedureOptions({ type: key });
  };

  function searchButtonClick() {
    if (searchList.filter(item => item.value.trim().length === 0).length > 0) {
      showErrorToast({
        message: 'Please Enter a keyword',
      });
    } else {
      setSearchParams({
        searchList: JSON.parse(JSON.stringify(searchList.slice())),
        ...procedureOptions,
      });
    }
  }

  const { searchStore, setParams } = useAdvancedSearchStore();

  const currentRecords = useMemo(() => {
    if (data) {
      return data.map(dataItem => dataItem?.data).flat();
    }
  }, [data]);

  const totalRecords = useMemo(() => {
    if (data) {
      return data[0] && data[0].recordsTotal;
    }
    return 0;
  }, [data]);

  useEffect(() => {
    if (searchStore) {
      if (searchStore.procedureSearchParams) {
        const {
          isGlobal,
          procedureText,
          procedureTitle,
          isInvisible,
          includeHidden,
          searchList,
        } = searchStore.procedureSearchParams;
        setProcedureOptions({
          type: 'reset',
          newState: {
            isGlobal,
            procedureText,
            procedureTitle,
            isInvisible,
            includeHidden,
          },
        });
        setSearchList(searchList);
      }
      if (searchStore.sortItem) {
        setCurrentSortItem(searchStore.sortItem);
      }
      if (searchStore.columnSearch) {
        setColumnsSearch(searchStore.columnSearch);
      }
      setSearchParams(searchStore.procedureSearchParams);
    }
  }, [searchStore]);

  function onClickToolTip(
    path: string,
    params: Record<string, number | string>,
  ) {
    setParams({
      procedureSearchParams: searchParams,
      columnSearch: columnsSearch,
      sortItem: currentSortItem,
    });
    localStorage.setItem('fromSearch', JSON.stringify(params?.Pid));
    window.open(path);
  }

  function getHighlightedText(content: string): ReactElement {
    let newString = [content];
    searchList?.forEach(searchItem => {
      const arr: string[] = [];
      newString?.forEach(x => {
        if (x !== undefined)
          arr?.push(...x?.split(new RegExp(`(${searchItem.value})`, 'gi')));
      });
      newString = [...arr];
    });

    return (
      <>
        <HighlightTextWrapper content={content} searchedWords={searchList} />
      </>
    );
  }

  return {
    totalProcedureRecordsCount: totalRecords,
    listedProcedureRecordCount: currentRecords,
    isProcedureSearchFetching: isFetching,
    procedureOptions,
    toggleProcedureOption,
    resetProcedureSearch,
    handleDownloadSearchResult,
    procedureTableProps: {
      columns: columnList,
      columnsSearch: columnsSearch,
      data: tableData,
      headerUpdateCallBack: headerUpdateCallBack,
      sortedItem: currentSortItem,
      handleSortChange: handleSortChange,
      isLoading: advancedSearchloading,
      noOptions: true,
      onEndReached: onEndReached,
      isFetching: !!isFetchingMore,
      onRedirect: onClickToolTip,
      getHighlightedText,
    },
    search: {
      searchList,
      setSearchList,
      searchButtonClick,
    },
  };
}
