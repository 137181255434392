import React, {
  ReactElement,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { confirmAlert } from 'utils/confirm-alert';
import { FormInput, FormSelect, FormGroup, Card, Loader } from 'ui/patterns';
import {
  Grid,
  Stack,
  Checkbox,
  RadioButton,
  Button,
  FormLabel,
  TinyMCE,
  Dropzone,
  StyledSelect,
} from 'ui/components';
import { useForm, Controller } from 'react-hook-form';
import {
  useAccountServices,
  useAccountStatus,
  useCreateFolder,
  useDeleteFile,
  useGetAccountInfo,
  useGetProcedures,
  useRelationshipManagers,
  useSaveAccountInfo,
  useStateProvince,
  useTimezones,
  useUploadExternalDocument,
  useUploadFile,
} from 'hooks';
import { AccountFormInputs } from 'types/accounts';
import { showErrorToast, showSuccessToast } from 'utils/showToast';
import FileImage from 'containers/ProcedureBuilder/FileImage';
import { apiBaseUrl } from 'utils/urls';
import { BlobFileEntity } from 'types/fileTypes';
import usePrompt from 'hooks/usePrompt';
import Icons from 'assets/icons';
const empty = {
  accountInfoReturn: undefined,
  accountFormInputs: undefined,
};

const formDefaults = {
  AnswerScript: '',
  AnswerScriptDetailType: 1,
  AnswerScriptDetails: '',
  AutoPurgeDeadlineInHours: 0,
  CID: '',
  City: '',
  Id: 0,
  IsAutoPurgeEnabled: false,
  IsLive: false,
  IsPriorityFinalization: false,
  Name: '',
  PID: '',
  SID: '',
  ShortAnswerScript: '',
  VDN: '',
  ExternalFile: '',
};

function useHelperHook(id: number) {
  const { data: stateProvinces, isLoading: l1 } = useStateProvince();
  const { data: relationshipManagers, isLoading: l2 } =
    useRelationshipManagers();
  const { data: timezones, isLoading: l3 } = useTimezones();
  const { data: accountStatuses, isLoading: l4 } = useAccountStatus();
  const { data: accountServices, isLoading: l5 } = useAccountServices();
  const { data: accountInfo, isLoading: l6 } = useGetAccountInfo(id);
  const { data: procedures, isLoading: l7 } = useGetProcedures(id);
  return {
    accountInfo,
    procedures,
    stateProvinces,
    relationshipManagers,
    timezones,
    accountStatuses,
    accountServices,
    isLoading: l1 || l2 || l3 || l4 || l5 || l6 || l7,
  };
}

// type AccountInfoType = {
//   setDirty: (value: boolean) => void;
// };

export default function AccountInformation(): ReactElement {
  const [isPageDirty, setDirty] = useState<boolean>();
  const [isTinyMCELoading, setTinyMCELoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [saveAccountInfo, { isLoading: isSaveAccountInfoLoading }] =
    useSaveAccountInfo();
  const [uploadExtarnalDocument, { isLoading: isDocumentUploadLoading }] =
    useUploadExternalDocument(setProgress);
  const history = useHistory();
  const { accountId } = useParams<{
    accountId: string;
    tabId?: string;
  }>();
  const {
    accountInfo,
    stateProvinces,
    relationshipManagers,
    timezones,
    accountStatuses,
    accountServices,
    procedures,
    isLoading,
  } = useHelperHook(parseInt(accountId));

  const isLoadingInfo = isSaveAccountInfoLoading || isLoading;

  const [externalFile, setExternalFile] = useState<string | undefined>();
  const [purgePermission, setPurgePermission] = useState(false);

  const { accountInfoReturn, accountFormInputs } = accountInfo || empty;
  const onSubmit = (data: AccountFormInputs) => {
    const checkifRMexists = relationshipManagers?.find(
      (manager: any) => manager.id === data.RelationshipManagerId,
    );
    if (checkifRMexists === undefined) {
      data.RelationshipManagerId = null;
    }
    const deadLine = AutoPurgeDeadlineInHours && +AutoPurgeDeadlineInHours;
    const initialDeadLine =
      accountInfoReturn && accountInfoReturn.AutoPurgeDeadlineInHours;

    if (isAutoPurgeEnabled && initialDeadLine !== deadLine && deadLine !== 72) {
      confirmAlert({
        message:
          'Warning! Changing Auto-Purge settings will affect what calls get DELETED or RETAINED. Are you sure that you wish to change these settings?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              setDirty(false);
              handleSave(data);
            },
            className: 'pr-btn-primary',
          },
          {
            label: 'No',
            onClick: () => {
              //
            },
          },
        ],
      });
    } else {
      setDirty(false);
      handleSave(data);
    }
  };

  useEffect(() => {
    const permissions = localStorage.getItem('userPermissions');
    if (permissions) {
      const temp = JSON.parse(permissions);
      setPurgePermission(temp.canPurgeAccount);
    }
  }, []);

  const saveFormApi = (data: AccountFormInputs, infoMessage: boolean) => {
    saveAccountInfo(
      {
        ...data,
        Id: accountFormInputs?.Id ? accountFormInputs.Id : 0,
        ExternalFile: externalFile,
        AnswerScriptDetailType:
          data.AnswerScriptDetailType === 0 && !!data.AnswerScriptDetails
            ? 1
            : data.AnswerScriptDetailType,
      },
      {
        onSuccess: (mutationResult: any, data: any) => {
          if (!data.Id && mutationResult.Id) {
            reset(); // clear the form
            history.replace(
              `/home/accounts/edit/${mutationResult.Id}/accountInfo`,
              { ID: mutationResult.Id },
            );
          } else if (data?.Id && mutationResult.Id) {
            showSuccessToast({
              message: 'Your changes were successfully saved.',
              autoClose: 3000,
            });
            if (infoMessage === true)
              confirmAlert({
                message:
                  'Please be aware that updates to procedure links will happen in the background and may take up to five minutes and all procedures for this account will be re-published through this process.',
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => {
                      history.push(
                        `/home/accounts/edit/${mutationResult.Id}/accountInfo`,
                        { ID: mutationResult.Id },
                      );
                    },
                    className: 'pr-btn-primary',
                  },
                ],
              });
            setTimeout(() => {
              setDirty(false);
            }, 500);
          } else if (mutationResult.Message) {
            showErrorToast({
              message: mutationResult.Message,
            });
          } else {
            showErrorToast({
              message:
                'An error occurred trying to save your changes, please try again.',
              // autoClose: 10000,
            });
          }
        },
        onError: (e: any) => {
          showErrorToast({
            message: `${'"An error occured while attempting to save your modifications.Please try again.'} ${e}`,
            // autoClose: 10000,
          });
        },
      },
    );
  };

  const handleSave = async (data: AccountFormInputs) => {
    // setTinyMCELoading(true);
    let infoMessage = false;
    if (
      data.CID !== accountInfo?.accountFormInputs.CID ||
      data.SID !== accountInfo?.accountFormInputs.SID ||
      data.PID !== accountInfo?.accountFormInputs.PID
    ) {
      infoMessage = true;
    }
    if (
      data?.AnswerScriptDetailType === 2 &&
      data?.AnswerScriptDetails === ''
    ) {
      showErrorToast({
        message: 'Please select a procedure in answer script details.',
      });
    } else if (
      data?.AnswerScriptDetailType === 3 &&
      (data?.AnswerScriptDetails === '' ||
        data?.AnswerScriptDetails === undefined)
    ) {
      showErrorToast({
        message: 'Please select an external document in answer script details.',
      });
    } else {
      if (infoMessage === true) {
        confirmAlert({
          message:
            "You are about to change an account's CID, SID, or PID. Are you sure you want to proceed?",
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                saveFormApi(data, infoMessage);
              },
              className: 'pr-btn-primary',
            },
            {
              label: 'No',
              onClick: () => {
                //
              },
            },
          ],
        });
      } else {
        await saveFormApi(data, infoMessage);
      }
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    errors,
    setValue,
    formState: { isDirty },
  } = useForm<AccountFormInputs>({
    defaultValues: {
      ...formDefaults,
    },
  });

  useEffect(() => {
    if (accountFormInputs && !isLoadingInfo) {
      reset({ ...accountFormInputs });
      setTimeout(() => {
        setDirty(false);
      }, 500);
    }
  }, [accountFormInputs, reset, isLoadingInfo, setDirty]);

  const answerScriptDetailType = watch('AnswerScriptDetailType');
  const isAutoPurgeEnabled = watch('IsAutoPurgeEnabled');
  const AutoPurgeDeadlineInHours = watch('AutoPurgeDeadlineInHours');

  useEffect(() => {
    if (!isTinyMCELoading) setDirty(isDirty);
  }, [setDirty, isDirty, isTinyMCELoading]);

  useEffect(() => {
    if (
      accountInfoReturn &&
      accountInfoReturn.AnswerScriptDetailType === answerScriptDetailType
    )
      setValue('AnswerScriptDetails', accountInfoReturn.AnswerScriptDetails, {
        shouldDirty: false,
      });
    else
      setValue('AnswerScriptDetails', '', {
        shouldDirty: false,
      });
  }, [answerScriptDetailType, accountInfoReturn, setValue]);

  useEffect(() => {
    if (accountInfoReturn?.IsAutoPurgeEnabled && isAutoPurgeEnabled)
      setValue(
        'AutoPurgeDeadlineInHours',
        accountInfoReturn?.AutoPurgeDeadlineInHours,
        {
          shouldDirty: false,
        },
      );
    else
      setValue('AutoPurgeDeadlineInHours', 72, {
        shouldDirty: false,
      });
  }, [accountInfoReturn, isAutoPurgeEnabled, setValue]);

  const [showFileImage, setShowFileImage] = useState(false);
  function toggleFileImage() {
    setShowFileImage(showFileImage => !showFileImage);
  }
  const [createFolder, { isLoading: isCreateFolderLoading }] =
    useCreateFolder();
  const [uploadFile, { isLoading: isuploadFileLoading }] = useUploadFile();
  const [deleteFile, { isLoading: isDeleteFileLoading }] = useDeleteFile();
  const isLoadingFile =
    isCreateFolderLoading || isuploadFileLoading || isDeleteFileLoading;
  // const [ExternalFile, setExternalFile] = useState<string | undefined>();
  usePrompt(
    isPageDirty,
    'Are you sure you want to leave? Changes that you made may not be saved.',
  );

  return (
    <>
      <Flex
        as="form"
        flexDirection="column"
        height="100%"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          style={{
            flex: '1 1 auto',
            flexGrow: 1,
            overflow: 'auto',
            height: '1px',
          }}
        >
          <Card p={4}>
            <Stack direction="y" gap={8}>
              <Grid minColWidth="350px" gap={4}>
                <FormInput
                  feedback={errors.VDN?.message}
                  validation={errors.VDN ? 'error' : undefined}
                  register={register}
                  type="text"
                  label="Start Code"
                  name="VDN"
                  length={4}
                  maxLength={4}
                  validate={{
                    validate: (value: any) => {
                      if (value.indexOf(' ') >= 0)
                        return 'Spaces are not allowed.';
                    },
                  }}
                />
                <FormInput
                  feedback={errors.CID?.message}
                  validation={errors.CID ? 'error' : undefined}
                  register={register}
                  label="CID"
                  type="text"
                  required
                  maxLength={4}
                />
                <FormInput
                  feedback={errors.SID?.message}
                  validation={errors.SID ? 'error' : undefined}
                  register={register}
                  label="SID"
                  type="text"
                  required
                  maxLength={4}
                />
                <FormInput
                  feedback={errors.PID?.message}
                  validation={errors.PID ? 'error' : undefined}
                  register={register}
                  label="PID"
                  type="text"
                  required
                  maxLength={4}
                />
                <Controller
                  render={(props: {
                    onChange: (value: number) => void;
                    value: number;
                  }) => {
                    return (
                      <FormSelect
                        value={props.value}
                        label="Relationship Manager"
                        options={relationshipManagers}
                        onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                          props.onChange(parseInt(e.currentTarget.value, 10));
                        }}
                      />
                    );
                  }}
                  defaultValue={''}
                  name="RelationshipManagerId"
                  control={control}
                />

                <Controller
                  render={(props: {
                    onChange: (value: number) => void;
                    value: number;
                  }) => {
                    return (
                      <FormSelect
                        value={props.value}
                        feedback={errors.StatusId?.message}
                        validation={
                          errors.StatusId?.message ? 'error' : undefined
                        }
                        label="Status"
                        options={accountStatuses}
                        onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                          props.onChange(parseInt(e.currentTarget.value, 10));
                        }}
                        required
                      />
                    );
                  }}
                  rules={{
                    required: 'The Status field is required.',
                    validate: {
                      invalid: value => {
                        if (Number.isNaN(value))
                          return 'The Status field is required.';
                      },
                    },
                  }}
                  defaultValue={''}
                  name="StatusId"
                  control={control}
                />

                <Controller
                  render={(props: {
                    onChange: (value: number) => void;
                    value: number;
                  }) => {
                    return (
                      <FormSelect
                        value={props.value}
                        feedback={errors.AccountServiceId?.message}
                        validation={
                          errors.AccountServiceId?.message ? 'error' : undefined
                        }
                        label="Service Type"
                        options={accountServices}
                        onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                          const value = parseInt(e.currentTarget.value, 10);
                          props.onChange(value);
                        }}
                        required
                      />
                    );
                  }}
                  rules={{
                    required: 'The Service Type field is required.',
                    validate: {
                      invalid: value => {
                        if (Number.isNaN(value))
                          return 'The Service Type field is required.';
                      },
                    },
                  }}
                  defaultValue={''}
                  name="AccountServiceId"
                  control={control}
                />

                <Controller
                  render={(props: {
                    onChange: (value: number) => void;
                    value: number;
                  }) => (
                    <FormSelect
                      value={props.value}
                      label="State/Province"
                      options={stateProvinces}
                      onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                        props.onChange(parseInt(e.currentTarget.value, 10));
                      }}
                    />
                  )}
                  defaultValue={''}
                  name="StateProvinceId"
                  control={control}
                />
                <FormInput register={register} label="City" type="text" />
                <Controller
                  render={(props: {
                    onChange: (value: number) => void;
                    value: number;
                  }) => {
                    return (
                      <FormSelect
                        value={props.value}
                        label="Time Zone"
                        options={timezones}
                        onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                          props.onChange(parseInt(e.currentTarget.value, 10));
                        }}
                      />
                    );
                  }}
                  defaultValue={''}
                  name="TimeZoneId"
                  control={control}
                />

                <FormInput
                  name="Name"
                  register={register}
                  label="Account Name"
                  type="text"
                />
                <FormInput
                  name="AnswerScript"
                  register={register}
                  label="Answer Script"
                  type="text"
                />
                <FormInput
                  name="ShortAnswerScript"
                  register={register}
                  label="Short Answer Script"
                  type="text"
                />
                <FormGroup>
                  <Flex height="100%" pt={7} alignItems="flex-start">
                    <Checkbox
                      name="IsPriorityFinalization"
                      register={register}
                      label="Priority Finalization"
                    />
                  </Flex>
                </FormGroup>
                <FormGroup>
                  <Flex height="100%" pt={7} alignItems="flex-start">
                    <Checkbox
                      name="IsLive"
                      register={register}
                      label="Is Live?"
                    />
                  </Flex>
                </FormGroup>
                <FormGroup>
                  <Flex height="100%" pt={7} flexDirection="column">
                    <Controller
                      render={(props: {
                        onChange: (value: boolean) => void;
                        value: boolean;
                      }) => {
                        return (
                          <Checkbox
                            name="IsAutoPurgeEnabled"
                            // register={register}
                            disabled={!purgePermission}
                            label="Auto-Purge Enabled?"
                            checked={isAutoPurgeEnabled}
                            handleChange={() => {
                              confirmAlert({
                                message:
                                  'Warning! Changing Auto-Purge settings will affect what calls get DELETED or RETAINED. Are you sure that you wish to change these settings?',
                                buttons: [
                                  {
                                    label: 'Yes',
                                    onClick: () => {
                                      props.onChange(!isAutoPurgeEnabled);
                                    },
                                    className: 'pr-btn-primary',
                                  },
                                  {
                                    label: 'No',
                                    onClick: () => {
                                      //
                                    },
                                  },
                                ],
                              });
                            }}
                          />
                        );
                      }}
                      defaultValue={false}
                      name="IsAutoPurgeEnabled"
                      control={control}
                    />

                    {isAutoPurgeEnabled === true && (
                      <FormInput
                        name="AutoPurgeDeadlineInHours"
                        register={register}
                        label="Auto-Purge Deadline in Hours"
                        type="text"
                      />
                    )}
                  </Flex>
                </FormGroup>
              </Grid>

              <Box>
                <FormLabel htmlFor="scriptDetails">
                  Answer Script Details
                </FormLabel>
                <Box my={2}>
                  <Stack direction="x" gap={3}>
                    <Controller
                      render={(props: {
                        onChange: (value: number) => void;
                        value: number;
                      }) => {
                        return (
                          <RadioButton
                            onChange={() => {
                              props.onChange(1);
                            }}
                            inline
                            label="Text"
                            name="AnswerScriptDetailType"
                            checked={
                              answerScriptDetailType === 1 ||
                              answerScriptDetailType === 0
                            }
                          />
                        );
                      }}
                      defaultValue={1}
                      name="AnswerScriptDetailType"
                      control={control}
                    />

                    {accountId && (
                      <Controller
                        render={(props: {
                          onChange: (value: number) => void;
                          value: number;
                        }) => {
                          return (
                            <RadioButton
                              onChange={() => {
                                props.onChange(2);
                              }}
                              inline
                              label="Procedure"
                              name="AnswerScriptDetailType"
                              checked={answerScriptDetailType === 2}
                            />
                          );
                        }}
                        defaultValue={1}
                        name="AnswerScriptDetailType"
                        control={control}
                      />
                    )}

                    <Controller
                      render={(props: {
                        onChange: (value: number) => void;
                        value: number;
                      }) => {
                        return (
                          <RadioButton
                            onChange={() => {
                              props.onChange(3);
                            }}
                            inline
                            label="External Document"
                            name="AnswerScriptDetailType"
                            checked={answerScriptDetailType === 3}
                          />
                        );
                      }}
                      defaultValue={1}
                      name="AnswerScriptDetailType"
                      control={control}
                    />
                  </Stack>
                </Box>
                {(answerScriptDetailType === 1 ||
                  answerScriptDetailType === 0) && (
                  <Controller
                    render={(props: {
                      onChange: (value: any) => void;
                      value: any;
                    }) => {
                      return (
                        <TinyMCE
                          toggleFileImage={toggleFileImage}
                          setLoading={setTinyMCELoading}
                          height={240}
                          initialValue={accountFormInputs?.AnswerScriptDetails}
                          value={props.value}
                          name="AnswerScriptDetails"
                          onChange={(content: string) => {
                            props.onChange(content);
                          }}
                        />
                      );
                    }}
                    defaultValue={''}
                    name="AnswerScriptDetails"
                    control={control}
                  />
                )}
                {answerScriptDetailType === 2 && (
                  <Controller
                    render={(props: {
                      onChange: (value: string) => void;
                      value: any;
                    }) => {
                      return (
                        <StyledSelect
                          onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                            props.onChange(e.currentTarget.value);
                          }}
                          value={props.value}
                          style={{ maxWidth: '258px' }}
                        >
                          <option value="" selected>
                            Select one
                          </option>
                          {procedures &&
                            procedures.map((item: any) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                        </StyledSelect>
                      );
                    }}
                    defaultValue={''}
                    name="AnswerScriptDetails"
                    control={control}
                  />
                )}
                {answerScriptDetailType === 3 && (
                  <Controller
                    render={(props: {
                      onChange: (value?: string) => void;
                      value: string;
                    }) => {
                      return (
                        <Dropzone
                          // accept={'*/*'}
                          info="Please ensure this file type is associated with a program in the Citrix environment"
                          progress={progress}
                          value={props.value}
                          isLoading={isDocumentUploadLoading}
                          accountId={accountInfoReturn && accountInfoReturn.Id}
                          onChange={(file?: File) => {
                            if (!file) props.onChange();
                            if (file && accountFormInputs) {
                              const fileExtension = file?.name.split('.');
                              const validExtensions = [
                                'jpg',
                                'jpeg',
                                'png',
                                'svg',
                                'txt',
                                'doc',
                                'docx',
                                'pdf',
                                'html',
                                'htm',
                              ];
                              const checkCondtion = validExtensions.includes(
                                fileExtension[1],
                              );
                              if (checkCondtion) {
                                uploadExtarnalDocument(
                                  {
                                    account: `${accountFormInputs.CID}${accountFormInputs.SID}${accountFormInputs.PID}`,
                                    file,
                                  },
                                  {
                                    onSuccess: (filename: any) => {
                                      props.onChange(filename);
                                    },
                                    onError: () => {
                                      showErrorToast({
                                        message:
                                          'Files of this extension cannot be uploaded',
                                      });
                                    },
                                  },
                                );
                              } else {
                                showErrorToast({
                                  message:
                                    'Files of this extension cannot be uploaded',
                                });
                              }
                            } else if (file) {
                              const reader = new FileReader();
                              reader.onabort = () =>
                                console.log('file reading was aborted');
                              reader.onerror = () =>
                                console.log('file reading has failed');
                              reader.onload = () => {
                                const base64String = reader.result;
                                if (typeof base64String === 'string') {
                                  props.onChange(file.name);
                                  setExternalFile(base64String);
                                }
                              };
                              reader.readAsDataURL(file);
                            }
                          }}
                        />
                      );
                    }}
                    defaultValue={''}
                    name="AnswerScriptDetails"
                    control={control}
                  />
                )}
              </Box>
            </Stack>
          </Card>
        </Box>
        <Flex justifyContent="flex-end" mt={3}>
          <Stack direction="x" gap={2}>
            <Button
              variant="secondary"
              onClick={() => {
                reset();
                history.push('/home/accounts');
              }}
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Stack>
        </Flex>
      </Flex>

      <FileImage
        filesOnly
        buttonText={'Add to answer script details'}
        account={`${accountInfoReturn?.CID}${'-'}${
          accountInfoReturn?.SID
        }${'-'}${accountInfoReturn?.PID}`}
        showPanel={showFileImage}
        togglePanel={toggleFileImage}
        createFolder={createFolder}
        uploadFile={uploadFile}
        deleteFile={deleteFile}
        addFileToEditor={(file: BlobFileEntity) => {
          if (file.FileType !== 'image') {
            const hrefTag =
              "<a href='" +
              `${apiBaseUrl}/procedures/root/${file.FilePath}` +
              "/'>" +
              file.FileName +
              '</a>';
            console.log('hrefTag:', hrefTag);
            (window as any)?.tinymce?.activeEditor?.insertContent(hrefTag);
          } else {
            const hrefTag = `<img src="${file.FileUri}" />`;
            (window as any)?.tinymce?.activeEditor?.insertContent(hrefTag);
          }
        }}
      />
      {isLoadingInfo || isLoadingFile ? <Loader /> : null}
    </>
  );
}
