import React, { ReactElement, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { SlidePanel, FormInput, FormTextarea, Alert } from 'ui/patterns';
import { Button, Stack, Checkbox } from 'ui/components';
import * as Icon from 'assets/icons';
import { useSaveRecordsCustomTable } from 'hooks';
import { showErrorToast, showSuccessToast } from 'utils/showToast';
import { CustomRecords } from 'types/customTables';

type RecordProps = {
  showPanel: boolean;
  togglePanel: () => void;
  selectedTable: any;
  record?: any;
  accountId: number;
  tableId: number;
  mode: string;
};

const formDefaults = {
  EmailTo: '',
  Values: [],
  AccessType: false,
  AccessTypeId: 0,
  HasAccess: false,
  AccountId: 0,
  Id: '0',
  TableId: 0,
};

export default function AddEditCustomRecords({
  showPanel,
  togglePanel,
  record,
  selectedTable,
  accountId,
  tableId,
  mode,
}: RecordProps): ReactElement {
  const customColumns = selectedTable?.Columns;
  const [saveCustomRecords, { isLoading }] = useSaveRecordsCustomTable();
  const { register, handleSubmit, reset } = useForm<Partial<CustomRecords>>({
    defaultValues: { ...formDefaults },
  });
  const selectedRecord = record?.original;

  const recordId = record?.original?.Id;

  const columnObj = useMemo(
    () =>
      selectedRecord?.Values.reduce(
        (columnObj: Array<any>, field: string, index: number) => {
          columnObj[customColumns[index]] = field;
          return columnObj;
        },
        {},
      ),
    [selectedRecord, customColumns],
  );

  useEffect(() => {
    if (selectedRecord && !isLoading) {
      reset({
        EmailTo: selectedRecord?.EmailTo,
        AccessType: selectedRecord?.AccessTypeId === 1 ? true : 0,
        HasAccess: selectedRecord?.HasAccess,
        Id: selectedRecord?.Id,
        ...columnObj,
      });
    }
  }, [selectedRecord, reset, isLoading, columnObj]);

  function resetForm(): void {
    const values = customColumns.values();
    const columnNames: Record<string, string> = {};
    for (const value of values) {
      columnNames[value] = '';
    }
    reset({
      ...columnNames,
      EmailTo: '',
      HasAccess: false,
      AccessType: false,
    });
  }

  function onSubmit(data: any) {
    let result = Object.keys(data).map(key => {
      if (key !== 'AccessType' && key !== 'EmailTo') return data[key];
      return undefined;
    });
    result = result.filter(item => item !== undefined);
    const emptyArray = result.filter(item => item);

    if (emptyArray.length > 0) {
      saveCustomRecords(
        {
          EmailTo: data.EmailTo,
          Values: result,
          AccessType: data.AccessType,
          AccessTypeId: data.AccessType === true ? 1 : 0,
          HasAccess: false,
          AccountId: accountId,
          Id: recordId === undefined ? '0' : selectedRecord?.Id,
          TableId: tableId,
        },
        {
          onSuccess: () => {
            showSuccessToast({
              message: 'Your changes were successfully saved.',
            });
            togglePanel();
            resetForm();
          },
          onError: ({ Message }: { Message: string }) => {
            showErrorToast({ message: Message });
          },
        },
      );
    } else
      showErrorToast({
        message: 'Please enter atleast one column value to continue.',
      });
  }

  const addRecordsAction = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          togglePanel();
          resetForm();
        }}
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        onClick={handleSubmit(onSubmit)}
        disabled={isLoading}
      >
        Save
      </Button>
    </>
  );

  return (
    <>
      <SlidePanel
        title={`${mode === 'Add' ? 'Add Record' : 'Edit Record'}`}
        actions={addRecordsAction()}
        onClose={() => {
          togglePanel();
          resetForm();
        }}
        open={showPanel}
      >
        <Stack direction="y" gap={4}>
          {customColumns &&
            customColumns.map((item: any) => (
              <FormTextarea
                key={item}
                label={item}
                rows={1}
                register={register}
                name={item}
              />
            ))}

          <Alert variant="info" icon={<Icon.Info />}>
            To specify more than one email, separate each email with a comma.
          </Alert>

          <FormInput
            label="Email To"
            type="email"
            // feedback="To specify more than one email, separate each email with a comma."
            register={register}
            name="EmailTo"
            // validate={{
            //   pattern: {
            //     value: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/,
            //     message: 'Enter a valid email address.',
            //   },
            // }}
            // validation={errors?.EmailTo && 'error'}
            // feedback={errors?.EmailTo?.message}
          />
          <Checkbox
            label="Limited Access"
            register={register}
            name="AccessType"
          />
        </Stack>
      </SlidePanel>
    </>
  );
}
