import React, { ReactElement } from 'react';
import { SlidePanel, Loader } from 'ui/patterns';
import { useGetProcedureDetails } from 'hooks';

export default function PreviewPanel({
  previewProcedure,
  closePreviewProcedure,
  selectedIds,
}: {
  previewProcedure: boolean;
  closePreviewProcedure: () => void;
  selectedIds?: { accountId: number; procedureId: number };
}): ReactElement {
  const { data: procedureDetails, isLoading: isProcedureDetailsLoading } =
    useGetProcedureDetails({
      accountId: selectedIds?.accountId,
      procedureId: selectedIds?.procedureId,
    });
  const createMarkup = () => {
    return { __html: procedureDetails?.Content || '' };
  };
  return (
    <>
      <SlidePanel
        open={previewProcedure}
        onClose={() => closePreviewProcedure()}
        title={procedureDetails?.AccountProcedure?.Procedure?.Name}
        size="md"
      >
        <div dangerouslySetInnerHTML={createMarkup()} />
        {isProcedureDetailsLoading && <Loader />}
      </SlidePanel>
    </>
  );
}
