import { MutationResultPair, useMutation } from 'react-query';
import { saveAdvanceSectionSearch } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { QuestionSearchParams, SortItem } from 'types/accountsSearch';

function downloadFile({
  fileName,
  fileContent,
}: {
  fileName: string;
  fileContent: string;
}) {
  const printWindow = window.open('height=800,width=1090', '_blank');
  const element = printWindow?.document.createElement('a');
  element?.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContent),
  );
  if (element) {
    element.setAttribute('download', fileName);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  printWindow?.close();
}

function getSortKey(sortKey?: string) {
  if (!sortKey) return '';
  const selected = sortKey?.toLowerCase();
  console.log('selected', selected);

  let key = '';
  if (selected === 'cid' || selected === 'pid' || selected === 'sid') {
    key = `${sortKey}`;
  } else if (selected === 'account name') {
    key = 'AccountName';
  } else if (selected === 'section title') {
    key = 'SectionTitle';
  } else if (selected === 'form id') {
    key = 'FormId';
  } else if (selected === 'form notes') {
    key = 'FormNotes';
  }
  return key;
}

async function fetchDownloadContent({
  requestData,
}: {
  requestData: any;
}): Promise<APIResult> {
  console.log('requestData', requestData);

  const response = await fetch(saveAdvanceSectionSearch, {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });
  return response.json();
}

export type APIResult = any;

export type TError = { message: string };
export type TVariables = {
  searchParams: QuestionSearchParams | undefined;
  currentSortItem: SortItem | undefined;
  columnsSearch: Record<string, string> | undefined;
  pageNumber: number | undefined;
};

export type TSnapshot = unknown;

function prepareData({
  searchList,
  includeGlobal,
  includeInactive,
  columnsSearch,
  currentSortItem,
  pageNumber,
}: QuestionSearchParams & {
  columnsSearch?: Record<string, string>;
  currentSortItem?: SortItem;
  pageNumber: number | undefined;
}) {
  return {
    PageNumber: pageNumber,
    draw: 1,
    PageSize: 100,
    columns: [
      {
        data: 'Id',
        name: 'Id',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: '',
        },
      },
      {
        data: 'CID',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['CID'] ? columnsSearch['CID'] : ''
          }`,
        },
      },
      {
        data: 'SID',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['SID'] ? columnsSearch['SID'] : ''
          }`,
        },
      },
      {
        data: 'PID',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['PID'] ? columnsSearch['PID'] : ''
          }`,
        },
      },
      {
        data: 'AccountName',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['Account Name']
              ? columnsSearch['Account Name']
              : ''
          }`,
        },
      },
      {
        data: 'SectionTitle',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['Section Title']
              ? columnsSearch['Section Title']
              : ''
          }`,
        },
      },
      {
        data: 'FormId',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['Form Id']
              ? columnsSearch['Form Id']
              : ''
          }`,
        },
      },
      {
        data: 'FormNotes',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['Form Notes']
              ? columnsSearch['Form Notes']
              : ''
          }`,
        },
      },
      {
        data: 'HelpText',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: `${
            columnsSearch && columnsSearch['Search Snippet']
              ? columnsSearch['Search Snippet']
              : ''
          }`,
        },
      },
    ],
    order: [
      {
        columnName: getSortKey(currentSortItem && currentSortItem.sortKey),
        dir: `${currentSortItem?.sortOrder}`,
      },
    ],
    search: searchList,
    IncludeGlobal: includeGlobal,
    IncludeInactive: includeInactive,
  };
}

function useSaveAdvancedSectionSearch(): MutationResultPair<
  void,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async ({
      searchParams,
      columnsSearch,
      currentSortItem,
      pageNumber,
    }: TVariables): Promise<void> => {
      if (!searchParams) return;
      const requestData = prepareData({
        ...searchParams,
        columnsSearch,
        currentSortItem,
        pageNumber,
      });
      const fetchResponse = await fetchDownloadContent({
        requestData,
      });
      if (fetchResponse)
        downloadFile({
          fileName: fetchResponse.FileName,
          fileContent: fetchResponse.FileContent,
        });
      return;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveAdvancedSectionSearch };
