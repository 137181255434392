import { transparentize, lighten, desaturate } from 'polished';
import tokenFontSizes from '../../utils/designtokens/fontSizes';
import tokenSpace from '../../utils/designtokens/spacing';
import tokenFontWeights from '../../utils/designtokens/fontWeights';
import tokenColors from '../../utils/designtokens/colors';
import tokenRadii from '../../utils/designtokens/radii';
import tokenShadows from '../../utils/designtokens/shadows';

const fonts = {
  body: `system-ui, sans-serif`,
  heading: 'inherit',
};

const fontSizes = [
  tokenFontSizes.xsmall,
  tokenFontSizes.small,
  tokenFontSizes.p,
  tokenFontSizes.h6,
  tokenFontSizes.h5,
  tokenFontSizes.h4,
  tokenFontSizes.h3,
  tokenFontSizes.h2,
  tokenFontSizes.h1,
];

const fontWeights = {
  light: tokenFontWeights.light,
  regular: tokenFontWeights.regular,
  medium: tokenFontWeights.medium,
  bold: tokenFontWeights.bold,
};

const space = [
  0,
  tokenSpace.space4,
  tokenSpace.space8,
  tokenSpace.space12,
  tokenSpace.space16,
  tokenSpace.space20,
  tokenSpace.space24,
  tokenSpace.space32,
  tokenSpace.space40,
  tokenSpace.space48,
  tokenSpace.space64,
  tokenSpace.space80,
  tokenSpace.space96,
  tokenSpace.space112,
  tokenSpace.space128,
];

const radii = {
  circle: tokenRadii.circle,
  rounded: tokenRadii.rounded,
  soft: tokenRadii.soft,
  hard: tokenRadii.hard,
};

const shadows = {
  xLarge: tokenShadows.xLarge,
  large: tokenShadows.large,
  medium: tokenShadows.medium,
  small: tokenShadows.small,
};

const zIndices = [-1, 0, 1, 2, 10, 1000, 1020, 1030, 1040, 1050, 1060];

const baseColors = {
  primary: '#f78857',
  secondary: tokenColors.purple,
  baseRed: tokenColors.red,
  baseGreen: tokenColors.green,
  baseYellow: tokenColors.yellow,
  baseBlue: tokenColors.blue,
  baseNeutral: tokenColors.gray,
  black: tokenColors.black,
  white: tokenColors.white,

  gray: [
    tokenColors.gray000,
    tokenColors.gray100,
    tokenColors.gray200,
    tokenColors.gray300,
    tokenColors.gray400,
    tokenColors.gray500,
    tokenColors.gray600,
    tokenColors.gray700,
    tokenColors.gray800,
    tokenColors.gray900,
  ],

  purple: [
    tokenColors.purple000,
    tokenColors.purple100,
    tokenColors.purple200,
    tokenColors.purple300,
    tokenColors.purple400,
    tokenColors.purple500,
    tokenColors.purple600,
    tokenColors.purple700,
    tokenColors.purple800,
    tokenColors.purple900,
  ],

  orange: [
    tokenColors.orange000,
    tokenColors.orange100,
    tokenColors.orange200,
    tokenColors.orange300,
    tokenColors.orange400,
    tokenColors.orange500,
    tokenColors.orange600,
    tokenColors.orange700,
    tokenColors.orange800,
    tokenColors.orange900,
  ],

  green: [
    tokenColors.green000,
    tokenColors.green100,
    tokenColors.green200,
    tokenColors.green300,
    tokenColors.green400,
    tokenColors.green500,
    tokenColors.green600,
    tokenColors.green700,
    tokenColors.green800,
    tokenColors.green900,
  ],

  red: [
    tokenColors.red000,
    tokenColors.red100,
    tokenColors.red200,
    tokenColors.red300,
    tokenColors.red400,
    tokenColors.red500,
    tokenColors.red600,
    tokenColors.red700,
    tokenColors.red800,
    tokenColors.red900,
  ],

  yellow: [
    tokenColors.yellow000,
    tokenColors.yellow100,
    tokenColors.yellow200,
    tokenColors.yellow300,
    tokenColors.yellow400,
    tokenColors.yellow500,
    tokenColors.yellow600,
    tokenColors.yellow700,
    tokenColors.yellow800,
    tokenColors.yellow900,
  ],

  blue: [
    tokenColors.blue000,
    tokenColors.blue100,
    tokenColors.blue200,
    tokenColors.blue300,
    tokenColors.blue400,
    tokenColors.blue500,
    tokenColors.blue600,
    tokenColors.blue700,
    tokenColors.blue800,
    tokenColors.blue900,
  ],
};

const colors = {
  grayLight: tokenColors.gray300,
  gray: tokenColors.gray,
  grayDark: tokenColors.gray700,
  black: baseColors.black,
  white: baseColors.white,
  primary: baseColors.primary,
  secondary: baseColors.purple,

  text: {
    body: `var(--color-body-text)`, // Dark gray on light mode and light gray on dark mode
    muted: `var(--color-neutral-contrast-06)`,
    error: baseColors.red[6],
    warning: baseColors.yellow[9],
    success: baseColors.green[5],
    info: baseColors.blue[6],
  },

  bg: {
    highContrastNeutral: `var(--high-contrast-neutral)`, // Black on light mode and white on dark mode
    lowContrastNeutral: `var(--low-contrast-neutral)`, // Light gray on light mode and dark gray on dark mode
    constantDarkGray: baseColors.gray[9],
    lightRed: baseColors.red[1],
    lightBlue: baseColors.blue[1],
  },

  border: {
    default: `var(--color-borer-default)`,
    blackfade: transparentize(0.15, tokenColors.black),
    whitefade: transparentize(0.15, tokenColors.white),
    grayLight: lighten(0.03, tokenColors.gray200),
    gray: tokenColors.gray200,
    grayDark: tokenColors.gray300,
    grayDarker: tokenColors.gray700,
    green: tokenColors.green400,
    greenLight: desaturate(0.4, tokenColors.green300),
    error: baseColors.red[5],
    warning: baseColors.yellow[6],
    success: baseColors.green[5],
    info: tokenColors.blue600,
  },

  transparent: {
    black15: transparentize(0.15, tokenColors.black),
    black20: transparentize(0.2, tokenColors.black),
    black30: transparentize(0.3, tokenColors.black),
    black35: transparentize(0.35, tokenColors.black),
    black50: transparentize(0.5, tokenColors.black),
    black75: transparentize(0.75, tokenColors.black),
    white15: transparentize(0.15, tokenColors.white),
    white50: transparentize(0.5, tokenColors.white),
    white70: transparentize(0.7, tokenColors.white),
  },

  state: {
    error: tokenColors.red500,
    warning: tokenColors.yellow700,
    success: tokenColors.green500,
    info: tokenColors.blue600,
  },

  focusOutline: {
    error: transparentize(0.15, baseColors.baseRed),
  },
};

export default {
  fonts,
  fontSizes,
  fontWeights,
  space,
  baseColors,
  colors,
  radii,
  shadows,
  zIndices,
};
