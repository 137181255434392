import React, { ReactElement } from 'react';
import { ColumnInstance } from 'react-table';
import { TableType } from 'types/countryType';
import { FloatLabelInput, ButtonGroup } from 'ui/patterns';
import { Flex, Text } from 'rebass/styled-components';
import Icon from 'assets/icons';
import { CustomDatePicker } from 'ui/components';

type SortItemType = {
  sortKey: string;
  sortOrder: string;
};

export default function TableHeader(
  props: {
    column: ColumnInstance<TableType>;
  } & {
    headerUpdateCallBack?: ({
      columnId,
      value,
    }: {
      columnId: string;
      value: any;
    }) => void;
    handleSortChange?: (item: string) => void;
    sortedItem?: { sortKey: string; sortOrder: string };
    columnsSearch?: Record<string, string>;
    multiSortedItem?: SortItemType | Array<SortItemType>;
  },
): ReactElement {
  const onChangeText = (value: string) => {
    props.headerUpdateCallBack &&
      props.headerUpdateCallBack({ columnId: props.column.id, value });
  };

  let sortedItem;
  if (props.multiSortedItem instanceof Array) {
    sortedItem = props.multiSortedItem.find(
      mItem => mItem.sortKey === props.column.id,
    );
  } else {
    sortedItem = props.multiSortedItem;
  }

  if (props.sortedItem) {
    sortedItem = props.sortedItem;
  }

  const onSortChange = () => {
    props.handleSortChange && props.handleSortChange(props.column.id);
  };

  return (
    <Flex>
      {props.column.id !== 'Updated At' ? (
        <FloatLabelInput
          style={{ flexGrow: '1' }}
          label={props.column.id}
          onChangeText={onChangeText}
          value={
            props.columnsSearch && props.columnsSearch[props.column.id]
              ? props.columnsSearch[props.column.id]
              : ''
          }
        />
      ) : (
        <CustomDatePicker
          label="Updated At"
          onChange={(value: any) => {
            props.headerUpdateCallBack &&
              props.headerUpdateCallBack({ columnId: props.column.id, value });
          }}
          value={
            props.columnsSearch && props.columnsSearch[`Updated At`]
              ? props.columnsSearch[`Updated At`]
              : ''
          }
        />
      )}
      <ButtonGroup onClick={onSortChange}>
        {sortedItem?.sortKey !== props.column.id && <Icon.Sort title="Sort" />}
        {sortedItem?.sortKey === props.column.id &&
          sortedItem?.sortOrder === 'asc' && <Icon.SortUp title="Sort" />}
        {sortedItem?.sortKey === props.column.id &&
          sortedItem?.sortOrder === 'desc' && <Icon.SortDown title="Sort" />}
      </ButtonGroup>
    </Flex>
  );
}
