import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { confirmAlert } from 'utils/confirm-alert';
import dayjs from 'dayjs';
import * as Icon from 'assets/icons';
import { showSuccessToast, showErrorToast } from 'utils/showToast';
import {
  useGetAccountInfo,
  useGetProcedureBuilderProcedures,
  useHideDisplayProcedures,
  useGetProcedureDetails,
  useGetReferenceForProcedures,
  useDeleteProcedure,
  useRenameProcedure,
  useForceRenameProcedure,
  useConvertToGlobalProcedure,
  useConvertToNormalProcedure,
  useCloneProcedure,
  useDiscardProcedureContent,
  usePublishProcedure,
  useSaveProcedure,
  useCreateProcedure,
  useUpdateProcedureDetails,
  useSaveLayout,
  useSaveTable,
  useCreateFolder,
  useUploadFile,
  useDeleteFile,
  useSaveAsPDFHtmlContent,
  useSaveAsWordHtmlContent,
  useHideDisplayMultipleProcedures,
  useCleanProcedure,
  useBrokenLinksForProcedures,
} from 'hooks';
import {
  ProcedureListType,
  ProcedureDetailsType,
} from 'types/procedureBuilder';
import { apiBaseUrl } from 'utils/urls';
import { lazyRun } from 'utils/useInterval';
import { queryCache } from 'react-query';

export default function useProcedureBuilder({
  accountId,
  pid,
  mode,
}: {
  accountId: number;
  pid?: number;
  mode?: string;
}) {
  const tempId = localStorage.getItem('fromSearch');
  const [selectedProcedures, setSelectedProcedures] = useState<
    Array<number> | undefined
  >(() => {
    if (pid) return [pid];
    if (tempId) return [JSON.parse(tempId)];
    else return;
  });

  const [procedures, setProcedures] = useState<Array<ProcedureListType>>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const [renameProc, setRenameProc] = useState<ProcedureListType>();
  const [appliedFilterOption, setAppliedFilterOption] = useState<string>('');
  const [userTyped, setUserTyped] = useState<boolean>(false);
  const [showBrokenLinks, setShowBrokenLinks] = useState<boolean>(false);
  const [content, setContent] = useState('');
  const [editorChanges, setEditorChanges] = useState<boolean>(false); // dedicated state variable for prompt

  const toggleBrokenLinks = () => {
    setShowBrokenLinks(!showBrokenLinks);
  };

  const { data: accountInfo, isLoading: isAccountInfoLoading } =
    useGetAccountInfo(accountId);
  const { data: procedureList, isLoading: isProcedureLoading } =
    useGetProcedureBuilderProcedures(accountId);

  const [saveAsPDF, { isLoading: isSavePDFLoading }] =
    useSaveAsPDFHtmlContent();

  const [saveAsWord, { isLoading: isSaveWordLoading }] =
    useSaveAsWordHtmlContent();
  const [saveLayoutApi, { isLoading: isSaveLayoutLoading }] = useSaveLayout();

  const [saveTableApi, { isLoading: isSaveTableLoading }] = useSaveTable();

  const [
    updateProcedureVisibility,
    { isLoading: isProcedureVisibilityLoading },
  ] = useHideDisplayProcedures();

  //clean template
  const [cleanTemplate, { isLoading: isTemplateCleanupLoading }] =
    useCleanProcedure();

  const [
    updateMultipleProcedureVisibility,
    { isLoading: isMultipleProcedureVisibilityLoading },
  ] = useHideDisplayMultipleProcedures();

  const [getProcedureReference, { isLoading: isProcedureReferenceLoading }] =
    useGetReferenceForProcedures();

  const [
    discardProcedureContent,
    { isLoading: isDiscardProcedureContentLoading },
  ] = useDiscardProcedureContent();

  const [deleteProcedure, { isLoading: isDeleteProcedureLoading }] =
    useDeleteProcedure();

  const [updateProcedureDetails, { isLoading: isUpdateProcedureDetails }] =
    useUpdateProcedureDetails();

  // created new hook for updating procedure details as endpoint for rename is changed
  const [renameProcedure, { isLoading: isRenameProcedureLoading }] =
    useRenameProcedure();

  //force rename for making both side names same
  const [forceRenameProcedure, { isLoading: isForceRenameProcedureLoading }] =
    useForceRenameProcedure();

  const [
    convertToGlobalProcedure,
    { isLoading: isConvertToGlobalProcedureLoading },
  ] = useConvertToGlobalProcedure();

  const [
    convertToNormalProcedure,
    { isLoading: isConvertToNormalProcedureLoading },
  ] = useConvertToNormalProcedure();

  const [cloneProcedure, { isLoading: isCloneProcedureLoading }] =
    useCloneProcedure();

  const [publishProcedure, { isLoading: isPublishProcedureLoading }] =
    usePublishProcedure();

  const [autoPublishProcedure] = usePublishProcedure();

  const [saveProcedure, { isLoading: isSaveProcedureLoading }] =
    useSaveProcedure();
  const [autoSaveProcedure] = useSaveProcedure();
  const selectdProcedureRef = useRef<number>();
  const procedureId = useMemo(() => {
    if (selectdProcedureRef?.current) {
      queryCache.removeQueries(
        ['get_procedure', accountId, selectdProcedureRef.current],
        { exact: true },
      );
    } else {
      queryCache.removeQueries(['get_procedure', accountId]);
    }
    const newProcedureId =
      selectedProcedures && selectedProcedures.length === 1
        ? selectedProcedures[0]
        : 0;
    selectdProcedureRef.current = newProcedureId;
    return newProcedureId;
  }, [accountId, selectedProcedures]);

  const { data: procedureDetails, isLoading: isProcedureDetailsLoading } =
    useGetProcedureDetails({
      accountId,
      procedureId,
    });
  const [createProcedure, { isLoading: isCreateProcedureLoading }] =
    useCreateProcedure();

  const { data: brokenLinks, isLoading: isBrokenLinksLoading } =
    useBrokenLinksForProcedures(accountId);

  const checkUnsavedContentExists = () => {
    let content = '';
    if ((window as any)?.tinymce?.activeEditor?.initialized === true) {
      content = `<!DOCTYPE html><html><head><title></title><base href='/api/procedures/V1/accountfolders/${
        procedureDetails?.AccountProcedure?.AccountId
      }/Policies/'></head><body>${(
        window as any
      )?.tinymce?.activeEditor?.getContent()}</body></html>`;
    }
    if (content !== procedureDetails?.Content) return true;
    else return false;
  };

  const totalBrokenLinksCount = useMemo(() => {
    if (brokenLinks && brokenLinks.length > 0) {
      let totalCounts = 0;
      brokenLinks.forEach((item: any) =>
        item.ProcedureLinks.forEach((link: any) => {
          if (link?.Link?.IgnoreIsBroken === false) {
            totalCounts = totalCounts + link.Count;
          }
        }),
      );
      return totalCounts;
    }
  }, [brokenLinks]);

  const isPublished = useMemo(() => {
    if (procedureList) {
      const proceduresList = [...procedureList];
      return (
        proceduresList &&
        proceduresList.filter(item => {
          if (
            (!item.IsLinked || item.IsParentLinked) &&
            !item.Procedure?.IsGlobal
          ) {
            if (item.Procedure.Versions.length === 0) {
              return true;
            } else {
              const seconds =
                (new Date(item.Procedure.LastUpdatedOn).getTime() -
                  new Date(
                    item.Procedure.Versions[
                      item.Procedure.Versions.length - 1
                    ].CreatedOn,
                  ).getTime()) /
                1000;
              if (Math.floor(seconds) > 0) return true;
              else return false;
            }
          } else {
            return false;
          }
        })
      );
    }
  }, [procedureList]);

  /*********SAVE PROCEDURE******************************************************************/
  //Do not show again checkbox//
  const handleSaveModalVisiblity = (e: any) => {
    if (e.target.checked === true) {
      localStorage.setItem('saveModalConfirmation', JSON.stringify(true));
    } else {
      localStorage.removeItem('saveModalConfirmation');
    }
  };
  //Function to handle apis
  const confirmSaveProcedure = ({
    accountId,
    params,
  }: {
    accountId: number;
    params: ParamsType;
  }) => {
    const unsaved = checkUnsavedContentExists();
    saveProcedure(
      {
        accountId: accountId,
        params: { ...params },
      },
      {
        onSuccess: () => {
          setUserTyped(false);
          setEditorChanges(false);
          if (unsaved)
            procedures.forEach(item => {
              if (item.ProcedureId === params.AccountProcedure.ProcedureId) {
                if (
                  (!item.IsLinked || item.IsParentLinked) &&
                  !item.Procedure?.IsGlobal
                ) {
                  item.IsPublished = true;
                }
              }
              return item;
            });
          showSuccessToast({
            message: 'Your changes were saved successfully.',
          });
        },
        onError: (error: { Message: string }) => {
          showErrorToast({
            message: error.Message,
          });
        },
      },
    );
  };
  //Main function handling save
  function saveContent(cb?: () => void, conversion?: boolean) {
    let content = '';
    const tempValue = localStorage.getItem('saveModalConfirmation');
    const showConfirmation = tempValue === null ? false : JSON.parse(tempValue);
    lazyRun(() => {
      // cancels all lazy runs scheduled prior
    }, 0);
    if ((window as any)?.tinymce?.activeEditor?.initialized === true) {
      content = `<!DOCTYPE html><html><head><title></title><base href='/api/procedures/V1/accountfolders/${
        procedureDetails?.AccountProcedure?.AccountId
      }/Policies/'></head><body>${(
        window as any
      )?.tinymce?.activeEditor?.getContent()}</body></html>`;
    }
    const saveParams = {
      AccountProcedure: {
        Procedure: {
          FileId: procedureDetails?.AccountProcedure.Procedure.FileId || '',
        },
        ProcedureId: procedureDetails?.AccountProcedure.ProcedureId || 0,
      },
      Content: content || '',
    };
    const pList = procedureList?.filter((item: any) =>
      selectedProcedures?.find(id => item.ProcedureId === id),
    );
    const procFileIds = pList && pList.map(item => item.Procedure.FileId);
    // setContent(content);
    if (cb) {
      if (conversion) {
        saveProcedure(
          {
            accountId: accountId,
            params: { ...saveParams },
            refetchList: false,
          },
          {
            onSuccess: (mutationResult: string) => {
              const unsaved = checkUnsavedContentExists();
              if (unsaved)
                procedures.forEach(item => {
                  if (
                    item.ProcedureId === saveParams.AccountProcedure.ProcedureId
                  ) {
                    if (
                      (!item.IsLinked || item.IsParentLinked) &&
                      !item.Procedure?.IsGlobal
                    ) {
                      item.IsPublished = true;
                    }
                  }
                  return item;
                });
              if (mutationResult) {
                if (procFileIds)
                  publishProcedure(
                    {
                      accountId: accountId,
                      procedureId:
                        procedureDetails?.AccountProcedure.ProcedureId || 0,
                      params: [...procFileIds],
                    },
                    {
                      onSuccess: cb,
                    },
                  );
              } else {
                showErrorToast({
                  message: 'Something went wrong.',
                });
              }
            },
            onError: (error: { Message: string }) => {
              showErrorToast({
                message: error.Message,
              });
            },
          },
        );
      } else {
        saveProcedure(
          {
            accountId: accountId,
            params: { ...saveParams },
          },
          {
            onSettled: cb,
          },
        );
      }
    } else {
      if (!showConfirmation) {
        confirmAlert({
          dontShowAgain: true,
          handleModalVisibility: handleSaveModalVisiblity,
          message:
            'This will save your progress to the current version, but it will not be available to Proteus Call Center until published.',
          buttons: [
            {
              label: 'Ok',
              onClick: () => {
                confirmSaveProcedure({
                  accountId: accountId,
                  params: { ...saveParams },
                });
              },
              className: 'pr-btn-primary',
            },
            {
              label: 'Cancel',
              onClick: () => {
                //
              },
            },
          ],
        });
      } else {
        confirmSaveProcedure({
          accountId: accountId,
          params: { ...saveParams },
        });
      }
    }
  }
  /*********SAVE PROCEDURE******************************************************************/

  /********* AUTO SAVE AND PUBLISH******************************************************************/
  const autoPublishPolicyContent = (cb?: () => void) => {
    lazyRun(() => {
      // cancels all lazy runs scheduled prior
    }, 0);
    let content = '';
    if ((window as any)?.tinymce?.activeEditor?.initialized === true) {
      content = `<!DOCTYPE html><html><head><title></title><base href='/api/procedures/V1/accountfolders/${
        procedureDetails?.AccountProcedure?.AccountId
      }/Policies/'></head><body>${(
        window as any
      )?.tinymce?.activeEditor?.getContent()}</body></html>`;
    }
    const saveParams = {
      AccountProcedure: {
        Procedure: {
          FileId: procedureDetails?.AccountProcedure.Procedure.FileId || '',
        },
        ProcedureId: procedureDetails?.AccountProcedure.ProcedureId || 0,
      },
      Content: content || '',
    };
    const pList = procedureList?.filter((item: any) =>
      selectedProcedures?.find(id => item.ProcedureId === id),
    );
    const procFileIds =
      pList && pList.map((item: any) => item.Procedure.FileId);
    autoSaveProcedure(
      {
        accountId: accountId,
        params: { ...saveParams },
        isAutoSave: true,
      },
      {
        onSuccess: (mutationResult: string) => {
          setUserTyped(false);
          setEditorChanges(false);
          if (mutationResult) {
            if (procFileIds)
              autoPublishProcedure(
                {
                  accountId: accountId,
                  procedureId:
                    procedureDetails?.AccountProcedure.ProcedureId || 0,
                  params: [...procFileIds],
                },
                {
                  onSuccess: cb,
                },
              );
          }
        },
      },
    );
  };

  const autoSaveContent = useCallback(
    (cb?: () => void, contentChange?: boolean) => {
      if (!(window as any)?.tinymce?.activeEditor) {
        console.log(
          'autosavecontent is cancelled due to absence of an active editor',
        );
        return;
      }
      let content = '';
      if ((window as any)?.tinymce?.activeEditor?.initialized === true) {
        content = `<!DOCTYPE html><html><head><title></title><base href='/api/procedures/V1/accountfolders/${
          procedureDetails?.AccountProcedure?.AccountId
        }/Policies/'></head><body>${(
          window as any
        )?.tinymce?.activeEditor?.getContent()}</body></html>`;
      }
      // setContent(content);
      if (content !== procedureDetails?.Content || contentChange) {
        const saveParams = {
          AccountProcedure: {
            Procedure: {
              FileId: procedureDetails?.AccountProcedure.Procedure.FileId || '',
            },
            ProcedureId: procedureDetails?.AccountProcedure.ProcedureId || 0,
          },
          Content: content || '',
        };
        autoSaveProcedure(
          {
            accountId,
            params: saveParams,
            isAutoSave: true,
          },
          {
            onSuccess: cb,
          },
        );
      }
    },
    [procedureDetails, autoSaveProcedure, accountId],
  );
  /********* AUTO SAVE AND PUBLISH******************************************************************/

  const isConvertableToGlobal = () => {
    if (selectedProcedures && selectedProcedures.length >= 1) {
      const pList = procedureList?.filter((item: any) =>
        selectedProcedures.find(id => item.ProcedureId === id),
      );
      const isConvertable =
        pList &&
        pList.find(
          (item: any) =>
            item.IsLinked === true || item.Procedure.IsGlobal === true,
        );
      return isConvertable;
    } else return true;
  };

  const isConvertableToNormal = () => {
    if (selectedProcedures && selectedProcedures.length >= 1) {
      const pList = procedureList?.filter((item: any) =>
        selectedProcedures.find(id => item.ProcedureId === id),
      );
      const isConvertable =
        pList && pList.find((item: any) => item.Procedure.IsGlobal === false);
      return isConvertable;
    } else return true;
  };

  /*********CLONE PROCEDURE******************************************************************/
  //Do not show again checkbox//
  const handleCloneModalVisiblity = (e: any) => {
    if (e.target.checked === true) {
      localStorage.setItem('cloneModalConfirmation', JSON.stringify(true));
    } else {
      localStorage.removeItem('cloneModalConfirmation');
    }
  };
  //clone function api call
  const confirmCloneProcedure = ({
    procedureFileIds,
    accountId,
  }: {
    procedureFileIds: Array<string>;
    accountId: number;
  }) => {
    cloneProcedure(
      {
        procedureFileIds: [...procedureFileIds],
        accountId: accountId,
      },
      {
        onSuccess: (mutationResult: any) => {
          setSearchValue('');
          if (
            mutationResult &&
            mutationResult.length &&
            mutationResult.length === 1
          ) {
            if (mutationResult[0]?.ProcedureId)
              setSelectedProcedures([mutationResult[0]?.ProcedureId]);
            setRenameProc(mutationResult[0]);
          }
          showSuccessToast({
            message: 'Your changes were saved successfully.',
          });
        },
        onError: (error: { Message: string }) => {
          showErrorToast({
            message: error.Message,
          });
        },
      },
    );
  };
  //Main function to handle clone
  const handleCloneProcedure = () => {
    const tempValue = localStorage.getItem('cloneModalConfirmation');
    const showConfirmation = tempValue === null ? false : JSON.parse(tempValue);
    if (selectedProcedures && selectedProcedures.length >= 1) {
      const pList = procedureList?.filter((item: any) =>
        selectedProcedures.find(id => item.ProcedureId === id),
      );
      const procFileIds =
        pList && pList.map((item: any) => item.Procedure.FileId);
      if (procFileIds)
        if (!showConfirmation) {
          confirmAlert({
            dontShowAgain: true,
            handleModalVisibility: handleCloneModalVisiblity,
            message: 'This will create a new procedure with the same contents.',
            buttons: [
              {
                label: 'Ok',
                onClick: () => {
                  confirmCloneProcedure({
                    procedureFileIds: [...procFileIds],
                    accountId: accountId,
                  });
                },
                className: 'pr-btn-primary',
              },
              {
                label: 'Cancel',
                onClick: () => {
                  //
                },
              },
            ],
          });
        } else {
          confirmCloneProcedure({
            procedureFileIds: [...procFileIds],
            accountId: accountId,
          });
        }
    }
  };
  /*********CLONE PROCEDURE******************************************************************/

  /*********PUBLISH******************************************************************/
  //Function to handle publish api (Single,Multiple)
  function publishProcedureApi(
    procFileIds: Array<string>,
    makeProceduresVisible?: boolean,
  ) {
    publishProcedure(
      {
        accountId: accountId,
        procedureId: procedureDetails?.AccountProcedure.ProcedureId || 0,
        params: [...procFileIds],
        makeProceduresVisible: makeProceduresVisible,
      },
      {
        onSuccess: (mutationResult: ProcedureDetailsType) => {
          if (mutationResult) {
            procedures.forEach(item => {
              if (
                item.ProcedureId ===
                procedureDetails?.AccountProcedure.ProcedureId
              ) {
                item.IsPublished = false;
              }
              return item;
            });
            showSuccessToast({
              message: 'Procedure(s) Published Successfully.',
            });
          }
        },
        onError: (error: { Message: string }) => {
          showErrorToast({
            message: error.Message,
          });
        },
      },
    );
  }
  //*********SINGLE PUBLISH******************************************************************//
  //Do not show again checkbox//
  const handleSinglePublishModalVisiblity = (e: any) => {
    if (e.target.checked === true) {
      localStorage.setItem('publishConfirmation', JSON.stringify(true));
    } else {
      localStorage.removeItem('publishConfirmation');
    }
  };
  type ParamsType = {
    AccountProcedure: {
      Procedure: {
        FileId: string;
      };
      ProcedureId: number;
    };
    Content: string;
  };
  //Function to save,check visibility,broken link presence and publish
  const confirmationSinglePublish = ({
    saveParams,
    brokenLinkProcedure,
    procFileIds,
    makeProceduresVisible,
  }: {
    saveParams: ParamsType;
    brokenLinkProcedure: ProcedureListType | undefined;
    procFileIds: Array<string>;
    makeProceduresVisible?: boolean;
  }) => {
    saveProcedure(
      {
        accountId: accountId,
        params: { ...saveParams },
        refetchList: false,
      },
      {
        onSuccess: (mutationResult: string) => {
          setUserTyped(false);
          setEditorChanges(false);
          if (mutationResult) {
            if (brokenLinkProcedure) {
              confirmAlert({
                message:
                  'You have broken links in the procedure(s). Do you want to continue?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
                      if (makeProceduresVisible)
                        publishProcedureApi(procFileIds, makeProceduresVisible);
                      else publishProcedureApi(procFileIds);
                    },
                    className: 'pr-btn-primary',
                  },
                  {
                    label: 'No',
                    onClick: () => {
                      //
                    },
                  },
                ],
              });
            } else {
              if (makeProceduresVisible)
                publishProcedureApi(procFileIds, makeProceduresVisible);
              else publishProcedureApi(procFileIds);
            }
          } else {
            showErrorToast({
              message: 'Something went wrong.',
            });
          }
        },
        onError: (error: { Message: string }) => {
          showErrorToast({
            message: error.Message,
          });
        },
      },
    );
  };
  // Main function to handle single publish
  function handlePublishSingleProcedure() {
    const tempValue = localStorage.getItem('publishConfirmation');
    const showConfirmation = tempValue === null ? false : JSON.parse(tempValue);
    lazyRun(() => {
      // cancels all scheduled auto saves
    }, 0);
    let info = '';
    if (mode === 'Edit') {
      if ((window as any)?.tinymce?.activeEditor?.initialized === true) {
        info = `<!DOCTYPE html><html><head><title></title><base href='/api/procedures/V1/accountfolders/${
          procedureDetails?.AccountProcedure?.AccountId
        }/Policies/'></head><body>${(
          window as any
        )?.tinymce?.activeEditor?.getContent()}</body></html>`;
      }
      setContent(info);
    }
    if (selectedProcedures && selectedProcedures.length >= 1) {
      const pList = procedureList?.filter((item: any) =>
        selectedProcedures.find(id => item.ProcedureId === id),
      );
      const procFileIds =
        pList && pList.map((item: any) => item.Procedure.FileId);
      const brokenLinkProcedure =
        pList && pList.find((item: any) => item.BrokenLinksCount > 0);
      const saveParams = {
        AccountProcedure: {
          Procedure: {
            FileId: procedureDetails?.AccountProcedure.Procedure.FileId || '',
          },
          ProcedureId: procedureDetails?.AccountProcedure.ProcedureId || 0,
        },
        Content: mode === 'Edit' ? info || '' : content || '',
      };
      const versionLength =
        procedureDetails?.AccountProcedure?.Procedure?.Versions?.length;
      if (procFileIds)
        if (versionLength !== undefined && versionLength > 0) {
          if (!showConfirmation) {
            confirmAlert({
              message:
                'This will finalize the current version and make it available to Proteus Call Center. Previous versions available in Revision History.',
              dontShowAgain: true,
              handleModalVisibility: handleSinglePublishModalVisiblity,
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => {
                    confirmationSinglePublish({
                      saveParams,
                      brokenLinkProcedure,
                      procFileIds,
                    });
                  },
                  className: 'pr-btn-primary',
                },
                {
                  label: 'Cancel',
                  onClick: () => {
                    //
                  },
                },
              ],
            });
          } else {
            confirmationSinglePublish({
              saveParams,
              brokenLinkProcedure,
              procFileIds,
            });
          }
        } else {
          confirmAlert({
            buttonOrder: true,
            message:
              'You are about to publish one or more procedures for the first time. Would you also like to add those new procedures to the Policies Menu?',
            buttons: [
              {
                label: 'Cancel',
                onClick: () => {
                  //
                },
                className: 'pr-btn-secondary',
              },
              {
                label: 'No',
                onClick: () => {
                  confirmationSinglePublish({
                    saveParams,
                    brokenLinkProcedure,
                    procFileIds,
                    makeProceduresVisible: false,
                  });
                },
              },
              {
                label: 'Yes',
                onClick: () => {
                  confirmationSinglePublish({
                    saveParams,
                    brokenLinkProcedure,
                    procFileIds,
                    makeProceduresVisible: true,
                  });
                },
                className: 'pr-btn-primary',
              },
            ],
          });
        }
    }
  }
  //*********SINGLE PUBLISH******************************************************************//

  //*********MULTIPLE PUBLISH******************************************************************//
  //Do not show again checkbox//
  const handleMultiplePublishModalVisiblity = (e: any) => {
    if (e.target.checked === true) {
      localStorage.setItem('publishConfirmation', JSON.stringify(true));
    } else {
      localStorage.removeItem('publishConfirmation');
    }
  };
  const confirmMultiplePublish = ({
    brokenLinkProcedure,
    procFileIds,
    makeProceduresVisible,
  }: {
    brokenLinkProcedure: Array<ProcedureListType> | undefined;
    procFileIds: Array<string>;
    makeProceduresVisible?: boolean;
  }) => {
    setTimeout(() => {
      if (brokenLinkProcedure && brokenLinkProcedure.length >= 1) {
        confirmAlert({
          message:
            'You have broken links in the procedure(s). Do you want to continue?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                if (makeProceduresVisible)
                  publishProcedureApi(procFileIds, makeProceduresVisible);
                else publishProcedureApi(procFileIds);
              },
              className: 'pr-btn-primary',
            },
            {
              label: 'No',
              onClick: () => {
                //
              },
            },
          ],
        });
      } else {
        if (makeProceduresVisible)
          publishProcedureApi(procFileIds, makeProceduresVisible);
        else publishProcedureApi(procFileIds);
      }
    }, 10);
  };
  function handlePublishMultipleProcedures() {
    const tempValue = localStorage.getItem('publishConfirmation');
    const showConfirmation = tempValue === null ? false : JSON.parse(tempValue);
    if (selectedProcedures && selectedProcedures.length >= 1) {
      const pList = procedureList?.filter((item: any) =>
        selectedProcedures.find(id => item.ProcedureId === id),
      );
      const procFileIds =
        pList && pList.map((item: any) => item.Procedure.FileId);
      const versionHistory =
        pList &&
        pList.filter((item: any) => {
          if (item?.Procedure?.Versions.length) return item;
        });

      const brokenLinkProcedure =
        pList && pList.filter((item: any) => item.BrokenLinksCount > 0);
      if (procFileIds)
        if (selectedProcedures?.length === versionHistory?.length) {
          if (!showConfirmation) {
            confirmAlert({
              dontShowAgain: true,
              handleModalVisibility: handleMultiplePublishModalVisiblity,
              message:
                'This will finalize the current version and make it available to Proteus Call Center. Previous versions available in Revision History.',
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => {
                    confirmMultiplePublish({
                      brokenLinkProcedure,
                      procFileIds,
                    });
                  },

                  className: 'pr-btn-primary',
                },
                {
                  label: 'Cancel',
                  onClick: () => {
                    //
                  },
                },
              ],
            });
          } else {
            confirmMultiplePublish({
              brokenLinkProcedure,
              procFileIds,
            });
          }
        } else {
          confirmAlert({
            buttonOrder: true,
            message:
              'You are about to publish one or more procedures for the first time. Would you also like to add those new procedures to the Policies Menu?',
            buttons: [
              {
                label: 'Cancel',
                onClick: () => {
                  //
                },
                className: 'pr-btn-secondary',
              },
              {
                label: 'No',
                onClick: () => {
                  confirmMultiplePublish({
                    brokenLinkProcedure,
                    procFileIds,
                    makeProceduresVisible: false,
                  });
                },
              },
              {
                label: 'Yes',
                onClick: () => {
                  confirmMultiplePublish({
                    brokenLinkProcedure,
                    procFileIds,
                    makeProceduresVisible: true,
                  });
                },
                className: 'pr-btn-primary',
              },
            ],
          });
        }
    }
  }
  //*********MULTIPLE PUBLISH******************************************************************//

  /*********PUBLISH******************************************************************/

  function handleConvertToGlobalProcedure() {
    const procIds = selectedProcedures && [...selectedProcedures];
    if (procIds)
      confirmAlert({
        message: 'Are you sure you want to change the procedure(s) to global?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              convertToGlobalProcedure(
                {
                  procedureIds: [...procIds],
                  accountId: accountId,
                },
                {
                  onSuccess: (mutationResult: any) => {
                    if (mutationResult && mutationResult instanceof Array)
                      showErrorToast({
                        message: mutationResult[0],
                      });
                    else {
                      showSuccessToast({
                        message: 'Your changes were saved successfully.',
                      });
                    }
                  },
                  onError: (error: { message: string }) => {
                    showErrorToast({
                      message: error.message,
                    });
                  },
                },
              );
            },
            className: 'pr-btn-primary',
          },
          {
            label: 'No',
            onClick: () => {
              //
            },
          },
        ],
      });
  }

  const handleConvertToNormalProcedure = () => {
    const procIds = selectedProcedures && [...selectedProcedures];

    if (procIds) {
      confirmAlert({
        message:
          'Do you really want to convert this global procedure(s) to normal?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              convertToNormalProcedure(
                {
                  procedureIds: [...procIds],
                  accountId: accountId,
                },
                {
                  onSuccess: () => {
                    showSuccessToast({
                      message: 'Your changes were saved successfully. ',
                    });
                    setSelectedProcedures([]);
                  },
                  onError: (error: { message: string }) => {
                    showErrorToast({
                      message: error.message,
                    });
                  },
                },
              );
            },
            className: 'pr-btn-primary',
          },
          {
            label: 'No',
            onClick: () => {
              //
            },
          },
        ],
      });
    }
  };

  const handleDeleteProcedures = () => {
    const procIds = selectedProcedures && [...selectedProcedures];
    if (procIds)
      confirmAlert({
        message: 'Are you sure you want to archive the procedure(s)?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              getProcedureReference(
                {
                  procedureIds: [...procIds],
                  isRemove: true,
                  accountId: accountId,
                },
                {
                  onSuccess: (mutationResult: Array<string>) => {
                    if (
                      mutationResult &&
                      mutationResult.length &&
                      (mutationResult.length > 1 ||
                        !mutationResult[0].includes('0000_POLICIESMENU.html'))
                    ) {
                      confirmAlert({
                        message:
                          'This procedure has been linked to other procedures. Are you sure you want to archive the procedure?',
                        buttons: [
                          {
                            label: 'Yes',
                            onClick: () => {
                              handleDeleteProcedureApi(procIds);
                            },
                            className: 'pr-btn-primary',
                          },
                          {
                            label: 'No',
                            onClick: () => {
                              //
                            },
                          },
                        ],
                      });
                    } else {
                      handleDeleteProcedureApi(procIds);
                    }
                  },
                  onError: (error: { message: string }) => {
                    showErrorToast({
                      message: error.message,
                    });
                  },
                },
              );
            },
            className: 'pr-btn-primary',
          },
          {
            label: 'No',
            onClick: () => {
              //
            },
          },
        ],
      });
  };

  // delete procedure fn and api
  function handleDeleteProcedureApi(procIds: Array<number>) {
    deleteProcedure(
      {
        procedureIds: [...procIds],
        accountId: accountId,
      },
      {
        onSuccess: () => {
          if (procedureList)
            setSelectedProcedures([procedureList[0].ProcedureId]);
          showSuccessToast({
            message: 'Your changes were saved successfully.',
          });
          setSearchValue('');
        },
        onError: (error: { message: string }) => {
          showErrorToast({
            message: error.message,
          });
        },
      },
    );
  }
  const accountNumber = `${accountInfo?.accountInfoReturn?.CID}${'-'}${
    accountInfo?.accountInfoReturn?.SID
  }${'-'}${accountInfo?.accountInfoReturn?.PID}`;

  const handleDemoCall = () => {
    if (isPublished && isPublished.length > 0) {
      confirmAlert({
        message:
          'Some procedures are not yet published. Do you want to continue?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              window.open(
                `${process.env.REACT_APP_CALLCENTER_REDIRECT_URL}/Calls/Call/00000000-0000-0000-0000-000000000000?accountId=${accountId}&accountNumber=${accountNumber}&isFromProcedure=true`,
              );
            },
            className: 'pr-btn-primary',
          },
          {
            label: 'No',
            onClick: () => {
              //
            },
          },
        ],
      });
    } else {
      window.open(
        `${process.env.REACT_APP_CALLCENTER_REDIRECT_URL}/Calls/Call/00000000-0000-0000-0000-000000000000?accountId=${accountId}&accountNumber=${accountNumber}&isFromProcedure=true`,
      );
    }
  };

  function handlePageActionClick(funcName: () => void, conversion?: boolean) {
    let content = '';
    if ((window as any)?.tinymce?.activeEditor?.initialized === true) {
      content = `<!DOCTYPE html><html><head><title></title><base href='/api/procedures/V1/accountfolders/${
        procedureDetails?.AccountProcedure?.AccountId
      }/Policies/'></head><body>${(
        window as any
      )?.tinymce?.activeEditor?.getContent()}</body></html>`;
    }
    let unsavedChanges = false;
    let isActive;
    if (userTyped === true && procedureDetails && content)
      unsavedChanges = content !== procedureDetails?.Content;
    if (unsavedChanges || (conversion && isLatestProcedurePublished)) {
      lazyRun(() => {
        //
      }, 0);
      confirmAlert({
        message: conversion
          ? 'Unpublished changes are present do you want to publish the changes?'
          : 'Unsaved changes are present do you want to save the changes?',
        buttons: [
          {
            label: 'Yes',
            onClick: () =>
              saveContent(() => {
                funcName();
                setUserTyped(false);
                setEditorChanges(false);
                if (conversion) {
                  procedures.forEach(item => {
                    if (
                      item.ProcedureId ===
                      procedureDetails?.AccountProcedure.ProcedureId
                    ) {
                      item.IsPublished = false;
                    }
                    return item;
                  });
                } else
                  procedures.forEach(item => {
                    if (
                      item.ProcedureId ===
                      procedureDetails?.AccountProcedure.ProcedureId
                    ) {
                      if (
                        (!item.IsLinked || item.IsParentLinked) &&
                        !item.Procedure?.IsGlobal
                      ) {
                        item.IsPublished = true;
                      }
                    }
                    return item;
                  });
              }, conversion),
            className: 'pr-btn-primary',
          },
          {
            label: 'No',
            onClick: () => {
              if (procedureDetails) {
                if (conversion) isActive = true;
                else isActive = procedureDetails.AccountProcedure.IsActive;
                updateProcedureDetails({
                  ...procedureDetails.AccountProcedure,
                  IsActive: isActive,
                  Procedure: {
                    ...procedureDetails.AccountProcedure.Procedure,
                    IsAutoSave:
                      !procedureDetails.AccountProcedure.Procedure.IsAutoSave,
                  },
                });
              }
              setTimeout(() => {
                funcName();
              }, 10);
            },
          },
        ],
      });
    } else funcName();
  }

  const handleSavePdf = () => {
    let content = '';
    if ((window as any)?.tinymce?.activeEditor?.initialized === true) {
      content = `<!DOCTYPE html><html><head><title>test</title><base href='/api/procedures/V1/accountfolders/${
        procedureDetails?.AccountProcedure?.AccountId
      }/Policies/'></head><body>${(
        window as any
      )?.tinymce?.activeEditor?.getContent()}</body></html>`;
    }
    setContent(content);
    let payload = '';
    const pList =
      selectedProcedures &&
      procedureList?.filter((item: any) =>
        selectedProcedures.find(id => item.ProcedureId === id),
      );

    pList &&
      pList.forEach((procedure: any) => {
        payload =
          payload + ('fileIds=' + procedure.Procedure.FileId.trim() + '&');
      });

    const pListName =
      pList?.length === 1
        ? procedureDetails?.AccountProcedure.Procedure.Name.replace(
            /.html/g,
            '',
          )
        : 'procedure';
    saveAsPDF({ payload, accountId, pListName });
  };

  const handleDownloadHtml = () => {
    let payload = '';
    const pList =
      selectedProcedures &&
      procedureList?.filter((item: any) =>
        selectedProcedures.find(id => item.ProcedureId === id),
      );
    pList &&
      pList.forEach((procedure: any) => {
        payload =
          payload + ('fileIds=' + procedure.Procedure.FileId.trim() + '&');
      });
    window.open(
      `${apiBaseUrl}${'accounts/'}${accountId}${'/procedure/downloadhtml?'}${payload}`,
      '_blank',
    );
  };

  const handleDownloadAsWord = () => {
    let payload = '';
    const pList =
      selectedProcedures &&
      procedureList?.filter((item: any) =>
        selectedProcedures.find(id => item.ProcedureId === id),
      );
    pList &&
      pList.forEach((procedure: any) => {
        payload =
          payload + ('fileIds=' + procedure.Procedure.FileId.trim() + '&');
      });
    let singleProcedureName = '';
    if (pList?.length === 1) {
      pList &&
        pList.forEach((procedure: any) => {
          singleProcedureName = procedure.Procedure.Name.replace('.html', '');
        });
    }
    saveAsWord(
      { payload, accountId },
      {
        onSuccess: (response: any) => {
          const header =
            "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
            "xmlns:w='urn:schemas-microsoft-com:office:word' " +
            "xmlns='http://www.w3.org/TR/REC-html40'>" +
            "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
          const footer = '</body></html>';
          const sourceHTML = header + response + footer;
          const source =
            'data:application/vnd.ms-word;charset=utf-8,' +
            encodeURIComponent(sourceHTML);
          const fileDownload = document.createElement('a');
          document.body.appendChild(fileDownload);
          fileDownload.href = source;
          if (pList?.length === 1)
            fileDownload.download = `${singleProcedureName}.doc`;
          else fileDownload.download = 'procedure.doc';
          fileDownload.click();
          document.body.removeChild(fileDownload);
        },
      },
    );
  };

  const checkVisibilityStatus = () => {
    let pList: Array<ProcedureListType> | undefined = [];
    let value = '';
    pList =
      selectedProcedures &&
      procedureList?.filter((item: any) => {
        return selectedProcedures.find(id => item.ProcedureId === id);
      });
    if (pList?.every(item => item.IsVisible === true)) {
      value = 'invisible';
    } else if (pList?.every(item => item.IsVisible === false)) {
      value = 'visible';
    } else {
      value = 'visible';
    }
    return value;
  };

  /*********MULTIPLE PROCEDURE VISIBILITY TOGGLE********************************************/
  //Do not show again checkbox//
  const handleMultipleToggleModalVisiblity = (e: any) => {
    if (e.target.checked === true) {
      localStorage.setItem(
        'multipleVisibilityModalConfirmation',
        JSON.stringify(true),
      );
    } else {
      localStorage.removeItem('multipleVisibilityModalConfirmation');
    }
  };
  //Function to handle apis
  const confirmMultipleVisibilityToggle = ({
    selectedProcedures,
    visibility,
    accountId,
  }: {
    selectedProcedures: Array<number> | undefined;
    visibility: boolean;
    accountId: number;
  }) => {
    updateMultipleProcedureVisibility(
      {
        selectedProcedures,
        visibility,
        accountId,
      },
      {
        onSuccess: () => {
          setSelectedProcedures([]);
        },
      },
    );
  };

  const changeMultipleProcedureVisibility = (
    visibility: boolean,
    pList: any,
  ) => {
    const tempValue = localStorage.getItem(
      'multipleVisibilityModalConfirmation',
    );
    const showConfirmation = tempValue === null ? false : JSON.parse(tempValue);
    let procedureIds: Array<number> | undefined = [];
    if (visibility === true) {
      const invisibleList =
        pList && pList.filter((item: any) => item.IsVisible !== true);
      procedureIds = invisibleList.map((item: any) => item.ProcedureId);
    } else {
      procedureIds = selectedProcedures;
    }
    const linkedProcedures =
      pList && pList.filter((item: any) => item.IsLinked);
    const isFirstTimeProcedure =
      pList &&
      pList.filter((item: any) => {
        if (!item?.Procedure?.Versions.length && !item?.IsVisible) return item;
      });
    const globalProcedures =
      pList && pList.filter((item: any) => item?.Procedure?.IsGlobal);

    if (selectedProcedures)
      if (!showConfirmation) {
        confirmAlert({
          dontShowAgain: isFirstTimeProcedure.length ? false : true,
          handleModalVisibility: handleMultipleToggleModalVisiblity,
          message: isFirstTimeProcedure?.length
            ? 'One or more of the selected procedures have never been published and are not yet available to users. In order to add them to the Policies Menu, all such procedures will also be published. Proceed? '
            : visibility === true
            ? 'This procedure(s) will be displayed in the Policies Menu.'
            : 'This procedure(s) will be removed from the Policies Menu, but procedures will still be able to link to it.',
          buttons: [
            {
              label: 'Ok',
              onClick: () => {
                setTimeout(() => {
                  if (
                    linkedProcedures.length > 0 ||
                    globalProcedures.length > 0
                  ) {
                    confirmAlert({
                      message:
                        'You are about to change the visibility of a global and/or linked procedure(s). This change would affect all the other accounts to which it has been added. Do you want to proceed?',
                      buttons: [
                        {
                          label: 'Yes',
                          onClick: () => {
                            confirmMultipleVisibilityToggle({
                              selectedProcedures: procedureIds,
                              visibility,
                              accountId,
                            });
                          },

                          className: 'pr-btn-primary',
                        },
                        {
                          label: 'No',
                          onClick: () => {
                            //
                          },
                        },
                      ],
                    });
                  } else {
                    confirmMultipleVisibilityToggle({
                      selectedProcedures: procedureIds,
                      visibility,
                      accountId,
                    });
                  }
                }, 10);
              },
              className: 'pr-btn-primary',
            },
            {
              label: 'Cancel',
              onClick: () => {
                //
              },
            },
          ],
        });
      } else {
        if (linkedProcedures.length > 0 || globalProcedures.length > 0) {
          confirmAlert({
            message:
              'You are about to change the visibility of a global and/or linked procedure(s). This change would affect all the other accounts to which it has been added. Do you want to proceed?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                  confirmMultipleVisibilityToggle({
                    selectedProcedures: procedureIds,
                    visibility,
                    accountId,
                  });
                },

                className: 'pr-btn-primary',
              },
              {
                label: isFirstTimeProcedure?.length ? 'Cancel' : 'No',
                onClick: () => {
                  //
                },
              },
            ],
          });
        } else {
          confirmMultipleVisibilityToggle({
            selectedProcedures: procedureIds,
            visibility,
            accountId,
          });
        }
      }
  };

  /*********MULTIPLE PROCEDURE VISIBILITY TOGGLE*******************************************/

  const pageActions = () => {
    const pList =
      selectedProcedures &&
      procedureList?.filter((item: any) =>
        selectedProcedures.find(id => item.ProcedureId === id),
      );
    const checkIfPoliciesMenu = pList?.some(
      (item: any) => item.Procedure.Name === '0000_POLICIESMENU.html',
    );

    return [
      {
        action: `${
          checkVisibilityStatus() === 'visible'
            ? 'Mark as Visible'
            : 'Mark as Invisible'
        }`,
        icon:
          checkVisibilityStatus() === 'visible' ? (
            <Icon.Eye title=" " />
          ) : (
            <Icon.EyeOff title=" " />
          ),
        handleButtonClick: () =>
          checkVisibilityStatus() === 'visible'
            ? changeMultipleProcedureVisibility(true, pList)
            : changeMultipleProcedureVisibility(false, pList),
        //disabled temporarily to prevent worsening of an link issue
        disabled: true,
        // disabled: pList?.length === 0 || checkIfPoliciesMenu ? true : false,
        //disabled temporarily to prevent worsening of an link issue
      },
      {
        action: 'Broken Links',
        icon: <Icon.Link title=" " />,
        handleButtonClick: toggleBrokenLinks,
      },
      {
        action: `${
          pList && pList[0]?.Procedure.IsGlobal === false
            ? 'Convert to global'
            : 'Convert to normal'
        }`,
        icon:
          pList && pList[0]?.Procedure.IsGlobal === false ? (
            <Icon.Globe />
          ) : (
            <Icon.ConvertToLocal />
          ),
        disabled:
          pList && pList[0]?.Procedure.IsGlobal === false
            ? isConvertableToGlobal()
            : isConvertableToNormal(),
        handleButtonClick: () =>
          pList && pList[0]?.Procedure.IsGlobal === false
            ? handlePageActionClick(handleConvertToGlobalProcedure, true)
            : handlePageActionClick(handleConvertToNormalProcedure, true),
      },
      {
        action: 'Clone',
        icon: <Icon.Copy title=" " />,
        disabled:
          selectedProcedures && selectedProcedures.length >= 1 ? false : true,
        handleButtonClick: () => handlePageActionClick(handleCloneProcedure),
      },
      {
        action: 'Archive',
        icon: <Icon.Archive />,
        disabled:
          (selectedProcedures && selectedProcedures.length === 0) ||
          checkIfPoliciesMenu
            ? true
            : false,
        handleButtonClick: () => {
          // handleDeleteProcedures();
          handlePageActionClick(handleDeleteProcedures);
        },
      },
      {
        action: 'Save as PDF',
        icon: <Icon.PDF />,
        disabled:
          selectedProcedures && selectedProcedures.length >= 1 ? false : true,
        handleButtonClick: () => handlePageActionClick(handleSavePdf),
      },
      {
        action: 'Save as HTML',
        icon: <Icon.Code />,
        disabled:
          selectedProcedures && selectedProcedures.length >= 1 ? false : true,
        handleButtonClick: () => handlePageActionClick(handleDownloadHtml),
      },
      {
        action: 'Save as Doc',
        icon: <Icon.File />,
        disabled:
          selectedProcedures && selectedProcedures.length >= 1 ? false : true,
        handleButtonClick: () => handlePageActionClick(handleDownloadAsWord),
      },
      {
        action: 'Demo call',
        icon: <Icon.Call />,
        disabled: false,
        handleButtonClick: () => handlePageActionClick(handleDemoCall),
      },
      {
        action: 'Publish',
        icon: <Icon.ExternalLink />,
        disabled:
          selectedProcedures && selectedProcedures.length >= 1 ? false : true,
        handleButtonClick: () =>
          selectedProcedures && selectedProcedures.length === 1
            ? handlePublishSingleProcedure()
            : handlePublishMultipleProcedures(),
      },
    ];
  };

  function selectProcedure(
    isAppend: boolean,
    procedureItem: ProcedureListType,
    doubleClicked?: boolean,
  ) {
    let content = '';
    if ((window as any)?.tinymce?.activeEditor?.initialized === true) {
      content = `<!DOCTYPE html><html><head><title></title><base href='/api/procedures/V1/accountfolders/${
        procedureDetails?.AccountProcedure?.AccountId
      }/Policies/'></head><body>${(
        window as any
      )?.tinymce?.activeEditor?.getContent()}</body></html>`;
    }
    const isDirty =
      userTyped === true &&
      selectedProcedures?.length === 1 &&
      content !== procedureDetails?.Content;

    const setSelected = () => {
      const isSelected =
        selectedProcedures &&
        selectedProcedures.find(item => item === procedureItem.ProcedureId);

      let newSelections = selectedProcedures ? [...selectedProcedures] : [];
      if (isSelected && !doubleClicked) {
        newSelections = newSelections.filter(
          item => item !== procedureItem.ProcedureId,
        );
      } else if (isAppend) {
        newSelections = [...newSelections, procedureItem.ProcedureId];
      } else newSelections = [procedureItem.ProcedureId];
      setSelectedProcedures([...newSelections]);
      // setRenameProc(undefined);(chnage made for new flow for changing selection of procedure by clicking on rename)
    };

    if (isDirty) {
      if (procedureDetails?.AccountProcedure.Procedure.IsAutoSave) {
        lazyRun(() => {
          //
        }, 0);
        saveContent(() => {
          setSelected();
          setUserTyped(false);
          setEditorChanges(false);
          procedures.forEach(item => {
            if (
              item.ProcedureId ===
              procedureDetails.AccountProcedure.Procedure?.Id
            ) {
              if (
                (!item.IsLinked || item.IsParentLinked) &&
                !item.Procedure?.IsGlobal
              ) {
                item.IsPublished = true;
              }
            }
            return item;
          });
        });
      } else {
        lazyRun(() => {
          //
        }, 0);
        confirmAlert({
          message:
            'Unsaved changes are present do you want to save the changes?',
          buttons: [
            {
              label: 'Yes',
              onClick: () =>
                saveContent(() => {
                  setSelected();
                  setUserTyped(false);
                  setEditorChanges(false);
                  procedures.forEach(item => {
                    if (
                      item.ProcedureId ===
                      procedureDetails?.AccountProcedure?.Procedure?.Id
                    ) {
                      if (
                        (!item.IsLinked || item.IsParentLinked) &&
                        !item.Procedure?.IsGlobal
                      ) {
                        item.IsPublished = true;
                      }
                    }
                    return item;
                  });
                }),
              className: 'pr-btn-primary',
            },
            {
              label: 'No',
              onClick: () => {
                setSelected();
              },
            },
          ],
        });
      }
    } else {
      setSelected();
    }
  }

  const lastEditedPerson = useMemo(() => {
    const pList = procedureList?.filter(
      (item: any) =>
        selectedProcedures &&
        selectedProcedures.find(id => item.ProcedureId === id),
    );
    if (
      pList &&
      pList.length === 1 &&
      pList[0].Procedure.Versions !== null &&
      pList[0].Procedure.Versions.length > 0
    ) {
      const versions = pList[0].Procedure?.Versions;
      return versions[versions.length - 1].CreatedBy;
    }
  }, [procedureList, selectedProcedures]);

  const lastEditedTime = useMemo(() => {
    const pList = procedureList?.filter(
      (item: any) =>
        selectedProcedures &&
        selectedProcedures.find(id => item.ProcedureId === id),
    );
    if (
      pList &&
      pList.length === 1 &&
      pList[0].Procedure.Versions !== null &&
      pList[0].Procedure.Versions.length > 0
    ) {
      const versions = pList[0].Procedure?.Versions;
      const date = dayjs
        .utc(versions[versions.length - 1].CreatedOn)
        .local()
        .format('MMM  DD, YYYY');
      const time = dayjs
        .utc(versions[versions.length - 1].CreatedOn)
        .local()
        .format('h:mm A');
      return {
        date,
        time,
      };
    }
  }, [procedureList, selectedProcedures]);

  const isLatestProcedurePublished = useMemo(() => {
    const pList = procedureList?.filter(
      (item: any) =>
        selectedProcedures &&
        selectedProcedures.find(id => item.ProcedureId === id),
    );
    if (
      pList &&
      pList.length === 1 &&
      pList[0].Procedure.Versions !== null &&
      pList[0].Procedure.Versions.length > 0
    ) {
      const versions = pList[0].Procedure?.Versions;
      if (
        pList[0].Procedure?.LastUpdatedOn >
        versions[versions.length - 1].CreatedOn
      )
        return true;
      else return false;
    } else if (pList && pList[0]?.Procedure.Versions.length === 0) {
      return true;
    }
  }, [procedureList, selectedProcedures]);

  const checkIsPublished = (procedureItem: ProcedureListType) => {
    if (
      (!procedureItem.IsLinked || procedureItem.IsParentLinked) &&
      !procedureItem.Procedure?.IsGlobal
    ) {
      if (procedureItem.Procedure.Versions.length === 0) {
        return true;
      } else {
        const seconds =
          (new Date(procedureItem.Procedure.LastUpdatedOn).getTime() -
            new Date(
              procedureItem.Procedure.Versions[
                procedureItem.Procedure.Versions.length - 1
              ].CreatedOn,
            ).getTime()) /
          1000;
        if (Math.floor(seconds) > 0) return true;
        else return false;
      }
    } else {
      if (procedureItem.IsPublished) {
        if (
          (!procedureItem.IsLinked || procedureItem.IsParentLinked) &&
          !procedureItem.Procedure?.IsGlobal
        ) {
          return true;
        }
        return false;
      }
      return false;
    }
  };

  useEffect(() => {
    setRenameProc(undefined);
  }, [searchValue]);

  useEffect(() => {
    let newProcedures: Array<ProcedureListType> = [];
    if (procedureList && procedureList?.length > 0) {
      const sorted = procedureList?.sort((a, b) =>
        a.Procedure.Name.split('_')
          .join('')
          .localeCompare(b.Procedure.Name.split('_').join('')),
      );
      if (sorted) newProcedures = [...sorted];
      if (searchValue) {
        newProcedures = newProcedures.filter(item => {
          const isSerchedItem = item.Procedure.Name.toLowerCase().includes(
            searchValue.toLowerCase(),
          );
          return isSerchedItem;
        });
      }
      // newProcedures.forEach(item => {
      //   item.IsPublished = false;
      // });
      if (appliedFilterOption) {
        if (appliedFilterOption === 'Procedures with broken links')
          newProcedures = newProcedures.filter(item => {
            const filteredItem = item.BrokenLinksCount > 0;
            return filteredItem;
          });
        if (appliedFilterOption === 'Linked procedures')
          newProcedures = newProcedures.filter(item => {
            const filteredItem = item.IsLinked === true;
            return filteredItem;
          });
        if (appliedFilterOption === 'Global procedures')
          newProcedures = newProcedures.filter(item => {
            const filteredItem = item.Procedure?.IsGlobal === true;
            return filteredItem;
          });
        if (appliedFilterOption === 'Visible procedures')
          newProcedures = newProcedures.filter(item => {
            const filteredItem = item.IsVisible === true;
            return filteredItem;
          });
        if (appliedFilterOption === 'Invisible procedures')
          newProcedures = newProcedures.filter(item => {
            const filteredItem = item.IsVisible === false;
            return filteredItem;
          });
        if (appliedFilterOption === 'Un-published procedures')
          newProcedures = newProcedures.filter(item => {
            const filteredItem = checkIsPublished(item) || item.IsPublished;
            if (filteredItem === true) return item;
          });
      }
    }
    setProcedures([...newProcedures]);
  }, [procedureList, searchValue, appliedFilterOption]);

  useEffect(() => {
    localStorage.removeItem('fromSearch');
    if (procedureList && procedureList?.length > 0 && !selectedProcedures) {
      queryCache.removeQueries(
        ['get_procedure', accountId, procedureList[0]?.ProcedureId],
        { exact: true },
      );
      setSelectedProcedures([procedureList[0]?.ProcedureId]);
    }
  }, [procedureList, selectedProcedures, accountId]);

  const [createFolder, { isLoading: isCreateFolderLoading }] =
    useCreateFolder();
  const [uploadFile, { isLoading: isuploadFileLoading }] = useUploadFile();
  const [deleteFile, { isLoading: isDeleteFileLoading }] = useDeleteFile();

  const isLoading =
    isAccountInfoLoading ||
    isProcedureLoading ||
    isProcedureVisibilityLoading ||
    isProcedureDetailsLoading ||
    isProcedureReferenceLoading ||
    isDeleteProcedureLoading ||
    isUpdateProcedureDetails ||
    isConvertToGlobalProcedureLoading ||
    isConvertToNormalProcedureLoading ||
    isCloneProcedureLoading ||
    isPublishProcedureLoading ||
    isCreateProcedureLoading ||
    // isSaveProcedureLoading ||
    isProcedureDetailsLoading ||
    isDiscardProcedureContentLoading ||
    isRenameProcedureLoading ||
    isSaveLayoutLoading ||
    isSaveTableLoading ||
    isCreateFolderLoading ||
    isuploadFileLoading ||
    isMultipleProcedureVisibilityLoading ||
    isDeleteFileLoading ||
    isTemplateCleanupLoading ||
    isForceRenameProcedureLoading ||
    isBrokenLinksLoading ||
    isSavePDFLoading ||
    isSaveWordLoading;

  // useEffect(() => {
  //   setUserTyped(false);
  // }, [selectedProcedures]);
  // }, [procedureDetails, selectedProcedures]);(Removing procedure details since the updating on content was done using that it is no longer in use due to the new flow of save)

  return {
    brokenLinks,
    accountInfo,
    procedures,
    procedureDetails,
    selectedProcedures,
    searchValue,
    isPublished,
    renameProc,
    totalBrokenLinksCount,
    lastEditedPerson,
    lastEditedTime,
    procedureList,
    updateProcedureVisibility,
    setSelectedProcedures,
    getProcedureReference,
    updateProcedureDetails,
    renameProcedure,
    setProcedures,
    setSearchValue,
    setRenameProc,
    saveContent,
    pageActions,
    createProcedure,
    selectProcedure,
    discardProcedureContent,
    appliedFilterOption,
    setAppliedFilterOption,
    isLoading,
    setUserTyped,
    editorChanges,
    setEditorChanges,
    userTyped,
    content,
    setContent,
    saveLayoutApi,
    saveTableApi,
    createFolder,
    uploadFile,
    deleteFile,
    showBrokenLinks,
    toggleBrokenLinks,
    autoSaveContent,
    autoPublishPolicyContent,
    isSaveProcedureLoading,
    cleanTemplate,
    forceRenameProcedure,
    // unpublishedProcedure,
    // setUnpublishedProcedure,
  };
}
