/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  DependencyType,
  AssociatedPersonItem,
  MappedColumnArray,
  TemplateType,
} from './questionTypes';

export function getRenderTypes(selectedQuestionType: any): boolean {
  if (
    selectedQuestionType?.QuestionType?.Description === 'Lookup' ||
    selectedQuestionType?.QuestionType?.Description === 'Choice' ||
    selectedQuestionType?.QuestionType?.Description === 'Text' ||
    selectedQuestionType?.QuestionType?.Description === 'Link'
  )
    return true;
  else return false;
}

export function getQuestionTypeDetails(
  data: any,
  selectedQuestionType: any,
  mappedColumns: MappedColumnArray,
  lookupDependency: DependencyType,
): any {
  if (selectedQuestionType && !getRenderTypes(selectedQuestionType)) {
    const questionTypeOptions = {
      ...selectedQuestionType?.QuestionTypeOptions,
      Id: selectedQuestionType && selectedQuestionType?.QuestionTypeOptions?.Id, // can change in edit
      IsNeverSentToAccount: data?.IsNeverSentToAccount,
      IsNotSentToAccountIfNoAnswer: data?.IsNotSentToAccountIfNoAnswer,
      IsRequired: data?.IsRequired,
    };
    return questionTypeOptions;
  }
  if (selectedQuestionType?.QuestionType?.Description === 'Text') {
    const questionTypeOptions = {
      ...selectedQuestionType?.QuestionTypeOptions,
      Id: selectedQuestionType && selectedQuestionType?.QuestionTypeOptions?.Id, // can change in edit
      IsNeverSentToAccount: data?.IsNeverSentToAccount,
      IsNotSentToAccountIfNoAnswer: data?.IsNotSentToAccountIfNoAnswer,
      IsRequired: data?.IsRequired,
      TextQuestionTypeFormat: null,
      TextQuestionTypeFormatId: data?.TextQuestionTypeFormatId,
      Type: data?.Type,
      DefaultValue: data?.DefaultValue,
    };
    return questionTypeOptions;
  }
  if (selectedQuestionType?.QuestionType?.Description === 'Link') {
    const questionTypeOptions = {
      ...selectedQuestionType?.QuestionTypeOptions,
      Id: selectedQuestionType && selectedQuestionType?.QuestionTypeOptions?.Id, // can change in edit
      IsNeverSentToAccount: data?.IsNeverSentToAccount,
      IsNotSentToAccountIfNoAnswer: data?.IsNotSentToAccountIfNoAnswer,
      IsRequired: data?.IsRequired,
      Type: data?.Type,
      LinkDescription: data?.LinkDescription,
      Url: data?.Url,
    };
    return questionTypeOptions;
  }
  if (selectedQuestionType?.QuestionType?.Description === 'Lookup') {
    const questionTypeOptions = {
      $type:
        'Proteus2.Api.Domain.Surveys.QuestionTypes.LookupQuestionTypeOptions, Proteus2.Api.Domain',
      Id: selectedQuestionType && selectedQuestionType?.QuestionTypeOptions?.Id, // can change in edit
      IsNeverSentToAccount: data?.IsNeverSentToAccount,
      IsNotSentToAccountIfNoAnswer: data?.IsNotSentToAccountIfNoAnswer,
      IsRequired: data?.IsRequired,
      CustomLookup: null,
      CustomLookupId: data?.CustomLookupId || null,
      MappedLookupColumns: data?.CustomLookupId ? [...mappedColumns] : [],
      Dependencies: [...lookupDependency],
    };
    return questionTypeOptions;
  }
  if (selectedQuestionType?.QuestionType?.Description === 'Choice') {
    const questionTypeOptions = {
      ...selectedQuestionType?.QuestionTypeOptions,
      Id: selectedQuestionType && selectedQuestionType?.QuestionTypeOptions?.Id, // can change in edit
      IsNeverSentToAccount: data?.IsNeverSentToAccount,
      IsNotSentToAccountIfNoAnswer: data?.IsNotSentToAccountIfNoAnswer,
      IsRequired: data?.IsRequired,
      Type: data?.ChoiceType,
    };
    return questionTypeOptions;
  }
}

export function getPersonAssociation(
  data: any,
  associatedPersons: Array<AssociatedPersonItem>,
): number {
  const personAssociation = associatedPersons.find(
    item => item.id === data?.PersonAssociation,
  );
  let personAssociationId = -1;
  if (personAssociation && personAssociation.name === 'None')
    personAssociationId = 2;
  if (personAssociation && personAssociation.name === 'Caller')
    personAssociationId = 0;
  if (personAssociation && personAssociation.name === 'Person of concern')
    personAssociationId = 1;

  return personAssociationId;
}

export function getQuestionOptions(
  selectedQuestionType: any,
  questionTemplateOptions: TemplateType,
) {
  const tempOption = [...questionTemplateOptions];

  const questionOptions =
    tempOption &&
    tempOption
      .filter(tempitem => tempitem.IncludeByDefault === true)
      .map((tempItem: any) => {
        return {
          ...tempItem,
        };
      });
  if (selectedQuestionType?.QuestionType?.Description === 'Choice') {
    return questionOptions;
  } else return [];
}

export function formatQuestionOptionsData(
  questionTemplateOptions: any,
  receivedQuestionParams: any,
) {
  const tempQuestionTemplateOptions = questionTemplateOptions.map(
    (item: any) => {
      return {
        ...item,
        QuestionTemplateOptionId: item.QuestionTemplateOptionId
          ? item.QuestionTemplateOptionId
          : item.Id,
      };
    },
  );

  const formatQuestionOptions =
    tempQuestionTemplateOptions &&
    tempQuestionTemplateOptions.map((questionTemplateItem: any) => {
      const tempItem = receivedQuestionParams.find(
        (item: any) =>
          item.QuestionTemplateOptionId ===
          questionTemplateItem.QuestionTemplateOptionId,
      );
      if (!tempItem)
        return {
          ...questionTemplateItem,
          IncludeByDefault: false,
        };
      else
        return {
          ...questionTemplateItem,
          ...tempItem,
          IncludeByDefault: true,
        };
    });
  return formatQuestionOptions;
}
