import { useQuery, QueryResult } from 'react-query';
import { urlValidation } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchurlValidation({ url }: TVariables): Promise<TResult> {
  if (!url)
    return new Promise((resolve, reject) => {
      reject();
    });
  const response = await fetch(urlValidation(url), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = any;

export type TError = { message: string };
export type TVariables = {
  url: string;
};

function useGetUrlValidation(url: string): QueryResult<TResult, TError> {
  return useQuery(
    'get_url_validation',
    async () => {
      const fetchResponse = await fetchurlValidation({
        url,
      });
      return fetchResponse;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetUrlValidation };
