import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { FormLabel } from '../../components';

const StyledToggleLabel = styled(FormLabel)`
  /* containing label */
  /* border: 1px solid #565656; */
  display: block;
  /* overflow: hidden; */
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-right: 4em;
  position: relative;
  border-radius: ${({ theme }) => theme.radii.rounded};
  transition: background 200ms ease-in-out;

  /* &:hover {
    background: var(--medium-contrast-neutral);
  } */

  & .pr-toggle-label {
    color: ${({ theme }) => theme.colors.text.body};
  }

  /* using the before/after pseudo elements of the span to create the "switch" */
  & .pr-toggle-ui:before,
  & .pr-toggle-ui:after {
    border: 1px solid ${({ theme }) => theme.colors.border.default};
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: background 200ms ease-in-out;
  }

  /* styling specific to the knob of the switch */
  & .pr-toggle-ui:after {
    background: #fff;
    border-radius: 100%;
    height: 1.5em;
    right: 1.45em;
    transition: right 0.1825s cubic-bezier(0.65, 0.05, 0.36, 1);
    width: 1.5em;
  }

  /* styling specific to the knob "container" */
  & .pr-toggle-ui:before {
    background: var(--color-neutral-contrast-02);
    border-radius: 1.75em;
    height: 1.75em;
    right: 0.25em;
    transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
    width: 2.75em;
  }

  & input {
    height: 100%;
    left: 0;
    opacity: 0.0001;
    position: absolute;
    top: 0;
    width: 100%;
  }

  & input:not([role='button']) {
    pointer-events: none;
  }

  & input:focus + .pr-toggle-ui:before {
    outline: none;
    border: 1px solid
      hsl(var(--primary-h), var(--primary-s), calc(var(--primary-l) - 10%));
    box-shadow: 0 0 0 0.2rem
      hsla(var(--primary-h), var(--primary-s), var(--primary-l), 60%);
  }

  /* change the position of the knob to indicate it has been checked*/
  & input:checked + .pr-toggle-ui:after {
    right: 0.25em;
  }

  /* update the color of the "container" to further visually indicate state */
  & input:checked + .pr-toggle-ui:before {
    background: ${({ theme }) => theme.colors.primary};
  }

  & input:disabled:hover + .pr-toggle-ui {
    cursor: not-allowed;
  }

  /* 'color in' the switch knob in high contrast mode by giving it
        a large border */
  @media screen and (-ms-high-contrast: active) {
    & .pr-toggle-ui:after {
      background-color: windowText;
    }
  }
`;

export type ToggleProps = {
  label?: string;
  required?: boolean;
  htmlFor?: string;
  register?: ({ required }: { required?: boolean }) => RefReturn;
  name?: string;
  disabled?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  checked?: boolean;
};

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

function Toggle({
  required,
  // htmlFor,
  label,
  register,
  name,
  disabled,
  onChange,
  checked,
}: ToggleProps): ReactElement {
  // const id = `toggle-${label ? label.replace(/ /g, '').toLowerCase() : null}`;
  return (
    <StyledToggleLabel className="check-switch" htmlFor={name}>
      <span className="pr-toggle-label">{label}</span>
      <input
        ref={register && register({ required })}
        id={name}
        name={name}
        data-check-switch=""
        role="switch"
        type="checkbox"
        disabled={disabled}
        onClick={onChange}
        checked={checked}
      />
      <span className="pr-toggle-ui" aria-hidden="true"></span>
    </StyledToggleLabel>
  );
}

export { Toggle };
