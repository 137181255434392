import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { CopyAccount, AccountFormInputs } from 'types/accounts';
import { saveCopyAccount } from 'utils/urls';
import { getTokenWithType } from './useAuth';

export async function postCopyAccount({
  params,
}: {
  params: CopyAccount;
}): Promise<TResult> {
  const response = await fetch(saveCopyAccount, {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = AccountFormInputs & ValidationError;
export type TVariables = CopyAccount;
export type TError = {
  Message: string;
};
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;
// async function wait(millis: number) {
//   return new Promise(resolve => {
//     setTimeout(resolve, millis);
//   });
// }

function useSaveCopyAccount(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: CopyAccount) => {
      const newAccount = await postCopyAccount({
        params: params,
      });
      if (newAccount.Message) throw newAccount;
      queryCache.invalidateQueries(['accounts_list']);
      //   await wait(2000);
      return newAccount;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveCopyAccount };
