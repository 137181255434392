import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { confirmAlert } from 'utils/confirm-alert';
import { Dropdown } from 'ui/patterns';
import { Button } from 'ui/components';
import { ColumnInstance, Row } from 'react-table';
import * as Icon from 'assets/icons';
import { Contact } from 'types/contacts';
import { useDeleteContacts } from 'hooks';

export default function TableOptionsHeader(props: {
  column: ColumnInstance<Contact>;
  row: Row<Contact>;
  onEditPress?: (contactId: number) => void;
}): ReactElement {
  const contactId = props.row.original.Id;
  const params = useParams<{
    accountId: string;
  }>();
  const accountId = parseInt(params.accountId);
  const [deleteContact] = useDeleteContacts(contactId, accountId);

  const contactListOptions = [
    {
      value: 'Edit Contact',
      icon: <Icon.Edit title="Options" />,
      groupEnd: true,
      action: () => props.onEditPress && props.onEditPress(contactId),
    },
    {
      value: 'Delete Contact',
      icon: <Icon.Delete title="Options" />,
      action: () => handleDeleteContact(),
    },
  ];

  const handleDeleteContact = () => {
    confirmAlert({
      // title: 'Proteus',
      message: 'Are you sure you want to delete this contact?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteContact({ contactId, accountId });
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  const contactListDropdown = () => (
    <Button size="sm">
      <Icon.DotsVertical title="Options" />
    </Button>
  );

  return (
    <>
      <Dropdown
        trigger={contactListDropdown()}
        menuList={contactListOptions}
        position="left-top"
        className="dd-listitem-options"
      />
    </>
  );
}
