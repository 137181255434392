import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { importBlobFiles } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { BlobFileEntity } from 'types/fileTypes';

export async function postSaveImportFiles({
  params,
}: TArguments): Promise<TResult> {
  const response = await fetch(importBlobFiles, {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = Array<BlobFileEntity> & ValidationError;

export type TError = { Message: string };
export type TArguments = {
  params: TVariables;
};
export type TVariables = {
  DestinationPath: string;
  FileListForImport: Array<BlobFileEntity>;
};

export type TSnapshot = unknown;

function useImportBlobFiles(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: TVariables) => {
      const postResult = await postSaveImportFiles({
        params,
      });

      queryCache.invalidateQueries(['get_file_tree'], {
        refetchInactive: true,
      });
      queryCache.removeQueries(['get_accounts_list_for_import_files']);

      if (postResult.Message) throw postResult;
      else return postResult;
    },
    {
      throwOnError: true,
    },
  );
}

export { useImportBlobFiles };
