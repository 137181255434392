import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { deleteTemplateGroup } from '../utils/urls';
import { getTokenWithType } from './useAuth';

export async function removeTemplateGroup(
  params: TVariables,
): Promise<TResult> {
  const response = await fetch(deleteTemplateGroup, {
    method: 'DELETE',
    headers: {
      Authorization: getTokenWithType(),
      'Content-type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  return response.json();
}

export type TResult = string;
export type TError = { message: string };

type DeleteTemplateParams = {
  FolderName: string;
  Container: string | undefined;
};
export type TVariables = DeleteTemplateParams;
export type TSnapshot = unknown;

function useDeleteTemplateGroup(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: DeleteTemplateParams) => {
      const result = await removeTemplateGroup(params);
      if (result) {
        if (params.Container === 'layouts') {
          queryCache.invalidateQueries(['getExistingLayouts'], {
            refetchInactive: true,
          });
        } else if (params.Container === 'tables') {
          queryCache.invalidateQueries(['getExistingTables'], {
            refetchInactive: true,
          });
        }
      }
      return result;
    },
    {
      throwOnError: true,
    },
  );
}

export { useDeleteTemplateGroup };
