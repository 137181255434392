// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const fontSizes = {
 "xsmall": "0.7142857142857143rem",
 "small": "0.8571428571428571rem",
 "p": "1rem",
 "h6": "1.1428571428571428rem",
 "h5": "1.4285714285714286rem",
 "h4": "1.7142857142857142rem",
 "h3": "2.2857142857142856rem",
 "h2": "2.857142857142857rem",
 "h1": "3.4285714285714284rem"
}

export default fontSizes;