import React, { SyntheticEvent, ReactElement } from 'react';
import { darken } from 'polished';
import styled from 'styled-components';

export const StyledFloatLabelInput = styled.div`
  --color__accent: var(--primary-color);
  --input__border: ${({ theme }) => theme.colors.border.gray};
  --input__border--hover: ${({ theme }) => theme.colors.border.grayDarker};
  --label__color: ${({ theme }) => theme.colors.text.body};

  .floating-input {
    th & {
      --input__border: ${({ theme }) =>
        darken(0.2, theme.colors.border.grayDark)};
    }
    padding: 1.2em 1em 0.4em 0;
    font-size: inherit;
    border: 0;
    border-bottom: 0.1em solid var(--input__border);
    transition: border-color 0.2s ease;
    caret-color: var(--color__accent);
    display: block;
    width: 100%;
    background: transparent;
    color: var(--label__color);

    &:focus {
      outline: none;
    }
  }

  .floating:hover .floating-input {
    border-color: var(--input__border--hover);
  }

  .floating-input::placeholder {
    color: rgba(0, 0, 0, 0);
  }

  .floating-label {
    display: block;
    position: relative;
    max-height: 0;
    font-weight: 500;
    pointer-events: none;
  }

  .floating-label::before {
    color: var(--label__color);
    content: attr(data-content);
    display: inline-block;
    /* filter: blur(0); */
    backface-visibility: hidden;
    transform-origin: left top;
    transition: transform 0.2s ease;
    left: 0;
    position: relative;
  }

  .floating-label::after {
    bottom: 1em;
    content: '';
    height: 0.1em;
    position: absolute;
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 200ms cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
    opacity: 0;
    left: 0;
    top: 100%;
    margin-top: -0.1em;
    transform: scale3d(0, 1, 1);
    width: 100%;
    background-color: var(--color__accent);
  }

  .floating-input:focus + .floating-label::after {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  .floating-input:placeholder-shown + .floating-label::before,
  .react-datepicker-wrapper:has(.floating-input:placeholder-shown)
    + .floating-label::before {
    transform: translate3d(0, -2.2em, 0) scale3d(1, 1, 1);
  }

  .floating-label::before,
  .floating-input:focus + .floating-label::before {
    transform: translate3d(0, -3.12em, 0) scale3d(0.82, 0.82, 1);
  }

  .floating-input:focus + .floating-label::before {
    color: var(--color__accent);
  }
`;

type FloatLabelInputProps = {
  label: string;
  style: any;
  onChangeText: (value: string) => void;
  onBlur?: () => void;
  value: string;
};

function FloatLabelInput({
  label,
  onChangeText,
  onBlur,
  value,
  style,
}: FloatLabelInputProps): ReactElement {
  const fieldID = label.replace(/\s/g, '').toLowerCase();
  const onChange = (e: SyntheticEvent) => {
    // console.log(
    //   '(e.target as HTMLInputElement).value:',
    //   (e.target as HTMLInputElement).value,
    // );
    onChangeText((e.target as HTMLInputElement).value);
  };
  return (
    <StyledFloatLabelInput style={style}>
      <input
        id={fieldID}
        className="floating-input"
        name="{label}"
        placeholder={label}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
      <label htmlFor={fieldID} className="floating-label" data-content={label}>
        <span className="sr-only">{label}</span>
      </label>
    </StyledFloatLabelInput>
  );
}

export { FloatLabelInput };
