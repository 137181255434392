import React, { ReactElement, SyntheticEvent, useEffect } from 'react';
import { Box } from 'rebass/styled-components';
import { FormInput, Alert, FormSelect } from 'ui/patterns';
import { RadioButton, Stack, Grid, Checkbox } from 'ui/components';
import * as Icon from 'assets/icons';
import { LinkQuestionType } from '../questionTypes';

export default function LinkQuestion(
  LinkQuestionProps: LinkQuestionType,
): ReactElement {
  const {
    selectedQuestionType,
    associatedPersons,
    register,
    control,
    Controller,
    watch,
    setValue,
  } = LinkQuestionProps;

  const linkType = watch('Type');

  useEffect(() => {
    if (selectedQuestionType?.QuestionTypeOptions?.Type === linkType)
      setValue('Type', selectedQuestionType?.QuestionTypeOptions?.Type, {
        shouldDirty: false,
      });
  }, [selectedQuestionType, linkType, setValue]);

  return (
    <Box as="form" height="100%" mb="50px">
      <Stack direction="y" gap={7}>
        <Grid minColWidth="300px" gap={3}>
          <FormInput
            label="Field Name"
            disabled
            register={register}
            name="FieldName"
          />
          <FormInput
            label="Original Field Label"
            disabled
            register={register}
            name="FieldLabel"
          />
          <FormInput
            label="Description of Use"
            disabled
            register={register}
            name="Description"
          />
          <FormInput
            label="Field Label"
            register={register}
            name="UpdatedFieldLabel"
          />
          <Controller
            render={(props: {
              onChange: (value: number) => void;
              value: number;
            }) => {
              return (
                <FormSelect
                  disabled={
                    !selectedQuestionType?.QuestionType?.IsQuestionAssociable
                  }
                  value={props.value}
                  label="Person Association"
                  noSelectOption
                  options={associatedPersons}
                  onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                    const value = parseInt(e.currentTarget.value, 10);
                    props.onChange(value);
                  }}
                />
              );
            }}
            defaultValue={''}
            name="PersonAssociation"
            control={control}
          />
        </Grid>
        <Grid minColWidth="250px" gap={3}>
          <Checkbox label="Required" register={register} name="IsRequired" />
          <Checkbox
            label="Never send to Account"
            register={register}
            name="IsNeverSentToAccount"
          />
          <Checkbox
            label="If no answer, don't send to Account"
            register={register}
            name="IsNotSentToAccountIfNoAnswer"
          />
        </Grid>

        <Stack direction="y" gap={3}>
          <Controller
            render={(props: {
              onChange: (value: number) => void;
              value: number;
            }) => {
              return (
                <RadioButton
                  onChange={() => {
                    props.onChange(0);
                  }}
                  inline
                  label="iFrame"
                  name="Type"
                  checked={linkType === 0}
                />
              );
            }}
            defaultValue={1}
            name="Type"
            control={control}
          />

          <Controller
            render={(props: {
              onChange: (value: number) => void;
              value: number;
            }) => {
              return (
                <RadioButton
                  onChange={() => {
                    props.onChange(1);
                  }}
                  inline
                  label="New Tab"
                  name="Type"
                  checked={linkType === 1}
                />
              );
            }}
            defaultValue={1}
            name="Type"
            control={control}
          />
          {linkType === 0 && (
            <Alert variant="warning" icon={<Icon.Warning />}>
              Make sure to test the form to ensure the iFrame works properly.
            </Alert>
          )}

          <Grid minColWidth="300px" gap={3}>
            <FormInput
              label="Link Description"
              register={register}
              name="LinkDescription"
            />
            <FormInput label="URL" register={register} name="Url" />
          </Grid>
        </Stack>
      </Stack>
    </Box>
  );
}
