import { useQuery, QueryResult } from 'react-query';
import { getTokenWithType } from './useAuth';
import { layout } from '../utils/urls';
import { TemplateResult } from '../types/procedureBuilder';

async function fetchExisitngLayouts({
  showPanel,
}: {
  showPanel: boolean;
}): Promise<TResult> {
  if (!showPanel)
    return new Promise((resolve, reject) => {
      reject();
    });
  const response = await fetch(layout(), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = Array<TemplateResult> | undefined;

export type TError = {
  message: string;
};

function useGetExistingLayouts(
  showPanel: boolean,
): QueryResult<TResult, TError> {
  return useQuery(
    ['getExistingLayouts', showPanel],
    async () => {
      return await fetchExisitngLayouts({
        showPanel,
      });
    },
    {
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetExistingLayouts };
