import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Box, Flex, Text } from 'rebass';
import 'react-tippy/dist/tippy.css';
import { SlidePanel, Table } from 'ui/patterns';
import { Button, RadioButton, Separator } from 'ui/components';
import { SegmentedButtons } from 'ui/patterns';
import { SearchWrapper } from './styles';

import SearchBlocks from './searchBlocks';
import useAdvancedSearchStore from 'store/useAdvancedSearchStore';
import useProcedureSearch from './ProcedureDetailSearch/ProcedureSearch/useProcedureSearch';
import useDetailsSearch from './ProcedureDetailSearch/DetailSearch/useDetailSearch';
import useQuestionSearch from './QuestionSectionSearch/QuestionSearch/useQuestionSearch';
import useSectionSearch from './QuestionSectionSearch/SectionSearch/useSectionSearch';
import ProcedureSearchOption from './ProcedureDetailSearch/ProcedureSearch/procedureSearchOptions';
import DetailSearchOptions from './ProcedureDetailSearch/DetailSearch/detailSearchOptions';
import QuestionSearchOptions from './QuestionSectionSearch/QuestionSearch/questionSearchOptions';
import SectionSearchOptions from './QuestionSectionSearch/SectionSearch/sectionSearchOptions';

const searchOptions = [
  { id: 1, name: 'Contains' },
  { id: 2, name: 'Equals' },
  { id: 3, name: 'Not Contains' },
];

function AdvancedSearch(): ReactElement {
  useEffect(() => {
    function checkUserData() {
      resetOnSession();
    }
    window.onstorage = () => {
      const expired = window.sessionStorage.getItem('AUTH_EXPIRY_VISIBLE');
      if (expired === 'true') {
        checkUserData();
      }
    };
    return () => {
      window.onstorage = null;
    };
  }, []);
  const {
    procedureOptions,
    toggleProcedureOption,
    procedureTableProps,
    resetProcedureSearch,
    search,
    handleDownloadSearchResult,
    totalProcedureRecordsCount,
    listedProcedureRecordCount,
    isProcedureSearchFetching,
  } = useProcedureSearch();

  const {
    detailsOptions,
    toggleDetailOption,
    detailTableProps,
    resetDetailSearch,
    detailSearch,
    handleDownloadDetailSearchResult,
    totalDetailRecordsCount,
    listedDetailRecordCount,
    isDetailSearchFetching,
  } = useDetailsSearch();

  const {
    questionOptions,
    toggleQuestionOption,
    questionTableProps,
    resetQuestionSearch,
    questionSearch,
    handleDownloadQuestionSearchResult,
    totalQuestionRecordsCount,
    listedQuestionRecordCount,
    isQuestionSearchFetching,
  } = useQuestionSearch();

  const {
    sectionOptions,
    toggleSectionOption,
    sectionTableProps,
    resetSectionSearch,
    sectionSearch,
    handleDownloadSectionSearchResult,
  } = useSectionSearch();

  const history = useHistory();
  const match = useRouteMatch('/home/accounts/advanced');
  const { removeParams } = useAdvancedSearchStore();
  const [isVisible, setVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>('proceduresdetails');
  const [selectedSearchoptions, setSelectedSearchoption] =
    useState<string>('procedure');

  useEffect(() => {
    if (match)
      setTimeout(() => {
        setVisible(true);
      }, 10);
  }, [match]);

  const handleDownload = () => {
    if (selectedTab === 'proceduresdetails') {
      if (selectedSearchoptions === 'procedure') handleDownloadSearchResult();
      else handleDownloadDetailSearchResult();
    } else {
      if (selectedSearchoptions === 'question')
        handleDownloadQuestionSearchResult();
      else handleDownloadSectionSearchResult();
    }
  };

  const handleDownloadButtonDisable = () => {
    if (selectedTab === 'proceduresdetails') {
      if (selectedSearchoptions === 'procedure') {
        if (
          procedureTableProps?.data?.length === 0 ||
          procedureTableProps.isLoading
        )
          return true;
      } else {
        if (detailTableProps?.data?.length === 0 || detailTableProps.isLoading)
          return true;
      }
    } else {
      if (selectedSearchoptions === 'question') {
        if (
          questionTableProps?.data?.length === 0 ||
          questionTableProps.isLoading
        )
          return true;
      } else {
        if (
          sectionTableProps?.data?.length === 0 ||
          sectionTableProps.isLoading
        )
          return true;
      }
    }
  };

  const AdvancedSearchActions = () => (
    <Button
      variant="secondary"
      onClick={() => handleDownload()}
      disabled={handleDownloadButtonDisable()}
    >
      Download search results as CSV
    </Button>
  );

  const handleCheckBoxOptions = () => {
    if (selectedTab === 'proceduresdetails') {
      if (selectedSearchoptions === 'procedure')
        return (
          <ProcedureSearchOption
            procedureOptions={procedureOptions}
            toggleProcedureOption={toggleProcedureOption}
          />
        );
      else
        return (
          <DetailSearchOptions
            detailsOptions={detailsOptions}
            toggleDetailOption={toggleDetailOption}
          />
        );
    } else {
      if (selectedSearchoptions === 'question')
        return (
          <QuestionSearchOptions
            questionOptions={questionOptions}
            toggleQuestionOption={toggleQuestionOption}
          />
        );
      else
        return (
          <SectionSearchOptions
            sectionOptions={sectionOptions}
            toggleSectionOption={toggleSectionOption}
          />
        );
    }
  };

  const handleTableDisplayed = () => {
    if (selectedTab === 'proceduresdetails') {
      if (selectedSearchoptions === 'procedure')
        return <Table tooltipHidden={!isVisible} {...procedureTableProps} />;
      else return <Table tooltipHidden={!isVisible} {...detailTableProps} />;
    } else {
      if (selectedSearchoptions === 'question')
        return <Table tooltipHidden={!isVisible} {...questionTableProps} />;
      else return <Table tooltipHidden={!isVisible} {...sectionTableProps} />;
    }
  };

  const handleCounterDisplayed = () => {
    if (selectedTab === 'proceduresdetails') {
      if (selectedSearchoptions === 'procedure')
        return {
          isFetching: isProcedureSearchFetching,
          totalRecords: totalProcedureRecordsCount,
          currentRecords: listedProcedureRecordCount,
        };
      else
        return {
          isFetching: isDetailSearchFetching,
          totalRecords: totalDetailRecordsCount,
          currentRecords: listedDetailRecordCount,
        };
    } else {
      if (selectedSearchoptions === 'question')
        return {
          isFetching: isQuestionSearchFetching,
          totalRecords: totalQuestionRecordsCount,
          currentRecords: listedQuestionRecordCount,
        };
    }
  };

  const handleSearchButtonDisable = () => {
    if (selectedTab === 'proceduresdetails') {
      if (selectedSearchoptions === 'procedure') {
        if (
          !procedureOptions.procedureText &&
          !procedureOptions.procedureTitle &&
          !procedureOptions.isGlobal &&
          !procedureOptions.isInvisible
        )
          return true;
      } else {
        if (!detailsOptions.fieldLabel && !detailsOptions.questionDetails)
          return true;
      }
    }
  };

  const advancedSearchMain = () => {
    if (selectedTab === 'proceduresdetails') {
      if (selectedSearchoptions === 'procedure') {
        search.searchButtonClick();
      } else if (selectedSearchoptions === 'details') {
        detailSearch.searchButtonClick();
      }
    } else {
      if (selectedSearchoptions === 'question') {
        questionSearch.searchButtonClick();
      } else if (selectedSearchoptions === 'section') {
        sectionSearch.searchButtonClick();
      }
    }
  };

  const resetOnSession = () => {
    resetProcedureSearch();
    resetDetailSearch();
    resetQuestionSearch();
    resetSectionSearch();
  };

  const resetSearchPage = () => {
    if (selectedTab === 'proceduresdetails') {
      if (selectedSearchoptions === 'procedure') {
        resetProcedureSearch();
      } else if (selectedSearchoptions === 'details') {
        resetDetailSearch();
      }
    } else {
      if (selectedSearchoptions === 'question') {
        resetQuestionSearch();
      } else if (selectedSearchoptions === 'section') {
        resetSectionSearch();
      }
    }
  };

  const handleSearchBlockDisplayed = () => {
    if (selectedTab === 'proceduresdetails') {
      if (selectedSearchoptions === 'procedure')
        return (
          <SearchBlocks
            searchOptions={searchOptions}
            search={search}
            advancedSearchMain={advancedSearchMain}
            handleSearchButtonDisable={handleSearchButtonDisable}
            resetSearchPage={resetSearchPage}
          />
        );
      else
        return (
          <SearchBlocks
            searchOptions={searchOptions}
            search={detailSearch}
            advancedSearchMain={advancedSearchMain}
            handleSearchButtonDisable={handleSearchButtonDisable}
            resetSearchPage={resetSearchPage}
          />
        );
    } else {
      if (selectedSearchoptions === 'question')
        return (
          <SearchBlocks
            searchOptions={searchOptions}
            search={questionSearch}
            advancedSearchMain={advancedSearchMain}
            handleSearchButtonDisable={handleSearchButtonDisable}
            resetSearchPage={resetSearchPage}
          />
        );
      else
        return (
          <SearchBlocks
            searchOptions={searchOptions}
            search={sectionSearch}
            advancedSearchMain={advancedSearchMain}
            handleSearchButtonDisable={handleSearchButtonDisable}
            resetSearchPage={resetSearchPage}
          />
        );
    }
  };

  const handleRadioButtonSelection = () => {
    if (selectedTab === 'proceduresdetails')
      return (
        <Flex style={{ gap: '0.5rem' }}>
          <RadioButton
            name={`searchInclude`}
            label="Procedures"
            checked={
              selectedTab === 'proceduresdetails' &&
              selectedSearchoptions === 'procedure'
            }
            onChange={() => setSelectedSearchoption('procedure')}
          />
          <RadioButton
            name={`searchInclude`}
            label="Details"
            checked={
              selectedTab === 'proceduresdetails' &&
              selectedSearchoptions === 'details'
            }
            onChange={() => setSelectedSearchoption('details')}
          />
        </Flex>
      );
    else
      return (
        <Flex style={{ gap: '0.5rem' }}>
          <RadioButton
            name={`searchInclude`}
            label="Questions"
            checked={
              selectedTab === 'questionsections' &&
              selectedSearchoptions === 'question'
            }
            onChange={() => setSelectedSearchoption('question')}
          />
          <RadioButton
            name={`searchInclude`}
            label="Sections"
            checked={
              selectedTab === 'questionsections' &&
              selectedSearchoptions === 'section'
            }
            onChange={() => setSelectedSearchoption('section')}
          />
        </Flex>
      );
  };

  const gridView = () => {
    return (
      <Flex flexDirection="column">
        {selectedSearchoptions !== 'section' ? (
          <Text as="p" mb={1} mt={'-12px'}>
            {handleCounterDisplayed()?.currentRecords?.length &&
            handleCounterDisplayed()?.totalRecords ? (
              <Text mb={2}>{`Showing ${
                handleCounterDisplayed()?.currentRecords?.length
              } of ${handleCounterDisplayed()?.totalRecords}${
                handleCounterDisplayed()?.isFetching ? ' | Processing' : ''
              }`}</Text>
            ) : null}
          </Text>
        ) : null}
        <Box
          flex="1"
          pr={3}
          height="100%"
          sx={{
            '& > *': { height: '100%' },
            transform: 'translate(0px, 0px)',
          }}
        >
          {handleTableDisplayed()}
        </Box>
      </Flex>
    );
  };

  const searchOptionToolbar = () => {
    return (
      <SearchWrapper>
        <Text
          as="h3"
          p="1"
          bg="var(--color-neutral-contrast-02)"
          fontWeight="medium"
          lineHeight="1.2"
          fontSize="1rem"
        >
          Search
        </Text>
        <Separator direction="x" />
        <Box p={3}>
          <SegmentedButtons width="100%" equalWidthChildren>
            <Button
              variant={
                selectedTab === 'proceduresdetails' ? 'primary' : 'outline'
              }
              onClick={() => {
                setSelectedTab('proceduresdetails');
                setSelectedSearchoption('procedure');
              }}
            >
              Procedures &amp; Details
            </Button>
            <Button
              variant={
                selectedTab === 'questionsections' ? 'primary' : 'outline'
              }
              onClick={() => {
                setSelectedTab('questionsections');
                setSelectedSearchoption('question');
              }}
            >
              Sections &amp; Questions
            </Button>
          </SegmentedButtons>
        </Box>
        <Separator direction="x" />
        <Flex
          justifyContent={'space-between'}
          bg="var(--color-neutral-contrast-02)"
          py="1"
          px="3"
        >
          <Text as="h3" fontWeight="medium" lineHeight="1.2" fontSize="1rem">
            Search should include
          </Text>
          {handleRadioButtonSelection()}
        </Flex>
        <Separator direction="x" />
        {handleCheckBoxOptions()}
        <Separator direction="x" />
        {handleSearchBlockDisplayed()}
        <Separator direction="x" />
      </SearchWrapper>
    );
  };

  const onClosePanel = () => {
    setVisible(false);
    removeParams();
    resetSearchPage();
    setTimeout(() => {
      history.push('/home/accounts');
    }, 10);
  };
  return (
    <SlidePanel
      title="Advanced Search"
      open={isVisible}
      onClose={() => onClosePanel()}
      size="xl"
      actions={AdvancedSearchActions()}
    >
      <Flex height="100%">
        {gridView()}
        {searchOptionToolbar()}
      </Flex>
    </SlidePanel>
  );
}

export default AdvancedSearch;
