export const phonePattern = [
  { char: /\d/, repeat: 3 },
  { exactly: '-' },
  { char: /\d/, repeat: 3 },
  { exactly: '-' },
  { char: /\d/, repeat: 4 },
  { exactly: ' ' },
  { char: ['[a-z0-9]', 'i'], repeat: 7 },
];

export const numberOnlyPattern = [{ char: /\d/, repeat: 1000 }];

export const ssnPattern = [
  { char: /\d/, repeat: 3 },
  { exactly: '-' },
  { char: /\d/, repeat: 2 },
  { exactly: '-' },
  { char: /\d/, repeat: 4 },
];

export const militaryTimePattern = [
  { char: ['[0-2]'] },
  { char: ['[0-9]'] },
  { exactly: ':' },
  { char: ['[0-5]'] },
  { char: ['[0-9]'] },
];

export const getValidations = (textQuestionTypeFormatId: number): any => {
  switch (textQuestionTypeFormatId) {
    case 1:
      return '';
    case 2:
      return /^\d{3}-\d{3}-\d{4} [a-zA-Z0-9]{7}$/;
    case 3:
      return /^[0-9]*$/;
    case 4:
      return /^\d{3}-\d{2}-\d{4}$/;
    case 5:
      return /^([01]\d|2[0-3]):([0-5]\d)$/;
    case 6:
      return /[a-zA-Z0-9+_.-]+@[a-zA-Z_0-9]+?\.[a-zA-Z]{2,3}$/;
  }
};
