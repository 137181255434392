import React, {
  ReactElement,
  SyntheticEvent,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Box, Text } from 'rebass/styled-components';
import { confirmAlert } from 'utils/confirm-alert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  FormSelect,
  FormInput,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  FormTextarea,
} from 'ui/patterns';
import {
  Button,
  RadioButton,
  Stack,
  Grid,
  Checkbox,
  DataTable,
  DataTableWrapper,
} from 'ui/components';
import {
  ChoiceQuestionType,
  TemplateItemType,
  TemplateType,
} from '../questionTypes';
import { QuestionTypeInput } from 'types/questions';
import * as Icon from 'assets/icons';
// import indexStories from 'ui/components/button/index.stories';
import NoDataImage from 'assets/images/NoDataImage';
import { EmptyState } from 'ui/patterns/emptyState';

type SelectedTemplateItemType = {
  Id: number;
  Name: string;
  Type: string;
};

type RemovedTemplateItemType = {
  Id: number;
  Name: string;
};

type ChoiceDependencyItemType = {
  DependentQuestions: Array<DependentQuestionType>;
  DependentSections: Array<DependentSectionType>;
  TemplateId: number;
};

type DependentSectionType = {
  $type: string;
  Id: number;
  Title: string;
  Section: {
    Title: string;
  };
};

type DependentQuestionType = {
  Id: number;
  FieldLabel: string;
};

export default function ChoiceQuestion(
  ChoiceQuestionProps: ChoiceQuestionType,
): ReactElement {
  const {
    selectedQuestionType,
    associatedPersons,
    register,
    control,
    Controller,
    watch,
    setValue,
    questionTemplateOptions,
    setQuestionTemplateOptions,
    sections,
    currentQuestion,
    handleFormandQuestionSave,
  } = ChoiceQuestionProps;

  const choiceType = watch('ChoiceType');

  useEffect(() => {
    if (selectedQuestionType?.QuestionTypeOptions?.Type === choiceType)
      setValue('Type', selectedQuestionType?.QuestionTypeOptions?.Type, {
        shouldDirty: false,
      });
  }, [selectedQuestionType, choiceType, setValue]);

  const [showAddDependency, setAddDependency] = useState<boolean>(false);
  const [isFormSaved, setFormSaved] = useState<boolean>(false);
  const [selectedTemplateItem, setTemplateItem] =
    useState<SelectedTemplateItemType>();
  const [choiceDependency, setChoiceDependency] = useState<
    Array<ChoiceDependencyItemType>
  >([]);
  const [removeDependencyItem, setRemoveDependency] =
    useState<RemovedTemplateItemType>();

  const [showRemoveDependencyTable, setShowRemoveDependencyTable] =
    useState<boolean>(false);
  useEffect(() => {
    let isQuestionSaved, isSectionSaved, unsavedSection, unsavedQuestion;
    if (sections && sections.length > 0) {
      unsavedSection = sections.find(item => item.Type === undefined);
      if (unsavedSection) isSectionSaved = false;
      else isSectionSaved = true;
      unsavedQuestion = sections.filter(sectionItem => {
        return (
          sectionItem.Questions &&
          sectionItem.Questions.find(
            (questionItem: QuestionTypeInput) =>
              questionItem.Order === undefined,
          )
        );
      });
      if (unsavedQuestion && unsavedQuestion.length > 0)
        isQuestionSaved = false;
      else isQuestionSaved = true;
    }

    const isFormSaved =
      isSectionSaved &&
      isQuestionSaved &&
      Object.keys(currentQuestion).length !== 0;
    if (isFormSaved) setFormSaved(isFormSaved);
  }, [selectedQuestionType, sections, currentQuestion]);

  const selectedSectionId = watch('DependentSectionId');
  const selectedQuestionId = watch('DependentQuestionId');

  useEffect(() => {
    let dependencies: ChoiceDependencyItemType[] = [];
    if (questionTemplateOptions && questionTemplateOptions?.length > 0)
      dependencies = questionTemplateOptions?.map(item => {
        return {
          DependentQuestions: item.DependentQuestions,
          DependentSections: item.DependentSections,
          TemplateId: item.Id,
        };
      });

    if (dependencies) setChoiceDependency(dependencies);
  }, [questionTemplateOptions]);

  const dependencySectionList = useMemo(() => {
    if (sections?.length > 0 && selectedTemplateItem?.Type === 'Question') {
      return sections
        .filter(
          item =>
            item.$type ===
            'Proteus2.Api.Domain.Surveys.Section, Proteus2.Api.Domain',
        )
        .map(item => {
          return {
            id: item.Id,
            name: item.Title,
          };
        });
    }

    // filtering out the current section from dependency list
    if (selectedTemplateItem?.Type === 'Section' && sections?.length > 0) {
      const filteredSections = sections.filter(
        item => item.Id !== currentQuestion?.SectionId,
      );
      return filteredSections?.map(item => {
        // return {
        //   id: item.Id,
        //   name: item.Title,
        // };
        if (item.Type === 1) {
          return {
            id: item.Id,
            name: `${item?.Section?.Title} (Global Section)`,
          };
        } else {
          return {
            id: item.Id,
            name: item.Title,
          };
        }
      });
    }
  }, [currentQuestion, sections, selectedTemplateItem]);

  const dependencyQuestionList = useMemo(() => {
    if (sections && sections.length > 0) {
      const selectedSection = sections.find(
        item => item.Id === selectedSectionId,
      );
      return (
        selectedSection?.Questions?.length > 0 &&
        selectedSection?.Questions?.filter(
          (qItem: QuestionTypeInput) => qItem.Id !== currentQuestion.Id,
        ).map((questionItem: QuestionTypeInput) => {
          return {
            id: questionItem.Id,
            name: questionItem.FieldLabel,
          };
        })
      );
    }
  }, [sections, selectedSectionId, currentQuestion]);

  // to show the eye icon
  const DependentList = useMemo(() => {
    const tempItem = questionTemplateOptions?.map(item =>
      choiceDependency.find(
        (choiceItem: ChoiceDependencyItemType) =>
          choiceItem.TemplateId === item?.Id,
      ),
    );
    return tempItem;
  }, [choiceDependency, questionTemplateOptions]);

  const handleIsDefaultChange = (templateItem: TemplateItemType) => {
    const tempOption =
      questionTemplateOptions &&
      questionTemplateOptions.map((item: TemplateItemType) => {
        if (item.Id === templateItem.Id) {
          return {
            ...item,
            IsDefault: true,
          };
        } else {
          return {
            ...item,
            IsDefault: false,
          };
        }
      });
    if (tempOption) setQuestionTemplateOptions([...tempOption]);
  };

  const handleIncludeByDefaultChange = (templateItem: TemplateItemType) => {
    const index =
      questionTemplateOptions &&
      questionTemplateOptions.findIndex(
        (item: TemplateItemType) => item.Id === templateItem.Id,
      );

    const tempOption = [...questionTemplateOptions];
    tempOption[index] = {
      ...tempOption[index],
      IncludeByDefault: !tempOption[index].IncludeByDefault,
    };
    tempOption.filter(item => item.IncludeByDefault === true);
    setQuestionTemplateOptions([...tempOption]);
  };

  const isNoDefaultChecked = (): boolean => {
    const isDefaultCheckedArray =
      questionTemplateOptions &&
      questionTemplateOptions.map((templateItem: TemplateItemType) => {
        if (templateItem.IsDefault === true) return true;
        else return false;
      });
    const isDefaultChecked = isDefaultCheckedArray.includes(true)
      ? false
      : true;

    return isDefaultChecked;
  };

  const handleChangeInput = (
    name: string,
    value: string,
    templateItem: TemplateItemType,
  ) => {
    const index =
      questionTemplateOptions &&
      questionTemplateOptions.findIndex(
        (item: TemplateItemType) => item.Id === templateItem.Id,
      );

    const tempOption = [...questionTemplateOptions];
    tempOption[index] = {
      ...tempOption[index],
      [name]: value,
    };
    setQuestionTemplateOptions([...tempOption]);
  };

  const handleChangeNoDefault = () => {
    const tempOption =
      questionTemplateOptions &&
      questionTemplateOptions.map((item: TemplateItemType) => {
        return {
          ...item,
          IsDefault: false,
        };
      });
    if (tempOption) setQuestionTemplateOptions([...tempOption]);
  };

  const handleSaveChoiceDependency = () => {
    const allDependencies: ChoiceDependencyItemType[] = [...choiceDependency];
    let dependentSections: any[] = [];
    let dependentQuestions: any[] = [];
    if (allDependencies && allDependencies.length > 0) {
      const dependentObj = allDependencies.find(
        (item: any) => item.TemplateId === selectedTemplateItem?.Id,
      );

      if (dependentObj) {
        dependentSections = dependentObj.DependentSections;
        dependentQuestions = dependentObj.DependentQuestions;
      }
    }

    if (selectedTemplateItem?.Type === 'Section') {
      const sectionObj = dependencySectionList
        ?.filter(item => item.id === selectedSectionId)
        .map(filterItem => {
          return {
            $type: 'Proteus2.Api.Domain.Surveys.Section, Proteus2.Api.Domain',
            Id: filterItem.id,
            Title: filterItem.name,
          };
        });

      if (sectionObj) dependentSections.push(sectionObj[0]);
    }

    if (selectedTemplateItem?.Type === 'Question') {
      const questionObj = dependencyQuestionList
        ?.filter((item: any) => item.id === selectedQuestionId)
        .map((filterItem: any) => {
          return {
            Id: filterItem.id,
            FieldLabel: filterItem.name,
          };
        });

      if (questionObj) dependentQuestions.push(questionObj[0]);
    }

    const index = allDependencies?.findIndex(
      (item: any) => item.TemplateId === selectedTemplateItem?.Id,
    );

    const dependentParams = {
      TemplateId: selectedTemplateItem?.Id || 0,
      DependentSections: dependentSections,
      DependentQuestions: dependentQuestions,
    };

    if (index === -1) {
      allDependencies.push(dependentParams);
    } else if (index >= 0) {
      allDependencies.splice(index, 1, dependentParams);
    }
    const templateOptionsArray = [...questionTemplateOptions];
    if (allDependencies) {
      const tempOptions =
        templateOptionsArray?.length > 0 &&
        templateOptionsArray?.map(item => {
          const tempItem = allDependencies?.find(
            (dependentItem: any) => item.Id === dependentItem.TemplateId,
          );
          if (tempItem)
            return {
              ...item,
              DependentQuestions: tempItem.DependentQuestions,
              DependentSections: tempItem.DependentSections,
            };
          else {
            return {
              ...item,
            };
          }
        });

      if (tempOptions) setQuestionTemplateOptions(tempOptions);
      setChoiceDependency(allDependencies);
      setAddDependency(false);
      if (selectedTemplateItem) {
        setRemoveDependency({
          Id: selectedTemplateItem.Id,
          Name: selectedTemplateItem.Name,
        });
      }
      setShowRemoveDependencyTable(true);
    }
  };

  const handleRemoveQuestionDependency = (item: DependentQuestionType) => {
    let dependentQuestions: DependentQuestionType[] = [];
    let dependentSections: DependentSectionType[] = [];
    if (choiceDependency?.length > 0) {
      const dependencies = choiceDependency?.find(
        item => item.TemplateId === removeDependencyItem?.Id,
      );
      if (dependencies) {
        dependentQuestions = dependencies?.DependentQuestions;
        dependentSections = dependencies?.DependentSections;
      }

      const index = dependentQuestions?.findIndex(
        (dependentItem: DependentQuestionType) => item.Id === dependentItem.Id,
      );
      if (index >= 0) {
        dependentQuestions && dependentQuestions.splice(index, 1);
      }
      const updatedDependency = choiceDependency?.map(item => {
        if (item.TemplateId === removeDependencyItem?.Id) {
          return {
            ...item,
            DependentQuestions: [...dependentQuestions],
          };
        } else {
          return {
            ...item,
          };
        }
      });
      if (updatedDependency) setChoiceDependency(updatedDependency);
      // if (dependentSections && dependentSections.length === 0)
      if (removeDependencyItem)
        setRemoveDependency({
          Id: removeDependencyItem.Id,
          Name: removeDependencyItem.Name,
        });
      setShowRemoveDependencyTable(true);
    }
  };

  const handleRemoveSectionDependency = (item: DependentSectionType) => {
    let dependentSections: DependentSectionType[] = [];
    let dependentQuestions: DependentQuestionType[] = [];
    if (choiceDependency?.length > 0) {
      const dependencies = choiceDependency?.find(
        item => item.TemplateId === removeDependencyItem?.Id,
      );
      if (dependencies) {
        dependentSections = dependencies?.DependentSections;
        dependentQuestions = dependencies?.DependentQuestions;
      }

      const index = dependentSections?.findIndex(
        (dependentItem: DependentSectionType) => item.Id === dependentItem.Id,
      );
      if (index >= 0) {
        dependentSections && dependentSections.splice(index, 1);
      }
      const updatedDependency = choiceDependency?.map(item => {
        if (item.TemplateId === removeDependencyItem?.Id) {
          return {
            ...item,
            DependentSectionIds: [...dependentSections],
          };
        } else {
          return {
            ...item,
          };
        }
      });
      if (updatedDependency) setChoiceDependency(updatedDependency);
      // if (dependentQuestions && dependentQuestions.length === 0)
      if (removeDependencyItem)
        setRemoveDependency({
          Id: removeDependencyItem.Id,
          Name: removeDependencyItem.Name,
        });
      setShowRemoveDependencyTable(true);
    }
  };

  const handleDependencyAlert = () => {
    confirmAlert({
      message:
        'Form needs to be saved before adding dependencies. Do you want to Proceed?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            handleFormandQuestionSave();
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  const handleRemoveDependencyWarning = (type: string, item: any) => {
    confirmAlert({
      message: 'Do you want to remove this dependency?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            if (type === 'Section') handleRemoveSectionDependency(item);
            if (type === 'Question') handleRemoveQuestionDependency(item);
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  const handleDependencyWarningMsg = () => {
    let message = '';
    if (selectedTemplateItem?.Type === 'Question')
      message = 'Please select the Question to continue.';
    else if (selectedTemplateItem?.Type === 'Section')
      message = 'Please select the Section to continue.';
    confirmAlert({
      message: message,
      buttons: [
        {
          label: 'Ok',
          onClick: () => {
            //
          },
          className: 'pr-btn-primary',
        },
      ],
    });
  };

  // a little function to help us with reordering the result
  const reorder = (
    list: Array<TemplateItemType>,
    startIndex: number,
    endIndex: number,
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onOrderUpdated = () => {
    const qTOs = [...questionTemplateOptions];
    qTOs.sort((item1, item2) => {
      if (parseInt(`${item1.Order}`) === parseInt(`${item2.Order}`))
        return `${item1.ItemLabel}`.toLowerCase() <
          `${item2.ItemLabel}`.toLowerCase()
          ? -1
          : 1;
      return parseInt(`${item1.Order}`) - parseInt(`${item2.Order}`);
    });
    setQuestionTemplateOptions([...qTOs]);
  };

  function handleDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const qTOs = [...questionTemplateOptions];
    const updatedList = reorder(
      qTOs,
      result.source.index,
      result.destination.index,
    );

    const updatedListCopy = [...updatedList].map(item => ({ ...item }));

    const firstPortion = [...updatedList].splice(0, result.destination.index);
    const lastPortion = [...updatedList].splice(
      result.destination.index + 1,
      [...updatedList].length - (result.destination.index - 1),
    );
    let firstPortionLastOrder = 0;
    if (firstPortion && firstPortion.length > 0) {
      firstPortionLastOrder = parseInt(
        `${firstPortion[firstPortion.length - 1].Order}`,
      );
    }
    updatedList[result.destination.index].Order = firstPortionLastOrder + 1;

    let newLastPortion: TemplateItemType[] = [];
    if (lastPortion && lastPortion.length > 0) {
      if (
        updatedList[result.destination.index].Order ===
        parseInt(`${lastPortion[0].Order}`)
      ) {
        // newLastPortion = lastPortion.map(item => ({
        //   ...item,
        //   Order: parseInt(`${item.Order}`) + 1,
        // }));
        for (let i = 0; i < lastPortion.length; i++) {
          const item = lastPortion[i];
          if (i === 0)
            newLastPortion.push({
              ...item,
              Order: parseInt(`${item.Order}`) + 1,
            });
          else {
            const previous = newLastPortion[i - 1];
            const newUpdatedOrder = parseInt(`${item.Order}`) + 1;
            if (
              previous.Order === newUpdatedOrder ||
              previous.Order === newUpdatedOrder + 1
            ) {
              newLastPortion.push({
                ...item,
                Order: newUpdatedOrder,
              });
            } else {
              newLastPortion.push({
                ...item,
              });
            }
          }
        }
      } else if (
        updatedList[result.destination.index].Order >
        parseInt(`${lastPortion[0].Order}`)
      ) {
        const diff =
          updatedList[result.destination.index].Order -
          parseInt(`${lastPortion[0].Order}`);
        // newLastPortion = lastPortion.map(item => ({
        //   ...item,
        //   Order: parseInt(`${item.Order}`) + diff + 1,
        // }));
        for (let i = 0; i < lastPortion.length; i++) {
          const item = lastPortion[i];
          if (i === 0)
            newLastPortion.push({
              ...item,
              Order: parseInt(`${item.Order}`) + diff + 1,
            });
          else {
            const previous = newLastPortion[i - 1];
            const newUpdatedOrder = parseInt(`${item.Order}`) + diff + 1;
            if (
              previous.Order === newUpdatedOrder ||
              previous.Order === newUpdatedOrder + 1
            ) {
              newLastPortion.push({
                ...item,
                Order: newUpdatedOrder,
              });
            } else {
              newLastPortion.push({
                ...item,
              });
            }
          }
        }
      } else {
        newLastPortion = lastPortion.map(item => ({
          ...item,
          Order: parseInt(`${item.Order}`),
        }));
      }
    }
    // if (
    //   lastPortion &&
    //   lastPortion[0] &&
    //   updatedList[result.destination.index].Order ===
    //     parseInt(`${lastPortion[0].Order}`)
    // ) {
    //   newLastPortion = lastPortion.map(item => ({
    //     ...item,
    //     Order: parseInt(`${item.Order}`) + 2,
    //   }));
    // } else if (lastPortion) {
    //   newLastPortion = lastPortion.map(item => ({
    //     ...item,
    //     Order: parseInt(`${item.Order}`),
    //   }));
    // }

    const newUpdatedList = [
      ...firstPortion,
      { ...updatedList[result.destination.index] },
      ...newLastPortion,
    ];
    // let order = 0;
    // let isAllSameOrder = true;
    // updatedList.forEach((item, index) => {
    //   // if (index === result.destination.index) item.Order = index + 1;
    //   if (order === 0) order = item.Order;
    //   else {
    //     if (order !== item.Order) isAllSameOrder = false;
    //   }
    // });
    // if (isAllSameOrder){

    // }
    // updatedList[result.source.index].Order = result.destination.index;
    // updatedList.forEach((item, index) => {
    //   // if (index === result.destination.index) item.Order = index + 1;
    //   item.Order = index + 1;
    // });
    // updatedList[result.destination.index].Order = result.destination.index + 1;
    newUpdatedList.sort((item1, item2) => {
      if (parseInt(`${item1.Order}`) === parseInt(`${item2.Order}`))
        return `${item1.ItemLabel}`.toLowerCase() <
          `${item2.ItemLabel}`.toLowerCase()
          ? -1
          : 1;
      return parseInt(`${item1.Order}`) - parseInt(`${item2.Order}`);
    });
    setQuestionTemplateOptions([...newUpdatedList]);
  }

  const renderTemplateOptions = () => {
    return (
      // <DataTableWrapper
      //   style={{ height: 'auto', overFlow: 'initial', position: 'initial' }}
      // >
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="questionItemLabel">
          {provider => (
            <DataTable
              className="choice-qn-dependency"
              style={{
                width: '100%',
                tableLayout: 'fixed',
                border: 'solid 1px  var(--color-neutral-contrast-03)',
                borderRadius: '3px',
                overFlow: 'hidden',
              }}
            >
              {/* <colgroup>
                  <col span={1} style={{ width: '5%' }} />
                  <col span={1} style={{ width: '5%' }} />
                  <col span={1} style={{ width: '5%' }} />
                  <col span={1} style={{ width: '35%' }} />
                  <col span={1} style={{ width: '15%' }} />
                  <col span={1} style={{ width: '35%' }} />
                </colgroup> */}
              <thead>
                <tr>
                  <th style={{ width: '9%' }}>Is Default?</th>
                  <th style={{ width: '9%' }}>Include</th>
                  <th style={{ width: '7%' }}>Order</th>
                  <th style={{ width: '30%' }}>Item Label</th>
                  <th style={{ width: '15%' }}>Original Item Label</th>
                  <th style={{ width: '30%' }}>Dependencies</th>
                </tr>
              </thead>
              <tbody ref={provider.innerRef} {...provider.droppableProps}>
                <tr>
                  <td colSpan={6}>
                    <RadioButton
                      label="No Default"
                      name="IsDefault"
                      checked={isNoDefaultChecked()}
                      onChange={handleChangeNoDefault}
                    />
                  </td>
                </tr>
                {questionTemplateOptions &&
                  questionTemplateOptions.map((templateItem, templateIndex) => {
                    const isDependentExist =
                      DependentList &&
                      DependentList.find(
                        item => item?.TemplateId === templateItem.Id,
                      );
                    return (
                      <Draggable
                        key={templateItem.Id}
                        index={templateIndex}
                        draggableId={`${templateItem.Id}`}
                      >
                        {provided => (
                          <tr
                            key={templateIndex}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="slidePanel-dragFix"
                          >
                            <td style={{ width: '9%' }}>
                              <RadioButton
                                label=""
                                name="IsDefault"
                                checked={templateItem.IsDefault}
                                onChange={() => {
                                  handleIsDefaultChange(templateItem);
                                }}
                                customId={`${templateItem.Id}`}
                                className="radio-draggable-qn"
                              />
                            </td>
                            <td style={{ width: '9%' }}>
                              <Checkbox
                                label=""
                                name={`IncludeByDefault${templateIndex}`}
                                checked={templateItem.IncludeByDefault}
                                handleChange={() =>
                                  handleIncludeByDefaultChange(templateItem)
                                }
                              />
                            </td>
                            <td style={{ width: '7%' }}>
                              <FormInput
                                label=""
                                name={`Order${templateIndex}`}
                                value={templateItem.Order}
                                onTextChange={(text: string) => {
                                  handleChangeInput(
                                    'Order',
                                    text,
                                    templateItem,
                                  );
                                }}
                                onBlur={onOrderUpdated}
                              />
                            </td>
                            <td style={{ width: '30%' }}>
                              <FormTextarea
                                rows={1}
                                label=""
                                name={`ItemLabel${templateIndex}`}
                                value={templateItem.ItemLabel}
                                onTextChange={(text: string) => {
                                  handleChangeInput(
                                    'ItemLabel',
                                    text,
                                    templateItem,
                                  );
                                }}
                                onBlur={onOrderUpdated}
                                className="textarea-draggable-qn"
                              />
                            </td>
                            <td style={{ width: '15%' }}>
                              {templateItem.ItemName}
                            </td>
                            <td style={{ width: '30%' }}>
                              <Stack direction="x" gap={3}>
                                <Button
                                  size="sm"
                                  style={{ minWidth: '34px' }}
                                  variant={
                                    isDependentExist &&
                                    (isDependentExist?.DependentQuestions
                                      .length > 0 ||
                                      isDependentExist?.DependentSections
                                        .length > 0)
                                      ? 'secondary'
                                      : undefined
                                  }
                                  disabled={
                                    isDependentExist &&
                                    (isDependentExist?.DependentQuestions
                                      .length > 0 ||
                                      isDependentExist?.DependentSections
                                        .length > 0)
                                      ? false
                                      : true
                                  }
                                  title="View dependencies"
                                  iconBefore={
                                    isDependentExist &&
                                    (isDependentExist?.DependentQuestions
                                      .length > 0 ||
                                      isDependentExist?.DependentSections
                                        .length > 0) ? (
                                      <Icon.Eye title="View dependencies" />
                                    ) : (
                                      ''
                                    )
                                  }
                                  onClick={() => {
                                    setRemoveDependency({
                                      Id: templateItem.Id,
                                      Name: templateItem.ItemName,
                                    });
                                    setShowRemoveDependencyTable(true);
                                    setAddDependency(false);
                                  }}
                                />
                                <Button
                                  size="sm"
                                  variant="secondary"
                                  style={{
                                    whiteSpace: 'nowrap',
                                    minHeight: '30px',
                                  }}
                                  onClick={() => {
                                    if (isFormSaved === false)
                                      handleDependencyAlert();
                                    else {
                                      setTemplateItem({
                                        Type: 'Question',
                                        Id: templateItem.Id,
                                        Name: templateItem.ItemName,
                                      });
                                      setAddDependency(true);
                                    }
                                  }}
                                >
                                  Add Question
                                </Button>
                                <Button
                                  size="sm"
                                  variant="secondary"
                                  style={{
                                    whiteSpace: 'nowrap',
                                    minHeight: '30px',
                                  }}
                                  onClick={() => {
                                    if (isFormSaved === false)
                                      handleDependencyAlert();
                                    else {
                                      setTemplateItem({
                                        Type: 'Section',
                                        Id: templateItem.Id,
                                        Name: templateItem.ItemName,
                                      });
                                      setAddDependency(true);
                                    }
                                  }}
                                >
                                  Add Section
                                </Button>
                              </Stack>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                {provider.placeholder}
              </tbody>
            </DataTable>
          )}
        </Droppable>
      </DragDropContext>
      //  </DataTableWrapper>
    );
  };

  const renderAddDependency = () => {
    return (
      <Card mt={6}>
        <CardHeader p={3}>
          <Text fontWeight="medium">{`Add ${selectedTemplateItem?.Type} to ${selectedTemplateItem?.Name}`}</Text>
        </CardHeader>
        <CardBody p={3}>
          <Stack direction="y" gap={2} maxWidth="350px">
            <Controller
              render={(props: {
                onChange: (value: number) => void;
                value: number;
              }) => {
                return (
                  <FormSelect
                    value={props.value}
                    label="Sections"
                    options={dependencySectionList}
                    onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                      const value = parseInt(e.currentTarget.value, 10);
                      props.onChange(value);
                    }}
                  />
                );
              }}
              defaultValue={''}
              name="DependentSectionId"
              control={control}
            />
            {selectedTemplateItem?.Type === 'Question' && (
              <Controller
                render={(props: {
                  onChange: (value: number) => void;
                  value: number;
                }) => {
                  return (
                    <FormSelect
                      value={props.value}
                      label="Questions"
                      options={dependencyQuestionList}
                      onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                        const value = parseInt(e.currentTarget.value, 10);
                        props.onChange(value);
                      }}
                    />
                  );
                }}
                defaultValue={''}
                name="DependentQuestionId"
                control={control}
              />
            )}
          </Stack>
          <Stack direction="x" gap={2} mt={3}>
            <Button variant="secondary" onClick={() => setAddDependency(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (
                  selectedTemplateItem?.Type === 'Question' &&
                  (selectedSectionId === '' ||
                    selectedSectionId === undefined ||
                    selectedQuestionId === '' ||
                    selectedQuestionId === undefined)
                )
                  handleDependencyWarningMsg();
                else if (
                  selectedTemplateItem?.Type === 'Section' &&
                  (selectedSectionId === '' || selectedSectionId === undefined)
                )
                  handleDependencyWarningMsg();
                else handleSaveChoiceDependency();
              }}
            >
              Save
            </Button>
          </Stack>
        </CardBody>
      </Card>
    );
  };

  const renderRemoveDependencies = () => {
    let dependentSections: DependentSectionType[] = [];
    let dependentQuestions: DependentQuestionType[] = [];
    if (choiceDependency?.length > 0) {
      const dependencies = choiceDependency?.find(
        (item: any) => item.TemplateId === removeDependencyItem?.Id,
      );
      if (dependencies) {
        if (sections?.length > 0 && dependencies?.DependentSections?.length > 0)
          dependentSections = sections?.filter(item => {
            return dependencies?.DependentSections?.find(
              (dependentItem: any) => item.Id === dependentItem?.Id,
            );
          });
        // dependencies?.DependentSections;
        if (
          sections?.length > 0 &&
          dependencies?.DependentQuestions?.length > 0
        )
          dependentQuestions = sections
            ?.filter(
              item =>
                item.$type ===
                'Proteus2.Api.Domain.Surveys.Section, Proteus2.Api.Domain',
            )
            .map(item =>
              item?.Questions?.filter((questionItem: any) => {
                return dependencies?.DependentQuestions?.find(
                  (dependentItem: any) => questionItem.Id === dependentItem?.Id,
                );
              }),
            )
            .flat();
      }
    }
    const hasDependency =
      dependentSections?.length > 0 || dependentQuestions?.length > 0;

    const findParentSection = (questionItem: any) => {
      const sectionSelected = sections.find(
        item => item.Id === questionItem.SectionId,
      );
      return sectionSelected.Title;
    };

    return (
      <>
        <Card mt={6}>
          <CardHeader p={3}>
            <Text fontWeight="medium">
              {`Dependencies for ${removeDependencyItem?.Name}`}
            </Text>
          </CardHeader>
          <CardBody p={3} style={{ position: 'relative' }} minHeight={240}>
            {hasDependency ? (
              <Stack direction="x" gap={3}>
                {dependentQuestions && dependentQuestions?.length > 0 && (
                  <Box flex={1}>
                    <Text fontWeight="medium" mb={2}>
                      Questions
                    </Text>
                    <DataTableWrapper style={{ minHeight: '200px' }}>
                      <DataTable className="equal-width-columns">
                        <thead>
                          <tr>
                            <th>Section</th>
                            <th>Field Label</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {dependentQuestions?.map(
                            (questionItem: DependentQuestionType) => (
                              <tr key={questionItem.Id}>
                                <td style={{ verticalAlign: 'middle' }}>
                                  {findParentSection(questionItem)}
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>
                                  {questionItem.FieldLabel}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  <Button
                                    size="sm"
                                    variant="danger"
                                    iconAfter={
                                      <Icon.Delete title="Remove dependency" />
                                    }
                                    onClick={() =>
                                      handleRemoveDependencyWarning(
                                        'Question',
                                        questionItem,
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </DataTable>
                    </DataTableWrapper>
                  </Box>
                )}

                {dependentSections && dependentSections?.length > 0 && (
                  <Box flex={1}>
                    <Text fontWeight="medium" mb={2}>
                      Sections
                    </Text>
                    <DataTableWrapper style={{ minHeight: '200px' }}>
                      <DataTable className="equal-width-columns">
                        <thead>
                          <tr>
                            <th>Section Title</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {dependentSections?.map(
                            (item: DependentSectionType) => (
                              <tr key={item.Id}>
                                <td style={{ verticalAlign: 'middle' }}>
                                  {item?.$type ===
                                  'Proteus2.Api.Domain.Surveys.Section, Proteus2.Api.Domain'
                                    ? item.Title
                                    : item.Section.Title}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  <Button
                                    size="sm"
                                    variant="danger"
                                    iconAfter={
                                      <Icon.Delete title="Remove dependency" />
                                    }
                                    onClick={() =>
                                      handleRemoveDependencyWarning(
                                        'Section',
                                        item,
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </DataTable>
                    </DataTableWrapper>
                  </Box>
                )}
              </Stack>
            ) : (
              <EmptyState>
                <NoDataImage feedback="No data available" />
              </EmptyState>
            )}
          </CardBody>
          <CardFooter p={3} display="flex" justifyContent="flex-end">
            <Button
              variant="secondary"
              onClick={() => {
                setShowRemoveDependencyTable(false);
                setRemoveDependency(undefined);
              }}
            >
              Close dependencies
            </Button>
          </CardFooter>
        </Card>
      </>
    );
  };

  const sampleDragItems = [
    { id: 1, label: 'one' },
    { id: 2, label: 'two' },
    { id: 3, label: 'three' },
    { id: 4, label: 'four' },
    { id: 5, label: 'five' },
    { id: 6, label: 'six' },
    { id: 7, label: 'seven' },
    { id: 8, label: 'eight' },
    { id: 9, label: 'nine' },
    { id: 10, label: 'ten' },
    { id: 11, label: 'eleven' },
    { id: 12, label: 'twelve' },
  ];

  return (
    <Box as="form" height="100%" mb="50px">
      <Stack direction="y" gap={7}>
        <Grid minColWidth="300px" gap={3}>
          <FormInput
            label="Field Name"
            disabled
            register={register}
            name="FieldName"
          />
          <FormInput
            label="Original Field Label"
            disabled
            register={register}
            name="FieldLabel"
          />
          <FormInput
            label="Description of Use"
            disabled
            register={register}
            name="Description"
          />
          <FormInput
            label="Field Label"
            register={register}
            name="UpdatedFieldLabel"
          />
          <Controller
            render={(props: {
              onChange: (value: number) => void;
              value: number;
            }) => {
              return (
                <FormSelect
                  value={props.value}
                  label="Person Association"
                  noSelectOption
                  options={associatedPersons}
                  onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                    const value = parseInt(e.currentTarget.value, 10);
                    props.onChange(value);
                  }}
                />
              );
            }}
            defaultValue={''}
            name="PersonAssociation"
            control={control}
          />
        </Grid>
        <Grid minColWidth="250px" gap={3}>
          <Checkbox label="Required" register={register} name="IsRequired" />
          <Checkbox
            label="Never send to Account"
            register={register}
            name="IsNeverSentToAccount"
          />
          <Checkbox
            label="If no answer, don't send to Account"
            register={register}
            name="IsNotSentToAccountIfNoAnswer"
          />
        </Grid>
        <Stack direction="y" gap={3}>
          <Controller
            render={(props: {
              onChange: (value: number) => void;
              value: number;
            }) => {
              return (
                <RadioButton
                  onChange={() => {
                    props.onChange(0);
                  }}
                  inline
                  label="Radio Buttons"
                  name="ChoiceType"
                  checked={choiceType === 0}
                />
              );
            }}
            defaultValue={1}
            name="ChoiceType"
            control={control}
          />
          <Controller
            render={(props: {
              onChange: (value: number) => void;
              value: number;
            }) => {
              return (
                <RadioButton
                  onChange={() => {
                    props.onChange(1);
                  }}
                  inline
                  label="Checkboxes"
                  name="ChoiceType"
                  checked={choiceType === 1}
                />
              );
            }}
            defaultValue={1}
            name="ChoiceType"
            control={control}
          />
          <Controller
            render={(props: {
              onChange: (value: number) => void;
              value: number;
            }) => {
              return (
                <RadioButton
                  onChange={() => {
                    props.onChange(2);
                  }}
                  inline
                  label="Dropdown List"
                  name="ChoiceType"
                  checked={choiceType === 2}
                />
              );
            }}
            defaultValue={1}
            name="ChoiceType"
            control={control}
          />
        </Stack>
        {!showAddDependency &&
          !showRemoveDependencyTable &&
          renderTemplateOptions()}
      </Stack>
      {showAddDependency && renderAddDependency()}
      {showRemoveDependencyTable && renderRemoveDependencies()}

      {/* <DataTableWrapper style={{ minHeight: '200px' }}>
        <DragDropContext onDragEnd={() => console.log('dragged')}>
          <Droppable droppableId="questionItemLabel">
            {provider => (
              <DataTable>
                <thead>
                  <tr>
                    <th>Section Title</th>
                    <th>some column</th>
                  </tr>
                </thead>
                <tbody ref={provider.innerRef} {...provider.droppableProps}>
                  {sampleDragItems.map((item, index) => (
                    <Draggable
                      key={item.id}
                      index={item.id}
                      draggableId={`${item.id}`}
                    >
                      {provided => (
                        <tr
                          key={item.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <td>{item.label}</td>
                          <td>{item.label}</td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provider.placeholder}
                </tbody>
              </DataTable>
            )}
          </Droppable>
        </DragDropContext>
      </DataTableWrapper> */}
    </Box>
  );
}
