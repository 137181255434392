export const accept = '.xlsx,.xls,image/*,.doc,.docx,.htm,.html,.pdf';

export function isValidFile(fileExtension: string): boolean {
  const FileTypes = [
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'html',
    'htm',
    'jpeg',
    'jpg',
    'png',
    'bmp',
    'tiff',
    'gif',
  ];
  return FileTypes.indexOf(fileExtension.toLowerCase()) > -1;
}

export function getEncodedFilePath(fileUri: string): string {
  let filePath = 'root';
  const pathModules = fileUri.split('/');
  for (let i = 4; i < pathModules.length; i++) {
    filePath = filePath + '/' + pathModules[i];
  }

  //replace the fileURI as encodedURI. Special characters are not allowed inside <a href> tag
  filePath = filePath.replace(/%20/g, ' ');
  const encodedFilePath = encodeURIComponent(filePath);

  return encodedFilePath
    .replace("'", '%27')
    .replace('!', '%21')
    .replace('~', '%7E')
    .replace('(', '%28')
    .replace(')', '%29')
    .replace('.', '%2E');
}
