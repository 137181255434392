import React, { ReactElement } from 'react';
import Select from 'react-select';

type optionType = {
  value: string;
  label: any;
  abbreviation: string;
  isdCode: string;
  name: string;
};

type MultiSelectInput = {
  options: Array<optionType> | undefined;
  size?: string;
  label?: string;
  id?: string;
  onChange?: (e: any) => void;
  value?: any;
  isDisabled?: boolean;
  className?: string;
  register?: any;
  name?: string;
};

function ReactSelectWithFlags({
  options,
  onChange,
  size,
  id,
  value,
  isDisabled,
  className,
  register,
  name,
}: MultiSelectInput): ReactElement {
  const customFilter = (option: any, rawInput: string): boolean => {
    const inputValue = rawInput.toLowerCase().trim();
    if (inputValue !== '') {
      const tempItem =
        `${option?.data?.abbreviation?.toLowerCase()}${option?.data?.isdCode?.toLowerCase()}${option?.data?.name?.toLowerCase()}`?.replace(
          /\s+/g,
          '',
        );
      const replacedValue = inputValue?.replace(/\s+/g, '');
      return tempItem?.includes(replacedValue);
    } else return true;
  };
  return (
    <Select
      id={id}
      name={name}
      options={options}
      isMulti={false}
      closeMenuOnSelect={true}
      onChange={onChange}
      classNamePrefix="pr-select"
      className={className}
      filterOption={customFilter}
      isSearchable={true}
      value={value}
      getOptionLabel={(option: any) => option?.label}
      getOptionValue={(option: any) => option?.value}
      maxMenuHeight={250}
      isDisabled={isDisabled}
      ref={register && register({})}
    />
  );
}

export { ReactSelectWithFlags };
