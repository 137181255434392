import styled from 'styled-components';
import { style } from 'styled-system';

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: solid 1px ${({ theme }) => theme.colors.border.default};
  border-radius: 6px;
  overflow: hidden;
  background-color: white;

  &.hide {
    display: none;
    border: none;
  }

  &.narrow {
    max-width: 60%;
    margin-inline: auto;
  }
`;

const IframeWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
`;

export { Iframe, IframeWrapper };
