import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { LocationInput } from 'types/locations';
import { saveLocation, getLocation } from 'utils/urls';
import { getTokenWithType } from './useAuth';

export async function postLocation({
  params,
}: {
  params: LocationInput;
}): Promise<TResult> {
  const response = await fetch(
    params.Id === 0
      ? saveLocation(params.AccountId)
      : getLocation(params.AccountId, params.Id),
    {
      method: params.Id === 0 ? 'POST' : 'PUT',
      headers: {
        Authorization: getTokenWithType(),
        'content-type': 'application/json',
      },
      body: JSON.stringify(params),
    },
  );
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = LocationInput & ValidationError;
export type TVariables = LocationInput;
export type TError = {
  Message: string;
};
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;
// async function wait(millis: number) {
//   return new Promise(resolve => {
//     setTimeout(resolve, millis);
//   });
// }

function useSaveLocation(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: LocationInput) => {
      if (params.Id === 0 && params.StateProvinceId === 0) {
        delete params.StateProvinceId;
        delete params.StateProvince;
      }
      const newLocation = await postLocation({
        params: params,
      });
      if (newLocation.Message) throw newLocation;
      queryCache.invalidateQueries(['locations_list']);
      queryCache.invalidateQueries(['get_location']);
      // await wait(2000);
      return newLocation;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveLocation };
