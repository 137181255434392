import React, { ReactElement } from 'react';
import { LayoutProps, layout, SpaceProps, space } from 'styled-system';
import styled, { css } from 'styled-components';
import * as Icon from 'assets/icons';

type WizardWrapperProps = LayoutProps & SpaceProps;

const WizardWrapper = styled.section`
  ${layout};
  ${space};
  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
  background: ${({ theme }) => theme.baseColors.gray[8]};
  border-radius: ${({ theme }) => theme.radii.soft};
  min-width: 200px;

  html[data-color-mode='dark'] & {
    background: ${({ theme }) => theme.baseColors.gray[9]};
  }
`;

const TabIcon = styled.span`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: solid 1px rgba(255, 255, 255, 0.1);
  z-index: 2;
  box-shadow: ${({ theme }) => theme.shadows.medium};
`;

const TabLabel = styled.span`
  flex: 1;
  padding-left: ${({ theme }) => theme.space[3]};
  padding-right: ${({ theme }) => theme.space[3]};
`;

type TabItemProps = {
  completed?: boolean;
  firstEdit?: boolean;
  active?: boolean;
};

const TabItem = styled.button<TabItemProps>`
  position: relative;
  margin-top: ${({ theme }) => theme.space[5]};
  margin-bottom: ${({ theme }) => theme.space[5]};
  padding: 0;
  display: flex;
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  width: 100%;
  color: ${({ theme }) => theme.colors.transparent.white70};
  text-align: left;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 14px;
    transform: translateY(95%);
    width: 2px;
    height: 21px;
    background: ${({ theme }) => theme.colors.transparent.white70};
    z-index: 1;
  }

  ${({ completed }) =>
    completed &&
    css`
      ${TabIcon} {
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.primary};
        border-color: ${({ theme }) => theme.colors.primary};
      }

      &:not(:last-child)::after {
        background: ${({ theme }) => theme.colors.primary};
      }
    `}

  ${({ firstEdit }) =>
    firstEdit &&
    css`
      ${TabIcon} {
        color: ${({ theme }) => theme.baseColors.gray[8]};
        background: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.white};
      }
    `}

	${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.white};
      ${TabIcon} {
        border-width: 3px;
        border-color: ${({ theme }) => theme.colors.white};
      }
    `}
`;

type WizardProps = {
  tabs: Array<tabItems>;
};

type tabItems = {
  id: number;
  label: string;
  completed?: boolean;
  firstEdit?: boolean;
  active?: boolean;
};

function Wizard({ tabs }: WizardProps): ReactElement {
  return (
    <WizardWrapper>
      {tabs.map(tab => (
        <TabItem
          key={tab.id}
          completed={tab.completed}
          firstEdit={!tab.completed && tab.firstEdit}
          active={tab.active}
        >
          <TabIcon>
            {tab.completed ? <Icon.Check /> : null}
            {!tab.completed && tab.firstEdit ? <Icon.Edit /> : null}
          </TabIcon>
          <TabLabel>{tab.label}</TabLabel>
        </TabItem>
      ))}
    </WizardWrapper>
  );
}

export { Wizard };
