import React, {
  ReactElement,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { Table, SearchInput } from 'ui/patterns';
import { Button, Stack } from 'ui/components';
import { Flex, Text } from 'rebass/styled-components';
import * as Icon from 'assets/icons';
import { useAccounts } from 'hooks';
import useAccountsColumns from 'containers/AccountsList/useAccountsColumns';
import { Row } from 'react-table';
import { Account } from 'types/accounts';

const sortItem = { sortKey: 'CID', sortOrder: 'asc' };
const userSettings = [
  // 'Phone Display',
  'CID',
  'SID',
  'PID',
  // 'Answer Script',
  'Account Name',
  // 'Rel Manager',
  // 'City',
  // 'State',
  // 'Time',
  'Status',
  // 'Service',
];
const columnSearch = {
  Status: 'Active',
};
function useAccountsStates(showPanel: boolean) {
  const columns = useAccountsColumns(userSettings, true);
  const [accountKeywordSearch, setAccountKeywordSearch] = useState(''); // keyword search
  const [columnsSearch, setColumnsSearch] = useState<Record<string, string>>({
    ...columnSearch,
  }); // header search
  const [currentSortItem, setCurrentSortItem] = useState({ ...sortItem });
  const {
    data,
    fetchMore,
    canFetchMore,
    isFetchingMore,
    isLoading,
    isFetching,
  } = useAccounts(
    0,
    'Live',
    accountKeywordSearch,
    columnsSearch,
    currentSortItem,
  );

  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch, setColumnsSearch],
  );

  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (
        currentSortItem.sortKey === item &&
        currentSortItem.sortOrder === 'asc'
      ) {
        sortOrder = 'desc';
      }
      setCurrentSortItem({
        sortKey: item,
        sortOrder,
      });
    },
    [setCurrentSortItem, currentSortItem],
  );

  const handleAccountKeywordSearch = (value: string): void => {
    setAccountKeywordSearch(value);
  };

  const handleReset = (): void => {
    setAccountKeywordSearch('');
    setColumnsSearch({
      ...columnSearch,
    });
    setCurrentSortItem(sortItem);
  };

  const tableAccountsData = useMemo(() => {
    if (data) {
      return data.map(dataItem => dataItem.data).flat();
    }
  }, [data]);
  const totalAccountsItems = useMemo(() => {
    if (data) {
      return data[0].recordsFiltered;
    }
    return 0;
  }, [data]);
  const onEndReached = () => {
    if (tableAccountsData) {
      if (canFetchMore && tableAccountsData?.length < totalAccountsItems)
        fetchMore();
    }
  };
  useEffect(() => {
    handleReset();
  }, [showPanel]);
  return {
    handleReset,
    isFetching,
    // states
    totalAccountsItems,
    accountKeywordSearch,
    handleAccountKeywordSearch,
    accountsTableProps: {
      columns,
      data: tableAccountsData,
      isLoading: isLoading,
      hasColumnSelection: false,
      headerUpdateCallBack: headerUpdateCallBack,
      handleSortChange: handleSortChange,
      sortedItem: currentSortItem,
      columnsSearch: columnsSearch,
      userSettings: userSettings,
      onEndReached: onEndReached,
      isFetching: !!isFetchingMore,
    },
  };
}

export default function AccountsPanel({
  selectedAccountId,
  setSelectedAccountId,
  isHidden,
  showPanel,
}: {
  selectedAccountId: number;
  setSelectedAccountId: (id: number) => void;
  isHidden: boolean;
  showPanel: boolean;
}): ReactElement {
  const {
    handleReset,
    isFetching,
    // states
    totalAccountsItems,
    accountKeywordSearch,
    handleAccountKeywordSearch,
    accountsTableProps,
  } = useAccountsStates(showPanel);
  const onAccountsRowClick = (row: Row<Account>) => {
    setSelectedAccountId(row?.original?.Id);
  };

  if (isHidden) return <></>;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text fontSize={1} color="text.body">
          {accountsTableProps.data && accountsTableProps.data.length > 0 && (
            <Text mb={2}>{`Showing ${
              accountsTableProps.data.length
            } of ${totalAccountsItems}${
              isFetching ? ' | Processing' : ''
            }`}</Text>
          )}
        </Text>
        <Stack direction="x" gap={2}>
          <SearchInput
            value={accountKeywordSearch}
            handleChange={handleAccountKeywordSearch}
            placeholder="Search Accounts"
          />
          <Button
            onClick={handleReset}
            iconBefore={<Icon.Refresh title="" ariaHidden="true" />}
            variant="secondary"
          >
            Reset All
          </Button>
        </Stack>
      </Flex>

      <Table
        selectedRowId={selectedAccountId}
        onRowClick={onAccountsRowClick}
        {...accountsTableProps}
        height="450px"
        noOptions
      />
    </>
  );
}
