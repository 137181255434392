//local
export const apiBaseUrl = process.env.REACT_APP_API_END_POINT;

//qa
// export const apiBaseUrl = 'https://proteus2-api.azurewebsites.net/api/';

export const getAccountsList = apiBaseUrl + 'Accounts/grid';
export const getUserSettings = apiBaseUrl + 'users/settings/account-builder';
export const getLocationList = (accountId: number | string): string =>
  `${apiBaseUrl}accounts/${accountId}/locations/grid`;

export const deleteLocation = (locId: number, accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/locations/${locId}`;

export const getStateProvince = apiBaseUrl + 'state-province';
export const getRelationshipManagers = apiBaseUrl + 'relationship-manager';
export const getTimezone = apiBaseUrl + 'timezone';
export const getAccountStatus = apiBaseUrl + 'account-status';
export const getAccountService = apiBaseUrl + 'account-service';
export const saveAccounts = apiBaseUrl + 'accounts';

// get and save office facts
export const officeFacts = (accountId: number | string): string =>
  `${apiBaseUrl}accounts/${accountId}/office-facts`;

export const getAccount = (id: number | string): string =>
  apiBaseUrl + 'accounts/' + id;
export const uploadExternalDocument = (
  account: string,
  filename: string,
): string =>
  `${apiBaseUrl}procedures/AccountFolders/${account}/Documents/${filename}`;
export const getProcedures = (account: number): string =>
  `${apiBaseUrl}accounts/${account}/procedures`;

export const saveLocation = (account: number): string =>
  `${apiBaseUrl}accounts/${account}/locations`;
export const getLocation = (account: number | string, locId: number): string =>
  `${apiBaseUrl}accounts/${account}/locations/${locId}`;
// contact list
export const getContactList = (account: number | string): string =>
  `${apiBaseUrl}accounts/${account}/contacts/grid`;
export const contactItem = (accountId: number, contactId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/contacts/${contactId}`;
// get custom table list
// export const getCustomTable = (accountId: number): string =>
//   `${apiBaseUrl}accounts/${accountId}/lookups`;
// get the custom records
export const getCustomRecords = (accountId: number, tableId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/lookups/${tableId}/grid`;
// add contact
export const addContact = (account: number): string =>
  `${apiBaseUrl}accounts/${account}/contacts`;
// add records in custom table(provider table)
export const addRecords = (account: number, tableId: number): string =>
  `${apiBaseUrl}accounts/${account}/lookups/${tableId}`;
//edit records in custom table(provider table)
export const editRecords = (
  account: number,
  tableId: number,
  recordId: string,
): string => `${apiBaseUrl}accounts/${account}/lookups/${tableId}/${recordId}`;
// custom table - get add
export const customTable = (accountId: string | number): string =>
  `${apiBaseUrl}accounts/${accountId}/lookups`; //providerlist

export const providerList = (accountId: string | number): string =>
  `${apiBaseUrl}accounts/${accountId}/lookups/providerlist`;

// delete records in custom table confirm
export const deleteRecordConfirm = (
  account: number,
  tableId: number,
  recordId: string,
): string =>
  `${apiBaseUrl}accounts/${account}/lookups/${tableId}/${recordId}/is-used-in-branching-question`;
// get xml-tags
export const xmlTags = `${apiBaseUrl}xml-tags`;
// get tag-mappings
export const tagMappings = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/tag-mappings`;
// get ftp settings
export const ftpSettings = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/ftpsettings`;
export const customTableCsv = (
  accountId: string | number,
  lookupId: string | number,
): string => `${apiBaseUrl}accounts/${accountId}/lookups/v1/${lookupId}/csv`;
// forms-list
export const formsList = `${apiBaseUrl}surveys/surveygrid`;
// get the published procedure list
export const getPublishedProcedures = (
  accountId: number,
): string => `${apiBaseUrl}accounts/${accountId}/published-procedures
`;
// save copy account
export const saveCopyAccount = `${apiBaseUrl}accounts/v1/copy`;
// save import account
export const saveImportAccount = (
  accountId: number,
): string => `${apiBaseUrl}accounts/v1/${accountId}/import
`;
// get call types
export const getCallTypes = `${apiBaseUrl}call-types`;
// get form details
export const getFormDetails = (formId: number): string =>
  `${apiBaseUrl}surveys/${formId}`;
// get global surveys
export const getGlobalSurvey = `${apiBaseUrl}surveys/global/sections/grid`;
// save new form
export const saveNewForm = `${apiBaseUrl}surveys`;
// get questions list
export const getQuestionsList = `${apiBaseUrl}Questions/grid`;
// get text formats
export const getTextFormats = `${apiBaseUrl}questions/details/formats`;
// get look ups for an account
export const getLookups = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/lookups`;
// get custom table details along with records
export const getCustomTableWithRecords = (
  accountId: number,
  lookupId: number,
): string =>
  `${apiBaseUrl}accounts/${accountId}/lookups/${lookupId}?includeRecords=true`;
export const getCheckLinks = (
  accountId: string | null,
  surveyId: string | null,
): string => `${apiBaseUrl}accounts/${accountId}/links?surveyId=${surveyId}`;
// get question types
export const getQuestionTypes = `${apiBaseUrl}questions/types`;
// get question specifications
export const getQuestionSpecifications = (
  questionTypeName: string | undefined,
): string =>
  `${apiBaseUrl}questions/types/${questionTypeName}/options/specification`;
// get question specification for lookup
export const getQuestionSpecificationsForLookup = (
  questionTypeName: string | undefined,
): string =>
  `${apiBaseUrl}questions/types/${questionTypeName}/options/specification/template`;
// add question
export const addQuestionToRepository = `${apiBaseUrl}questions`;
// edit question
export const editQuestionInRepository = (questionId: number): string =>
  `${apiBaseUrl}questions/${questionId}`;
// copy section
export const copySection = (id: number | string): string =>
  `${apiBaseUrl}surveys/copy/sections/${id}`;
export const copyQuestion = (qId: number): string =>
  `${apiBaseUrl}surveys/copy/questions/${qId}`;
export const releaseNotes = `${apiBaseUrl}release-note/2`;
// procedure builder gte procedures
export const getProceduresInProcedureBuilder = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/v1/getallprocedure`;
//get procedure details
export const getProcedureDetails = (
  accountId: number | undefined,
  procedureId: number | undefined,
): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/${procedureId}`;
export const hideDisplayProcedure = (
  accountId: number | undefined,
  procedureId: number | undefined,
): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/v1/${procedureId}`;
// // hide and display procedure
export const hideDisplayProcedureVisibility = (
  accountId: number | undefined,
  procedureId: number | undefined,
): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/${procedureId}/visibility`;

//hide and display multiple procedures
export const hideDisplayMultipleProcedureVisibility = (
  accountId: number | undefined,
): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/change-visibility`;
// rename procedure
export const renameProcedureUrl = (
  accountId: number | undefined,
  procedureId: number | undefined,
): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/v1/${procedureId}/rename`;
export const procedureLinks = (
  accountId: number,
  start: number,
  pageLength: number,
): string =>
  `${apiBaseUrl}procedure-builder/accounts/procedures-link?start=${start}&take=${pageLength}&accountId=${accountId}`;
export const searchProcedureLinks = (
  accountId: number,
  start: number,
  pageLength: number,
  keyword: string,
): string =>
  `${apiBaseUrl}procedure-builder/accounts/procedures-link?start=${start}&take=${pageLength}&accountId=${accountId}&accountName=${keyword}`;
// get reference for procedures for rename(isRemove: false) and delete(isRemove: true)
export const getReference = (accountId: number, isRemove: boolean): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/getreferences?isRemoving=${isRemove}`;
// delete a procedure
export const deleteProcedure = (
  accountId: number,
  operation: 'delete' | 'v1/archive',
): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/${operation}`;
// change to global procedure
export const convertToGlobalProc = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/v1/change`;
// convert to normal procedure
export const convertToNormalProc = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/v1/convert-to-normal`;
// clone procedure
export const cloneProc = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/clone`;
export const linkProcedures = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/v1/link`;
export const undoProcedures = (
  accountId: number,
  procedureId: number,
): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/${procedureId}/undo-procedure
`;
// version history
export const versionHistory = (
  accountId: number,
  procedureId: number | undefined,
): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/version/${procedureId}`;
export const brokenLinks = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/v1/getbrokenlinksforprocedures`;
export const createProcedure = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/create`;
export const externalLinksForAccount = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/v1/getexternallinksforaccount`;
// get the content of procedure versions
export const procedureVersions = (
  accountId: number,
  procedureId: number,
  version: number | undefined,
): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/${procedureId}/${version}`;
// restore version
export const restoreVersions = (
  accountId: number,
  fileId: string,
  version: number,
): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/restore?fileId=${fileId}&snapshotTime=${version}`;
// save procedure
export const saveProcedure = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/save`;
// publish procedure
export const publishProcedure = (
  accountId: number,
  makeProceduresVisible?: boolean,
): string =>
  makeProceduresVisible
    ? `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/publish?makeProceduresVisible=${makeProceduresVisible}`
    : `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/publish`;
export const AccountListForEmbedProcedure = (
  accountId: number,
  start: number,
  pageLength: number,
  keyword: string,
): string =>
  `${apiBaseUrl}procedure-builder/accounts/procedures?start=${start}&take=${pageLength}&accountId=${accountId}&accountName=${keyword}&includeCurrentAccount=true`;
export const AccountListForEmbedProcedureForImport = (
  accountId: number,
  start: number,
  pageLength: number,
  keyword: string,
): string =>
  `${apiBaseUrl}procedure-builder/accounts/procedures/v1/import-procedure?start=${start}&take=${pageLength}&accountId=${accountId}&accountName=${keyword}&includeCurrentAccount=true`;
export const proceduresForEmbedProcedure = (
  accountId: number,
  showHidden: boolean,
): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures?excludeNonVisible=${showHidden}`;
export const previewForEmbedProcedure = (
  id: string,
  procedureName: string,
): string =>
  `${apiBaseUrl}procedures/V1/accountfolders/${id}/Policies/${procedureName}/isEditable=false`;
//For saving and getting exisiting layouts
export const layout = (): string =>
  `${apiBaseUrl}procedure-builder/procedures/layout`;
//For saving and getting existing tables
export const table = (): string =>
  `${apiBaseUrl}procedure-builder/procedures/table`;
// import procedure
export const getGlobalPrtocedures = (accountId: number | string): string =>
  `${apiBaseUrl}procedure-builder/procedures/global/${accountId}`;
export const getAccounts = (accountId: number | string): string =>
  `${apiBaseUrl}accounts/${accountId}`;
export const getExistingProcedures = ({
  start,
  pageLength,
  accountId,
}: {
  start: number;
  pageLength: number;
  accountId: number | string;
}): string =>
  `${apiBaseUrl}procedure-builder/accounts/procedures?start=${start}&take=${pageLength}&accountId=${accountId}`;
export const archivedProcedures = (accountId: number | string): string =>
  `${apiBaseUrl}procedure-builder/procedures/archived/${accountId}`;
//import and publish
export const importProcedure = (accountId: number | string): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/v1/import`;
//import and hide
export const importProcedureInvisible = (accountId: number | string): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/v1/import?importAsInvisible=true`;
export const linkedProcedures = (procedureId: number | string): string =>
  `${apiBaseUrl}procedure-builder/procedures/global/${procedureId}/linked-accounts`;
export const getFileTree = (account: string): string =>
  `${apiBaseUrl}procedure-builder/account/${account}/get-blob-files`;
export const createFolder = `${apiBaseUrl}procedure-builder/procedures/create-blob-directory`;
export const uploadFile = `${apiBaseUrl}procedure-builder/procedures/upload-blob-files`;
export const deleteFile = `${apiBaseUrl}procedure-builder/procedures/delete-blob-files`;
export const getAccountsForImportFiles = ({
  start,
  pageLength,
  accountId,
  searchKey,
}: {
  start: number;
  pageLength: number;
  accountId: number | string;
  searchKey?: string;
}): string =>
  `${apiBaseUrl}procedure-builder/accounts/import/exclude/${accountId}?start=${start}&take=${pageLength}&searchKey=${searchKey}`;
export const importBlobFiles = `${apiBaseUrl}/procedure-builder/procedures/import-blob-files`;
export const copyForm = (formId: string | number): string =>
  `${apiBaseUrl}surveys/copy/${formId}`;
export const getLinksForDismissal = (
  accountId: number,
  payloadString: string | undefined,
): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/v1/links?${payloadString}`;
export const dismissLinks = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/update-link`;
//To duplicate imported procedures (visible and publish)
export const copyImportedProcedures = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/v1/force-copy`;
//To duplicate imported procedures (invisible)
export const copyImportedProceduresInvisible = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/v1/force-copy?importAsInvisible=true`;
export const saveAsPdf = (accountId: number, payload: string): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure/get-html?${payload}`;
export const saveAsWord = (accountId: number, payload: string): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure/get-document?${payload}`;
//Advanced Procedure search
export const getProcedureSearchList = `${apiBaseUrl}procedures/advance-search`;
export const saveAdvancedProcedureSearch = `${apiBaseUrl}procedures/export-to-csv`;

//Advanced Detail Search
export const getDetailSearchList = `${apiBaseUrl}questions/advance-search`;
export const saveAdvancedDetailSearch = `${apiBaseUrl}questions/export-to-csv`;

//Advanced Question Search
export const getQuestionSearchList = `${apiBaseUrl}questions/question-search`;
export const saveAdvanceQuestionSearch = `${apiBaseUrl}questions/questionSearch-to-csv`;

//Advanced Section Search
export const getSectionSearchList = `${apiBaseUrl}questions/section-search`;
export const saveAdvanceSectionSearch = `${apiBaseUrl}questions/sectionSearch-to-csv`;

export const cleanProcedureTemplate = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/clean-procedure`;
export const forceRenameProcedureUrl = (
  accountId: number | undefined,
  procedureId: number | undefined,
): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/v1/${procedureId}/force-rename`;
export const resetPolicyMenu = (
  accountId: number | undefined,
  procedureId: number | undefined,
): string =>
  `${apiBaseUrl}accounts/${accountId}/procedure-builder/procedures/${procedureId}/reset`;
//user permissions
export const getUserPermissions = apiBaseUrl + 'accounts/permissions';

//For deleting and renaming title of tables and layouts
export const deleteTemplate = `${apiBaseUrl}procedure-builder/procedures/blob/file`;
export const editTemplateName = (force: boolean): string =>
  `${apiBaseUrl}procedure-builder/procedures/blob/file-directory/rename?force=${force}`;

//url validation in text questions
export const urlValidation = (url: string): string =>
  `${apiBaseUrl}accounts/validatelink?url=${url}`;

export const updateFingerPrint = (accountId: number): string =>
  `${apiBaseUrl}ftpsettings/update-fingerprint/${accountId}`;

export const deleteTemplateGroup = `${apiBaseUrl}procedure-builder/procedures/blob/folder`;
export const editTemplateGroupName = `${apiBaseUrl}procedure-builder/procedures/blob/group/rename`;

// version api call in iframe
export const getVersionIframe = `${apiBaseUrl}version`;

export const utcTime = `${apiBaseUrl}users/get-utc-time`;

//Telephony integration
export const getBranchOutGroup = `${apiBaseUrl}twiliobranchout`;
export const getRoutingSubGroup = `${apiBaseUrl}twilioworkflow`;
export const getTwilioPermissionDetails = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/twilio-info`;
// to get country details
export const getCountry = `${apiBaseUrl}country`;
//to get telephony grid details
export const getTelephonyGrid = `${apiBaseUrl}accounts/telephony-logs  `;
export const getPhoneTypes = `${apiBaseUrl}twilio-phone-types`;
export const saveTelephony = (accountId: number): string =>
  `${apiBaseUrl}accounts/${accountId}/update-twilio`;
