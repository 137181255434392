import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  FlexProps,
  space,
  SpaceProps,
} from 'styled-system';
import styled, { css } from 'styled-components';

type StackProps = SpaceProps &
  FlexProps &
  LayoutProps &
  FlexboxProps & {
    direction?: 'y' | 'x';
    gap: number;
  };

const Stack = styled.div<StackProps>`
  display: flex;
  justify-content: flex-start;
  ${({ direction, gap }) =>
    direction === 'y' &&
    css`
      flex-direction: column;
      & > * + * {
        margin-top: ${({ theme }) => theme.space[gap]};
      }
    `}

  ${({ direction, gap }) =>
    direction === 'x' &&
    css`
      & > * + * {
        margin-left: ${({ theme }) => theme.space[gap]};
      }
    `}
  ${space};
  ${flexbox};
  ${layout};
`;
export { Stack };
