import styled, { keyframes } from 'styled-components';

const SpinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  --spinner-hsl: var(--color-neutral-contrast-03-hsl);
  --a: 80%;
  border-radius: ${({ theme }) => theme.radii && theme.radii.circle};
  width: 10em;
  height: 10em;
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid var(--color-neutral-contrast-03);
  border-right: 1.1em solid var(--color-neutral-contrast-03);
  border-bottom: 1.1em solid var(--color-neutral-contrast-03);
  border-left: 1.1em solid
    ${({ theme }) => theme.colors && theme.colors.primary};
  transform: translateZ(0);
  /* animation: ${SpinnerAnimation} 1.1s infinite linear; */
  animation-name: ${SpinnerAnimation};
  animation-duration: 1.1s !important;
  animation-timing-function: linear !important;
  animation-iteration-count: infinite !important;
  
  &::after {
    border-radius: ${({ theme }) => theme.radii && theme.radii.circle};
    width: 10em;
    height: 10em;
  }
`;

export { Spinner };
