import React, { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { getHSL } from 'utils/getHSL';
import getCssVar from '../../../utils/getCssVar';

export const StyledDropdownMenu = styled.div<DropdownMenuProps>`
  --bg: ${({ theme }) => theme.baseColors.white};
  background-clip: padding-box;
  background-color: var(--bg);
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: ${({ theme }) => theme.radii.rounded};
  box-shadow: ${({ theme }) => theme.shadows.large};
  list-style: none;
  margin-top: 2px;
  position: absolute;
  top: 100%;
  min-width: 180px;
  max-width: 260px;
  z-index: 1050;
  right: 0px;
  left: auto;
  padding: 5px 0px !important;

  html[data-color-mode='dark'] & {
    --bg: ${({ theme }) => theme.baseColors.gray[8]};
  }

  .procedure-filter-dropdown & {
    padding: 0 !important;
    min-width: 272px;
  }

  .dd-group-selection & {
    min-width: 260px;
    max-width: 300px;
  }

  ${({ position }) =>
    position === 'left' &&
    css`
      top: 100%;
      left: auto;
    `}

  ${({ position }) =>
    position === 'left-top' &&
    css`
      top: 0;
      right: 100%;
      left: auto;
      margin-top: 0;
      margin-right: ${({ theme }) => theme.space[1]};
    `}

  ${({ position }) =>
    position === 'right' &&
    css`
      top: 100%;
      left: 0;
      right: auto;
    `}

  ${({ position }) =>
    position === 'right-top' &&
    css`
      top: 0;
      left: 100%;
      right: auto;
      margin-top: 0;
      margin-left: ${({ theme }) => theme.space[1]};
    `}

  & .dropdownMenuList {
    & > li {
      .dd-divider {
        height: 0;
        margin: 0.5rem 0;
        overflow: hidden;
        border-top: 1px solid ${({ theme }) => theme.colors.border.default};
      }
    }

    & > li button {
      --px: ${({ theme }) => theme.space[3]};
      --py: ${({ theme }) => theme.space[1]};
      --h: ${() => getHSL('h', getCssVar('--color-neutral-contrast-03'))};
      --s: ${() => getHSL('s', getCssVar('--color-neutral-contrast-03'))};
      --l: ${() => getHSL('l', getCssVar('--color-neutral-contrast-03'))};
      display: inline-flex;
      align-items: center;
      --a: 0%;
      --bg: hsla(var(--h), var(--s), var(--l), var(--a));
      --switch: calc((var(--l) - var(--contrast-threshold)) * -100);
      /* display: flex;
      justify-content: space-between; */
      background-color: var(--bg);
      font-size: 14px;
      padding: var(--py) var(--px);
      border: 0;
      width: 100%;
      text-align: left;
      color: hsl(0, 0%, var(--switch));
      transition: all 200ms ease-in-out;

      &:hover,
      &:focus {
        outline: none;
        cursor: pointer;
        --h: var(--primary-h);
        --s: var(--primary-s);
        --l: var(--primary-l);
        --a: 100%;
      }

      .pr-icon {
        opacity: 0.6;
        margin-right: ${({ theme }) => theme.space[2]};
      }

      .pr-button-label {
        line-height: 1;
      }
    }
  }
`;

type DropdownMenuProps = {
  children?: string | ReactNode;
  position?: 'right-top' | 'right' | 'left-top' | 'left';
};

function DropdownMenu({ children, position }: DropdownMenuProps): ReactElement {
  return (
    <StyledDropdownMenu position={position}>{children}</StyledDropdownMenu>
  );
}

export default DropdownMenu;
