import { useMemo, useState, useEffect, useCallback, useReducer } from 'react';
import useQuestionSearchColumns from './useQuestionSearchColumns';
import {
  useGetAdvancedQuestionSearch,
  useSaveAdvancedQuestionSearch,
} from 'hooks';
import {
  QuestionSearchParams,
  SearchRuleItem,
  QuestionOptionsType,
} from 'types/accountsSearch';
import { showErrorToast } from 'utils/showToast';
import useAdvancedSearchStore from 'store/useAdvancedSearchStore';
import { useHistory } from 'react-router-dom';

const questionColumnData = [
  'CID',
  'PID',
  'SID',
  'Name',
  'SectionTitle',
  'QuestionType,',
  'FieldName',
  'FieldLabel',
];

const searchItem: SearchRuleItem = {
  value: '',
  expression: 'Contains',
  operator: 'AND',
  regex: false,
  orderable: true,
};

const sortItem = { sortKey: '', sortOrder: '' };

const questionInitialState: QuestionOptionsType = {
  includeGlobal: true,
  includeInactive: false,
  includeHidden: false,
};

function reducer(
  state: QuestionOptionsType,
  action: {
    type: keyof QuestionOptionsType | 'reset';
    newState?: QuestionOptionsType;
  },
) {
  switch (action.type) {
    case 'includeGlobal': {
      return {
        ...state,
        includeGlobal: !state.includeGlobal,
      };
    }
    case 'includeInactive': {
      return {
        ...state,
        includeInactive: !state.includeInactive,
      };
    }
    case 'includeHidden': {
      return {
        ...state,
        includeHidden: !state.includeHidden,
      };
    }
    case 'reset':
      if (action.newState) return { ...action.newState };
      return { ...questionInitialState };
    default:
      throw new Error();
  }
}
const initialSearchList: Array<SearchRuleItem> = [{ ...searchItem }];

export default function useQuestionSearch() {
  const history = useHistory();

  // search block states
  const [searchList, setSearchList] = useState<Array<SearchRuleItem>>([
    ...initialSearchList.map(item => item),
  ]);

  //checkbox states
  const [questionOptions, setQuestionOptions] = useReducer(reducer, {
    ...questionInitialState,
  });
  const [searchParams, setSearchParams] = useState<QuestionSearchParams>();
  const [columnsSearch, setColumnsSearch] = useState({}); // header search
  const [currentSortItem, setCurrentSortItem] = useState(sortItem);

  const {
    data,
    fetchMore,
    canFetchMore,
    isFetching,
    isLoading: advancedSearchloading,
    isFetchingMore,
  } = useGetAdvancedQuestionSearch(
    searchParams,
    columnsSearch,
    currentSortItem,
  );

  const pageNumber = data && data[data?.length - 1]?.pageNumber;

  const [saveInterAccountSearchList] = useSaveAdvancedQuestionSearch();

  const handleDownloadQuestionSearchResult = () => {
    saveInterAccountSearchList({
      searchParams,
      columnsSearch,
      currentSortItem,
      pageNumber,
    });
  };

  const tableData = useMemo(() => {
    if (data) {
      return data
        .map(dataItem => dataItem?.data)
        .flat()
        .filter(item => !!item);
    }
    return;
  }, [data]);

  const onEndReached = () => {
    if (canFetchMore) fetchMore();
  };

  const currentRecords = useMemo(() => {
    if (data) {
      return data.map(dataItem => dataItem?.data).flat();
    }
  }, [data]);

  const totalRecords = useMemo(() => {
    if (data) {
      return data[0] && data[0].recordsTotal;
    }
    return 0;
  }, [data]);
  const columnList = useQuestionSearchColumns([...questionColumnData.slice()]);

  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch],
  );

  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (
        currentSortItem.sortKey === item &&
        currentSortItem.sortOrder === 'asc'
      ) {
        sortOrder = 'desc';
      }
      setCurrentSortItem({
        sortKey: item,
        sortOrder,
      });
    },
    [setCurrentSortItem, currentSortItem],
  );

  const resetQuestionSearch = () => {
    setSearchList([{ ...searchItem }]);
    setColumnsSearch({});
    setCurrentSortItem(sortItem);
    setQuestionOptions({ type: 'reset' });
    setSearchParams(undefined);
  };

  const toggleQuestionOption = (key?: keyof QuestionOptionsType) => () => {
    if (!key) return setQuestionOptions({ type: 'reset' });
    return setQuestionOptions({ type: key });
  };

  function searchButtonClick() {
    if (searchList.filter(item => item.value.trim().length === 0).length > 0) {
      showErrorToast({
        message: 'Please Enter a keyword',
      });
    } else {
      setSearchParams({
        searchList: JSON.parse(JSON.stringify(searchList.slice())),
        ...questionOptions,
      });
    }
  }

  const { searchStore, setParams } = useAdvancedSearchStore();

  useEffect(() => {
    if (searchStore) {
      if (searchStore.questionSearchParams) {
        const { includeGlobal, includeInactive, searchList, includeHidden } =
          searchStore.questionSearchParams;
        setQuestionOptions({
          type: 'reset',
          newState: {
            includeGlobal,
            includeInactive,
            includeHidden,
          },
        });
        setSearchList(searchList);
      }
      if (searchStore.sortItem) {
        setCurrentSortItem(searchStore.sortItem);
      }
      if (searchStore.columnSearch) {
        setColumnsSearch(searchStore.columnSearch);
      }
      setSearchParams(searchStore.questionSearchParams);
    }
  }, [searchStore]);

  function onClickToolTip(
    path: string,
    params: Record<string, number | string>,
  ) {
    setParams({
      questionSearchParams: searchParams,
      columnSearch: columnsSearch,
      sortItem: currentSortItem,
    });
    // history.push(path, params);
    localStorage.setItem('fromSearch', JSON.stringify(params?.Pid));
    window.open(path);
  }

  return {
    totalQuestionRecordsCount: totalRecords,
    listedQuestionRecordCount: currentRecords,
    isQuestionSearchFetching: isFetching,
    questionOptions,
    toggleQuestionOption,
    resetQuestionSearch,
    handleDownloadQuestionSearchResult,
    questionTableProps: {
      columns: columnList,
      columnsSearch: columnsSearch,
      data: tableData,
      headerUpdateCallBack: headerUpdateCallBack,
      sortedItem: currentSortItem,
      handleSortChange: handleSortChange,
      isLoading: advancedSearchloading,
      noOptions: true,
      onEndReached: onEndReached,
      isFetching: !!isFetchingMore,
      onRedirect: onClickToolTip,
    },
    questionSearch: {
      searchList,
      setSearchList,
      searchButtonClick,
    },
  };
}
