import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { convertToNormalProc } from 'utils/urls';
import { getTokenWithType } from './useAuth';
import { ProcedureListType } from 'types/procedureBuilder';

export async function convertProcedure({
  params,
}: {
  params: TVariables;
}): Promise<TResult> {
  const response = await fetch(convertToNormalProc(params.accountId), {
    method: 'PUT',
    headers: {
      Authorization: getTokenWithType(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(params.procedureIds),
  });
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = string;
export type TVariables = {
  procedureIds: Array<number>;
  accountId: number;
};
export type TError = {
  message: string;
};
export type TSnapshot = unknown;

function useConvertToNormalProcedure(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: TVariables) => {
      const fetchResponse = await convertProcedure({
        params: params,
      });
      // if (fetchResponse) {
      queryCache.invalidateQueries(['get_procedure_builder_procedures'], {
        refetchInactive: true,
      });
      queryCache.invalidateQueries(['get_procedure'], {
        refetchInactive: true,
      });
      queryCache.invalidateQueries(['get_global_procedures'], {
        refetchInactive: true,
      });
      // }
      return fetchResponse;
    },
    {
      onMutate: params => {
        const old: Array<ProcedureListType> | undefined =
          queryCache.getQueryData([
            'get_procedure_builder_procedures',
            params.accountId,
          ]);
        let newData: Array<ProcedureListType> | undefined = [];
        params?.procedureIds?.forEach(id => {
          newData = old?.map(item => {
            if (item.Procedure.Id === id) {
              return {
                ...item,
                Procedure: {
                  ...item.Procedure,
                  IsGlobal: false,
                },
              };
            }
            return {
              ...item,
            };
          });
        });
        queryCache.setQueryData(
          ['get_procedure_builder_procedures', params.accountId],
          newData,
        );
        return () =>
          queryCache.setQueryData(
            ['get_procedure_builder_procedures', params.accountId],
            old,
          );
      },
      onError: (err, params, rollback: () => void) => {
        rollback && rollback();
      },
      throwOnError: true,
    },
  );
}

export { useConvertToNormalProcedure };
