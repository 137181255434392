import { useMutation, MutationResultPair, queryCache } from 'react-query';
import {
  editRecords as deleteRecord,
  deleteRecordConfirm,
} from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function deleteCustomTableRecords({
  params,
}: TArguments): Promise<TResult> {
  const response = await fetch(
    params.type === 'confirm'
      ? deleteRecordConfirm(params.accountId, params.tableId, params.recordId)
      : deleteRecord(params.accountId, params.tableId, params.recordId),
    {
      method: params.type === 'confirm' ? 'GET' : 'DELETE',
      headers: {
        Authorization: getTokenWithType(),
        'Content-Type': 'application/json',
      },
    },
  );
  if (params.type === 'confirm') return response.json();
  return new Promise((resolve, reject) => {
    if (response.status >= 200 && response.status <= 210) resolve(true);
    else reject(false);
  });
}

export type TResult = boolean;
export type TError = { Message: string };
export type TArguments = {
  params: any;
};
export type TVariables = {
  accountId: number;
  tableId: number;
  recordId: string;
  type: string;
};

function useDeleteRecordsCustomTable(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  unknown
> {
  return useMutation(async (params: any) => {
    const deleteRecordResult = await deleteCustomTableRecords({
      params: params,
    });
    if (params.type === 'delete') {
      queryCache.invalidateQueries('custom_records');
      queryCache.invalidateQueries(['custom_table']);
    }

    return deleteRecordResult;
  });
}

export { useDeleteRecordsCustomTable };
