import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import { Flex } from 'rebass/styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { confirmAlert } from 'utils/confirm-alert';
import { MainContent, PageHeader, Loader, SlidePanel } from 'ui/patterns';
import { Button } from 'ui/components';
import {
  useDocumentTitle,
  useGetAccountInfo,
  useProcedureLinks,
  useGetProcedureDetails,
  useLinkProcedures,
} from 'hooks';
import * as Icon from 'assets/icons';
import {
  ProcedureList as ProcedureType,
  SelectedAccounts,
} from 'types/procedureBuilder';
import { showErrorToast, showSuccessToast } from 'utils/showToast';

import AccountsList from './AccountsList';
import SelectedProceduresList from './SelectedProcedureList';
import PreviewPanel from './PreviewPanel';

export default function LinkProcedure({
  showPanel,
  togglePanel: toggle,
}: // accountId,
{
  showPanel: boolean;
  togglePanel: () => void;
  // accountId: number;
}): ReactElement {
  // const history = useHistory<{ ID: number }>();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(showPanel);
    }, 10);
  }, [showPanel]);

  function togglePanel() {
    setIsVisible(false);
    setTimeout(() => {
      toggle();
    }, 500);
  }

  const params = useParams<{
    accountId: string;
  }>();
  const accountId = parseInt(params.accountId);

  const [checkedAccounts, setCheckedAccounts] = useState<
    Array<SelectedAccounts>
  >([]);
  const [previewProcedure, setPreviewProcedure] = useState(false);
  const [procedureListCount, setProcedureListCount] = useState(0);
  const [procedureHeader, setProcedureHeader] = useState(false);
  const [procedureHeaderData, setProcedureHeaderData] = useState<any>({});
  const [keywordSearch, setKeywordSearch] = useState('');
  const [selectedIds, setSelectetIds] = useState<{
    accountId: number;
    procedureId: number;
  }>();
  const [checkState, setCheckState] = useState(false);

  const [postLinkedProcedures, { isLoading: isLinkProcedureLoading }] =
    useLinkProcedures();

  const { selectedAccountId, selectedProcedureId } = useMemo(() => {
    const selectedAccounts = checkedAccounts.filter(item => {
      const filteredItems = item?.accountProcedures?.filter(
        accountItem => accountItem.isChecked === true,
      );

      return filteredItems && filteredItems.length > 0;
    });
    if (selectedAccounts && selectedAccounts.length === 1) {
      const selectedProcedures =
        selectedAccounts[0] &&
        selectedAccounts[0].accountProcedures &&
        selectedAccounts[0].accountProcedures.filter(
          item => item.isChecked === true,
        );
      if (selectedProcedures && selectedProcedures.length === 1)
        return {
          selectedAccountId: selectedAccounts[0].id,
          selectedProcedureId: selectedProcedures[0].ProcedureId,
        };
    }
    return {
      selectedAccountId: undefined,
      selectedProcedureId: undefined,
    };
  }, [checkedAccounts]);

  const openPreviewProcedure = (
    accountId: number | undefined,
    procedureId: number | undefined,
  ) => {
    setPreviewProcedure(true);
    if (procedureId && accountId)
      setSelectetIds({
        procedureId: procedureId,
        accountId: accountId,
      });
  };

  const { data: procedureDetails, isLoading: isProcedureDetailsLoading } =
    useGetProcedureDetails({
      accountId: selectedAccountId,
      procedureId: selectedProcedureId,
    });

  const closePreviewProcedure = () => {
    setPreviewProcedure(false);
  };
  const { data: accountInfo, isLoading: isAccountLoading } =
    useGetAccountInfo(accountId);
  const {
    data: procedureList,
    fetchMore,
    canFetchMore,
    isLoading: isProcedureDataLoading,
    isFetching,
  } = useProcedureLinks(accountId, keywordSearch);

  const existingAccounts = useMemo(() => {
    if (procedureList) {
      return procedureList?.flat();
    } else return [];
  }, [procedureList]);
  // console.log(
  //   'lastGroup, allGroups: existingAccounts',
  //   existingAccounts,
  //   procedureList,
  // );

  const [expanded, setExpanded] = useState(() => {
    return existingAccounts.map(item => false);
  });

  const isLoading = isAccountLoading || isProcedureDataLoading || isFetching;

  const onEndReached = () => {
    if (canFetchMore) fetchMore();
  };

  const linkProcedures = () => {
    let checkedProcedures = checkedAccounts
      ?.map(accounts =>
        accounts?.accountProcedures?.map(procedure => {
          if (procedure.isChecked) {
            return procedure.Procedure;
          } else return;
        }),
      )
      .flat();
    checkedProcedures = checkedProcedures?.filter(item => item !== undefined);
    confirmAlert({
      message: 'Are you sure you want to link the procedure(s)?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            postLinkedProcedures(
              { accountId, checkedProcedures },

              {
                onSuccess: mutationResult => {
                  if (mutationResult && mutationResult instanceof Array)
                    showErrorToast({
                      message: mutationResult[0],
                    });
                  else {
                    showSuccessToast({
                      message: 'Procedure(s) Linked Successfully.',
                    });
                    setCheckedAccounts([]);
                    collapseAll();
                    togglePanel();
                  }
                },
                onError: e => {
                  showErrorToast({
                    message: `${'An error occurred trying to save your changes, please try again.'} ${e}`,
                  });
                },
              },
            );
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  const pageActions = [
    {
      action: 'Link',
      icon: <Icon.LinkedProcedure />,
      disabled: procedureListCount === 0 ? true : false,
      handleButtonClick: () => linkProcedures(),
    },
  ];

  const accountNumber = accountInfo?.accountInfoReturn
    ? `${accountInfo.accountInfoReturn?.CID}${'-'}${
        accountInfo.accountInfoReturn?.SID
      }${'-'}${accountInfo.accountInfoReturn?.PID}`
    : '';

  const accountName = accountInfo?.accountInfoReturn
    ? accountInfo.accountInfoReturn.Name
    : '';

  const onClickExpanded = (index: number, opened: boolean) => {
    const newExpanded = [...expanded];
    newExpanded[index] = opened;
    setExpanded([...newExpanded]);
  };

  const collapseAll = () => {
    setExpanded(existingAccounts.map(item => false));
  };

  const reset = () => {
    togglePanel && togglePanel();
    setCheckedAccounts([]);
    collapseAll();
    setKeywordSearch('');
    setCheckState(false);
  };

  const panelActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          reset();
        }}
      >
        Cancel
      </Button>
      <Button
        disabled={(() => {
          const checkedProcedures = checkedAccounts
            ?.map(accounts =>
              accounts?.accountProcedures?.map(procedure => {
                if (procedure.isChecked) {
                  return procedure.Procedure;
                } else return;
              }),
            )
            .flat();
          if (checkedProcedures.length > 0) return false;
          return true;
        })()}
        onClick={linkProcedures}
        variant="primary"
      >
        {'Link'}
      </Button>
    </>
  );

  return (
    <SlidePanel
      open={isVisible}
      onClose={() => {
        reset();
      }}
      size="lg"
      title="Link Procedure"
      actions={panelActions()}
    >
      <Flex flexDirection="column" height="100%">
        <Flex height="100%" flex="1 1 auto">
          <AccountsList
            checkState={checkState}
            setCheckState={setCheckState}
            keywordSearch={keywordSearch}
            procedureListData={existingAccounts}
            checkedAccounts={checkedAccounts}
            setCheckedAccounts={setCheckedAccounts}
            onEndReached={onEndReached}
            setProcedureHeader={setProcedureHeader}
            setProcedureHeaderData={setProcedureHeaderData}
            setKeywordSearch={setKeywordSearch}
            expanded={expanded}
            setExpanded={setExpanded}
            onClickExpanded={onClickExpanded}
            collapseAll={collapseAll}
          />
          <SelectedProceduresList
            procedureHeader={procedureHeader}
            procedureHeaderData={procedureHeaderData}
            checkedAccounts={checkedAccounts}
            procedureDetails={procedureDetails}
            isProcedureDetailsLoading={isProcedureDetailsLoading}
            setCheckedAccounts={setCheckedAccounts}
            setProcedureListCount={setProcedureListCount}
            togglePreviewProcedure={openPreviewProcedure}
          />
        </Flex>
        <PreviewPanel
          previewProcedure={previewProcedure}
          closePreviewProcedure={closePreviewProcedure}
          selectedIds={selectedIds}
        />
        {(isLoading || isAccountLoading || isLinkProcedureLoading) && (
          <Loader />
        )}
      </Flex>
    </SlidePanel>
  );
}
