import { useInfiniteQuery, InfiniteQueryResult, queryCache } from 'react-query';
import { Account } from 'types/accounts';
import { getAccountsList } from '../utils/urls';
import { getTokenWithType } from './useAuth';
const pageLength = 100;

function getColumnFromId(id: string) {
  const idsList = [
    'Phone Display',
    'CID',
    'SID',
    'PID',
    'Answer Script',
    'Account Name',
    'Rel Manager',
    'City',
    'State',
    'Time',
    'Status',
    'Service',
    'Call Recording',
    'Routing Group',
    'Branch-out Group',
    'Twilio Flex Priority',
    'Press Any Key to Answer',
  ];
  const index = idsList.findIndex(item => item === id);
  return `${index + 1}`;
}

async function fetchAccounts({ requestData }: TVariables): Promise<TResult> {
  const response = await fetch(getAccountsList, {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

type resultType = {
  data: Array<Account>;
  draw: number;
  isAutoRefreshEnabled: boolean;
  error: Error;
  recordsFiltered: number;
  recordsTotal: number;
};

export type TResult = {
  message: string;
  isSuccess: boolean;
  data: Array<resultType>;
  recordsFiltered: number;
  recordsTotal: number;
  draw: number;
  error: Error | null;
};

export type TError = { message: string };
export type TVariables = {
  requestData: any;
};

function formatRequestData(
  page: number,
  accountStatus: string,
  keywordSearch: string,
  columnsSearch: Record<string, string>,
  sortedColumn: string,
  sortOrder: string,
): FormData {
  const formData: any = new URLSearchParams();
  console.log('sort order', sortOrder, sortedColumn);

  formData.append('draw', '1');
  formData.append('columns[0][data]', 'AccountStatusFilter');
  formData.append('columns[0][name]', '');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append(
    'columns[0][search][value]',
    `${accountStatus.toLowerCase()}`,
  );
  formData.append('columns[0][search][regex]', 'false');
  formData.append('columns[1][data]', 'function');
  formData.append('columns[1][name]', 'VDN,ShortAnswerScript');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append(
    'columns[1][search][value]',
    columnsSearch && columnsSearch['Phone Display']
      ? columnsSearch['Phone Display']
      : '',
  );
  formData.append('columns[1][search][regex]', 'false');
  formData.append('columns[2][data]', 'CID');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append(
    'columns[2][search][value]',
    columnsSearch && columnsSearch.CID ? columnsSearch.CID : '',
  );
  formData.append('columns[2][search][regex]', 'false');
  formData.append('columns[3][data]', 'SID');
  formData.append('columns[3][name]', '');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append(
    'columns[3][search][value]',
    columnsSearch && columnsSearch.SID ? columnsSearch.SID : '',
  );
  formData.append('columns[3][search][regex]', 'false');
  formData.append('columns[4][data]', 'PID');
  formData.append('columns[4][name]', '');
  formData.append('columns[4][searchable]', 'true');
  formData.append('columns[4][orderable]', 'true');
  formData.append(
    'columns[4][search][value]',
    columnsSearch && columnsSearch.PID ? columnsSearch.PID : '',
  );
  formData.append('columns[4][search][regex]', 'false');
  formData.append('columns[5][data]', 'AnswerScript');
  formData.append('columns[5][name]', '');
  formData.append('columns[5][searchable]', 'true');
  formData.append('columns[5][orderable]', 'true');
  formData.append(
    'columns[5][search][value]',
    columnsSearch && columnsSearch['Answer Script']
      ? columnsSearch['Answer Script']
      : '',
  );
  formData.append('columns[5][search][regex]', 'false');
  formData.append('columns[6][data]', 'Name');
  formData.append('columns[6][name]', '');
  formData.append('columns[6][searchable]', 'true');
  formData.append('columns[6][orderable]', 'true');
  formData.append(
    'columns[6][search][value]',
    columnsSearch && columnsSearch['Account Name']
      ? columnsSearch['Account Name']
      : '',
  );
  formData.append('columns[6][search][regex]', 'false');
  formData.append('columns[7][data]', 'RelationshipManager');
  formData.append(
    'columns[7][name]',
    'RelationshipManager.FirstName,RelationshipManager.LastName',
  );
  formData.append('columns[7][searchable]', 'true');
  formData.append('columns[7][orderable]', 'true');
  formData.append(
    'columns[7][search][value]',
    columnsSearch && columnsSearch['Rel Manager']
      ? columnsSearch['Rel Manager']
      : '',
  );
  formData.append('columns[7][search][regex]', 'false');

  formData.append('columns[8][data]', 'City');
  formData.append('columns[8][name]', '');
  formData.append('columns[8][searchable]', 'true');
  formData.append('columns[8][orderable]', 'true');
  formData.append(
    'columns[8][search][value]',
    columnsSearch && columnsSearch.City ? columnsSearch.City : '',
  );
  formData.append('columns[8][search][regex]', 'false');
  formData.append('columns[9][data]', 'StateProvince');
  formData.append('columns[9][name]', 'StateProvince.Abbreviation');
  formData.append('columns[9][searchable]', 'true');
  formData.append('columns[9][orderable]', 'true');
  formData.append(
    'columns[9][search][value]',
    columnsSearch && columnsSearch.State ? columnsSearch.State : '',
  );
  formData.append('columns[9][search][regex]', 'false');
  formData.append('columns[10][data]', 'TimeZone');
  formData.append('columns[10][name]', 'TimeZone.Abbreviation');
  formData.append('columns[10][searchable]', 'true');
  formData.append('columns[10][orderable]', 'true');
  formData.append(
    'columns[10][search][value]',
    columnsSearch && columnsSearch.Time ? columnsSearch.Time : '',
  );
  formData.append('columns[10][search][regex]', 'false');
  formData.append('columns[11][data]', 'Status');
  formData.append('columns[11][name]', 'Status.Description');

  formData.append('columns[11][searchable]', 'true');
  formData.append('columns[11][orderable]', 'true');
  formData.append(
    'columns[11][search][value]',
    columnsSearch && columnsSearch.Status ? columnsSearch.Status : '',
  );
  formData.append('columns[11][search][regex]', 'false');

  formData.append('columns[12][data]', 'AccountService');
  formData.append('columns[12][name]', 'AccountService.Description');
  formData.append('columns[12][searchable]', 'true');
  formData.append('columns[12][orderable]', 'true');
  formData.append(
    'columns[12][search][value]',
    columnsSearch && columnsSearch.Service ? columnsSearch.Service : '',
  );
  formData.append('columns[12][search][regex]', 'false');

  formData.append('columns[13][data]', 'EnableRecording');
  formData.append('columns[13][name]', '');
  formData.append('columns[13][searchable]', 'true');
  formData.append('columns[13][orderable]', 'true');
  formData.append(
    'columns[13][search][value]',
    columnsSearch && columnsSearch['Call Recording']
      ? columnsSearch['Call Recording']
      : '',
  );
  formData.append('columns[13][search][regex]', 'false');

  // commented because these values cause gloablsearch api to get 500
  // formData.append('columns[14][data]', 'TwilioAccountWorkFlow');
  // formData.append('columns[14][name]', '');
  // formData.append('columns[14][searchable]', 'true');
  // formData.append('columns[14][orderable]', 'true');
  // formData.append(
  //   'columns[14][search][value]',
  //   columnsSearch && columnsSearch['Routing Group']
  //     ? columnsSearch['Routing Group']
  //     : '',
  // );
  // formData.append('columns[14][search][regex]', 'false');

  // formData.append('columns[15][data]', 'TwilioAccountBranchOutCondition');
  // formData.append('columns[15][name]', '');
  // formData.append('columns[15][searchable]', 'true');
  // formData.append('columns[15][orderable]', 'true');
  // formData.append(
  //   'columns[15][search][value]',
  //   columnsSearch && columnsSearch['Branch-out Group']
  //     ? columnsSearch['Branch-out Group']
  //     : '',
  // );
  // formData.append('columns[15][search][regex]', 'false');

  // formData.append('columns[16][data]', 'TwilioFlexPriority');
  // formData.append('columns[16][name]', '');
  // formData.append('columns[16][searchable]', 'true');
  // formData.append('columns[16][orderable]', 'true');
  // formData.append(
  //   'columns[16][search][value]',
  //   columnsSearch && columnsSearch['Twilio Flex Priority']
  //     ? columnsSearch['Twilio Flex Priority']
  //     : '',
  // );
  // formData.append('columns[16][search][regex]', 'false');

  // formData.append('columns[17][data]', 'TwilioPAK');
  // formData.append('columns[17][name]', '');
  // formData.append('columns[17][searchable]', 'true');
  // formData.append('columns[17][orderable]', 'true');
  // formData.append(
  //   'columns[17][search][value]',
  //   columnsSearch && columnsSearch['Press Any Key to Answer'] === 'Yes'
  //     ? true
  //     : false,
  // );
  // formData.append('columns[17][search][regex]', 'false');

  // sortedcolumn number is restricted to value less than 13 , as this cause error ,bcz sort and filterng of new columns were not introduced by back end
  formData.append(
    'order[0][column]',
    parseInt(sortedColumn) > 13 ? 1 : sortedColumn,
  );
  formData.append('order[0][dir]', sortOrder);
  formData.append('search[value]', `${keywordSearch}`);
  formData.append('search[regex]', 'false');
  formData.append('start', `${page * 100}`);
  formData.append('length', `${100}`);
  formData.append('timezoneOffset', '-330');
  return formData;
}

function useAccounts(
  page: number,
  accountStatus: string,
  keywordSearch: string,
  columnsSearch: Record<string, string>,
  sortItem: { sortKey: string; sortOrder: string },
): InfiniteQueryResult<TResult, TError> {
  const data: Array<TResult> | undefined = queryCache.getQueryData([
    'accounts_list',
    accountStatus,
    keywordSearch,
    columnsSearch,
    sortItem,
  ]);

  let start = data ? data.length : 0;
  if (start > 0 && data) {
    const totalPages = data[0].recordsFiltered / pageLength;
    if (totalPages > 1) {
      start = start > totalPages ? totalPages : start;
    } else {
      start = 0;
    }
  }

  const requestData = formatRequestData(
    start,
    accountStatus,
    keywordSearch,
    columnsSearch,
    getColumnFromId(sortItem.sortKey),
    sortItem.sortOrder,
  );

  return useInfiniteQuery(
    ['accounts_list', accountStatus, keywordSearch, columnsSearch, sortItem],
    () => fetchAccounts({ requestData }),
    {
      getFetchMore: (lastGroup, allGroups) => {
        return allGroups.length < lastGroup.recordsFiltered / pageLength
          ? true
          : null;
      },
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useAccounts };
