import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { deleteFile } from '../utils/urls';
import { getTokenWithType } from './useAuth';

export async function postDeleteFile({ params }: TArguments): Promise<TResult> {
  const response = await fetch(deleteFile, {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  return response.json();
}

export type TResult = string;
export type DeleteFileParams = {
  Title: string;
  Container: string;
};
export type TError = { message: string };
export type TArguments = {
  params: DeleteFileParams;
};
export type TVariables = DeleteFileParams;
export type TSnapshot = unknown;

function useDeleteFile(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: DeleteFileParams) => {
      const renamedResult = await postDeleteFile({
        params: params,
      });

      if (renamedResult) {
        queryCache.invalidateQueries(['get_file_tree'], {
          refetchInactive: true,
        });
      }
      queryCache.removeQueries(['get_accounts_list_for_import_files']);
      return renamedResult;
    },
    {
      throwOnError: true,
    },
  );
}

export { useDeleteFile };
