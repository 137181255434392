import React, { ReactElement, useState, SyntheticEvent } from 'react';
import { FormInput, FormSelect, EmptyState } from 'ui/patterns';
import {
  Button,
  Stack,
  Checkbox,
  Dropzone,
  DataTable,
  DataTableWrapper,
} from 'ui/components';
import NoDataImage from 'assets/images/NoDataImage';
import * as Icon from 'assets/icons';
import { PdfDetails, PdfType } from 'types/questions';

function PDFQuestion({
  handlePDF,
  pdfData,
  updateField,
}: {
  handlePDF: (file?: File) => void;
  pdfData?: PdfDetails;
  updateField: (pdfDetails: PdfDetails) => void;
}): ReactElement {
  const [editChoice, setEditChoice] = useState<number>(-1);

  return (
    <Stack direction="y" gap={3} mt={6}>
      {pdfData && (
        <FormInput
          label=""
          name="Character Limit"
          disabled
          value={pdfData?.FileName}
        />
      )}
      {!pdfData && <Dropzone onChange={handlePDF} />}
      {pdfData && (
        <DataTableWrapper style={{ minHeight: '300px', height: '1px' }} mb={5}>
          <DataTable>
            <thead>
              <tr>
                <th>PDF Field Name</th>
                <th>Character Limit</th>
                <th>Display Name</th>
                <th>Exclude?</th>
                <th>Choices</th>
              </tr>
            </thead>
            <tbody>
              {pdfData.Fields.map((item: PdfType, index) => (
                <Row
                  key={item.PdfFieldName + index}
                  feild={item}
                  index={index}
                  editChoice={editChoice}
                  setEditChoice={setEditChoice}
                  updateFeild={(feild: PdfType) => {
                    const newFeilds = [
                      ...pdfData.Fields.slice(0, index),
                      { ...feild },
                      ...pdfData.Fields.slice(index + 1),
                    ];

                    const newPdfDetails = {
                      ...pdfData,
                      Fields: [...newFeilds],
                    };
                    updateField({ ...newPdfDetails });
                  }}
                />
              ))}
            </tbody>
          </DataTable>
          {pdfData?.Fields && pdfData?.Fields.length <= 0 ? (
            <EmptyState>
              <NoDataImage feedback="No data available" />
            </EmptyState>
          ) : null}
        </DataTableWrapper>
      )}
    </Stack>
  );
}

function Row({
  feild,
  updateFeild,
  index,
  setEditChoice,
  editChoice,
}: {
  feild: PdfType;
  updateFeild: (feild: PdfType) => void;
  index: number;
  setEditChoice: (index: number) => void;
  editChoice: number;
}) {
  return (
    <>
      <tr>
        <td>{feild.PdfFieldName}</td>
        <td>
          {!feild.Choices && (
            <FormInput
              // label="Character Limit"
              label=""
              name={`Character_Limit${index}`}
              value={`${feild?.limit || ''}`}
              onTextChange={text => {
                updateFeild({
                  ...feild,
                  limit: parseInt(text.replace(/[^0-9]/g, '')),
                });
              }}
            />
          )}
        </td>
        <td>
          <FormInput
            // label="Display Name"
            label=""
            name={`DisplayName_${index}`}
            value={`${feild?.DisplayName || ''}`}
            onTextChange={text => {
              updateFeild({
                ...feild,
                DisplayName: text,
              });
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <Checkbox
            checked={feild.ExcludeFromQuestion}
            inline
            // label="Exclude?"
            label=""
            name={`exclude_${index}`}
            handleChange={() => {
              updateFeild({
                ...feild,
                ExcludeFromQuestion: !feild.ExcludeFromQuestion,
              });
            }}
          />
        </td>
        <td>
          {feild.Choices && (
            <Button
              onClick={() => setEditChoice(index)}
              size="sm"
              variant="secondary"
              style={{ whiteSpace: 'nowrap', minHeight: '30px' }}
            >
              Edit Choices
            </Button>
          )}
        </td>
      </tr>
      {editChoice === index && (
        <tr>
          <td colSpan={4}>
            {/* <label>Use Checkbox? </label> */}

            <Checkbox
              checked={feild.TreatChoicesAsCheckbox}
              label="Use Checkbox?"
              name={`TreatChoicesAsCheckbox_${index}`}
              inline
              handleChange={() => {
                updateFeild({
                  ...feild,
                  TreatChoicesAsCheckbox: !feild.TreatChoicesAsCheckbox,
                });
              }}
            />
            {feild.TreatChoicesAsCheckbox === false ? (
              <Stack direction="y" gap={3} mt={3}>
                {feild &&
                  feild.Choices &&
                  feild.Choices.map((choiceItem, index) => (
                    <FormInput
                      key={`${choiceItem.PdfChoiceName}_${index}`}
                      value={choiceItem.DisplayName}
                      label={choiceItem.PdfChoiceName}
                      name="Display Name"
                    />
                  ))}
              </Stack>
            ) : (
              <CheckedUncheckedChoice feild={feild} updateFeild={updateFeild} />
            )}
          </td>
          <td style={{ textAlign: 'right' }}>
            <Button variant="subtle" onClick={() => setEditChoice(-1)}>
              <Icon.X />
            </Button>
          </td>
        </tr>
      )}
    </>
  );
}

function CheckedUncheckedChoice({
  feild,
  updateFeild,
}: {
  feild: PdfType;
  updateFeild: (feild: PdfType) => void;
}) {
  const options = feild?.Choices?.map((item, index) => ({
    id: index,
    name: item.PdfChoiceName,
  }));
  const checkedChoice = options?.findIndex(
    item => item.name === feild.CheckedChoice,
  );
  const unCheckedChoice = options?.findIndex(
    item => item.name === feild.UncheckedChoice,
  );
  return (
    <Stack direction="y" gap={3} mt={3}>
      <FormSelect
        value={checkedChoice}
        label="Checked Choice "
        // noSelectOption
        options={options}
        onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
          const id = parseInt(e.currentTarget.value, 10);
          const choice = options?.find(item => item.id === id);
          updateFeild({
            ...feild,
            CheckedChoice: choice ? choice.name : null,
          });
        }}
      />
      <FormSelect
        value={unCheckedChoice}
        label="Unchecked Choice "
        // noSelectOption
        options={options}
        onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
          const id = parseInt(e.currentTarget.value, 10);
          const choice = options?.find(item => item.id === id);
          updateFeild({
            ...feild,
            UncheckedChoice: choice ? choice.name : null,
          });
        }}
      />
    </Stack>
  );
}

export default PDFQuestion;
