// both custom table and provider table ui is same diff in api only

import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import { Box } from 'rebass/styled-components';
import { confirmAlert } from 'utils/confirm-alert';
import { SlidePanel, FormInput, Alert } from 'ui/patterns';
import { Button, Stack, Cluster, Tag } from 'ui/components';
import * as Icon from 'assets/icons';
import { useForm } from 'react-hook-form';
import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
} from 'utils/showToast';
import { useSaveCustomTable, useCustomRecords } from 'hooks';
import { CustomTable } from 'types/customTables';

type CustomTableProps = {
  showPanel: boolean;
  togglePanel: () => void;
  type?: string;
  accountId: number;
  tableItem?: CustomTable;
};

export default function AddCustomTable({
  showPanel,
  togglePanel,
  type,
  accountId,
  tableItem,
}: CustomTableProps): ReactElement {
  const [saveCustomTable, { isLoading }] = useSaveCustomTable();
  const { register, handleSubmit, reset } = useForm();
  const [columns, setColumns] = useState<Array<string>>([]);
  const [columnName, setColumnName] = useState<string>();

  const tableColumns =
    (tableItem && tableItem.Columns && tableItem?.Columns[0]) || '';

  const sortItem = { sortKey: tableColumns, sortOrder: 'asc' };

  const page = 0;
  const columnsSearch = {};
  const keywordSearch = '';
  const tableId = tableItem?.Id || 0;
  const currentSortItem = sortItem;
  const selectedTableColumns = tableItem?.Columns || [];

  const { data } = useCustomRecords(
    page,
    columnsSearch,
    currentSortItem,
    keywordSearch,
    accountId,
    tableId,
    selectedTableColumns,
  );
  const tableData = useMemo(() => {
    if (data) {
      return data
        .map(dataItem => {
          if (dataItem) return dataItem.data;
        })
        .flat();
    }
  }, [data]);

  function addColumn() {
    if (columnName) {
      const index = columns.findIndex(item => item === columnName);
      if (index === -1) {
        setColumns([...columns, columnName]);
        setColumnName('');
      } else {
        showInfoToast({
          message: `The column name '${columnName}' already exists `,
        });
      }
    }
  }

  function deleteColumn(columnName: string) {
    const index = columns.findIndex(item => item === columnName);
    if (index !== -1) {
      if (!!tableItem)
        setColumns([
          ...columns.slice(0, index),
          '',
          ...columns.slice(index + 1),
        ]);
      else
        setColumns([...columns.slice(0, index), ...columns.slice(index + 1)]);
    }
  }

  function removeColumn(columnName: string) {
    if (tableItem && tableData && tableData.length > 0) {
      confirmAlert({
        // title: 'Proteus',
        message:
          'The data in this column will be lost. Do you want to proceed?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              deleteColumn(columnName);
            },
            className: 'pr-btn-primary',
          },
          {
            label: 'No',
            onClick: () => {
              //
            },
          },
        ],
      });
    } else deleteColumn(columnName);
    // const index = columns.findIndex(item => item === columnName);
    // if (index !== -1) {
    //   if (!!tableItem)
    //     setColumns([
    //       ...columns.slice(0, index),
    //       '',
    //       ...columns.slice(index + 1),
    //     ]);
    //   else
    //     setColumns([...columns.slice(0, index), ...columns.slice(index + 1)]);
    // }
  }

  function resetForm(): void {
    reset({
      'Table Name': '',
    });
    setColumns([]);
    setColumnName('');
  }

  const handleSave = (data: any) => {
    saveCustomTable(
      {
        AccountId: accountId,
        Id: tableItem?.Id || 0,
        // Columns: colsToUpload,
        Columns: columns,
        TableName:
          type === 'Provider Table' ? 'ProviderList' : data['Table Name'],
      },
      {
        onSuccess: () => {
          showSuccessToast({
            message: 'Your changes were successfully saved.',
          });
          resetForm();
          togglePanel();
        },
        onError: error => {
          showErrorToast({
            message: error,
          });
        },
      },
    );
  };

  function onsubmit(data: any) {
    const columnsArray = columns.filter(item => item !== '');
    // validate
    if (columnsArray.length === 0) {
      showErrorToast({
        message: 'There must be at least one column',
      });
    } else handleSave(data);

    // handleSave(data);
    // submit
    // let colsToUpload = columns;
    // if (tableItem?.Columns) {
    //   colsToUpload = tableItem.Columns.map((item, index) => {
    //     if (columns[index] === item) return item;
    //     else return '';
    //   });
    //   console.log('colsToUpload');
    //   const newCols = columns.filter((item, index) => {
    //     const colIndex = tableItem.Columns.findIndex(col => col === item);
    //     return colIndex !== index;
    //   });
    //   colsToUpload = [...colsToUpload, ...newCols];
    // }
  }

  useEffect(() => {
    if (tableItem) {
      setColumns(tableItem.Columns);
      reset({ 'Table Name': tableItem.TableName });
    }
  }, [tableItem, reset]);

  const addActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          resetForm();
          togglePanel();
        }}
      >
        Cancel
      </Button>
      <Button
        disabled={isLoading}
        onClick={handleSubmit(onsubmit)}
        variant="primary"
      >
        Save
      </Button>
    </>
  );
  const colsTorender = columns.filter(item => !!item);
  return (
    <>
      <SlidePanel
        // title="Create New Table"
        title={`${tableItem === undefined ? 'Create New Table' : 'Edit Table'}`}
        actions={addActions()}
        size="sm"
        onClose={() => {
          resetForm();
          togglePanel();
        }}
        open={showPanel}
      >
        <Stack direction="y" gap={4}>
          <Alert variant="danger" icon={<Icon.ExclamationCircle />}>
            Existing records will be lost, if columns with values are deleted.
          </Alert>
          <Alert variant="warning" icon={<Icon.Warning />}>
            Adding more than one column with the same name will cause an error
            and the table will not save.
          </Alert>

          {type === 'Provider Table' ? (
            <FormInput
              label="Table Name"
              required
              disabled
              value={'ProviderList'}
            />
          ) : (
            <FormInput label="Table Name" required register={register} />
          )}

          <Box
            sx={{
              display: 'grid',
              gridGap: 3,
              gridTemplateColumns: '1fr minmax(50px, 15%)',
              alignItems: 'end',
            }}
          >
            <FormInput
              label="Column Name"
              type="text"
              onTextChange={setColumnName}
              value={columnName}
            />
            <Button
              variant="primary"
              style={{ height: '35px' }}
              iconBefore={<Icon.Add />}
              disabled={columnName ? false : true}
              onClick={addColumn}
            />
          </Box>
          <Cluster gap={3}>
            <Box>
              {/* {columns.map((item, index) => ( */}
              {colsTorender.map((item, index) => (
                <Tag
                  onClose={() => {
                    removeColumn(item);
                  }}
                  key={`${item}${index}`}
                >
                  {item}
                </Tag>
              ))}
            </Box>
          </Cluster>
        </Stack>
      </SlidePanel>
    </>
  );
}
