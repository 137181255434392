import React, { ReactElement, useCallback, useState } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { SlidePanel, SearchInput, Table, Pagination } from 'ui/patterns';
import { Button, Stack } from 'ui/components';
import * as Icon from 'assets/icons';
import { useGetGlobalSurveys } from 'hooks';
import useGlobalSurveyColumns from './useGlobalSurveyColumns';
import { SectionTypeInput } from 'types/forms';

type GlobalSectionProps = {
  showPanel: boolean;
  togglePanel: () => void;
  handleAddEditGlobalSection: (sectionItem: SectionTypeInput) => void;
};

const pageLength = 100;

const sortItem = [
  { sortKey: 'Survey', sortOrder: 'asc' },
  { sortKey: 'Title', sortOrder: 'asc' },
];

type SortItemType = {
  sortKey: string;
  sortOrder: string;
};

export default function AddGlobalSection({
  showPanel,
  togglePanel,
  handleAddEditGlobalSection,
}: GlobalSectionProps): ReactElement {
  const [columnsSearch, setColumnsSearch] = useState({}); // header search
  const [currentSortItem, setCurrentSortItem] = useState<
    SortItemType | Array<SortItemType>
  >(sortItem);

  const [page, setPage] = useState(0);
  const [keywordSearch, setKeywordSearch] = useState(''); // keyword search
  const [selectedGlobalSection, setGlobalSection] = useState<SectionTypeInput>(
    {},
  );

  let numberOfPages = 0;

  const {
    resolvedData: data,
    latestData,
    isLoading,
    isFetching,
  } = useGetGlobalSurveys({
    page,
    keywordSearch,
    columnsSearch,
    sortItem: currentSortItem,
  });

  const columns = useGlobalSurveyColumns();

  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch],
  );

  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (currentSortItem instanceof Array) {
        const existingItem = currentSortItem.find(
          sItem => sItem.sortKey === item,
        );
        if (existingItem) {
          setCurrentSortItem({
            sortKey: item,
            sortOrder: existingItem.sortOrder === 'asc' ? 'desc' : 'asc',
          });
        } else {
          setCurrentSortItem({
            sortKey: item,
            sortOrder,
          });
        }
      } else if (currentSortItem.sortKey && currentSortItem.sortOrder) {
        if (
          currentSortItem.sortKey === item &&
          currentSortItem.sortOrder === 'asc'
        ) {
          sortOrder = 'desc';
        }
        setCurrentSortItem({
          sortKey: item,
          sortOrder,
        });
      }
    },
    [setCurrentSortItem, currentSortItem],
  );

  if (data && data.recordsFiltered) {
    numberOfPages = Math.floor(data.recordsFiltered / pageLength);
  }
  const setPageNumber = useCallback(
    (newPage: number) => {
      if (newPage >= 0 && newPage <= numberOfPages) setPage(newPage);
    },
    [setPage, numberOfPages],
  );

  const handleKeywordSearch = (value: string): void => {
    setKeywordSearch(value);
  };

  const handleReset = (): void => {
    setKeywordSearch('');
    setColumnsSearch({});
    setCurrentSortItem(sortItem);
    setGlobalSection({});
  };

  const handleRowClick = (row: any): void => {
    setGlobalSection(row.original);
  };

  const addGlobalSectionActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          togglePanel();
          setGlobalSection({});
        }}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={() => {
          handleAddEditGlobalSection(selectedGlobalSection);
          togglePanel();
          setGlobalSection({});
        }}
        disabled={(selectedGlobalSection?.Id ? false : true) || !showPanel}
      >
        Save
      </Button>
    </>
  );
  return (
    <>
      <SlidePanel
        title="Add Global Section"
        size="md"
        actions={addGlobalSectionActions()}
        onClose={() => {
          togglePanel();
          setGlobalSection({});
        }}
        open={showPanel}
      >
        <Flex flexDirection="column" height="100%">
          <Flex justifyContent="space-between" alignItems="center" mb={2}>
            <Text fontSize={1} color="text.body" sx={{ height: '20px' }}>
              {data &&
                !isFetching &&
                `Showing ${pageLength * page + 1} to ${
                  pageLength * page + (latestData?.data?.length || 0)
                } of ${data?.recordsFiltered}`}
              {isFetching && 'Processing'}
            </Text>
            <Stack direction="x" gap={2}>
              <SearchInput
                placeholder="Search"
                handleChange={handleKeywordSearch}
                value={keywordSearch}
              />
              <Button
                iconBefore={<Icon.Refresh title="" ariaHidden="true" />}
                variant="secondary"
                onClick={handleReset}
              >
                Reset All
              </Button>
            </Stack>
          </Flex>
          <Stack direction="y" gap={4} style={{ flex: 1 }}>
            <Table
              height="auto"
              columns={columns}
              data={data ? data.data : undefined}
              isLoading={isLoading || isFetching}
              headerUpdateCallBack={headerUpdateCallBack}
              handleSortChange={handleSortChange}
              multiSortedItem={currentSortItem}
              columnsSearch={columnsSearch}
              noOptions
              onRowClick={handleRowClick}
              selectedRowId={selectedGlobalSection?.Id}
            />
            <Flex mt={4} justifyContent="flex-end">
              <Pagination
                page={page}
                numberOfPages={numberOfPages}
                onClick={setPageNumber}
              />
            </Flex>
          </Stack>
        </Flex>
      </SlidePanel>
    </>
  );
}
