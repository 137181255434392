import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { deleteLocation } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { showSuccessToast } from 'utils/showToast';

export async function deleteLocations({
  locId,
  accountId,
}: TArguments): Promise<Response> {
  return fetch(deleteLocation(locId, accountId), {
    method: 'DELETE',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/json',
    },
  });
}

export type TResult = {
  message: string;
  isSuccess: boolean;
};

export type TError = { message: string };
export type TArguments = {
  locId: number;
  accountId: number;
};
export type TVariables = {
  locId: number;
  accountId: number;
};

function useDeleteLocations(
  locId: number,
  accountId: number,
): MutationResultPair<void, TError, TVariables, unknown> {
  return useMutation(async () => {
    await deleteLocations({
      locId,
      accountId,
    });
    queryCache.invalidateQueries('locations_list');
    showSuccessToast({
      message: 'Location deleted successfully.',
    });
  });
}

export { useDeleteLocations };
