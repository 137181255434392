import styled, { css } from 'styled-components';
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  flexbox,
  FlexboxProps,
} from 'styled-system';

type SegmentedButtonsProps = LayoutProps &
  SpaceProps &
  FlexboxProps & {
    equalWidthChildren?: boolean;
  };

const SegmentedButtons = styled.div<SegmentedButtonsProps>`
  display: flex;

  & > * {
    margin-right: -1px;
  }

  & > *:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > *:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  ${({ equalWidthChildren }) =>
    equalWidthChildren &&
    css`
      & > * {
        flex-grow: 1;
      }
    `}

  ${layout}
  ${space}
	${flexbox}
`;

export { SegmentedButtons };
