import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { SlidePanel, Tabs } from 'ui/patterns';
import { Button } from 'ui/components';
import QuestionDefinition from './QuestionDefinition';
import QuestionDetails from './QuestionDetails';
import {
  getQuestionTypeDetails,
  getPersonAssociation,
  getQuestionOptions,
} from './formatQuestionData';

import {
  MappedColumnArray,
  TemplateType,
  QuestionPropsType,
  DependencyType,
} from './questionTypes';
import { showErrorToast } from 'utils/showToast';
import { SectionTypeInput } from 'types/forms';
import { useGetUrlValidation } from 'hooks';

export default function AddEditQuestion({
  showPanel,
  togglePanel,
  handleAddEditQuestion,
  currentSection,
  selectedQuestionType,
  setQuestionType,
  currentQuestion,
  accountId,
  accountInfoReturn,
  sections,
  handleSaveForm,
  url,
  setUrl,
}: QuestionPropsType): ReactElement {
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    errors,
    getValues,
  } = useForm();

  // look up question state and functions
  const [mappedColumns, setMappedColumns] = useState<MappedColumnArray>([]);
  const [lookupDependency, setLookupDependency] = useState<DependencyType>([]);
  const [submit, setSubmit] = useState<boolean>(false);

  useEffect(() => {
    if (selectedQuestionType?.QuestionTypeOptions?.Dependencies)
      setLookupDependency(
        selectedQuestionType?.QuestionTypeOptions?.Dependencies,
      );
  }, [selectedQuestionType]);

  useEffect(() => {
    if (selectedQuestionType?.QuestionTypeOptions?.MappedLookupColumns) {
      setMappedColumns(
        selectedQuestionType?.QuestionTypeOptions?.MappedLookupColumns,
      );
    }
  }, [selectedQuestionType]);

  // choice type question state and function

  const [questionTemplateOptions, setQuestionTemplateOptions] =
    useState<TemplateType>([]);

  useEffect(() => {
    if (selectedQuestionType?.QuestionTemplateOptions)
      setQuestionTemplateOptions(
        selectedQuestionType?.QuestionTemplateOptions.sort(
          (
            a: { Order: number; ItemLabel: string },
            b: { Order: number; ItemLabel: string },
          ) => {
            if (parseInt(`${a.Order}`) === parseInt(`${b.Order}`))
              return `${a.ItemLabel}`.toLowerCase() <
                `${b.ItemLabel}`.toLowerCase()
                ? -1
                : 1;
            return parseInt(`${a.Order}`) - parseInt(`${b.Order}`);
          },
        ).map((item: any) => {
          return {
            ...item,
            QuestionTemplateOptionId: item.QuestionTemplateOptionId
              ? item.QuestionTemplateOptionId
              : item.Id,
            DependentSections: item.DependentSections
              ? item.DependentSections
              : [],
            DependentQuestions: item.DependentQuestions
              ? item.DependentQuestions
              : [],
          };
        }),
      );
  }, [selectedQuestionType]);

  function resetForm(): void {
    setQuestionType(undefined);
    setLookupDependency([]);
  }

  const {
    data: validatedResult,
    refetch: geUrlValidation,
    isFetching,
  } = useGetUrlValidation(url);

  const errorMessage = useMemo(() => {
    if (validatedResult) {
      if (validatedResult?.StatusCode === 200 && submit && !isFetching)
        return 'valid';
      if (validatedResult?.StatusCode == null && submit && !isFetching)
        return 'invalid';
    }
    return '';
  }, [validatedResult, submit, isFetching]);

  const associatedPersons = [
    { id: 0, name: 'None' },
    { id: 1, name: 'Caller' },
    { id: 2, name: 'Person of concern' },
  ];

  useEffect(() => {
    if (selectedQuestionType) {
      let personAssociationId = -1;
      if (selectedQuestionType?.PersonAssociation === null)
        personAssociationId = 0;
      if (selectedQuestionType?.PersonAssociation === 0)
        personAssociationId = 1;
      if (selectedQuestionType?.PersonAssociation === 1)
        personAssociationId = 2;

      reset({
        ...selectedQuestionType,
        ...selectedQuestionType?.QuestionTypeOptions,
        PersonAssociation: personAssociationId,
        UpdatedFieldLabel: selectedQuestionType?.FieldLabel,
        Type: selectedQuestionType?.QuestionTypeOptions?.Type === 0 ? 0 : 1,
        CustomLookupId:
          selectedQuestionType?.QuestionTypeOptions?.CustomLookupId,
        QuestionDetailType: selectedQuestionType?.QuestionDetail?.Type,
        ExternalDocumentUri:
          selectedQuestionType?.QuestionDetail?.ExternalDocumentUri,
        HelpText: selectedQuestionType?.QuestionDetail?.HelpText,
        ProcedureUri: selectedQuestionType?.QuestionDetail?.ProcedureUri,
        ChoiceType: selectedQuestionType?.QuestionTypeOptions?.Type,
      });
    }
  }, [selectedQuestionType, reset]);

  function onSubmit(
    data: any,
    cb?: (newSections: Array<SectionTypeInput>, questionParams: any) => void,
  ) {
    console.log('🚀 ~ file: index.tsx ~ line 77 ~ onSubmit ~ data', data);

    const personAssociationId = getPersonAssociation(data, associatedPersons);

    const questionDetail = {
      ExternalDocumentUri: data?.ExternalDocumentUri || '',
      HelpText: data?.HelpText || '',
      ProcedureUri: data?.ProcedureUri || '',
      Type: data?.QuestionDetailType,
      Id: selectedQuestionType?.QuestionDetail?.Id || 0,
    };
    const questionTemplate = {
      ...selectedQuestionType,
    };

    const questionTypeOptions = getQuestionTypeDetails(
      data,
      selectedQuestionType,
      mappedColumns,
      lookupDependency,
    );

    const questionOptions = getQuestionOptions(
      selectedQuestionType,
      questionTemplateOptions,
    );

    const questionParams = {
      AccountTag: '',
      FieldLabel: data?.UpdatedFieldLabel,
      Id: selectedQuestionType && selectedQuestionType?.Id, // shuld be updated for edit
      IsDeleted: false,
      IsMultiValuesAllowed: !!data.IsMultiValuesAllowed,
      PersonAssociation: personAssociationId === 2 ? null : personAssociationId,
      QuestionDetail: questionDetail,
      QuestionOptions: questionOptions,
      QuestionTemplate: { ...questionTemplate },
      QuestionTemplateId: selectedQuestionType?.QuestionTemplateId,
      QuestionTypeOptions: questionTypeOptions,
      Order: selectedQuestionType?.Order
        ? selectedQuestionType?.Order
        : undefined,
      SectionId: currentSection?.Id,
    };
    const itemLabels = questionParams.QuestionOptions.map(item =>
      item.ItemLabel.toLowerCase().replace(/ /g, ''),
    );

    const duplicateLabels = itemLabels.filter(
      (item, index) => itemLabels.indexOf(item) !== index,
    );
    if (data?.QuestionDetailType === 1 && data?.ProcedureUri === '') {
      showErrorToast({
        message: 'Please select a procedure in question details.',
        toastId: 10245,
      });
    } else if (
      data?.QuestionDetailType === 2 &&
      (data?.ExternalDocumentUri === '' ||
        data?.ExternalDocumentUri === undefined)
    ) {
      showErrorToast({
        message: 'Please select an external document in question details.',
      });
    } else if (duplicateLabels.length !== 0) {
      showErrorToast({
        message: 'Item label already exists',
      });
    } else handleAddEditQuestion(questionParams, currentSection, cb);
  }

  const questionSubmit = handleSubmit(data => {
    let defaultUrl = '';
    if (url.includes('http://')) {
      defaultUrl = url.replace('http://', '').trim();
    } else if (url.includes('https://')) {
      defaultUrl = url.replace('https://', '').trim();
    }

    let value: any;
    if (data?.TextQuestionTypeFormatId === 7) {
      value = {
        ...data,
        DefaultValue: defaultUrl.length ? url : '',
      };
    } else {
      value = {
        ...data,
      };
    }
    if (data?.TextQuestionTypeFormatId === 7 && defaultUrl.length) {
      setSubmit(true);
      geUrlValidation().then(message => {
        if (message.StatusCode === 200) {
          onSubmit(value);
          setLookupDependency([]);
        }
      });
    } else {
      onSubmit(value);
      setLookupDependency([]);
    }
  });

  function handleFormandQuestionSave() {
    handleSubmit(data => {
      onSubmit(
        data,
        (newSections: Array<SectionTypeInput>, questionParams: any) => {
          handleSaveForm(newSections, questionParams);
        },
      );
    })();
  }

  const addQuestionActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          togglePanel();
          resetForm();
        }}
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        onClick={questionSubmit}
        disabled={selectedQuestionType?.Id ? false : true || !showPanel}
      >
        Save
      </Button>
    </>
  );

  const AddQuestionTabs = [
    {
      id: 'questionDefinition',
      label: 'Question Definition',
      content: (
        <QuestionDefinition
          showPanel={showPanel}
          setQuestionType={setQuestionType}
          selectedQuestionType={selectedQuestionType}
          resetForm={resetForm}
          currentQuestion={currentQuestion}
          associatedPersons={associatedPersons}
          register={register}
          control={control}
          Controller={Controller}
          watch={watch}
          setValue={setValue}
          errors={errors}
          getValues={getValues}
          reset={reset}
          accountId={accountId}
          mappedColumns={mappedColumns}
          setMappedColumns={setMappedColumns}
          questionTemplateOptions={questionTemplateOptions}
          setQuestionTemplateOptions={setQuestionTemplateOptions}
          sections={sections}
          setLookupDependency={setLookupDependency}
          lookupDependency={lookupDependency}
          handleFormandQuestionSave={handleFormandQuestionSave}
          url={url}
          setUrl={setUrl}
          errorMessage={errorMessage}
        />
      ),
    },
    {
      id: 'questionDetails',
      label: 'Question Details',
      content: (
        <QuestionDetails
          selectedQuestionType={selectedQuestionType}
          control={control}
          Controller={Controller}
          watch={watch}
          setValue={setValue}
          accountId={accountId}
          accountInfoReturn={accountInfoReturn}
        />
      ),
    },
  ];

  return (
    <>
      <SlidePanel
        title={
          Object.keys(currentQuestion).length === 0
            ? 'Add New Question'
            : 'Edit Question'
        }
        open={showPanel}
        onClose={() => {
          togglePanel();
          resetForm();
        }}
        actions={addQuestionActions()}
        size="lg"
      >
        {showPanel && <Tabs tabs={AddQuestionTabs} />}
      </SlidePanel>
    </>
  );
}
