import { usePaginatedQuery, PaginatedQueryResult } from 'react-query';
import { getCheckLinks } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { CheckLinksType } from 'types/checkLinks';

async function fetchCheckLinks({
  accountId,
  formId,
}: TVariables): Promise<TResult> {
  const response = await fetch(getCheckLinks(accountId, formId), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    // body: requestData,
  });
  return response.json();
}

type resultType = CheckLinksType;

export type TResult = Array<resultType>;

export type TError = { message: string };
export type TVariables = {
  accountId: string | null;
  formId: string | null;
};

function useCheckLinks(
  page: number,
  accountId: string | null,
  formId: string | null,
): PaginatedQueryResult<TResult, TError> {
  //   const data: Array<TResult> | undefined = queryCache.getQueryData([
  //     'check_links_list',
  //   ]);
  //   let nextPage = data ? data.length : 0;
  //   if (nextPage > 0 && data) {
  //     const totalPages = data[0].recordsFiltered / pageLength;
  //     nextPage = nextPage > totalPages ? totalPages : nextPage;
  //   }

  return usePaginatedQuery(
    [
      'check_links_list',
      // nextPage,
    ],
    () => fetchCheckLinks({ accountId, formId }),
    // {
    //   getFetchMore: (lastGroup, allGroups) => {
    //     // console.log('lastGroup: ', lastGroup.recordsFiltered / pageLength);
    //     // console.log(
    //     //   'allGroups: ',
    //     //   allGroups.length,
    //     //   allGroups.length < lastGroup.recordsFiltered / pageLength,
    //     // );
    //     return allGroups.length < lastGroup.recordsFiltered / pageLength
    //       ? true
    //       : null;
    //   },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useCheckLinks };
