import { toast } from 'react-toastify';

const options = {
  autoClose: 50000,
  // type: toast.TYPE.INFO,
  hideProgressBar: true,
  position: toast.POSITION.TOP_RIGHT,
  //   pauseOnHover: true,
};

type ToastType = {
  message: string;
  autoClose?: number | false;
  toastId?: number;
};

export const showSuccessToast = ({ message, autoClose }: ToastType): void => {
  // let delay;
  // delay = autoClose !== undefined ? autoClose : 5000;
  toast.success(message, {
    ...options,
    autoClose: autoClose !== undefined ? autoClose : 5000,
  });
};

export const showErrorToast = ({
  message,
  autoClose,
  toastId,
}: ToastType): void => {
  if (toastId) {
    const activeId = toast.isActive(toastId);
    if (activeId) return;
  }
  // const delay = autoClose !== undefined ? autoClose : 5000;
  toast.error(message, {
    ...options,
    autoClose: autoClose !== undefined ? autoClose : 5000,
    toastId,
  });
};

export const showInfoToast = ({ message, autoClose }: ToastType): void => {
  // const delay = autoClose !== undefined ? autoClose : 5000;
  toast.info(message, {
    ...options,
    autoClose: autoClose !== undefined ? autoClose : 5000,
  });
};

export const showWarningToast = ({ message, autoClose }: ToastType): void => {
  // const delay = autoClose !== undefined ? autoClose : 5000;
  toast.warning(message, {
    ...options,
    autoClose: autoClose !== undefined ? autoClose : 5000,
  });
};
