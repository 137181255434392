import React, {
  ReactElement,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import {
  MainContent,
  PageContent,
  ButtonGroup,
  PageHeader,
  Table,
} from 'ui/patterns';
import {
  useAccounts,
  useDocumentTitle,
  useGetUserSettings,
  useGetRoutingSubGroup,
  useGetBranchOutGroup,
} from 'hooks';
import useAccountsColumns from './useAccountsColumns';
import { Text, Flex } from 'rebass/styled-components';
import { Button } from 'ui/components/button';
import * as Icon from '../../assets/icons';
import CopyAccount from '../CopyAccount';
import ImportAccount from '../ImportAccount';
import { Account } from 'types/accounts';

const pageTitle = 'List of Accounts';
// const pageLength = 100;

const filterOptions = [
  { id: 0, filter: 'Live' },
  { id: 1, filter: 'Hidden' },
  { id: 2, filter: 'All' },
];

// type searchItem = { columnId: string; value: string };

const sortItem = { sortKey: 'Phone Display', sortOrder: 'asc' };

export default function AccountsList(): ReactElement {
  useDocumentTitle('Index', true);
  const history = useHistory();
  const pageActions = [
    {
      action: 'Reset',
      icon: <Icon.Refresh title="" ariaHidden="true" />,
      handleButtonClick: () => handleReset(),
    },
    {
      action: 'Add Account',
      icon: <Icon.Add title="" ariaHidden="true" />,
      handleButtonClick: () => handleAddAccount(),
    },
  ];
  const [columnsSearch, setColumnsSearch] = useState({}); // header search
  const [currentSortItem, setCurrentSortItem] = useState(sortItem);
  const {
    data: userSettings,
    // isLoading: uisLoading
  } = useGetUserSettings();

  const { data: branchOutData, isLoading: branchoutDataLoading } =
    useGetBranchOutGroup();
  const { data: workFlowData, isLoading: workFlowDataLoading } =
    useGetRoutingSubGroup();

  console.log('userSettings', userSettings);
  const columns = useAccountsColumns(
    userSettings,
    false,
    branchOutData,
    workFlowData,
  );
  const [page, setPage] = useState(0);
  const [keywordSearch, setKeywordSearch] = useState(''); // keyword search
  const [accountStatus, setAccountStatus] = useState('Live'); // account filter

  // import and copy account

  const [showImportAccount, setImportAccount] = useState(false);
  const [showCopyAccount, setCopyAccount] = useState(false);

  const [accountDetails, setAccountDetails] = useState<Account>();

  const toggleImportAccount = () => {
    setImportAccount(false);
    setAccountDetails(undefined);
  };

  const toggleCopyAccount = () => {
    setCopyAccount(false);
    setAccountDetails(undefined);
  };

  const setAccounts = (row: any, type: string) => {
    if (type === 'ImportAccount') {
      setImportAccount(true);
      setAccountDetails(row);
    }
    if (type === 'CopyAccount') {
      setCopyAccount(true);
      setAccountDetails(row);
    }
  };

  // let numberOfPages = 0;
  const {
    data,
    fetchMore,
    canFetchMore,
    isFetchingMore,
    isLoading,
    isFetching,
  } = useAccounts(
    page,
    accountStatus,
    keywordSearch,
    columnsSearch,
    currentSortItem,
  );

  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch],
  );
  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (
        currentSortItem.sortKey === item &&
        currentSortItem.sortOrder === 'asc'
      ) {
        sortOrder = 'desc';
      }
      setCurrentSortItem({
        sortKey: item,
        sortOrder,
      });
    },
    [setCurrentSortItem, currentSortItem],
  );
  // if (data && data.recordsFiltered) {
  //   numberOfPages = Math.floor(data.recordsFiltered / pageLength);
  // }
  // const setPageNumber = useCallback(
  //   (newPage: number) => {
  //     if (newPage >= 0 && newPage <= numberOfPages) setPage(newPage);
  //   },
  //   [setPage, numberOfPages],
  // );

  const handleKeywordSearch = (value: string): void => {
    setKeywordSearch(value);
  };

  const handleClickFilter = (filterValue: string): void => {
    setAccountStatus(filterValue);
    // queryCache.invalidateQueries('get_user_settings');
  };

  const handleReset = (): void => {
    setAccountStatus('Live');
    setKeywordSearch('');
    setColumnsSearch({});
    setCurrentSortItem(sortItem);
  };

  const handleAddAccount = (): void => {
    history.push('/home/accounts/add');
  };

  const handleEditAccount = (row: any): void => {
    history.push(`/home/accounts/edit/${row.original.Id}/accountInfo`, {
      ID: row.original.Id,
    });
  };

  // to preserve the filter values

  useEffect(() => {
    const savedPage = window.sessionStorage.getItem('page');
    const savedaccountStatus = window.sessionStorage.getItem('accountStatus');
    const savedkeywordSearch = window.sessionStorage.getItem('keywordSearch');
    const savedcolumnsSearch = window.sessionStorage.getItem('columnsSearch');
    const savedcurrentSortItem =
      window.sessionStorage.getItem('currentSortItem');
    savedPage && setPage(parseInt(savedPage));
    savedaccountStatus && setAccountStatus(savedaccountStatus);
    savedkeywordSearch && setKeywordSearch(savedkeywordSearch);
    savedcolumnsSearch && setColumnsSearch(JSON.parse(savedcolumnsSearch));
    savedcurrentSortItem &&
      setCurrentSortItem(JSON.parse(savedcurrentSortItem));
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem('page', page + '');
    window.sessionStorage.setItem('accountStatus', accountStatus);
    window.sessionStorage.setItem('keywordSearch', keywordSearch);
    window.sessionStorage.setItem(
      'columnsSearch',
      JSON.stringify(columnsSearch),
    );
    window.sessionStorage.setItem(
      'currentSortItem',
      JSON.stringify(currentSortItem),
    );
  }, [page, accountStatus, keywordSearch, columnsSearch, currentSortItem]);

  const tableData = useMemo(() => {
    if (data) {
      return data.map(dataItem => dataItem.data).flat();
    }
  }, [data]);

  const totalItems = useMemo(() => {
    if (data) {
      return data[0].recordsFiltered;
    }
    return 0;
  }, [data]);

  const onEndReached = () => {
    if (tableData) {
      if (canFetchMore && tableData?.length < totalItems) fetchMore();
    }
  };

  // const onEndReached = useCallback(() => {
  //   if (tableData) {
  //     if (canFetchMore && tableData?.length < totalItems) fetchMore();
  //   }
  // }, [canFetchMore, tableData, fetchMore, totalItems]);

  const pageAddons = () => (
    <div className="addons">
      <ButtonGroup className="button-group">
        {filterOptions.map(filterValue => (
          <Button
            key={filterValue.id}
            variant={
              filterValue.filter === accountStatus ? 'primary' : undefined
            }
            onClick={() => handleClickFilter(filterValue.filter)}
          >
            {filterValue.filter}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );

  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const toggleAdvancedSearch = () => {
    // setShowAdvancedSearch(!showAdvancedSearch);
    if (
      history.location.pathname === '/home/accounts' ||
      history.location.pathname === '/home/accounts/'
    ) {
      history.push('/home/accounts/advanced');
    } else {
      history.push('/home/accounts');
    }
  };

  return (
    <>
      <MainContent flexDirection="column">
        <PageHeader
          title={pageTitle}
          actions={pageActions}
          hasSearch
          addons={pageAddons()}
          searchValue={keywordSearch}
          handleChange={handleKeywordSearch}
        />
        <PageContent>
          <Flex justifyContent="space-between" alignItems="flex-end" mb={2}>
            <Text mb={2} fontSize={1} color="text.body" sx={{ height: '20px' }}>
              {tableData && tableData.length > 0 && (
                <Text mb={2}>{`Showing ${tableData.length} of ${totalItems}${
                  isFetching ? ' | Processing' : ''
                }`}</Text>
              )}
            </Text>
            <Tooltip
              animation="fade"
              position="left"
              arrow={true}
              arrowSize="small"
              html={
                <span>
                  Search procedures, sections and questions <br /> across the
                  selected accounts.
                </span>
              }
            >
              <Button
                variant="secondary"
                onClick={() => toggleAdvancedSearch()}
              >
                Advanced Search
              </Button>
            </Tooltip>
          </Flex>
          <Table
            columns={columns}
            // data={data ? data.data : undefined}
            data={tableData}
            isLoading={isLoading} //|| isFetching
            hasColumnSelection
            headerUpdateCallBack={headerUpdateCallBack}
            handleSortChange={handleSortChange}
            sortedItem={currentSortItem}
            columnsSearch={columnsSearch}
            userSettings={userSettings}
            onRowDoubleClick={handleEditAccount}
            setAccounts={setAccounts}
            onEndReached={onEndReached}
            isFetching={!!isFetchingMore}
          />
          {/* <Flex mt={4} justifyContent="flex-end">
            <Pagination
              page={page}
              numberOfPages={numberOfPages}
              onClick={setPageNumber}
            />
          </Flex> */}
        </PageContent>
      </MainContent>
      <ImportAccount
        showPanel={showImportAccount}
        togglePanel={toggleImportAccount}
        account={accountDetails?.AccountNameAndNumber || ''}
        importAccountId={accountDetails?.Id || 0}
      />

      <CopyAccount
        showPanel={showCopyAccount}
        togglePanel={toggleCopyAccount}
        account={accountDetails}
      />

      {/* <AdvancedSearch
        showPanel={showAdvancedSearch}
        togglePanel={toggleAdvancedSearch}
      /> */}
    </>
  );
}
