import React, { ReactElement, useState, useEffect } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { SlidePanel, SearchInput } from 'ui/patterns';
import { Stack } from 'ui/components';
import NoDataImage from 'assets/images/NoDataImage';
import { LinkedAccountsListType } from '../procedureTypes';

export default function LinkedAccountsList({
  showLinkedAccounts,
  toggleLinkedAccounts,
  linkedProcedure,
}: LinkedAccountsListType): ReactElement {
  const [searchValue, setSearchValue] = useState<string>();
  const [linkedAccounts, setLinkedAccounts] = useState<Array<string>>([]);
  const [parentAccount, setParentAccount] = useState<string>('');

  useEffect(() => {
    if (
      linkedProcedure &&
      linkedProcedure?.LinkedAccounts &&
      linkedProcedure?.LinkedAccounts.length > 0 &&
      linkedProcedure?.LinkedFromAccount
    ) {
      const accountsLinked = [
        linkedProcedure?.LinkedFromAccount?.replace(/[)(]/g, ''),
        ...linkedProcedure?.LinkedAccounts,
      ];
      const sorted = accountsLinked?.sort((a, b) => a.localeCompare(b));
      if (sorted) setLinkedAccounts([...sorted]);
      setParentAccount('');
    } else if (
      linkedProcedure?.LinkedFromAccount &&
      linkedProcedure?.LinkedAccounts?.length === 0
    ) {
      setParentAccount(
        linkedProcedure?.LinkedFromAccount?.replace(/[)(]/g, ''),
      );
      setLinkedAccounts([]);
    }
  }, [linkedProcedure]);

  const handleSearch = (value: string) => {
    let newAccounts: string[] | null | undefined = [];
    let linkedAccounts: string[] | null | undefined = [];
    if (
      linkedProcedure &&
      linkedProcedure?.LinkedAccounts &&
      linkedProcedure?.LinkedAccounts.length > 0
    ) {
      const accountsLinked = [
        linkedProcedure?.LinkedFromAccount?.replace(/[)(]/g, ''),
        ...linkedProcedure?.LinkedAccounts,
      ];
      const sorted = accountsLinked?.sort((a, b) => a.localeCompare(b));
      if (sorted) {
        linkedAccounts = [...sorted];
      }
      newAccounts =
        linkedAccounts &&
        linkedAccounts.filter(item => {
          if (item.toLowerCase().includes(value.toLowerCase())) return item;
          return false;
        });
      if (newAccounts) setLinkedAccounts([...newAccounts]);
    } else if (
      linkedProcedure?.LinkedFromAccount &&
      linkedProcedure?.LinkedAccounts?.length === 0
    ) {
      const sorted = linkedProcedure?.LinkedFromAccount?.replace(/[)(]/g, '')
        .toLowerCase()
        .includes(value.toLowerCase());
      if (!sorted) setParentAccount('');
      else
        setParentAccount(
          linkedProcedure?.LinkedFromAccount?.replace(/[)(]/g, ''),
        );
    }
    setSearchValue(value);
  };

  return (
    <SlidePanel
      open={showLinkedAccounts}
      onClose={() => {
        toggleLinkedAccounts();
        setSearchValue('');
      }}
      title={
        linkedProcedure
          ? `Accounts linked to ${linkedProcedure?.Procedure?.Name}`
          : 'Accounts linked to'
      }
      size="sm"
    >
      <Flex flexDirection="column" height="100%">
        <Box>
          <SearchInput
            placeholder="Search linked accounts"
            mb={3}
            value={searchValue}
            handleChange={handleSearch}
          />
        </Box>
        <Stack
          direction="y"
          gap={3}
          flex="1 0 auto"
          height="1px"
          overflowY="auto"
        >
          {linkedAccounts && linkedAccounts?.length > 0 ? (
            linkedAccounts.map((item, index) => (
              <Text key={index} as="span">
                {item}
              </Text>
            ))
          ) : parentAccount.length ? (
            parentAccount
          ) : (
            <NoDataImage height="100%" feedback="No data available" />
          )}
        </Stack>
      </Flex>
    </SlidePanel>
  );
}
