import create from 'zustand';
import {
  ProcedureSearchParams,
  DetailSearchParams,
  QuestionSearchParams,
  SortItem,
} from 'types/accountsSearch';

type SearchStore = {
  searchStore:
    | {
        procedureSearchParams?: ProcedureSearchParams | undefined;
        detailSearchParams?: DetailSearchParams | undefined;
        questionSearchParams?: QuestionSearchParams | undefined;
        sectionSearchParams?: QuestionSearchParams | undefined;
        columnSearch: Record<string, string> | undefined;
        sortItem: SortItem | undefined;
      }
    | undefined;
  setParams: (params: {
    procedureSearchParams?: ProcedureSearchParams | undefined;
    detailSearchParams?: DetailSearchParams | undefined;
    questionSearchParams?: QuestionSearchParams | undefined;
    sectionSearchParams?: QuestionSearchParams | undefined;
    columnSearch: Record<string, string> | undefined;
    sortItem: SortItem | undefined;
  }) => void;
  removeParams: () => void;
};

const useAdvancedSearchStore = create<SearchStore>(set => ({
  searchStore: undefined,
  setParams: params => set(() => ({ searchStore: { ...params } })),
  removeParams: () => set({ searchStore: undefined }),
}));

export default useAdvancedSearchStore;
