import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Flex, Box, Heading, Text } from 'rebass/styled-components';
import useOnClickOutside from 'use-onclickoutside';
import {
  Container,
  Header,
  Body,
  Content,
  Chevron,
  LayoutNameInput,
  Footer,
  DDWrapper,
  GroupNameInput,
} from './styles';
import * as Icon from 'assets/icons';
import { Button, Iframe, Checkbox } from 'ui/components';
import { showErrorToast } from 'utils/showToast';
import { confirmAlert } from 'utils/confirm-alert';
import {
  DropdownButton,
  DropdownMenu,
  StyledDropdown,
  EmptyState,
} from 'ui/patterns';
import NoDataImage from 'assets/images/NoDataImage';
import { BlobFile } from 'types/procedureBuilder';

type Group = {
  label: string;
  value: string;
};

type ProcedureAccordionProps = {
  title?: string;
  content?: string | undefined;
  open?: string;
  container?: string;
  setOpen?: (arg0: any) => void;
  setInsertDisable?: (arg: boolean) => void;
  deleteTemplate?: (fileTitle: string) => void;
  editTemplate?: (
    oldFileName: string,
    newFileName: string,
    force: boolean,
  ) => void;
  templateExistingCheck?: (title: string | undefined) => boolean;
  updateTemplate?: (title: string, content: string | undefined) => void;
  templateGroups?: Array<Group>;
  handleGroupInsertion?: (
    selectedGroups: Array<Group>,
    title: string,
    content: string | undefined,
    unselectedGroups: Array<Group>,
  ) => void;
  mappedGrouping: () => void;
  ungroupedSet?: Array<BlobFile> | undefined;
  selectedGroupDropDown: Group;
};

function ProcedureAccordion({
  title,
  content,
  open,
  setOpen,
  container,
  deleteTemplate,
  editTemplate,
  templateExistingCheck,
  updateTemplate,
  setInsertDisable,
  templateGroups,
  handleGroupInsertion,
  mappedGrouping,
  ungroupedSet,
  selectedGroupDropDown,
}: ProcedureAccordionProps): ReactElement {
  const collapseRef = useRef<HTMLDivElement>(null);
  const [isFileNameEdit, setIsFileNameEdit] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>();
  //for knowing if the user is editing the same procedure or different one
  const [fileNameBeforeEdit, setFileNameBeforeEdit] = useState<string>();

  const [createTemplateGroup, setCreateTemplateGroup] =
    useState<boolean>(false);
  //To me iframe content editable
  const [templateContentEdit, setTemplateContentEdit] =
    useState<boolean>(false);

  /* States helping with grouping of templates */
  const [dropDownOpen, setDropDownOpen] = useState(false); // create group menu dropdown
  const [selectedGroups, setSelectedGroups] = useState<Array<Group>>([]); //grouping template folder selection
  const [groupName, setGroupName] = useState<string>(); //group name edit
  const [groups, setGroups] = useState<Array<Group>>([]); //list of exisiting folder options
  const [unselectedGroups, setUnselectedGroups] = useState<Array<Group>>([]); //groups removed while toggling selection in groups dropdown

  useEffect(() => {
    if (templateGroups) setGroups(templateGroups);
  }, [templateGroups]);
  /* States helping with grouping of states */

  const templateRef = useRef() as React.MutableRefObject<HTMLIFrameElement>;

  useEffect(() => {
    if (open !== title) {
      setTemplateContentEdit(false);
      setInsertDisable && setInsertDisable(false);
    }
  }, [open, setInsertDisable, title]);

  const handleClickOutsideGroupMenu = () => {
    setDropDownOpen(false);
  };

  const groupDropDownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(groupDropDownRef, handleClickOutsideGroupMenu);

  const handleToggle = (e: any) => {
    e.stopPropagation();
    setDropDownOpen(!dropDownOpen);
    mappedGrouping();
    const temp: Array<Group> = [];
    groups.map(group => {
      if (ungroupedSet) {
        const selected = ungroupedSet?.filter(item => item.FileName === title);
        const data = selected[0]?.mappedGroups?.filter(
          (item: string) => item === group.label,
        );
        const index =
          selectedGroups?.length > 0
            ? selectedGroups?.findIndex(
                (item: Group) => item.value === group.value,
              )
            : -1;
        if (data?.length && data[0] === group.value && index === -1) {
          temp.push(group);
        } else if (data?.length === 0 && index !== -1) {
          selectedGroups && selectedGroups.splice(index, 1);
        }
      }
    });
    setSelectedGroups([...selectedGroups, ...temp]);
  };

  const handleSelectedTemplateGroups = (group: Group) => {
    const selectedIndex =
      selectedGroups?.length > 0
        ? selectedGroups?.findIndex((item: Group) => item.value === group.value)
        : -1;
    const unselectedIndex =
      unselectedGroups?.length > 0
        ? unselectedGroups?.findIndex(
            (item: Group) => item.value === group.value,
          )
        : -1;
    if (selectedIndex === -1) {
      setSelectedGroups([...selectedGroups, group]);
      unselectedGroups && unselectedGroups.splice(unselectedIndex, 1);
      setUnselectedGroups([...unselectedGroups]);
    } else if (selectedIndex >= 0) {
      setUnselectedGroups([...unselectedGroups, group]);
      selectedGroups && selectedGroups.splice(selectedIndex, 1);
      setSelectedGroups([...selectedGroups]);
    }
  };

  const groupNameValidation = (groupName: string) => {
    const exisiting = templateGroups?.filter(
      item => item.label.toLowerCase() === groupName.toLowerCase(),
    );
    const newlyCreated = selectedGroups?.filter(
      item => item.label.toLowerCase() === groupName.toLowerCase(),
    );
    if (exisiting?.length || newlyCreated?.length) return true;
    else return false;
  };

  const menuContent = () => (
    <Box onClick={e => e.stopPropagation()}>
      <Heading
        as="h6"
        fontSize={2}
        className="dd-header"
        px={3}
        pt={1}
        pb={2}
        sx={{
          whiteSpace: 'break-spaces',
          textAlign: 'left',
          position: 'relative',
        }}
      >
        Groups
        <Button
          size="sm"
          variant="ghost"
          iconBefore={<Icon.X />}
          title="Close"
          onClick={e => {
            e.stopPropagation();
            setDropDownOpen(false);
          }}
          style={{
            position: 'absolute',
            right: '8px',
            top: '42%',
            transform: 'translateY(-50%)',
          }}
        />
      </Heading>
      {groups?.length === 0 ? (
        <Box style={{ height: '200px', position: 'relative' }}>
          <Box style={{ transform: 'scale(.75)', height: '100%' }}>
            <EmptyState>
              <NoDataImage feedback="No groups available" />
            </EmptyState>
          </Box>
        </Box>
      ) : null}
      <Box className="dd-list" p={3} sx={{ textAlign: 'left' }}>
        {groups &&
          groups.map((group: Group) => (
            <Checkbox
              label={group.label}
              value={group.value}
              name={group.value}
              key={group.label + group.value}
              checked={
                selectedGroups.findIndex(item => item.value === group.value) >=
                0
                  ? true
                  : false
              }
              handleChange={() => {
                handleSelectedTemplateGroups(group);
              }}
            />
          ))}
        {createTemplateGroup ? (
          <>
            <Flex>
              <Checkbox label="" value="newGroup" name="newGroup" checked />
              <GroupNameInput
                placeholder="Enter group name"
                value={groupName}
                onChange={e => {
                  e.stopPropagation();
                  setGroupName(e.target.value);
                }}
              />
              <Button
                size="sm"
                variant="ghost"
                iconBefore={<Icon.Check />}
                title="Save"
                onClick={() => {
                  if (
                    !groupName ||
                    !groupName.trim() ||
                    groupName.toLowerCase() === 'all' ||
                    groupName.includes(',')
                  ) {
                    showErrorToast({
                      message: 'Please enter a valid name',
                    });
                    return;
                  } else if (groupNameValidation(groupName)) {
                    showErrorToast({
                      message: 'Group name already exists.',
                    });
                    return;
                  } else {
                    setGroups([
                      ...groups,
                      { value: groupName.trim(), label: groupName.trim() },
                    ]);
                    setSelectedGroups([
                      ...selectedGroups,
                      { label: groupName.trim(), value: groupName.trim() },
                    ]);
                    setCreateTemplateGroup(false);
                    setGroupName('');
                  }
                }}
              />
              <Button
                size="sm"
                variant="ghost"
                iconBefore={<Icon.X />}
                title="Cancel"
                onClick={() => {
                  setCreateTemplateGroup(false);
                  setGroupName('');
                }}
              />
            </Flex>
          </>
        ) : null}
      </Box>
      <Box
        px={3}
        pt={2}
        pb={1}
        className="dd-footer"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button
          variant="outline"
          size="sm"
          onClick={() => setCreateTemplateGroup(true)}
        >
          Create new group
        </Button>
        <Button
          variant="primary"
          onClick={(e: any) => {
            e.stopPropagation();
            if (title?.length && handleGroupInsertion) {
              handleGroupInsertion(
                selectedGroups,
                title,
                content,
                unselectedGroups,
              );
            }
            setDropDownOpen(false);
          }}
          disabled={
            selectedGroups?.length === 0 || createTemplateGroup ? true : false
          }
        >
          Apply
        </Button>
      </Box>
    </Box>
  );

  return (
    <Container>
      <Header
        onClick={() => {
          setOpen && setOpen(title === open ? '' : title);
        }}
        opened={open === title ? true : false}
        style={{ gap: '0.5rem' }}
      >
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          flexGrow={1}
          style={{ gap: '0.5rem' }}
        >
          {isFileNameEdit ? (
            <LayoutNameInput
              type="text"
              value={fileName}
              onClick={e => {
                e.stopPropagation();
              }}
              onChange={e => {
                e.stopPropagation();
                setFileName(e.target.value);
              }}
            />
          ) : (
            <Text fontWeight="500">{title}</Text>
          )}

          {/* Edit Template Action Buttons */}
          {isFileNameEdit ? (
            <>
              <Button
                size="sm"
                variant="ghost"
                iconBefore={<Icon.Check />}
                title="Rename"
                onClick={(e: any) => {
                  e.stopPropagation();
                  if (!fileName || !fileName.trim() || fileName.includes(',')) {
                    showErrorToast({
                      message: 'Please enter a valid title',
                    });
                    return;
                  } else {
                    if (
                      templateExistingCheck &&
                      templateExistingCheck(fileName.trim()) === true &&
                      fileNameBeforeEdit?.toLowerCase().trim() !==
                        fileName?.toLowerCase().trim()
                    ) {
                      confirmAlert({
                        message:
                          container === 'layouts'
                            ? 'Layout with the same name exists. Do you want to overwrite the current layout?'
                            : 'Table with the same name exists. Do you want to overwrite the current table?',

                        buttons: [
                          {
                            label: 'Yes',
                            onClick: () => {
                              if (editTemplate !== undefined && title) {
                                editTemplate(title, fileName, true);
                                setIsFileNameEdit(false);
                              }
                            },
                            className: 'pr-btn-primary',
                          },
                          {
                            label: 'No',
                            onClick: () => {
                              //
                            },
                          },
                        ],
                      });
                    } else {
                      if (editTemplate !== undefined && title) {
                        editTemplate(title, fileName, false);
                        setIsFileNameEdit(false);
                      }
                    }
                  }
                }}
              />
              <Button
                size="sm"
                variant="ghost"
                iconBefore={<Icon.X />}
                title="Cancel"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsFileNameEdit(false);
                }}
              />
            </>
          ) : null}
        </Flex>
        {/* Edit Template Action Buttons */}
        {/* Edit Template Button */}
        {!isFileNameEdit ? (
          <Button
            size="sm"
            variant="ghost"
            iconBefore={<Icon.Edit />}
            title={container === 'layouts' ? 'Rename layout' : 'Rename table'}
            onClick={(e: any) => {
              e.stopPropagation();
              setIsFileNameEdit(true);
              setFileName(title);
              setFileNameBeforeEdit(title);
            }}
          />
        ) : null}
        {/* Edit Template Button */}
        {/* Layout group button */}
        <DDWrapper style={{ textAlign: 'right' }}>
          <StyledDropdown
            ref={groupDropDownRef}
            onClick={handleToggle}
            className="dd-col-selection dd-group-selection"
          >
            <DropdownButton title="Add to Group">
              <Icon.Folder />
            </DropdownButton>
            {dropDownOpen && <DropdownMenu>{menuContent()}</DropdownMenu>}
          </StyledDropdown>
        </DDWrapper>
        {/* Layout group button */}

        {/* Delete Template Button */}
        <Button
          size="sm"
          variant="ghostDanger"
          iconBefore={
            selectedGroupDropDown?.label === 'All' ? (
              <Icon.Delete />
            ) : (
              <Icon.minusCircle />
            )
          }
          title={
            container === 'layouts'
              ? 'Remove layout'
              : selectedGroupDropDown?.label === 'All'
              ? 'Delete Table'
              : 'Remove table from group'
          }
          onClick={(e: any) => {
            e.stopPropagation();
            setIsFileNameEdit(false);
            if (deleteTemplate !== undefined && title) {
              deleteTemplate(title);
            }
          }}
          /* Delete Template Button */
        />

        <Chevron opened={open === title ? true : false}>
          {' '}
          <Icon.ChevronDown />
        </Chevron>
      </Header>
      <Body
        ref={collapseRef}
        style={
          open === title
            ? { height: collapseRef.current?.scrollHeight + 'px' }
            : { height: '0px' }
        }
      >
        <Flex flexDirection="column" width="100%">
          <Content>
            {' '}
            <Iframe
              ref={templateRef}
              srcDoc={
                templateContentEdit
                  ? `<div class='pr-procedure-layout'contenteditable="true">${content}</div>`
                  : content
              }
            />
          </Content>
          <Footer>
            {templateContentEdit ? (
              <Flex style={{ gap: '0.5rem' }}>
                <Button
                  variant="ghost"
                  onClick={() => {
                    confirmAlert({
                      message: 'Are you sure you want to discard the changes?',
                      buttons: [
                        {
                          label: 'Yes',
                          onClick: () => {
                            setTemplateContentEdit(false);
                            setInsertDisable && setInsertDisable(false);
                          },
                          className: 'pr-btn-primary',
                        },
                        {
                          label: 'No',
                          onClick: () => {
                            //
                          },
                        },
                      ],
                    });
                  }}
                >
                  Discard
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    setTemplateContentEdit(false);
                    setInsertDisable && setInsertDisable(false);
                    if (updateTemplate && title && content) {
                      const editedContent =
                        templateRef?.current?.contentWindow?.document?.body.querySelector(
                          '.pr-procedure-layout',
                        )?.innerHTML;

                      updateTemplate(title, editedContent);
                    }
                  }}
                >
                  Update
                </Button>
              </Flex>
            ) : (
              <Button
                variant="primary"
                onClick={() => {
                  setTemplateContentEdit(true);
                  setInsertDisable && setInsertDisable(true);
                }}
              >
                {container === 'layouts' ? 'Edit Layout' : 'Edit Table'}{' '}
              </Button>
            )}
          </Footer>
        </Flex>
      </Body>
    </Container>
  );
}

export { ProcedureAccordion };
