import { usePaginatedQuery, PaginatedQueryResult } from 'react-query';
import { Location } from 'types/locations';
import { getLocationList } from '../utils/urls';
import { getTokenWithType } from './useAuth';

function getColumnFromId(id: string) {
  const idsList = [
    'Location Name',
    'Street',
    'City',
    'State',
    'Zip Code',
    'County',
    'Country',
    'Notes',
  ];
  const index = idsList.findIndex(item => item === id);
  return `${index}`;
}

async function fetchLocationList({
  requestData,
  accountId,
}: TVariables): Promise<TResult> {
  const response = await fetch(getLocationList(accountId), {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

type resultType = {
  data: Array<Location>;
  draw: number;
  isAutoRefreshEnabled: boolean;
  error: Error;
  recordsFiltered: number;
  recordsTotal: number;
};

export type TResult = {
  message: string;
  isSuccess: boolean;
  data: Array<resultType>;
  recordsFiltered: number;
  recordsTotal: number;
  draw: number;
  error: Error | null;
};

export type TError = { message: string };
export type TVariables = {
  requestData: any;
  accountId: number | string;
};

function formatRequestData(
  page: number,
  columnsSearch: Record<string, string>,
  sortedColumn: string,
  sortOrder: string,
  keywordSearch: string,
): FormData {
  const formData = new URLSearchParams();

  formData.append('draw', '1');
  formData.append('columns[0][data]', 'Name');
  formData.append('columns[0][name]', '');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append(
    'columns[0][search][value]',
    columnsSearch && columnsSearch['Location Name']
      ? columnsSearch['Location Name']
      : '',
  );
  formData.append('columns[0][search][regex]', 'false');
  formData.append('columns[1][data]', 'Street');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append(
    'columns[1][search][value]',
    columnsSearch && columnsSearch.Street ? columnsSearch.Street : '',
  );
  formData.append('columns[1][search][regex]', 'false');
  formData.append('columns[2][data]', 'City');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append(
    'columns[2][search][value]',
    columnsSearch && columnsSearch['City'] ? columnsSearch['City'] : '',
  );
  formData.append('columns[2][search][regex]', 'false');
  formData.append('columns[3][data]', 'StateProvince');
  formData.append('columns[3][name]', 'StateProvince.Name');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append(
    'columns[3][search][value]',
    columnsSearch && columnsSearch['State'] ? columnsSearch['State'] : '',
  );
  formData.append('columns[3][search][regex]', 'false');
  formData.append('columns[4][data]', 'PostalCode');
  formData.append('columns[4][name]', '');
  formData.append('columns[4][searchable]', 'true');
  formData.append('columns[4][orderable]', 'true');
  formData.append(
    'columns[4][search][value]',
    columnsSearch && columnsSearch['Zip Code'] ? columnsSearch['Zip Code'] : '',
  );
  formData.append('columns[4][search][regex]', 'false');
  formData.append('columns[5][data]', 'County');
  formData.append('columns[5][name]', '');
  formData.append('columns[5][searchable]', 'true');
  formData.append('columns[5][orderable]', 'true');
  formData.append(
    'columns[5][search][value]',
    columnsSearch && columnsSearch['County'] ? columnsSearch['County'] : '',
  );
  formData.append('columns[5][search][regex]', 'false');
  formData.append('columns[6][data]', 'Country');
  formData.append('columns[6][name]', '');
  formData.append('columns[6][searchable]', 'true');
  formData.append('columns[6][orderable]', 'true');
  formData.append(
    'columns[6][search][value]',
    columnsSearch && columnsSearch['Country'] ? columnsSearch['Country'] : '',
  );
  formData.append('columns[6][search][regex]', 'false');
  formData.append('columns[7][data]', 'Notes');
  formData.append('columns[7][name]', '');
  formData.append('columns[7][searchable]', 'true');
  formData.append('columns[7][orderable]', 'true');
  formData.append(
    'columns[7][search][value]',
    columnsSearch && columnsSearch['Notes'] ? columnsSearch['Notes'] : '',
  );
  formData.append('columns[7][search][regex]', 'false');
  formData.append('order[0][column]', sortedColumn);
  formData.append('order[0][dir]', sortOrder);
  formData.append('search[value]', `${keywordSearch}`);
  formData.append('search[regex]', 'false');
  formData.append('start', `${page * 100}`);
  formData.append('length', `${100}`);
  formData.append('timezoneOffset', '-330');
  return formData;
}

function useLocations(
  page: number,
  columnsSearch: Record<string, string>,
  sortItem: { sortKey: string; sortOrder: string },
  keywordSearch: string,
  accountId: number,
): PaginatedQueryResult<TResult, TError> {
  const requestData = formatRequestData(
    page,
    columnsSearch,
    getColumnFromId(sortItem.sortKey),
    sortItem.sortOrder,
    keywordSearch,
  );
  return usePaginatedQuery(
    ['locations_list', page, keywordSearch, columnsSearch, sortItem],
    () => fetchLocationList({ requestData, accountId }),
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useLocations };
