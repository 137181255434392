import React, { ReactElement, ReactNode } from 'react';
import { confirmAlert } from 'utils/confirm-alert';
import { Dropdown, Loader } from 'ui/patterns';
import { Button } from 'ui/components';
import { ColumnInstance, Row } from 'react-table';
import * as Icon from 'assets/icons';
import { Forms } from 'types/forms';
import { useFormDetailsCopy, useUpdateFormDetails } from 'hooks';

export default function TableOptionsHeader(props: {
  column: ColumnInstance<Forms>;
  row: Row<Forms>;
  onEditPress?: (row: any) => void;
}): ReactElement {
  const formId = props.row.original.Id;
  const [updateFormDetails, { isLoading }] = useUpdateFormDetails();
  const [copyForm, { isLoading: isFormCopyLoading }] = useFormDetailsCopy();

  const formsListOptions: Array<{
    value: string;
    icon: ReactNode;
    groupEnd?: boolean;
    action: () => void;
  }> = [
    {
      value: 'Edit Form',
      icon: <Icon.Edit title="" ariaHidden="true" />,
      groupEnd: true,
      action: () => handleEditForm(),
    },
    {
      value:
        props.row.original.SurveyStatus?.Description === 'Disabled'
          ? 'Enable Form'
          : 'Disable Form',
      icon:
        props.row.original.SurveyStatus?.Description === 'Disabled' ? (
          <Icon.CheckCircle title="" ariaHidden="true" />
        ) : (
          <Icon.Ban title="" ariaHidden="true" />
        ),
      groupEnd: true,
      action: () => handleEnableDisableForm(),
    },
  ];

  const copyFormMenuItem = {
    value: 'Copy Form',
    icon: <Icon.Copy title="" ariaHidden="true" />,
    // groupEnd: true,
    action: () => {
      copyForm(formId);
    },
  };

  if (props.row.original.Account?.CID !== '9000') {
    formsListOptions.push(copyFormMenuItem);
  }

  const handleEnableDisableForm = () => {
    confirmAlert({
      message: `Are you sure you want to ${
        props.row.original.SurveyStatus?.Description === 'Disabled'
          ? 'enable'
          : 'disable'
      } this form?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            updateFormDetails(formId);
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  const handleEditForm = (): void => {
    // history.push('/home/forms/edit', { ID: accountId, formId: formId });
    if (props.onEditPress) props.onEditPress(formId);
  };

  const formsListDropdown = () => (
    <Button size="sm">
      <Icon.DotsVertical title="Options" />
    </Button>
  );

  return (
    <>
      <Dropdown
        trigger={formsListDropdown()}
        menuList={formsListOptions}
        position="left-top"
        className="dd-listitem-options"
      />
      {(isLoading || isFormCopyLoading) && <Loader />}
    </>
  );
}
