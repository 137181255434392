import { useQuery, QueryResult } from 'react-query';
import { tagMappings } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { TagMappings } from 'types/tagMapping';

async function fetchTagMappings({ accountId }: TVariables): Promise<TResult> {
  const response = await fetch(tagMappings(accountId), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = Array<TagMappings>;

export type TError = { message: string };
export type TVariables = {
  accountId: number;
};

function useGetTagMappings(accountId: number): QueryResult<TResult, TError> {
  return useQuery(
    'get_tag_mappings',
    async () => {
      const fetchResponse = await fetchTagMappings({
        accountId,
      });
      return fetchResponse;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetTagMappings };
