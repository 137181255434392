import React, { useEffect } from 'react';
import { AuthConsumer } from 'utils/authenticationHelper/authProvider';
import { Loader } from 'ui/patterns';
import { useHistory } from 'react-router';

export const Logout = () => {
  const history = useHistory();
  useEffect(() => {
    // setTimeout(() => {
    history.push('/home');
    // window.location = history.location.pathname.replace("logout", "home");
    // }, 60 * 1000);
  }, [history]);
  return <Loader />;
};
