import { useMutation, MutationResultPair, queryCache } from 'react-query';
import {
  copyImportedProcedures,
  copyImportedProceduresInvisible,
} from 'utils/urls';
import { getTokenWithType } from './useAuth';

export async function duplicateImportedProcedure({
  accountId,
  selectedDuplicates,
  invisible,
}: {
  accountId: number;
  selectedDuplicates: Array<any> | undefined;
  invisible: boolean;
}): Promise<TResult> {
  const url = invisible
    ? copyImportedProceduresInvisible(accountId)
    : copyImportedProcedures(accountId);
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(selectedDuplicates),
  });
  if (response.status !== 200) {
    const error = await response.json();
    throw error;
  }
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = ValidationError;
export type TVariables = duplicateImportedProcedureInput;

export type duplicateImportedProcedureInput = {
  accountId: number;
  selectedDuplicates: Array<any> | undefined;
  invisible: boolean;
};
export type TError = string;
export type TSnapshot = unknown;
function useImportedProcedureDuplicate(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(async (params: duplicateImportedProcedureInput) => {
    const data = await duplicateImportedProcedure({
      accountId: params.accountId,
      selectedDuplicates: params.selectedDuplicates,
      invisible: params.invisible,
    });
    queryCache.invalidateQueries(['get_procedure_builder_procedures'], {
      refetchInactive: true,
    });
    queryCache.invalidateQueries(['get_global_procedures'], {
      refetchInactive: true,
    });
    queryCache.invalidateQueries(['get_archived_procedures'], {
      refetchInactive: true,
    });
    queryCache.invalidateQueries(['get_procedure'], {
      refetchInactive: true,
    });
    return data;
  });
}

export { useImportedProcedureDuplicate };
