const checkBoxList = [
  {
    id: 1,
    label: 'Office Facts',
    name: 'CopyOfficeFacts',
  },
  {
    id: 2,
    label: 'Location List',
    name: 'CopyLocationList',
  },
  {
    id: 3,
    label: 'Contact List',
    name: 'CopyContactList',
  },
  {
    id: 4,
    label: 'All Custom Tables and Forms',
    name: 'CopyCustomTablesAndForms',
  },
  {
    id: 5,
    label: 'XML Tag Mappings',
    name: 'CopyTagMappings',
  },
  {
    id: 6,
    label: 'FTP Settings',
    name: 'CopyFtpSettings',
  },
];

export { checkBoxList };
