import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useReducer,
  ReactElement,
} from 'react';
import { showErrorToast } from 'utils/showToast';
import { HighlightTextWrapper } from 'ui/patterns';
import { useGetAdvancedDetailSearch, useSaveAdvancedDetailSearch } from 'hooks';
import useDetailSearchColumns from './useDetailSearchColumns';
import {
  DetailSearchParams,
  SearchRuleItem,
  DetailOptionsType,
} from 'types/accountsSearch';
import useAdvancedSearchStore from 'store/useAdvancedSearchStore';

const detailsColumnData = [
  'CID',
  'PID',
  'SID',
  'Name',
  'RelationshipManager',
  'Procedure',
  'SearchSnippet',
];

const searchItem: SearchRuleItem = {
  value: '',
  expression: 'Contains',
  operator: 'AND',
  regex: false,
  orderable: true,
};

const sortItem = { sortKey: '', sortOrder: '' };

const detailInitialState: DetailOptionsType = {
  questionDetails: true,
  fieldLabel: false,
  includeHidden: false,
};

function reducer(
  state: DetailOptionsType,
  action: {
    type: keyof DetailOptionsType | 'reset';
    newState?: DetailOptionsType;
  },
) {
  switch (action.type) {
    case 'questionDetails': {
      return {
        ...state,
        questionDetails: !state.questionDetails,
      };
    }
    case 'fieldLabel': {
      return {
        ...state,
        fieldLabel: !state.fieldLabel,
      };
    }
    case 'includeHidden': {
      return {
        ...state,
        includeHidden: !state.includeHidden,
      };
    }
    case 'reset':
      if (action.newState) return { ...action.newState };
      return { ...detailInitialState };
    default:
      throw new Error();
  }
}
const initialSearchList: Array<SearchRuleItem> = [{ ...searchItem }];

export default function useDetailSearch() {
  // search block states
  const [searchList, setSearchList] = useState<Array<SearchRuleItem>>([
    ...initialSearchList.map(item => item),
  ]);

  //checkbox states
  const [detailsOptions, setDetailsOptions] = useReducer(reducer, {
    ...detailInitialState,
  });
  const [searchParams, setSearchParams] = useState<DetailSearchParams>();
  const [columnsSearch, setColumnsSearch] = useState({}); // header search
  const [currentSortItem, setCurrentSortItem] = useState(sortItem);

  const {
    data,
    fetchMore,
    canFetchMore,
    isFetching,
    isLoading: advancedSearchloading,
    isFetchingMore,
  } = useGetAdvancedDetailSearch(searchParams, columnsSearch, currentSortItem);

  const pageNumber = data && data[data?.length - 1]?.pageNumber;

  const [saveInterAccountSearchList] = useSaveAdvancedDetailSearch();

  const handleDownloadDetailSearchResult = () => {
    saveInterAccountSearchList({
      searchParams,
      columnsSearch,
      currentSortItem,
      pageNumber,
    });
  };

  const tableData = useMemo(() => {
    if (data) {
      return data
        .map(dataItem => dataItem?.data)
        .flat()
        .filter(item => !!item);
    }
    return;
  }, [data]);

  const currentRecords = useMemo(() => {
    if (data) {
      return data.map(dataItem => dataItem?.data).flat();
    }
  }, [data]);

  const totalRecords = useMemo(() => {
    if (data) {
      return data[0] && data[0].recordsTotal;
    }
    return 0;
  }, [data]);

  const onEndReached = () => {
    if (canFetchMore) fetchMore();
  };

  const columnList = useDetailSearchColumns([...detailsColumnData.slice()]);

  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch],
  );

  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (
        currentSortItem.sortKey === item &&
        currentSortItem.sortOrder === 'asc'
      ) {
        sortOrder = 'desc';
      }
      setCurrentSortItem({
        sortKey: item,
        sortOrder,
      });
    },
    [setCurrentSortItem, currentSortItem],
  );

  const resetDetailSearch = () => {
    setSearchList([{ ...searchItem }]);
    setColumnsSearch({});
    setCurrentSortItem(sortItem);
    setDetailsOptions({ type: 'reset' });
    setSearchParams(undefined);
  };

  const toggleDetailOption = (key?: keyof DetailOptionsType) => () => {
    if (!key) return setDetailsOptions({ type: 'reset' });
    return setDetailsOptions({ type: key });
  };

  function searchButtonClick() {
    if (searchList.filter(item => item.value.trim().length === 0).length > 0) {
      showErrorToast({
        message: 'Please Enter a keyword',
      });
    } else {
      setSearchParams({
        searchList: JSON.parse(JSON.stringify(searchList.slice())),
        ...detailsOptions,
      });
    }
  }

  const { searchStore, setParams } = useAdvancedSearchStore();

  useEffect(() => {
    if (searchStore) {
      if (searchStore.detailSearchParams) {
        const { questionDetails, fieldLabel, searchList, includeHidden } =
          searchStore.detailSearchParams;
        setDetailsOptions({
          type: 'reset',
          newState: {
            questionDetails,
            fieldLabel,
            includeHidden,
          },
        });
        setSearchList(searchList);
      }
      if (searchStore.sortItem) {
        setCurrentSortItem(searchStore.sortItem);
      }
      if (searchStore.columnSearch) {
        setColumnsSearch(searchStore.columnSearch);
      }
      setSearchParams(searchStore.detailSearchParams);
    }
  }, [searchStore]);

  function onClickToolTip(
    path: string,
    params: Record<string, number | string>,
  ) {
    setParams({
      detailSearchParams: searchParams,
      columnSearch: columnsSearch,
      sortItem: currentSortItem,
    });
    localStorage.setItem('fromSearch', JSON.stringify(params?.Pid));
    window.open(path);
  }

  function getHighlightedText(content: string): ReactElement {
    let newString = [content];
    searchList?.forEach(searchItem => {
      const arr: string[] = [];
      newString?.forEach(x => {
        if (x !== undefined)
          arr?.push(...x?.split(new RegExp(`(${searchItem.value})`, 'gi')));
      });
      newString = [...arr];
    });

    return (
      <>
        <HighlightTextWrapper content={content} searchedWords={searchList} />
      </>
    );
  }

  return {
    totalDetailRecordsCount: totalRecords,
    listedDetailRecordCount: currentRecords,
    isDetailSearchFetching: isFetching,
    detailsOptions,
    toggleDetailOption,
    resetDetailSearch,
    handleDownloadDetailSearchResult,
    detailTableProps: {
      columns: columnList,
      columnsSearch: columnsSearch,
      data: tableData,
      headerUpdateCallBack: headerUpdateCallBack,
      sortedItem: currentSortItem,
      handleSortChange: handleSortChange,
      isLoading: advancedSearchloading,
      noOptions: true,
      onEndReached: onEndReached,
      isFetching: !!isFetchingMore,
      onRedirect: onClickToolTip,
      getHighlightedText,
    },
    detailSearch: {
      searchList,
      setSearchList,
      searchButtonClick,
    },
  };
}
