import styled from 'styled-components';
import {
  SpaceProps,
  space,
  LayoutProps,
  layout,
  FlexboxProps,
  flexbox,
} from 'styled-system';

type ssProps = SpaceProps & LayoutProps & FlexboxProps;

type FormGroupProps = ssProps & {
  className?: string;
  style?: React.CSSProperties;
};

const FormGroup = styled.div<FormGroupProps>`
  &.textarea-draggable-qn {
    &.textarea-draggable-qn > * {
      --py: 0.371em;
    }

    & textarea {
      resize: vertical;
      @-moz-document url-prefix() {
        & {
          height: calc(1.5em + 0.75rem + 2px);
        }
      }
      @supports (-ms-ime-align: auto) and (-webkit-text-stroke: initial) {
        & {
          height: auto;
        }
      }
    }
  }

  ${space}
  ${layout}
	${flexbox}
`;

export { FormGroup };
