import React, { ReactElement, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';
import { layout, space } from 'styled-system';
import { getHSL } from 'utils/getHSL';

// import { StyledFormInput } from '../textInput';
type RefReturn =
  | string
  | ((instance: HTMLSelectElement | null) => void)
  | React.RefObject<HTMLSelectElement>
  | null
  | undefined;

export type OptionItem = {
  id: number;
  name: string;
};
type SelectProps = {
  value?: number;
  placeholder?: string;
  id?: string;
  options?: Array<OptionItem>;
  disabled?: boolean;
  validation?: 'error' | 'success' | 'warning';
  onChange?: (e: SyntheticEvent<HTMLSelectElement>) => void;
  register?: ({ required }: { required?: boolean }) => RefReturn;
  name?: string;
  inline?: boolean;
  style?: React.CSSProperties;
};

type item = string;

const StyledSelect = styled.select<SelectProps>`
  ${layout};
  ${space};

  --px: 0.571em;
  --py: 0.571em;
  --a: 80%;
  --h: var(--primary-h);
  --s: var(--primary-s);
  --l: var(--primary-l);
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: var(--py) var(--px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.text.body};
  background-color: hsla(var(--color-neutral-contrast-03-hsl), 50%);
  background-clip: padding-box;
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  border-radius: ${({ theme }) => theme.radii.rounded};
  transition: all 200ms ease-in-out;

  &:focus {
    background-color: var(--color-neutral-contrast-00);
    border-color: var(--primary-color);
    outline: 0;
    box-shadow: 0 0 0 0.2rem hsla(var(--h), var(--s), var(--l), 30%);

    html[data-color-mode='dark'] & {
      background-color: var(--color-neutral-contrast-00);
    }
  }

  html[data-color-mode='dark'] & {
    --a: 40%;

    ::placeholder {
      color: var(--reduced-high-contrast-neutral);
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: var(--input-disabled-opacity);
      cursor: not-allowed;
    `}

  ${({ validation }) =>
    validation === 'error' &&
    css`
      border-color: ${({ theme }) => theme.colors.border.error};
      --h: ${({ theme }) => getHSL('h', theme.colors.state.error)};
      --s: ${({ theme }) => getHSL('s', theme.colors.state.error)};
      --l: ${({ theme }) => getHSL('l', theme.colors.state.error)};

      &:focus {
        border-color: ${({ theme }) => theme.colors.border.error};
      }
    `}

    ${({ validation }) =>
    validation === 'success' &&
    css`
      border-color: ${({ theme }) => theme.colors.border.success};
      --h: ${({ theme }) => getHSL('h', theme.colors.state.success)};
      --s: ${({ theme }) => getHSL('s', theme.colors.state.success)};
      --l: ${({ theme }) => getHSL('l', theme.colors.state.success)};

      &:focus {
        border-color: ${({ theme }) => theme.colors.border.success};
      }
    `}

    ${({ validation }) =>
    validation === 'warning' &&
    css`
      border-color: ${({ theme }) => theme.colors.border.warning};
      --h: ${({ theme }) => getHSL('h', theme.colors.state.warning)};
      --s: ${({ theme }) => getHSL('s', theme.colors.state.warning)};
      --l: ${({ theme }) => getHSL('l', theme.colors.state.warning)};

      &:focus {
        border-color: ${({ theme }) => theme.colors.border.warning};
      }
    `}

  ${({ inline }) =>
    inline &&
    css`
      width: auto;
    `}
  --py: 0.171em;

  & > option {
    --bg: var(--color-neutral-contrast-00);
    background-color: var(--bg);
    color: var(--high-contrast-neutral);

    html[data-color-mode='dark'] & {
      --bg: ${({ theme }) => theme.baseColors.gray[8]};
    }
  }
`;

type ISelectProps = {
  value?: number;
  placeholder?: string;
  id?: string;
  options?: Array<OptionItem>;
  disabled?: boolean;
  validation?: 'error' | 'success' | 'warning';
  onChange?: (e: SyntheticEvent<HTMLSelectElement>) => void;
  register?: ({ required }: { required?: boolean }) => RefReturn;
  name?: string;
  inline?: boolean;
  noSelectOption?: boolean;
  style?: React.CSSProperties;
};
const Select = ({
  name,
  placeholder,
  value,
  id,
  options,
  disabled,
  validation,
  onChange,
  register,
  inline,
  noSelectOption,
  style,
}: ISelectProps): ReactElement => {
  // const selectedValue = options?.find(option => option.id === value);
  return (
    <StyledSelect
      name={name}
      as="select"
      placeholder={placeholder}
      value={value}
      id={id}
      options={options}
      validation={validation}
      disabled={disabled}
      onChange={onChange}
      inline={inline}
      style={style}
      // ref={register && register({ required: true })}
      // defaultValue={value}
    >
      {!noSelectOption && name !== 'advancedSearch' && (
        <option value="" selected>
          Select one
        </option>
      )}

      {options &&
        options.map(option => (
          <option
            value={option.id}
            key={option.id}
            // selected={value === option.id}
          >
            {option.name}
          </option>
        ))}
    </StyledSelect>
  );
};

export { Select, StyledSelect };
