import React, { ReactElement, useState, useCallback, useMemo } from 'react';
import {
  MainContent,
  PageContent,
  ButtonGroup,
  PageHeader,
  Table,
} from 'ui/patterns';
import { useDocumentTitle, useCheckLinks } from 'hooks';
import useCheckLinksTableColumns from './useCheckLinksColumns';
import { Text } from 'rebass/styled-components';
import { Button } from 'ui/components/button';
import * as Icon from '../../../assets/icons';

const pageTitle = 'Links Report';
const page = 100;

const sortItem = { sortKey: 'Location', sortOrder: 'asc' };

const filterOptions = [
  { id: 0, filter: 'Bad Links' },
  { id: 1, filter: 'All Links' },
];

export default function CheckLinks(): ReactElement {
  useDocumentTitle('Links Report', true);
  const urlParams = new URLSearchParams(window.location.search);
  const accountId = urlParams.get('accountId');
  const formId = urlParams.get('surveyId');

  const pageActions = [
    {
      action: 'Reset',
      icon: <Icon.Refresh title="Refresh" />,
      handleButtonClick: () => handleReset(),
    },
  ];
  const [columnsSearch, setColumnsSearch] = useState<Record<string, string>>(); // header search
  const [currentSortItem, setCurrentSortItem] = useState(sortItem);

  const columns = useCheckLinksTableColumns();
  // const [page, setPage] = useState(0);
  const [linkStatus, setLinkStatus] = useState('Bad Links'); // account filter

  const [searchText, setSearchText] = useState<string>('');

  const {
    data,
    // fetchMore,
    // canFetchMore,
    // isFetchingMore,
    isLoading,
    isFetching,
  } = useCheckLinks(page, accountId, formId);

  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch],
  );

  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (
        currentSortItem.sortKey === item &&
        currentSortItem.sortOrder === 'asc'
      ) {
        sortOrder = 'desc';
      }
      setCurrentSortItem({
        sortKey: item,
        sortOrder,
      });
    },
    [setCurrentSortItem, currentSortItem],
  );

  const filteredData = useMemo(() => {
    let filteredData = data ? [...data] : [];
    if (columnsSearch) {
      if (columnsSearch['Location']) {
        filteredData = filteredData.filter(item =>
          item.Location.toLocaleLowerCase().includes(
            columnsSearch['Location'].toLocaleLowerCase(),
          ),
        );
      }
      if (columnsSearch['Sub Location']) {
        filteredData = filteredData.filter(item =>
          item.SubLocation.includes(
            columnsSearch['Sub Location'].toLocaleLowerCase(),
          ),
        );
      }
      if (columnsSearch['Section']) {
        filteredData = filteredData.filter(item =>
          item.SectionTitle.toLocaleLowerCase().includes(
            columnsSearch['Section'].toLocaleLowerCase(),
          ),
        );
      }
      if (columnsSearch['Question']) {
        filteredData = filteredData.filter(item =>
          item.QuestionName.toLocaleLowerCase().includes(
            columnsSearch['Question'].toLocaleLowerCase(),
          ),
        );
      }
      if (columnsSearch['Url']) {
        filteredData = filteredData.filter(item =>
          item.Url.toLocaleLowerCase().includes(
            columnsSearch['Url'].toLocaleLowerCase(),
          ),
        );
      }
      if (columnsSearch['Status Code']) {
        filteredData = filteredData.filter(
          item =>
            item?.StatusCode &&
            `${item.StatusCode}`
              .toLocaleLowerCase()
              .includes(columnsSearch['Status Code'].toLocaleLowerCase()),
        );
      }
      if (columnsSearch['Status Description']) {
        filteredData = filteredData.filter(item =>
          item.StatusDescription.toLocaleLowerCase().includes(
            columnsSearch['Status Description'].toLocaleLowerCase(),
          ),
        );
      }
    }
    if (searchText) {
      filteredData = filteredData.filter(
        item =>
          item.Location.toLocaleLowerCase().includes(
            searchText.toLocaleLowerCase(),
          ) ||
          item.SubLocation.toLocaleLowerCase().includes(
            searchText.toLocaleLowerCase(),
          ) ||
          item.SectionTitle.toLocaleLowerCase().includes(
            searchText.toLocaleLowerCase(),
          ) ||
          item.QuestionName.toLocaleLowerCase().includes(
            searchText.toLocaleLowerCase(),
          ) ||
          item.Url.toLocaleLowerCase().includes(
            searchText.toLocaleLowerCase(),
          ) ||
          (item?.StatusCode &&
            `${item.StatusCode}`
              .toLocaleLowerCase()
              .includes(`${searchText}`.toLocaleLowerCase())) ||
          item.StatusDescription.toLocaleLowerCase().includes(
            searchText.toLocaleLowerCase(),
          ),
      );
    }
    return filteredData;
  }, [columnsSearch, data, searchText]);

  const statusFilteredData = useMemo(() => {
    if (!filteredData) return filteredData;
    const statusFilter = [...filteredData];

    if (linkStatus === 'Bad Links')
      return statusFilter.filter(
        item => item?.StatusCode === 404 || item?.StatusCode === 403,
      );
    else return statusFilter;
  }, [filteredData, linkStatus]);

  const sortedData = useMemo(() => {
    if (!statusFilteredData) return statusFilteredData;
    const sorted = [...statusFilteredData];

    sorted.sort((a, b) => {
      if (
        currentSortItem.sortKey === 'Location' &&
        currentSortItem.sortOrder === 'asc'
      )
        return a.Location.localeCompare(b.Location);
      else if (
        currentSortItem.sortKey === 'Location' &&
        currentSortItem.sortOrder === 'desc'
      )
        return b.Location.localeCompare(a.Location);
      else if (
        currentSortItem.sortKey === 'Sub Location' &&
        currentSortItem.sortOrder === 'asc'
      )
        return a.SubLocation.localeCompare(b.SubLocation);
      else if (
        currentSortItem.sortKey === 'Sub Location' &&
        currentSortItem.sortOrder === 'desc'
      )
        return b.SubLocation.localeCompare(a.SubLocation);
      else if (
        currentSortItem.sortKey === 'Section' &&
        currentSortItem.sortOrder === 'asc'
      )
        return a.SectionTitle.localeCompare(b.SectionTitle);
      else if (
        currentSortItem.sortKey === 'Section' &&
        currentSortItem.sortOrder === 'desc'
      )
        return b.SectionTitle.localeCompare(a.SectionTitle);
      else if (
        currentSortItem.sortKey === 'Question' &&
        currentSortItem.sortOrder === 'asc'
      )
        return a.QuestionName.localeCompare(b.QuestionName);
      else if (
        currentSortItem.sortKey === 'Question' &&
        currentSortItem.sortOrder === 'desc'
      )
        return b.QuestionName.localeCompare(a.QuestionName);
      else if (
        currentSortItem.sortKey === 'Url' &&
        currentSortItem.sortOrder === 'asc'
      )
        return a.Url.localeCompare(b.Url);
      else if (
        currentSortItem.sortKey === 'Url' &&
        currentSortItem.sortOrder === 'desc'
      )
        return b.Url.localeCompare(a.Url);
      else if (
        currentSortItem.sortKey === 'Status Code' &&
        currentSortItem.sortOrder === 'asc'
      )
        return `${a.StatusCode}`.localeCompare(`${b.StatusCode}`);
      else if (
        currentSortItem.sortKey === 'Status Code' &&
        currentSortItem.sortOrder === 'desc'
      )
        return `${b.StatusCode}`.localeCompare(`${a.StatusCode}`);
      else if (
        currentSortItem.sortKey === 'Status Description' &&
        currentSortItem.sortOrder === 'asc'
      )
        return a.StatusDescription.localeCompare(b.StatusDescription);
      else if (
        currentSortItem.sortKey === 'Status Description' &&
        currentSortItem.sortOrder === 'desc'
      )
        return b.StatusDescription.localeCompare(a.StatusDescription);
      return 0;
    });
    return sorted;
  }, [currentSortItem, statusFilteredData]);

  // if (data && data.recordsFiltered) {
  //   numberOfPages = Math.floor(data.recordsFiltered / pageLength);
  // }
  // const setPageNumber = useCallback(
  //   (newPage: number) => {
  //     if (newPage >= 0 && newPage <= numberOfPages) setPage(newPage);
  //   },
  //   [setPage, numberOfPages],
  // );

  const handleReset = (): void => {
    setLinkStatus('Bad Links');
    setSearchText('');
    setColumnsSearch({});
    setCurrentSortItem(sortItem);
  };

  const handleClickFilter = (filterValue: string): void => {
    setLinkStatus(filterValue);
    // queryCache.invalidateQueries('get_user_settings');
  };

  //   const tableData = useMemo(() => {
  //     if (data) {
  //       return data.map(dataItem => dataItem.data).flat();
  //     }
  //   }, [data]);

  //   const totalItems = useMemo(() => {
  //     if (data) {
  //       return data[0].recordsFiltered;
  //     }
  //     return 0;
  //   }, [data]);

  //   const onEndReached = () => {
  //     if (tableData) {
  //       if (canFetchMore && tableData?.length < totalItems) fetchMore();
  //     }
  //   };

  const pageAddons = () => (
    <div className="addons">
      <ButtonGroup className="button-group">
        {filterOptions.map(filterValue => (
          <Button
            key={filterValue.id}
            variant={filterValue.filter === linkStatus ? 'primary' : undefined}
            onClick={() => handleClickFilter(filterValue.filter)}
          >
            {filterValue.filter}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );

  return (
    <>
      <MainContent flexDirection="column">
        <PageHeader
          title={pageTitle}
          actions={pageActions}
          hasSearch
          searchValue={searchText}
          handleChange={setSearchText}
          addons={pageAddons()}
        />
        <PageContent>
          <Text mb={2} fontSize={1} color="text.body" sx={{ height: '20px' }}>
            {`Total ${sortedData?.length} links exists`}
          </Text>
          <Table
            columns={columns}
            data={sortedData}
            isLoading={isLoading || isFetching}
            headerUpdateCallBack={headerUpdateCallBack}
            handleSortChange={handleSortChange}
            sortedItem={currentSortItem}
            columnsSearch={columnsSearch}
            noOptions
          />
          {/* <Flex mt={4} justifyContent="flex-end">
            <Pagination
              page={page}
              numberOfPages={numberOfPages}
              onClick={setPageNumber}
            />
          </Flex> */}
        </PageContent>
      </MainContent>
    </>
  );
}
