import React, {
  ReactElement,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Table, SearchInput } from 'ui/patterns';
import { Button, Stack } from 'ui/components';
import * as Icon from 'assets/icons';
import { useCustomTable } from 'hooks';
import useCustomTableColumns from './useCustomTableColumns';
import CustomRecords from '../CustomRecords';
import AddCustomTable from '../AddCustomTable';
import { getFormatDate } from 'utils/dateHelper';
import { CustomTable } from 'types/customTables';

const sortItem = { sortKey: 'Table Name', sortOrder: 'asc' };

export default function CustomTables(): ReactElement {
  const history = useHistory<{ ID: number }>();
  const [showPanel, setShowPanel] = useState(false);
  const togglePanel = () => {
    if (editTable) setEditTable(undefined);
    setShowPanel(false);
  };
  const [showRecordsTable, setRecordsTable] = useState(false);
  const [selectedTable, setSelectedTable] = useState({});
  const [type, setType] = useState(''); // add custom table or provider table
  const [editTable, setEditTable] = useState<CustomTable>();
  const { accountId } = useParams<{
    accountId: string;
    tabId?: string;
  }>();
  const { data, isLoading, isFetching } = useCustomTable(parseInt(accountId));
  const [isProviderListavailable, setProviderListAvailable] = useState(false);
  useEffect(() => {
    const index = data?.findIndex(item => item.TableName === 'ProviderList');
    if (index !== -1) setProviderListAvailable(true);
    else setProviderListAvailable(false);
  }, [data]);

  const [currentSortItem, setCurrentSortItem] = useState(sortItem);
  const toggleRecordsGrid = () => {
    setSelectedTable({});
    setRecordsTable(false);
  };

  const columns = useCustomTableColumns();

  const handleRowClick = (row: any): void => {
    setSelectedTable(row.original);
    setRecordsTable(true);
  };
  const [columnsSearch, setColumnsSearch] = useState<Record<string, string>>(); // header search
  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch],
  );

  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (
        currentSortItem.sortKey === item &&
        currentSortItem.sortOrder === 'asc'
      ) {
        sortOrder = 'desc';
      }
      setCurrentSortItem({
        sortKey: item,
        sortOrder,
      });
    },
    [setCurrentSortItem, currentSortItem],
  );

  const [searchText, setSearchText] = useState<string>('');

  const filteredData = useMemo(() => {
    let filteredData = data ? [...data] : [];
    if (columnsSearch) {
      if (columnsSearch['Table Name']) {
        filteredData = filteredData.filter(item =>
          item.TableName.toLocaleLowerCase().includes(
            columnsSearch['Table Name'].toLocaleLowerCase(),
          ),
        );
      }
      if (columnsSearch['Last Updated']) {
        filteredData = filteredData.filter(item =>
          getFormatDate(item.LastUpdated).includes(
            columnsSearch['Last Updated'].toLocaleLowerCase(),
          ),
        );
      }
      if (columnsSearch['Last Updated By']) {
        filteredData = filteredData.filter(item =>
          item.LastUpdatedBy.toLocaleLowerCase().includes(
            columnsSearch['Last Updated By'].toLocaleLowerCase(),
          ),
        );
      }
    }
    if (searchText) {
      filteredData = filteredData.filter(
        item =>
          item.TableName.toLocaleLowerCase().includes(
            searchText.toLocaleLowerCase(),
          ) ||
          getFormatDate(item.LastUpdated).includes(
            searchText.toLocaleLowerCase(),
          ) ||
          item.LastUpdatedBy.toLocaleLowerCase().includes(
            searchText.toLocaleLowerCase(),
          ),
      );
    }
    return filteredData;
  }, [columnsSearch, data, searchText]);

  const sortedData = useMemo(() => {
    if (!filteredData) return filteredData;
    const sorted = [...filteredData];
    sorted.sort((a, b) => {
      if (
        currentSortItem.sortKey === 'Table Name' &&
        currentSortItem.sortOrder === 'asc'
      )
        return a.TableName.localeCompare(b.TableName);
      else if (
        currentSortItem.sortKey === 'Table Name' &&
        currentSortItem.sortOrder === 'desc'
      )
        return b.TableName.localeCompare(a.TableName);
      else if (
        currentSortItem.sortKey === 'Last Updated' &&
        currentSortItem.sortOrder === 'asc'
      )
        return a.LastUpdated.localeCompare(b.LastUpdated);
      else if (
        currentSortItem.sortKey === 'Last Updated' &&
        currentSortItem.sortOrder === 'desc'
      )
        return b.LastUpdated.localeCompare(a.LastUpdated);
      else if (
        currentSortItem.sortKey === 'Last Updated By' &&
        currentSortItem.sortOrder === 'asc'
      )
        return a.LastUpdatedBy.localeCompare(b.LastUpdatedBy);
      else if (
        currentSortItem.sortKey === 'Last Updated By' &&
        currentSortItem.sortOrder === 'desc'
      )
        return b.LastUpdatedBy.localeCompare(a.LastUpdatedBy);
      return 0;
    });
    return sorted;
  }, [currentSortItem, filteredData]);

  function reset() {
    setCurrentSortItem(sortItem);
    setColumnsSearch(undefined);
    setSearchText('');
  }

  const handleEditTable = (id: number) => {
    //
    const tableItem = sortedData.find(item => item.Id === id);
    if (tableItem && tableItem?.TableName !== 'ProviderList') {
      setEditTable(tableItem);
      setShowPanel(true);
      setType('Custom Table');
    }
    if (tableItem && tableItem?.TableName === 'ProviderList') {
      setEditTable(tableItem);
      setShowPanel(true);
      setType('Provider Table');
    }
  };

  return (
    <>
      <Flex
        flexDirection="column"
        sx={{ height: '100%' }}
        style={{ display: showRecordsTable ? 'none' : '' }}
      >
        <Flex justifyContent="space-between" alignItems="center" mb={2}>
          <Stack direction="x" gap={2} style={{ alignItems: 'center' }}>
            <Text fontSize={1} color="text.body" sx={{ height: '20px' }}>
              {`Total ${sortedData?.length} table exists`}
            </Text>
          </Stack>
          <Stack direction="x" gap={2}>
            <SearchInput
              handleChange={setSearchText}
              value={searchText}
              placeholder="Search"
            />
            <Button
              onClick={reset}
              iconBefore={<Icon.Refresh ariaHidden="true" />}
              variant="secondary"
            >
              Reset All
            </Button>
            <Button
              iconBefore={<Icon.Add ariaHidden="true" />}
              variant="primary"
              onClick={() => {
                setType('Provider Table');
                setShowPanel(true);
              }}
              disabled={isProviderListavailable}
            >
              Add New Provider Table
            </Button>
            <Button
              iconBefore={<Icon.Add title="" ariaHidden="true" />}
              variant="primary"
              onClick={() => {
                setType('Custom Table');
                setShowPanel(true);
              }}
            >
              Add New Custom Table
            </Button>
          </Stack>
        </Flex>
        <Table
          columns={columns}
          data={sortedData}
          isLoading={isLoading || isFetching}
          onRowClick={handleRowClick}
          columnsSearch={columnsSearch}
          headerUpdateCallBack={headerUpdateCallBack}
          handleSortChange={handleSortChange}
          sortedItem={currentSortItem}
          onEditPress={handleEditTable}
        />
      </Flex>
      {showRecordsTable ? (
        <CustomRecords
          selectedTable={selectedTable}
          toggleRecordsGrid={toggleRecordsGrid}
        />
      ) : null}

      <AddCustomTable
        showPanel={showPanel}
        togglePanel={togglePanel}
        type={type}
        accountId={parseInt(accountId)}
        tableItem={editTable}
      />
    </>
  );
}
