import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { getUserSettings } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function saveUserSettings({
  saveRequest,
}: TArguments): Promise<Response> {
  return fetch(getUserSettings, {
    method: 'PUT',
    headers: {
      Authorization: getTokenWithType(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'Accounts-Grid-Coloumns': JSON.stringify(saveRequest),
    }),
  });
}

export type TResult = {
  message: string;
  isSuccess: boolean;
};

export type TError = { message: string };
export type TArguments = {
  saveRequest: Array<string>;
};
export type TVariables = {
  saveRequest: Array<string>;
};

function useSaveUserSettings(
  onSuccess: () => void,
): MutationResultPair<void, TError, TVariables, unknown> {
  return useMutation(
    async ({ saveRequest }: { saveRequest: Array<string> }) => {
      await saveUserSettings({
        saveRequest,
      });
      // queryCache.invalidateQueries('get_user_settings', {
      //   refetchInactive: true,
      //   refetchActive: true,
      // });
      queryCache.invalidateQueries('get_user_settings');
    },
    {
      onSuccess: onSuccess,
    },
  );
}

export { useSaveUserSettings };
