import React, { ReactElement, SyntheticEvent } from 'react';
import { CSSObject } from 'styled-components';
import { SpaceProps, LayoutProps, FlexboxProps } from 'styled-system';
import { FormLabel, StyledFormFeedback } from 'ui/components';
import { ReactSelect } from '../reactSelect';
import { FormGroup } from '../index';

type ssProps = SpaceProps & LayoutProps & FlexboxProps;

type OptionItem = {
  id: number;
  name: string;
};

type SelectProps = ssProps & {
  name?: string;
  label: string;
  value?: number | string | any;
  onTextChange?: (text: string) => void;
  type?: 'email' | 'number' | 'password' | 'search' | 'text' | 'tel' | 'url';
  placeholder?: string | undefined;
  disabled?: boolean;
  validation?: 'error' | 'success' | 'warning';
  feedback?: string;
  required?: boolean;
  options?: Array<{ value: number | string; label: any }> | undefined;
  register?: ({ required }: { required?: boolean }) => RefReturn;
  onChange?: (e: SyntheticEvent<HTMLSelectElement>) => void;
  noSelectOption?: boolean;
  style?: CSSObject;
};

type item = string;

type RefReturn =
  | string
  | ((instance: HTMLSelectElement | null) => void)
  | React.RefObject<HTMLSelectElement>
  | null
  | undefined;

const FormReactSelect = ({
  name,
  label,
  value,
  onTextChange,
  placeholder,
  disabled,
  validation,
  feedback,
  required,
  options,
  register,
  onChange,
  noSelectOption,
  style,
  ...ssProps
}: SelectProps): ReactElement => {
  const id = `toggle-${label ? label.replace(/ /g, '').toLowerCase() : null}`;

  return (
    <FormGroup style={style} {...ssProps}>
      {label ? (
        <FormLabel htmlFor={id} required={required}>
          {label}
        </FormLabel>
      ) : null}
      <ReactSelect
        options={options}
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        isSearchable={false}
        classNamePrefix="pr-select"
      />
      {feedback ? (
        <StyledFormFeedback validation={validation}>
          {feedback}
        </StyledFormFeedback>
      ) : null}
    </FormGroup>
  );
};

export { FormReactSelect };
