import React, { ReactElement, SyntheticEvent } from 'react';
import { Flex } from 'rebass';
import { Tooltip } from 'react-tippy';

import { Button, RadioButton, Stack, Select, Separator } from 'ui/components';
import { FormInput } from 'ui/patterns';
import * as Icon from 'assets/icons';
import { SearchOptionsParams } from 'types/accountsSearch';
import { SearchRuleItem, ExpressionType } from 'types/accountsSearch';

export default function SearchBlocks({
  searchOptions,
  search,
  advancedSearchMain,
  handleSearchButtonDisable,
  resetSearchPage,
}: SearchOptionsParams): ReactElement {
  //setting value for expression drop down
  const checkExpression = (index: number) => {
    if (search.searchList[index].expression === 'Contains') return 1;
    else if (search.searchList[index].expression === 'Equals') return 2;
    else if (search.searchList[index].expression === 'NotContains') return 3;
    else return 0;
  };

  const onANDORButtonPressed =
    (index: number, operator: 'AND' | 'OR') => () => {
      search.setSearchList((searchList: any) => {
        return [...searchList].map((item, i) => ({
          ...item,
          operator: index === i ? operator : item.operator,
        }));
      });
    };

  const checkANDORSearchSelected = (index: number, operator: 'AND' | 'OR') => {
    return search.searchList[index].operator === operator;
  };

  //Expression (contains/equal/not contains)
  const handleExpressionChange =
    (index: number) => (e: SyntheticEvent<HTMLSelectElement>) => {
      const value = parseInt(e.currentTarget.value);
      search.setSearchList((list: Array<SearchRuleItem>) => {
        let selectedExpression: ExpressionType = 'Contains';
        if (value === 1) {
          selectedExpression = 'Contains';
        } else if (value === 2) {
          selectedExpression = 'Equals';
        } else if (value === 3) {
          selectedExpression = 'NotContains';
        }
        return list.map((item, i) => {
          if (index === i)
            return {
              ...item,
              expression: selectedExpression,
            };
          return {
            ...item,
          };
        });
      });
    };

  //search term
  const handleSearchInputChange = (index: number) => (e: string) => {
    search.setSearchList((list: any) => {
      return list.map((item: any, i: any) => {
        if (index === i)
          return {
            ...item,
            value: e,
          };
        return {
          ...item,
        };
      });
    });
  };

  //for removing new search block
  const handleRemoveSearchTermBlock = (index: number) => () => {
    search.setSearchList((list: any) => {
      return [...list.slice(0, index), ...list.slice(index + 1)];
    });
  };

  const searchItem: SearchRuleItem = {
    value: '',
    expression: 'Contains',
    operator: 'AND',
    regex: false,
    orderable: true,
  };

  const handleAddSearchTermBlock = () => {
    if (search.searchList.length === 2) {
      search.setSearchList((list: any) => [
        ...list,
        { ...searchItem, operator: 'AND' },
      ]);
    } else {
      search.setSearchList((list: any) => [...list, { ...searchItem }]);
    }
  };

  const searchTermBlocks = (item: any, index: any) => {
    return (
      <>
        <Stack direction="x" gap={2}>
          <Select
            name="advancedSearch"
            options={searchOptions}
            style={{ width: 'auto' }}
            value={checkExpression(index)}
            onChange={handleExpressionChange(index)}
          />
          <FormInput
            name={`searchkeyword-${index}`}
            label=""
            placeholder="Enter search term"
            style={{ flexGrow: 1 }}
            value={item.value}
            onTextChange={handleSearchInputChange(index)}
          />
          {index !== 0 ? (
            <Button
              title="Remove search field"
              onClick={handleRemoveSearchTermBlock(index)}
            >
              <Icon.X title="Remove search field" />
            </Button>
          ) : null}
        </Stack>
        {search.searchList.length - 1 > index ? (
          <Stack direction="x" gap={3}>
            <RadioButton
              name={`andOr-${index}`}
              label="AND"
              customId={`and-${index}`}
              onChange={onANDORButtonPressed(index, 'AND')}
              checked={checkANDORSearchSelected(index, 'AND')}
            />
            <RadioButton
              name={`andOr-${index}`}
              label="OR"
              customId={`or-${index}`}
              onChange={onANDORButtonPressed(index, 'OR')}
              checked={checkANDORSearchSelected(index, 'OR')}
            />
          </Stack>
        ) : null}
      </>
    );
  };

  const buttonGroup = () => {
    return (
      <Flex justifyContent="space-between" pt={3}>
        <Tooltip
          animation="fade"
          title="You can add only upto 3 search terms"
          position="bottom"
          arrow={true}
          arrowSize="small"
        >
          <Button
            variant="outline"
            onClick={handleAddSearchTermBlock}
            disabled={search.searchList.length >= 3 ? true : false}
          >
            Add new search term
          </Button>
        </Tooltip>
        <Stack direction="x" gap={2}>
          <Button variant="secondary" onClick={() => resetSearchPage()}>
            Reset
          </Button>
          <Button
            disabled={handleSearchButtonDisable()}
            variant="primary"
            onClick={() => advancedSearchMain()}
          >
            Search
          </Button>
        </Stack>
      </Flex>
    );
  };
  return (
    <Stack direction="y" gap={3} p={4}>
      {search.searchList.map((item: any, index: any) =>
        searchTermBlocks(item, index),
      )}
      <Separator direction="x" />
      {buttonGroup()}
    </Stack>
  );
}
