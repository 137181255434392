import React, { ReactElement, useEffect } from 'react';
import { Text, Flex } from 'rebass/styled-components';
import { Card, CardHeader, EmptyState, Loader } from 'ui/patterns';
import * as Icon from 'assets/icons';
import {
  Button,
  StickyListWrap,
  StickyList,
  StickyListTitle,
  StickyListItem,
} from 'ui/components';
import {
  SelectedAccounts,
  AccountProcedures,
  ProcedureInfoType,
} from 'types/procedureBuilder';
import NoDataImage from 'assets/images/NoDataImage';

type SelectedProcedureListType = {
  checkedAccounts: Array<SelectedAccounts>;
  setCheckedAccounts: (
    value:
      | Array<SelectedAccounts>
      | ((prevState: Array<SelectedAccounts>) => Array<SelectedAccounts>),
  ) => void;
  setProcedureListCount: (
    value: number | ((prevState: number) => number),
  ) => void;
  togglePreviewProcedure: (accountId: number, procedureId: number) => void;
  procedureDetails: ProcedureInfoType | undefined;
  isProcedureDetailsLoading: boolean;
  procedureHeader: boolean;
  procedureHeaderData: any;
};

export default function SelectedProcedureList({
  checkedAccounts,
  setCheckedAccounts,
  togglePreviewProcedure,
  procedureDetails,
  isProcedureDetailsLoading,
  setProcedureListCount,
  procedureHeaderData,
  procedureHeader,
}: SelectedProcedureListType): ReactElement {
  let procedureCount = 0;
  checkedAccounts.forEach(accounts =>
    accounts.accountProcedures?.forEach(procedure => {
      if (procedure.isChecked) {
        procedureCount += 1;
      }
    }),
  );
  useEffect(() => {
    setProcedureListCount(procedureCount);
  }, [procedureCount]);
  const createMarkup = () => {
    return { __html: procedureDetails?.Content || '' };
  };

  const removeProcedures = (
    selectedProcedure: AccountProcedures,
    selectedAccount: SelectedAccounts,
  ) => {
    const index =
      checkedAccounts && checkedAccounts.length > 0
        ? checkedAccounts?.findIndex(
            (account: { id: number }) => account.id === selectedAccount.id,
          )
        : -1;

    selectedAccount?.accountProcedures?.map(item => {
      if (item.ProcedureId === selectedProcedure.ProcedureId) {
        item.isChecked = !item.isChecked;
      }
    });
    let selectedProcedureCount = 0;
    selectedAccount?.accountProcedures?.forEach(procedure => {
      if (procedure.isChecked) {
        selectedProcedureCount += 1;
      }
    });

    checkedAccounts[index] = {
      id: selectedAccount.id,
      name: selectedAccount.name,
      accountProcedures: selectedAccount?.accountProcedures,
      accountNameAndNumber: selectedAccount?.accountNameAndNumber,
      isAccountChecked: selectedAccount?.accountProcedures?.every(
        item => item.isChecked === true,
      ),
      policiesSelected: selectedProcedureCount ? true : false,
    };
    if (checkedAccounts) setCheckedAccounts([...checkedAccounts]);
  };
  return (
    <Card
      flex="1"
      ml={3}
      position="relative"
      style={{ display: 'flex', flexDirection: 'column' }}
      // bg="var(--color-neutral-contrast-01)"
    >
      {procedureCount > 0 || procedureHeader === true ? (
        procedureCount === 1 || procedureHeader === true ? (
          <>
            <CardHeader p={3} bg="var(--color-neutral-contrast-02)">
              <Text
                as="h4"
                fontWeight="medium"
                lineHeight={1}
                color="text.body"
              >
                {procedureDetails?.AccountProcedure?.Procedure?.Name}
              </Text>
            </CardHeader>
            <Flex flex="1 0 auto" overflowY="auto" p={3} height="1px">
              <div dangerouslySetInnerHTML={createMarkup()} />
            </Flex>
            {isProcedureDetailsLoading && <Loader />}
          </>
        ) : (
          <>
            <CardHeader p={3} bg="var(--color-neutral-contrast-02)">
              <Text
                as="h4"
                fontWeight="medium"
                lineHeight={1}
                color="text.body"
              >
                {procedureCount} procedures selected
              </Text>
            </CardHeader>

            <StickyListWrap height="1px" overflowY="auto" flex="1 0 auto">
              {checkedAccounts?.map(
                (accounts, accountIndex) =>
                  accounts.policiesSelected && (
                    <StickyList key={accountIndex}>
                      <StickyListTitle borderTop="none" key={accountIndex}>
                        {accounts?.accountNameAndNumber}
                      </StickyListTitle>
                      {accounts?.accountProcedures?.map(
                        (procedures, procedureIndex) =>
                          procedures.isChecked ? (
                            <StickyListItem tabIndex={0} key={procedureIndex}>
                              {procedures?.Procedure?.Name}
                              <Button
                                onClick={() =>
                                  togglePreviewProcedure(
                                    procedures.AccountId,
                                    procedures.ProcedureId,
                                  )
                                }
                                variant="subtle"
                                size="sm"
                                title="Preview procedure"
                              >
                                <Icon.Document title="Preview procedure" />
                              </Button>
                              <Button
                                variant="subtle"
                                size="sm"
                                title="Remove procedure"
                                onClick={() =>
                                  removeProcedures(procedures, accounts)
                                }
                              >
                                <Icon.X title="Remove procedure" />
                              </Button>
                            </StickyListItem>
                          ) : null,
                      )}
                    </StickyList>
                  ),
              )}
            </StickyListWrap>
          </>
        )
      ) : (
        <EmptyState>
          <NoDataImage feedback="Please select a procedure to get started" />
        </EmptyState>
      )}
    </Card>
  );
}
