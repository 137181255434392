import React, { ReactElement } from 'react';
import { Box } from 'rebass';
import { Checkbox, Cluster } from 'ui/components';
import { DetailOptionsType } from 'types/accountsSearch';

export default function DetailSearchOptions({
  detailsOptions,
  toggleDetailOption,
}: {
  detailsOptions: DetailOptionsType;
  toggleDetailOption: any;
}): ReactElement {
  return (
    <Cluster gap={3} flex="1" p={2}>
      <Box>
        <Checkbox
          name="QuestionDetails"
          label="Question Details"
          checked={detailsOptions.questionDetails}
          handleChange={toggleDetailOption('questionDetails')}
        />
        <Checkbox
          name="includeHidden"
          label="Include Hidden Accounts"
          checked={detailsOptions.includeHidden}
          handleChange={toggleDetailOption('includeHidden')}
        />
        <Checkbox
          name="FieldLabel"
          label="Field Label"
          checked={detailsOptions.fieldLabel}
          handleChange={toggleDetailOption('fieldLabel')}
        />
      </Box>
    </Cluster>
  );
}
