import { ReactNode } from 'react';
import styled from 'styled-components';
import {
  LayoutProps,
  layout,
  FlexboxProps,
  flexbox,
  SpaceProps,
  space,
} from 'styled-system';

type GridProps = LayoutProps &
  FlexboxProps &
  SpaceProps & {
    children?: ReactNode;
    className?: string;
    gap: number;
    minColWidth?: string;
    maxContent?: boolean;
  };

const Grid = styled.div<GridProps>`
  ${layout}
  ${flexbox}
	${space}

	display: grid;
  grid-gap: ${({ gap, theme }) => theme.space[gap]};
  grid-template-columns: repeat(
    auto-fit,
    minmax(
      min(${({ minColWidth }) => minColWidth}, 100%),
      ${({ maxContent }) => (maxContent ? 'max-content' : '1fr')}
    )
  );
`;

export { Grid };
