import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { getFormDetails } from '../utils/urls';
import { Forms } from 'types/forms';
import { getTokenWithType } from './useAuth';

async function fetchFormDetails({ formId }: TVariables): Promise<TResult> {
  const response = await fetch(getFormDetails(formId), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

async function putFormDetails({
  formId,
  params,
}: TVariablesPut): Promise<TResult> {
  const response = await fetch(getFormDetails(formId), {
    method: 'PUT',
    headers: {
      Authorization: getTokenWithType(),
    },
    body: JSON.stringify(params),
  });
  return response.json();
}

export type TResult = Forms | undefined;

export type TError = { message: string };
export type TVariables = {
  formId: number;
};

export type TVariablesPut = {
  formId: number;
  params: Forms;
};
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;

function useUpdateFormDetails(): MutationResultPair<
  TResult,
  TError,
  number,
  TSnapshot
> {
  return useMutation(async (formId: number) => {
    if (formId) {
      const apiResult = await fetchFormDetails({
        formId,
      });
      if (apiResult) {
        const putResult = await putFormDetails({
          formId,
          params: {
            ...apiResult,
            SurveyStatusId:
              apiResult.SurveyStatus?.Description === 'Enabled' ? 1 : 2,
          },
        });
        if (putResult) queryCache.invalidateQueries(['forms_list']);
        return putResult;
      } else throw apiResult;
    }
  });
}

export { useUpdateFormDetails };
