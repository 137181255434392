import { useInfiniteQuery, InfiniteQueryResult, queryCache } from 'react-query';
import { getAccountsForImportFiles } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { Account } from 'types/accounts';
const pageLength = 20;

async function fetchAccountListForEmbedProcedure({
  keyword,
  start,
  pageLength,
  accountId,
}: TVariables): Promise<TResult> {
  const response = await fetch(
    getAccountsForImportFiles({
      accountId,
      start,
      pageLength,
      searchKey: keyword,
    }),
    {
      method: 'GET',
      headers: {
        Authorization: getTokenWithType(),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return response.json();
}

export type TResult = Array<Account>;

export type TError = { message: string };
export type TVariables = {
  accountId: number;
  start: number;
  pageLength: number;
  keyword: string;
};

function useGetAccountsListForImportFiles(
  accountId: number,
  keyword: string,
): InfiniteQueryResult<TResult | undefined, TError> {
  const data: Array<TResult> | undefined = queryCache.getQueryData([
    'get_accounts_list_for_import_files',
    accountId,
    keyword,
  ]);
  const start = data ? data.length * pageLength : 0;
  return useInfiniteQuery(
    ['get_accounts_list_for_import_files', accountId, keyword],
    () => {
      if (!accountId) return;
      return fetchAccountListForEmbedProcedure({
        accountId,
        keyword,
        start,
        pageLength,
      });
    },
    {
      getFetchMore: () => {
        return true;
      },
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetAccountsListForImportFiles };
