import React, {
  ReactElement,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { queryCache } from 'react-query';
import { Box, Flex, Text } from 'rebass/styled-components';
import { SpaceProps, LayoutProps } from 'styled-system';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { useHistory, useParams } from 'react-router-dom';
import { confirmAlert } from 'utils/confirm-alert';
import dayjs from 'dayjs';
import {
  MainContent,
  PageHeader,
  Card,
  CardHeader,
  ButtonGroup,
  Loader,
  EmptyState,
  Alert,
} from 'ui/patterns';
import * as Icon from 'assets/icons';
import { Button, Checkbox, Iframe, Stack, IframeWrapper } from 'ui/components';
import {
  ProcedureEditActions,
  LastEdited,
  ProcedureEditorWrapper,
  InfoText,
  VersionButton,
} from './styles';
import NoDataImage from 'assets/images/NoDataImage';
import { TinyMCE } from 'ui/components/tinyMCE/ProcedureEditor';
import { showSuccessToast, showErrorToast } from 'utils/showToast';
import { useDocumentTitle, useResetPolicyMenu } from 'hooks';
import { ProcedureListType } from 'types/procedureBuilder';
import ProceduresLists from './ProcedureList';
import SelectedProceduresList from './SelectedProceduresList';
import EmbeddedProcedure from './EmbeddedProcedure';
import ExistingLayout from './ExistingLayout';
import ExistingTable from './ExistingTable';
import FileImage from './FileImage';
import Hyperlink from './Hyperlink';
import SaveLayout from './SaveLayout';
import SaveTable from './SaveTable';
import utc from 'dayjs/plugin/utc';
import { apiBaseUrl } from 'utils/urls';
import { BlobFileEntity } from 'types/fileTypes';
import ImportProcedure from './ImportProcedure';
import LinkProcedure from './LinkProcedure';
import VersionHistory from './VersionHistory';
import FileBrowser from './FileBrowser';
import BrokenLinks from './BrokenLinks';
import usePrompt from 'hooks/usePrompt';
import useProcedureBuilder from './useProcedureBuilder';
import { lazyRun } from 'utils/useInterval';

dayjs.extend(utc);
type StyleProps = SpaceProps & LayoutProps;
type ProcedureBuilder = StyleProps;
export default function ProcedureBuilder({
  ...StyleProps
}: ProcedureBuilder): ReactElement {
  const history = useHistory<{ ID: number; Pid?: number }>();
  const params = useParams<{
    accountId: string;
    tabId?: string;
  }>();
  const accountId = parseInt(params.accountId);
  const pid = history?.location?.state?.Pid;

  const [mode, setMode] = useState<'Edit' | 'Preview'>('Edit');
  const [importProcedureVisible, setImportProcedureVisible] =
    useState<boolean>(false);

  const {
    brokenLinks,
    accountInfo,
    procedures,
    procedureDetails,
    selectedProcedures,
    isLoading,
    searchValue,
    renameProc,
    totalBrokenLinksCount,
    lastEditedPerson,
    lastEditedTime,
    procedureList,
    updateProcedureVisibility,
    setSelectedProcedures,
    getProcedureReference,
    discardProcedureContent,
    updateProcedureDetails,
    renameProcedure,
    setProcedures,
    setSearchValue,
    setRenameProc,
    saveContent,
    pageActions,
    selectProcedure,
    createProcedure,
    appliedFilterOption,
    setAppliedFilterOption,
    setUserTyped,
    editorChanges,
    setEditorChanges,
    userTyped,
    content,
    setContent,
    saveLayoutApi,
    saveTableApi,
    createFolder,
    uploadFile,
    deleteFile,
    showBrokenLinks,
    toggleBrokenLinks,
    autoSaveContent,
    autoPublishPolicyContent,
    isSaveProcedureLoading,
    cleanTemplate,
    forceRenameProcedure,
  } = useProcedureBuilder({
    accountId,
    pid,
    mode,
  });

  const [resetPolicyProcedure, { isLoading: isPolicyMenuResetting }] =
    useResetPolicyMenu();

  useEffect(() => {
    if (procedureDetails?.Content) {
      setContent(procedureDetails?.Content);
    } else {
      setContent('');
    }
  }, [procedureDetails?.Content, setContent]);

  const accountName = `${accountInfo?.accountInfoReturn?.Name}`;
  const accountIdentifier = `${'('} ${
    accountInfo?.accountInfoReturn?.CID
  } ${'-'} ${accountInfo?.accountInfoReturn?.SID} ${'-'} ${
    accountInfo?.accountInfoReturn?.PID
  } ${')'}`;

  // let subtitle = '';

  function toggleImportProcedureVisible() {
    setImportProcedureVisible(
      importProcedureVisible => !importProcedureVisible,
    );
  }

  const [linkProcedureVisible, setLinkProcedureVisible] =
    useState<boolean>(false);
  function toggleLinkProcedureVisible() {
    setLinkProcedureVisible(linkProcedureVisible => !linkProcedureVisible);
  }
  const titleName = () => {
    if (linkProcedureVisible) {
      return 'Link Procedure';
    }
    if (importProcedureVisible) {
      return 'Import Procedure';
    } else {
      return `${accountName} ${accountIdentifier}`;
    }
  };

  const subtitle = titleName();
  useDocumentTitle(subtitle, true);

  const [versionHistoryVisible, setVersionHistoryVisible] =
    useState<boolean>(false);
  function toggleVersionHistory() {
    setVersionHistoryVisible(versionHistoryVisible => !versionHistoryVisible);
    queryCache.invalidateQueries(
      [
        'get_procedure_version_history',
        accountId,
        selectedProcedures && selectedProcedures[0],
      ],
      {
        refetchInactive: true,
      },
    );
  }

  const [fileBrowserVisible, setFileBrowserVisible] = useState<boolean>(false);

  function toggleFileBrowser() {
    setFileBrowserVisible(fileBrowserVisible => !fileBrowserVisible);
  }

  // const [selectedWords, setSelectedWords] = useState<string>('');
  const [showEmbeddedProcedure, setShowEmbeddedProcedure] = useState(false);
  function toggleEmbeddedProcedure(
    params:
      | {
          account: string;
          procedureName: string;
        }
      | undefined,
  ) {
    setShowEmbeddedProcedure(!showEmbeddedProcedure);
    if (params) {
      const iframeContent = `<iframe style="width:100%;height: 300px;" src="${apiBaseUrl}procedures/V1/accountfolders/${params.account}/Policies/${params.procedureName}/isEditable=false" id="${params.procedureName}"></iframe>`;
      (window as any)?.tinymce?.activeEditor?.insertContent(iframeContent);
    }
  }

  const [showExistingLayout, setShowExistingLayout] = useState(false);
  function toggleExistingLayout() {
    setShowExistingLayout(!showExistingLayout);
  }

  const [showExistingTable, setShowExistingTable] = useState(false);
  function toggleExistingTable() {
    setShowExistingTable(!showExistingTable);
  }

  const [showFileImage, setShowFileImage] = useState(false);
  const [iframeLoader, setIframeLoader] = useState(false);
  function toggleFileImage() {
    setShowFileImage(showFileImage => !showFileImage);
  }

  const [showHyperlink, setShowHyperlink] = useState<string>('');
  function toggleHyperlink() {
    // getAllTags();
    const selectedWords = (
      window as any
    )?.tinymce?.activeEditor?.selection?.getContent({ format: 'text' });

    if (selectedWords.length) setShowHyperlink(selectedWords);
  }

  useEffect(() => {
    if (mode === 'Preview') setMode('Edit');
  }, [procedureDetails]);

  function getAllTags(): Array<{ Id: string; html: string }> {
    const tagNames: Array<{ Id: string; html: string }> = [];
    const content = (window as any)?.tinymce?.activeEditor?.getBody();
    if (content) {
      const links = content.querySelectorAll('[id^="hyper_link-"]');
      for (let i = 0; i < links.length; i++) {
        tagNames.push({
          Id: links[i].getAttribute('Id'),
          html: links[i].dataset.linktext,
        });
      }
    }
    return tagNames;
  }

  const [showSaveLayout, setShowSaveLayout] = useState<string | undefined>();
  function toggleSaveLayout(value?: string) {
    setShowSaveLayout(value);
  }
  const [showSaveTable, setShowSaveTable] = useState<string | undefined>();
  function toggleSaveTable(value?: string) {
    setShowSaveTable(value);
  }

  //enable-disable condition of table icon
  const [isTableSelected, setIsTableSelected] = useState<boolean>(false);

  const [random, setRandom] = useState(0);

  useEffect(() => {
    if (accountId === undefined) {
      history.push('/home');
    }
  }, [accountId, history]);

  function cancelAutosaveCallbacks() {
    lazyRun(() => {
      //
    }, 10);
  }

  const unsavedChanges = (() => {
    let newContent = '';
    if (
      editorChanges === true &&
      procedureDetails &&
      procedureDetails?.Content
    ) {
      if ((window as any)?.tinymce?.activeEditor?.initialized === true) {
        newContent = `<!DOCTYPE html><html><head><title></title><base href='/api/procedures/V1/accountfolders/${
          procedureDetails?.AccountProcedure?.AccountId
        }/Policies/'></head><body>${(
          window as any
        )?.tinymce?.activeEditor?.getContent()}</body></html>`;
      }
      if (newContent) {
        return newContent !== procedureDetails?.Content;
      }
    }
    return false;
  })();

  /*********RENAME PROCEDURE******************************************************************/
  //Do not show again checkbox//
  const handleRenameModalVisiblity = (e: any) => {
    if (e.target.checked === true) {
      localStorage.setItem('renameModalConfirmation', JSON.stringify(true));
    } else {
      localStorage.removeItem('renameModalConfirmation');
    }
  };
  //function to handle apis
  const confirmRenameProcedure = ({
    procedureIds,
    accountId,
    renamedProc,
    cb,
  }: {
    procedureIds: Array<number>;
    accountId: number;
    renamedProc: ProcedureListType;
    cb: (isSuccess: boolean) => void;
  }) => {
    getProcedureReference(
      {
        procedureIds: [...procedureIds],
        isRemove: false,
        accountId: accountId,
      },
      {
        onSuccess: (mutationResult: Array<string>) => {
          if (
            mutationResult &&
            mutationResult.length &&
            (mutationResult.length > 1 ||
              !mutationResult[0].includes('0000_POLICIESMENU.html'))
          ) {
            confirmAlert({
              message:
                'This procedure has been linked to other procedures. Are you sure you want to rename the procedure?',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    handleRenameProcedureApi(renamedProc, cb);
                  },
                  className: 'pr-btn-primary',
                },
                {
                  label: 'No',
                  onClick: () => {
                    handleCancelRenameAlert();
                    cb(false);
                  },
                },
              ],
              onKeypressEscape: () => {
                handleCancelRenameAlert();
                cb(false);
              },
              onClickOutside: () => {
                handleCancelRenameAlert();
                cb(false);
              },
            });
          } else {
            handleRenameProcedureApi(renamedProc, cb);
          }
        },
        onError: (error: { message: string }) => {
          cb(false);
          showErrorToast({
            message: error.message,
          });
        },
      },
    );
  };
  // rename procedure function and api
  const handleRenameProcedures = (
    newName: string,
    cb: (isSuccess: boolean) => void,
  ) => {
    let editedName = '';
    if (!newName.endsWith('.html')) {
      if (!renameProc?.Procedure.Name.endsWith('.htm')) {
        const name = newName.substring(newName.lastIndexOf('.') + 1);
        const extension = `${name}.html`;
        editedName = newName.replace(name, extension);
      } else {
        const name = newName.substring(newName.lastIndexOf('.') + 1);
        const extension = `${name}.html`;
        editedName = newName.replace(name, extension);
      }
    } else {
      editedName = newName;
    }
    if (!renameProc) return;
    const renamedProc: ProcedureListType = {
      ...renameProc,
      Procedure: {
        ...renameProc?.Procedure,
        Name: editedName.trim(),
      },
    };
    const tempValue = localStorage.getItem('renameModalConfirmation');
    const showConfirmation = tempValue === null ? false : JSON.parse(tempValue);
    const procIds: Array<number> = [];
    if (renamedProc) procIds.push(renamedProc.ProcedureId);
    if (!showConfirmation) {
      confirmAlert({
        dontShowAgain:
          renameProc?.Procedure?.Name === '0000_POLICIESMENU.html'
            ? false
            : true,
        handleModalVisibility: handleRenameModalVisiblity,
        message:
          renameProc?.Procedure?.Name === '0000_POLICIESMENU.html'
            ? 'You are about to rename the Policies Menu. This will automatically create a new Policies Menu using currently visible procedures. Any manual customizations will be lost. Do you want to proceed?'
            : 'This will change the name of the procedure document. Any links to the procedure will need to reflect this change. The Policies Menu will self-update as needed.',
        buttons: [
          {
            label:
              renameProc?.Procedure?.Name === '0000_POLICIESMENU.html'
                ? 'Yes'
                : 'Ok',
            onClick: () => {
              confirmRenameProcedure({
                procedureIds: [...procIds],
                accountId: accountId,
                renamedProc,
                cb,
              });
            },
            className: 'pr-btn-primary',
          },
          {
            label:
              renameProc?.Procedure?.Name === '0000_POLICIESMENU.html'
                ? 'No'
                : 'Cancel',
            onClick: () => {
              handleCancelRenameAlert();
              cb(false);
            },
          },
        ],
        onKeypressEscape: () => {
          handleCancelRenameAlert();
          cb(false);
        },
        onClickOutside: () => {
          handleCancelRenameAlert();
          cb(false);
        },
      });
    } else {
      confirmRenameProcedure({
        procedureIds: [...procIds],
        accountId: accountId,
        renamedProc,
        cb,
      });
    }
  };
  const [renameFailed, setRenameFailed] = useState(false);
  function handleRenameProcedureApi(
    renameProc: ProcedureListType,
    cb: (isSuccess: boolean) => void,
  ) {
    renameProcedure(
      {
        ...renameProc,
      },
      {
        onSuccess: (mutationResult: string) => {
          if (mutationResult && renameProc) {
            cb(true);
            setSearchValue('');
            setRenameProc(undefined);
          }
          showSuccessToast({
            message: 'Your changes were saved successfully.',
          });
        },
        onError: (error: { message: string; code: number }) => {
          queryCache.invalidateQueries(
            ['get_procedure_builder_procedures', renameProc?.AccountId],
            {
              refetchInactive: true,
            },
          );
          queryCache.invalidateQueries(
            ['get_procedure', renameProc?.AccountId, renameProc.ProcedureId],
            {
              refetchInactive: true,
            },
          );
          if (
            error.code === 409 &&
            error.message ===
              'The previous title displayed in the Policy Menu had been edited. Would you like to update it to match the new procedure file name?'
          ) {
            confirmAlert({
              message: `${error.message}`,
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    forceRenameProcedure(
                      {
                        ...renameProc,
                      },
                      {
                        onSuccess: (mutationResult: string) => {
                          if (mutationResult && renameProc) {
                            setSearchValue('');
                            setRenameProc(undefined);
                          }
                          showSuccessToast({
                            message: 'Your changes were saved successfully.',
                          });
                        },
                        onError: (error: { message: string }) => {
                          showErrorToast({
                            message: error.message,
                          });
                        },
                      },
                    );
                  },
                  className: 'pr-btn-primary',
                },
                {
                  label: 'No',
                  onClick: () => {
                    //
                  },
                },
              ],
            });
          } else if (
            error.code === 404 &&
            error.message === 'PoliciesMenu Not Found'
          ) {
            showSuccessToast({
              message: 'Your changes were saved successfully',
            });
          } else {
            setRenameFailed(true);
            queryCache.invalidateQueries(
              ['get_procedure', renameProc?.AccountId],
              {
                refetchInactive: true,
              },
            );

            showErrorToast({
              message: error.message,
            });
          }
        },
      },
    );
  }

  const handleCancelRenameAlert = () => {
    let proceduresList: Array<ProcedureListType> | undefined = [];
    let newProcedures: Array<ProcedureListType> | undefined = [];
    if (procedures) proceduresList = [...procedures];
    if (searchValue)
      newProcedures =
        proceduresList &&
        proceduresList.filter(item => {
          if (
            item.Procedure.Name.toLowerCase().includes(
              searchValue?.toLowerCase(),
            )
          )
            return item;

          return false;
        });
    else newProcedures = [...proceduresList];
    if (newProcedures) setProcedures([...newProcedures]);
  };
  /*********RENAME PROCEDURE******************************************************************/

  const handleDiscardProcedure = () => {
    const selected = procedureDetails?.AccountProcedure;
    const accountId = selected?.AccountId;
    const procedureId = selected?.ProcedureId;
    confirmAlert({
      message:
        'This will replace the procedure to its last published state. Do you want to continue?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            discardProcedureContent(
              {
                accountId: accountId,
                procedureId: procedureId,
              },
              {
                onSuccess: (mutationResult: any) => {
                  // setProcedureContent(mutationResult.Content);
                },
                onError: (error: { Message: string }) => {
                  showErrorToast({
                    message: error.Message,
                  });
                },
              },
            );
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  // const updateHistoryAction = (
  //   newRoute: string,
  //   params: { ID: number; Pid: number },
  // ) => {
  //   const content = (window as any)?.tinymce?.activeEditor?.getContent();
  //   let unsavedChanges = false;
  //   if (userTyped === true && procedureDetails && content)
  //     unsavedChanges = content !== procedureDetails?.Content;
  //   if (unsavedChanges) {
  //     cancelAutosaveCallbacks();
  //     confirmAlert({
  //       message: 'Unsaved changes are present do you want to save the changes?',
  //       buttons: [
  //         {
  //           label: 'Yes',
  //           onClick: () => {
  //             saveContent(() => {
  //               setUserTyped(false);
  //               history.push(newRoute, params);
  //             });
  //           },
  //           className: 'pr-btn-primary',
  //         },
  //         {
  //           label: 'No',
  //           onClick: () => {
  //             history.push(newRoute, params);
  //           },
  //         },
  //       ],
  //     });
  //   } else history.push(newRoute, params);
  // };

  function importProcedure() {
    toggleImportProcedureVisible();
  }

  function linkProcedure() {
    toggleLinkProcedureVisible();
  }

  function addProcedure() {
    let content = '';
    if ((window as any)?.tinymce?.activeEditor?.initialized === true) {
      content = `<!DOCTYPE html><html><head><title></title><base href='/api/procedures/V1/accountfolders/${
        procedureDetails?.AccountProcedure?.AccountId
      }/Policies/'></head><body>${(
        window as any
      )?.tinymce?.activeEditor?.getContent()}</body></html>`;
    }
    let unsavedChanges = false;
    if (userTyped === true && procedureDetails && content)
      unsavedChanges = content !== procedureDetails?.Content;
    if (!unsavedChanges) {
      createProcedure(accountId, {
        onSuccess: (result: any) => {
          if (result?.AccountProcedure?.Procedure) {
            // handleSelectProcedure(result?.AccountProcedure);
            setSelectedProcedures([result?.AccountProcedure.ProcedureId]);
            setRenameProc(result?.AccountProcedure);
          }
        },
      });
    } else {
      cancelAutosaveCallbacks();
      confirmAlert({
        message: 'Unsaved changes are present do you want to save the changes?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              saveContent(() => {
                setUserTyped(false);
                setEditorChanges(false);
                createProcedure(accountId, {
                  onSuccess: (result: any) => {
                    const unsaved = checkUnsavedContentExists();
                    if (unsaved)
                      procedures.forEach(item => {
                        if (
                          item.ProcedureId ===
                          procedureDetails?.AccountProcedure?.Procedure?.Id
                        ) {
                          if (
                            (!item.IsLinked || item.IsParentLinked) &&
                            !item.Procedure?.IsGlobal
                          ) {
                            item.IsPublished = true;
                          }
                        }
                        return item;
                      });
                    if (result?.AccountProcedure?.Procedure) {
                      setSelectedProcedures([
                        result?.AccountProcedure.ProcedureId,
                      ]);
                      setRenameProc(result?.AccountProcedure);
                    }
                  },
                });
              });
            },
            className: 'pr-btn-primary',
          },
          {
            label: 'No',
            onClick: () => {
              createProcedure(accountId, {
                onSuccess: (result: any) => {
                  if (result?.AccountProcedure?.Procedure) {
                    // handleSelectProcedure(result?.AccountProcedure);
                    setSelectedProcedures([
                      result?.AccountProcedure.ProcedureId,
                    ]);
                    setRenameProc(result?.AccountProcedure);
                  }
                },
              });
            },
          },
        ],
      });
    }
  }

  const handleCleanProcedureTemplate = () => {
    let newContent = '';
    if ((window as any)?.tinymce?.activeEditor?.initialized === true) {
      newContent = `<!DOCTYPE html><html><head><title></title><base href='/api/procedures/V1/accountfolders/${
        procedureDetails?.AccountProcedure?.AccountId
      }/Policies/'></head><body>${(
        window as any
      )?.tinymce?.activeEditor?.getContent()}</body></html>`;
    }
    cleanTemplate(
      {
        accountId: accountId,
        content: newContent,
        id: procedureDetails?.AccountProcedure?.ProcedureId,
      },
      {
        onSuccess: (response: string) => {
          setContent(response);
        },
      },
    );
  };
  const handleResetPolicyMenu = () => {
    confirmAlert({
      message:
        'Warning! This will rebuild the Policies Menu based on the titles of current visible procedures, using default formatting. Any manual customizations will be lost. Proceed?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            resetPolicyProcedure(
              {
                accountId: procedureDetails?.AccountProcedure?.AccountId,
                procedureId: procedureDetails?.AccountProcedure?.ProcedureId,
              },
              {
                onSuccess: (response: string) => {
                  setContent(response);
                  autoPublishPolicyContent(() =>
                    procedures.forEach(item => {
                      if (
                        item.ProcedureId ===
                        procedureDetails?.AccountProcedure?.ProcedureId
                      ) {
                        item.IsPublished = false;
                      }
                      return item;
                    }),
                  );
                },
              },
            );
          },

          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  const checkIsDiscardChanges = () => {
    const selectedProcedure = procedureDetails?.AccountProcedure;
    if (selectedProcedure?.Procedure.Versions.length === 0) {
      return false;
    } else {
      if (selectedProcedure) {
        if (
          new Date(selectedProcedure.Procedure.LastUpdatedOn) >
          new Date(
            selectedProcedure?.Procedure?.Versions[
              selectedProcedure?.Procedure?.Versions.length - 1
            ].CreatedOn,
          )
        ) {
          return true;
        }
      }
    }
  };
  usePrompt(
    unsavedChanges,
    'Are you sure you want to leave? Changes that you made may not be saved.',
  );

  const brokenLinksInProcedure = useMemo(() => {
    if (
      brokenLinks &&
      brokenLinks.length > 0 &&
      procedureDetails?.AccountProcedure?.ProcedureId
    ) {
      const brokenLinksArray: any = [];
      brokenLinks.forEach((item: any) => {
        if (item.Id === procedureDetails?.AccountProcedure?.ProcedureId) {
          item.ProcedureLinks.forEach((links: any): any => {
            brokenLinksArray.push(links.Link);
          });
        }
      });
      return brokenLinksArray;
    }
  }, [brokenLinks, procedureDetails?.AccountProcedure?.ProcedureId]);

  const checkUnsavedContentExists = () => {
    let content = '';
    if ((window as any)?.tinymce?.activeEditor?.initialized === true) {
      content = `<!DOCTYPE html><html><head><title></title><base href='/api/procedures/V1/accountfolders/${
        procedureDetails?.AccountProcedure?.AccountId
      }/Policies/'></head><body>${(
        window as any
      )?.tinymce?.activeEditor?.getContent()}</body></html>`;
    }
    if (content !== procedureDetails?.Content) return true;
    else return false;
  };
  const procedureIframeRef = useRef<HTMLIFrameElement>(null);

  return (
    <MainContent flexDirection="column" m={-4} {...StyleProps}>
      <PageHeader
        title="Procedures"
        actions={pageActions()}
        subTitle={subtitle}
        SubtitleTootip={subtitle}
        className="procedureBuilder-header"
      />
      <Flex flex="1 1 auto" p={3}>
        <ProceduresLists
          brokenLinks={brokenLinks}
          accountId={accountId}
          totalBrokenLinksCount={totalBrokenLinksCount}
          procedureList={[]}
          isProcedureLoading={isLoading}
          procedures={procedures}
          setProcedures={setProcedures}
          selectedProcedures={selectedProcedures}
          setSelectedProcedures={setSelectedProcedures}
          updateProcedureVisibility={updateProcedureVisibility}
          renameProc={renameProc}
          setRenameProc={setRenameProc}
          handleRenameProcedures={handleRenameProcedures}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          selectProcedure={selectProcedure}
          appliedFilterOption={appliedFilterOption}
          setAppliedFilterOption={setAppliedFilterOption}
          addProcedure={addProcedure}
          importProcedure={importProcedure}
          linkProcedure={linkProcedure}
          toggleFileBrowser={toggleFileBrowser}
          setRenameFailed={setRenameFailed}
          renameFailed={renameFailed}
          autoSaveContent={autoSaveContent}
          procedureDetails={procedureDetails}
          // unpublishedProcedure={unpublishedProcedure}
          // setUnpublishedProcedure={setUnpublishedProcedure}
          setUserTyped={setUserTyped}
          setEditorChanges={setEditorChanges}
          saveContent={saveContent}
          userTyped={userTyped}
        />
        {selectedProcedures && selectedProcedures.length === 1 && (
          <Card flex="1" ml={3} position="relative" procedureEditor>
            <CardHeader
              py={1}
              px={3}
              minHeight="38px"
              bg="var(--color-neutral-contrast-01)"
              alignItems="center"
              display="flex"
              justifyContent="space-between"
            >
              <Box minWidth="0" flex={2}>
                <Text
                  as="h4"
                  fontWeight="medium"
                  lineHeight={2}
                  color="text.body"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {procedureList &&
                    procedureList.find(
                      (item: any) => item.ProcedureId === selectedProcedures[0],
                    )?.Procedure.Name}
                </Text>
              </Box>
              <Flex mx={3} flex="1 0 auto" justifyContent="center">
                <ButtonGroup>
                  <Button
                    variant={mode === 'Edit' ? 'secondary' : undefined}
                    size="sm"
                    onClick={() => setMode('Edit')}
                  >
                    Edit
                  </Button>
                  <Button
                    size="sm"
                    variant={mode === 'Preview' ? 'secondary' : undefined}
                    onClick={() => {
                      setMode('Preview');
                      let content = '';
                      if (
                        (window as any)?.tinymce?.activeEditor?.initialized ===
                        true
                      ) {
                        content = `<!DOCTYPE html><html><head><title></title><base href='/api/procedures/V1/accountfolders/${
                          procedureDetails?.AccountProcedure?.AccountId
                        }/Policies/'></head><body>${(
                          window as any
                        )?.tinymce?.activeEditor?.getContent()}</body></html>`;
                      }
                      setContent(content);
                    }}
                    // onClick={() => setMode('View')}
                  >
                    Preview
                  </Button>
                </ButtonGroup>
              </Flex>

              <Flex flex={2.5} alignItems="center" justifyContent={'flex-end'}>
                {lastEditedPerson && lastEditedTime ? (
                  <Flex style={{ gap: '0.5rem' }}>
                    <LastEdited
                      onClick={() => {
                        toggleVersionHistory();
                      }}
                    >
                      <small>
                        Last edit was made on{' '}
                        <strong>{`${lastEditedTime.date} at ${lastEditedTime.time}`}</strong>{' '}
                        {lastEditedPerson === 'Auto Generated' ? '-' : 'by'}{' '}
                        <strong> {lastEditedPerson}</strong>
                      </small>
                    </LastEdited>
                    <VersionButton
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        toggleVersionHistory();
                      }}
                    >
                      Revision History
                    </VersionButton>
                  </Flex>
                ) : (
                  <Flex style={{ gap: '0.5rem' }}>
                    <InfoText>
                      <Icon.ExclamationCircle size={'1rem'} title="" />
                      <small>
                        Procedure has never been published and is not yet
                        available to users
                      </small>
                    </InfoText>
                    <VersionButton variant="outline" size="sm" disabled>
                      Revision History
                    </VersionButton>
                  </Flex>
                )}
              </Flex>
            </CardHeader>
            <Flex flex={1}>
              <ProcedureEditActions>
                <Tooltip
                  animation="fade"
                  title="Save"
                  position="right"
                  arrow={true}
                >
                  <button
                    onClick={() => {
                      cancelAutosaveCallbacks();
                      saveContent();
                    }}
                    disabled={mode === 'Edit' ? false : true}
                  >
                    <Icon.Save />
                  </button>
                </Tooltip>
                {procedureDetails?.AccountProcedure?.Procedure?.Name ===
                '0000_POLICIESMENU.html' ? null : (
                  <Tooltip
                    animation="fade"
                    title="Discard Changes"
                    position="right"
                    arrow={true}
                  >
                    <button
                      disabled={
                        checkIsDiscardChanges() && mode === 'Edit'
                          ? false
                          : true
                      }
                      onClick={handleDiscardProcedure}
                    >
                      <Icon.Backspace />
                    </button>
                  </Tooltip>
                )}
                <Tooltip
                  animation="fade"
                  title="Save Layout"
                  position="right"
                  arrow={true}
                >
                  <button
                    disabled={mode === 'Edit' ? false : true}
                    onClick={() => {
                      const htmlContentBackup = (
                        window as any
                      )?.tinymce?.activeEditor?.getContent();
                      const html = (
                        window as any
                      )?.tinymce?.activeEditor?.getBody();
                      // const updated: Array<string> = [];
                      const replaceText = (elem: Element) => {
                        for (const child of Array.from(elem.children)) {
                          if (child.children.length > 0) {
                            replaceText(child);
                          } else {
                            if (child.textContent) {
                              child.textContent = 'Enter your text here';
                            }
                          }
                        }
                      };
                      replaceText(html);
                      const innerHTML = html.innerHTML;
                      toggleSaveLayout(innerHTML);
                      (window as any)?.tinymce?.activeEditor?.setContent(
                        htmlContentBackup,
                      );
                      //
                    }}
                  >
                    <Icon.Layout />
                  </button>
                </Tooltip>
                <Tooltip
                  animation="fade"
                  title="Save Table"
                  position="right"
                  arrow={true}
                >
                  <button
                    disabled={mode === 'Edit' && isTableSelected ? false : true}
                    onClick={() => {
                      const htmlContentBackup = (
                        window as any
                      )?.tinymce?.activeEditor?.getContent();
                      const html = (
                        window as any
                      )?.tinymce?.activeEditor?.selection
                        ?.getNode()
                        ?.closest('table');
                      // // const updated: Array<string> = [];
                      // const replaceText = (elem: Element) => {
                      //   for (const child of Array.from(elem.children)) {
                      //     if (child.children.length > 0) {
                      //       replaceText(child);
                      //     } else {
                      //       if (child.textContent) {
                      //         child.textContent = 'Enter your text here';
                      //       }
                      //     }
                      //   }
                      // };
                      // replaceText(html);
                      // const innerHTML = html.innerHTML;
                      const htmlString = html?.outerHTML;
                      toggleSaveTable(htmlString);
                      (window as any)?.tinymce?.activeEditor?.setContent(
                        htmlContentBackup,
                      );
                    }}
                  >
                    <Icon.LgTable />
                  </button>
                </Tooltip>
                {/* print for both edit and preview */}
                <Tooltip
                  animation="fade"
                  title="Print"
                  position="right"
                  arrow={true}
                >
                  <button
                    onClick={() =>
                      (window as any)?.tinymce?.activeEditor?.execCommand(
                        'mcePrint',
                      )
                    }
                  >
                    <Icon.Print />
                  </button>
                </Tooltip>
                {procedureDetails?.AccountProcedure?.Procedure?.Name ===
                '0000_POLICIESMENU.html' ? (
                  <Tooltip
                    animation="fade"
                    title="Reset Policy Menu"
                    position="right"
                    arrow={true}
                  >
                    <button onClick={() => handleResetPolicyMenu()}>
                      <Icon.Refresh title="" />
                    </button>
                  </Tooltip>
                ) : null}
              </ProcedureEditActions>
              <ProcedureEditorWrapper>
                {mode === 'Edit' && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '18px',
                      right: '20px',
                      zIndex: 10,
                    }}
                  >
                    <Checkbox
                      disabled={
                        procedureDetails?.AccountProcedure?.Procedure?.Name ===
                        '0000_POLICIESMENU.html'
                          ? true
                          : false
                      }
                      checked={
                        procedureDetails?.AccountProcedure?.Procedure?.Name ===
                        '0000_POLICIESMENU.html'
                          ? true
                          : procedureDetails?.AccountProcedure?.Procedure
                              ?.IsAutoSave
                      }
                      label="Auto Save"
                      name="autosave"
                      handleChange={() => {
                        let content = '';
                        if (
                          (window as any)?.tinymce?.activeEditor
                            ?.initialized === true
                        ) {
                          content = `<!DOCTYPE html><html><head><title></title><base href='/api/procedures/V1/accountfolders/${
                            procedureDetails?.AccountProcedure?.AccountId
                          }/Policies/'></head><body>${(
                            window as any
                          )?.tinymce?.activeEditor?.getContent()}</body></html>`;
                        }
                        let unsavedChanges = false;
                        if (userTyped === true && procedureDetails && content)
                          unsavedChanges =
                            content !== procedureDetails?.Content;
                        if (unsavedChanges) {
                          cancelAutosaveCallbacks();
                          confirmAlert({
                            message:
                              'Unsaved changes are present do you want to save the changes?',
                            buttons: [
                              {
                                label: 'Yes',
                                onClick: () => {
                                  saveContent(() => {
                                    setUserTyped(false);
                                    // setContent(content);
                                    setEditorChanges(false);
                                    if (procedureDetails) {
                                      updateProcedureDetails({
                                        ...procedureDetails.AccountProcedure,
                                        Procedure: {
                                          ...procedureDetails.AccountProcedure
                                            .Procedure,
                                          IsAutoSave:
                                            !procedureDetails.AccountProcedure
                                              .Procedure.IsAutoSave,
                                        },
                                      });
                                    }
                                  });
                                },
                                className: 'pr-btn-primary',
                              },
                              {
                                label: 'No',
                                onClick: () => {
                                  if (procedureDetails) {
                                    updateProcedureDetails({
                                      ...procedureDetails.AccountProcedure,
                                      Procedure: {
                                        ...procedureDetails.AccountProcedure
                                          .Procedure,
                                        IsAutoSave:
                                          !procedureDetails.AccountProcedure
                                            .Procedure.IsAutoSave,
                                      },
                                    });
                                  }
                                },
                              },
                            ],
                          });
                        } else {
                          if (procedureDetails) {
                            updateProcedureDetails({
                              ...procedureDetails.AccountProcedure,
                              Procedure: {
                                ...procedureDetails.AccountProcedure.Procedure,
                                IsAutoSave:
                                  !procedureDetails.AccountProcedure.Procedure
                                    .IsAutoSave,
                              },
                            });
                          }
                        }
                      }}
                    />
                  </Box>
                )}

                {mode === 'Edit' && !isLoading && (
                  <TinyMCE
                    setEditorChanges={setEditorChanges}
                    height={695}
                    initialValue={procedureDetails?.Content}
                    // value={procedureDetails?.Content}
                    value={content}
                    brokenLinks={brokenLinksInProcedure}
                    name="ProcedureContent"
                    onChange={(value: string) => {
                      // setProcedureContent(value);
                    }}
                    onUserTyped={
                      procedureDetails?.AccountProcedure?.Procedure?.Name ===
                      '0000_POLICIESMENU.html'
                        ? () => {
                            setUserTyped(true);
                            lazyRun(() => {
                              if (
                                autoPublishPolicyContent &&
                                !isPolicyMenuResetting
                              )
                                autoPublishPolicyContent(() => {
                                  procedures.forEach(item => {
                                    if (
                                      item.ProcedureId ===
                                      procedureDetails?.AccountProcedure
                                        ?.ProcedureId
                                    ) {
                                      item.IsPublished = false;
                                    }
                                    return item;
                                  });
                                });
                            }, 7000);
                          }
                        : (undo?: boolean) => {
                            setUserTyped(true);
                            lazyRun(() => {
                              if (
                                autoSaveContent &&
                                procedureDetails?.AccountProcedure?.Procedure
                                  .IsAutoSave
                              )
                                autoSaveContent(() => {
                                  const unsaved = checkUnsavedContentExists();
                                  setUserTyped(false);
                                  setEditorChanges(false);
                                  if (unsaved)
                                    procedures.forEach(item => {
                                      if (
                                        item.ProcedureId ===
                                        procedureDetails?.AccountProcedure
                                          ?.ProcedureId
                                      ) {
                                        if (
                                          (!item.IsLinked ||
                                            item.IsParentLinked) &&
                                          !item.Procedure?.IsGlobal
                                        ) {
                                          item.IsPublished = true;
                                        }
                                      }
                                      return item;
                                    });
                                }, undo);
                            }, 7000);
                          }
                    }
                    onDetectTableSelection={() => {
                      const isTablePresent = (
                        window as any
                      )?.tinymce?.activeEditor?.selection
                        ?.getNode()
                        ?.closest('table');
                      if (isTablePresent) setIsTableSelected(true);
                      else setIsTableSelected(false);
                    }}
                    toggleEmbeddedProcedure={toggleEmbeddedProcedure}
                    toggleExistingLayout={toggleExistingLayout}
                    toggleExistingTable={toggleExistingTable}
                    toggleFileImage={toggleFileImage}
                    toggleHyperlink={toggleHyperlink}
                    procedureId={procedureDetails?.AccountProcedure?.Id}
                    handleCleanProcedureTemplate={handleCleanProcedureTemplate}
                  />
                )}

                {mode === 'Preview' && !isLoading && (
                  <Stack direction="y" gap={3} height="100%">
                    {procedureDetails?.AccountProcedure.Procedure.Name ===
                      '0000_POLICIESMENU.html' && (
                      <div style={{ width: '30%' }}>
                        <Button
                          variant="primary"
                          onClick={() => {
                            setRandom(random => random + 1);
                          }}
                          type="submit"
                          fullWidth={false}
                        >
                          Home
                        </Button>
                      </div>
                    )}

                    <IframeWrapper>
                      {!iframeLoader ? <Loader /> : null}

                      <Iframe
                        className={!iframeLoader ? 'hide' : 'narrow'}
                        ref={procedureIframeRef}
                        height="100%"
                        key={random}
                        onLoad={() => setIframeLoader(true)}
                        src={`${apiBaseUrl}procedures/V1/accountfolders/${procedureDetails?.AccountProcedure.AccountId}/Policies/${procedureDetails?.AccountProcedure.Procedure.Name}/isfromprocedurebuilder=true`}
                      />
                    </IframeWrapper>
                    <Flex style={{ gap: `0.5rem` }} justifyContent="flex-end">
                      <Alert
                        variant="info"
                        icon={<Icon.ExclamationCircle />}
                        style={{
                          padding: '0.25rem 0.5rem',
                          borderRadius: '3px',
                        }}
                      >
                        <Flex
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Text color="currentColor">
                            Please click the{' '}
                            <strong>procedure refresh button</strong> if the
                            procedure is not loaded properly.
                          </Text>
                        </Flex>
                      </Alert>
                      <Button
                        variant="primary"
                        onClick={() => {
                          setRandom(random => random + 1);
                        }}
                        type="submit"
                        fullWidth={false}
                      >
                        <Icon.Refresh title="" />
                      </Button>
                    </Flex>
                  </Stack>
                )}
              </ProcedureEditorWrapper>
            </Flex>
          </Card>
        )}
        {selectedProcedures && selectedProcedures.length > 1 && (
          <SelectedProceduresList
            selectedProcedures={selectedProcedures}
            setSelectedProcedures={setSelectedProcedures}
            procedureList={procedureList}
          />
        )}
        {/* {!isProcedureLoading && */}
        {selectedProcedures && selectedProcedures.length === 0 ? (
          <Card
            flex="1"
            ml={3}
            position="relative"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <EmptyState>
              <NoDataImage feedback="Please Select an existing Procedure or Create/Import a New Procedure in order to Manage it." />
            </EmptyState>
          </Card>
        ) : null}
      </Flex>
      {showEmbeddedProcedure && (
        <EmbeddedProcedure
          accountId={accountId}
          showPanel={showEmbeddedProcedure}
          togglePanel={toggleEmbeddedProcedure}
        />
      )}

      {showExistingLayout && (
        <ExistingLayout
          showPanel={showExistingLayout}
          togglePanel={toggleExistingLayout}
        />
      )}

      {showExistingTable && (
        <ExistingTable
          showPanel={showExistingTable}
          togglePanel={toggleExistingTable}
        />
      )}
      {showFileImage && (
        <FileImage
          account={`${accountInfo?.accountInfoReturn?.CID}${'-'}${
            accountInfo?.accountInfoReturn?.SID
          }${'-'}${accountInfo?.accountInfoReturn?.PID}`}
          showPanel={showFileImage}
          togglePanel={toggleFileImage}
          createFolder={createFolder}
          uploadFile={uploadFile}
          deleteFile={deleteFile}
          addFileToEditor={(file: BlobFileEntity) => {
            if (file.FileType !== 'image') {
              const hrefTag =
                "<a href='" +
                `${apiBaseUrl}/procedures/root/${file.FilePath}` +
                "/'>" +
                file.FileName +
                '</a>';
              (window as any)?.tinymce?.activeEditor?.insertContent(hrefTag);
            } else {
              const hrefTag = `<img src="${file.FileUri}" />`;
              (window as any)?.tinymce?.activeEditor?.insertContent(hrefTag);
            }
          }}
        />
      )}

      {!!showHyperlink && (
        <Hyperlink
          accountId={accountId}
          showPanel={!!showHyperlink}
          togglePanel={() => setShowHyperlink('')}
          selectedWords={showHyperlink}
          tagsList={getAllTags()}
        />
      )}

      {importProcedureVisible && (
        <ImportProcedure
          togglePanel={toggleImportProcedureVisible}
          showPanel={importProcedureVisible}
        />
      )}

      {linkProcedureVisible && (
        <LinkProcedure
          togglePanel={toggleLinkProcedureVisible}
          showPanel={linkProcedureVisible}
        />
      )}

      {versionHistoryVisible && selectedProcedures && selectedProcedures[0] ? (
        <VersionHistory
          togglePanel={toggleVersionHistory}
          showPanel={versionHistoryVisible}
          procedureId={selectedProcedures[0]}
        />
      ) : null}

      {fileBrowserVisible && (
        <FileBrowser
          togglePanel={toggleFileBrowser}
          showPanel={fileBrowserVisible}
          account={`${accountInfo?.accountInfoReturn?.CID}${'-'}${
            accountInfo?.accountInfoReturn?.SID
          }${'-'}${accountInfo?.accountInfoReturn?.PID}`}
        />
      )}

      {showSaveLayout && (
        <SaveLayout
          showPanel={!!showSaveLayout}
          togglePanel={() => toggleSaveLayout()}
          onSavePress={(title: string, saveOption: boolean) => {
            autoSaveContent(() => {
              const unsaved = checkUnsavedContentExists();
              setUserTyped(false);
              setEditorChanges(false);
              if (unsaved)
                procedures.forEach(item => {
                  if (
                    item.ProcedureId ===
                    procedureDetails?.AccountProcedure?.Procedure?.Id
                  ) {
                    if (
                      (!item.IsLinked || item.IsParentLinked) &&
                      !item.Procedure?.IsGlobal
                    ) {
                      item.IsPublished = true;
                    }
                  }
                  return item;
                });
            });
            const specialChars = /[\\/]/;
            if (!title || !title.trim() || specialChars.test(title)) {
              if (saveOption)
                showErrorToast({ message: 'Please enter a valid title' });
              else showErrorToast({ message: 'Please select a valid title' });
              return;
            }
            saveLayoutApi(
              {
                params: {
                  title: title.trim(),
                  content: showSaveLayout || '',
                  container: 'layouts',
                },
                saveOption: saveOption,
              },
              {
                onSuccess: () => {
                  toggleSaveLayout();
                  showSuccessToast({ message: 'Layout saved successfully' });
                },
              },
            );
          }}
        />
      )}

      {showSaveTable && (
        <SaveTable
          showPanel={!!showSaveTable}
          togglePanel={() => toggleSaveTable()}
          onSavePress={(title: string, saveOption: boolean) => {
            autoSaveContent(() => {
              const unsaved = checkUnsavedContentExists();
              setUserTyped(false);
              setEditorChanges(false);
              if (unsaved)
                procedures.forEach(item => {
                  if (
                    item.ProcedureId ===
                    procedureDetails?.AccountProcedure?.Procedure?.Id
                  ) {
                    if (
                      (!item.IsLinked || item.IsParentLinked) &&
                      !item.Procedure?.IsGlobal
                    ) {
                      item.IsPublished = true;
                    }
                  }
                  return item;
                });
            });
            const specialChars = /[\\/]/;
            if (!title || !title.trim() || specialChars.test(title)) {
              if (saveOption)
                showErrorToast({ message: 'Please enter a valid title' });
              else showErrorToast({ message: 'Please select a valid title' });
              return;
            }
            saveTableApi(
              {
                params: {
                  title: title.trim(),
                  content: showSaveTable || '',
                  container: 'tables',
                },
                saveOption: saveOption,
              },
              {
                onSuccess: () => {
                  toggleSaveTable();
                  showSuccessToast({ message: 'Table saved successfully' });
                },
              },
            );
          }}
        />
      )}

      {showBrokenLinks && (
        <BrokenLinks
          showPanel={showBrokenLinks}
          togglePanel={() => toggleBrokenLinks()}
          accountId={accountId}
          selectedProcedures={selectedProcedures}
          autoSaveContent={autoSaveContent}
        />
      )}
      {(isLoading || isPolicyMenuResetting || isSaveProcedureLoading) && (
        <Loader />
      )}
    </MainContent>
  );
}
