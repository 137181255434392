import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { cloneProc } from 'utils/urls';
import { getTokenWithType } from './useAuth';
import { ProcedureListType } from 'types/procedureBuilder';

export async function cloneProcedure({
  params,
}: {
  params: TVariables;
}): Promise<TResult> {
  const response = await fetch(cloneProc(params.accountId), {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(params.procedureFileIds),
  });
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = Array<ProcedureListType> & ValidationError;
export type TVariables = {
  procedureFileIds: Array<string>;
  accountId: number;
};
export type TError = {
  Message: string;
};
export type TSnapshot = unknown;

function useCloneProcedure(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: TVariables) => {
      const fetchResponse = await cloneProcedure({
        params: params,
      });
      if (fetchResponse.Message) throw fetchResponse;
      if (fetchResponse) {
        queryCache.invalidateQueries(['get_procedure_builder_procedures'], {
          refetchInactive: true,
        });
        queryCache.invalidateQueries(['get_procedure'], {
          refetchInactive: true,
        });
      }
      return fetchResponse;
    },
    {
      throwOnError: true,
    },
  );
}

export { useCloneProcedure };
