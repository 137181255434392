import React, { ReactElement, useMemo } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { Stack } from 'ui/components';
import { SectionTypeInput } from 'types/forms';
import SectionItem from './SectionItem';
import { SectionProps } from './sectionTypes';
import { QuestionTypeInput } from 'types/questions';

export default function SectionList({
  toggleQuestionPanel,
  toggleNewSection,
  sections,
  handleCurrentSection,
  handleDeleteSection,
  handleMoveSectionToTop,
  handleMoveSectionToBottom,
  handleMoveSectionUp,
  handleMoveSectionDown,
  handleCurrentQuestion,
  handleDeleteQuestion,
  toggleCopyQuestionPanel,
  handleSectionDragAndDrop,
  openCloseSection,
  expandedState,
  isCopyQnEnabled,
  handleSaveForm,
  isAdd,
}: // handleCopySection,
// setExpandAll,
// expandAll,
SectionProps): ReactElement {
  const dependentSections = useMemo(() => {
    if (sections && sections.length > 0) {
      const questions = sections.map(item => item.Questions).flat();

      const lookUpDependencies =
        questions &&
        questions
          .map(item => item?.QuestionTypeOptions?.Dependencies || [])
          .flat();

      const choiceDependencies =
        questions && questions.map(item => item?.QuestionOptions || []).flat();
      const dependentSectionsIds = [];
      const lookupIds = lookUpDependencies
        .map(item => item.DependentSectionIds)
        .flat();

      if (lookupIds) dependentSectionsIds.push(lookupIds);

      const choiceIds = choiceDependencies
        .map(item => item?.DependentSections)
        .flat()
        .map((sectionItem: any) => sectionItem.Id || [])
        .flat();

      if (choiceIds) dependentSectionsIds.push(choiceIds);

      return dependentSectionsIds.flat();
    }
  }, [sections]);

  const dependentQuestions = useMemo(() => {
    if (sections && sections.length > 0) {
      const questions = sections.map(item => item.Questions).flat();

      const lookUpDependencies =
        questions &&
        questions
          .map(item => item?.QuestionTypeOptions?.Dependencies || [])
          .flat();

      const choiceDependencies =
        questions && questions.map(item => item?.QuestionOptions || []).flat();
      const dependentQuestionIds = [];
      const lookupIds = lookUpDependencies
        .map(item => item?.DependentQuestionIds)
        .flat();
      if (lookupIds) dependentQuestionIds.push(lookupIds);

      const choiceIds = choiceDependencies
        .map(item => item?.DependentQuestions)
        .flat()
        .map((questionItem: any) => questionItem.Id || [])
        .flat();
      if (choiceIds) dependentQuestionIds.push(choiceIds);

      return dependentQuestionIds.flat();
    }
  }, [sections]);

  const questionsList = useMemo(() => {
    if (sections && sections.length > 0) {
      const questions = sections.map(item => item.Questions).flat();
      return questions;
    }
  }, [sections]);

  const dnQnObj: { [x: number]: Array<QuestionTypeInput> } = {};

  questionsList?.forEach(item => {
    const depQns: Array<QuestionTypeInput> = questionsList?.filter(qItem => {
      if (
        qItem?.QuestionTypeOptions?.$type ===
        'Proteus2.Api.Domain.Surveys.QuestionTypes.LookupQuestionTypeOptions, Proteus2.Api.Domain'
      ) {
        const dependencies = qItem?.QuestionTypeOptions?.Dependencies;
        const qnDependency = dependencies
          .map((dItem: any) => dItem?.DependentQuestionIds)
          .flat()
          .find((qnItem: any) => qnItem === item?.Id);

        if (qnDependency) return true;
      } else if (
        qItem?.QuestionTypeOptions?.$type ===
        'Proteus2.Api.Domain.Surveys.QuestionTypes.ChoiceQuestionTypeOptions, Proteus2.Api.Domain'
      ) {
        const dependencies = qItem?.QuestionOptions;
        const qnDependency = dependencies
          .map((dItem: any) => dItem?.DependentQuestions)
          .flat()
          .find((qnItem: any) => qnItem?.Id === item?.Id);

        if (qnDependency) return true;
      }
    });
    dnQnObj[item?.Id] = depQns;
  });

  const dnSecObj: any = {};

  sections?.forEach(item => {
    const depSections = questionsList?.filter(qItem => {
      if (
        qItem?.QuestionTypeOptions?.$type ===
        'Proteus2.Api.Domain.Surveys.QuestionTypes.LookupQuestionTypeOptions, Proteus2.Api.Domain'
      ) {
        const dependencies = qItem?.QuestionTypeOptions?.Dependencies;
        const qnDependency = dependencies
          .map((dItem: any) => dItem?.DependentSectionIds)
          .flat()
          .find((qnItem: any) => qnItem === item?.Id);

        if (qnDependency) return true;
      } else if (
        qItem?.QuestionTypeOptions?.$type ===
        'Proteus2.Api.Domain.Surveys.QuestionTypes.ChoiceQuestionTypeOptions, Proteus2.Api.Domain'
      ) {
        const dependencies = qItem?.QuestionOptions;

        const qnDependency = dependencies
          .map((dItem: any) => dItem?.DependentSections)
          .flat()
          .find((qnItem: any) => qnItem?.Id === item?.Id);

        if (qnDependency) return true;
      }
    });
    dnSecObj[item?.Id] = depSections;
  });

  return (
    <Flex p={4} flexDirection="column" flex="1 0 auto">
      <Text mb={2} fontSize={1} color="text.body" sx={{ height: '20px' }}>
        {sections && sections?.length > 0
          ? `Total ${sections?.length} Sections`
          : ''}
      </Text>
      <Stack
        direction="y"
        gap={3}
        style={{ height: '1px', overflow: 'auto', flexGrow: 1 }}
      >
        {/* Section */}
        {sections && sections.length > 0
          ? sections.map((sectionItem: SectionTypeInput, index: number) => (
              <SectionItem
                isAdd={isAdd}
                key={sectionItem.Id}
                sectionItem={sectionItem}
                index={index}
                toggleQuestionPanel={toggleQuestionPanel}
                toggleNewSection={toggleNewSection}
                handleCurrentSection={handleCurrentSection}
                handleDeleteSection={handleDeleteSection}
                handleMoveSectionToTop={handleMoveSectionToTop}
                handleMoveSectionToBottom={handleMoveSectionToBottom}
                handleMoveSectionUp={handleMoveSectionUp}
                handleMoveSectionDown={handleMoveSectionDown}
                handleCurrentQuestion={handleCurrentQuestion}
                handleDeleteQuestion={handleDeleteQuestion}
                handleSectionDragAndDrop={handleSectionDragAndDrop}
                dependentSections={dependentSections}
                dependentQuestions={dependentQuestions}
                dependentQnToolTip={dnQnObj}
                dependentSecToolTip={dnSecObj}
                toggleCopyQuestionPanel={toggleCopyQuestionPanel}
                // isExpanded={isSectionExpanded(sectionItem.Id)}
                // expandAll={expandAll}
                // setExpandAll={setExpandAll}
                openCloseSection={openCloseSection}
                expandedState={expandedState}
                // handleCopySection={handleCopySection}
                isCopyQnEnabled={isCopyQnEnabled}
                handleSaveForm={handleSaveForm}
              />
            ))
          : null}
      </Stack>
    </Flex>
  );
}
