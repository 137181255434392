export let isThisOnlyOne = true;

const bc = new BroadcastChannel('account-builder-revamp');

bc.onmessage = event => {
  if (event.data === `first?`) {
    bc.postMessage(`No`);
  }

  if (event.data === `No`) {
    isThisOnlyOne = false;
  }
};

bc.postMessage(`first?`);

export async function otherTabsAvailable() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(!isThisOnlyOne);
    }, 10);
  });
}
