import { getFileTree } from '../utils/urls';
import { useQuery, QueryResult } from 'react-query';
import { getTokenWithType } from './useAuth';
import { Directory } from 'types/fileTypes';

async function fetchFileTree({
  account,
}: TVariables): Promise<Array<Directory>> {
  const response = await fetch(getFileTree(account), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = Directory | undefined;

export type TError = { message: string };
export type TVariables = {
  account: string;
};

function useGetFileTree(account?: string): QueryResult<TResult, TError> {
  return useQuery(
    ['get_file_tree', account],
    async () => {
      if (account) {
        const files = await fetchFileTree({
          account,
        });
        return files && files[0] ? files[0] : undefined;
      }
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetFileTree };
