import React, { ReactElement, ReactNode } from 'react';
import { Flex } from 'rebass/styled-components';
import styled, { css } from 'styled-components';

const PageActionButton = styled.button`
  display: inline-flex;
  align-items: center;
  font-family: inherit;
  appearance: none;
  border: 0;
  background: transparent;
  color: ${({ theme }) => theme.baseColors.gray[2]};
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  cursor: pointer;
  transition: all 200ms ease-in-out;

  :hover {
    color: var(--color-on-primary);
    background: ${({ theme }) => theme.colors.primary};
  }

  :active,
  :active:focus {
    background: hsl(
      var(--primary-h),
      var(--primary-s),
      calc(var(--primary-l) - 10%)
    );
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
  }

  :focus {
    outline: none;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
    /* background: none; */
  }

  & .pr-icon {
    display: inline-flex;
    margin-right: ${({ theme }) => theme.space[2]};
    /* transform: translateY(1px); */
  }

  & .pr-page-action-label {
    display: inline-block;
    line-height: 1;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;

      &:hover {
        background: hsl(var(--h), var(--s), var(--l));
      }
    `}

  @media (max-width: 1345px) {
    .procedureBuilder-header & {
      font-size: 0.75rem;
    }
    .procedureBuilder-header & .pr-icon {
      display: none;
    }
  }
`;

type PageActionsProps = {
  actions: Array<item>;
};

type item = {
  action: string;
  icon?: ReactNode;
  handleButtonClick?: () => void;
  disabled?: boolean;
};

function PageActions({ actions }: PageActionsProps): ReactElement {
  return (
    <Flex className="pr-page-actions">
      {actions.map(action => (
        <PageActionButton
          key={action.action}
          onClick={action.handleButtonClick}
          disabled={action.disabled}
        >
          {action.icon ? action.icon : null}
          <span className="pr-page-action-label">{action.action}</span>
        </PageActionButton>
      ))}
    </Flex>
  );
}

export { PageActions };
