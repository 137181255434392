import {
  AUTH_TOKEN,
  IDENTITY_CONFIG,
  LOGIN_TYPE,
  METADATA_OIDC,
} from './authConstant';
import { UserManager, WebStorageStateStore, Log, User } from 'oidc-client';

export default class AuthService {
  UserManager: any;
  user: User | null = null;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    });

    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;
    this.UserManager.events.addUserLoaded((user: any) => {
      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen();
      }
    });
    this.UserManager.events.addSilentRenewError((e: { message: any }) => {
      console.log('silent renew error', e.message);
    });

    this.UserManager.events.addAccessTokenExpiring(() => {
      console.log('token expired', new Date().getTime());
      this.signinSilent();
    });
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then((user: User) => {
      this.user = user ?? null;
    });
  };

  async signIn() {
    await this.UserManager.clearStaleState();
    /*preserve the path when navigated from ab and also on refresh */
    let path = '';
    const pathname = window.location.pathname;
    if (window.location.search === '') path = pathname;
    else path = pathname.concat(window.location.search);
    localStorage.setItem('LastKnownLocation', path);
    const signinRequest = await this.UserManager.createSigninRequest();
    this.UserManager.signinRedirect({});
  }

  // getUser = async () => {
  //   // console.log("user", this.user);
  //   let user = await this.UserManager.getUser();
  //   // console.log("user", this.user);
  //   if (!user) {
  //     user = await this.UserManager.signinRedirectCallback();
  //   }
  //   this.user = user;
  //   // console.log("user", this.user);
  //   return user;
  // };

  getUser = async () => {
    const user = await this.UserManager.signinRedirectCallback();
    return user;
  };

  parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = () => {
    localStorage.setItem('redirectUri', window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  navigateToScreen = () => {
    window.location.replace('/home');
  };

  isAuthenticated = () => {
    const store = sessionStorage.getItem(
      `oidc.user:${process.env.NEXT_PUBLIC_AUTH_URL!}:${process.env
        .NEXT_PUBLIC_IDENTITY_CLIENT_ID!}`,
    );
    // console.log(
    //   "store",
    //   store,
    //   `oidc.user:${process.env.NEXT_PUBLIC_AUTH_URL!}:${process.env
    //     .NEXT_PUBLIC_IDENTITY_CLIENT_ID!}`,
    // );

    if (store) {
      // console.log("store", store);
      const oidcStorage = JSON.parse(store);
      // console.log("store", store);

      return !!oidcStorage && !!oidcStorage.access_token;
    }
    return false;
  };

  signinSilent = () => {
    // this.signinSilentCallback();
    this.UserManager.signinSilent()
      .then(async (user: any) => {
        const user1 = await AuthServiceSingleton.getInstance().getUser();
        this.user = user ?? null;
        // console.log("user1user1user1user1", user1);
      })
      .catch((err: any) => {
        console.log('signinSilent', err);
      });
  };
  signinSilentCallback = () => {
    // console.log("useruseruseruseruseruser::: ");
    this.UserManager.signinSilentCallback()
      .then(async (user: User | undefined) => {
        const user1 = await AuthServiceSingleton.getInstance().getUser();

        // localStorage.setItem(AUTH_TOKEN, JSON.stringify(user));

        if (user) this.user = user;
      })
      .catch((err: any) => {
        console.log('signinSilentCallback', err);
      });
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    });
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(LOGIN_TYPE);
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(process.env.NEXT_PUBLIC_PUBLIC_URL!);
    });
    this.UserManager.clearStaleState();
  };
}

export const AuthServiceSingleton = (function () {
  let instance: AuthService;

  function createInstance() {
    const object = new AuthService();
    return object;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();
