import styled from 'styled-components';

export const Improvements = styled.ul`
  list-style: none;
  margin-top: ${({ theme }) => theme.space[3]};
  margin-bottom: ${({ theme }) => theme.space[3]};
  padding-left: ${({ theme }) => theme.space[3]};

  & > * + * {
    margin-top: ${({ theme }) => theme.space[2]};
  }

  & > li {
    display: flex;
    color: ${({ theme }) => theme.colors.text.body};
  }

  & > li::before {
    content: '✔';
    display: inline-block;
    margin-right: 0.5rem;
  }
`;
