import styled from 'styled-components';
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
  typography,
  TypographyProps,
  BorderProps,
  border,
} from 'styled-system';

type ssProps = LayoutProps &
  SpaceProps &
  ColorProps &
  FlexboxProps &
  PositionProps &
  TypographyProps &
  BorderProps;

export const StickyListWrap = styled.div<ssProps>`
  ${color}
  ${space}
	${space}
	${layout}
	${flexbox}
	${position}
`;

export const StickyList = styled.dl<ssProps>`
  ${color}
  ${space}
	${space}
	${layout}
	${flexbox}
	${position}
  margin: 0;
`;

export const StickyListTitle = styled.dt<ssProps>`
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  background-color: var(--color-neutral-contrast-03);
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
  border-top: 1px solid ${({ theme }) => theme.colors.border.default};
  color: ${({ theme }) => theme.colors.text.body};
  line-height: 1;
  margin: 0;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 1;
  ${space}
  ${space}
	${layout}
	${flexbox}
	${position}
	${typography}
  ${color}
  ${border}
`;

export const StickyListItem = styled.dd<ssProps>`
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[5]};
  line-height: 1.2;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.body};
  border-bottom: solid 1px var(--color-neutral-contrast-02);
  ${color}
  ${space}
	${space}
	${layout}
	${flexbox}
	${position}

	&:hover {
    background: var(--color-neutral-contrast-02);
  }

  /* &:focus-within, */
  &:focus {
    background: var(--color-neutral-contrast-02);
    outline: solid 1px var(--color-neutral-contrast-05);
    outline-offset: -2px;
  }

  & > button {
    visibility: hidden;
    background: none;
    will-change: transform, opacity;
    transform: translate3d(-20%, 0, 0);
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    transition-property: opacity, transform;
    opacity: 0;

    &:hover {
      background: none;
    }
  }

  /* &:focus-within > button, */
  &:hover > button,
  &:focus > button {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);

    &:nth-of-type(1) {
      transition-delay: 0.025s;
      margin-left: ${({ theme }) => theme.space[2]};
    }

    &:nth-of-type(2) {
      transition-delay: calc(0.025s * 4);
    }
  }
`;

export default { StickyListWrap, StickyList, StickyListTitle, StickyListItem };
