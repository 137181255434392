import styled, { css } from 'styled-components';

type StyledFormFeedbackProps = {
  validation?: 'error' | 'success' | 'warning' | 'info' | 'default' | undefined;
  disabled?: boolean;
};

const StyledFormFeedback = styled.small<StyledFormFeedbackProps>`
  display: block;
  margin-top: ${({ theme }) => theme.space[1]};
  color: ${({ theme }) => theme.colors.text.muted};

  ${({ validation }) =>
    validation === 'error' &&
    css`
      color: ${({ theme }) => theme.colors.text.error};
    `}
  ${({ validation }) =>
    validation === 'success' &&
    css`
      color: ${({ theme }) => theme.colors.text.success};
    `}
  ${({ validation }) =>
    validation === 'warning' &&
    css`
      color: ${({ theme }) => theme.colors.text.warning};
    `}
    ${({ validation }) =>
    validation === 'info' &&
    css`
      color: ${({ theme }) => theme.colors.text.info};
    `}

     ${({ validation }) =>
    validation === 'default' &&
    css`
      color: ${({ theme }) => theme.colors.text.muted};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--color-neutral-contrast-04);
    `}
`;

export { StyledFormFeedback };
