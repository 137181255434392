import React from 'react';
import { Column, TableInstance, ColumnInstance } from 'react-table';
import { Location } from 'types/locations';
import Header from './tableHeader';
import LocationOptions from './tableLocationOptions';

function contains(array: Array<string>, string: string): boolean {
  const index = array.findIndex(item => item === string);
  return index !== -1;
}

export default function useLocationsColumns(
  columnsList?: Array<string>,
): Array<Column<Location>> {
  return React.useMemo((): Array<Column<Location>> => {
    if (columnsList) {
      const columns = [];
      if (contains(columnsList, 'Location Name'))
        columns.push(getLocationNameHeader(Header));
      if (contains(columnsList, 'Street'))
        columns.push(getStreetHeader(Header));
      if (contains(columnsList, 'City')) columns.push(getCityHeader(Header));
      if (contains(columnsList, 'State')) columns.push(getStateHeader(Header));
      if (contains(columnsList, 'Zip Code'))
        columns.push(getZipCodeHeader(Header));
      if (contains(columnsList, 'County'))
        columns.push(getCountyHeader(Header));
      if (contains(columnsList, 'Country'))
        columns.push(getCountryHeader(Header));
      if (contains(columnsList, 'Notes')) columns.push(getNotesHeader(Header));

      columns.push(getOptionsHeader());
      return columns;
    }
    return [
      getLocationNameHeader(Header),
      getStreetHeader(Header),
      getCityHeader(Header),
      getStateHeader(Header),
      getZipCodeHeader(Header),
      getCountyHeader(Header),
      getCountryHeader(Header),
      getNotesHeader(Header),
      getOptionsHeader(),
    ];
  }, [columnsList]);
}

function getLocationNameHeader(
  Header: (
    props: TableInstance<Location> & {
      column: ColumnInstance<Location>;
    },
  ) => JSX.Element,
): Column<Location> {
  return {
    Header: Header,
    accessor: 'Name',
    id: 'Location Name',
  };
}

function getStreetHeader(
  Header: (
    props: TableInstance<Location> & {
      column: ColumnInstance<Location>;
    },
  ) => JSX.Element,
): Column<Location> {
  return {
    Header: Header,
    accessor: 'Street',
    id: 'Street',
  };
}

function getCityHeader(
  Header: (
    props: TableInstance<Location> & {
      column: ColumnInstance<Location>;
    },
  ) => JSX.Element,
): Column<Location> {
  return {
    id: 'City',
    Header: Header,
    accessor: 'City',
  };
}

function getStateHeader(
  Header: (
    props: TableInstance<Location> & {
      column: ColumnInstance<Location>;
    },
  ) => JSX.Element,
): Column<Location> {
  return {
    id: 'State',
    Header: Header,
    accessor: (row: Location) => `${row.StateProvince?.Name || ''}`,
  };
}

function getZipCodeHeader(
  Header: (
    props: TableInstance<Location> & {
      column: ColumnInstance<Location>;
    },
  ) => JSX.Element,
): Column<Location> {
  return {
    Header: Header,
    accessor: 'PostalCode',
    id: 'Zip Code',
  };
}

function getCountyHeader(
  Header: (
    props: TableInstance<Location> & {
      column: ColumnInstance<Location>;
    },
  ) => JSX.Element,
): Column<Location> {
  return {
    Header: Header,
    accessor: 'County',
    id: 'County',
  };
}

function getCountryHeader(
  Header: (
    props: TableInstance<Location> & {
      column: ColumnInstance<Location>;
    },
  ) => JSX.Element,
): Column<Location> {
  return {
    Header: Header,
    accessor: 'Country',
    id: 'Country',
  };
}

function getNotesHeader(
  Header: (
    props: TableInstance<Location> & {
      column: ColumnInstance<Location>;
    },
  ) => JSX.Element,
): Column<Location> {
  return {
    Header: Header,
    accessor: 'Notes',
    id: 'Notes',
  };
}

function getOptionsHeader(): Column<Location> {
  return {
    id: 'Options',
    Header: 'Action',
    Cell: LocationOptions,
  };
}
