import React, { ReactElement } from 'react';
import { Stack } from 'ui/components';
import { Flex, Text } from 'rebass/styled-components';
import { SectionTypeInput } from 'types/forms';
import { Questions } from 'types/questions';
import QuestionItem from '../SectionList/QuestionItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import NoDataImage from 'assets/images/NoDataImage';
import * as Icon from 'assets/icons';
import { Alert } from 'ui/patterns';

export default function QuestionsPanel({
  selectedSectionItem,
  selectQuestionItem,
  selectedQuestionItem,
  allQuestionsInSection,
  isCopyQuestion,
}: {
  selectedSectionItem?: SectionTypeInput;
  selectQuestionItem?: (Q: Questions) => void;
  selectedQuestionItem?: Questions;
  allQuestionsInSection?: Array<Questions>;
  isCopyQuestion?: boolean;
}): ReactElement {
  const visibleSectionQuestions = selectedSectionItem?.Questions;
  const filteredQns = visibleSectionQuestions.filter((item: Questions) => {
    const isItemExists = allQuestionsInSection?.find(
      fItem => fItem.QuestionTemplateId === item?.QuestionTemplateId,
    );
    if (!!isItemExists) return false;
    return true;
  });
  return (
    <DragDropContext
      onDragEnd={() => {
        //
      }}
    >
      <Droppable droppableId="questionDropList">
        {provider => (
          <Stack
            direction="y"
            gap={0}
            ref={provider.innerRef}
            {...provider.droppableProps}
            style={{ flex: 1, overflow: 'auto' }}
          >
            <Alert variant="info" icon={<Icon.Info />}>
              Note: questions that already exist in the target section are not
              shown.
            </Alert>
            <div style={{ marginTop: '20px' }}>
              <Text fontSize={1} color="text.body">
                {filteredQns && filteredQns?.length > 0
                  ? `Total ${filteredQns?.length} Questions`
                  : ''}
              </Text>
            </div>
            {filteredQns && filteredQns.length > 0 ? (
              filteredQns.map((questionItem: any, index: number) => (
                <QuestionItem
                  isCopyQuestion={isCopyQuestion}
                  key={questionItem.Id}
                  index={index}
                  questionItem={questionItem}
                  sectionItem={selectedSectionItem}
                  dependentQuestions={undefined}
                  selectQuestionItem={selectQuestionItem}
                  isSelected={selectedQuestionItem?.Id === questionItem.Id}
                />
              ))
            ) : (
              <Flex height="500px" flex="1" justifyContent="center">
                <NoDataImage feedback="No data available" />
              </Flex>
            )}
            {provider.placeholder}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
}
