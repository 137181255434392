import { useEffect, useRef } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { UnregisterCallback } from 'history';

/**
 * Auxiliary hook. Reference for implementation:
 * - https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state;
 * - https://usehooks.com/usePrevious/;
 */

const useCurrentPath = (value: string): string | null => {
  const locationRef = useRef<string | null>(null);

  useEffect(() => {
    locationRef.current = value;
  }, [value]);
  return locationRef.current;
};

/**
 * This custom hook has two functions:
 *
 * 1. add an event listener to the beforeUnload event and
 * trigger the default browser prompt. References for implementation:
 * - https://developer.mozilla.org/pt-BR/docs/Web/API/Window/beforeunload_event
 * - https://dev.to/eons/detect-page-refresh-tab-close-and-route-change-with-react-router-v5-3pd
 *
 * 2. add shipping confirmation when a pre-determined condition is true. References
 * for implementation:
 *
 * - https://medium.com/@jozollo/blocking-navigation-with-react-router-v4-a3f2e359d096;
 * - https://github.com/ReactTraining/react-router/issues/5405;
 * - https://gist.github.com/sibelius/60a4e11da1f826b8d60dc3975a1ac805 (mentioned in the issue above);
 *
 * Important: The use of the hook was chosen because it was the best way to solve the problem
 * of changing the browser url even when the user cancels browsing. This problem is
 * quoted in the issue referred to above.
 *
 * @param { boolean } when - Trigger condition of prompt to prevent navigation
 * @param { string } message - Message fired to user when navigation occurs via React Router
 */

export default (
  when = false,
  message = 'The changes you made might not be saved.',
): void => {
  const history = useHistory();
  const { pathname } = useLocation();

  const previousLocation = useCurrentPath(pathname);

  const URLRef = useRef<UnregisterCallback | null>(null);

  const onUnload = (event: BeforeUnloadEvent): string => {
    // ensuring compatibility with older and mobile browsers
    const listener = event || window.event;

    listener.preventDefault();

    if (listener) {
      /* compatibility with Gecko (Firefox) browser engines,
      Trident(Internet Explorer) and Chrome versions 34+ */
      listener.returnValue = 'Are you sure you want to exit?';
    }
    /* backward compatibility with Gecko (Firefox) browser engines
    to 4, Webkit(iOS, Safari, Chrome browsers <= 27) and Chrome versions <34 */
    return '';
  };

  useEffect(() => {
    if (when) {
      URLRef.current = history.block((location, action) => {
        const isLocationChanged = previousLocation !== location.pathname;

        if (isLocationChanged) {
          const confirm = window.confirm(message);

          if (!confirm && action === 'POP') {
            // if navigation is canceled, recovering value from previous url
            // window.history.replaceState(null, '', `/# ${previousLocation} `);
            window.history.replaceState(null, '', ` ${previousLocation}`);
          }
          if (confirm) {
            // navigation allowe
            return undefined;
          }
        }
        return false;
      });
      window.addEventListener('beforeunload', onUnload);
    } else {
      URLRef.current = null;
    }

    return () => {
      if (URLRef.current) {
        /* canceling the subscription and stopping the rerouting block
        and history object listener.
        For this, it is necessary to call the function returned by history.block and history.listen.
        References:
        - https://github.com/ReactTraining/history/blob/v4/docs/Blocking.md;
        - https://github.com/ReactTraining/history/blob/v4/docs/GettingStarted.md#listening */
        URLRef.current();
        URLRef.current = null;
        window.removeEventListener('beforeunload', onUnload);
      }
    };
  }, [message, when, history, previousLocation]);
};
