import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { FTPSettings } from 'types/ftpSettings';
import { ftpSettings as ftpSettingsApi } from 'utils/urls';
import { getTokenWithType } from './useAuth';

export async function postFtpSettings({
  ftpSettings,
  accountId,
  privateKey,
  mode,
}: {
  ftpSettings: FTPSettings;
  accountId: number;
  privateKey?: File;
  mode?: string;
}): Promise<TResult> {
  const formData = new FormData();
  formData.append('ftpSettings', JSON.stringify(ftpSettings));
  if (privateKey) formData.append('privateKey', privateKey);
  const response = await fetch(ftpSettingsApi(accountId), {
    method: mode === 'edit' ? 'PUT' : 'POST',
    headers: {
      Authorization: getTokenWithType(),
    },
    body: formData,
  });
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = FTPSettings & ValidationError & Array<string>;
export type TVariables = {
  ftpSettings: FTPSettings;
  accountId: number;
  privateKey?: File;
  mode?: string;
};

export type TError = string;
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;
// async function wait(millis: number) {
//   return new Promise(resolve => {
//     setTimeout(resolve, millis);
//   });
// }

function useSaveFtpSettings(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async ({ ftpSettings, accountId, privateKey, mode }: TVariables) => {
      const newFtp = await postFtpSettings({
        ftpSettings,
        accountId,
        privateKey,
        mode,
      });
      if (newFtp.Message) throw newFtp.Message;
      else if (newFtp.length) throw newFtp[0];
      queryCache.invalidateQueries(['get_ftp_settings']);

      return newFtp;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveFtpSettings };
