import React, { ReactElement } from 'react';
import styled from 'styled-components';

type LogoProps = {
  src: string;
  route: string;
};

const StyledLogoWrapper = styled.div`
  margin-right: ${({ theme }) => theme.space[3]};
  padding-right: ${({ theme }) => theme.space[3]};
  border-right: solid 1px var(--color-neutral-contrast-03);
  display: flex;
  align-items: center;

  & a {
    display: grid;
    place-items: center;
    /* max-width: 120px; */
    max-height: 30px;

    & img {
      max-height: 100%;
    }

    & * {
      mix-blend-mode: multiply;
    }

    html[data-color-mode='dark'] & * {
      filter: invert(100%) hue-rotate(180deg);
      mix-blend-mode: screen;
    }
  }
`;

function Logo({ src, route }: LogoProps): ReactElement {
  return (
    <StyledLogoWrapper>
      <a href={route}>
        <img src={src} alt="logo" height="30" />
        {/* <img src={src} alt="logo" width="90" height="22" /> */}
      </a>
    </StyledLogoWrapper>
  );
}

export { Logo };
