import styled, { css } from 'styled-components';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

type SearchSnippetProps = LayoutProps &
  SpaceProps & {
    numberOfLines?: number;
  };

const SearchSnippet = styled.p<SearchSnippetProps>`
  --lh: 1.4rem;
  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
  line-height: var(--lh);
  background: var(--color-neutral-contrast-02);
  border: solid 1px var(--color-neutral-contrast-04);
  border-radius: ${({ theme }) => theme.radii.soft};
  color: var(--color-body-text);
  text-align: left;

  ${({ numberOfLines }) =>
    numberOfLines &&
    numberOfLines > 1 &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${numberOfLines};
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}

  ${({ numberOfLines }) =>
    numberOfLines === 1 &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${space}
	${layout}
`;

const SearchHighlight = styled.strong`
  display: inline-block;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.baseColors.yellow[4]};

  html[data-color-mode='dark'] & {
    color: black;
  }
`;

export { SearchSnippet, SearchHighlight };
