import { useMutation, MutationResultPair } from 'react-query';
import { saveTelephony } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { TwilioDetails } from 'types/countryType';

export async function saveTwilioInfo(params: TwilioDetails): Promise<TResult> {
  const response = await fetch(saveTelephony(params.AccountId), {
    method: 'PUT',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  if (response.status !== 200) {
    const errorText = await response.text();
    const errorCode = await response.status;
    throw { message: errorText, code: errorCode };
  }
  return response.json();
}

export type TResult = string;

export type TError = { message: string; code: number };
export type TArguments = {
  params: TwilioDetails;
};
export type TVariables = any;
export type TSnapshot = unknown;

function useSaveTwilioInfo(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: TwilioDetails) => {
      const renamedResult = await saveTwilioInfo(params);
      return renamedResult;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveTwilioInfo };
