import { useQuery, QueryResult } from 'react-query';
import { getUserPermissions } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchUserPermissions(): Promise<TResult> {
  const response = await fetch(getUserPermissions, {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = any;

export type TError = { message: string };

function useGetUserPermissions(): QueryResult<TResult, TError> {
  return useQuery(
    'get_user_permissions',
    async () => {
      const fetchResponse = await fetchUserPermissions();
      return fetchResponse;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetUserPermissions };
