import React, { ReactElement, useState, ReactNode } from 'react';
import { Flex } from 'rebass/styled-components';
import {
  LayoutProps,
  SpaceProps,
  FlexboxProps,
  PositionProps,
} from 'styled-system';
import { Dropdown } from 'ui/patterns';
import { Button, Checkbox } from 'ui/components';
import {
  StyledTree,
  TreeItem,
  TreeList,
  TreeListItem,
  FileActions,
  FileName,
} from './styles';
import * as Icon from 'assets/icons';
import { BlobFileEntity, Directory } from 'types/fileTypes';
import { apiBaseUrl } from 'utils/urls';
import { confirmAlert } from 'utils/confirm-alert';
import { showErrorToast } from 'utils/showToast';
const folderActionsTrigger = () => (
  <Button size="sm" variant="subtle">
    <Icon.DotsVertical
      title="Options"
      fill="var(--color-neutral-contrast-06)"
    />
  </Button>
);
type ssProps = LayoutProps & SpaceProps & FlexboxProps & PositionProps;

type TreeProps = ssProps & {
  files?: Directory;
  selectMode?: boolean;
  setSelectedFile?: (item: BlobFileEntity) => void;
  selectedFile?: BlobFileEntity;
  toggleAddFolder?: (folder?: BlobFileEntity) => void;
  toggleFileUpload?: (folder?: BlobFileEntity) => void;
  deleteFile?: (folder: BlobFileEntity) => void;
  toggleOpenImportFile?: (folder: BlobFileEntity) => void;
  selectedFiles?: Array<BlobFileEntity>;
  updatedSelectedFiles?: (files: Array<BlobFileEntity>) => void;
  account?: string;
  isHidden?: boolean;
};

type treeItem = {
  id?: string;
  fileName?: string;
  fileType?: string;
  subFiles?: any;
};

type dropdownOption = {
  id?: number;
  value: string;
  action?: () => void;
  icon?: ReactNode;
  groupEnd?: boolean;
};

function Tree({
  files,
  //   folderActions,
  setSelectedFile,
  selectedFile,
  toggleAddFolder,
  toggleFileUpload,
  deleteFile,
  toggleOpenImportFile,
  selectMode,
  selectedFiles,
  updatedSelectedFiles,
  account,
  isHidden,
  ...ssProps
}: TreeProps): ReactElement | null {
  const [rootOpened, setRootOpened] = useState(false);
  if (!files) return null;
  // if (isHidden) return null;
  return (
    <StyledTree hidden={isHidden} {...ssProps}>
      <TreeListItem>
        <TreeItem
          onClick={() => {
            setRootOpened(!rootOpened);
            setSelectedFile &&
              setSelectedFile({
                FileName: 'root',
                FileUri: '',
                FileType: 'folder',
                BlobFile: [],
                FilePath: '',
              });
          }}
          haveFiles={
            files?.BlobFile?.length && files?.BlobFile?.length > 0
              ? true
              : false
          }
          active={selectedFile?.FilePath === ''}
        >
          <Flex flex="1" alignItems="center">
            {files?.BlobFile?.length && files?.BlobFile?.length > 0 ? (
              <button className={`chevron ${rootOpened ? 'rotate' : ''}`}>
                <Icon.ChevronRight title="Close folder" />
              </button>
            ) : null}
            {rootOpened ? (
              <Icon.FolderOpened className="icon-folder" />
            ) : (
              <Icon.Folder className="icon-folder" />
            )}
            <FileName
              isFolder={true}
              className="truncate"
              title={files?.DirectoryName}
            >
              {files?.DirectoryName}
            </FileName>
          </Flex>
          {/* <Dropdown
            trigger={folderActionsTrigger()}
            menuList={[
              {
                value: 'Add Folder',
                icon: <Icon.FolderAdd title="Add folder" />,
                groupEnd: true,
                action: () => {
                  toggleAddFolder &&
                    toggleAddFolder({
                      FileName: '',
                      FileUri: '',
                      FileType: 'folder',
                      FilePath: '',
                    });
                },
              },
              {
                value: 'Upload Files',
                icon: <Icon.Upload title="Upload files" />,
                groupEnd: true,
                action: () => toggleFileUpload && toggleFileUpload(),
              },
              {
                value: 'Delete Folder',
                icon: <Icon.Delete title="Delete folder" />,
                action: () => {
                  deleteFile &&
                    deleteFile({
                      FileName: '',
                      FileUri: '',
                      FileType: 'folder',
                      FilePath: '',
                    });
                },
              },
            ]}
            position="left"
            className="dd-listitem-options"
          /> */}
        </TreeItem>
        {rootOpened && files?.BlobFile && (
          <Browse
            files={files?.BlobFile}
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
            toggleAddFolder={toggleAddFolder}
            toggleFileUpload={toggleFileUpload}
            deleteFile={deleteFile}
            importFilesMenu={toggleOpenImportFile ? true : false}
            toggleOpenImportFile={toggleOpenImportFile}
            selectMode={selectMode}
            selectedFiles={selectedFiles}
            updatedSelectedFiles={updatedSelectedFiles}
            account={account}
          />
        )}
      </TreeListItem>
    </StyledTree>
  );
}

function Browse({
  files,
  setSelectedFile,
  selectedFile,
  toggleAddFolder,
  toggleFileUpload,
  deleteFile,
  importFilesMenu,
  toggleOpenImportFile,
  selectMode,
  selectedFiles,
  updatedSelectedFiles,
  account,
}: {
  files: BlobFileEntity[];
  setSelectedFile?: (item: BlobFileEntity) => void;
  selectedFile?: BlobFileEntity;
  toggleAddFolder?: (folder?: BlobFileEntity) => void;
  toggleFileUpload?: (folder?: BlobFileEntity) => void;
  deleteFile?: (folder: BlobFileEntity) => void;
  importFilesMenu?: boolean;
  selectMode?: boolean;
  toggleOpenImportFile?: (folder: BlobFileEntity) => void;
  selectedFiles?: Array<BlobFileEntity>;
  updatedSelectedFiles?: (files: Array<BlobFileEntity>) => void;
  account?: string;
}) {
  return (
    <>
      <TreeList>
        <TreeListItem>
          {files.map(item => (
            <FileItem
              key={item.FilePath + item.FileName}
              item={item}
              setSelectedFile={setSelectedFile}
              selectedFile={selectedFile}
              toggleAddFolder={toggleAddFolder}
              toggleFileUpload={toggleFileUpload}
              deleteFile={deleteFile}
              importFilesMenu={importFilesMenu}
              toggleOpenImportFile={toggleOpenImportFile}
              selectMode={selectMode}
              selectedFiles={selectedFiles}
              updatedSelectedFiles={updatedSelectedFiles}
              account={account}
            />
          ))}
        </TreeListItem>
      </TreeList>
    </>
  );
}

function FileItem({
  item,
  setSelectedFile,
  selectedFile,
  toggleAddFolder,
  toggleFileUpload,
  deleteFile,
  importFilesMenu,
  toggleOpenImportFile,
  selectMode,
  selectedFiles,
  updatedSelectedFiles,
  account,
}: {
  item: BlobFileEntity;
  setSelectedFile?: (item: BlobFileEntity) => void;
  selectedFile?: BlobFileEntity;
  toggleAddFolder?: (folder?: BlobFileEntity) => void;
  toggleFileUpload?: (folder?: BlobFileEntity) => void;
  deleteFile?: (folder: BlobFileEntity) => void;
  importFilesMenu?: boolean;
  toggleOpenImportFile?: (folder: BlobFileEntity) => void;
  selectMode?: boolean;
  selectedFiles?: Array<BlobFileEntity>;
  updatedSelectedFiles?: (files: Array<BlobFileEntity>) => void;
  account?: string;
}) {
  const [isOpened, setOpened] = useState(false);
  function getMenuList(item: BlobFileEntity) {
    const addFolder = {
      value: 'Add Folder',
      icon: <Icon.FolderAdd title="Add folder" />,
      groupEnd: true,
      action: () => {
        toggleAddFolder &&
          toggleAddFolder({
            ...item,
          });
      },
    };
    const uploadFolder = {
      value: 'Upload Files',
      icon: <Icon.Upload title="Upload files" />,
      groupEnd: true,
      action: () => {
        toggleFileUpload &&
          toggleFileUpload({
            ...item,
          });
      },
    };
    const deleteFolder = {
      value: 'Delete Folder',
      icon: <Icon.Delete title="Delete folder" />,
      groupEnd: true,
      action: () => {
        if (item.BlobFile && item.BlobFile.length > 0) {
          showErrorToast({
            message:
              'Please try after deleting all sub- directories and files.',
          });
        } else {
          deleteFile &&
            deleteFile({
              ...item,
            });
        }
      },
    };
    const importFiles = {
      value: 'Import Files',
      icon: <Icon.Import title="Options" />,
      groupEnd: true,
      action: () => {
        toggleOpenImportFile &&
          toggleOpenImportFile({
            ...item,
          });
      },
    };
    let menus = [];
    if (importFilesMenu) menus = [addFolder, importFiles, uploadFolder];
    else menus = [addFolder, uploadFolder];
    const folders = item.FilePath.split('/');
    // console.log('account', folders, item.FilePath, account);
    if (account && folders.length > 2 && folders.indexOf(account) === 0) {
      menus.push(deleteFolder);
    }
    return menus;
  }
  const isSelected = selectedFiles?.find(i => i.FilePath === item.FilePath);
  function getAllFiles(folder: BlobFileEntity): Array<BlobFileEntity> {
    const fileList: Array<BlobFileEntity> = [];
    function recursiveToAccumulate(f: BlobFileEntity) {
      if (f && f.FileType === 'folder' && f.BlobFile) {
        f.BlobFile.forEach(blob => {
          recursiveToAccumulate(blob);
        });
      }
      fileList.push(f);
    }
    recursiveToAccumulate(folder);
    return fileList.reverse();
  }
  if (selectMode === true && item.FilePath === 'SharedFiles/') return null;
  return (
    <>
      <TreeItem
        onClick={() => {
          if (item?.FileType === 'folder') setOpened(!isOpened);
          // else
          setSelectedFile && setSelectedFile(item);
        }}
        haveFiles={
          item?.FileType !== 'folder' ||
          (item?.BlobFile?.length && item?.BlobFile?.length > 0)
            ? true
            : false
        }
        active={selectedFile?.FilePath === item?.FilePath}
      >
        <Flex flex="1" alignItems="center">
          {item?.BlobFile?.length && item?.BlobFile?.length > 0 ? (
            <button className={`chevron ${isOpened ? 'rotate' : ''}`}>
              <Icon.ChevronRight title="Close folder" />
            </button>
          ) : null}
          <FileIcon fileType={item.FileType} isOpened={isOpened} />
          {selectMode === true ? (
            <Checkbox
              handleChange={() => {
                if (updatedSelectedFiles) {
                  let files: Array<BlobFileEntity> = [];
                  if (item.FileType === 'folder') {
                    files = item.BlobFile
                      ? getAllFiles(item) //item.BlobFile?.map(blob => ({ ...blob }))
                      : [];
                  } else {
                    files = [{ ...item }];
                  }
                  updatedSelectedFiles([...files]);
                }
              }}
              checked={!!isSelected}
              label={item?.FileName}
              name={item.FileType + item?.FilePath}
            />
          ) : (
            <FileName
              isFolder={item?.FileType === 'folder'}
              className="truncate"
              title={item.FileName}
            >
              {item.FileName}
            </FileName>
          )}
        </Flex>
        {!selectMode ? (
          <>
            {item?.FileType === 'folder' ? (
              <Dropdown
                trigger={folderActionsTrigger()}
                menuList={getMenuList(item)}
                position="left"
                className="dd-listitem-options"
              />
            ) : (
              <FileActions>
                {importFilesMenu && (
                  <button
                    onClick={() => {
                      window.open(
                        `${apiBaseUrl}${'procedure-builder/procedures/download-blob-files?filePath='}${
                          item.FilePath
                        }`,
                        '_blank',
                      );
                    }}
                  >
                    <Icon.Download />
                  </button>
                )}
                {account && item.FilePath.split('/').indexOf(account) === 0 ? (
                  <button
                    onClick={() => {
                      confirmAlert({
                        message: 'Do you want to delete the file?',
                        buttons: [
                          {
                            label: 'Yes',
                            onClick: () => {
                              deleteFile &&
                                deleteFile({
                                  ...item,
                                });
                            },
                            className: 'pr-btn-primary',
                          },
                          {
                            label: 'No',
                            onClick: () => {
                              //
                            },
                          },
                        ],
                      });
                    }}
                  >
                    <Icon.Delete />
                  </button>
                ) : null}
              </FileActions>
            )}
          </>
        ) : null}
      </TreeItem>
      {isOpened && item?.BlobFile && (
        <Browse
          files={item?.BlobFile}
          setSelectedFile={setSelectedFile}
          selectedFile={selectedFile}
          toggleAddFolder={toggleAddFolder}
          toggleFileUpload={toggleFileUpload}
          deleteFile={deleteFile}
          importFilesMenu={importFilesMenu}
          toggleOpenImportFile={toggleOpenImportFile}
          selectMode={selectMode}
          selectedFiles={selectedFiles}
          updatedSelectedFiles={updatedSelectedFiles}
          account={account}
        />
      )}
    </>
  );
}

function FileIcon({
  fileType,
  isOpened,
}: {
  fileType: string;
  isOpened: boolean;
}) {
  if (fileType === 'image') {
    return <Icon.Image className="icon-file" />;
  } else if (fileType === 'html') {
    return <Icon.Code className="icon-file" />;
  } else if (fileType === 'pdf') {
    return <Icon.Document className="icon-file" />;
  } else if (fileType === 'folder') {
    return (
      <>
        {isOpened ? (
          <Icon.FolderOpened className="icon-folder" />
        ) : (
          <Icon.Folder className="icon-folder" />
        )}
      </>
    );
  }
  return <Icon.Document className="filefolder" />;
}

export { Tree };
