import { useQuery, QueryResult } from 'react-query';
import {
  getQuestionSpecifications,
  getQuestionSpecificationsForLookup,
} from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { QuestionSpecificationType } from 'types/questions';

async function fetchQuestionSpecifications({
  questionTypeName,
}: TVariables): Promise<TResult> {
  const response = await fetch(
    questionTypeName === 'lookup'
      ? getQuestionSpecificationsForLookup(questionTypeName)
      : getQuestionSpecifications(questionTypeName),
    {
      method: 'GET',
      headers: {
        Authorization: getTokenWithType(),
      },
    },
  );
  return response.json();
}

export type TResult = QuestionSpecificationType | undefined;

export type TError = { message: string };
export type TVariables = {
  questionTypeName: string | undefined;
};

function useGetQuestionSpecifications(
  questionTypeName: string | undefined,
): QueryResult<TResult, TError> {
  return useQuery(
    ['get_question_spec', questionTypeName],
    async () => {
      if (questionTypeName)
        return await fetchQuestionSpecifications({
          questionTypeName,
        });
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetQuestionSpecifications };
