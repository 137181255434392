import React, { ReactElement } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import WelcomePage from 'containers/WelcomePage';
import AccountsList from 'containers/AccountsList';
import Question from 'containers/Questions';
import About from 'containers/About';
import AddEditAccount from 'containers/AddEditAccount';
import FormsList from 'containers/FormsList';
import AddEditForm from 'containers/AddEditForm';
import CheckLinks from 'containers/AddEditForm/CheckLinks';
import AdvancedSearch from 'containers/AccountsList/AdvancedSearch';
import Unauthorized from 'containers/Unauthorized';
import NotFound from 'containers/NotFound';
import CustomRoute from './authRoutes/customRoute';
import { getVersionIframe } from 'utils/urls';

const HomeRouter = (): ReactElement => {
  let permissions = [];
  const permissionSet = localStorage.getItem('userPermissions');
  if (permissionSet) {
    permissions = JSON.parse(permissionSet);
  }

  return (
    <>
      <iframe src={getVersionIframe} style={{ display: 'none' }} />
      <Switch>
        {/* <CustomRoute
          path="/"
          component={WelcomePage}
          exact
          permission={permissions?.CanAccessAccountBuilder}
        /> */}

        <Route exact path="/">
          {permissions?.CanAccessAccountBuilder ? (
            <Redirect to="/home" />
          ) : (
            <Redirect to="/home/unauthorized" />
          )}
        </Route>
        <CustomRoute
          path="/home"
          component={WelcomePage}
          exact
          permission={permissions?.CanAccessAccountBuilder}
        />
        <CustomRoute
          exact
          path="/home/accounts"
          component={AccountsList}
          permission={permissions?.CanAccessAccountBuilder}
        />
        <CustomRoute
          exact
          path="/home/accounts/advanced"
          component={AccountsList}
          permission={permissions?.CanAccessAccountBuilder}
        />
        <CustomRoute
          path="/home/questions"
          component={Question}
          permission={
            permissions?.CanEditQuestionRepository &&
            permissions?.CanAccessAccountBuilder
          }
        />
        <CustomRoute
          path="/home/about"
          component={About}
          permission={permissions?.CanAccessAccountBuilder}
        />
        <CustomRoute
          path="/home/accounts/add"
          component={AddEditAccount}
          permission={permissions?.CanAccessAccountBuilder}
        />
        <CustomRoute
          path="/home/accounts/edit/:accountId/:tabId"
          component={AddEditAccount}
          permission={permissions?.CanAccessAccountBuilder}
        />
        <CustomRoute
          exact
          path="/home/forms"
          component={FormsList}
          permission={permissions?.CanAccessAccountBuilder}
        />
        <CustomRoute
          path="/home/forms/add"
          component={AddEditForm}
          permission={permissions?.CanAccessAccountBuilder}
        />
        <CustomRoute
          path="/home/forms/edit"
          component={AddEditForm}
          permission={permissions?.CanAccessAccountBuilder}
        />
        <CustomRoute
          path="/home/forms/check-links"
          component={CheckLinks}
          permission={permissions?.CanAccessAccountBuilder}
        />
        <CustomRoute
          path="*"
          component={NotFound}
          permission={permissions?.CanAccessAccountBuilder}
        />
      </Switch>
      <Switch>
        <CustomRoute
          exact
          path="/home/accounts/advanced"
          component={AdvancedSearch}
          permission={permissions?.CanAccessAccountBuilder}
        />
        <Route exact path="/home/unauthorized">
          <Unauthorized />
        </Route>
      </Switch>
    </>
  );
};

export default HomeRouter;
