/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  AddEditQuestionType,
  QuestionTemplateOptionsType,
  QuestionSpecificationType,
  QuestionTypeOptionsType,
} from 'types/questions';

export type AssociatedPersonItem = {
  id: number;
  name: string;
};

type questionDataType = {
  questionTypeOptions: QuestionSpecificationType | undefined;
  personAssociation: number;
  questionTemplateOptionsToSave: Array<QuestionTemplateOptionsType>;
};

type QuestionTemplateItemType = {
  Id: number;
  IncludeByDefault: boolean;
  IsDefault: boolean;
  ItemLabel: string;
  ItemName: string;
  Order: number;
  QuestionTemplateId: number;
};

export function getPersonAssociation(
  data: AddEditQuestionType,
  associatedPersons: Array<AssociatedPersonItem>,
): number {
  const personAssociation = associatedPersons.find(
    item => item.id === data?.PersonAssociation,
  );
  let personAssociationId = -1;
  if (personAssociation && personAssociation.name === 'None')
    personAssociationId = 2;
  if (personAssociation && personAssociation.name === 'Caller')
    personAssociationId = 0;
  if (personAssociation && personAssociation.name === 'Person of concern')
    personAssociationId = 1;
  if (data?.PersonAssociation === '') return 2;

  return personAssociationId;
}

export function formatQuestionTypeOptions(
  data: any,
  options: QuestionSpecificationType | undefined,
  lookupFields: Array<string> | undefined,
): any {
  const type = options?.$type;
  if (
    type ===
    'Proteus2.Api.Domain.Surveys.QuestionTypes.LinkQuestionTypeOptions, Proteus2.Api.Domain'
  ) {
    const typeOptions = {
      ...options,
      Type: data?.Type,
      Url: data?.Url,
      LinkDescription: data?.LinkDescription,
    };
    return typeOptions;
  }
  if (
    type ===
    'Proteus2.Api.Domain.Surveys.QuestionTypes.TextQuestionTypeOptions, Proteus2.Api.Domain'
  ) {
    const typeOptions = {
      ...options,
      Type: data?.Type,
      TextQuestionTypeFormatId: data?.TextQuestionTypeFormatId,
      DefaultValue: data?.DefaultValue,
    };
    return typeOptions;
  }
  if (
    type ===
    'Proteus2.Api.Domain.Surveys.QuestionTypes.LookupTemplateTypeOptions, Proteus2.Api.Domain'
  ) {
    const typeOptions = {
      ...options,
      FieldNames: lookupFields,
    };
    return typeOptions;
  }
  if (
    type ===
    'Proteus2.Api.Domain.Surveys.QuestionTypes.ChoiceQuestionTypeOptions, Proteus2.Api.Domain'
  ) {
    const typeOptions = {
      ...options,
      Type: data?.Type,
    };
    return typeOptions;
  }
  return options;
}

export function formatQuestionData(
  data: AddEditQuestionType,
  questionSpec: QuestionSpecificationType | undefined,
  personAssociationOptions: Array<AssociatedPersonItem>,
  questionId: number,
  questionTypeOptionsReturn: QuestionTypeOptionsType | undefined,
  lookupFields: Array<string> | undefined,
  questionTemplateOptions: Array<QuestionTemplateItemType>,
): questionDataType {
  let questionTypeOptions;
  if (questionId === 0) {
    questionTypeOptions = formatQuestionTypeOptions(
      data,
      questionSpec,
      lookupFields,
    );
  } else {
    questionTypeOptions = formatQuestionTypeOptions(
      data,
      questionTypeOptionsReturn,
      lookupFields,
    );
  }
  const personAssociation = getPersonAssociation(
    data,
    personAssociationOptions,
  );
  const questionTemplateOptionsToSave = [...questionTemplateOptions]; // edit for choice question
  return {
    questionTypeOptions,
    personAssociation,
    questionTemplateOptionsToSave,
  };
}
