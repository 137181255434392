/* eslint-disable react/display-name */
import React from 'react';
import { Column, TableInstance, ColumnInstance } from 'react-table';
import { Text } from 'rebass/styled-components';
import { CheckLinksType } from 'types/checkLinks';
import Header from './tableHeader';
import { Stack } from 'ui/components';
import * as Icon from 'assets/icons';

// type ColumnSearchType = {
//   'Table Name': string;
//   'Last Updated': string;
//   'Last Updated By': string;
// };

// type ColumnNames = keyof ColumnSearchType;

function contains(array: Array<string>, string: string): boolean {
  const index = array.findIndex(item => item === string);
  return index !== -1;
}

export default function useCheckLinksTableColumns(
  columnsList?: Array<string>,
): Array<Column<CheckLinksType>> {
  return React.useMemo((): Array<Column<CheckLinksType>> => {
    if (columnsList) {
      const columns = [];
      if (contains(columnsList, 'Location'))
        columns.push(getLocationHeader(Header));
      if (contains(columnsList, 'Sub Location'))
        columns.push(getSubLocationHeader(Header));
      if (contains(columnsList, 'Section'))
        columns.push(getSectionHeader(Header));
      if (contains(columnsList, 'Question'))
        columns.push(getQuestionHeader(Header));
      if (contains(columnsList, 'Url')) columns.push(getUrlHeader(Header));

      if (contains(columnsList, 'Status Code'))
        columns.push(getStatusCodeHeader(Header));

      if (contains(columnsList, 'Status Description'))
        columns.push(getStatusDescriptionHeader(Header));

      return columns;
    }
    return [
      getLocationHeader(Header),
      getSubLocationHeader(Header),
      getSectionHeader(Header),
      getQuestionHeader(Header),
      getUrlHeader(Header),
      getStatusCodeHeader(Header),
      getStatusDescriptionHeader(Header),
    ];
  }, [columnsList]);
}

function getLocationHeader(
  Header: (
    props: TableInstance<CheckLinksType> & {
      column: ColumnInstance<CheckLinksType>;
    },
  ) => JSX.Element,
): Column<CheckLinksType> {
  return {
    Header: Header,
    accessor: 'Location',
    id: 'Location',
  };
}

function getSubLocationHeader(
  Header: (
    props: TableInstance<CheckLinksType> & {
      column: ColumnInstance<CheckLinksType>;
    },
  ) => JSX.Element,
): Column<CheckLinksType> {
  return {
    Header: Header,
    accessor: 'SubLocation',
    id: 'Sub Location',
  };
}

function getSectionHeader(
  Header: (
    props: TableInstance<CheckLinksType> & {
      column: ColumnInstance<CheckLinksType>;
    },
  ) => JSX.Element,
): Column<CheckLinksType> {
  return {
    id: 'Section',
    Header: Header,
    accessor: 'SectionTitle',
  };
}

function getQuestionHeader(
  Header: (
    props: TableInstance<CheckLinksType> & {
      column: ColumnInstance<CheckLinksType>;
    },
  ) => JSX.Element,
): Column<CheckLinksType> {
  return {
    id: 'Question',
    Header: Header,
    accessor: 'QuestionName',
  };
}
function getUrlHeader(
  Header: (
    props: TableInstance<CheckLinksType> & {
      column: ColumnInstance<CheckLinksType>;
    },
  ) => JSX.Element,
): Column<CheckLinksType> {
  return {
    id: 'Url',
    Header: Header,
    // accessor: 'Url',
    accessor: row => (
      <a target="_blank" rel="noopener noreferrer" href={row.Url}>
        {row.Url}
      </a>
    ),
  };
}
function getStatusCodeHeader(
  Header: (
    props: TableInstance<CheckLinksType> & {
      column: ColumnInstance<CheckLinksType>;
    },
  ) => JSX.Element,
): Column<CheckLinksType> {
  return {
    id: 'Status Code',
    Header: Header,
    accessor: 'StatusCode',
    Cell: rowInfo => {
      return (
        <Stack direction="x" gap={2}>
          {(rowInfo.row.original.StatusCode === 404 ||
            rowInfo.row.original.StatusCode === 403) && (
            <Icon.Xcircle fill="var(--color-state-error)" />
          )}

          {rowInfo.row.original.StatusCode === 200 && (
            <Icon.CheckSquare fill="var(--color-state-info)" />
          )}
          {rowInfo.row.original.StatusCode === null && (
            <Icon.Warning fill="var(--color-state-warning)" />
          )}
          {rowInfo.row.original.StatusCode && (
            <Text as="span" color="state.error" ml={2}>
              {rowInfo.row.original.StatusCode}
            </Text>
          )}
        </Stack>
      );
    },
  };
}

function getStatusDescriptionHeader(
  Header: (
    props: TableInstance<CheckLinksType> & {
      column: ColumnInstance<CheckLinksType>;
    },
  ) => JSX.Element,
): Column<CheckLinksType> {
  return {
    id: 'Status Description',
    Header: Header,
    accessor: 'StatusDescription',
    Cell: rowInfo => {
      return (
        <Stack direction="x" gap={2}>
          {(rowInfo.row.original.StatusCode === 404 ||
            rowInfo.row.original.StatusCode === 403) && (
            <Text color="state.error">
              {rowInfo.row.original.StatusDescription}
            </Text>
          )}
          {rowInfo.row.original.StatusCode === 200 && (
            <Text color="state.info">
              {rowInfo.row.original.StatusDescription}
            </Text>
          )}
          {rowInfo.row.original.StatusCode === null && (
            <Text color="state.warning">
              {rowInfo.row.original.StatusDescription}
            </Text>
          )}
        </Stack>
      );
    },
  };
}
