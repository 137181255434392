import { useState, useEffect } from 'react';

const useDarkMode = (): [string, () => void] => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)')
    .matches;
  const userPreferedTheme = prefersDarkTheme ? 'dark' : 'light';

  const [colorMode, setColorMode] = useState(userPreferedTheme);

  const setAttribute = (colorMode: string) => {
    const root = window.document.documentElement;
    root.classList.add('color-mode-in-transition');
    root.setAttribute('data-color-mode', colorMode);
    window.setTimeout(function () {
      root.classList.remove('color-mode-in-transition');
    }, 1000);
  };

  const toggleColorMode = () => {
    if (colorMode === 'light') {
      setColorMode('dark');
      window.localStorage.setItem('colorMode', 'dark');
      setAttribute('dark');
    } else {
      setColorMode('light');
      window.localStorage.setItem('colorMode', 'light');
      setAttribute('light');
    }
  };

  useEffect(() => {
    const localTheme: any = window.localStorage.getItem('colorMode');
    setAttribute(localTheme);
    if (localTheme) {
      setColorMode(localTheme);
    } else {
      window.localStorage.setItem('colorMode', userPreferedTheme);
      setAttribute(userPreferedTheme);
    }
  }, []);

  return [colorMode, toggleColorMode];
};

export { useDarkMode };

export default useDarkMode;
