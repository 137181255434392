import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { TagMappings } from 'types/tagMapping';
import { tagMappings as saveTagMapping } from 'utils/urls';
import { getTokenWithType } from './useAuth';

export async function postTagMapping({
  params,
}: {
  params: TVariables;
}): Promise<TResult> {
  const response = await fetch(saveTagMapping(params.AccountId), {
    method: 'PUT',
    headers: {
      Authorization: getTokenWithType(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(params.TagMapping),
  });
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = TagMappings & ValidationError;
export type TVariables = {
  AccountId: number;
  TagMapping: Array<TagMappings>;
};

export type TError = {
  Message: string;
};
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;

function useSaveTagMappings(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: TVariables) => {
      const newTagMapping = await postTagMapping({
        params: params,
      });
      if (newTagMapping.Message) throw newTagMapping;
      queryCache.invalidateQueries(['get_tag_mappings']);
      return newTagMapping;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveTagMappings };
