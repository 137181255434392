import { useInfiniteQuery, InfiniteQueryResult, queryCache } from 'react-query';
import { Contact } from 'types/contacts';
import { getCustomRecords } from '../utils/urls';
import { getTokenWithType } from './useAuth';

const pageLength = 100;
function getColumnFromId(columns: Array<string>, id: string) {
  const idsList = columns;
  const index = idsList.findIndex(item => item === id);
  return `${index}`;
}

async function fetchCustomRecord({
  requestData,
  accountId,
  tableId,
}: TVariables): Promise<TResult> {
  const response = await fetch(getCustomRecords(accountId, tableId), {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

type resultType = {
  data: Array<Contact>;
  draw: number;
  isAutoRefreshEnabled: boolean;
  error: Error;
  recordsFiltered: number;
  recordsTotal: number;
};

export type TResult =
  | {
      message: string;
      isSuccess: boolean;
      data: Array<resultType>;
      recordsFiltered: number;
      recordsTotal: number;
      draw: number;
      error: Error | null;
    }
  | undefined;

export type TError = { message: string };
export type TVariables = {
  requestData: any;
  accountId: number;
  tableId: number;
};

function formatRequestData(
  page: number,
  columnsSearch: Record<string, string>,
  sortedColumn: string,
  sortOrder: string,
  keywordSearch: string,
  columns: Array<string>,
): FormData {
  const formData = new URLSearchParams();
  formData.append('draw', '8');
  columns.forEach((item: any, index: number) => {
    formData.append(`columns[${index}][data]`, 'function');
    formData.append(`columns[${index}][name]`, '');
    formData.append(`columns[${index}][searchable]`, 'true');
    formData.append(`columns[${index}][orderable]`, 'true');
    formData.append(
      `columns[${index}][search][value]`,
      columnsSearch && columnsSearch[item] ? columnsSearch[item] : '',
    );
    formData.append(`columns[${index}][search][regex]`, 'false');
  });
  formData.append('order[0][column]', sortedColumn);
  formData.append('order[0][dir]', sortOrder);
  formData.append('search[value]', `${keywordSearch}`);
  formData.append('search[regex]', 'false');
  formData.append('start', `${page * 100}`);
  formData.append('length', `${100}`);
  formData.append('timezoneOffset', '-330');
  return formData;
}

function useCustomRecords(
  page: number,
  columnsSearch: Record<string, string>,
  sortItem: { sortKey: string; sortOrder: string },
  keywordSearch: string,
  accountId: number,
  tableId: number,
  columns: Array<string>,
): InfiniteQueryResult<TResult, TError> {
  const data: Array<TResult> | undefined = queryCache.getQueryData([
    'custom_records',
    keywordSearch,
    columnsSearch,
    sortItem,
    accountId,
    tableId,
  ]);
  let start = data ? data.length : 0;
  if (start > 0 && data) {
    const totalPages = (data[0] && data[0].recordsFiltered / pageLength) || 0;
    if (totalPages > 1) {
      start = start > totalPages ? totalPages : start;
    } else {
      start = 0;
    }
  }
  const requestData = formatRequestData(
    start,
    columnsSearch,
    getColumnFromId(columns, sortItem.sortKey),
    sortItem.sortOrder,
    keywordSearch,
    columns,
  );

  return useInfiniteQuery(
    [
      'custom_records',
      // page,
      keywordSearch,
      columnsSearch,
      sortItem,
      accountId,
      tableId,
    ],
    () => fetchCustomRecord({ requestData, accountId, tableId }),
    {
      getFetchMore: (lastGroup, allGroups) => {
        if (lastGroup)
          return allGroups.length < lastGroup.recordsFiltered / pageLength
            ? true
            : null;
      },

      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useCustomRecords };
