// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const spacing = {
 "space4": "0.2857142857142857rem",
 "space8": "0.5714285714285714rem",
 "space12": "0.8571428571428571rem",
 "space16": "1.1428571428571428rem",
 "space20": "1.4285714285714284rem",
 "space24": "1.7142857142857142rem",
 "space32": "2.2857142857142856rem",
 "space40": "2.8571428571428568rem",
 "space48": "3.4285714285714284rem",
 "space64": "4.571428571428571rem",
 "space80": "5.7142857142857135rem",
 "space96": "6.857142857142857rem",
 "space112": "8rem",
 "space128": "9.142857142857142rem"
}

export default spacing;