import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { copyForm } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { Forms } from 'types/forms';

async function postForm({ formId }: TVariables): Promise<TResult> {
  const response = await fetch(copyForm(formId), {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = Forms | undefined;

export type TError = { message: string };
export type TVariables = {
  formId: number;
};

export type TVariablesPut = {
  formId: number;
  params: Forms;
};
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;

function useFormDetailsCopy(): MutationResultPair<
  TResult,
  TError,
  number,
  TSnapshot
> {
  return useMutation(async (formId: number) => {
    if (formId) {
      const apiResult = await postForm({
        formId,
      });
      queryCache.invalidateQueries(['forms_list']);
      return apiResult;
    }
  });
}

export { useFormDetailsCopy };
