import { useQuery, QueryResult } from 'react-query';
import { getPublishedProcedures } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchPublishedProcedure({
  accountId,
}: TVariables): Promise<TResult> {
  const response = await fetch(getPublishedProcedures(accountId), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type ResultItem = {
  ProcedureId: number;
  ProcedureName: string;
};

export type TResult = Array<ResultItem> | undefined;

export type TError = { message: string };
export type TVariables = {
  accountId: number;
};

function useGetPublishedProcedures(
  accountId: number,
): QueryResult<TResult, TError> {
  return useQuery(
    ['get_published_procedures', accountId],
    async () => {
      if (accountId) {
        return await fetchPublishedProcedure({
          accountId,
        });
      }
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetPublishedProcedures };
