import { useQuery, QueryResult } from 'react-query';
import { officeFacts } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { OfficeFactsInput } from 'types/accounts';

async function fetchOfficeFacts({ id }: TVariables): Promise<APIResult> {
  if (!id)
    return new Promise((resolve, reject) => {
      reject();
    });
  const response = await fetch(officeFacts(id), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type APIResult = OfficeFactsInput;
export type TResult = {
  officeFactsReturn: OfficeFactsInput;
  officeFactsInputs: OfficeFactsInput;
};

export type TError = { message: string };
export type TVariables = {
  id: number;
};

function useGetOfficeFacts(id: number): QueryResult<TResult, TError> {
  return useQuery(
    ['get_officeFacts', id],
    async () => {
      const fetchResponse = await fetchOfficeFacts({ id });
      const result: OfficeFactsInput = {
        Account: fetchResponse.Account,
        AccountId: fetchResponse.AccountId,
        CallbackProcedures: fetchResponse.CallbackProcedures,
        Coverage: fetchResponse.Coverage,
        Hours: fetchResponse.Hours,
        Id: fetchResponse.Id,
        Notes: fetchResponse.Notes,
      };
      return { officeFactsInputs: result, officeFactsReturn: fetchResponse };
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetOfficeFacts };
