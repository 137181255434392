import React, { ReactElement, useEffect, useCallback, useMemo } from 'react';
import StyledHeader from './styles';
import { Flex, Box, Text } from 'rebass/styled-components';
import { Avatar, Logo, ColorModeToggle, Stack } from 'ui/components';
import { Dropdown, PrimaryNav } from 'ui/patterns';
import LogoFile from 'assets/images/protocall-logo-withcaption.png';
import AvatarDefault from 'assets/images/avatar-default.svg';
// import { useReactOidc } from '@axa-fr/react-oidc-context';
import * as Icon from '../../../assets/icons';
import { useDarkMode } from 'hooks';
import { AUTH_TOKEN } from 'utils/authenticationHelper/authConstant';
import { AuthServiceSingleton } from 'utils/authenticationHelper/authServices';

// import AuthenticationService from 'utils/authenticationHelper/authenticationService';

// const links = [
//   { label: 'Dashboard', route: '/home' },
//   { label: 'Accounts', route: '/home/accounts' },
//   { label: 'Questions', route: '/home/questions' },
//   { label: 'About', route: '/home/about' },
// ];

type HeaderProps = {
  username: string;
  avatar: string;
  permissions: {
    CanEditQuestionRepository: boolean;
    CanAccessAccountBuilder: boolean;
    CanEditForms: boolean;
    CanModifyAllAccounts: boolean;
  };
};

function Header({
  username,
  avatar,
  permissions,
}: Partial<HeaderProps>): ReactElement {
  // const { logout: oidcLogout } = useReactOidc();

  /*procedure - iframe authentication fix */
  // useEffect(() => {
  //   const tabsOpen = localStorage.getItem('tabsOpen');
  //   if (tabsOpen == null) {
  //     localStorage.setItem('tabsOpen', '1');
  //   } else {
  //     const newTabCount = parseInt(tabsOpen) + 1;
  //     localStorage.setItem('tabsOpen', newTabCount.toString());
  //   }
  // }, []);

  // useEffect(() => {
  //   let totalTabs: number;
  //   window.onload = (e: any) => {
  //     const accessToken = sessionStorage.getItem(AUTH_TOKEN);
  //     if (accessToken !== null) {
  //       localStorage.setItem(AUTH_TOKEN, accessToken);
  //       sessionStorage.removeItem(AUTH_TOKEN);
  //     }
  //     if (localStorage.getItem('tabsOpen') === null) {
  //       totalTabs = 0;
  //     } else {
  //       totalTabs = parseInt(localStorage.getItem('tabsOpen') || '');
  //     }
  //     totalTabs++;
  //     localStorage.setItem('tabsOpen', '' + totalTabs);
  //   };

  //   window.onbeforeunload = (e: any) => {
  //     if (localStorage.getItem('tabsOpen') === null) {
  //       totalTabs = 1;
  //     } else {
  //       totalTabs = parseInt(localStorage.getItem('tabsOpen') || '');
  //     }
  //     totalTabs--;
  //     localStorage.setItem('tabsOpen', '' + totalTabs);
  //     if (totalTabs < 1) {
  //       const accessToken = localStorage.getItem(AUTH_TOKEN);
  //       if (accessToken !== null) {
  //         sessionStorage.setItem(AUTH_TOKEN, accessToken);
  //       }
  //       localStorage.removeItem(AUTH_TOKEN);
  //       localStorage.removeItem('tabsOpen');
  //       localStorage.removeItem('LastKnownLocation');
  //     }
  //   };
  // }, []);

  const navMenu = useMemo(() => {
    const links = [
      { label: 'Dashboard', route: '/home' },
      { label: 'Accounts', route: '/home/accounts' },
      { label: 'Questions', route: '/home/questions' },
      { label: 'About', route: '/home/about' },
    ];
    if (permissions) {
      if (!permissions.CanAccessAccountBuilder) {
        links.splice(0, 4);
      } else {
        if (!permissions.CanEditQuestionRepository) {
          links.splice(2, 1);
        }
        if (!permissions.CanModifyAllAccounts) {
          links.splice(1, 1);
        }
      }
      return links;
    }
  }, [permissions]);

  const logout = useCallback(() => {
    //clearing(meant for user permissions) of specific keys in localStorage to preserve certain user selections
    localStorage.removeItem('userPermissions');
    localStorage.removeItem('GlobalState');
    AuthServiceSingleton.getInstance().logout();
  }, []);

  const ddItems = [
    { id: 1, value: 'Logout', icon: <Icon.Logout />, action: logout },
  ];

  const userDisplayName = username?.split(', ')[0];
  const userDesignation = username?.split(', ')[1];

  const profileInfo = () => (
    <Flex data-testid={'profile-dropdown'} alignItems="center">
      <Avatar src={avatar || AvatarDefault} className="pr-header-avatar" />
      <Box ml="2" mr="1">
        {/* {username} */}
        <Text fontSize={1} fontWeight="medium" color="text.body">
          {userDisplayName},{' '}
        </Text>
        {userDesignation ? (
          <Text
            color="text.muted"
            fontWeight="medium"
            fontSize="1"
            sx={{
              maxWidth: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {userDesignation}
          </Text>
        ) : (
          ''
        )}
      </Box>
      <Icon.ChevronDown title="Down arrow" />
    </Flex>
  );

  const [colorMode, toggleColorMode] = useDarkMode();

  return (
    <StyledHeader>
      <Box>
        <Flex>
          <Logo route="/home" src={`${process.env.REACT_APP_LOGO}`}></Logo>
          <PrimaryNav links={navMenu ? navMenu : []} />
        </Flex>
        <Stack
          direction="x"
          gap={6}
          style={{ alignItems: 'center', marginInlineEnd: '50px' }}
        >
          {/* <button onClick={() => toggleColorMode()}>
            {colorMode === 'dark' ? 'Light' : 'Dark'}
          </button> */}
          <ColorModeToggle
            onClick={() => toggleColorMode()}
            colorMode={colorMode}
          />
          <Dropdown
            trigger={profileInfo()}
            menuList={ddItems}
            className="dropdown-profile"
          />
        </Stack>
      </Box>
    </StyledHeader>
  );
}

export { Header };
