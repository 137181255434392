import React, { ReactElement, useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import { SearchRuleItem } from 'types/accountsSearch';
type HighlightInput = {
  searchedWords: Array<SearchRuleItem>;
  content: string;
};

const HighlightTextWrapper = ({
  searchedWords,
  content,
}: HighlightInput): ReactElement => {
  const words = useMemo(() => {
    if (searchedWords) {
      return searchedWords
        ?.filter(searchedItem => searchedItem?.expression !== 'NotContains')
        .map(item => {
          if (item?.value.indexOf('"') > -1)
            return item?.value.replaceAll('"', ' ').trim();
          else return item?.value.replaceAll('"', ' ').trim().split(/[ ,]+/);
        })
        .flat();
    }
  }, [searchedWords]);

  return (
    <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={words}
      autoEscape={true}
      textToHighlight={content.toString()}
    />
  );
};
export { HighlightTextWrapper };
