import styled, { css } from 'styled-components';
import {
  FlexboxProps,
  flexbox,
  SpaceProps,
  space,
  LayoutProps,
  layout,
  FontSizeProps,
  fontSize,
} from 'styled-system';
import { transparentize } from 'polished';

type BadgeProps = FlexboxProps &
  SpaceProps &
  LayoutProps &
  FontSizeProps & {
    variant?: 'primary' | 'info' | 'error' | 'success' | 'warning';
  };

const Badge = styled.span<BadgeProps>`
  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
  font-size: 0.75rem;
  text-align: center;
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  border-radius: 100px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.text.body};
  background-color: var(--color-neutral-contrast-03);
  border: solid 1px ${({ theme }) => theme.colors.border.default};

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      color: ${({ theme }) => theme.colors.primary};
      background-color: ${({ theme }) =>
        transparentize(0.6, theme.colors.primary)};
      border: solid 1px ${({ theme }) => theme.colors.primary};
    `}

  ${({ variant }) =>
    variant === 'info' &&
    css`
      color: ${({ theme }) => theme.baseColors.blue[9]};
      background-color: ${({ theme }) =>
        transparentize(0.2, theme.baseColors.blue[1])};
      border: solid 1px ${({ theme }) => theme.colors.border.info};
    `}

  ${({ variant }) =>
    variant === 'success' &&
    css`
      color: ${({ theme }) => theme.baseColors.green[9]};
      background-color: ${({ theme }) =>
        transparentize(0.2, theme.baseColors.green[2])};
      border: solid 1px ${({ theme }) => theme.colors.border.success};
    `}

  ${({ variant }) =>
    variant === 'error' &&
    css`
      color: ${({ theme }) => theme.baseColors.red[9]};
      background-color: ${({ theme }) =>
        transparentize(0.2, theme.baseColors.red[2])};
      border: solid 1px ${({ theme }) => theme.colors.border.error};
    `}

  ${({ variant }) =>
    variant === 'warning' &&
    css`
      color: ${({ theme }) => theme.baseColors.yellow[9]};
      background-color: ${({ theme }) => theme.baseColors.yellow[3]};
      border: solid 1px ${({ theme }) => theme.colors.border.warning};
    `}

  ${flexbox}
  ${space}
	${layout}
	${fontSize}
`;

export { Badge };
