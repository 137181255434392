import React, {
  useEffect,
  ReactElement,
  useLayoutEffect,
  useState,
  useRef,
} from 'react';
import { Editor as EditorComponent } from '@tinymce/tinymce-react';
import { Editor } from 'tinymce';
import { confirmAlert } from 'utils/confirm-alert';
import { useLocation } from 'react-router-dom';
import { showSuccessToast } from 'utils/showToast';

function uuidv4() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
}

function wrapInner(parent: any, wrapper: any) {
  if (typeof wrapper === 'string') wrapper = document.createElement(wrapper);

  const div = parent.appendChild(wrapper);

  while (parent.firstChild !== wrapper) wrapper.appendChild(parent.firstChild);
}
// default plugins list
// 'advlist autolink lists link image charmap print preview anchor',
// 'searchreplace visualblocks code fullscreen',
// 'insertdatetime media table paste code help wordcount',
// initialValue="<p>This is the initial content of the editor</p>"

const handleNotImplemented = () => {
  confirmAlert({
    message: 'Not Implemented',
    buttons: [
      {
        label: 'Ok',
        onClick: () => {
          //
        },
        className: 'pr-btn-primary',
      },
    ],
  });
};
const stripHtml = (html: string): string =>
  new DOMParser().parseFromString(html, 'text/html').body.textContent || '';

function TinyMCE({
  onChange,
  value,
  // initialValue,
  name,
  height,
  setLoading,
  toggleEmbeddedProcedure,
  toggleExistingLayout,
  toggleExistingTable,
  toggleFileImage,
  toggleHyperlink,
  brokenLinks,
  onUserTyped,
  onDetectTableSelection,
  procedureId,
  handleCleanProcedureTemplate,
  setEditorChanges,
}: {
  onChange: (content: string, editor: any) => void;
  value: any;
  initialValue: any;
  name: string;
  height: number;
  setLoading?: (isLoading: boolean) => void;
  toggleEmbeddedProcedure: (
    params:
      | {
          account: string;
          procedureName: string;
        }
      | undefined,
  ) => void;
  toggleExistingLayout: () => void;
  toggleExistingTable: () => void;
  toggleFileImage: () => void;
  toggleHyperlink: () => void;
  setEditorChanges: (value: boolean) => void;
  handleCleanProcedureTemplate: () => any;
  brokenLinks?: Array<any>;
  onUserTyped?: (undo?: boolean) => void;
  onDetectTableSelection?: () => void;
  procedureId?: number;
}): ReactElement | null {
  const [isRefreshing, setRefreshing] = useState(false);
  const procedureIdRef = useRef<number>();

  const location = useLocation();
  const [tinyEditor, setTinyEditor] = useState<Editor>();
  useLayoutEffect(() => {
    setLoading && setLoading(true);
    return () => {
      setLoading && setLoading(false);
    };
  }, [setLoading]);

  useEffect(() => {
    const editor = tinyEditor;
    if (typeof editor != 'undefined') {
      if (procedureIdRef.current !== procedureId) {
        procedureIdRef.current = procedureId;
        setRefreshing(true);
        setTimeout(() => {
          setRefreshing(false);
        }, 10);
      }
    }
  }, [procedureId, tinyEditor]);

  useEffect(() => {
    const editor = tinyEditor;
    const url = window.location.href;
    const apiBaseUrl = url.replace(location.pathname, '');
    if (editor && editor?.getBody() !== null) {
      const tagCollection = editor?.getBody()?.getElementsByTagName('a');

      //External links in new tab in CC
      for (let i = 0; i < tagCollection.length; i++) {
        if (tagCollection[i].host !== window.location.host) {
          tagCollection[i].target = '_blank';
        }
      }
      if (brokenLinks && brokenLinks?.length > 0) {
        for (let j = 0; j < brokenLinks?.length; j++) {
          for (let i = 0; i < tagCollection.length; i++) {
            const decodeHref = decodeURI(tagCollection[i].href);
            if (
              apiBaseUrl.replace('/api/', '') + brokenLinks[j]?.Url ===
                decodeHref ||
              stripHtml(brokenLinks[j]?.Url.replace(/\/$/, '')) ==
                decodeHref.replace(/\/$/, '')
            ) {
              tagCollection[i].className = 'fa-cls';
              if (brokenLinks[j].IgnoreIsBroken === true) {
                tagCollection[i].classList.add('dismissed');
              }
              if (
                brokenLinks[j].IgnoreIsBroken === false &&
                tagCollection[i]?.classList.contains('dismissed')
              ) {
                tagCollection[i].classList.remove('dismissed');
              }
            }
          }
        }
        if (brokenLinks?.length !== tagCollection?.length) {
          for (let i = 0; i < tagCollection.length; i++) {
            const decodeHref = decodeURI(tagCollection[i].href);
            const condition = brokenLinks?.filter(
              (item: any) =>
                apiBaseUrl.replace('/api/', '') + item?.Url === decodeHref ||
                stripHtml(item.Url.replace(/\/$/, '')) ===
                  decodeHref.replace(/\/$/, ''),
            );
            if (condition?.length === 0) {
              if (
                tagCollection[i].classList.contains('fa-cls') ||
                tagCollection[i].classList.contains('dismissed')
              ) {
                tagCollection[i].classList.remove('fa-cls', 'dismissed');
                tagCollection[i].nextElementSibling?.remove();
              }
            }
          }
        }
        // editor.undoManager.reset();
      } else {
        for (let i = 0; i < tagCollection.length; i++) {
          if (
            tagCollection[i].classList.contains('fa-cls') ||
            tagCollection[i].classList.contains('dismissed')
          ) {
            tagCollection[i].classList.remove('fa-cls', 'dismissed');
            tagCollection[i].nextElementSibling?.remove();
          }
        }
      }
    }
  }, [brokenLinks, tinyEditor, location]);

  if (isRefreshing) return null;

  return (
    <EditorComponent
      // onInit={(_, editor) => {
      //   setLoading && setLoading(false);
      //   setTinyEditor(editor);
      // }}
      onInit={(_, editor) => {
        setLoading && setLoading(false);
        setTinyEditor(editor);
        const url = window.location.href;
        const apiBaseUrl = url.replace(location.pathname, '');
        if (editor && editor?.getBody() !== null) {
          const tagCollection = editor?.getBody()?.getElementsByTagName('a');
          //External links in new tab in CC
          for (let i = 0; i < tagCollection.length; i++) {
            if (tagCollection[i].host !== window.location.host) {
              tagCollection[i].target = '_blank';
            }
          }
          if (brokenLinks && brokenLinks?.length > 0) {
            for (let j = 0; j < brokenLinks?.length; j++) {
              for (let i = 0; i < tagCollection.length; i++) {
                const decodeHref = decodeURI(tagCollection[i].href);
                if (
                  apiBaseUrl.replace('/api/', '') + brokenLinks[j]?.Url ===
                    decodeHref ||
                  stripHtml(brokenLinks[j]?.Url.replace(/\/$/, '')) ==
                    decodeHref.replace(/\/$/, '')
                ) {
                  tagCollection[i].className = 'fa-cls';
                  if (brokenLinks[j].IgnoreIsBroken === true) {
                    tagCollection[i].classList.add('dismissed');
                  }
                  if (
                    brokenLinks[j].IgnoreIsBroken === false &&
                    tagCollection[i]?.classList.contains('dismissed')
                  ) {
                    tagCollection[i].classList.remove('dismissed');
                  }
                }
              }
            }
          }
        }
      }}
      textareaName={name}
      value={value}
      init={{
        setup: editor => {
          editor.on('mouseover', function () {
            const tagCollection: HTMLAnchorElement[] = (
              window as any
            )?.tinymce?.activeEditor
              ?.getBody()
              ?.getElementsByTagName('a');
            for (let i = 0; i < tagCollection.length; i++) {
              if (
                (window as any)?.tinymce?.activeEditor
                  ?.getBody()
                  ?.getElementsByTagName('a')
                  [i]?.href.includes('hyper_link')
              ) {
                tagCollection[i].title = tagCollection[i]?.classList.contains(
                  'dismissed',
                )
                  ? `Dismissed Broken Link - ${(tagCollection[i].title = (
                      window as any
                    )?.tinymce?.activeEditor
                      ?.getBody()
                      ?.getElementsByTagName('a')
                      [i]?.href?.split('api')[1])}`
                  : (tagCollection[i].title = (
                      window as any
                    )?.tinymce?.activeEditor
                      ?.getBody()
                      ?.getElementsByTagName('a')
                      [i]?.href?.split('api')[1]);
              } else {
                tagCollection[i].title = tagCollection[i]?.classList.contains(
                  'dismissed',
                )
                  ? `Dismissed Broken Link - ${decodeURI(
                      tagCollection[i].href,
                    )}`
                  : decodeURI(tagCollection[i].href);
              }
            }
          });
          editor.ui.registry.addButton('hyperlink', {
            icon: undefined,
            tooltip: 'Hyperlink',
            text: 'Hyperlink',
            onAction: function () {
              // handleNotImplemented();
              toggleHyperlink();
            },
          });
          editor.ui.registry.addMenuItem('hyperlink', {
            text: 'Set As Link',
            onAction: () => {
              const id = uuidv4();
              const content = (window as any)?.tinymce?.activeEditor?.selection
                ?.getContent({ format: 'text' })
                ?.trim();
              if (content != '') {
                const node = (
                  window as any
                )?.tinymce?.activeEditor?.selection?.getNode();
                if (node.getAttribute('id')) {
                  wrapInner(
                    node,
                    "<span id='" +
                      `hyper_link-${id}` +
                      "',data-linkText='" +
                      content +
                      "' ></span>",
                  );
                } else {
                  node.setAttribute('id', `hyper_link-${id}`);
                  node.setAttribute('data-linkText', content);
                }
                showSuccessToast({ message: 'Set selected text as link' });
              }
            },
          });
          editor.ui.registry.addMenuItem('Copy-Link', {
            text: 'Copy-Link',
            onAction: () => {
              let selectedText;
              if (
                (window as any)?.tinymce?.activeEditor?.selection
                  ?.getNode()
                  ?.href?.includes('hyper_link')
              ) {
                selectedText = (window as any)?.tinymce?.activeEditor?.selection
                  ?.getNode()
                  ?.href.split('api')[1];
              } else {
                selectedText = (
                  window as any
                )?.tinymce?.activeEditor?.selection?.getNode()?.href;
              }
              const element = document.createElement('textarea');
              element.value = selectedText;
              document.body.appendChild(element);
              element.select();
              document.execCommand('copy');
              document.body.removeChild(element);
            },
          });
          editor.ui.registry.addButton('FileImage', {
            icon: undefined,
            tooltip: 'File/Image',
            text: 'File/Image',
            onAction: function () {
              toggleFileImage();
            },
          });
          editor.ui.registry.addButton('EmbeddedProcedure', {
            icon: undefined,
            tooltip: 'Embedded-Procedure',
            text: 'Embedded-Procedure',
            onAction: function () {
              toggleEmbeddedProcedure(undefined);
            },
          });
          editor.ui.registry.addMenuButton('Template', {
            icon: undefined,
            tooltip: 'Template',
            text: 'Template',
            fetch: function (callback: any) {
              const menuItems = [
                {
                  type: 'menuitem',
                  text: 'Layout',
                  onAction: function () {
                    toggleExistingLayout();
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Table',
                  onAction: function () {
                    toggleExistingTable();
                  },
                },
              ];
              callback(menuItems);
            },
          });
          editor.ui.registry.addButton('CleanTemplate', {
            icon: undefined,
            tooltip: 'Clean Template',
            text: 'Clean Template',
            onAction: function () {
              handleCleanProcedureTemplate();
            },
          });
        },
        height: height || 500,
        autolink_pattern:
          /^(https?:\/\/|ssh:\/\/|ftp:\/\/|file:\/|www\.)(.+)$/i,
        font_family_formats:
          'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Cambria Serif=Cambria, Georgia, serif; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Roboto="Roboto",sans-serif; Source Sans Pro="Source Sans Pro", sans-serif; Source Serif Pro="Source Serif Pro", serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
        menubar: 'file edit view insert format tools table',
        table_toolbar:
          'tableprops tabledelete | tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | tablemergecells tablesplitcells',
        plugins: [
          'print',
          'powerpaste',
          'advlist',
          'autolink',
          'lists',
          'link',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'advcode',
          'fullscreen',
          'insertdatetime',
          'table',
          'image',
          // 'imagetools',
          'tableofcontents', // preminum plugin
          'tinycomments',
          // 'fullpage', //deprecated
          'advtable',
          'a11ychecker ',
          'formatpainter',
          'permanentpen',
          'checklist',
          'casechange',
          'tinymcespellchecker',
          'export',
          // 'contextmenu', //deprecated
          // 'spellchecker', //deprecated
        ],
        forced_root_block_attrs: {
          style: 'margin: 0',
        },
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author',
        a11y_advanced_options: true,
        toolbar1:
          'undo redo | fontfamily | fontsize | blocks | bold italic underline  | alignleft aligncenter alignright alignjustify |bullist numlist outdent indent | forecolor backcolor | print | fullpage | unlink | hyperlink | FileImage | Template|  EmbeddedProcedure |  a11ycheck | formatpainter | permanentpen | checklist | casechange | showcomments | CleanTemplate | export',
        convert_urls: false,
        content_css_cors: true,
        content_css:
          'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css',
        extended_valid_elements: 'i[*]',
        content_style: `
        @import url('https://fonts.googleapis.com/css2?family=Roboto&family=Source+Sans+Pro&family=Source+Serif+Pro&display=swap');
         .mce-content-body {
              font-family:Arial;
              font-size:10pt;
              margin: 1rem;
            }
            .fa-cls {
              display: inline-block;
              color:indianred;
            }
            .fa-cls::after {
              font-family: FontAwesome;
              content: '\\f0c1';
              padding-inline-start: 0.05rem;
              text-decoration: none;
            }
            sup i.fa-link {
              display: none;
            }
            a.fa-cls + sup i.fa-link {
              display: inline;
            }
            .dismissed, .dismissed + sup, .dismissed + i {
              color: #888 !important ;
            }
            #navcontainer {
              width: 600px;
          }

              #navcontainer ul {
                  margin-left: 10px;
                  padding-left: 0;
                  list-style-type: none;
                  font-family: "Times New Roman", Times, serif;
                  font-size: 16px;
                  font-weight: bold;
              }

              #navcontainer a {
                  display: block;
                  padding: 2px;
                  width: 600px;
                  background-color: lightblue;
                  border-bottom: 1px solid #eee;
              }

                  #navcontainer a:link, #navlist a:visited {
                      color: Black;
                      text-decoration: none;
                  }

                  #navcontainer a:hover {
                      background-color: Orange;
                      color: #fff;
                  }

            `,
        // toolbar_drawer: false, // deprecated
        spellchecker_active: true,
        valid_children: '* [*]',
        contextmenu: false,
        link_title: false,
      }}
      onEditorChange={(content, editor) => {
        setEditorChanges(true);
        setTinyEditor(editor);
        // onChange(content, editor);
        const url = window.location.href;
        const apiBaseUrl = url.replace(location.pathname, '');
        if (editor && editor?.getBody() !== null) {
          const tagCollection = editor?.getBody()?.getElementsByTagName('a');
          //External links in new tab in CC
          for (let i = 0; i < tagCollection.length; i++) {
            if (tagCollection[i].host !== window.location.host) {
              tagCollection[i].target = '_blank';
            }
          }
          if (brokenLinks && brokenLinks?.length > 0) {
            for (let j = 0; j < brokenLinks?.length; j++) {
              for (let i = 0; i < tagCollection.length; i++) {
                const decodeHref = decodeURI(tagCollection[i].href);
                if (
                  apiBaseUrl.replace('/api/', '') + brokenLinks[j]?.Url ===
                    decodeHref ||
                  stripHtml(brokenLinks[j]?.Url.replace(/\/$/, '')) ==
                    decodeHref.replace(/\/$/, '')
                ) {
                  tagCollection[i].className = 'fa-cls';
                  if (brokenLinks[j].IgnoreIsBroken === true) {
                    tagCollection[i].classList.add('dismissed');
                  }
                  if (
                    brokenLinks[j].IgnoreIsBroken === false &&
                    tagCollection[i]?.classList.contains('dismissed')
                  ) {
                    tagCollection[i].classList.remove('dismissed');
                  }
                }
              }
            }
          }
        }
      }}
      onKeyDown={() => {
        if (onUserTyped) onUserTyped();
      }}
      onMouseDown={() => {
        if (onUserTyped) onUserTyped();
      }}
      onNodeChange={onDetectTableSelection}
      onUndo={() => {
        if (onUserTyped) onUserTyped(true);
      }}
      onRedo={() => {
        if (onUserTyped) onUserTyped(true);
      }}
    />
  );
}

export { TinyMCE };
