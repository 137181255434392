import { useQuery, QueryResult } from 'react-query';
import { xmlTags } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchXmlTags(): Promise<APIResult> {
  const response = await fetch(xmlTags, {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type XmlTagsResult = string;
export type APIResult = Array<XmlTagsResult>;

export type ResultItem = {
  id: number;
  name: string;
};
export type TResult = Array<ResultItem>;

export type TError = { message: string };

function useGetXmlTags(): QueryResult<TResult, TError> {
  return useQuery(
    'get_xml_tags',
    async () => {
      const fetchResponse = await fetchXmlTags();
      const result = fetchResponse.map((item, index) => ({
        id: index + 1,
        name: item,
      }));
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetXmlTags };
