import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Text, Flex, Box } from 'rebass/styled-components';
import AppRouter from 'router';
import { GlobalStyles, theme, themeLFL } from 'assets/styles';
import { StoreProvider } from 'store/authContext';
import { MainContent } from 'ui/patterns';
import { Spinner } from 'ui/components';
import { ReactQueryDevtools } from 'react-query-devtools';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredImage from 'assets/images/SessionExpired';

const AuthenticatingPage = () => (
  <MainContent contentCentered>
    <Flex flexDirection="column" alignItems="center" justifyContent="center">
      <Box>
        <Spinner />
      </Box>
      <Text fontSize={5} fontWeight="regular" className="fade-in-up">
        Authenticating
      </Text>
    </Flex>
  </MainContent>
);

const NotAutherizedPage = () => (
  <MainContent contentCentered>
    <Flex flexDirection="column" alignItems="center" justifyContent="center">
      <Box>
        <Spinner />
      </Box>
      <Text fontSize={5} fontWeight="regular" className="fade-in-up">
        Redirecting
      </Text>
    </Flex>
  </MainContent>
);

const SessionLostPage = () => {
  // const { login } = useReactOidc();
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const path = query.get('path') || '/home';
  function handleClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    history.push(path);
    window.location.reload();
  }
  return (
    <MainContent contentCentered>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        className="fade-in-up"
      >
        <SessionExpiredImage />
        <Text fontSize={5} fontWeight="regular" my={4}>
          Your session has expired, please{' '}
          <a href="" onClick={handleClick} className="outer-pages-link">
            login
          </a>
          .
        </Text>
        {/* <Button
          variant="primary"
          size="lg"
          onClick={() => {
            login();
          }}
        >
          Login
        </Button> */}
      </Flex>
    </MainContent>
  );
};

const App = (): ReactElement => (
  <StoreProvider>
    <ThemeProvider
      theme={process.env.REACT_APP_THEME === 'lfl' ? themeLFL : theme}
    >
      <GlobalStyles />
      <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      <ToastContainer draggable={false} />
      {/* <BrowserRouter> */}
      {/* <AuthenticationProvider
          configuration={oidcConfiguration}
          loggerLevel={oidcLog.DEBUG}
          authenticating={AuthenticatingPage}
          callbackComponentOverride={AuthenticatingPage}
          notAuthenticated={AuthenticatingPage}
          notAuthorized={NotAutherizedPage}
          sessionLostComponent={SessionLostPage} */}
      {/* > */}
      <AppRouter />
      {/* </AuthenticationProvider> */}
      {/* </BrowserRouter> */}
    </ThemeProvider>
  </StoreProvider>
);
export default App;
