import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { copyQuestion } from 'utils/urls';
import { getTokenWithType } from './useAuth';
import { Forms } from 'types/forms';

export async function postCopyQuestion({
  sectionId,
  qId,
}: {
  sectionId: number;
  qId: number;
}): Promise<TResult> {
  const response = await fetch(
    // params.Id === 0 ? saveAccounts : getAccount(params.Id),
    copyQuestion(sectionId),
    {
      method: 'POST', //id === 0 ? 'POST' : 'PUT',
      headers: {
        Authorization: getTokenWithType(),
        'content-type': 'application/json',
      },
      body: JSON.stringify([qId]),
    },
  );
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = Forms & ValidationError;
export type TVariables = CopyQusetionInput;
export type TError = string;
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;

export type CopyQusetionInput = {
  id: number;
  sectionId: number;
  questionId: number;
};

function useCopyQuestion(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: CopyQusetionInput) => {
      const newFormsInfo = await postCopyQuestion({
        sectionId: params.sectionId,
        qId: params.questionId,
      });
      if (newFormsInfo.Message) throw newFormsInfo.Message;
      else {
        queryCache.invalidateQueries(['get_form_details', params.id]);
        //   if (newAccountInfo.length) throw newAccountInfo[0];
        //   else if (newAccountInfo.Id)
        //     if (params.Id !== 0) {
        //       queryCache.invalidateQueries(['get_account', params.Id]);
        //       await wait(2000);
        //     }
        //   return newAccountInfo;
        return newFormsInfo;
      }
    },
    {
      throwOnError: true,
    },
  );
}

export { useCopyQuestion };
