import React, {
  ReactElement,
  useState,
  useRef,
  useEffect,
  SyntheticEvent,
} from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { confirmAlert } from 'utils/confirm-alert';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  Card,
  CardBody,
  CardHeader,
  AccordionHeader,
  AccordionBody,
  AccordionHeaderContent,
} from 'ui/patterns';
import { Button, Stack } from 'ui/components';
import * as Icon from 'assets/icons';
import { SectionTypeInput } from 'types/forms';
import QuestionItem from './QuestionItem';
import { Questions } from 'types/questions';
import { SectionItemTypes } from './sectionTypes';

export default function SectionItem({
  sectionItem,
  index,
  toggleQuestionPanel,
  toggleNewSection,
  handleCurrentSection,
  handleDeleteSection,
  handleMoveSectionToTop,
  handleMoveSectionToBottom,
  handleMoveSectionUp,
  handleMoveSectionDown,
  handleCurrentQuestion,
  handleDeleteQuestion,
  handleSectionDragAndDrop,
  dependentSections,
  dependentQuestions,
  onSelectSectionItem,
  isSelected,
  dependentQnToolTip,
  dependentSecToolTip,
  openCloseSection,
  expandedState,
  toggleCopyQuestionPanel,
  hideExpander,
  isCopyQnEnabled,
  handleSaveForm,
  isAdd,
}: // handleCopySection,
SectionItemTypes): ReactElement {
  const newSection = 'Proteus2.Api.Domain.Surveys.Section, Proteus2.Api.Domain';

  const showDeleteSectionAlert = (
    sectionItem: SectionTypeInput,
    handleDeleteSection?: (sectionItem: SectionTypeInput) => void,
  ) => {
    confirmAlert({
      message:
        'Are you sure you wish to remove this section and all questions in it?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            handleDeleteSection && handleDeleteSection(sectionItem);
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  // a little function to help us with reordering the result
  const reorder = (
    list: Array<Questions>,
    startIndex: number,
    endIndex: number,
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function handleDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const questions = [...sectionItem.Questions];
    const updatedList = reorder(
      questions,
      result.source.index,
      result.destination.index,
    );
    const newSectionItem: SectionTypeInput = {
      ...sectionItem,
      Questions: [...updatedList],
    };
    handleSectionDragAndDrop && handleSectionDragAndDrop(newSectionItem, index);
  }

  function handleQuestionsOrder(currentIndex: number, destIndex: number) {
    const questions = [...sectionItem.Questions];
    const updatedList = reorder(questions, currentIndex, destIndex);
    const newSectionItem: SectionTypeInput = {
      ...sectionItem,
      Questions: [...updatedList],
    };
    handleSectionDragAndDrop && handleSectionDragAndDrop(newSectionItem, index);
  }

  const dependentToolTip =
    dependentSecToolTip &&
    dependentSecToolTip[sectionItem.Id]
      ?.map((item: any) => item.FieldLabel)
      .join(',');

  const collapseRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const defaultHeight = collapseRef?.current?.scrollHeight;
    setHeight(defaultHeight || 0);
  }, [collapseRef]);

  const [mounted, setMounted] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setMounted(true);
    setHeight(collapseRef?.current?.scrollHeight || 0);
  }, []);

  // const toggleCopyQn = () => {
  //   console.log('sectionItem.Id', sectionItem.Id);
  //   toggleCopyQuestionPanel && toggleCopyQuestionPanel(sectionItem.Id);
  // };

  // useEffect(() => {
  //   setHeight(78 * sectionItem?.Questions?.length + 50);
  // }, [sectionItem]);

  useEffect(() => {
    setHeight(
      78 * sectionItem?.Questions?.length +
        (sectionItem?.Questions?.length <= 5 ? 55 : 50),
    );
  }, [sectionItem]);

  // useEffect(() => {
  //   setExpanded(expandAll || false);

  return (
    // <AccordionWrapper>
    <Card
      // missingCustomTable
      hasDependency={dependentSections?.includes(sectionItem.Id)}
      key={sectionItem.Id}
      active={!onSelectSectionItem && !sectionItem.IsActive}
      onClick={onSelectSectionItem}
      selected={isSelected}
    >
      <AccordionHeader opened={expandedState && expandedState[sectionItem.Id]}>
        <AccordionHeaderContent>
          <CardHeader px={3} py={2}>
            <Flex justifyContent="space-between">
              <Box flex="2" sx={{ wordBreak: 'break-word' }}>
                <Text color="text.body" fontSize={2}>
                  Name:
                </Text>
                {!sectionItem.IsActive && (
                  <Text color="text.body" fontWeight="bold">
                    {`${sectionItem.Title} ${'( Inactive Section )'}`}
                  </Text>
                )}
                {sectionItem.IsActive && (
                  <Text color="text.body" fontWeight="bold">
                    {sectionItem?.$type === newSection
                      ? `${sectionItem.Title}`
                      : // : isAdd
                        // ? `${sectionItem.Title} ${'( Global Section )'}`
                        `${
                          sectionItem?.Section?.Title
                        } ${'( Global Section )'}`}
                  </Text>
                )}
              </Box>
              <Box flex="1" ml={3}>
                {sectionItem?.CallType?.Description ? (
                  <Text color="text.body" fontSize={2}>
                    Call Type:
                  </Text>
                ) : null}

                <Text color="text.body" fontWeight="bold">
                  {sectionItem?.CallType?.Description}
                </Text>
              </Box>
              <Stack direction="x" gap={3} ml={3}>
                {sectionItem.$type === newSection ? (
                  <Flex alignItems="center">
                    <Text
                      fontSize={1}
                      fontWeight="medium"
                      color="text.body"
                      px={3}
                      py={2}
                      lineHeight={1}
                      sx={{
                        borderRadius: '100px',
                      }}
                      className="pc-section-item-questions"
                    >
                      {`Total ${
                        sectionItem.Questions && sectionItem?.Questions?.length
                      } questions`}
                    </Text>
                  </Flex>
                ) : null}

                {dependentSections?.includes(sectionItem.Id) && (
                  <Button
                    variant="ghostTransparent"
                    size="sm"
                    title={`Dependent on ${dependentToolTip}`}
                  >
                    <Icon.Link title={`Dependent on ${dependentToolTip}`} />
                  </Button>
                )}

                {handleMoveSectionToTop && (
                  <Button
                    variant="ghostTransparent"
                    size="sm"
                    title="Move section to top"
                    onClick={() => handleMoveSectionToTop(index)}
                  >
                    <Icon.ChevronUpDouble title="Move section to top" />
                  </Button>
                )}
                {handleMoveSectionUp && (
                  <Button
                    variant="ghostTransparent"
                    size="sm"
                    title="Move section up"
                    onClick={() => handleMoveSectionUp(index)}
                  >
                    <Icon.ArrowUp title="Move section up" />
                  </Button>
                )}
                {handleMoveSectionDown && (
                  <Button
                    variant="ghostTransparent"
                    size="sm"
                    title="Move section down"
                    onClick={() => handleMoveSectionDown(index)}
                  >
                    <Icon.ArrowDown title="Move section down" />
                  </Button>
                )}
                {handleMoveSectionToBottom && (
                  <Button
                    variant="ghostTransparent"
                    size="sm"
                    title="Move section to bottom"
                    onClick={() => handleMoveSectionToBottom(index)}
                  >
                    <Icon.ChevronDownDouble title="Move section to bottom" />
                  </Button>
                )}
                {handleCurrentSection && (
                  <>
                    {sectionItem?.$type === newSection ? (
                      <Button
                        variant="ghostTransparent"
                        size="sm"
                        title="Edit Section"
                        onClick={() => {
                          toggleNewSection && toggleNewSection();
                          handleCurrentSection &&
                            handleCurrentSection(sectionItem);
                        }}
                      >
                        <Icon.Edit title="Edit Section" />
                      </Button>
                    ) : null}
                  </>
                )}

                {handleDeleteSection && (
                  <Button
                    variant="ghostTransparent"
                    size="sm"
                    title="Remove Section"
                    onClick={() =>
                      showDeleteSectionAlert(sectionItem, handleDeleteSection)
                    }
                  >
                    <Icon.Delete title="Remove Section" />
                  </Button>
                )}

                {!hideExpander && sectionItem?.$type === newSection && (
                  <Button
                    testId="expand"
                    variant="secondary"
                    size="sm"
                    onClick={(e: SyntheticEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      openCloseSection && openCloseSection(sectionItem.Id);
                    }}
                  >
                    {expandedState && !expandedState[sectionItem.Id] ? (
                      <Icon.ChevronDown title="" />
                    ) : (
                      <Icon.ChevronUp title="" />
                    )}
                  </Button>
                )}
                {/* <Button variant="secondary" size="sm">
              <Icon.ChevronDown />
            </Button> */}
              </Stack>
            </Flex>
          </CardHeader>
        </AccordionHeaderContent>
      </AccordionHeader>
      <AccordionBody
        className="pc-section-item-accordion-body"
        style={
          !mounted
            ? {}
            : {
                height:
                  expandedState && expandedState[sectionItem.Id]
                    ? `${height}px`
                    : `0px`,
                overflow: 'auto',
              }
        }
        ref={collapseRef}
      >
        {sectionItem.$type === newSection ? (
          <CardBody
            p={3}
            height="100%"
            className="pc-section-item-accordion-body"
          >
            <Flex justifyContent="flex-end" mb={3}>
              <Stack direction="x" gap={3}>
                {handleCurrentSection && (
                  <Button
                    iconBefore={<Icon.Add title="" ariaHidden="true" />}
                    variant="primary"
                    onClick={() => {
                      toggleQuestionPanel && toggleQuestionPanel();
                      handleCurrentSection && handleCurrentSection(sectionItem);
                    }}
                  >
                    Add Question
                  </Button>
                )}
                {handleCurrentSection && (
                  <Button
                    iconBefore={<Icon.Copy title="" ariaHidden="true" />}
                    variant="primary"
                    onClick={() => {
                      if (!isCopyQnEnabled) {
                        confirmAlert({
                          message:
                            'Please save the form to continue with copy question.',
                          buttons: [
                            {
                              label: 'Save',
                              onClick: () => {
                                handleSaveForm &&
                                  handleSaveForm(
                                    sectionItem,
                                    (savedSectionId?: number) => {
                                      toggleCopyQuestionPanel &&
                                        savedSectionId &&
                                        toggleCopyQuestionPanel(savedSectionId);
                                    },
                                  );
                              },
                              className: 'pr-btn-primary',
                            },
                            {
                              label: 'Cancel',
                              onClick: () => {
                                //
                              },
                            },
                          ],
                        });
                      } else {
                        toggleCopyQuestionPanel &&
                          toggleCopyQuestionPanel(sectionItem.Id);
                      }
                    }}
                  >
                    Copy Question
                  </Button>
                )}
              </Stack>
            </Flex>
            <Stack direction="y" gap={3}>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="questionDropList">
                  {provider => (
                    <Stack
                      direction="y"
                      gap={0}
                      ref={provider.innerRef}
                      {...provider.droppableProps}
                    >
                      {sectionItem.Questions &&
                      sectionItem.Questions.length > 0 ? (
                        sectionItem.Questions.map(
                          (questionItem: any, index: number) => (
                            <QuestionItem
                              key={questionItem.Id}
                              index={index}
                              questionItem={questionItem}
                              sectionItem={sectionItem}
                              toggleQuestionPanel={toggleQuestionPanel}
                              handleCurrentSection={handleCurrentSection}
                              handleCurrentQuestion={handleCurrentQuestion}
                              handleDeleteQuestion={
                                handleDeleteQuestion && handleDeleteQuestion
                              }
                              handleQuestionsOrder={
                                handleSectionDragAndDrop && handleQuestionsOrder
                              }
                              dependentQuestions={dependentQuestions}
                              dependentQnToolTip={dependentQnToolTip}
                            />
                          ),
                        )
                      ) : (
                        <Text color="text.body" textAlign="center" flex="1">
                          No Questions are available.
                        </Text>
                      )}
                      {provider.placeholder}
                    </Stack>
                  )}
                </Droppable>
              </DragDropContext>
            </Stack>
          </CardBody>
        ) : null}
      </AccordionBody>
    </Card>
  );
}
