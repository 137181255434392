import React, {
  useState,
  useMemo,
  useCallback,
  ReactElement,
  useEffect,
} from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { Button, Stack } from 'ui/components';
import { Table, SearchInput } from 'ui/patterns';
import * as Icon from 'assets/icons';
import { useGetFormsList } from 'hooks';
import useFormsColumns from 'containers/FormsList/useFormsColumns';
import { Row } from 'react-table';
import { Forms } from 'types/forms';

const formsSortItem = [
  { sortKey: 'Form Notes', sortOrder: 'desc' },
  { sortKey: 'Form Status', sortOrder: 'desc' },
];
type SortItemType = {
  sortKey: string;
  sortOrder: string;
};

const columnSearchInitial = {
  'Form Status': 'Enabled',
};

function useFormsState({
  accountId,
  showPanel,
}: {
  accountId: number;
  showPanel: boolean;
}) {
  const columns = useFormsColumns(['Form Notes', 'Form Status'], true);
  // const [selectedFormId, setSelectedFromId] = useState(0);
  const [keywordSearch, setKeywordSearch] = useState(''); // keyword search
  const [columnsSearch, setColumnsSearch] = useState<Record<string, string>>({
    ...columnSearchInitial,
  }); // header search
  const [currentSortItem, setCurrentSortItem] = useState<
    SortItemType | Array<SortItemType>
  >([...formsSortItem]);
  const {
    data,
    fetchMore,
    canFetchMore,
    isFetchingMore,
    isLoading,
    isFetching,
  } = useGetFormsList({
    accountId: accountId,
    page: 0,
    keywordSearch,
    columnsSearch,
    sortItem: currentSortItem,
  });

  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch, setColumnsSearch],
  );

  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (currentSortItem instanceof Array) {
        const existingItem = currentSortItem.find(
          sItem => sItem.sortKey === item,
        );
        if (existingItem) {
          setCurrentSortItem({
            sortKey: item,
            sortOrder: existingItem.sortOrder === 'asc' ? 'desc' : 'asc',
          });
        } else {
          setCurrentSortItem({
            sortKey: item,
            sortOrder,
          });
        }
      } else if (currentSortItem.sortKey && currentSortItem.sortOrder) {
        if (
          currentSortItem.sortKey === item &&
          currentSortItem.sortOrder === 'asc'
        ) {
          sortOrder = 'desc';
        }
        setCurrentSortItem({
          sortKey: item,
          sortOrder,
        });
      }
    },
    [setCurrentSortItem, currentSortItem],
  );

  const handleKeywordSearch = (value: string): void => {
    setKeywordSearch(value);
  };

  const handleReset = (): void => {
    setKeywordSearch('');
    setColumnsSearch({ ...columnSearchInitial });
    setCurrentSortItem([...formsSortItem]);
  };

  const formTableData = useMemo(() => {
    if (data) {
      return data.map(dataItem => dataItem.data).flat();
    }
  }, [data]);

  const totalFormsItems = useMemo(() => {
    if (data) {
      return data[0].recordsFiltered;
    }
    return 0;
  }, [data]);

  const onFormsEndReached = () => {
    if (formTableData) {
      if (canFetchMore && formTableData?.length < totalFormsItems) fetchMore();
    }
  };
  useEffect(() => {
    handleReset();
  }, [showPanel]);
  return {
    totalFormsItems,
    isFetching,
    handleReset,
    handleKeywordSearch,
    keywordSearch,
    formTableProps: {
      columns,
      data: formTableData,
      isLoading: isLoading || isFetching,
      headerUpdateCallBack,
      handleSortChange,
      columnsSearch,
      multiSortedItem: currentSortItem,
      hasInlineLoader: true,
      onEndReached: onFormsEndReached,
      isFetching: !!isFetchingMore,
    },
  };
}

export default function FormPanel({
  selectedAccountId,
  selectedFormId,
  setSelectedFromId,
  isHidden,
  showPanel,
}: {
  selectedAccountId: number;
  selectedFormId: number;
  setSelectedFromId: (id: number) => void;
  isHidden: boolean;
  showPanel: boolean;
}): ReactElement {
  const {
    totalFormsItems,
    isFetching,
    handleReset,
    handleKeywordSearch,
    keywordSearch,
    formTableProps,
  } = useFormsState({ accountId: selectedAccountId, showPanel: showPanel });
  const onFormRowClick = (row: Row<Forms>) => {
    setSelectedFromId(row?.original?.Id);
  };
  if (isHidden) return <></>;
  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text fontSize={1} color="text.body">
          {formTableProps.data && formTableProps.data.length > 0 && (
            <Text mb={2}>{`Showing ${
              formTableProps.data.length
            } of ${totalFormsItems}${isFetching ? ' | Processing' : ''}`}</Text>
          )}
        </Text>
        <Stack direction="x" gap={2}>
          <SearchInput
            handleChange={handleKeywordSearch}
            value={keywordSearch}
            placeholder="Search Forms"
          />
          <Button
            onClick={handleReset}
            iconBefore={<Icon.Refresh title="" ariaHidden="true" />}
            variant="secondary"
          >
            Reset All
          </Button>
        </Stack>
      </Flex>

      <Table
        {...formTableProps}
        height="450px"
        onRowClick={onFormRowClick}
        selectedRowId={selectedFormId}
        noOptions
      />
      {/* / Forms */}
    </>
  );
}
