import React, { ReactElement } from 'react';
import { Box } from 'rebass/styled-components';
import { FormInput } from 'ui/patterns';
import { Button, Stack, Tag, Cluster } from 'ui/components';
import * as Icon from 'assets/icons';
import { showInfoToast } from 'utils/showToast';

type LookupQuestionPropsType = {
  lookupFieldName: string | undefined;
  setLookupFieldName: (
    value: string | ((prevState: string | undefined) => string | undefined),
  ) => void;
  lookupFields: Array<string> | undefined;
  setLookupFields: (
    value:
      | Array<string>
      | undefined
      | ((prevState: Array<string> | undefined) => Array<string> | undefined),
  ) => void;
};

export default function LookupQuestion(
  LookupQuestionProps: LookupQuestionPropsType,
): ReactElement {
  const {
    lookupFieldName,
    lookupFields,
    setLookupFieldName,
    setLookupFields,
  } = LookupQuestionProps;

  function addLookupFieldColumn() {
    if (lookupFieldName) {
      const index = lookupFields?.findIndex(item => item === lookupFieldName);
      if (index === -1 && lookupFields) {
        setLookupFields([...lookupFields, lookupFieldName]);
        setLookupFieldName('');
      } else {
        showInfoToast({
          message: `The column name '${lookupFieldName}' already exists `,
        });
      }
    }
  }

  function removeLookupFieldName(fieldName: string) {
    const index =
      lookupFields && lookupFields?.length > 0
        ? lookupFields?.findIndex(item => item === fieldName)
        : -1;

    if (index !== -1 && lookupFields) {
      setLookupFields([
        ...lookupFields.slice(0, index),
        ...lookupFields.slice(index + 1),
      ]);
    }
  }

  function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      addLookupFieldColumn();
    }
  }
  return (
    <Stack direction="y" gap={3} mt={6}>
      <Box
        sx={{
          display: 'grid',
          gridGap: 3,
          gridTemplateColumns: '1fr minmax(50px, 15%)',
          alignItems: 'end',
        }}
      >
        <FormInput
          label="Standard Field Names"
          type="text"
          onTextChange={setLookupFieldName}
          value={lookupFieldName}
          onKeyPress={handleKeyPress}
        />
        <Button
          variant="primary"
          style={{ height: '35px' }}
          iconBefore={<Icon.Add />}
          disabled={lookupFieldName ? false : true}
          onClick={addLookupFieldColumn}
        />
      </Box>
      <Cluster gap={3}>
        <Box>
          {lookupFields?.map((item, index) => (
            <Tag
              width="auto"
              notTruncate
              onClose={() => {
                removeLookupFieldName(item);
              }}
              key={`${item}${index}`}
            >
              {item}
            </Tag>
          ))}
        </Box>
      </Cluster>
    </Stack>
  );
}
