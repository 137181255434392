import { useQuery, QueryResult } from 'react-query';
import { editQuestionInRepository as getQuestionDetail } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { AddEditQuestionType } from 'types/questions';

async function fetchQuestionDetail({
  questionId,
}: TVariables): Promise<TResult> {
  const response = await fetch(getQuestionDetail(questionId), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = AddEditQuestionType | undefined;

export type TError = { message: string };
export type TVariables = {
  questionId: number;
};

function useGetQuestionDetail(
  questionId: number,
): QueryResult<TResult, TError> {
  return useQuery(
    ['get_question_detail', questionId],
    async () => {
      if (questionId)
        return await fetchQuestionDetail({
          questionId,
        });
    },
    {
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetQuestionDetail };
