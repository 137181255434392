import React, {
  ReactElement,
  useEffect,
  useState,
  SyntheticEvent,
} from 'react';
import { Flex, Text, Box } from 'rebass/styled-components';
import {
  Stack,
  RadioButton,
  TinyMCE,
  Dropzone,
  StyledSelect,
} from 'ui/components';
import {
  useCreateFolder,
  useDeleteFile,
  useGetProcedures,
  useUploadExternalDocument,
  useUploadFile,
} from 'hooks';
import { QuestionDetailsType } from '../questionTypes';
import FileImage from 'containers/ProcedureBuilder/FileImage';
import { Loader } from 'ui/patterns';
import { apiBaseUrl } from 'utils/urls';
import { BlobFileEntity } from 'types/fileTypes';
import { showErrorToast } from 'utils/showToast';

export default function QuestionDetails(
  QuestionDetailsProps: QuestionDetailsType,
): ReactElement {
  const {
    selectedQuestionType,
    control,
    Controller,
    watch,
    setValue,
    accountId,
    accountInfoReturn,
  } = QuestionDetailsProps;
  const [progress, setProgress] = useState(0);
  // const [_, setExternalFile] = useState<string | undefined>();

  const { data: procedures } = useGetProcedures(accountId);

  const [uploadExternalDocument, { isLoading: isDocumentUploadLoading }] =
    useUploadExternalDocument(setProgress);

  const questionDetailType = watch('QuestionDetailType');
  const helpText = watch('HelpText');

  useEffect(() => {
    if (selectedQuestionType?.QuestionDetail?.Type === questionDetailType)
      setValue(
        'QuestionDetailType',
        selectedQuestionType?.QuestionDetail?.Type,
        {
          shouldDirty: false,
        },
      );
  }, [selectedQuestionType, questionDetailType, setValue]);

  const [showFileImage, setShowFileImage] = useState(false);
  function toggleFileImage() {
    setShowFileImage(showFileImage => !showFileImage);
  }
  const [createFolder, { isLoading: isCreateFolderLoading }] =
    useCreateFolder();
  const [uploadFile, { isLoading: isuploadFileLoading }] = useUploadFile();
  const [deleteFile, { isLoading: isDeleteFileLoading }] = useDeleteFile();
  const isLoadingFile =
    isCreateFolderLoading || isuploadFileLoading || isDeleteFileLoading;

  return (
    <>
      <Flex as="form" flexDirection="column" height="100%">
        {selectedQuestionType &&
        Object.keys(selectedQuestionType).length !== 0 ? (
          <Box>
            <Stack direction="x" gap={4} mb={4}>
              <Controller
                render={(props: {
                  onChange: (value: number) => void;
                  value: number;
                }) => {
                  return (
                    <RadioButton
                      onChange={() => {
                        props.onChange(0);
                        console.log('clickedd');
                      }}
                      inline
                      label="Text"
                      name="QuestionDetailType"
                      checked={questionDetailType === 0}
                    />
                  );
                }}
                defaultValue={0}
                name="QuestionDetailType"
                control={control}
              />

              <Controller
                render={(props: {
                  onChange: (value: number) => void;
                  value: number;
                }) => {
                  return (
                    <RadioButton
                      onChange={() => {
                        props.onChange(1);
                      }}
                      inline
                      label="Procedure"
                      name="QuestionDetailType"
                      checked={questionDetailType === 1}
                    />
                  );
                }}
                defaultValue={0}
                name="QuestionDetailType"
                control={control}
              />
              <Controller
                render={(props: {
                  onChange: (value: number) => void;
                  value: number;
                }) => {
                  return (
                    <RadioButton
                      onChange={() => {
                        props.onChange(2);
                      }}
                      inline
                      label="External Document"
                      name="QuestionDetailType"
                      checked={questionDetailType === 2}
                    />
                  );
                }}
                defaultValue={0}
                name="QuestionDetailType"
                control={control}
              />
            </Stack>

            <Controller
              render={(props: {
                onChange: (value: any) => void;
                value: any;
              }) => {
                if (questionDetailType !== 0) return null;
                return (
                  <TinyMCE
                    toggleFileImage={toggleFileImage}
                    height={240}
                    initialValue={helpText}
                    value={props.value}
                    name="HelpText"
                    onChange={(content: string) => {
                      console.log('content', content);
                      props.onChange(content);
                    }}
                  />
                );
              }}
              defaultValue={''}
              name="HelpText"
              control={control}
            />
            {/* )} */}
            {/* {questionDetailType === 1 && ( */}
            <Box maxWidth="305px">
              <Controller
                render={(props: {
                  onChange: (value: string) => void;
                  value: any;
                }) => {
                  if (questionDetailType !== 1) return null;
                  return (
                    <StyledSelect
                      onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                        props.onChange(e.currentTarget.value);
                      }}
                      value={props.value}
                    >
                      <option value="" selected>
                        Select one
                      </option>
                      {procedures &&
                        procedures.map(item => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                    </StyledSelect>
                  );
                }}
                defaultValue={''}
                name="ProcedureUri"
                control={control}
              />
            </Box>
            {/* )} */}

            {/* {questionDetailType === 2 && ( */}
            <Controller
              render={(props: {
                onChange: (value?: string) => void;
                value: string;
              }) => {
                if (questionDetailType !== 2) return null;
                return (
                  <Dropzone
                    info="Please ensure this file type is associated with a program in the Citrix environment"
                    progress={progress}
                    value={props.value}
                    isLoading={isDocumentUploadLoading}
                    accountId={accountId}
                    onChange={(file?: File) => {
                      if (!file) props.onChange();
                      if (file && accountInfoReturn) {
                        const fileExtension = file?.name.split('.');
                        const validExtensions = [
                          'jpg',
                          'jpeg',
                          'png',
                          'svg',
                          'txt',
                          'docx',
                          'doc',
                          'pdf',
                          'html',
                          'htm',
                        ];
                        const checkCondtion = validExtensions.includes(
                          fileExtension[1],
                        );
                        if (checkCondtion) {
                          uploadExternalDocument(
                            {
                              account: `${accountInfoReturn.CID}${accountInfoReturn.SID}${accountInfoReturn.PID}`,
                              file,
                            },
                            {
                              onSuccess: filename => {
                                props.onChange(filename);
                              },
                              onError: () => {
                                showErrorToast({
                                  message:
                                    'Files of this extension cannot be uploaded',
                                });
                              },
                            },
                          );
                        } else {
                          showErrorToast({
                            message:
                              'Files of this extension cannot be uploaded',
                          });
                        }
                      } else if (file) {
                        const reader = new FileReader();
                        reader.onabort = () =>
                          console.log('file reading was aborted');
                        reader.onerror = () =>
                          console.log('file reading has failed');
                        reader.onload = () => {
                          const base64String = reader.result;
                          if (typeof base64String === 'string') {
                            props.onChange(file.name);
                            // setExternalFile(base64String);
                          }
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                  />
                );
              }}
              defaultValue={''}
              name="ExternalDocumentUri"
              control={control}
            />
            {/* )} */}
          </Box>
        ) : (
          <Flex alignItems="center" justifyContent="center" height="100%">
            <Text fontSize={4}>
              Please select a question to add the details.
            </Text>
          </Flex>
        )}
      </Flex>
      <FileImage
        filesOnly
        account={`${accountInfoReturn?.CID}${'-'}${
          accountInfoReturn?.SID
        }${'-'}${accountInfoReturn?.PID}`}
        showPanel={showFileImage}
        togglePanel={toggleFileImage}
        createFolder={createFolder}
        uploadFile={uploadFile}
        deleteFile={deleteFile}
        buttonText={'Add to to question details'}
        addFileToEditor={(file: BlobFileEntity) => {
          if (file.FileType !== 'image') {
            const hrefTag =
              "<a href='" +
              `${apiBaseUrl}/procedures/root/${file.FilePath}` +
              "/'>" +
              file.FileName +
              '</a>';
            console.log('hrefTag:', hrefTag);
            (window as any)?.tinymce?.activeEditor?.insertContent(hrefTag);
          } else {
            const hrefTag = `<img src="${file.FileUri}" />`;
            (window as any)?.tinymce?.activeEditor?.insertContent(hrefTag);
          }
        }}
      />
      {isLoadingFile ? <Loader /> : null}
    </>
  );
}
