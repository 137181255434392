import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Loader } from 'ui/patterns';
import { confirmAlert } from 'utils/confirm-alert';
import {
  AUTH_TOKEN,
  LOGIN_TYPE,
  LOGIN_TYPES,
  AUTH_EXPIRY_VISIBLE,
} from 'utils/authenticationHelper/authConstant';
import { fetchUtcTime } from 'utils/getUtctime';
import { systemClockMsg } from 'utils/appConstants';
import { AuthServiceSingleton } from 'utils/authenticationHelper/authServices';

export const Callback = () => {
  const history = useHistory();
  const [tagUpdateModal, setTagUpdateModal] = useState(false);

  useEffect(() => {
    async function checkLogin() {
      try {
        const loginType = localStorage.getItem(LOGIN_TYPE);
        const user = await AuthServiceSingleton.getInstance().getUser();
        localStorage.setItem(AUTH_TOKEN, JSON.stringify(user));
        if (loginType === LOGIN_TYPES.REFRESH_LOGIN) {
          const win = window.open('about:blank', '_self');
          sessionStorage.removeItem(AUTH_EXPIRY_VISIBLE);
          win?.close();
        } else {
          const lastKnownLocation = localStorage.getItem('LastKnownLocation');
          history.push(lastKnownLocation ?? '/');
        }
      } catch (e) {
        const currentTime = await fetchUtcTime();
        if (new Date(currentTime) !== new Date()) {
          setTagUpdateModal(true);
        }
      }
    }
    checkLogin();
  }, [history]);

  return (
    <>
      {tagUpdateModal ? (
        confirmAlert({
          message: systemClockMsg,
          buttons: [
            {
              label: 'Ok',
              onClick: () => {
                window.localStorage.clear();
                AuthServiceSingleton.getInstance().logout();
              },
              className: 'pr-btn-primary',
            },
          ],
        })
      ) : (
        <Loader />
      )}
    </>
  );
};
