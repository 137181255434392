import { useInfiniteQuery, InfiniteQueryResult, queryCache } from 'react-query';
import {
  AccountListForEmbedProcedure,
  AccountListForEmbedProcedureForImport,
} from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { Account } from 'types/accounts';
const pageLength = 20;

async function fetchAccountListForEmbedProcedure({
  keyword,
  start,
  pageLength,
  accountId,
  isImport,
}: TVariables): Promise<TResult> {
  const response = await fetch(
    isImport
      ? AccountListForEmbedProcedureForImport(
          accountId,
          start,
          pageLength,
          keyword,
        )
      : AccountListForEmbedProcedure(accountId, start, pageLength, keyword),
    {
      method: 'GET',
      headers: {
        Authorization: getTokenWithType(),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return response.json();
}

export type TResult = Array<Account>;

export type TError = { message: string };
export type TVariables = {
  accountId: number;
  start: number;
  pageLength: number;
  keyword: string;
  isImport?: boolean;
};

function useGetAccountListForEmbedProcedures(
  accountId: number,
  keyword: string,
  isImport?: boolean,
): InfiniteQueryResult<TResult, TError> {
  const data: Array<TResult> | undefined = queryCache.getQueryData([
    'getAccountForEmbedProcedures',
    accountId,
    keyword,
  ]);

  const start =
    data && data[data.length - 1].length === pageLength
      ? data.length * pageLength
      : 0;
  return useInfiniteQuery(
    ['getAccountForEmbedProcedures', accountId, keyword],
    () => {
      return fetchAccountListForEmbedProcedure({
        accountId,
        keyword,
        start,
        pageLength,
        isImport,
      });
    },
    {
      getFetchMore: () => {
        return true;
      },
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetAccountListForEmbedProcedures };
