import styled from 'styled-components';

const StyledPrimaryNav = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  & > * {
    margin-left: ${({ theme }) => theme.space[2]};
  }

  & > li > a {
    --bg: transparent;
    --color: ${({ theme }) => theme.colors.text.body};

    display: inline-block;
    text-decoration: none;
    padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
    color: var(--color);
    font-size: ${({ theme }) => theme.fontSizes[2]};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: 1;
    border-radius: ${({ theme }) => theme.radii.circle};
    transition: background 200ms ease-in-out;
    background: var(--bg);

    &:hover {
      --color: ${({ theme }) => theme.colors.gray900};
    }

    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 3px
        ${({ theme }) => theme.colors.transparent.black50};
    }

    &.active,
    &.active:hover {
      --bg: var(--primary-color);
      --color: var(--color-on-primary);
    }
  }

  /* --space: 1rem;
  overflow: hidden;

  & > * {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: calc(var(--space) / 2 * -1);
  }

  & > * > * {
    margin: calc(var(--space) / 2);
  } */
`;

export default StyledPrimaryNav;
