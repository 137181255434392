// import { useHistory } from 'react-router-dom';
import { useMutation, MutationResultPair, queryCache } from 'react-query';
// import { useDispatch } from 'store/authContext';
// import { AccountFormInputs } from 'types/accounts';
import { customTableCsv as customTableCsvUpload } from 'utils/urls';
import { getTokenWithType } from './useAuth';

export type TResult = string;
export type TVariables = {
  account: number;
  file: File;
  lookupId: number;
  isForced?: boolean;
};
export type TError = {
  Message: string;
};
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;

function useUploadCustomTable(
  setProgress?: (progress: number) => void,
): MutationResultPair<TResult, TError, TVariables, TSnapshot> {
  // const [progress, setProgress] = useState(0);

  function updateProgress(evt: any) {
    // console.log('evt,evt', evt);
    if (evt.lengthComputable) {
      // evt.loaded the bytes the browser received
      // evt.total the total bytes set by the header
      // jQuery UI progress bar to show the progress on screen
      const percentComplete = (evt.loaded / evt.total) * 100;
      setProgress && setProgress(percentComplete);
    }
  }

  return useMutation(
    async ({ account, lookupId, file, isForced }: TVariables) => {
      // await postAccountInfo({
      //   ...params,
      //   token_type,
      //   token,
      // });
      try {
        await new Promise((res, rej) => {
          const formData: FormData = new FormData();
          formData.append('file', file);
          const xhr = new XMLHttpRequest();
          xhr.open(
            'POST',
            `${customTableCsvUpload(account, lookupId)}${
              isForced ? '?force=true' : ''
            }`,
          );
          // for (var k in opts.headers || {})
          xhr.setRequestHeader('Authorization', getTokenWithType());
          // xhr.setRequestHeader('content-type', 'multipart/form-data');
          xhr.onreadystatechange = function (aEvt: any) {
            if (xhr.readyState === 4) {
              if (
                aEvt.currentTarget.status >= 200 &&
                aEvt.currentTarget.status <= 210
              ) {
                res(aEvt.currentTarget.response);
              } else {
                rej(JSON.parse(aEvt.currentTarget.response));
              }
            }
          };
          // req.send();
          xhr.onprogress = updateProgress;
          xhr.upload.addEventListener('progress', updateProgress);
          // xhr.onload = e => e.target.responseText;
          xhr.onerror = e => rej(e);
          // if (xhr.upload) xhr.upload.onprogress = setProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
          xhr.send(formData);
        });
      } catch (e) {
        console.log('EEEEEE', e);
        throw e;
      }
      queryCache.invalidateQueries(['custom_table'], {
        refetchInactive: true,
      });
      queryCache.invalidateQueries(['custom_records'], {
        refetchInactive: true,
      });
      return `Success`;
    },
    {
      throwOnError: true,
    },
  );
}

export { useUploadCustomTable };
