import theme from './theme';

const fonts = { ...theme.fonts };
const fontSizes = [...theme.fontSizes];
const fontWeights = { ...theme.fontWeights };
const space = [...theme.space];
const radii = { ...theme.radii };
const shadows = { ...theme.shadows };
const zIndices = [...theme.zIndices];
const baseColors = { ...theme.baseColors };
const colors = {
  ...theme.colors,
  primary: '#02A570',
};

export default {
  fonts,
  fontSizes,
  fontWeights,
  space,
  baseColors,
  colors,
  radii,
  shadows,
  zIndices,
};
