import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { forceRenameProcedureUrl } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { ProcedureListType } from 'types/procedureBuilder';

export async function postForceRenameProcedure({
  params,
}: TArguments): Promise<TResult> {
  const response = await fetch(
    forceRenameProcedureUrl(params.AccountId, params.ProcedureId),
    {
      method: 'PUT',
      headers: {
        Authorization: getTokenWithType(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    },
  );
  if (response.status !== 200) {
    const errorText = await response.text();
    throw { message: errorText };
  }
  return response.json();
}

export type TResult = string;

export type TError = { message: string };
export type TArguments = {
  params: ProcedureListType;
};
export type TVariables = ProcedureListType;
export type TSnapshot = unknown;

function useForceRenameProcedure(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: ProcedureListType) => {
      const renamedResult = await postForceRenameProcedure({
        params: params,
      });

      if (renamedResult) {
        queryCache.invalidateQueries(
          ['get_procedure_builder_procedures', params.AccountId],
          {
            refetchInactive: true,
          },
        );
        queryCache.invalidateQueries(['get_procedure', params.AccountId], {
          refetchInactive: true,
        });
      }
      return renamedResult;
    },
    {
      throwOnError: true,
    },
  );
}

export { useForceRenameProcedure };
