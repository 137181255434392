import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { customTable as deleteCustomTable } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { showSuccessToast, showWarningToast } from 'utils/showToast';

async function deleteCustomTables({
  Id,
  accountId,
}: TArguments): Promise<Response> {
  return fetch(`${deleteCustomTable(accountId)}/${Id}`, {
    method: 'DELETE',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/json',
    },
  });
}

export type TResult = {
  Message: string;
  isSuccess: boolean;
};

export type TError = { Message: string };
export type TArguments = {
  Id: number;
  accountId: number;
};
export type TVariables = {
  Id: number;
  accountId: number;
};

function useDeleteCustomTable(): MutationResultPair<
  void,
  TError,
  TVariables,
  unknown
> {
  return useMutation(async (params: TVariables) => {
    const response = await deleteCustomTables({
      Id: params.Id,
      accountId: params.accountId,
    });
    if (response.status >= 200 && response.status <= 210) {
      queryCache.invalidateQueries('custom_table');
      showSuccessToast({
        message: 'Table deleted successfully.',
      });
    } else {
      const error = await response.json();
      if (error && error.Message)
        showWarningToast({
          message: error.Message,
        });
    }
  });
}

export { useDeleteCustomTable };
