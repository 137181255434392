import React, { ReactNode, ReactElement } from 'react';
import styled from 'styled-components';
import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
} from 'styled-system';

const StyledIconWrapper = styled.span`
  display: inline-flex;
  align-self: center;

  ${space}
  ${layout}
  ${position}

  svg:not(.icon-outline) {
    height: 20px;
    width: 20px;
    fill: currentColor;
  }

  svg.large {
    height: 24px;
    width: 24px;
  }

  svg.pr-icon-fa {
    position: relative;
    top: 0.185em;
    height: 1em;
    width: 1em;
    fill: currentColor;
  }

  &.filtered {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -2px;
      right: 0;
      width: 10px;
      height: 10px;
      background: var(--color-state-error);
      border: solid 1px #fff;
      border-radius: 50%;
      z-index: 1;
    }
  }
`;

type ssProps = SpaceProps & LayoutProps & PositionProps;

type IconWrapperProps = ssProps & {
  children: ReactNode;
  className?: string;
};

function IconWrapper({
  children,
  className,
  ...ssProps
}: IconWrapperProps): ReactElement {
  return (
    <StyledIconWrapper className={`pr-icon ${className}`} {...ssProps}>
      {children}
    </StyledIconWrapper>
  );
}

export { IconWrapper };
