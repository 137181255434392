import React, {
  ReactElement,
  useState,
  SyntheticEvent,
  useEffect,
} from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { confirmAlert } from 'utils/confirm-alert';
import { Table, Card, FormInput, FormSelect, Loader } from 'ui/patterns';
import { Button, Stack } from 'ui/components';
import * as Icon from 'assets/icons';
import { useGetXmlTags, useGetTagMappings, useSaveTagMappings } from 'hooks';
import useXmlTagColumns from './useXmlTagColumns';
import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
} from 'utils/showToast';
import usePrompt from 'hooks/usePrompt';

export default function XMLTagMapping(): ReactElement {
  const [isPageDirty, setDirty] = useState<boolean>();
  const history = useHistory<{ ID: number }>();
  const { accountId } = useParams<{
    accountId: string;
    tabId?: string;
  }>();
  const { data: xmlTags } = useGetXmlTags();
  const { data, isLoading, isFetching } = useGetTagMappings(
    parseInt(accountId),
  );
  const [saveTagMapping, { isLoading: isSaveLoading }] = useSaveTagMappings();
  const [fieldId, setFieldId] = useState<number>(0);
  const [mappedName, setMappedName] = useState<string>('');
  const [tagMapping, setTagMapping] = useState<Array<any>>([]);

  useEffect(() => {
    if (data && data?.length > 0) setTagMapping([...data]);
  }, [data]);

  const { handleSubmit } = useForm();

  function addTagMapping() {
    setDirty(true);
    if (fieldId && mappedName && mappedName.trim() !== '') {
      const field = xmlTags && xmlTags.find(item => item.id === fieldId);
      const index = tagMapping.findIndex(
        item => item.FieldName === field?.name,
      );
      if (index === -1) {
        setTagMapping([
          ...tagMapping,
          {
            FieldName: field?.name,
            MappedName: mappedName.trim(),
            AccountId: accountId,
            Id: 0,
          },
        ]);
        setFieldId(0);
        setMappedName('');
      } else {
        showInfoToast({
          message: 'Each field name can be mapped only once.',
        });
        setFieldId(0);
        setMappedName('');
      }
    } else {
      showInfoToast({
        message:
          'Please select the field name and enter the mapped name to continue.',
      });
    }
  }

  function removeTagMapping(fieldName: string) {
    const index = tagMapping.findIndex(item => item.FieldName === fieldName);
    if (index !== -1) {
      confirmAlert({
        message: 'Are you sure you want to delete this mapped tag?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              setDirty(true);
              setTagMapping([
                ...tagMapping.slice(0, index),
                ...tagMapping.slice(index + 1),
              ]);
            },
            className: 'pr-btn-primary',
          },
          {
            label: 'No',
            onClick: () => {
              //
            },
          },
        ],
      });
    }
  }

  function resetForm(): void {
    setMappedName('');
    setTagMapping([]);
  }

  function onsubmit() {
    setDirty(false);
    saveTagMapping(
      {
        AccountId: parseInt(accountId),
        TagMapping: [...tagMapping],
      },
      {
        onSuccess: () => {
          showSuccessToast({
            message: 'Your changes were successfully saved.',
            autoClose: 3000,
          });
          resetForm();
        },
        onError: error => {
          showErrorToast({
            message: error.Message,
          });
        },
      },
    );
  }

  const columns = useXmlTagColumns(undefined, removeTagMapping);
  usePrompt(
    isPageDirty,
    'Are you sure you want to leave? Changes that you made may not be saved.',
  );
  return (
    <>
      <Flex as="form" flexDirection="column" height="100%">
        <Text fontSize={2} color="text.body" mb={3}>
          Add Tag Mapping
        </Text>
        <Card p={3} mb={5} pb={4}>
          <Box
            sx={{
              display: 'grid',
              gridGap: 3,
              gridTemplateColumns: '1fr 1fr minmax(40px, 5%)',
              alignItems: 'end',
              maxWidth: '900px',
            }}
          >
            <FormSelect
              label="Field Name"
              options={xmlTags}
              onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                setFieldId(parseInt(e.currentTarget.value, 10));
              }}
              value={fieldId}
            />
            <FormInput
              type="text"
              label="Mapped Name"
              value={mappedName || ''}
              onTextChange={setMappedName}
            />
            <Button
              variant="primary"
              style={{ height: '34px' }}
              iconBefore={<Icon.Add />}
              onClick={addTagMapping}
            />
          </Box>
        </Card>

        <Text fontSize={2} color="text.body" mb={3}>
          Mapped Tag
        </Text>

        <Table
          columns={columns}
          data={tagMapping}
          isLoading={isLoading || isFetching}
        />

        <Flex justifyContent="flex-end" mt={3}>
          <Stack direction="x" gap={2}>
            <Button
              variant="secondary"
              onClick={() => {
                history.push('/home/accounts');
                resetForm();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={
                isSaveLoading ||
                (data && data.length <= 0 && tagMapping.length <= 0)
              }
              onClick={handleSubmit(onsubmit)}
            >
              Save
            </Button>
          </Stack>
        </Flex>
        {isSaveLoading ? <Loader /> : null}
      </Flex>
    </>
  );
}
