import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { restoreVersions } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { ProcedureInfoType } from 'types/procedureBuilder';

export async function updateProcedureVersion({
  accountId,
  fileId,
  version,
}: TArguments): Promise<TResult> {
  const response = await fetch(restoreVersions(accountId, fileId, version), {
    method: 'PUT',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/json',
    },
  });
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = ProcedureInfoType & ValidationError;

export type TError = { Message: string };
export type TArguments = {
  fileId: string;
  version: number;
  accountId: number;
};
export type TVariables = {
  fileId: string;
  version: number;
  accountId: number;
};
export type TSnapshot = unknown;

function useRestoreVersions(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async ({ fileId, version, accountId }: TVariables) => {
      const restoredVersion = await updateProcedureVersion({
        accountId,
        fileId,
        version,
      });
      if (restoredVersion.Message) throw restoredVersion;
      if (restoredVersion) {
        queryCache.invalidateQueries(
          ['get_procedure_builder_procedures', accountId],
          {
            refetchInactive: true,
          },
        );
        queryCache.invalidateQueries(['get_procedure_version_history'], {
          refetchInactive: true,
        });
        queryCache.invalidateQueries(['get_procedure'], {
          refetchInactive: true,
        });
      }
      return restoredVersion;
    },
    {
      throwOnError: true,
    },
  );
}

export { useRestoreVersions };
