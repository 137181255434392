import styled, { css } from 'styled-components';
import { ButtonReset } from 'ui/utils/buttonReset';

type BackdropProps = {
  show?: boolean;
};

const Backdrop = styled.div<BackdropProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(100%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: ${({ theme }) => theme.space[3]};
  background-color: var(--color-neutral-contrast-01);
  will-change: transform;
  transition: transform 300ms cubic-bezier(0.55, 0.06, 0.68, 0.19);
  transition-delay: 100ms;

  ${({ show }) =>
    show &&
    css`
      transform: translateX(0);
      transition: transform 300ms cubic-bezier(0.22, 0.61, 0.36, 1);
      transition-delay: 0ms;
    `}
`;

const CloseButton = styled.button`
  ${ButtonReset}
  position: absolute;
  top: 21px;
  right: 17px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text};
`;

export { Backdrop, CloseButton };
