import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { createProcedure as createProcedureURL } from 'utils/urls';
import { getTokenWithType } from './useAuth';

export async function createProcedure({
  accountId,
}: {
  accountId: number;
}): Promise<TResult> {
  const response = await fetch(createProcedureURL(accountId), {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'content-type': 'application/json',
    },
    //   body: JSON.stringify(params),
  });
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = any & ValidationError;
export type TVariables = number;
export type TError = {
  Message: string;
};
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;
// async function wait(millis: number) {
//   return new Promise(resolve => {
//     setTimeout(resolve, millis);
//   });
// }

function useCreateProcedure(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (accountId: number) => {
      const accountProcedure = await createProcedure({
        accountId,
      });
      if (accountProcedure.Message) throw accountProcedure;
      if (accountProcedure) {
        queryCache.invalidateQueries([
          'get_procedure_builder_procedures',
          accountId,
        ]);
        queryCache.invalidateQueries(['get_procedure'], {
          refetchInactive: true,
        });
        // queryCache.invalidateQueries(['forms_list'], {
        //   refetchInactive: true,
        // });
      }
      // await wait(2000);
      return accountProcedure;
    },
    {
      throwOnError: true,
    },
  );
}

export { useCreateProcedure };
