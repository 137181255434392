import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { AuthServiceSingleton } from 'utils/authenticationHelper/authServices';
import { SILENT_SIGN_IN_LOADING } from './authConstant';
import { showTokenExpiryConfirmDialog } from './showTokenConfirmation';

const silentRenewTime = 5 * 60 * 1000; // silent renew token in every 5 min

export function useRenewTimer() {
  const activeTimeRef = useRef<number | undefined>();

  useEffect(() => {
    const setLastActiveTime = () => {
      const token = localStorage.getItem('AUTH_TOKEN') || '';
      const expiryTime = JSON.parse(token)?.expires_at * 1000;
      if (expiryTime < Date.now()) {
        showTokenExpiryConfirmDialog();
        return;
      }
      if (
        activeTimeRef.current &&
        Date.now() - activeTimeRef.current > 5 * 60 * 1000
      ) {
        triggerSilentSign();
      }
      activeTimeRef.current = Date.now();
    };

    const debounceStorageUpdate = debounce(setLastActiveTime, 1000, {
      trailing: true,
    });

    function trackLastActivity() {
      window.onload = debounceStorageUpdate;
      window.onmousemove = debounceStorageUpdate;
      window.onmousedown = debounceStorageUpdate;
      window.ontouchstart = debounceStorageUpdate;
      window.ontouchmove = debounceStorageUpdate;
      window.onclick = debounceStorageUpdate;
      window.onkeydown = debounceStorageUpdate;
      window.addEventListener('scroll', debounceStorageUpdate, true);
    }
    trackLastActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const lastActiveTime = activeTimeRef.current;
      if (!lastActiveTime || Date.now() - lastActiveTime < 5 * 60 * 1000)
        triggerSilentSign();
    }, silentRenewTime);
    return () => clearInterval(interval);
  }, []);
}

function triggerSilentSign() {
  localStorage.setItem(SILENT_SIGN_IN_LOADING, 'TRUE');
  AuthServiceSingleton.getInstance().signinSilent();
  setTimeout(() => {
    localStorage.removeItem(SILENT_SIGN_IN_LOADING);
  }, 3 * 60 * 1000);
}
