import { useQuery, QueryResult } from 'react-query';
import { getRoutingSubGroup } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchRoutingSubGroup(): Promise<ApiResult> {
  const response = await fetch(getRoutingSubGroup, {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = Array<{
  label: string;
  value: number;
  description: string;
}>;

export type ApiResult = Array<{
  Description: string;
  Id: number;
  Name: string;
}>;

export type TError = { message: string };

function useGetRoutingSubGroup(): QueryResult<TResult, TError> {
  return useQuery(
    'get_routing_group',
    async () => {
      const fetchResponse = await fetchRoutingSubGroup();
      const result = fetchResponse.map(item => ({
        value: item.Id,
        label: `${item.Name}`,
        description: `${item.Description}`,
      }));
      result.unshift({ value: -1, label: 'Select one', description: '' });
      return result;
    },

    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetRoutingSubGroup };
