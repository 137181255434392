import styled, { css } from 'styled-components';

type FormLabelProps = {
  required?: boolean;
  htmlFor?: string;
  disabled?: boolean;
};

const FormLabel = styled.label<FormLabelProps>`
  color: ${({ theme }) => theme.colors.text.body};
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.space[1]};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 1;

  ${({ required }) =>
    required &&
    css`
      &::after {
        content: '*';
        display: inline-block;
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        margin-left: ${({ theme }) => theme.space[1]};
        color: ${({ theme }) => theme.colors.state.error};
      }
    `}/* ${({ disabled }) =>
    disabled &&
    css`
      color: var(--color-neutral-contrast-04);
    `} */
`;

export { FormLabel };
