import React, {
  ReactNode,
  ReactElement,
  useState,
  useRef,
  SyntheticEvent,
  useCallback,
  useEffect,
} from 'react';
import styled from 'styled-components';
// import useClickOutside from 'hooks/useClickoutside';
import DropdownButton from './DropdownButton';
import DropdownMenu from './DropdownMenu';
import useOnClickOutside from 'use-onclickoutside';

const StyledDropdown = styled.div`
  position: relative;
  display: inline-block;

  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.colors.gray[1]};
  }

  /* Column selection dropdown */
  &.dd-col-selection {
    summary {
      background: none;
      border: none;
      padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[1]};
      line-height: 1.2;
      box-shadow: none;
      display: flex;
      align-items: center;

      &:focus,
      &:active {
        outline: none;
        background: ${({ theme }) => theme.colors.bg.grayDark};
        color: #fff;
      }
    }
  }

  /* List item options dropdown */
  &.dd-listitem-options {
    summary {
      padding: 0;

      button {
        padding: ${({ theme }) => theme.space[1]};
      }
    }
  }

  &.dd-procedure-filter {
    summary {
      padding: 0;

      & + * {
        width: 280px;
        max-width: 280px;
        padding: 0 !important;
      }

      button {
        padding: ${({ theme }) => theme.space[1]};
      }
    }
  }
`;

export type DropdownProps = {
  trigger?: string | ReactNode;
  menuList?: Array<item>;
  menuContent?: ReactNode;
  className?: any;
  autoclose?: boolean;
  position?: 'right-top' | 'right' | 'left-top' | 'left';
  ref?: React.RefObject<HTMLDivElement>;
  onClick?: (e: any) => void;
  children?: ReactNode;
};

type item = {
  id?: number;
  value: string;
  action?: () => void;
  icon?: ReactNode;
  groupEnd?: boolean;
};

function Dropdown({
  trigger,
  menuList,
  menuContent,
  className,
  autoclose = true,
  position,
  ref,
  onClick,
  children,
}: DropdownProps): ReactElement {
  const [open, setOpen] = useState(false);
  const handleOnClickOutside = () => {
    // console.log('outside');
    setOpen(false);
  };
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(wrapperRef, handleOnClickOutside);
  const handleToggle = (e: SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpen(autoclose === true ? !open : true);
  };

  const handleKeyDown = useCallback(
    (e: any) => {
      if (open && e.code === 'Escape') setOpen(false);
    },
    [open],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <StyledDropdown
      ref={wrapperRef}
      onClick={handleToggle}
      className={className}
    >
      <DropdownButton>{trigger}</DropdownButton>
      {open && (
        <DropdownMenu position={position}>
          {menuList ? (
            <ul data-testid="header-profile-list" className="dropdownMenuList">
              {menuList.map((item, index) => (
                <li key={index}>
                  <button
                    data-testid={`button${item.id}`}
                    type="button"
                    onClick={() => {
                      // console.log('HELLOOOO');
                      setOpen(false);
                      item.action && item.action();
                    }}
                  >
                    {item.icon ? item.icon : null}
                    <span className="pr-button-label">{item.value}</span>
                  </button>
                  {item.groupEnd ? (
                    <div className="dd-divider" aria-hidden="true"></div>
                  ) : null}
                </li>
              ))}
            </ul>
          ) : (
            null || menuContent || children
          )}
        </DropdownMenu>
      )}
    </StyledDropdown>
  );
}

export { Dropdown, DropdownButton, DropdownMenu, StyledDropdown };
