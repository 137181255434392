import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { Text } from 'rebass/styled-components';
import { Spinner } from '../../components';

type StyledLoaderProps = {
  noBg?: boolean;
};

const StyledLoader = styled.div<StyledLoaderProps>`
  --bg: ${({ theme }) => theme.colors.transparent.white50};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: ${({ noBg }) => (noBg ? 'fixed' : 'absolute')};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background: ${({ noBg }) => (noBg ? 'transparent' : 'var(--bg)')};
  -webkit-backdrop-filter: ${({ noBg }) => (noBg ? 'blur(5px)' : 'blur(5px)')};
  backdrop-filter: ${({ noBg }) => (noBg ? 'blur(0px)' : 'blur(5px)')};
  z-index: 1000000;

  html[data-color-mode='dark'] & {
    --bg: ${({ theme }) => theme.colors.transparent.black50};
  }
`;

type LoaderProps = {
  noBg?: boolean;
  text?: string;
};

function Loader({ noBg, text }: LoaderProps): ReactElement {
  return (
    <StyledLoader className="pr-loader" noBg={noBg}>
      <Spinner className="pr-spinner"></Spinner>
      {text && <Text mt={2}>{text}</Text>}
    </StyledLoader>
  );
}

export { Loader };
