import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { updateFingerPrint } from '../utils/urls';
import { getTokenWithType } from './useAuth';

export async function resetFingerPrint(accountId: number): Promise<TResult> {
  const response = await fetch(updateFingerPrint(accountId), {
    method: 'PUT',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/json',
    },
  });
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = any & ValidationError;

export type TError = Array<string>;

export type TVariables = {
  accountId: number;
};
export type TSnapshot = unknown;

function useUpdateFingerPrint(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async ({ accountId }: TVariables) => {
      const postResult = await resetFingerPrint(accountId);
      if (postResult && postResult instanceof Array) throw postResult;
      else queryCache.invalidateQueries(['get_ftp_settings', accountId]);
      return postResult;
    },
    {
      throwOnError: true,
    },
  );
}

export { useUpdateFingerPrint };
