import React, { ReactElement } from 'react';
import { ColumnInstance } from 'react-table';
import { GlobalSurveyType } from 'types/forms';
import { FloatLabelInput, ButtonGroup } from 'ui/patterns';
import { Flex } from 'rebass/styled-components';
import Icon from 'assets/icons';

type SortItemType = {
  sortKey: string;
  sortOrder: string;
};

export default function TableHeader(
  props: {
    column: ColumnInstance<GlobalSurveyType>;
  } & {
    headerUpdateCallBack?: ({
      columnId,
      value,
    }: {
      columnId: string;
      value: string;
    }) => void;
    handleSortChange?: (item: string) => void;
    sortedItem?: { sortKey: string; sortOrder: string };
    columnsSearch?: Record<string, string>;
    multiSortedItem?: SortItemType | Array<SortItemType>;
  },
): ReactElement {
  const onChangeText = (value: string) => {
    props.headerUpdateCallBack &&
      props.headerUpdateCallBack({ columnId: props.column.id, value });
  };

  let sortedItem;
  if (props.multiSortedItem instanceof Array) {
    sortedItem = props.multiSortedItem.find(
      mItem => mItem.sortKey === props.column.id,
    );
  } else {
    sortedItem = props.multiSortedItem;
  }

  if (props.sortedItem) {
    sortedItem = props.sortedItem;
  }

  const onSortChange = () => {
    props.handleSortChange && props.handleSortChange(props.column.id);
  };
  return (
    <Flex>
      <FloatLabelInput
        style={{ flexGrow: '1' }}
        label={props.column.id}
        onChangeText={onChangeText}
        value={
          (props.columnsSearch && props.columnsSearch[props.column.id]) || ''
        }
      />

      <ButtonGroup onClick={onSortChange}>
        {sortedItem?.sortKey !== props.column.id && <Icon.Sort title="Sort" />}
        {sortedItem?.sortKey === props.column.id &&
          sortedItem?.sortOrder === 'asc' && <Icon.SortUp title="Sort" />}
        {sortedItem?.sortKey === props.column.id &&
          sortedItem?.sortOrder === 'desc' && <Icon.SortDown title="Sort" />}
      </ButtonGroup>
    </Flex>
  );
}
