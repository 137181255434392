import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { createFolder } from '../utils/urls';
import { getTokenWithType } from './useAuth';

export async function postCreateFolders({
  params,
}: TArguments): Promise<TResult> {
  const response = await fetch(createFolder, {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  return response.json();
}

export type TResult = string;
export type CreateFolderParams = {
  Title: string;
  Content: string;
  Container: string;
  streamContent: string;
};
export type TError = { message: string };
export type TArguments = {
  params: CreateFolderParams;
};
export type TVariables = CreateFolderParams;
export type TSnapshot = unknown;

function useCreateFolder(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: CreateFolderParams) => {
      const renamedResult = await postCreateFolders({
        params: params,
      });

      if (renamedResult) {
        queryCache.invalidateQueries(['get_file_tree'], {
          refetchInactive: true,
        });
      }
      return renamedResult;
    },
    {
      throwOnError: true,
    },
  );
}

export { useCreateFolder };
