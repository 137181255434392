export function getFormatDate(lastUpdateDate: string): string {
  const date = new Date(lastUpdateDate);
  const month =
    date.getMonth() + 1 >= 10
      ? date.getMonth() + 1
      : '0' + (date.getMonth() + 1);
  const formattedDate =
    date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
  const hours = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours();
  const minutes =
    date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes();
  const seconds =
    date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds();
  return (
    month +
    '/' +
    formattedDate +
    '/' +
    date.getFullYear() +
    '  ' +
    hours +
    ':' +
    minutes +
    ':' +
    seconds
  );
}
