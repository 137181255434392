import React, {
  ReactElement,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MainContent, PageContent, PageHeader, Table } from 'ui/patterns';
import { useDocumentTitle, useGetAccountInfo } from 'hooks';
import { Flex, Text } from 'rebass/styled-components';
import { SpaceProps, LayoutProps } from 'styled-system';
import * as Icon from '../../assets/icons';
import { useGetFormsList } from 'hooks';
import useFormsColumns from './useFormsColumns';

const pageTitle = 'List of Account Forms';

const sortItem = [
  { sortKey: 'Date Last Updated', sortOrder: 'desc' },
  { sortKey: 'Form Status', sortOrder: 'desc' },
];

type SortItemType = {
  sortKey: string;
  sortOrder: string;
};

type StyleProps = SpaceProps & LayoutProps;

type FormListType = {
  switchTab?: (tabId: string, formId?: number) => void;
  pageTitle?: string;
  pageSubTitle?: string;
} & StyleProps;

export default function FormsList({
  ...StyleProps
}: FormListType): ReactElement {
  useDocumentTitle('Account Forms', true);
  const history = useHistory<{ ID: number; formId: number }>();
  const { accountId: passedId, tabId } = useParams<{
    accountId: string;
    tabId?: string;
  }>();
  const accountId = parseInt(passedId);
  const { data: accountInfo, isLoading: isAccountInfoLoading } =
    useGetAccountInfo(accountId);
  const accountName = isAccountInfoLoading
    ? 'Processing'
    : `${accountInfo?.accountInfoReturn.Name} `;
  const pageSubTitle = !isAccountInfoLoading
    ? `${accountInfo?.accountInfoReturn.CID}${'-'}${
        accountInfo?.accountInfoReturn.SID
      }${'-'}${accountInfo?.accountInfoReturn.PID}`
    : null;

  useEffect(() => {
    if (accountId === undefined) {
      history.push('/home');
    }
  });

  function switchTab(tabId: string, formId?: string | number) {
    history.push(
      `/home/accounts/edit/${accountId}/${tabId}${formId ? `/${formId}` : ''}`,
    );
  }

  const pageActions = [
    {
      action: 'Reset',
      icon: <Icon.Refresh title="" ariaHidden="true" />,
      handleButtonClick: () => handleReset(),
    },
    {
      action: 'Add New Form',
      icon: <Icon.Add title="" ariaHidden="true" />,
      handleButtonClick: () => {
        switchTab && switchTab('AddForm');
      }, // handleAddForm(),
    },
  ];
  const [columnsSearch, setColumnsSearch] = useState({}); // header search
  const [currentSortItem, setCurrentSortItem] = useState<
    SortItemType | Array<SortItemType>
  >(sortItem);

  // const [page, setPage] = useState(0);
  const [keywordSearch, setKeywordSearch] = useState(''); // keyword search

  // let numberOfPages = 0;

  // const {
  //   resolvedData: data,
  //   latestData,
  //   isLoading,
  //   isFetching,
  // } = useGetFormsList({
  //   accountId: accountId,
  //   page,
  //   keywordSearch,
  //   columnsSearch,
  //   sortItem: currentSortItem,
  // });

  const {
    data,
    fetchMore,
    canFetchMore,
    isFetchingMore,
    isLoading,
    isFetching,
  } = useGetFormsList({
    accountId: accountId,
    page: 0,
    keywordSearch,
    columnsSearch,
    sortItem: currentSortItem,
  });

  const columns = useFormsColumns();

  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch],
  );

  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (currentSortItem instanceof Array) {
        const existingItem = currentSortItem.find(
          sItem => sItem.sortKey === item,
        );
        if (existingItem) {
          setCurrentSortItem({
            sortKey: item,
            sortOrder: existingItem.sortOrder === 'asc' ? 'desc' : 'asc',
          });
        } else {
          setCurrentSortItem({
            sortKey: item,
            sortOrder,
          });
        }
      } else if (currentSortItem.sortKey && currentSortItem.sortOrder) {
        if (
          currentSortItem.sortKey === item &&
          currentSortItem.sortOrder === 'asc'
        ) {
          sortOrder = 'desc';
        }
        setCurrentSortItem({
          sortKey: item,
          sortOrder,
        });
      }
    },
    [setCurrentSortItem, currentSortItem],
  );

  // if (data && data.recordsFiltered) {
  //   numberOfPages = Math.floor(data.recordsFiltered / pageLength);
  // }
  // const setPageNumber = useCallback(
  //   (newPage: number) => {
  //     if (newPage >= 0 && newPage <= numberOfPages) setPage(newPage);
  //   },
  //   [setPage, numberOfPages],
  // );

  const handleKeywordSearch = (value: string): void => {
    setKeywordSearch(value);
  };

  const handleReset = (): void => {
    setKeywordSearch('');
    setColumnsSearch({});
    setCurrentSortItem(sortItem);
  };

  const handleAddForm = (): void => {
    // history.push('/home/forms/add', { ID: accountId, formId: 0 });
  };

  const handleEditForm = (row: any): void => {
    // history.push('/home/forms/edit', {
    //   ID: accountId,
    //   formId: row.original.Id,
    // });
    if (switchTab) switchTab('EditForm', row.original.Id);
  };

  const tableData = useMemo(() => {
    if (data) {
      return data.map(dataItem => dataItem.data).flat();
    }
  }, [data]);

  const totalItems = useMemo(() => {
    if (data) {
      return data[0].recordsFiltered;
    }
    return 0;
  }, [data]);

  const onEndReached = () => {
    if (tableData) {
      if (canFetchMore && tableData?.length < totalItems) fetchMore();
    }
  };
  return (
    <MainContent flexDirection="column" m={-4} {...StyleProps}>
      <PageHeader
        title={pageTitle || ''}
        subTitle={`${accountName}(${pageSubTitle})`}
        SubtitleTootip={`${accountName}(${pageSubTitle})`}
        actions={pageActions}
        hasSearch
        searchValue={keywordSearch}
        handleChange={handleKeywordSearch}
      />
      <PageContent>
        <Text mb={2} fontSize={1} color="text.body" sx={{ height: '20px' }}>
          {/* {data &&
            !isFetching &&
            `Showing ${pageLength * page + 1} to ${
              pageLength * page + (latestData?.data?.length || 0)
            } of ${data?.recordsFiltered}`} */}
          {tableData && tableData.length > 0 && (
            <Text mb={2}>{`Showing ${tableData.length} of ${totalItems}${
              isFetching ? ' | Processing' : ''
            }`}</Text>
          )}
          {/* {isFetching && 'Processing'} */}
        </Text>
        <Table
          columns={columns}
          data={tableData}
          isLoading={isLoading || isFetching}
          headerUpdateCallBack={headerUpdateCallBack}
          handleSortChange={handleSortChange}
          multiSortedItem={currentSortItem}
          columnsSearch={columnsSearch}
          onRowDoubleClick={handleEditForm}
          onEditPress={(id: number) => {
            if (switchTab) switchTab('EditForm', id);
          }}
          hasInlineLoader
          onEndReached={onEndReached}
          isFetching={!!isFetchingMore}
        />
        <Flex mt={4} justifyContent="flex-end">
          {/* <Pagination
            page={page}
            numberOfPages={numberOfPages}
            onClick={setPageNumber}
          /> */}
        </Flex>
      </PageContent>
    </MainContent>
  );
}
