/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { AuthContext } from './authProvider';

export const useReactOidc = () => {
  const {
    user,
    signinRedirectCallback,
    logout,
    signoutRedirectCallback,
    isAuthenticated,
    signinRedirect,
    signinSilentCallback,
    createSigninRequest,
  } = React.useContext(AuthContext);

  return {
    ...user,
    signinRedirectCallback,
    logout,
    signoutRedirectCallback,
    isAuthenticated,
    signinRedirect,
    signinSilentCallback,
    createSigninRequest,
  };
};
