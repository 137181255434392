import { QueryResult, useQuery } from 'react-query';
import { getProcedureDetails } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { ProcedureInfoType } from 'types/procedureBuilder';

async function fetchProcedureDetails({
  accountId,
  procedureId,
}: {
  accountId: number | undefined;
  procedureId: number | undefined;
}): Promise<TResult> {
  const response = await fetch(getProcedureDetails(accountId, procedureId), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = ProcedureInfoType | undefined;

export type TError = { message: string };
export type TVariables = {
  accountId: number | undefined;
  procedureId: number | undefined;
};
export type TSnapshot = unknown;

export type TVariablesInput = {
  accountId: number | undefined;
  procedureId: number | undefined;
};

function useGetProcedureDetails({
  accountId,
  procedureId,
}: TVariables): QueryResult<TResult, TError> {
  return useQuery(
    ['get_procedure', accountId, procedureId],
    async () => {
      if (!accountId || !procedureId) return;
      const apiResult = await fetchProcedureDetails({
        accountId,
        procedureId,
      });
      return apiResult;
    },

    {
      staleTime: Infinity,
    },
  );
}

export { useGetProcedureDetails };
