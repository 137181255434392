import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { confirmAlert } from 'utils/confirm-alert';
import { Dropdown } from 'ui/patterns';
import { Button } from 'ui/components';
import { ColumnInstance, Row } from 'react-table';
import * as Icon from 'assets/icons';
import { Location } from 'types/locations';
import { useDeleteLocations } from 'hooks';

export default function TableOptionsHeader(props: {
  column: ColumnInstance<Location>;
  row: Row<Location>;
  onEditPress?: (locationId: number) => void;
}): ReactElement {
  const locId = props.row.original.Id;
  const params = useParams<{
    accountId: string;
  }>();
  const accountId = parseInt(params.accountId);
  const [deleteLocation] = useDeleteLocations(locId, accountId);

  const locationlistOptions = [
    {
      value: 'Edit Location',
      icon: <Icon.Edit title="Options" />,
      groupEnd: true,
      action: () => props.onEditPress && props.onEditPress(locId),
    },
    {
      value: 'Delete Location',
      icon: <Icon.Delete title="Options" />,
      action: () => handleDeleteLocation(),
    },
  ];

  const handleDeleteLocation = () => {
    confirmAlert({
      // title: 'Proteus',
      message: 'Are you sure you want to delete this location?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteLocation({ locId, accountId });
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  const locationlistDropdown = () => (
    <Button size="sm">
      <Icon.DotsVertical title="Options" />
    </Button>
  );

  return (
    <>
      <Dropdown
        trigger={locationlistDropdown()}
        menuList={locationlistOptions}
        position="left-top"
        className="dd-listitem-options"
      />
    </>
  );
}
