import React, { ReactElement } from 'react';
import Select from 'react-select';
import { Text, Box } from 'rebass/styled-components';
import { StylesConfig } from 'react-select';

type ReactSelectProps = {
  name: string | undefined;
  options:
    | Array<{ value: number | string; label: string | undefined | any }>
    | undefined;
  isClearable?: boolean;
  isSearchable?: boolean;
  className?: string;
  classNamePrefix?: string;
  isMulti?: boolean;
  required?: boolean;
  label?: string;
  disabled?: boolean;
  onChange?: any;
  onMenuOpen?: any;
  styles?: StylesConfig;
  value?: { value: number | string; label: string | undefined } | undefined;
  placeholder?: string;
};

function ReactSelect({
  name,
  options,
  isClearable,
  isSearchable,
  className,
  classNamePrefix,
  isMulti,
  required,
  label,
  disabled,
  onChange,
  styles,
  value,
  onMenuOpen,
  placeholder,
}: ReactSelectProps): ReactElement {
  return (
    <>
      {required ? (
        <Box marginBottom="xxsmall">
          <Text required>{label}</Text>
        </Box>
      ) : (
        <Box marginBottom="xxsmall">
          <Text fontWeight="medium">{label}</Text>
        </Box>
      )}

      <Select
        options={options}
        // styles={reactSelectBase}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isMulti={isMulti}
        isDisabled={disabled}
        className={className}
        classNamePrefix={classNamePrefix}
        onChange={onChange}
        styles={styles}
        value={value}
        onMenuOpen={onMenuOpen}
        placeholder={placeholder}
      />
      {/* {errors && errors[name] && (
        <Box marginTop="xsmall">
          <Flex gap="xxsmall">
            <Box color="danger" style={{ marginTop: '-4px' }}>
              <Icon.ExclamationCircleSmall />
            </Box>
            <Text color="danger" size="small">
              {errors[name].message}
            </Text>
          </Flex>
        </Box>
      )} */}
    </>
  );
}

export { ReactSelect };
