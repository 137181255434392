import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { hideDisplayMultipleProcedureVisibility } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { showSuccessToast, showErrorToast } from 'utils/showToast';

export async function updateMultipleProcedureVisibilty({
  params,
}: {
  params: ParamType;
}): Promise<TResult> {
  const { accountId, visibility, selectedProcedures } = params;
  const response = await fetch(
    hideDisplayMultipleProcedureVisibility(accountId),
    {
      method: 'PUT',
      headers: {
        Authorization: getTokenWithType(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ProcedureIds: selectedProcedures,
        IsVisible: visibility,
      }),
    },
  );

  return response.status;
}

export type TResult = number;

export type ParamType = {
  accountId: number;
  visibility: boolean;
  selectedProcedures: Array<number> | undefined;
};
export type TError = { message: string };
export type TVariables = {
  params: ParamType;
};
export type TSnapshot = unknown;

function useHideDisplayMultipleProcedures(): MutationResultPair<
  void,
  TError,
  ParamType,
  TSnapshot
> {
  return useMutation(async (params: ParamType) => {
    const response = await updateMultipleProcedureVisibilty({
      params: params,
    });
    if (response === 200) {
      showSuccessToast({
        message: 'Your changes were saved successfully.',
      });
      queryCache.invalidateQueries(
        ['get_procedure_builder_procedures', params.accountId],
        {
          refetchInactive: true,
        },
      );
      queryCache.invalidateQueries(['get_procedure'], {
        refetchInactive: true,
      });
    } else {
      showErrorToast({
        message: 'Something went wrong',
      });
    }
  });
}

export { useHideDisplayMultipleProcedures };
