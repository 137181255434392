import React, { ReactElement, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'ui/patterns';
import { Button } from 'ui/components';
import { ColumnInstance, Row } from 'react-table';
import useOnClickOutside from 'use-onclickoutside';
import * as Icon from 'assets/icons';
import { Account } from 'types/accounts';

export default function TableAccountOptions(props: {
  column: ColumnInstance<Account>;
  row: Row<Account>;
  setAccounts?: (row: any, type: string) => void;
}): ReactElement {
  const history = useHistory();
  const accountId = props.row.original.Id;
  const listItemOptions = [
    {
      value: 'Edit Account',
      icon: <Icon.Edit title="" ariaHidden="true" />,
      groupEnd: true,
      action: () => handleEditAccount(),
    },
    {
      value: 'Import Account',
      icon: <Icon.Import title="" ariaHidden="true" />,
      groupEnd: true,
      action: () =>
        props.setAccounts &&
        props.setAccounts(props.row.original, 'ImportAccount'),
    },
    {
      value: 'Copy Account',
      icon: <Icon.Copy title="" ariaHidden="true" />,
      groupEnd: true,
      action: () =>
        props.setAccounts &&
        props.setAccounts(props.row.original, 'CopyAccount'),
    },
    {
      value: 'Form List',
      icon: <Icon.List title="" ariaHidden="true" />,
      groupEnd: true,

      action: () => {
        // props.setAccounts && props.setAccounts(props.row.original, 'FormList');
        // history.push('/home/forms', { ID: accountId });
        history.push(`/home/accounts/edit/${props.row.original.Id}/FormList`, {
          ID: props.row.original.Id,
          tabId: 'FormList',
        });
      },
    },
    {
      value: 'Procedures',
      icon: <Icon.Document title="" ariaHidden="true" />,
      groupEnd: true,
      action: () => {
        history.push(
          `/home/accounts/edit/${props.row.original.Id}/Procedures`,
          {
            ID: props.row.original.Id,
            tabId: 'Procedures',
          },
        );
        // props.setAccounts &&
        //   props.setAccounts(props.row.original, 'Procedures');
        // history.push('/home/procedure-builder', { ID: accountId });
      },
    },
    {
      value: 'Demo Call',
      icon: <Icon.Call />,
      action: () => {
        const accountNumber = `${props?.row?.original?.CID}${'-'}${
          props?.row?.original?.SID
        }${'-'}${props?.row?.original.PID}`;
        window.open(
          `${process.env.REACT_APP_CALLCENTER_REDIRECT_URL}/Calls/Call/00000000-0000-0000-0000-000000000000?accountId=${accountId}&accountNumber=${accountNumber}&isFromProcedure=false`,
        );
      },
    },
  ];

  const listItemDropdown = () => (
    <Button size="sm" variant="subtle">
      <Icon.DotsVertical title="Options" />
    </Button>
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setOpen] = useState(false);
  const handleOnClickOutside = () => {
    setOpen(false);
  };
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(wrapperRef, handleOnClickOutside);

  const handleEditAccount = (): void => {
    history.push(`/home/accounts/edit/${props.row.original.Id}/accountInfo`, {
      ID: props.row.original.Id,
    });
  };

  return (
    <>
      <Dropdown
        trigger={listItemDropdown()}
        menuList={listItemOptions}
        position="left-top"
        className="dd-listitem-options"
      />
    </>
  );
}
