import React, { ReactElement } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { transparentize } from 'polished';
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  FlexProps,
  space,
  SpaceProps,
} from 'styled-system';
import { Separator, Button } from 'ui/components';
import * as Icon from 'assets/icons';

type ssProps = SpaceProps & FlexProps & LayoutProps & FlexboxProps;

export const FileBrowserBtn = styled.button`
  appearance: none;
  display: flex;
  background: none;
  border: none;
  width: 100%;
  padding: ${({ theme }) => theme.space[2]};
  color: ${({ theme }) => theme.colors.text.body};
  align-self: center;
  cursor: pointer;

  &:hover {
    background-color: var(--color-neutral-contrast-03);
  }

  &:focus {
    outline: solid 1px var(--color-neutral-contrast-05);
    outline-offset: -2px;
  }

  & > .pr-icon > svg {
    fill: ${({ theme }) => theme.colors.text.body};
  }

  & > .label {
    display: inline-block;
    margin-left: ${({ theme }) => theme.space[2]};
    font-weight: ${({ theme }) => theme.fontWeights.medium};

    & + .pr-icon {
      margin-left: auto;
    }
  }
`;

const rotation = keyframes`
	to {
		transform: rotate(-359deg);
	}
`;

type BrokenLinkCountProps = {
  fetching?: boolean;
};

export const BrokenLinkCount = styled.span<BrokenLinkCountProps>`
  display: inline-flex;
  align-items: center;
  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
  margin-left: ${({ theme }) => theme.space[2]};
  background: ${({ theme }) => theme.colors.bg.lightRed};
  border-radius: 50px;
  color: ${({ theme }) => theme.colors.state.error};
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeights.medium};

  & > button {
    display: none;
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    line-height: 1;
    height: 20px;
    color: ${({ theme }) => theme.baseColors.gray[4]};
    margin-left: ${({ theme }) => theme.space[2]};
    padding-left: ${({ theme }) => theme.space[2]};
    border-left: 1px solid ${({ theme }) => theme.baseColors.red[2]};

    &:focus {
      outline: dotted 1px currentcolor;
      outline-offset: -0.2em;
    }

    ${({ fetching }) =>
      fetching &&
      css`
        & svg {
          animation: 3s ${rotation} infinite linear;
        }
      `}
  }

  & > ${Separator}, & > button {
    display: none;
  }

  &:hover > ${Separator}, &:focus-within > ${Separator}, &:hover > button,
  &:focus-within > button {
    display: block;
  }

  ${({ fetching }) =>
    fetching &&
    css`
      & > ${Separator}, & > button {
        display: block;
      }
    `}
`;

export const ProcedureSearch = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  background: var(--color-neutral-contrast-02);
  border-bottom: solid 1px ${({ theme }) => theme.colors.border.default};

  & > .search-input {
    flex: 1;
    position: relative;
    margin-left: ${({ theme }) => theme.space[2]};
    margin-right: ${({ theme }) => theme.space[2]};

    & > .pr-icon {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-45%);
      pointer-events: none;

      & > svg > path {
        fill: var(--color-neutral-contrast-04);
      }
    }

    & > input[type='search'] {
      appearance: none;
      background: none;
      border: none;
      width: 100%;
      padding-left: 30px;
      color: ${({ theme }) => theme.colors.text.body};

      &:focus {
        outline: solid 1px var(--color-neutral-contrast-05);
        outline-offset: 2px;
      }
    }
  }
`;

export const ProcedureList = styled.div`
  & > * {
    border-bottom: solid 1px ${({ theme }) => theme.colors.border.default};
  }
`;

type ProcedureListItemProps = {
  editMode?: boolean;
  unpublished?: boolean;
  isSelected?: boolean;
  draft?: boolean;
};

export const ProcedureListItem = styled.div<ProcedureListItemProps>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  transition: background 200ms ease-out;

  &:hover {
    background: var(--color-neutral-contrast-03);
  }

  & > * + * {
    margin-left: ${({ theme }) => theme.space[2]};
  }

  & .procedure__visibility {
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  & .procedure__name {
    padding: 2px ${({ theme }) => theme.space[1]};
    min-width: 0;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    appearance: none;
    border: solid 1px ${({ theme }) => theme.colors.border.default};
    font-size: 0.9rem;
    flex: 1;
    color: ${({ theme }) => theme.colors.text.body};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    background: ${({ theme }) => transparentize(0.8, theme.colors.primary)};

    &:focus {
      background: ${({ theme }) => transparentize(0.8, theme.colors.primary)};
      border: solid 1px ${({ theme }) => theme.colors.primary};
      outline: none;
    }

    &[readonly] {
      background: none;
      border: none;
    }

    html[data-color-mode='dark'] &:focus {
      background: ${({ theme }) => transparentize(0.4, theme.colors.primary)};
      color: #fff !important;
    }
  }

  &:hover > .procedure__attributes {
    display: none;
  }

  &:hover > .procedure__actions {
    display: flex;
  }

  & .procedure__flag {
    color: var(--color-on-primary);
    font-size: ${({ theme }) => theme.fontSizes[0]};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    background: ${({ theme }) => theme.colors.state.warning};
    border-radius: 2px;
    padding-inline: ${({ theme }) => theme.space[1]};
    text-transform: uppercase;
    text-shadow: 0px 0px 3px hsl(0deg 0% 0% / 20%);
    margin-inline-end: ${({ theme }) => theme.space[1]};
  }

  & > .procedure__actions,
  & .procedure__name-edit {
    display: none;

    > * {
      width: 20px;
      height: 20px;
    }

    button {
      appearance: none;
      background: none;
      border: none;
      padding: 0;
      color: ${({ theme }) => theme.colors.text.body};
      width: 20px;
      height: 20px;
      cursor: pointer;
      transform: none;

      &:focus {
        outline: 1px solid var(--color-neutral-contrast-04);
      }

      &:active {
        transform: translate3d(0, 1px, 0);
      }
      &:disabled {
        opacity: 0.4;
        pointer-events: none;
      }
      &.hidden {
        opacity: 0.05;
        pointer-events: none;
      }
    }
  }

  ${({ draft }) =>
    draft &&
    css`
      & .procedure__name,
      & .procedure__tooltip__wrapper {
        max-width: 120px;
      }
    `}

  ${({ editMode }) =>
    editMode &&
    css`
      & > .procedure__actions,
      &:hover > .procedure__actions,
      & > .procedure__attributes {
        display: none;
      }
      & .procedure__name-edit {
        display: flex;
      }
      & .procedure__name,
      & .procedure__tooltip__wrapper {
        max-width: 150px;
      }
      &:hover .procedure__flag__tooltip {
        margin-inline-end: 0;
      }
    `}

  ${({ unpublished }) =>
    unpublished &&
    css`
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0;
        width: 3px;
        /* border-top-right-radius: 3px;
        border-bottom-right-radius: 3px; */
        /* height: 80%; */
        background-color: ${({ theme }) => theme.colors.state.error};
      }
    `}

	${({ isSelected }) =>
    isSelected &&
    css`
      background: hsla(var(--primary-color-hsl), 15%);
    `}
`;

export const StyledProcedureBrokenLinks = styled.span`
  position: relative;
  display: flex;
  align-items: center;

  .brokenlink__count {
    position: absolute;
    top: -6px;
    right: -9px;
    width: 16px;
    height: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.state.error};
    color: #fff;
    font-size: ${({ theme }) => theme.fontSizes[0]};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    border-radius: 10px;
    line-height: 1;
  }
`;

type ProcedureBrokenLinksProps = {
  count?: number;
};

export function ProcedureBrokenLinks({
  count,
}: ProcedureBrokenLinksProps): ReactElement {
  return (
    <StyledProcedureBrokenLinks>
      <Icon.Link
        fill={
          count
            ? 'var(--color-state-error)'
            : 'var(--color-neutral-contrast-03)'
        }
      />
      {count ? <span className="brokenlink__count">{count}</span> : null}
    </StyledProcedureBrokenLinks>
  );
}

export const FilterMenu = styled.div``;

type FilterMenuItemProps = {
  selected?: boolean;
};

export const FilterMenuItem = styled.button<FilterMenuItemProps>`
  display: flex;
  width: 100%;
  appearance: none;
  border: none;
  background: none;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  border-bottom: solid 1px ${({ theme }) => theme.colors.border.default};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.body};
  transform: none;

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    border-bottom-color: ${({ theme }) => theme.colors.border.default};
    color: #fff;
  }

  &:focus {
    /* outline: solid 1px var(--color-neutral-contrast-04);
    outline-offset: -1px; */
    outline: none;
  }

  &:active {
    transform: translate3d(0, 1px, 0);
  }

  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.colors.primary};
    `}
`;

export const FilterMenuItemLabel = styled.span`
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 200px;
  margin-left: ${({ theme }) => theme.space[3]};
  line-height: 1.4;

  & + .pr-icon {
    margin-left: auto;
    padding-left: ${({ theme }) => theme.space[4]};
  }
`;

export const FilterMenuActions = styled.div`
  display: flex;
  background: var(--color-neutral-contrast-02);

  & > button {
    flex: 1;
    appearance: none;
    border: none;
    background: none;
    padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
    cursor: pointer;
    color: ${({ theme }) => theme.colors.text.body};
    transform: none;

    &:hover {
      background: var(--color-neutral-contrast-03);
    }

    &:focus {
      outline: solid 1px var(--color-neutral-contrast-05);
      outline-offset: -2px;
    }

    &:active {
      transform: translate3d(0, 1px, 0);
    }
  }
`;

export const ProcedureEditActions = styled.div<ssProps>`
  --bg: var(--color-neutral-contrast-02);
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.space[2]};
  padding-bottom: ${({ theme }) => theme.space[2]};
  background: var(--bg);
  border-right: solid 1px ${({ theme }) => theme.colors.border.default};
  border-bottom-left-radius: ${({ theme }) => theme.radii.soft};

  html[data-color-mode='dark'] & {
    --bg: var(--color-neutral-contrast-01);
  }

  ${flexbox}
  ${space}
	${layout}

	button {
    appearance: none;
    border: none;
    background: none;
    padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[2]};
    color: ${({ theme }) => theme.colors.text.body};
    line-height: 1;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }

    html[data-color-mode='dark'] & {
      --bg: var(--color-neutral-contrast-05);
      color: var(--color-neutral-contrast-06);
    }

    &:focus {
      outline: none;
      background: var(--color-neutral-contrast-07);

      html[data-color-mode='dark'] & {
        background: var(--color-neutral-contrast-01);
      }
    }

    &:active {
      transform: translate3d(0, 1px, 0);
    }
  }
`;

const AfterArrowAnimation = keyframes`
	0% {
    opacity: 1;
    transform: translateX(0);
  }
  45% {
    opacity: 0;
    transform: translateX(6px);
  }
  46% {
    opacity: 0;
    transform: translateX(-20px);
  }
  90% {
    opacity: 1;
    transform: translateX(-4px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const LastEdited = styled.a`
  color: ${({ theme }) => theme.baseColors.blue[7]};
  transition: color 200ms ease-out;
  text-align: right;
  cursor: pointer;
  word-break: break-word;
  line-height: 1.3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.space[2]};

  &:hover {
    color: ${({ theme }) => theme.baseColors.blue[5]};
  }

  &:active {
    transform: translate3d(0, 1px, 0);
  }

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  /* & > small::after {
    content: '\\279C';
    width: 10px;
    height: 10px;
    padding-left: 0.25rem;
    display: inline-block;
    font-weight: bold;
  }

  &:hover > small::after {
    animation: ${AfterArrowAnimation} 400ms ease-out;
  } */
`;

export const ProcedureEditorWrapper = styled.div`
  position: relative;
  flex: 1;
  padding: ${({ theme }) => theme.space[2]};

  .tox-tinymce {
    width: 100%;
    height: 100% !important;
    border-radius: ${({ theme }) => theme.radii.soft};
  }
`;

export const InfoText = styled.span`
  color: ${({ theme }) => theme.colors.text.warning};
  text-align: right;
  position: relative;
  top: -4px;

  & > .pr-icon {
    position: relative;
    top: 5px;
    margin-inline-end: ${({ theme }) => theme.space[0]};
  }
`;

export const VersionButton = styled(Button)`
  white-space: nowrap;

  &:disabled {
    pointer-events: none;
  }
`;

export default {
  FileBrowserBtn,
  BrokenLinkCount,
  ProcedureSearch,
  ProcedureList,
  ProcedureBrokenLinks,
  FilterMenu,
  FilterMenuItem,
  FilterMenuItemLabel,
  FilterMenuActions,
  ProcedureEditActions,
  LastEdited,
  ProcedureEditorWrapper,
  InfoText,
  VersionButton,
};
