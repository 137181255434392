import styled from 'styled-components';

const SearchWrapper = styled.div`
  flex-shrink: 0;
  width: 430px;
  margin-top: calc(${({ theme }) => theme.space[4]} * -1);
  margin-bottom: calc(${({ theme }) => theme.space[4]} * -1);
  margin-right: calc(${({ theme }) => theme.space[4]} * -1);
  border-left: 1px solid ${({ theme }) => theme.colors.border.default};
`;

export { SearchWrapper };
