import React, {
  ReactNode,
  useRef,
  useEffect,
  ReactElement,
  useCallback,
} from 'react';
// import { createPortal } from 'react-dom';
import { Flex } from 'rebass/styled-components';
import { Stack } from '../../components';
import * as Icon from 'assets/icons';
import {
  SlidePanelBackDrop,
  StyledSlidePanel,
  SlidePanelHeader,
  SlidePanelBody,
  SlidePanelFooter,
  SlidePanelClose,
} from './styles';

type SlidePanelProps = {
  children: ReactNode;
  title?: string | ReactNode;
  actions?: ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  maxWidth?: string;
  open?: boolean;
  onClose?: () => void;
};

function SlidePanel({
  children,
  title,
  actions,
  size,
  maxWidth,
  open,
  onClose,
}: SlidePanelProps): ReactElement {
  const handleKeyDown = useCallback(
    (e: any) => {
      // console.log('sadsdasdasdsadasd', e.code, e);
      if (open && e.code === 'Escape') onClose && onClose();
    },
    [onClose, open],
  );

  const wrapperRef = useRef<HTMLInputElement>();
  // const currentEl = wrapperRef.current;
  // const focusableEls = Object.assign(
  //   [],
  //   currentEl &&
  //     currentEl.querySelectorAll(
  //       'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]',
  //     ),
  // );
  // const value = focusableEls[0] && focusableEls[0].value;
  // console.log(focusableEls[0].getAttribute('value') as HTMLInputElement);
  // console.log(focusableEls[0]);
  // (document.getElementById(elementId) as HTMLInputElement).value;
  // console.log(focusableEls[0]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <StyledSlidePanel
        size={size}
        maxWidth={maxWidth}
        open={open}
        ref={wrapperRef}
      >
        {title ? <SlidePanelHeader>{title}</SlidePanelHeader> : null}
        <SlidePanelBody>{children}</SlidePanelBody>
        {actions ? (
          <SlidePanelFooter>
            <Flex justifyContent="flex-end" alignItems="center">
              <Stack direction="x" gap={2}>
                {actions}
              </Stack>
            </Flex>
          </SlidePanelFooter>
        ) : null}

        <SlidePanelClose onClick={onClose} open={open}>
          <Icon.X />
        </SlidePanelClose>
      </StyledSlidePanel>
      <SlidePanelBackDrop
        open={open}
        tabIndex={-1}
        onClick={onClose}
      ></SlidePanelBackDrop>
    </>
  );
}

export { SlidePanel };
