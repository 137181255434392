import { useQuery, QueryResult } from 'react-query';
import { getLookups } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchLookups({ accountId }: TVariables): Promise<APIResult> {
  const response = await fetch(getLookups(accountId), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type LookupResult = {
  Account: null;
  AccountId: number;
  Columns: Array<string>;
  Id: number;
  LastUpdated: string;
  LastUpdatedBy: string;
  Records: Array<any>;
  TableName: string;
};

export type APIResult = Array<LookupResult>;

export type ResultItem = {
  id: number;
  name: string;
};
export type TResult = Array<ResultItem>;

export type TError = { message: string };
export type TVariables = {
  accountId: number;
};

function useGetLookups(accountId: number): QueryResult<TResult, TError> {
  return useQuery(
    'get_look_ups',
    async () => {
      const fetchResponse = await fetchLookups({
        accountId,
      });
      const result = fetchResponse.map(item => ({
        id: item.Id,
        name: item.TableName,
      }));
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetLookups };
