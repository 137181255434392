import React, { ReactElement, SyntheticEvent, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { confirmAlert } from 'utils/confirm-alert';
import { Dropdown, Loader } from 'ui/patterns';
import { Button } from 'ui/components';
import { ColumnInstance, Row } from 'react-table';
import * as Icon from 'assets/icons';
import { CustomTable } from 'types/customTables';
import ImportCustomTable from '../ImportCustomTable';
import { useDeleteCustomTable } from 'hooks';
import { customTableCsv } from 'utils/urls';

export default function TableOptionsHeader(props: {
  column: ColumnInstance<CustomTable>;
  row: Row<CustomTable>;
  onEditPress?: (contactId: number) => void;
}): ReactElement {
  const params = useParams<{
    accountId: string;
  }>();
  const accountId = parseInt(params.accountId);
  const [showImportTable, setImportTable] = useState(false);
  const [selectedTable, setSelectedTable] = useState<CustomTable>();
  const [deleteTable, { isLoading }] = useDeleteCustomTable();
  const toggleImportTable = () => {
    setSelectedTable(undefined);
    setImportTable(false);
  };

  function handleExport() {
    const csvUrl = customTableCsv(accountId, props.row.original.Id);
    window.open(csvUrl, '_newtab');
    // download same page
    // const a = document.createElement('a');
    // a.href = csvUrl;
    // a.setAttribute('download', props.row.original.TableName);
    // a.click();
  }

  const handleDeleteTable = () => {
    confirmAlert({
      // title: 'Proteus',
      message: 'Are you sure you want to delete this table?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteTable({ Id: props.row.original.Id, accountId });
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  const customTableOptions = [
    {
      value: 'Edit Table',
      icon: <Icon.Edit title="Options" />,
      groupEnd: true,
      action: () =>
        props.onEditPress && props.onEditPress(props.row.original.Id),
    },
    {
      value: 'Delete Table',
      icon: <Icon.Delete title="Options" />,
      groupEnd: true,
      action: handleDeleteTable,
    },
    {
      value: 'Import Table',
      icon: <Icon.Import title="Options" />,
      groupEnd: true,
      action: () => {
        setImportTable(true);
        setSelectedTable(props.row.original);
      },
    },
    {
      value: 'Export Table',
      icon: <Icon.Export title="Options" />,
      action: () => handleExport(),
    },
  ];

  const customTableDropdown = () => (
    <Button style={{ padding: '.5em .6em .6em' }}>
      <Icon.DotsVertical title="Options" />
    </Button>
  );

  return (
    <div onClick={(e: SyntheticEvent<HTMLDivElement>) => e.stopPropagation()}>
      <Dropdown
        trigger={customTableDropdown()}
        menuList={customTableOptions}
        position="left-top"
        className="dd-listitem-options"
      />

      <ImportCustomTable
        showPanel={showImportTable}
        togglePanel={toggleImportTable}
        // selectedTable={selectedTable}
        accountId={accountId}
        lookupId={selectedTable?.Id}
        title={selectedTable?.TableName}
      />
      {isLoading && <Loader />}
    </div>
  );
}
