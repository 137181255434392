import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { StyledFormInput } from '../textInput';

type TextareaProps = {
  value?: string;
  onTextChange?: (text: string) => void;
  placeholder?: string;
  id?: string;
  disabled?: boolean;
  validation?: string;
  rows?: number;
  cols?: number;
  register?: ({ required }: { required?: boolean }) => RefReturn;
  required?: boolean;
  onChange?: (e: any) => void;
  onBlur?: () => void;
  resize?: 'both' | 'horizontal' | 'vertical' | 'none';
};

type StyledTextareaProps = {
  resize?: 'both' | 'horizontal' | 'vertical' | 'none';
};

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

export const StyledTextarea = styled(StyledFormInput)<StyledTextareaProps>`
  height: auto;

  ${({ resize }) =>
    resize === 'both' &&
    css`
      resize: both;
    `}

  ${({ resize }) =>
    resize === 'horizontal' &&
    css`
      resize: horizontal;
    `}

	${({ resize }) =>
    resize === 'vertical' &&
    css`
      resize: vertical;
    `}

	${({ resize }) =>
    resize === 'none' &&
    css`
      resize: none;
    `}
`;
const Textarea = ({
  placeholder,
  value,
  id,
  disabled,
  validation,
  rows,
  cols,
  register,
  required,
  onChange,
  onBlur,
  resize,
}: TextareaProps): ReactElement => (
  <StyledTextarea
    as="textarea"
    placeholder={placeholder}
    value={value}
    id={id}
    disabled={disabled}
    validation={validation}
    rows={rows}
    cols={cols}
    ref={register && register({ required })}
    name={id}
    // onChange={e => onTextChange(e.currentTarget.value)}
    onChange={onChange}
    onBlur={onBlur}
    resize={resize}
  />
);

export { Textarea };
