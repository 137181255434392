import { useQuery, QueryResult } from 'react-query';
import { getTokenWithType } from './useAuth';
import { proceduresForEmbedProcedure } from '../utils/urls';
import { ProcedureListType } from '../types/procedureBuilder';

async function fetchProceduresForEmbedProcedures({
  accountId,
  showHidden,
}: {
  accountId: number;
  showHidden: boolean;
}): Promise<TResult> {
  const response = await fetch(
    proceduresForEmbedProcedure(accountId, showHidden),
    {
      method: 'GET',
      headers: {
        Authorization: getTokenWithType(),
      },
    },
  );
  return response.json();
}

export type TResult = Array<ProcedureListType> | undefined;

export type TError = { message: string };

function useGetProceduresForEmbedProcedure({
  accountId,
  showHidden,
}: {
  accountId?: number;
  showHidden: boolean;
}): QueryResult<TResult, TError> {
  return useQuery(
    ['getProceduresForEmbedProcedures', accountId, showHidden],
    async () => {
      if (accountId)
        return await fetchProceduresForEmbedProcedures({
          accountId,
          showHidden,
        });
    },
    {
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetProceduresForEmbedProcedure };
