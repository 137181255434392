import React, { ReactElement } from 'react';
import { Box } from 'rebass';
import { Checkbox, Cluster } from 'ui/components';
import { ProcedureOptionsType } from 'types/accountsSearch';

export default function ProcedureSearchOption({
  procedureOptions,
  toggleProcedureOption,
}: {
  procedureOptions: ProcedureOptionsType;
  toggleProcedureOption: any;
}): ReactElement {
  return (
    <Cluster gap={3} flex="1" p={2}>
      <Box>
        <Checkbox
          name="SearchProcedureTitles"
          label="Procedure Titles"
          checked={procedureOptions.procedureTitle}
          handleChange={toggleProcedureOption('procedureTitle')}
        />
        <Checkbox
          name="SearchProcedureText"
          label="Procedure Text"
          checked={procedureOptions.procedureText}
          handleChange={toggleProcedureOption('procedureText')}
        />
        <Checkbox
          name="IncludeGlobalProcedures"
          label=" Include Global Procedures"
          checked={procedureOptions.isGlobal}
          handleChange={toggleProcedureOption('isGlobal')}
        />
        <Checkbox
          name="IncludeInvisible"
          label=" Include Invisible"
          checked={procedureOptions.isInvisible}
          handleChange={toggleProcedureOption('isInvisible')}
        />
        <Checkbox
          name="IncludeHidden"
          label=" Include Hidden Accounts"
          checked={procedureOptions.includeHidden}
          handleChange={toggleProcedureOption('includeHidden')}
        />
      </Box>
    </Cluster>
  );
}
