import React from 'react';
import { Column, TableInstance, ColumnInstance } from 'react-table';
import { Account } from 'types/accounts';
import Header from './tableHeader';
import OptionsHeader from './tableOptionsHeader';
import AccountOptions from './tableAccountsOptions';

function contains(array: Array<string>, string: string): boolean {
  const index = array.findIndex(item => item === string);
  return index !== -1;
}

const columnSet = [
  'Phone Display',
  'CID',
  'SID',
  'PID',
  'Answer Script',
  'Account Name',
  'Rel Manager',
  'City',
  'State',
  'Time',
  'Status',
  'Service',
];

export default function useAccountsColumns(
  columns?: Array<string>,
  skipOptions?: boolean,
  branchOutData?: Array<any>,
  workFlowData?: Array<any>,
): Array<Column<Account>> {
  const procedureName = process.env.REACT_APP_THEME;

  return React.useMemo((): Array<Column<Account>> => {
    const columnsList = columns ? columns : columnSet;
    if (columnsList) {
      const columns = [];
      if (contains(columnsList, 'Phone Display'))
        columns.push(getPhoneHeader(Header));
      if (contains(columnsList, 'CID')) columns.push(getCIDHeader(Header));
      if (contains(columnsList, 'SID')) columns.push(getSIDHeader(Header));
      if (contains(columnsList, 'PID')) columns.push(getPIDHeader(Header));
      if (contains(columnsList, 'Answer Script'))
        columns.push(getAnswerScriptHeader(Header));
      if (contains(columnsList, 'Account Name'))
        columns.push(getNameHeader(Header));
      if (contains(columnsList, 'Rel Manager'))
        columns.push(getRelManagerHeader(Header));
      if (contains(columnsList, 'City')) columns.push(getCityHeader(Header));
      if (contains(columnsList, 'State')) columns.push(getStateHeader(Header));
      if (contains(columnsList, 'Time')) columns.push(getTimeHeader(Header));
      if (contains(columnsList, 'Status'))
        columns.push(getStatusHeader(Header));
      if (contains(columnsList, 'Service'))
        columns.push(getServiceHeader(Header));
      if (procedureName !== 'lfl') {
        if (contains(columnsList, 'Routing Group'))
          columns.push(getRoutingGroupHeader(Header, workFlowData));
        if (contains(columnsList, 'Branch-out Group'))
          columns.push(getBranchOutGroupHeader(Header, branchOutData));
        if (contains(columnsList, 'Call Recording'))
          columns.push(getCallRecordingHeader(Header));
        if (contains(columnsList, 'Twilio Flex Priority'))
          columns.push(getTwilioFlexPriorityHeader(Header));
        if (contains(columnsList, 'Press Any Key to Answer'))
          columns.push(getTwilioPressKeyToAnswerHeader(Header));
      }
      if (!skipOptions) columns.push(getOptionsHeader());

      return columns;
    }
    return [
      getPhoneHeader(Header),
      getCIDHeader(Header),
      getSIDHeader(Header),
      getPIDHeader(Header),
      getAnswerScriptHeader(Header),
      getNameHeader(Header),
      getRelManagerHeader(Header),
      getCityHeader(Header),
      getStateHeader(Header),
      getTimeHeader(Header),
      getStatusHeader(Header),
      getServiceHeader(Header),
      getRoutingGroupHeader(Header, workFlowData),
      getBranchOutGroupHeader(Header, branchOutData),
      getCallRecordingHeader(Header),
      getTwilioFlexPriorityHeader(Header),
      getTwilioPressKeyToAnswerHeader(Header),
      getOptionsHeader(),
    ];
  }, [branchOutData, columns, procedureName, skipOptions, workFlowData]);
}

function getPhoneHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: (row: Account) =>
      `${row.VDN || ''} ${row.ShortAnswerScript || ''}`,
    id: 'Phone Display',
  };
}

function getCIDHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: 'CID',
    id: 'CID',
  };
}

function getSIDHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: 'SID',
    id: 'SID',
  };
}

function getPIDHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: 'PID',
    id: 'PID',
  };
}

function getAnswerScriptHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: 'AnswerScript',
    width: 1000,
    id: 'Answer Script',
  };
}

function getNameHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: 'Name',
    id: 'Account Name',
  };
}

function getRelManagerHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Rel Manager',
    Header: Header,
    accessor: (row: Account) =>
      row.RelationshipManager
        ? `${row.RelationshipManager?.FirstName || ''} ${
            row.RelationshipManager?.LastName || ''
          }`
        : '',
  };
}

function getCityHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'City',
    Header: Header,
    accessor: 'City',
  };
}

function getStateHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'State',
    Header: Header,
    accessor: (row: Account) => `${row.StateProvince?.Abbreviation || ''}`,
  };
}

function getTimeHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Time',
    Header: Header,
    accessor: (row: Account) => `${row.TimeZone?.Abbreviation || ''}`,
  };
}

function getStatusHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Status',
    Header: Header,
    accessor: (row: Account) => `${row.Status?.Description || ''}`,
  };
}

function getServiceHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    id: 'Service',
    Header: Header,
    accessor: (row: Account) => `${row.AccountService?.Description || ''}`,
  };
}

function getCallRecordingHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: (row: Account) =>
      `${row?.EnableRecording === true ? 'Yes' : 'No' || ''}`,
    id: 'Call Recording',
  };
}

function getBranchOutId(
  branchOutCondition: Array<any> | undefined,
  Id: number | undefined,
) {
  const name = branchOutCondition?.find((item: any) => item?.id === Id)?.name;
  return name;
}

function getWorkFlowId(
  workFlow: Array<any> | undefined,
  Id: number | undefined,
) {
  const name = workFlow?.find((item: any) => item?.value === Id)?.label;
  return name;
}

function getBranchOutGroupHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
  branchOutData: Array<any> | undefined,
): Column<Account> {
  return {
    Header: Header,
    accessor: (row: Account) =>
      getBranchOutId(
        branchOutData,
        row?.TwilioAccountBranchOutCondition?.TwilioBranchOutConditionsId,
      ),
    id: 'Branch-out Group',
  };
}

function getRoutingGroupHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
  workFlowData: Array<any> | undefined,
): Column<Account> {
  return {
    Header: Header,
    accessor: (row: Account) =>
      getWorkFlowId(workFlowData, row?.TwilioAccountWorkFlow?.TwilioWorkFlowId),
    id: 'Routing Group',
  };
}

function getTwilioFlexPriorityHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: (row: Account) => row?.TwilioFlexPriority,
    id: 'Twilio Flex Priority',
  };
}

function getTwilioPressKeyToAnswerHeader(
  Header: (
    props: TableInstance<Account> & {
      column: ColumnInstance<Account>;
    },
  ) => JSX.Element,
): Column<Account> {
  return {
    Header: Header,
    accessor: (row: Account) =>
      `${row?.EnableRecording === true ? 'Yes' : 'No' || ''}`,
    id: 'Press Any Key to Answer',
  };
}

function getOptionsHeader(): Column<Account> {
  return {
    id: 'Options',
    Header: OptionsHeader,
    Cell: AccountOptions,
  };
}
