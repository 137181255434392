import React, {
  ReactElement,
  SyntheticEvent,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import {
  SlidePanel,
  Loader,
  SearchableList,
  ListBody,
  ListSearch,
  List,
  Alert,
} from 'ui/patterns';
import { Button, FormLabel, Stack, Iframe } from 'ui/components';
import * as Icon from 'assets/icons';
import {
  useGetAccountListForEmbedProcedures,
  useGetProceduresForEmbedProcedure,
  useGetPreviewForEmbedProcedure,
} from 'hooks';
import { ProcedureList, ProcedureListType } from 'types/procedureBuilder';
import { apiBaseUrl } from '../../../utils/urls';
import { Account } from 'types/accounts';
import { queryCache } from 'react-query';

type EmbeddedProcedureProps = {
  showPanel: boolean;
  accountId: number;
  togglePanel: (
    params:
      | {
          account: string;
          procedureName: string;
        }
      | undefined,
  ) => void;
};

const pageLength = 20;

export default function EmbeddedProcedure({
  showPanel,
  togglePanel: toggle,
  accountId,
}: EmbeddedProcedureProps): ReactElement {
  const [isVisible, setIsVisible] = useState(false);
  const [random, setRandom] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(showPanel);
    }, 10);
  }, [showPanel]);

  function togglePanel(
    params:
      | {
          account: string;
          procedureName: string;
        }
      | undefined,
  ) {
    setIsVisible(false);
    setTimeout(() => {
      toggle(params);
    }, 500);
  }
  const [keyword, setKeyword] = useState<string>('');
  const [procedureKeyword, setProcedureKeyword] = useState<string>('');
  const [activeAccount, setActiveAccount] = useState<number>();
  const [activeProcedure, setActiveProcedure] = useState<number>();
  const [activeAccountNumber, setActiveAccountccountNumber] =
    useState<string>('');
  const [activeProcedureName, setActiveProcedureName] = useState<string>('');
  const [procedureList, setProcedureList] = useState<Array<ProcedureListType>>(
    [],
  );

  const resetPanel = () => {
    togglePanel(undefined);
    setActiveAccount(undefined);
    setActiveProcedure(undefined);
    setProcedureList([]);
    setKeyword('');
    setProcedureKeyword('');
  };
  const procedureIframeRef = useRef<HTMLIFrameElement>(null);

  const setEmbedProcedure = (
    activeAccount: number | undefined,
    activeProcedureName: string,
  ) => {
    togglePanel({
      account: `${activeAccount}`,
      procedureName: activeProcedureName,
    });
    setActiveAccount(undefined);
    setActiveProcedure(undefined);
    setProcedureList([]);
    setKeyword('');
    setProcedureKeyword('');
  };
  const procedureActions = () => {
    return (
      <>
        <Button
          variant="secondary"
          onClick={() => {
            resetPanel();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setEmbedProcedure(activeAccount, activeProcedureName);
            //   togglePanel({
            //     account: `${activeAccount}`,
            //     procedureName: activeProcedureName,
            //   });
            //   setActiveAccount(undefined);
            //   setActiveProcedure(undefined);
            //   setProcedureList([]);
          }}
        >
          Save
        </Button>
      </>
    );
  };

  const { data: accountProcedures, isLoading: isAccountProceduresLoading } =
    useGetProceduresForEmbedProcedure({
      accountId: activeAccount,
      showHidden: true,
    });

  useEffect(() => {
    if (accountProcedures) {
      const data = [...accountProcedures];
      setProcedureList(data);
    }
  }, [accountProcedures]);

  // const {
  //   data: previewContent,
  //   isLoading: isPreviewContentLoading,
  // } = useGetPreviewForEmbedProcedure({
  //   id: activeAccountNumber,
  //   procedureName: activeProcedureName,
  // });

  const {
    data: accountsData,
    fetchMore,
    isLoading: isAccountListLoading,
    isFetching,
  } = useGetAccountListForEmbedProcedures(accountId, keyword);

  const accountList = useMemo(() => {
    return accountsData?.flat();
  }, [accountsData]);
  // const onEndReached = () => {
  //   if (canFetchMore) fetchMore();
  // };

  const tbodyRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleScroll = useCallback(
    (e: any) => {
      if (tbodyRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom) {
          const localData: Array<Array<Account>> | undefined =
            queryCache.getQueryData([
              'getAccountForEmbedProcedures',
              accountId,
              keyword,
            ]);
          if (
            localData &&
            localData[localData.length - 1].length === pageLength
          ) {
            fetchMore();
            // setKeyword('');
          }
        }
      }
    },
    [accountId, fetchMore, keyword],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  const handleSelectedProcedure = (procedure: ProcedureListType) => {
    const accountNumber = procedure?.Account
      ? `${procedure.Account?.CID}${'-'}${procedure.Account?.SID}${'-'}${
          procedure.Account?.PID
        }`
      : '';
    setActiveProcedure(procedure?.ProcedureId);
    setActiveProcedureName(procedure?.Procedure?.Name);
    setActiveAccountccountNumber(accountNumber);
  };

  const handleSearchKeyword = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setKeyword(value);
  };

  const handleProcedureSearch = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setProcedureKeyword(value);
    let newProcedures: Array<ProcedureListType> | undefined = [];

    newProcedures =
      accountProcedures &&
      accountProcedures.filter(item => {
        const searchTerm = `${item.Procedure.Name}`;
        if (searchTerm.toLowerCase().includes(value.toLowerCase())) return item;
      });
    if (newProcedures) setProcedureList([...newProcedures]);
  };

  return (
    <SlidePanel
      open={isVisible}
      onClose={() => {
        resetPanel();
      }}
      size="lg"
      title="Select Procedure"
      actions={procedureActions()}
    >
      <Flex flexDirection="column" height="100%">
        <Stack direction="x" gap={3}>
          <Box flex="1">
            <FormLabel>Accounts</FormLabel>
            <SearchableList>
              <ListSearch>
                <input
                  type="search"
                  placeholder="Search account name / CID"
                  value={keyword}
                  onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                    handleSearchKeyword(e)
                  }
                />
                <Icon.Search />
              </ListSearch>
              <ListBody ref={tbodyRef}>
                {(isAccountListLoading || isFetching) && <Loader />}
                <List>
                  {accountList &&
                    accountList.map(accounts => (
                      <li
                        className={
                          accounts?.Id === activeAccount ? 'active' : ''
                        }
                        key={accounts?.Id}
                        title={accounts?.AccountNameAndNumber}
                        onClick={() => {
                          if (accounts) setActiveAccount(accounts.Id);
                        }}
                      >
                        {accounts?.AccountNameAndNumber}
                      </li>
                    ))}
                </List>
              </ListBody>
            </SearchableList>
          </Box>
          <Box flex="1">
            <FormLabel>Procedures in the selected account</FormLabel>
            <SearchableList>
              <ListSearch>
                <input
                  type="search"
                  placeholder="Search procedure names "
                  value={procedureKeyword}
                  onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                    handleProcedureSearch(e)
                  }
                />
                <Icon.Search />
              </ListSearch>
              <ListBody>
                {isAccountProceduresLoading && <Loader />}
                <List>
                  {procedureList &&
                    procedureList.map(procedure => (
                      <li
                        className={
                          procedure.ProcedureId === activeProcedure
                            ? 'active'
                            : ''
                        }
                        key={procedure.ProcedureId}
                        title={procedure.Procedure.Name}
                        onClick={() => handleSelectedProcedure(procedure)}
                      >
                        {procedure.Procedure.Name}
                      </li>
                    ))}
                </List>
              </ListBody>
            </SearchableList>
          </Box>
        </Stack>
        {activeProcedure ? (
          <Box flex="1" mt={4} style={{ position: 'relative' }}>
            {/* {isPreviewContentLoading && <Loader />} */}
            <Iframe
              ref={procedureIframeRef}
              key={random}
              src={`${apiBaseUrl}procedures/V1/accountfolders/${activeAccountNumber}/Policies/${activeProcedureName}/isEditable=false`}
            />

            <Flex
              style={{ gap: `0.5rem`, paddingBlockEnd: '1rem' }}
              justifyContent="flex-end"
            >
              <Alert
                variant="info"
                icon={<Icon.ExclamationCircle />}
                style={{
                  padding: '0.25rem 0.5rem',
                  borderRadius: '3px',
                }}
              >
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text color="currentColor">
                    Please click the <strong>procedure refresh button</strong>{' '}
                    if the procedure is not loaded properly.
                  </Text>
                </Flex>
              </Alert>
              <Button
                variant="primary"
                onClick={() => {
                  setRandom(random => random + 1);
                }}
                type="submit"
                fullWidth={false}
              >
                <Icon.Refresh title="" />
              </Button>
            </Flex>
          </Box>
        ) : null}
      </Flex>
    </SlidePanel>
  );
}
