import React, {
  ReactElement,
  SyntheticEvent,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { Flex, Text, Box } from 'rebass/styled-components';
import { confirmAlert } from 'utils/confirm-alert';
import {
  FormSelect,
  FormInput,
  Loader,
  EmptyState,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from 'ui/patterns';
import {
  Stack,
  Checkbox,
  Button,
  Grid,
  DataTable,
  DataTableWrapper,
  StyledSelect,
} from 'ui/components';
import NoDataImage from 'assets/images/NoDataImage';
import { useGetLookups, useGetCustomTableWithRecords } from 'hooks';
import { LookupQuestionType, DependencyItemType } from '../questionTypes';
import { QuestionTypeInput } from 'types/questions';
import { SectionTypeInput } from 'types/forms';
import * as Icon from 'assets/icons';

type RecordItemType = {
  Id: string;
  Values: Array<string>;
  Type: string;
};

type RemovedTemplateItemType = {
  Id: string;
  Values: Array<string>;
};

export default function LookupQuestion(
  LookupQuestionProps: LookupQuestionType,
): ReactElement {
  const {
    accountId,
    selectedQuestionType,
    associatedPersons,
    register,
    control,
    Controller,
    watch,
    mappedColumns,
    setMappedColumns,
    sections,
    currentQuestion,
    setLookupDependency,
    lookupDependency,
    handleFormandQuestionSave,
  } = LookupQuestionProps;

  const { data: lookUps } = useGetLookups(accountId);
  const customLookupId = watch('CustomLookupId');
  const { data: customTableWithRecords, isLoading: isCustomTableLoading } =
    useGetCustomTableWithRecords({ accountId, lookupId: customLookupId });

  const columns = useMemo(() => {
    return customTableWithRecords?.Columns || [];
  }, [customTableWithRecords]);

  const records = useMemo(() => {
    return customTableWithRecords?.Records || [];
  }, [customTableWithRecords]);

  useEffect(() => {
    let columnsMapped: {
      LookupColumnName: string;
      TemplateFieldName: string | null;
      SendToAccount: boolean;
    }[] = [];
    if (
      columns?.length > 0 &&
      selectedQuestionType?.QuestionTypeOptions?.CustomLookupId !==
        customLookupId
    ) {
      columnsMapped = columns.map(item => {
        return {
          LookupColumnName: item,
          TemplateFieldName: '',
          SendToAccount: true,
        };
      });
      setMappedColumns(columnsMapped);
    } else {
      if (selectedQuestionType?.QuestionTypeOptions?.MappedLookupColumns)
        setMappedColumns(
          selectedQuestionType?.QuestionTypeOptions?.MappedLookupColumns,
        );
    }
  }, [columns, customLookupId, selectedQuestionType, setMappedColumns]);

  const [displayRecords, setDisplayRecords] = useState<boolean>(false);
  const [selectedRecordItem, setRecordItem] = useState<RecordItemType>();

  const [showAddDependency, setAddDependency] = useState<boolean>(false);
  const [isFormSaved, setFormSaved] = useState<boolean>(false);
  const [removeDependencyItem, setRemoveDependency] =
    useState<RemovedTemplateItemType>();

  const [showRemoveDependencyTable, setShowRemoveDependencyTable] =
    useState<boolean>(false);
  const handleChangeDisplayRecords = () => {
    setDisplayRecords(!displayRecords);
  };

  const fieldNames = selectedQuestionType?.QuestionTypeOptions?.FieldNames;

  useEffect(() => {
    let isQuestionSaved, isSectionSaved, unsavedSection, unsavedQuestion;
    if (sections && sections.length > 0) {
      unsavedSection = sections.find(item => item.Type === undefined);
      if (unsavedSection) isSectionSaved = false;
      else isSectionSaved = true;
      unsavedQuestion = sections.filter(sectionItem => {
        return (
          sectionItem.Questions &&
          sectionItem.Questions.find(
            (questionItem: QuestionTypeInput) =>
              questionItem.Order === undefined,
          )
        );
      });
      if (unsavedQuestion && unsavedQuestion.length > 0)
        isQuestionSaved = false;
      else isQuestionSaved = true;
    }

    const isFormSaved =
      isSectionSaved &&
      isQuestionSaved &&
      Object.keys(currentQuestion).length !== 0;
    if (isFormSaved) setFormSaved(isFormSaved);
  }, [selectedQuestionType, sections, currentQuestion]);

  const selectedSectionId = watch('DependentSectionId');
  const selectedQuestionId = watch('DependentQuestionId');

  const dependencySectionList = useMemo(() => {
    if (sections?.length > 0 && selectedRecordItem?.Type === 'Question') {
      return sections
        .filter(
          item =>
            item.$type ===
            'Proteus2.Api.Domain.Surveys.Section, Proteus2.Api.Domain',
        )
        .map(item => {
          return {
            id: item.Id,
            name: item.Title,
          };
        });
    }

    // filtering out the current section from dependency list
    if (selectedRecordItem?.Type === 'Section' && sections?.length > 0) {
      const filteredSections = sections.filter(
        item => item.Id !== currentQuestion?.SectionId,
      );
      return filteredSections?.map(item => {
        if (item.Type === 1) {
          return {
            id: item.Id,
            name: `${item?.Section?.Title} (Global Section)`,
          };
        } else
          return {
            id: item.Id,
            name: item.Title,
          };
      });
    }
  }, [currentQuestion, sections, selectedRecordItem]);

  const dependencyQuestionList = useMemo(() => {
    if (sections && sections.length > 0) {
      const selectedSection = sections.find(
        item => item.Id === selectedSectionId,
      );
      return (
        selectedSection?.Questions?.length > 0 &&
        selectedSection?.Questions?.filter(
          (qItem: QuestionTypeInput) => qItem.Id !== currentQuestion.Id,
        ).map((questionItem: QuestionTypeInput) => {
          return {
            id: questionItem.Id,
            name: questionItem.FieldLabel,
          };
        })
      );
    }
  }, [sections, selectedSectionId, currentQuestion]);

  const handleChangeMappedField = (item: string, value: string) => {
    let mappedArray = [...mappedColumns];
    const columnItem =
      mappedColumns &&
      mappedColumns.find(mappedItem => mappedItem.LookupColumnName === item);
    if (columnItem) {
      const checkedArray =
        mappedArray &&
        mappedArray.map(mappedItem => {
          if (columnItem === mappedItem) {
            return {
              ...mappedItem,
              TemplateFieldName: value,
            };
          } else
            return {
              ...mappedItem,
            };
        });
      mappedArray = [...checkedArray];
    }

    setMappedColumns(mappedArray);
  };

  const handleValue = (item: string): any => {
    const value =
      mappedColumns &&
      mappedColumns.length > 0 &&
      mappedColumns.find(mappedItem => mappedItem.LookupColumnName === item);
    if (value) {
      return value.TemplateFieldName;
    } else return '';
  };

  const handleCheckReport = (item: string) => {
    const checkedItem =
      mappedColumns &&
      mappedColumns.find(mappedItem => mappedItem.LookupColumnName === item);
    if (checkedItem && checkedItem.SendToAccount) {
      return true;
    } else return false;
  };

  const handleChangeReport = (item: string) => {
    const columnItem =
      mappedColumns &&
      mappedColumns.find(mappedItem => mappedItem.LookupColumnName === item);
    const mappedArray = [...mappedColumns];

    if (columnItem) {
      const checkedArray =
        mappedArray &&
        mappedArray.map(mappedItem => {
          if (columnItem === mappedItem) {
            return {
              ...mappedItem,
              SendToAccount: !mappedItem.SendToAccount,
            };
          } else
            return {
              ...mappedItem,
            };
        });
      setMappedColumns(checkedArray);
    }
  };

  const handleSaveLookupDependency = () => {
    const allDependencies = [...lookupDependency];
    let dependentSectionId: Array<number> = [];
    let dependentQuestionId: Array<number> = [];
    if (allDependencies && allDependencies.length > 0) {
      const dependentObj = allDependencies.find(
        item => item.RecordId === selectedRecordItem?.Id,
      );
      if (dependentObj) {
        dependentSectionId = dependentObj.DependentSectionIds;
        dependentQuestionId = dependentObj.DependentQuestionIds;
      }
    }
    if (selectedRecordItem?.Type === 'Section') {
      dependentSectionId.push(selectedSectionId);
    }

    if (selectedRecordItem?.Type === 'Question') {
      dependentQuestionId.push(selectedQuestionId);
    }

    const index = allDependencies?.findIndex(
      (item: DependencyItemType) => item.RecordId === selectedRecordItem?.Id,
    );

    const dependentParams = {
      RecordId: selectedRecordItem?.Id || '',
      DependentSectionIds: dependentSectionId,
      DependentQuestionIds: dependentQuestionId,
    };

    if (index === -1) {
      allDependencies.push(dependentParams);
    } else if (index >= 0) {
      allDependencies.splice(index, 1, dependentParams);
    }
    if (allDependencies) {
      setLookupDependency(allDependencies);
      setAddDependency(false);
      if (selectedRecordItem) {
        setRemoveDependency({
          Id: selectedRecordItem.Id,
          Values: selectedRecordItem.Values,
        });
      }
      setShowRemoveDependencyTable(true);
    }
  };

  // to show the eye icon
  const DependentList = useMemo(() => {
    let tempItem = records?.map(item =>
      lookupDependency.find(lookUpItem => lookUpItem.RecordId === item?.Id),
    );
    tempItem = tempItem.filter(item => item !== undefined);
    return tempItem;
  }, [lookupDependency, records]);

  const handleRemoveQuestionDependency = (item: QuestionTypeInput) => {
    let dependentQuestions: number[] = [];
    let dependentSections: number[] = [];
    if (lookupDependency?.length > 0) {
      const dependencies = lookupDependency?.find(
        item => item.RecordId === removeDependencyItem?.Id,
      );
      if (dependencies) {
        dependentQuestions = dependencies?.DependentQuestionIds;
        dependentSections = dependencies?.DependentSectionIds;
      }

      const index = dependentQuestions?.findIndex(
        (dependentItem: number) => item.Id === dependentItem,
      );
      if (index >= 0) {
        dependentQuestions && dependentQuestions.splice(index, 1);
      }
      const updatedDependency = lookupDependency?.map(item => {
        if (item.RecordId === removeDependencyItem?.Id) {
          return {
            ...item,
            DependentQuestionIds: [...dependentQuestions],
          };
        } else {
          return {
            ...item,
          };
        }
      });

      if (updatedDependency) setLookupDependency(updatedDependency);
      // if (dependentSections && dependentSections.length === 0)
      if (selectedRecordItem) {
        setRemoveDependency({
          Id: selectedRecordItem.Id,
          Values: selectedRecordItem.Values,
        });
      }
      setShowRemoveDependencyTable(true);
    }
  };

  const handleRemoveSectionDependency = (item: SectionTypeInput) => {
    let dependentSections: number[] = [];
    let dependentQuestions: number[] = [];
    if (lookupDependency?.length > 0) {
      const dependencies = lookupDependency?.find(
        item => item.RecordId === removeDependencyItem?.Id,
      );
      if (dependencies) {
        dependentSections = dependencies?.DependentSectionIds;
        dependentQuestions = dependencies?.DependentQuestionIds;
      }

      const index = dependentSections?.findIndex(
        (dependentItem: number) => item.Id === dependentItem,
      );
      if (index >= 0) {
        dependentSections && dependentSections.splice(index, 1);
      }
      const updatedDependency = lookupDependency?.map(item => {
        if (item.RecordId === removeDependencyItem?.Id) {
          return {
            ...item,
            DependentSectionIds: [...dependentSections],
          };
        } else {
          return {
            ...item,
          };
        }
      });
      if (updatedDependency) setLookupDependency(updatedDependency);
      // if (dependentQuestions && dependentQuestions.length === 0)
      if (selectedRecordItem) {
        setRemoveDependency({
          Id: selectedRecordItem.Id,
          Values: selectedRecordItem.Values,
        });
      }
      setShowRemoveDependencyTable(true);
    }
  };

  const handleDependencyWarningMsg = () => {
    let message = '';
    if (selectedRecordItem?.Type === 'Question')
      message = 'Please select the Question to continue.';
    else if (selectedRecordItem?.Type === 'Section')
      message = 'Please select the Section to continue.';
    confirmAlert({
      message: message,
      buttons: [
        {
          label: 'Ok',
          onClick: () => {
            //
          },
          className: 'pr-btn-primary',
        },
      ],
    });
  };

  const handleDependencyAlert = () => {
    confirmAlert({
      message:
        'Form needs to be saved before adding dependencies. Do you want to Proceed?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            handleFormandQuestionSave();
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  const handleRemoveDependencyWarning = (type: string, item: any) => {
    confirmAlert({
      message: 'Do you want to remove this dependency?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            if (type === 'Section') handleRemoveSectionDependency(item);
            if (type === 'Question') handleRemoveQuestionDependency(item);
          },
          className: 'pr-btn-primary',
        },
        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  const renderCustomColumnTable = () => {
    return (
      <DataTableWrapper style={{ minHeight: '300px' }}>
        <DataTable className="equal-width-columns">
          <thead>
            <tr>
              <th>Account Specific Column Name</th>
              <th>Standard Column Name</th>
              <th style={{ textAlign: 'center' }}>Show on Report</th>
            </tr>
          </thead>
          <tbody>
            {columns &&
              columns.map((item, index) => (
                <tr key={index}>
                  <td>{item}</td>
                  <td>
                    <StyledSelect
                      onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                        handleChangeMappedField(item, e.currentTarget.value);
                      }}
                      value={handleValue(item)}
                    >
                      <option>Not mapped</option>

                      {fieldNames &&
                        fieldNames
                          .filter((fieldItem: string) => {
                            if (mappedColumns && mappedColumns.length > 0) {
                              const mappedItemIndex = mappedColumns.findIndex(
                                columnItem =>
                                  columnItem.TemplateFieldName === fieldItem &&
                                  columnItem.LookupColumnName !== item,
                              );
                              return mappedItemIndex === -1;
                            }
                            return true;
                          })
                          .map((mappedItem: string, mappedIndex: number) => (
                            <option key={mappedIndex}>{mappedItem}</option>
                          ))}
                    </StyledSelect>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Checkbox
                      label=""
                      inline
                      name={`report${item}`}
                      checked={handleCheckReport(item)}
                      handleChange={() => handleChangeReport(item)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </DataTable>
        {isCustomTableLoading && <Loader />}
      </DataTableWrapper>
    );
  };

  const renderCustomRecordsTable = () => {
    return (
      <DataTableWrapper style={{ minHeight: '300px', marginBottom: '16px' }}>
        <DataTable className={columns.length <= 4 ? 'equal-width-columns' : ''}>
          <thead>
            <tr>
              {columns &&
                columns.map((item, index) => <th key={index}>{item}</th>)}
              <th>Dependencies</th>
            </tr>
          </thead>
          <tbody>
            {records &&
              records.map((recordItem, recordIndex) => {
                const isDependentExist =
                  DependentList &&
                  DependentList.find(item => item?.RecordId === recordItem?.Id);
                return (
                  <tr key={recordIndex}>
                    {recordItem.Values.map((valueItem, valueIndex) => (
                      <td key={valueIndex}>{valueItem}</td>
                    ))}
                    <td>
                      <Stack direction="x" gap={3}>
                        <Button
                          key={recordItem.Id}
                          size="sm"
                          variant={
                            isDependentExist &&
                            isDependentExist !== undefined &&
                            (isDependentExist?.DependentQuestionIds.length >
                              0 ||
                              isDependentExist?.DependentSectionIds.length > 0)
                              ? 'secondary'
                              : undefined
                          }
                          title="View dependencies"
                          style={{ minWidth: '34px' }}
                          disabled={
                            isDependentExist &&
                            (isDependentExist?.DependentQuestionIds.length >
                              0 ||
                              isDependentExist?.DependentSectionIds.length > 0)
                              ? false
                              : true
                          }
                          iconBefore={
                            isDependentExist &&
                            (isDependentExist?.DependentQuestionIds.length >
                              0 ||
                              isDependentExist?.DependentSectionIds.length >
                                0) ? (
                              <Icon.Eye title="View dependencies" />
                            ) : (
                              ''
                            )
                          }
                          onClick={() => {
                            setRemoveDependency({
                              Id: recordItem.Id,
                              Values: recordItem.Values,
                            });
                            setShowRemoveDependencyTable(true);
                            setAddDependency(false);
                          }}
                        />
                        <Button
                          size="sm"
                          variant="secondary"
                          style={{ whiteSpace: 'nowrap', minHeight: '30px' }}
                          onClick={() => {
                            if (isFormSaved === false) handleDependencyAlert();
                            else {
                              setRecordItem({
                                Type: 'Question',
                                Id: recordItem.Id,
                                Values: recordItem.Values,
                              });
                              setAddDependency(true);
                            }
                          }}
                        >
                          Add Question
                        </Button>
                        <Button
                          size="sm"
                          variant="secondary"
                          style={{ whiteSpace: 'nowrap', minHeight: '30px' }}
                          onClick={() => {
                            if (isFormSaved === false) handleDependencyAlert();
                            else {
                              setRecordItem({
                                Type: 'Section',
                                Id: recordItem.Id,
                                Values: recordItem.Values,
                              });
                              setAddDependency(true);
                            }
                          }}
                        >
                          Add Section
                        </Button>
                      </Stack>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </DataTable>
        {!isCustomTableLoading && records && records.length <= 0 ? (
          <EmptyState>
            <NoDataImage feedback="No data available" />
          </EmptyState>
        ) : null}
      </DataTableWrapper>
    );
  };

  const renderAddDependency = () => {
    return (
      <Card>
        <CardHeader p={3}>
          <Text fontWeight="medium">{`Add ${selectedRecordItem?.Type} to ${selectedRecordItem?.Values[0]}`}</Text>
        </CardHeader>
        <CardBody p={3}>
          <Stack direction="y" gap={2} maxWidth="350px">
            <Controller
              render={(props: {
                onChange: (value: number) => void;
                value: number;
              }) => {
                return (
                  <FormSelect
                    value={props.value}
                    label="Sections"
                    options={dependencySectionList}
                    onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                      const value = parseInt(e.currentTarget.value, 10);
                      props.onChange(value);
                    }}
                  />
                );
              }}
              defaultValue={''}
              name="DependentSectionId"
              control={control}
            />
            {selectedRecordItem?.Type === 'Question' && (
              <Controller
                render={(props: {
                  onChange: (value: number) => void;
                  value: number;
                }) => {
                  return (
                    <FormSelect
                      value={props.value}
                      label="Questions"
                      options={dependencyQuestionList}
                      onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                        const value = parseInt(e.currentTarget.value, 10);
                        props.onChange(value);
                      }}
                    />
                  );
                }}
                defaultValue={''}
                name="DependentQuestionId"
                control={control}
              />
            )}
          </Stack>
          <Stack direction="x" gap={2} mt={3}>
            <Button variant="secondary" onClick={() => setAddDependency(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (
                  selectedRecordItem?.Type === 'Question' &&
                  (selectedSectionId === '' ||
                    selectedSectionId === undefined ||
                    selectedQuestionId === '' ||
                    selectedQuestionId === undefined)
                )
                  handleDependencyWarningMsg();
                else if (
                  selectedRecordItem?.Type === 'Section' &&
                  (selectedSectionId === '' || selectedSectionId === undefined)
                )
                  handleDependencyWarningMsg();
                else handleSaveLookupDependency();
              }}
            >
              Save
            </Button>
          </Stack>
        </CardBody>
      </Card>
    );
  };

  const renderRemoveDependencies = () => {
    let dependentSections: number[] = [];
    let dependentQuestions: number[] = [];
    if (lookupDependency?.length > 0) {
      const dependencies = lookupDependency?.find(
        item => item.RecordId === removeDependencyItem?.Id,
      );
      if (dependencies) {
        dependentSections = dependencies?.DependentSectionIds;
        dependentQuestions = dependencies?.DependentQuestionIds;
      }
    }
    let sectionsList: SectionTypeInput[] = [];
    if (sections?.length > 0)
      sectionsList = sections?.filter(item => {
        return dependentSections?.find(
          (dependentItem: number) => item.Id === dependentItem,
        );
      });
    let questionList: QuestionTypeInput[] = [];
    if (sections?.length > 0) {
      questionList = sections
        ?.filter(
          item =>
            item.$type ===
            'Proteus2.Api.Domain.Surveys.Section, Proteus2.Api.Domain',
        )
        .map(item =>
          item?.Questions.filter((questionItem: any) => {
            return dependentQuestions?.find(
              (dependentItem: number) => questionItem.Id === dependentItem,
            );
          }),
        )
        .flat();
    }
    const hasDependency = sectionsList?.length > 0 || questionList?.length > 0;

    const findParentSection = (questionItem: QuestionTypeInput) => {
      const sectionSelected = sections.find(
        item => item.Id === questionItem.SectionId,
      );
      return sectionSelected.Title;
    };

    return (
      <>
        <Card>
          <CardHeader p={3}>
            <Text fontWeight="medium">
              {`Dependencies for ${removeDependencyItem?.Values[0]}`}
            </Text>
          </CardHeader>
          <CardBody p={3} style={{ position: 'relative' }} minHeight={240}>
            {hasDependency ? (
              <Stack direction="x" gap={3}>
                {questionList && questionList?.length > 0 && (
                  <Box flex={1}>
                    <Text fontWeight="medium" mb={2}>
                      Questions
                    </Text>
                    <DataTableWrapper style={{ minHeight: '200px' }}>
                      <DataTable className="equal-width-columns">
                        <thead>
                          <tr>
                            <th>Section</th>
                            <th>Field Label</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {questionList?.map(
                            (questionItem: QuestionTypeInput) => (
                              <tr key={questionItem.Id}>
                                <td style={{ verticalAlign: 'middle' }}>
                                  {findParentSection(questionItem)}
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>
                                  {questionItem.FieldLabel}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  <Button
                                    size="sm"
                                    variant="danger"
                                    iconAfter={
                                      <Icon.Delete title="Remove dependency" />
                                    }
                                    onClick={() =>
                                      handleRemoveDependencyWarning(
                                        'Question',
                                        questionItem,
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </DataTable>
                    </DataTableWrapper>
                  </Box>
                )}

                {sectionsList && sectionsList?.length > 0 && (
                  <Box flex={1}>
                    <Text fontWeight="medium" mb={2}>
                      Sections
                    </Text>
                    <DataTableWrapper style={{ minHeight: '200px' }}>
                      <DataTable className="equal-width-columns">
                        <thead>
                          <tr>
                            <th>Section Title</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {sectionsList?.map((item: SectionTypeInput) => (
                            <tr key={item.Id}>
                              <td style={{ verticalAlign: 'middle' }}>
                                {item?.$type ===
                                'Proteus2.Api.Domain.Surveys.Section, Proteus2.Api.Domain'
                                  ? item.Title
                                  : item.Section.Title}
                              </td>
                              <td style={{ textAlign: 'right' }}>
                                <Button
                                  size="sm"
                                  variant="danger"
                                  iconAfter={
                                    <Icon.Delete title="Remove dependency" />
                                  }
                                  onClick={() =>
                                    handleRemoveDependencyWarning(
                                      'Section',
                                      item,
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </DataTable>
                    </DataTableWrapper>
                  </Box>
                )}
              </Stack>
            ) : (
              <EmptyState>
                <NoDataImage feedback="No data available" />
              </EmptyState>
            )}
          </CardBody>
          <CardFooter p={3} display="flex" justifyContent="flex-end">
            <Button
              variant="secondary"
              onClick={() => {
                setRemoveDependency(undefined);
                setShowRemoveDependencyTable(false);
              }}
            >
              Close dependencies
            </Button>
          </CardFooter>
        </Card>
      </>
    );
  };
  return (
    <Flex as="form" flexDirection="column" height="100%" mb="50px">
      <Grid minColWidth="300px" gap={3}>
        <FormInput
          label="Field Name"
          disabled
          register={register}
          name="FieldName"
        />
        <FormInput
          label="Original Field Label"
          disabled
          register={register}
          name="FieldLabel"
        />
        <FormInput
          label="Description of Use"
          disabled
          register={register}
          name="Description"
        />
        <FormInput
          label="Field Label"
          register={register}
          name="UpdatedFieldLabel"
        />
        <Controller
          render={(props: {
            onChange: (value: number) => void;
            value: number;
          }) => {
            return (
              <FormSelect
                value={props.value}
                label="Person Association"
                noSelectOption
                options={associatedPersons}
                onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                  const value = parseInt(e.currentTarget.value, 10);
                  props.onChange(value);
                }}
              />
            );
          }}
          defaultValue={''}
          name="PersonAssociation"
          control={control}
        />
      </Grid>
      <Grid minColWidth="250px" gap={3} mt={5}>
        <Checkbox label="Required" register={register} name="IsRequired" />
        <Checkbox
          label="Never send to Account"
          register={register}
          name="IsNeverSentToAccount"
        />
        <Checkbox
          label="If no answer, don't send to Account"
          register={register}
          name="IsNotSentToAccountIfNoAnswer"
        />
      </Grid>
      <Stack direction="y" gap={3} mt={3}>
        <Grid minColWidth="300px" gap={3} my={3}>
          <Controller
            render={(props: {
              onChange: (value: number) => void;
              value: number;
            }) => {
              return (
                <FormSelect
                  value={props.value}
                  label="Custom Table"
                  options={lookUps}
                  onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                    const value = parseInt(e.currentTarget.value, 10);
                    props.onChange(value);
                    setDisplayRecords(false);
                  }}
                />
              );
            }}
            defaultValue={''}
            name="CustomLookupId"
            control={control}
          />

          <div aria-hidden="true"></div>
          <div aria-hidden="true"></div>
          <div aria-hidden="true"></div>
          <div aria-hidden="true"></div>
        </Grid>
        {customLookupId ? (
          <>
            {columns && columns.length > 0 && renderCustomColumnTable()}
            {!isCustomTableLoading && columns && columns.length > 0 && (
              <Box pt={3} mb={4}>
                <Checkbox
                  name="displayRecords"
                  label="Display Records?"
                  handleChange={handleChangeDisplayRecords}
                  checked={displayRecords}
                />
              </Box>
            )}
            {displayRecords &&
              !showAddDependency &&
              !showRemoveDependencyTable &&
              renderCustomRecordsTable()}
            {showAddDependency && renderAddDependency()}
          </>
        ) : null}
      </Stack>

      {showRemoveDependencyTable && renderRemoveDependencies()}
      <Box pt={3} mb={4}>
        <Checkbox
          name="IsMultiValuesAllowed"
          label="Allow Multiple Answers"
          register={register}
        />
      </Box>
    </Flex>
  );
}
