import { useQuery, QueryResult } from 'react-query';
import { getRelationshipManagers } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchRelationshipManagers(): Promise<APIResult> {
  const response = await fetch(getRelationshipManagers, {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type RelationshipManagerResultItem = {
  Email?: string;
  FirstName: string;
  Id: number;
  LastName: string;
  Phone?: string;
};

export type APIResult = Array<RelationshipManagerResultItem>;

export type ResultItem = {
  id: number;
  name: string;
};
export type TResult = Array<ResultItem>;

export type TError = { message: string };

function useRelationshipManagers(): QueryResult<TResult, TError> {
  return useQuery(
    'get_relationship_managers',
    async () => {
      const fetchResponse = await fetchRelationshipManagers();
      const result = fetchResponse.map(item => ({
        id: item.Id,
        name: `${item.FirstName} ${item.LastName}`,
      }));
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useRelationshipManagers };
