import React, { useEffect, useState, ReactElement } from 'react';
import { Redirect, Route } from 'react-router-dom';

type propTypes = {
  permission: boolean | undefined;
  exact?: boolean;
  component: any;
  path: string;
};

const CustomRoute = (props: propTypes): ReactElement => {
  const [returnedRoute, setReturnedRoute] = useState<any>();
  useEffect(() => {
    if (props?.permission) return setReturnedRoute(<Route {...props} />);
    else return setReturnedRoute(<Redirect to="/home/unauthorized" />);
  }, [props]);
  return <>{returnedRoute}</>;
};

export default CustomRoute;
