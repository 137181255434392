/* eslint-disable react/display-name */
import React from 'react';
import { Column, TableInstance, ColumnInstance } from 'react-table';
import { Forms } from 'types/forms';
import Header from './tableHeader';
import FormsOptions from './tableFormsOptions';
import { getFormatDate } from 'utils/dateHelper';

// function contains(array: Array<string>, string: string): boolean {
//   const index = array.findIndex(item => item === string);
//   return index !== -1;
// }

export default function useFormsColumns(
  columnsList?: Array<string>,
  skipOptions?: boolean,
): Array<Column<Forms>> {
  return React.useMemo((): Array<Column<Forms>> => {
    if (columnsList) {
      const columns = [];
      columnsList.forEach(columnName => {
        if (columnName === 'Id') columns.push(getIDHeader(Header));
        if (columnName === 'CID') columns.push(getCIDHeader(Header));
        if (columnName === 'Account')
          columns.push(getAccountNameHeader(Header));
        if (columnName === 'Last Updated By')
          columns.push(getLastUpdatedByHeader(Header));
        if (columnName === 'Date Last Updated')
          columns.push(getDateLastUpdatedHeader(Header));
        if (columnName === 'Form Status')
          columns.push(getFormStatusHeader(Header));
        if (columnName === 'Form Notes')
          columns.push(getFormNotesHeader(Header));
      });
      // if (contains(columnsList, 'CID')) columns.push(getCIDHeader(Header));
      // if (contains(columnsList, 'Account'))
      //   columns.push(getAccountNameHeader(Header));
      // if (contains(columnsList, 'Last Updated By'))
      //   columns.push(getLastUpdatedByHeader(Header));
      // if (contains(columnsList, 'Date Last Updated'))
      //   columns.push(getDateLastUpdatedHeader(Header));
      // if (contains(columnsList, 'Form Status'))
      //   columns.push(getFormStatusHeader(Header));
      // if (contains(columnsList, 'Form Notes'))
      //   columns.push(getFormNotesHeader(Header));

      if (!skipOptions) columns.push(getOptionsHeader());
      return columns;
    }
    return [
      getIDHeader(Header),
      getCIDHeader(Header),
      getAccountNameHeader(Header),
      getLastUpdatedByHeader(Header),
      getDateLastUpdatedHeader(Header),
      getFormStatusHeader(Header),
      getFormNotesHeader(Header),
      getOptionsHeader(),
    ];
  }, [columnsList, skipOptions]);
}

function getIDHeader(
  Header: (
    props: TableInstance<Forms> & {
      column: ColumnInstance<Forms>;
    },
  ) => JSX.Element,
): Column<Forms> {
  return {
    Header: Header,
    accessor: (row: Forms) => `${row.Id || ''}`,
    id: 'Id',
  };
}

function getCIDHeader(
  Header: (
    props: TableInstance<Forms> & {
      column: ColumnInstance<Forms>;
    },
  ) => JSX.Element,
): Column<Forms> {
  return {
    Header: Header,
    accessor: (row: Forms) => `${row.Account?.CID || ''}`,
    id: 'CID',
  };
}

function getAccountNameHeader(
  Header: (
    props: TableInstance<Forms> & {
      column: ColumnInstance<Forms>;
    },
  ) => JSX.Element,
): Column<Forms> {
  return {
    Header: Header,
    accessor: (row: Forms) => `${row.Account?.Name || ''}`,
    id: 'Account Name',
  };
}

function getLastUpdatedByHeader(
  Header: (
    props: TableInstance<Forms> & {
      column: ColumnInstance<Forms>;
    },
  ) => JSX.Element,
): Column<Forms> {
  return {
    id: 'Last Updated By',
    Header: Header,
    accessor: 'LastModifiedBy',
  };
}

function getDateLastUpdatedHeader(
  Header: (
    props: TableInstance<Forms> & {
      column: ColumnInstance<Forms>;
    },
  ) => JSX.Element,
): Column<Forms> {
  return {
    id: 'Date Last Updated',
    Header: Header,
    accessor: row => getFormatDate(row.LastModified),
  };
}

function getFormStatusHeader(
  Header: (
    props: TableInstance<Forms> & {
      column: ColumnInstance<Forms>;
    },
  ) => JSX.Element,
): Column<Forms> {
  return {
    Header: Header,
    accessor: (row: Forms) => `${row.SurveyStatus?.Description || ''}`,
    id: 'Form Status',
  };
}

function getFormNotesHeader(
  Header: (
    props: TableInstance<Forms> & {
      column: ColumnInstance<Forms>;
    },
  ) => JSX.Element,
): Column<Forms> {
  return {
    Header: Header,
    accessor: row => row.Notes,
    id: 'Form Notes',
  };
}

function getOptionsHeader(): Column<Forms> {
  return {
    id: 'Options',
    Header: 'Action',
    Cell: FormsOptions,
  };
}
