import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { AddEditQuestionType } from 'types/questions';
import { addQuestionToRepository, editQuestionInRepository } from 'utils/urls';
import { getTokenWithType } from './useAuth';

export async function saveQuestion({
  params,
}: {
  params: AddEditQuestionType;
}): Promise<TResult> {
  const response = await fetch(
    params.Id === 0
      ? addQuestionToRepository
      : editQuestionInRepository(params.Id),
    {
      method: params.Id === 0 ? 'POST' : 'PUT',
      headers: {
        Authorization: getTokenWithType(),
        'content-type': 'application/json',
      },
      body: JSON.stringify(params),
    },
  );
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = AddEditQuestionType & ValidationError;
export type TVariables = AddEditQuestionType;
export type TError = {
  Message: string;
};
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;

function useSaveQuestion(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: AddEditQuestionType) => {
      const newQuestion = await saveQuestion({
        params: params,
      });
      if (newQuestion.Message) throw newQuestion;
      if (newQuestion) {
        queryCache.invalidateQueries(['questions_list'], {
          refetchInactive: true,
        });
        queryCache.invalidateQueries(['get_question_detail'], {
          refetchInactive: true,
        });
      }
      return newQuestion;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveQuestion };
