import React, { ReactNode } from 'react';
import styled from 'styled-components';
import {
  FlexboxProps,
  flexbox,
  SpaceProps,
  space,
  LayoutProps,
  layout,
} from 'styled-system';

type SsProps = FlexboxProps & SpaceProps & LayoutProps;

type StyledClusterProps = SsProps & {
  gap: number;
};

const StyledCluster = styled.div<StyledClusterProps>`
  --space: ${({ theme, gap }) => theme.space[gap]};
  overflow: hidden;

  & > * {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: calc(var(--space) / 2 * -1);
  }

  & > * > * {
    margin: calc(var(--space) / 2);
  }

  ${flexbox}
  ${space}
	${layout}
`;

type ClusterProps = SsProps & {
  gap: number;
  children: ReactNode;
};

function Cluster({ gap, children, ...SsProps }: ClusterProps) {
  return (
    <StyledCluster gap={gap} {...SsProps}>
      {children}
    </StyledCluster>
  );
}

export { Cluster };
