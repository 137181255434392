import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { publishProcedure } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { ProcedureDetailsType } from 'types/procedureBuilder';

export async function postPublishProcedure({
  accountId,
  procedureId,
  makeProceduresVisible,
  params,
}: TArguments): Promise<TResult> {
  const response = await fetch(
    publishProcedure(accountId, makeProceduresVisible),
    {
      method: 'PUT',
      headers: {
        Authorization: getTokenWithType(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    },
  );
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = ProcedureDetailsType & ValidationError;

type ParamsType = Array<string>;

export type TError = { Message: string };
export type TArguments = {
  accountId: number;
  procedureId: number;
  params: ParamsType;
  makeProceduresVisible?: boolean;
};
export type TVariables = {
  accountId: number;
  procedureId: number;
  params: ParamsType;
  makeProceduresVisible?: boolean;
};
export type TSnapshot = unknown;

function usePublishProcedure(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async ({
      accountId,
      procedureId,
      params,
      makeProceduresVisible,
    }: TVariables) => {
      const postResult = await postPublishProcedure({
        accountId,
        procedureId,
        params: params,
        makeProceduresVisible,
      });

      if (postResult.Message) throw postResult;
      if (postResult) {
        queryCache.invalidateQueries(
          ['get_procedure_builder_procedures', accountId],
          {
            refetchInactive: true,
          },
        );
        // queryCache.invalidateQueries(['get_procedure', accountId], {
        //   refetchInactive: true,
        // });
      }
      return postResult;
    },
    {
      throwOnError: true,
    },
  );
}

export { usePublishProcedure };
