import React, { ReactElement } from 'react';
import { ColumnInstance } from 'react-table';
import { Account } from 'types/accounts';
import { FloatLabelInput, ButtonGroup } from 'ui/patterns';
import { Flex } from 'rebass/styled-components';
import Icon from 'assets/icons';

export default function TableHeader(
  props: {
    column: ColumnInstance<Account>;
  } & {
    headerUpdateCallBack?: ({
      columnId,
      value,
    }: {
      columnId: string;
      value: string;
    }) => void;
    handleSortChange?: (item: string) => void;
    sortedItem?: { sortKey: string; sortOrder: string };
    columnsSearch?: Record<string, string>;
  },
): ReactElement {
  const onChangeText = (value: string) => {
    props.headerUpdateCallBack &&
      props.headerUpdateCallBack({ columnId: props.column.id, value });
  };
  const onSortChange = () => {
    props.handleSortChange && props.handleSortChange(props.column.id);
  };

  // columnsSearch
  return (
    <Flex>
      <FloatLabelInput
        style={{ flexGrow: '1' }}
        label={props.column.id}
        onChangeText={onChangeText}
        value={
          (props.columnsSearch && props.columnsSearch[props.column.id]) || ''
        }
      />

      {props?.column?.id === 'Search Snippet' ? (
        ''
      ) : (
        <ButtonGroup onClick={onSortChange}>
          {props.sortedItem?.sortKey !== props.column.id && (
            <Icon.Sort title="" />
          )}
          {props.sortedItem?.sortKey === props.column.id &&
            props.sortedItem?.sortOrder === 'asc' && <Icon.SortUp title="" />}
          {props.sortedItem?.sortKey === props.column.id &&
            props.sortedItem?.sortOrder === 'desc' && (
              <Icon.SortDown title="" />
            )}
        </ButtonGroup>
      )}
    </Flex>
  );
}
