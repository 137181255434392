import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { CustomRecords } from 'types/customTables';
import { addRecords, editRecords } from 'utils/urls';
import { getTokenWithType } from './useAuth';

function uuidv4() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
}

export async function postCustomRecords({
  params,
}: {
  params: CustomRecords;
}): Promise<TResult> {
  const response = await fetch(
    params.Id === '0'
      ? addRecords(params.AccountId, params.TableId)
      : editRecords(params.AccountId, params.TableId, params.Id),
    {
      method: params.Id === '0' ? 'POST' : 'PUT',
      headers: {
        Authorization: getTokenWithType(),
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        ...params,
        Id: params.Id === '0' ? uuidv4() : params.Id,
      }),
    },
  );
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = CustomRecords & ValidationError;
export type TVariables = CustomRecords;
export type TError = {
  Message: string;
};
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;
// async function wait(millis: number) {
//   return new Promise(resolve => {
//     setTimeout(resolve, millis);
//   });
// }

function useSaveRecordsCustomTable(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: CustomRecords) => {
      const newContact = await postCustomRecords({
        params: params,
      });
      if (newContact.Message) throw newContact;
      queryCache.invalidateQueries(['custom_records']);
      queryCache.invalidateQueries(['custom_table']);
      // await wait(2000);
      return newContact;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveRecordsCustomTable };
