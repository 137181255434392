import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { contactItem as deleteContact } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { showSuccessToast } from 'utils/showToast';

async function deleteContacts({
  contactId,
  accountId,
}: TArguments): Promise<Response> {
  return fetch(deleteContact(accountId, contactId), {
    method: 'DELETE',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/json',
    },
  });
}

export type TResult = {
  message: string;
  isSuccess: boolean;
};

export type TError = { message: string };
export type TArguments = {
  contactId: number;
  accountId: number;
};
export type TVariables = {
  contactId: number;
  accountId: number;
};

function useDeleteContacts(
  contactId: number,
  accountId: number,
): MutationResultPair<void, TError, TVariables, unknown> {
  return useMutation(async () => {
    await deleteContacts({
      contactId,
      accountId,
    });
    queryCache.invalidateQueries('contact_list');
    showSuccessToast({
      message: 'Contact deleted successfully.',
    });
  });
}

export { useDeleteContacts };
