import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Card, CardBody, CardHeader } from 'ui/patterns';

export const AccordionWrapper = styled(Card)`
  &.pb-link-listitem,
  &.ab-version-accordion {
    border-radius: 0;
    border: none;
    box-shadow: none;
  }

  &.ab-version-accordion {
    border-bottom: solid 1px ${({ theme }) => theme.colors.border.default};
  }
`;

type AccordionHeaderProps = {
  opened?: boolean;
  headerContent?: ReactNode;
};

export const AccordionHeader = styled(CardHeader)<AccordionHeaderProps>`
  --bg: var(--color-neutral-contrast-00);
  display: flex;
  align-items: center;
  /* padding: ${({ theme }) => theme.space[3]}; */
  border-width: 0px;
  border-bottom-left-radius: ${({ theme }) => theme.radii.soft};
  border-bottom-right-radius: ${({ theme }) => theme.radii.soft};

  & > * + * {
    margin-left: ${({ theme }) => theme.space[3]};
  }

  .ab-version-accordion & {
    border-radius: 0;
    align-items: stretch;
    position: relative;

    button {
      border-radius: 0 !important;
      margin-left: 0;
      background: none;
      position: absolute;
      inset: 0;
      left: auto;
    }
  }

  .pr-icon {
    transform: rotate(0deg);
    transition: transform ease-out 0.3ms;
  }

  ${({ opened }) =>
    opened &&
    css`
      /* border-width: 1px; */
      transition: all 0.3s ease-out;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      .pr-icon {
        /* transform: rotate(180deg); */
      }
    `}

  .pb-link-listitem & {
    padding: ${({ theme }) => theme.space[1]};
    padding-left: ${({ theme }) => theme.space[3]};
    border-radius: 0;
  }

  .pb-link-listitem:hover & {
    transition: background-color 200ms east-out;
    --bg: var(--color-neutral-contrast-03);
  }
`;

export const AccordionHeaderContent = styled.div`
  flex: 1;
`;

type AccordionBodyProps = {
  opened?: boolean;
  ref?: any;
  style?: any;
};

export const AccordionBody = styled(CardBody)<AccordionBodyProps>`
  --bg: var(--color-neutral-contrast-03);
  background: var(--bg);
  height: 0px;
  overflow: hidden;
  transition: height ease-out 0.3s;
  border-bottom-left-radius: ${({ theme }) => theme.radii.soft};
  border-bottom-right-radius: ${({ theme }) => theme.radii.soft};

  .pb-link-listitem &,
  .ab-version-accordion & {
    --bg: var(--color-neutral-contrast-00);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ab-version-accordion & {
    padding-left: ${({ theme }) => theme.space[6]};
    /* border-top: solid 1px ${({ theme }) => theme.colors.border.default};
    margin-bottom: -1px; */
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      background: ${({ theme }) => theme.colors.border.default};
    }
  }
`;
