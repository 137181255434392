import React, { ReactElement, ReactNode } from 'react';
// import { useRouteMatch } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import Icon from 'assets/icons';
import { Separator } from 'ui/components';
import {
  VerticalNavWrapper,
  NavItemInner,
  StyledVerticalNav,
  CollapseToggle,
} from './styles';

type VerticalNavProps = {
  tabs: Array<tab>;
  activeTab: string;
  collapsed?: boolean;
  toggleCollapse?: () => void;
  onNavClick: (id: string) => void;
};

type tab = { label: string; id: string; icon?: ReactNode; groupEnd?: boolean };

function NavItem({
  tab,
  activeTab,
  onNavClick,
  collapsed,
}: {
  tab: tab;
  activeTab: string;
  collapsed?: boolean;
  onNavClick: (id: string) => void;
}) {
  return (
    <>
      <NavItemInner
        // href="#"
        onClick={() => onNavClick(tab.id)}
        className={tab.id === activeTab ? 'active' : ''}
        collapsed={collapsed}
      >
        {tab.icon ? tab.icon : null}
        <span className="pr-v-nav-label">{tab.label}</span>
        <Icon.ChevronRight className="arrow-icon" ariaHidden={true} title="" />
      </NavItemInner>
      {tab.groupEnd ? (
        <Separator bg="grayDark" direction="x" mt={2} mb={2} />
      ) : null}
    </>
  );
}

function VerticalNav({
  tabs,
  onNavClick,
  activeTab,
  collapsed,
  toggleCollapse,
}: VerticalNavProps): ReactElement {
  let permissions: any = [];
  const permissionSet = localStorage.getItem('userPermissions');
  if (permissionSet) {
    permissions = JSON.parse(permissionSet);
  }
  const procedureName = process.env.REACT_APP_THEME;
  return (
    <VerticalNavWrapper collapsed={collapsed}>
      <StyledVerticalNav>
        {tabs.map((tab: any) => {
          if (
            tab.id !== 'Telephony' ||
            (tab?.id === 'Telephony' && procedureName !== 'lfl')
          ) {
            return collapsed ? (
              <Tooltip
                animation="fade"
                title={tab.label}
                position="right-end"
                arrow={true}
              >
                <NavItem
                  key={tab.label}
                  tab={tab}
                  activeTab={activeTab}
                  onNavClick={onNavClick}
                  collapsed={collapsed}
                />
              </Tooltip>
            ) : (
              <NavItem
                key={tab.label}
                tab={tab}
                activeTab={activeTab}
                onNavClick={onNavClick}
                collapsed={collapsed}
              />
            );
          }
        })}
      </StyledVerticalNav>
      <Tooltip
        animation="fade"
        title={collapsed ? 'Expand' : 'Collapse'}
        position="right"
        arrow={true}
      >
        <CollapseToggle onClick={toggleCollapse}>
          {collapsed ? <Icon.ChevronRightDouble /> : <Icon.ChevronLeftDouble />}
        </CollapseToggle>
      </Tooltip>
    </VerticalNavWrapper>
  );
}

export { VerticalNav };
