import { usePaginatedQuery, PaginatedQueryResult } from 'react-query';
import { CustomTable } from 'types/customTables';
import { customTable as getCustomTable } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchCustomTable({ accountId }: TVariables): Promise<TResult> {
  const response = await fetch(getCustomTable(accountId), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return response.json();
}

export type TResult = Array<CustomTable>;

export type TError = { message: string };
export type TVariables = {
  accountId: number;
};

function useCustomTable(
  accountId: number,
): PaginatedQueryResult<TResult, TError> {
  return usePaginatedQuery(
    ['custom_table', accountId],
    () => fetchCustomTable({ accountId }),
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useCustomTable };
