import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { convertToGlobalProc } from 'utils/urls';
import { getTokenWithType } from './useAuth';
import { ProcedureListType } from 'types/procedureBuilder';

export async function convertProcedure({
  params,
}: {
  params: TVariables;
}): Promise<TResult> {
  const response = await fetch(convertToGlobalProc(params.accountId), {
    method: 'PUT',
    headers: {
      Authorization: getTokenWithType(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(params.procedureIds),
  });
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = string | Array<string>;
export type TVariables = {
  procedureIds: Array<number>;
  accountId: number;
};
export type TError = {
  message: string;
};
export type TSnapshot = unknown;

function useConvertToGlobalProcedure(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: TVariables) => {
      const old: Array<ProcedureListType> | undefined = queryCache.getQueryData(
        ['get_procedure_builder_procedures', params.accountId],
      );
      let newData: Array<ProcedureListType> | undefined = [];
      params?.procedureIds?.forEach(id => {
        newData = old?.map(item => {
          if (item.Procedure.Id === id) {
            return {
              ...item,
              Procedure: {
                ...item.Procedure,
                IsGlobal: true,
              },
            };
          }
          return {
            ...item,
          };
        });
      });
      queryCache.setQueryData(
        ['get_procedure_builder_procedures', params.accountId],
        newData,
      );
      const fetchResponse = await convertProcedure({
        params: params,
      });
      if (fetchResponse && fetchResponse instanceof Array) {
        queryCache.setQueryData(
          ['get_procedure_builder_procedures', params.accountId],
          old,
        );
      }

      // if (fetchResponse && fetchResponse instanceof Array) {
      //   return fetchResponse;
      // } else {
      queryCache.invalidateQueries(['get_procedure_builder_procedures'], {
        refetchInactive: true,
      });
      queryCache.invalidateQueries(['get_procedure'], {
        refetchInactive: true,
      });
      return fetchResponse;
      // }
    },
    {
      // onMutate: params => {
      // return () =>
      //   queryCache.setQueryData(
      //     ['get_procedure_builder_procedures', params.accountId],
      //     old,
      //   );
      // params.procedureIds.forEach(id => {
      //   const old: ProcedureInfoType | undefined = queryCache.getQueryData([
      //     'get_procedure_builder_procedures',
      //     params.accountId,
      //     id,
      //   ]);
      //   if (old) {
      //     const newData = {
      //       ...old,
      //       AccountProcedure: {
      //         ...old.AccountProcedure,
      //         Procedure: {
      //           ...old.AccountProcedure.Procedure,
      //           IsGlobal: true,
      //         },
      //       },
      //     };
      //     console.log('DOOOL', newData);
      //     queryCache.setQueryData(
      //       ['get_procedure', params.accountId, id],
      //       newData,
      //     );
      //   }
      // });
      // },
      throwOnError: true,
      onError: (err, params, rollback: () => void) => {
        rollback && rollback();
      },
    },
  );
}

export { useConvertToGlobalProcedure };
