import React, { ReactElement } from 'react';
import styled from 'styled-components';

const StyledSvgWrapper = styled.div`
  --bg: var(--color-neutral-contrast-03);
  --borders: var(--color-neutral-contrast-08);
  --header-bg: var(--color-neutral-contrast-06);
  --handle-bg: var(--color-neutral-contrast-05);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  html[data-color-mode='dark'] & {
    --borders: var(--color-neutral-contrast-01);
    --header-bg: var(--color-neutral-contrast-02);
  }
`;

function SessionExpiredImage(): ReactElement {
  return (
    <StyledSvgWrapper>
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="150"
        viewBox="0 0 200 200"
      >
        <circle cx="100" cy="100" r="100" fill="var(--bg)" />
        <path
          d="M100 159c32.585 0 59-26.415 59-59 0-32.5848-26.415-59-59-59-32.5848 0-59 26.4152-59 59 0 32.585 26.4152 59 59 59z"
          fill="var(--borders)"
        />
        <path
          d="M100 153c29.271 0 53-23.729 53-53 0-29.2711-23.729-53-53-53-29.2711 0-53 23.7289-53 53 0 29.271 23.7289 53 53 53z"
          fill="var(--color-neutral-contrast-00)"
        />
        <path
          d="M119.97 86.3574c1.747-1.7474 1.747-4.5799 0-6.3271-1.748-1.7474-4.58-1.7474-6.327 0L96.6792 96.994l6.3268 6.327 16.964-16.9636z"
          fill="var(--borders)"
        />
        <path
          d="M97.2871 98.7585L72.1213 123.924c-1.0919 1.092-1.0919 2.863 0 3.955a2.7878 2.7878 0 001.9772.819c.7156 0 1.4315-.273 1.9772-.819l25.1663-25.166-3.9549-3.9545z"
          fill="var(--primary-color)"
        />
        <path
          d="M80.4253 74.0985c-1.7474-1.7474-4.5798-1.7474-6.327 0-1.7474 1.7475-1.7474 4.5799 0 6.3271l22.8954 22.8954 6.3273-6.327-22.8957-22.8955z"
          fill="var(--borders)"
        />
        <path
          d="M100 106.991c-3.8546 0-6.9906-3.136-6.9906-6.991 0-3.8546 3.136-6.9906 6.9906-6.9906 3.855 0 6.991 3.136 6.991 6.9906 0 3.855-3.136 6.991-6.991 6.991z"
          fill="var(--primary-color)"
        />
        <path
          d="M67.5641 102.796h-4.1943c-1.5444 0-2.7963-1.252-2.7963-2.796 0-1.5441 1.2519-2.7963 2.7963-2.7963h4.1943c1.5443 0 2.7962 1.2522 2.7962 2.7963 0 1.544-1.2519 2.796-2.7962 2.796zM100 70.3601c-1.5444 0-2.7963-1.2521-2.7963-2.7963v-4.1941c0-1.5441 1.2519-2.7962 2.7963-2.7962 1.544 0 2.796 1.2521 2.796 2.7962v4.1943c0 1.544-1.252 2.7961-2.796 2.7961zM136.63 102.796h-4.194c-1.544 0-2.796-1.252-2.796-2.796 0-1.5441 1.252-2.7963 2.796-2.7963h4.194c1.545 0 2.797 1.2522 2.797 2.7963 0 1.544-1.252 2.796-2.797 2.796zM100 139.427c-1.5444 0-2.7963-1.253-2.7963-2.797v-4.194c0-1.544 1.2519-2.796 2.7963-2.796 1.544 0 2.796 1.252 2.796 2.796v4.194c0 1.544-1.252 2.797-2.796 2.797z"
          fill="var(--handle-bg)"
        />
      </svg>
    </StyledSvgWrapper>
  );
}

export default SessionExpiredImage;
