import { useEffect } from 'react';

function useDocumentTitle(
  title: string | undefined | null,
  setAppName?: boolean,
): void {
  const appName =
    process.env.REACT_APP_THEME === 'lfl' ? 'Lines For Life' : 'Proteus';
  useEffect(() => {
    if (title && setAppName === true) document.title = `${title} - ${appName}`;
    else if (title) {
      document.title = title;
    }
  }, [title, appName, setAppName]);
}

export { useDocumentTitle };
