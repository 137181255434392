import { usePaginatedQuery, PaginatedQueryResult } from 'react-query';
import { GlobalSurveyType } from 'types/forms';
import { getGlobalSurvey } from '../utils/urls';
import { getTokenWithType } from './useAuth';

function getColumnFromId(id: string) {
  const idsList = ['Survey', 'Title', 'Call taker can add to call'];
  const index = idsList.findIndex(item => item === id);
  return `${index}`;
}

async function fetchGlobalSurvey({
  requestData,
}: TVariables): Promise<TResult> {
  const response = await fetch(getGlobalSurvey, {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

type resultType = {
  data: Array<GlobalSurveyType>;
  draw: number;
  isAutoRefreshEnabled: boolean;
  error: Error;
  recordsFiltered: number;
  recordsTotal: number;
};

export type TResult = {
  message: string;
  isSuccess: boolean;
  data: Array<resultType>;
  recordsFiltered: number;
  recordsTotal: number;
  draw: number;
  error: Error | null;
};

export type TError = { message: string };
export type TVariables = {
  requestData: any;
};

type SortItemType = {
  sortKey: string;
  sortOrder: string;
};

function formatRequestData(
  page: number,
  keywordSearch: string,
  columnsSearch: Record<string, string>,
  sortedColumn: string,
  sortOrder: string,
  sortedColumn1?: string,
  sortOrder1?: string,
): FormData {
  const formData = new URLSearchParams();

  formData.append('draw', '1');
  formData.append('columns[0][data]', 'SurveyNotes');
  formData.append('columns[0][name]', 'Survey.Notes');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append(
    'columns[0][search][value]',
    columnsSearch && columnsSearch['Survey'] ? columnsSearch['Survey'] : '',
  );
  formData.append('columns[0][search][regex]', 'false');
  formData.append('columns[1][data]', 'Title');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append(
    'columns[1][search][value]',
    columnsSearch && columnsSearch['Title'] ? columnsSearch['Title'] : '',
  );
  formData.append('columns[1][search][regex]', 'false');
  formData.append('columns[2][data]', 'IsClinicianAllowedToAddToCall');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append(
    'columns[2][search][value]',
    columnsSearch && columnsSearch['Call taker can add to call']
      ? columnsSearch['Call taker can add to call']
      : '',
  );
  formData.append('columns[2][search][regex]', 'false');

  formData.append('order[0][column]', sortedColumn);
  formData.append('order[0][dir]', sortOrder);

  if (sortedColumn1 && sortOrder1) {
    formData.append('order[1][column]', sortedColumn1); // for initial fetch and reset
    formData.append('order[1][dir]', sortOrder1);
  }
  formData.append('search[value]', `${keywordSearch}`);
  formData.append('search[regex]', 'false');
  formData.append('start', `${page * 100}`);
  formData.append('length', `${100}`);
  formData.append('timezoneOffset', '-330');
  return formData;
}

function useGetGlobalSurveys({
  page,
  keywordSearch,
  columnsSearch,
  sortItem,
}: {
  page: number;
  keywordSearch: string;
  columnsSearch: Record<string, string>;
  sortItem: SortItemType | Array<SortItemType>;
}): PaginatedQueryResult<TResult, TError> {
  let requestData = {};
  if (sortItem instanceof Array) {
    requestData = formatRequestData(
      page,
      keywordSearch,
      columnsSearch,
      getColumnFromId(sortItem[0].sortKey),
      sortItem[0].sortOrder,
      getColumnFromId(sortItem[1].sortKey),
      sortItem[1].sortOrder,
    );
  } else if (sortItem.sortKey && sortItem.sortOrder) {
    requestData = formatRequestData(
      page,
      keywordSearch,
      columnsSearch,
      getColumnFromId(sortItem.sortKey),
      sortItem.sortOrder,
    );
  }

  return usePaginatedQuery(
    ['global_survey_list', page, keywordSearch, columnsSearch, sortItem],
    () => fetchGlobalSurvey({ requestData }),
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetGlobalSurveys };
