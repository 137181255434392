import React, { ReactElement } from 'react';
import styled from 'styled-components';

type AvatarProps = {
  src: string;
  className?: string;
};

const StyledAvatar = styled.img`
  display: inline-block;
  overflow: hidden;
  line-height: 1;
  vertical-align: middle;
  border-radius: 50%;
`;

function Avatar({ src, className }: AvatarProps): ReactElement {
  return <StyledAvatar src={src} className={className} />;
}

export { Avatar };
