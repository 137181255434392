import React, { ReactElement, useState, useEffect } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { SlidePanel, SearchInput } from 'ui/patterns';
import { Stack } from 'ui/components';
import NoDataImage from 'assets/images/NoDataImage';
import { LinkedGlobalAccountsListType } from '../procedureTypes';

export default function LinkedGlobalAccountsList({
  showLinkedAccounts,
  toggleLinkedAccounts,
  globalProcedure,
}: LinkedGlobalAccountsListType): ReactElement {
  const [searchValue, setSearchValue] = useState<string>();
  const [linkedAccounts, setLinkedAccounts] = useState<Array<string>>([]);

  useEffect(() => {
    if (
      globalProcedure &&
      globalProcedure?.GlobalProcedureAccountList &&
      globalProcedure?.GlobalProcedureAccountList.length > 0
    ) {
      const sorted = globalProcedure?.GlobalProcedureAccountList?.sort((a, b) =>
        a.localeCompare(b),
      );
      if (sorted) setLinkedAccounts([...sorted]);
    }
  }, [globalProcedure]);

  const handleSearch = (value: string) => {
    let newAccounts: string[] | null | undefined = [];
    let globalProcedureAccountList: string[] | null | undefined = [];
    if (
      globalProcedure &&
      globalProcedure?.GlobalProcedureAccountList &&
      globalProcedure?.GlobalProcedureAccountList.length > 0
    ) {
      const sorted = globalProcedure?.GlobalProcedureAccountList?.sort((a, b) =>
        a.localeCompare(b),
      );
      if (sorted) {
        globalProcedureAccountList = [...sorted];
      }
    }

    newAccounts =
      globalProcedureAccountList &&
      globalProcedureAccountList.filter(item => {
        if (item.toLowerCase().includes(value.toLowerCase())) return item;
        return false;
      });

    if (newAccounts) setLinkedAccounts([...newAccounts]);
    setSearchValue(value);
  };

  return (
    <SlidePanel
      open={showLinkedAccounts}
      onClose={() => {
        toggleLinkedAccounts();
        setSearchValue('');
      }}
      title={
        globalProcedure
          ? `Accounts linked to ${globalProcedure?.Procedure?.Name}`
          : 'Accounts linked to'
      }
      size="sm"
    >
      <Flex flexDirection="column" height="100%">
        <Box>
          <SearchInput
            placeholder="Search linked accounts"
            mb={3}
            value={searchValue}
            handleChange={handleSearch}
          />
        </Box>
        <Stack
          direction="y"
          gap={3}
          flex="1 0 auto"
          height="1px"
          overflowY="auto"
        >
          {linkedAccounts && linkedAccounts?.length > 0 ? (
            linkedAccounts.map((item, index) => (
              <Text key={index} as="span">
                {item}
              </Text>
            ))
          ) : (
            <NoDataImage height="100%" feedback="No data available" />
          )}
        </Stack>
      </Flex>
    </SlidePanel>
  );
}
