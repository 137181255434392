import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { hideDisplayProcedureVisibility } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { ProcedureListType } from 'types/procedureBuilder';

export async function updateProcedureVisibilty({
  params,
}: TArguments): Promise<TResult> {
  const response = await fetch(
    hideDisplayProcedureVisibility(params.AccountId, params.ProcedureId),
    {
      method: 'PUT',
      headers: {
        Authorization: getTokenWithType(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    },
  );
  return response.json();
}

export type TResult = string;

export type TError = { message: string };
export type TArguments = {
  params: ProcedureListType;
};
export type TVariables = ProcedureListType;
export type TSnapshot = unknown;

function useHideDisplayProcedures(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: ProcedureListType) => {
      const updatedVisibility = await updateProcedureVisibilty({
        params: params,
      });

      if (updatedVisibility) {
        queryCache.invalidateQueries(
          ['get_procedure_builder_procedures', params.AccountId],
          {
            refetchInactive: true,
          },
        );
        queryCache.invalidateQueries(['get_procedure'], {
          refetchInactive: true,
        });
      }
      return updatedVisibility;
    },
    {
      throwOnError: true,
    },
  );
}

export { useHideDisplayProcedures };
