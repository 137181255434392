import React, {
  ReactElement,
  useState,
  useRef,
  useEffect,
  useCallback,
  SyntheticEvent,
  useMemo,
} from 'react';
import { Box, Text } from 'rebass/styled-components';
import {
  SlidePanel,
  Loader,
  SearchableList,
  ListBody,
  ListSearch,
  List,
  FormInput,
  FormSelect,
} from 'ui/patterns';
import { Button, Cluster, FormLabel, Stack } from 'ui/components';
import * as Icon from 'assets/icons';
import {
  useGetAccountListForEmbedProcedures,
  useGetProceduresForEmbedProcedure,
} from 'hooks';
import { ProcedureList, ProcedureListType } from 'types/procedureBuilder';
import { showErrorToast } from 'utils/showToast';
import { queryCache } from 'react-query';
import { Account } from 'types/accounts';

type HyperlinkProps = {
  showPanel: boolean;
  togglePanel: () => void;
  accountId: number;
  selectedWords: string;
  tagsList: Array<{ Id: string; html: string }>;
};

const pageLength = 20;

function Hyperlink({
  showPanel,
  togglePanel: toggle,
  accountId,
  selectedWords,
  tagsList,
}: HyperlinkProps): ReactElement {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(showPanel);
    }, 10);
  }, [showPanel]);

  function togglePanel() {
    setIsVisible(false);
    setTimeout(() => {
      toggle();
    }, 500);
  }
  //Search
  const [keyword, setKeyword] = useState<string>('');
  const [procedureKeyword, setProcedureKeyword] = useState<string>('');
  //Handling Selection
  const [activeAccount, setActiveAccount] = useState<number>();
  const [activeProcedure, setActiveProcedure] = useState<number>();

  const [procedureList, setProcedureList] = useState<Array<ProcedureListType>>(
    [],
  );
  //Conditional rendering of fields - removed since anchor type link is avoided
  // const [linkType, setLinkType] = useState(0);
  const [internalExternalLink, setInternalExternalLink] = useState(0);
  //Setting urls based on selection
  const [externalUrl, setExternalUrl] = useState('');
  const [selectedExternalUrlType, setSelectedExternalUrlTypes] =
    useState<number>(1);
  const [linkText, setLinkText] = useState<string>('');
  const [tag, setTag] = useState<{ Id: string; html: string } | undefined>();
  function reset() {
    setKeyword('');
    setProcedureKeyword('');
    setActiveAccount(0);
    setActiveProcedure(0);
    // setLinkType(0);
    setInternalExternalLink(0);
    setExternalUrl('');
    setSelectedExternalUrlTypes(1);
    setLinkText('');
    setTag(undefined);
    setShowSelectedAccountAndProcedure(undefined);
  }

  function setAsHyperLink() {
    let url = '';
    //Remove since no more link typeoption available
    // if (!linkType || linkType === linkTypes[0].id) {
    //   showErrorToast({ message: 'Please select hyperlink type' });
    //   return;
    // }
    // if (linkType === linkTypes[1].id) {
    if (internalExternalLink === internalExternalLinks[1].id) {
      if (
        selectedExternalUrlType === externalUrlTypes[1].id ||
        selectedExternalUrlType === externalUrlTypes[2].id
      ) {
        const prefix = externalUrlTypes.find(
          item => item.id === selectedExternalUrlType,
        )?.name;
        if (externalUrl) url = `${prefix}://${externalUrl}`;
        else showErrorToast({ message: 'Please enter the url' });
      } else {
        showErrorToast({ message: 'Please select a external url type' });
      }
    } else if (internalExternalLink === internalExternalLinks[2].id) {
      if (activeAccount) {
        if (activeProcedure) {
          const procedure = procedureList.find(
            item => item.ProcedureId === activeProcedure,
          );
          const account =
            accountList && accountList.find(i => i && i.Id === activeAccount);
          url = `/api/procedures/V1/accountfolders/${
            // url = `${apiBaseUrl}procedures/V1/accountfolders/${
            account && account.CID
          }-${account && account.SID}-${account && account.PID}${'/Policies/'}${
            procedure?.Procedure.Name
          }`;
          // if (tag) {
          //   url = `${url}#${tag.Id}`;
          // }
        } else {
          showErrorToast({ message: 'Please select a procedure' });
        }
      } else {
        showErrorToast({ message: 'Please select a account' });
      }
    } else {
      showErrorToast({ message: 'Please select a link' });
    }
    // }
    //Anchor type linking removed
    // else if (linkType === linkTypes[2].id) {
    //   if (tag) {
    //     //
    //     url = `#${tag.Id}`;
    //   }
    // }
    if (url) {
      const anchor = '<a href="' + url + '">' + linkText + '</a>';
      (window as any).tinymce?.activeEditor?.insertContent(anchor);
      togglePanel();
      reset();
    }
  }
  useEffect(() => {
    setLinkText(selectedWords);
  }, [selectedWords, linkText]);
  const procedureActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          togglePanel();
          reset();
        }}
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        // disabled={linkText.length ? false : true}
        onClick={() => {
          setAsHyperLink();
        }}
      >
        Apply
      </Button>
    </>
  );

  const {
    data: accountsData,
    fetchMore,
    // canFetchMore,
    isLoading: isAccountListLoading,
    isFetching,
  } = useGetAccountListForEmbedProcedures(accountId, keyword);

  const accountList = useMemo(() => {
    return accountsData?.flat();
  }, [accountsData]);

  // const onEndReached = useCallback(() => {
  //   if (canFetchMore) fetchMore();
  // }, [canFetchMore, fetchMore]);

  const tbodyRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleScroll = useCallback(
    (e: any) => {
      if (tbodyRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom) {
          const localData: Array<Array<Account>> | undefined =
            queryCache.getQueryData([
              'getAccountForEmbedProcedures',
              accountId,
              keyword,
            ]);
          if (
            localData &&
            localData[localData.length - 1].length === pageLength
          ) {
            fetchMore();
            // setKeyword('');
          }
        }
      }
    },
    [accountId, fetchMore, keyword],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  const handleSearchKeyword = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setKeyword(value);
  };

  const handleSelectedAccount = (account: ProcedureList) => {
    setActiveAccount(account.Id);
  };

  const { data: accountProcedures, isLoading: isAccountProceduresLoading } =
    useGetProceduresForEmbedProcedure({
      accountId: activeAccount,
      showHidden: false,
    });

  useEffect(() => {
    if (accountProcedures) {
      const data = [...accountProcedures];
      const result = accountProcedures.filter((item, index, data) => {
        if (index === data.findIndex(t => t.ProcedureId === item.ProcedureId))
          return item;
      });
      setProcedureList(result);
    }
  }, [accountProcedures]);

  const handleSelectedProcedure = (procedure: ProcedureListType) => {
    const accountNumber = procedure?.Account
      ? `${procedure.Account?.CID}${'-'}${procedure.Account?.SID}${'-'}${
          procedure.Account?.PID
        }`
      : '';
    setActiveProcedure(procedure?.ProcedureId);
    // setActiveProcedureName(procedure?.Procedure?.Name);
    // setActiveAccountccountNumber(accountNumber);
  };

  const handleProcedureSearch = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setProcedureKeyword(value);
    let newProcedures: Array<ProcedureListType> | undefined = [];

    newProcedures =
      accountProcedures &&
      accountProcedures.filter(item => {
        const searchTerm = `${item.Procedure.Name}`;
        if (searchTerm.toLowerCase().includes(value.toLowerCase())) return item;
      });
    if (newProcedures) setProcedureList([...newProcedures]);
  };

  // const linkTypes = [
  //   { id: 1, name: ' Select the hyperlink type' },
  //   { id: 2, name: 'Link outside current procedure' },
  //   { id: 3, name: 'Link to anchor in current procedure' },
  // ];

  const procedureName =
    process.env.REACT_APP_THEME === 'lfl'
      ? 'Lines For Life Procedures'
      : 'Proteus Procedures';
  const internalExternalLinks = [
    { id: 1, name: 'Select To what URL should this link go?' },
    { id: 2, name: 'External Website/URL' },
    { id: 3, name: `${procedureName}` },
  ];

  const externalUrlTypes = [
    { id: 1, name: 'Select link type' },
    { id: 2, name: 'http' },
    { id: 3, name: 'https' },
  ];

  const handleLinkText = (text: string) => {
    setLinkText(text);
  };
  const handleExternalUrl = (text: string) => {
    setExternalUrl(text);
  };

  const [showSelectedAccountAndProcedure, setShowSelectedAccountAndProcedure] =
    useState<{
      linkedAccount: string;
      procedureName: string;
      link: string;
    }>();

  useEffect(() => {
    if (!!showPanel) {
      const hrefTag = (
        window as any
      ).tinymce?.activeEditor?.selection?.getNode()?.href;
      if (hrefTag) {
        const procedurePath = hrefTag.split('/');
        if (procedurePath.indexOf('accountfolders') > -1) {
          if (procedurePath.indexOf('procedures') > -1) {
            // setLinkType(2);
            setInternalExternalLink(3);
            const linkedAccount = procedurePath[procedurePath.length - 3];
            const procedureName = procedurePath[procedurePath.length - 1];
            // setActiveAccount(selectedAccount?.Id);
            setShowSelectedAccountAndProcedure({
              linkedAccount: linkedAccount,
              procedureName: procedureName.replace('%20', ''),
              link: hrefTag,
            });
            // setKeyword(linkedAccount);
            // setProcedureKeyword(procedureName);
          }
        } else if (hrefTag.indexOf('#hyper_link') > -1) {
          // setLinkType(3);
          const tagId = hrefTag.split('#').pop(-1);
          const tagItem = tagsList.find(item => item.Id === tagId);
          setTag(tagItem);
        } else if (
          hrefTag.startsWith('http://') ||
          hrefTag.startsWith('https://')
        ) {
          // setLinkType(2);
          setInternalExternalLink(2);
          if (hrefTag.startsWith('https://')) {
            setSelectedExternalUrlTypes(3);
            setExternalUrl(hrefTag.replace('https://', ''));
          } else {
            setSelectedExternalUrlTypes(2);
            setExternalUrl(hrefTag.replace('http://', ''));
          }
        }
      }
    }
  }, [showPanel, tagsList]);

  // const handleExternalUrlType = (type: number) => {
  //   if (type === 2) setExternalUrl('http://');
  //   else if (type === 3) setExternalUrl('https://');
  // };

  return (
    <SlidePanel
      open={isVisible}
      onClose={() => {
        togglePanel();
        reset();
      }}
      size="lg"
      title="Hyperlink"
      actions={procedureActions()}
    >
      <Stack direction="y" gap={4}>
        <Stack direction="x" gap={3}>
          <FormInput
            name="textToDisplay"
            label="Text to display"
            flex="1"
            value={linkText}
            validation={linkText.length ? undefined : 'error'}
            onTextChange={(text: string) => handleLinkText(text)}
          />
          <FormInput
            name="hyperLinkType"
            label="Hyperlink Type"
            flex="1"
            value="Link outside current procedure"
            disabled={true}
          />
          {/* <FormSelect
            options={linkTypes}
            label="Hyperlink Type"
            flex="1"
            onChange={e => {
              setLinkType(parseInt(e.currentTarget.value));
              setInternalExternalLink(0);
              setShowSelectedAccountAndProcedure(undefined);
            }}
            value={linkType}
            noSelectOption
          /> */}
        </Stack>

        {/* {linkType === 2 ? ( */}
        <Stack direction="x" gap={3}>
          <FormSelect
            options={internalExternalLinks}
            label="Internal/External link"
            flex="1"
            onChange={(e: any) => {
              setInternalExternalLink(parseInt(e.currentTarget.value));
              setShowSelectedAccountAndProcedure(undefined);
            }}
            value={internalExternalLink}
            noSelectOption
          />
          <Box flex="1" aria-hidden="true"></Box>
        </Stack>
        {/* ) : null} */}

        {internalExternalLink === 2 ? (
          <Stack direction="x" gap={3}>
            <FormSelect
              options={externalUrlTypes}
              label="External Url Type"
              flex="1"
              noSelectOption
              value={selectedExternalUrlType}
              onChange={(e: any) => {
                const nextId = parseInt(e.currentTarget.value);
                setSelectedExternalUrlTypes(nextId);
                setShowSelectedAccountAndProcedure(undefined);
              }}
            />
            <FormInput
              name="url"
              label=" "
              flex="1"
              disabled={selectedExternalUrlType === externalUrlTypes[0].id}
              prefix={
                selectedExternalUrlType !== externalUrlTypes[0].id
                  ? `${
                      externalUrlTypes.find(
                        item => item.id === selectedExternalUrlType,
                      )?.name
                    }://`
                  : ''
              }
              value={externalUrl}
              onTextChange={(text: string) => handleExternalUrl(text)}
            />
          </Stack>
        ) : null}
        {showSelectedAccountAndProcedure ? (
          <Stack direction="y" gap={3}>
            {/* <Box flex="1" frameBorder="1"> */}
            <FormLabel>{`Account: ${showSelectedAccountAndProcedure.linkedAccount}`}</FormLabel>
            <FormLabel>{`Procedure: ${showSelectedAccountAndProcedure.procedureName}`}</FormLabel>
            {/* </Box> */}
          </Stack>
        ) : (
          <>
            {internalExternalLink === 3 ? (
              <Stack direction="x" gap={3}>
                <Box flex="1">
                  <FormLabel>Choose Account Name</FormLabel>
                  <SearchableList>
                    <ListSearch>
                      <input
                        type="search"
                        placeholder="Search account name"
                        value={keyword}
                        onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                          handleSearchKeyword(e)
                        }
                      />
                      <Icon.Search />
                    </ListSearch>
                    <ListBody ref={tbodyRef}>
                      {(isAccountListLoading || isFetching) && <Loader />}
                      <List>
                        {accountList &&
                          accountList.map(accounts => (
                            <li
                              className={
                                accounts && accounts.Id === activeAccount
                                  ? 'active'
                                  : ''
                              }
                              key={accounts && accounts.Id}
                              title={accounts && accounts.AccountNameAndNumber}
                              onClick={() => {
                                if (accounts) setActiveAccount(accounts.Id);
                              }}
                            >
                              {accounts && accounts.AccountNameAndNumber}
                            </li>
                          ))}
                      </List>
                    </ListBody>
                  </SearchableList>
                </Box>
                <Box flex="1">
                  <FormLabel>Choose Procedure Name</FormLabel>
                  <SearchableList>
                    <ListSearch>
                      <input
                        // disabled={!!activeAccount}
                        type="search"
                        placeholder="Search procedure names "
                        value={procedureKeyword}
                        onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                          handleProcedureSearch(e)
                        }
                      />
                      <Icon.Search />
                    </ListSearch>
                    <ListBody>
                      {isAccountProceduresLoading && <Loader />}
                      {activeAccount ? (
                        <List>
                          {procedureList &&
                            procedureList.map(procedure => {
                              return (
                                <li
                                  className={
                                    procedure.ProcedureId === activeProcedure
                                      ? 'active'
                                      : ''
                                  }
                                  key={`${procedure.Procedure.Name} - ${procedure.ProcedureId}`}
                                  title={procedure.Procedure.Name}
                                  onClick={() => {
                                    handleSelectedProcedure(procedure);
                                  }}
                                >
                                  {procedure.IsVisible ? (
                                    <Cluster gap={1}>
                                      <div style={{ flexWrap: 'nowrap' }}>
                                        <Icon.Eye fill="var(--color-body-text)" />
                                        <Text
                                          fontWeight="600"
                                          className="truncate"
                                        >
                                          {procedure.Procedure.Name}
                                        </Text>
                                      </div>
                                    </Cluster>
                                  ) : (
                                    <Cluster gap={1}>
                                      <div style={{ flexWrap: 'nowrap' }}>
                                        <Icon.EyeOff fill="var(--color-neutral-contrast-05)" />
                                        <Text
                                          style={{ opacity: '0.75' }}
                                          className="truncate"
                                        >
                                          {procedure.Procedure.Name}
                                        </Text>
                                      </div>
                                    </Cluster>
                                  )}
                                </li>
                              );
                            })}
                        </List>
                      ) : null}
                    </ListBody>
                  </SearchableList>
                </Box>
              </Stack>
            ) : null}
          </>
        )}
        {/* || (linkType === 2 && internalExternalLink === 3) */}
        {/* {linkType === 3 ? (
          <Stack direction="x" gap={3}>
            <Box flex="1">
              <FormLabel>Tag Name</FormLabel>
              <SearchableList>
                <ListBody>
                  <List>
                    {tagsList.map(item => (
                      <li
                        className={tag?.Id === item.Id ? 'active' : ''}
                        key={item.Id}
                        title={item.Id}
                        onClick={() => setTag(item)}
                      >
                        {item.html}
                      </li>
                    ))}
                  </List>
                </ListBody>
              </SearchableList>
            </Box>
            <Box flex="1"></Box>
          </Stack>
        ) : null} */}
      </Stack>
    </SlidePanel>
  );
}

export default Hyperlink;
