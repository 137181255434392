import React, { ReactElement, ReactNode, useCallback, useState } from 'react';
import { Box } from 'rebass/styled-components';
import {
  LayoutProps,
  layout,
  SpaceProps,
  space,
  FlexProps,
  flex,
  PositionProps,
  position,
  FlexboxProps,
  flexbox,
} from 'styled-system';

import { useDropzone } from 'react-dropzone';
// import useUploadExternalDocument from 'hooks/useUploadExternalDocument';
import styled from 'styled-components';
import { ProgressBar, FormLabel } from 'ui/components';
import { apiBaseUrl } from 'utils/urls';
import { showErrorToast } from 'utils/showToast';

type ssProps = LayoutProps &
  SpaceProps &
  FlexProps &
  PositionProps &
  FlexboxProps;

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return 'var(--color-neutral-contrast-04)';
};

const StyledDropZone = styled.div<ssProps>`
  --bg: hsla(var(--color-neutral-contrast-03-hsl), 50%);
  --color: #777;
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 3px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: var(--bg);
  color: var(--color);
  outline: none;
  transition: border 0.24s ease-in-out;

  ${layout}
  ${space}
	${flex}
	${position}
	${flexbox}

  html[data-color-mode='dark'] & {
    --bg: var(--color-neutral-contrast-02);
    --color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

const Description = styled.p`
  opacity: 0.8;
  color: ${({ theme }) => theme.colors.text.body};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

function Dropzone({
  onChange,
  isLoading,
  value,
  progress,
  accountId,
  info,
  label,
  icon,
  accept,
  ...ssProps
}: ssProps & {
  onChange: (file?: File) => void;
  isLoading?: boolean;
  value?: string;
  progress?: number;
  accountId?: number;
  info?: string;
  label?: string;
  icon?: ReactNode;
  accept?: string;
}): ReactElement {
  const [size, setSize] = useState(0);
  const onDrop = useCallback(
    async acceptedFiles => {
      // console.log('acceptedFiles', acceptedFiles);
      if (acceptedFiles.length === 0) {
        showErrorToast({
          message: 'Please choose a valid file to upload',
        });
      }
      acceptedFiles.forEach((file: File) => {
        setSize(file.size);
        onChange(file);
      });
    },
    [onChange],
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
  });
  const id = `toggle-${label ? label.replace(/ /g, '').toLowerCase() : null}`;

  return (
    <>
      {value && (
        <StyledDropZone {...ssProps}>
          {accountId ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${apiBaseUrl}procedures/${value}`}
            >{`${value ? value : ''}`}</a>
          ) : (
            <p>{value}</p>
          )}

          <button onClick={() => onChange()}>Remove Document</button>
        </StyledDropZone>
      )}
      {isLoading && (
        <StyledDropZone {...ssProps}>
          <p>{`Uploading... ${(size / 1024).toFixed(1)} KB`}</p>
          <ProgressBar progress={progress} />
        </StyledDropZone>
      )}
      {!value && !isLoading && (
        <>
          {label ? <FormLabel htmlFor={id}>{label}</FormLabel> : null}
          <StyledDropZone {...getRootProps()} {...ssProps}>
            <input {...getInputProps()} id={id} tabIndex={0} />

            {icon ? <Box mb={3}>{icon}</Box> : null}

            <div style={{ textAlign: 'center' }}>
              <Description>Drop file here or click to upload.</Description>
              {info ? (
                <p>
                  <small>{info}</small>
                </p>
              ) : null}
            </div>
          </StyledDropZone>
        </>
      )}
    </>
  );
}

export { Dropzone };
