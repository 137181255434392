import React, { ReactElement } from 'react';
import { Flex, Heading, Box } from 'rebass/styled-components';
import { MainContent, Alert } from 'ui/patterns';
import { useDocumentTitle } from 'hooks';
import * as Icon from 'assets/icons';

export default function WelcomePage(): ReactElement {
  useDocumentTitle('Home Page', true);
  let permissions = [];
  const permissionSet = localStorage.getItem('userPermissions');
  if (permissionSet) {
    permissions = JSON.parse(permissionSet);
  }

  return (
    <MainContent contentCentered>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection={'column'}
      >
        <Heading fontWeight="regular" color="text.body" className="fade-in">
          Welcome to Account Builder
        </Heading>
        {permissions.CanAccessAccountBuilder !== undefined &&
        !permissions?.CanAccessAccountBuilder ? (
          <Box mt={3}>
            <Alert variant="danger" icon={<Icon.ExclamationCircle />}>
              Account Builder is a tool for Relationship Managers. If you think
              that you should have access to this site, please contact your
              administrator.
            </Alert>
          </Box>
        ) : null}
      </Flex>
    </MainContent>
  );
}
