import { useQuery, QueryResult } from 'react-query';
import { getUserSettings } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchUserSettings(): Promise<APIResult> {
  const response = await fetch(getUserSettings, {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type APIResult = {
  'Accounts-Grid-Coloumns': string;
};
export type TResult = Array<string>;

export type TError = { message: string };

function useGetUserSettings(): QueryResult<TResult, TError> {
  return useQuery(
    'get_user_settings',
    async () => {
      const fetchResponse = await fetchUserSettings();
      const result = JSON.parse(fetchResponse['Accounts-Grid-Coloumns']);
      return result;
    },
    {
      staleTime: 5 * 60 * 10000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetUserSettings };
