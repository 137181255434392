import React, { ReactNode, ReactElement } from 'react';
import styled from 'styled-components';

const StyledEmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background: var(--low-contrast-neutral);
  z-index: 1;
`;

type EmptyStateProps = {
  children: ReactNode;
};

function EmptyState({ children }: EmptyStateProps): ReactElement {
  return (
    <StyledEmptyState className="pr-empty-state">{children}</StyledEmptyState>
  );
}

export { EmptyState };
