import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { deleteProcedure } from 'utils/urls';
import { getTokenWithType } from './useAuth';

export async function removeProcedure({
  params,
}: {
  params: TVariables;
}): Promise<TResult> {
  const response = await fetch(
    deleteProcedure(
      params.accountId,
      params.isHardDelete ? 'delete' : 'v1/archive',
    ),
    {
      method: 'PUT',
      headers: {
        Authorization: getTokenWithType(),
        'content-type': 'application/json',
      },
      body: JSON.stringify(params.procedureIds),
    },
  );
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = string;
export type TVariables = {
  procedureIds: Array<number>;
  accountId: number;
  isHardDelete?: boolean;
};
export type TError = {
  message: string;
};
export type TSnapshot = unknown;

function useDeleteProcedure(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: TVariables) => {
      const fetchResponse = await removeProcedure({
        params: params,
      });
      if (fetchResponse) {
        queryCache.invalidateQueries(['get_procedure_builder_procedures'], {
          refetchInactive: true,
        });
        queryCache.invalidateQueries(
          ['get_archived_procedures', params.accountId],
          {
            refetchInactive: true,
          },
        );
        queryCache.invalidateQueries(
          ['get_global_procedures', params.accountId],
          {
            refetchInactive: true,
          },
        );
        queryCache.invalidateQueries(['get_procedure'], {
          refetchInactive: true,
        });
      }
      return fetchResponse;
    },
    {
      throwOnError: true,
    },
  );
}

export { useDeleteProcedure };
