// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const colors = {
 "gray": "rgba(106, 115, 125, 1)",
 "gray000": "rgba(250, 251, 252, 1)",
 "gray100": "rgba(246, 248, 250, 1)",
 "gray200": "rgba(225, 228, 232, 1)",
 "gray300": "rgba(209, 213, 218, 1)",
 "gray400": "rgba(149, 157, 165, 1)",
 "gray500": "rgba(106, 115, 125, 1)",
 "gray600": "rgba(88, 96, 105, 1)",
 "gray700": "rgba(68, 77, 86, 1)",
 "gray800": "rgba(47, 54, 61, 1)",
 "gray900": "rgba(36, 41, 46, 1)",
 "purple000": "rgba(234, 235, 243, 1)",
 "purple100": "rgba(202, 204, 226, 1)",
 "purple200": "rgba(168, 172, 206, 1)",
 "purple300": "rgba(135, 139, 185, 1)",
 "purple400": "rgba(111, 113, 170, 1)",
 "purple500": "rgba(89, 88, 157, 1)",
 "purple600": "rgba(83, 80, 147, 1)",
 "purple700": "rgba(74, 70, 135, 1)",
 "purple800": "rgba(53, 44, 99, 1)",
 "purple900": "rgba(66, 61, 122, 1)",
 "orange": "rgba(250, 144, 40, 1)",
 "orange000": "rgba(255, 243, 226, 1)",
 "orange100": "rgba(255, 225, 183, 1)",
 "orange200": "rgba(255, 205, 136, 1)",
 "orange300": "rgba(255, 185, 91, 1)",
 "orange400": "rgba(255, 170, 60, 1)",
 "orange500": "rgba(255, 154, 25, 1)",
 "orange600": "rgba(250, 144, 40, 1)",
 "orange700": "rgba(244, 129, 37, 1)",
 "orange800": "rgba(226, 90, 32, 1)",
 "orange900": "rgba(237, 114, 35, 1)",
 "green": "rgba(40, 167, 69, 1)",
 "green000": "rgba(240, 255, 244, 1)",
 "green100": "rgba(220, 255, 228, 1)",
 "green200": "rgba(190, 245, 203, 1)",
 "green300": "rgba(133, 232, 157, 1)",
 "green400": "rgba(52, 208, 88, 1)",
 "green500": "rgba(40, 167, 69, 1)",
 "green600": "rgba(34, 134, 58, 1)",
 "green700": "rgba(23, 111, 44, 1)",
 "green800": "rgba(20, 70, 32, 1)",
 "green900": "rgba(22, 92, 38, 1)",
 "red": "rgba(215, 58, 73, 1)",
 "red000": "rgba(255, 238, 240, 1)",
 "red100": "rgba(255, 220, 224, 1)",
 "red200": "rgba(253, 174, 183, 1)",
 "red300": "rgba(249, 117, 131, 1)",
 "red400": "rgba(234, 74, 90, 1)",
 "red500": "rgba(215, 58, 73, 1)",
 "red600": "rgba(203, 36, 49, 1)",
 "red700": "rgba(179, 29, 40, 1)",
 "red800": "rgba(134, 24, 29, 1)",
 "red900": "rgba(158, 28, 35, 1)",
 "yellow": "rgba(255, 211, 61, 1)",
 "yellow000": "rgba(255, 253, 239, 1)",
 "yellow100": "rgba(255, 251, 221, 1)",
 "yellow200": "rgba(255, 245, 177, 1)",
 "yellow300": "rgba(255, 234, 127, 1)",
 "yellow400": "rgba(255, 223, 93, 1)",
 "yellow500": "rgba(255, 211, 61, 1)",
 "yellow600": "rgba(249, 197, 19, 1)",
 "yellow700": "rgba(219, 171, 9, 1)",
 "yellow800": "rgba(115, 92, 15, 1)",
 "yellow900": "rgba(176, 136, 0, 1)",
 "blue": "rgba(0, 162, 242, 1)",
 "blue000": "rgba(224, 244, 254, 1)",
 "blue100": "rgba(178, 226, 252, 1)",
 "blue200": "rgba(126, 208, 250, 1)",
 "blue300": "rgba(74, 189, 247, 1)",
 "blue400": "rgba(32, 176, 245, 1)",
 "blue500": "rgba(0, 162, 242, 1)",
 "blue600": "rgba(0, 148, 227, 1)",
 "blue700": "rgba(0, 129, 207, 1)",
 "blue800": "rgba(3, 80, 153, 1)",
 "blue900": "rgba(0, 112, 187, 1)",
 "black": "rgba(27, 31, 35, 1)",
 "white": "rgba(255, 255, 255, 1)",
 "purple": "rgba(89, 88, 157, 1)"
}

export default colors;