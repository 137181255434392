import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { Flex } from 'rebass/styled-components';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { confirmAlert } from 'utils/confirm-alert';
import { MainContent, PageHeader, FormInput, Loader } from 'ui/patterns';
import * as Icon from 'assets/icons';
import {
  useDocumentTitle,
  useGetFormDetails,
  useGetAccountInfo,
  useSaveForm,
  useCopySection,
  useCopyQuestion,
} from 'hooks';
import AddEditSection from './AddEditSection';
import AddGlobalSection from './AddGlobalSection';
import AddEditQuestion from './AddEditQuestion';
import SectionList from './SectionList';
import { SectionTypeInput } from 'types/forms';
import { showErrorToast, showSuccessToast } from 'utils/showToast';
import { Questions, QuestionTypeInput } from 'types/questions';
import { formatQuestionOptionsData } from './AddEditQuestion/formatQuestionData';
import CopySection from './CopySection';
import { useStateCallback } from './genericFn';
import { LayoutProps, SpaceProps } from 'styled-system';
import usePrompt from 'hooks/usePrompt';

const empty = {
  accountInfoReturn: undefined,
};

type StyleProps = SpaceProps & LayoutProps;

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

type AddEditFormType = {
  formId?: number;
  switchTab?: (tabId: string, formId?: number) => void;
  setDirty?: (isDirty: boolean) => void;
} & StyleProps;

export default function AddEditForm({
  // formId,
  // switchTab,
  // setDirty,
  ...StyleProps
}: AddEditFormType): ReactElement {
  const history = useHistory();
  const {
    accountId: passedId,
    tabId,
    formId: passedFormId,
  } = useParams<{
    accountId: string;
    tabId?: string;
    formId: string;
  }>();

  const match = useRouteMatch(`/home/accounts/edit/${passedId}/AddForm`);
  const formId = parseInt(passedFormId);
  const accountId = parseInt(passedId);
  // const formId = history?.location?.state?.formId;

  useDocumentTitle(`${match ? 'Add Form' : 'Edit Form'}`, true);
  const [showNewSection, setNewSection] = useState<boolean>(false);
  const [showGlobalSection, setGlobalSection] = useState<boolean>(false);
  const [showQuestionPanel, setQuestionPanel] = useState<boolean>(false);
  const [sections, setSections] = useStateCallback<Array<SectionTypeInput>>([]);
  const [currentSection, setCurrentSection] =
    useState<SectionTypeInput>(undefined);
  const [formNotes, setFormNotes] = useState<string>('');
  const [isAdd, setIsAdd] = useStateCallback<boolean>(false);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [selectedQuestionType, setQuestionType] = useState({});
  const [showCopyQuestionPanel, setShowCopyQuestion] = useState<number>(0);
  const [showCopySection, setCopySection] = useState<boolean>(false);
  const [expandAll, setExpandAll] = useState<Record<number, boolean>>({});
  const [copySectionApi, { isLoading: copySectionLoading }] = useCopySection();
  const [copyQuestionApi, { isLoading: copyQnLoading }] = useCopyQuestion();
  // const [isPageDirty, setDirty] = useState<boolean>();

  // isAdd

  // const callbackRef = React.useRef(setDirty);
  // useLayoutEffect(() => {
  //   callbackRef.current = setDirty;
  // });

  // useEffect(() => {
  //   setDirty(isAdd);
  // }, [isAdd]);

  function switchTab(tabId: string, formId?: string | number) {
    history.push(
      `/home/accounts/edit/${accountId}/${tabId}${formId ? `/${formId}` : ''}`,
    );
  }

  usePrompt(
    isAdd,
    'Are you sure you want to leave? Changes that you made may not be saved.',
  );

  function openCloseSection(id: number) {
    const expandedState = { ...expandAll };
    expandedState[id] = expandedState[id] ? false : true;
    setExpandAll({ ...expandedState });
  }

  function allSectionExpandCollapse() {
    const showCollapsed = Object.values(expandAll).find(item => item === true);
    const expandedList: Record<number, boolean> = {};
    sections &&
      sections
        .filter(
          item =>
            item.$type ===
            'Proteus2.Api.Domain.Surveys.Section, Proteus2.Api.Domain',
        )
        .forEach(item => {
          expandedList[item.Id] = showCollapsed ? false : true;
        });
    setExpandAll({ ...expandedList });
  }

  const toggleNewSection = () => {
    setNewSection(!showNewSection);
    setCurrentSection(undefined);
  };

  const toggleCopyQuestionPanel = (sectionId?: number) => {
    // if (isPreviewEnabled() === true) {
    setShowCopyQuestion(sectionId ? sectionId : 0);
    // } else {
    //   confirmAlert({
    //     message: 'Please save the form to continue with copy question.',
    //     buttons: [
    //       {
    //         label: 'Save',
    //         onClick: () => {
    //           handleSave(undefined, null, () => {
    //             setShowCopyQuestion(sectionId ? sectionId : 0);
    //           });
    //         },
    //         className: 'pr-btn-primary',
    //       },
    //       {
    //         label: 'Cancel',
    //         onClick: () => {
    //           //
    //         },
    //       },
    //     ],
    //   });
    // }
  };

  const toggleCopySectionPanel = () => {
    setCopySection(!showCopySection);
  };

  const toggleGlobalSection = () => {
    setGlobalSection(false);
  };

  const toggleQuestionPanel = () => {
    setQuestionPanel(!showQuestionPanel);
    setCurrentQuestion({});
    setQuestionType({});
    setCurrentSection(undefined);
  };

  const { data: formDetails, isLoading: isFormLoading } =
    useGetFormDetails(formId);

  const prevFormDetails = usePrevious({ formDetails });

  useEffect(() => {
    // const showCollapsed = Object.values(expandAll).find(item => item === true);
    if (!prevFormDetails?.formDetails) {
      const expandedList: Record<number, boolean> = {};
      const sections = formDetails?.Sections;
      sections &&
        sections
          .filter(
            item =>
              item.$type ===
              'Proteus2.Api.Domain.Surveys.Section, Proteus2.Api.Domain',
          )
          .forEach(item => {
            expandedList[item.Id] = true;
          });
      setExpandAll({ ...expandedList });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDetails]);

  const { data: accountInfo, isLoading: isAccountLoading } =
    useGetAccountInfo(accountId);

  const [saveForm, { isLoading: isSaveFormLoading }] = useSaveForm();

  const { accountInfoReturn } = accountInfo || empty;

  const [url, setUrl] = useState<string>('');

  const isLoading =
    isFormLoading ||
    isAccountLoading ||
    isSaveFormLoading ||
    copySectionLoading ||
    copyQnLoading;

  const accountNumber = accountInfoReturn
    ? `${accountInfoReturn?.CID}${'-'}${accountInfoReturn?.SID}${'-'}${
        accountInfoReturn?.PID
      }`
    : '';

  useEffect(() => {
    if (formDetails) setFormNotes(formDetails?.Notes);
  }, [formDetails]);

  useEffect(() => {
    if (formDetails && formDetails.Sections.length > 0) {
      setSections([...formDetails?.Sections]);
    }
  }, [formDetails, setSections]);

  const handleAddEditSections = (sectionItem: SectionTypeInput) => {
    const allSections = [...sections];
    const index =
      allSections && allSections.length > 0
        ? allSections.findIndex(item => item.Id === sectionItem.Id)
        : -1;
    if (index === -1) {
      allSections && allSections.push({ ...sectionItem });
    } else if (index >= 0) {
      allSections && allSections.splice(index, 1, sectionItem);
    }

    setSections([...allSections]);
    setIsAdd(true);
  };

  const handleCurrentSection = (sectionItem: SectionTypeInput) => {
    if (sectionItem) {
      setCurrentSection(sectionItem);
    }
  };

  const handleDeleteSection = (sectionItem: SectionTypeInput) => {
    const allSections = [...sections];
    const index =
      allSections && allSections.length > 0
        ? allSections.findIndex(item => item.Id === sectionItem.Id)
        : -1;
    if (index >= 0) {
      allSections && allSections.splice(index, 1);
    }
    setSections([...allSections]);
    setIsAdd(true);
  };

  const handleAddEditGlobalSection = (sectionItem: SectionTypeInput) => {
    const allSections = [...sections];
    const index =
      allSections && allSections.length > 0
        ? allSections.findIndex(item => item.SectionId === sectionItem.Id)
        : -1;
    if (sectionItem && index === -1) {
      allSections.push({
        $type:
          'Proteus2.Api.Domain.Surveys.GlobalSectionReference, Proteus2.Api.Domain',
        Id: new Date().getTime(),
        IsActive: true,
        SectionId: sectionItem?.Id,
        Title: sectionItem?.Title,
        Section: {
          Title: sectionItem?.Title,
        },
        Questions: [],
        Type: undefined,
      });
      setSections([...allSections]);
      setIsAdd(true);
    } else {
      confirmAlert({
        message: 'Form may not have duplicate global sections.',
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
              //
            },
            className: 'pr-btn-primary',
          },
        ],
      });
    }
  };

  const handleAddEditQuestion = (
    questionParams: QuestionTypeInput,
    currentSection: SectionTypeInput,
    cb?: (newSections: Array<SectionTypeInput>, questionParams: any) => void,
  ) => {
    const allQuestions = [...currentSection?.Questions];
    const index =
      allQuestions && allQuestions.length > 0
        ? allQuestions.findIndex(
            (item: QuestionTypeInput) =>
              item.QuestionTemplateId === questionParams?.QuestionTemplateId,
          )
        : -1;

    if (index === -1) {
      allQuestions && allQuestions.push(questionParams);
    } else if (index >= 0) {
      allQuestions && allQuestions.splice(index, 1, questionParams);
    }

    const updatedSections = sections.map(item => {
      if (item.Id === currentSection.Id) {
        return {
          ...item,
          Questions: allQuestions,
        };
      } else
        return {
          ...item,
        };
    });

    if (Object.keys(currentQuestion).length !== 0 || index === -1) {
      setSections([...updatedSections], newSections => {
        if (cb) {
          setIsAdd(false);
          cb && cb(newSections, questionParams);
          // handleCurrentQuestion(questionParams);
        } else {
          setIsAdd(true);
          toggleQuestionPanel();
          setQuestionType({});
        }
      });
    } else {
      confirmAlert({
        message: 'This question has already been added to this section.',
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
              //
            },
            className: 'pr-btn-primary',
          },
        ],
      });
      setQuestionType({});
    }
  };

  const handleCurrentQuestion = (questionItem: any) => {
    console.log(
      '🚀 ~ file: index.tsx ~ line 212 ~ handleCurrentQuestion ~ questionItem',
      questionItem,
    );
    const questionType =
      questionItem?.QuestionTemplate?.QuestionType?.Description;

    const questionTypeOptions = {
      ...questionItem?.QuestionTypeOptions,
      FieldNames:
        questionItem?.QuestionTemplate?.QuestionTypeOptions?.FieldNames,
    };

    const questionTemplateOptions = [
      ...questionItem.QuestionTemplate.QuestionTemplateOptions,
    ];

    const receivedQuestionParams = [...questionItem.QuestionOptions];

    const formatQuestionOptions = formatQuestionOptionsData(
      questionTemplateOptions,
      receivedQuestionParams,
    );

    if (questionItem) {
      const currentQuestionParams = {
        Description: questionItem?.QuestionTemplate?.Description,
        FieldLabel: questionItem?.FieldLabel,
        FieldName: questionItem?.QuestionTemplate?.FieldName,
        Id: questionItem?.Id,
        IsDisabled: questionItem?.QuestionTemplate?.IsDisabled,
        PersonAssociation: questionItem?.PersonAssociation,
        QuestionType: questionItem?.QuestionTemplate?.QuestionType,
        QuestionTypeId: questionItem?.QuestionTemplate?.QuestionTypeId,
        QuestionTypeOptions:
          questionType === 'Lookup'
            ? questionTypeOptions
            : questionItem?.QuestionTypeOptions,
        QuestionTemplateId: questionItem?.QuestionTemplateId,
        Order: questionItem?.Order,
        QuestionDetail: questionItem?.QuestionDetail,
        QuestionTemplateOptions: formatQuestionOptions,
        SectionId: questionItem?.SectionId,
        IsMultiValuesAllowed: questionItem?.IsMultiValuesAllowed,
      };
      setQuestionType(currentQuestionParams);
      setCurrentQuestion(questionItem);
    }
  };

  const handleDeleteQuestion = (
    questionItem: QuestionTypeInput,
    sectionItem: SectionTypeInput,
  ) => {
    const allQuestions = [...sectionItem?.Questions];
    const index =
      allQuestions && allQuestions.length > 0
        ? allQuestions.findIndex(
            (item: QuestionTypeInput) => item.Id === questionItem?.Id,
          )
        : -1;
    if (index >= 0) {
      allQuestions && allQuestions.splice(index, 1);
    }
    const updatedSections = sections.map(item => {
      if (item.Id === sectionItem?.Id) {
        return {
          ...item,
          Questions: allQuestions,
        };
      } else
        return {
          ...item,
        };
    });
    setSections([...updatedSections]);
    setIsAdd(true);
  };

  function formatDataToSave(newSections?: Array<SectionTypeInput>) {
    const allSections = newSections ? [...newSections] : [...sections];

    const sectionsToSave =
      allSections &&
      allSections.map(item => {
        if (item.Type === undefined) {
          return {
            ...item,
            Id: 0,
          };
        } else {
          return {
            ...item,
          };
        }
      });

    const saveSections =
      sectionsToSave &&
      sectionsToSave.map(item => {
        const Questions =
          item.Questions &&
          item.Questions.map((questionItem: QuestionTypeInput) => {
            if (questionItem?.Order !== undefined) {
              return {
                ...questionItem,
              };
            } else {
              return {
                ...questionItem,
                Id: 0,
                SectionId: 0,
                QuestionTypeOptions: {
                  ...questionItem?.QuestionTypeOptions,
                  Id: 0,
                },
              };
            }
          });
        return {
          ...item,
          Questions,
        };
      });

    return saveSections;
  }

  function handleSaveForm(
    sectionItem: SectionTypeInput,
    callback?: (newSectionId?: number) => void,
  ) {
    const saveSections = formatDataToSave();

    const disabledSurveyStatus = {
      Description: 'Disabled',
      Id: 1,
    };
    const oldSections = [...sections];
    saveForm(
      {
        Account: accountInfoReturn,
        AccountId: accountId,
        Notes: formNotes,
        Sections: saveSections,
        SurveyStatusId: formDetails ? formDetails?.SurveyStatusId : 1,
        SurveyStatus: formDetails
          ? formDetails?.SurveyStatus
          : disabledSurveyStatus,
        Id: formDetails ? formDetails?.Id : 0,
        LastModified: formDetails ? formDetails?.LastModified : '',
        LastModifiedBy: formDetails ? formDetails?.LastModifiedBy : '',
      },
      {
        onSuccess: mutationResult => {
          setIsAdd(false, () => {
            if (mutationResult) {
              const updatedSections = mutationResult?.Sections && [
                ...mutationResult.Sections,
              ];
              let localSectionData;
              if (updatedSections && updatedSections.length > 0) {
                localSectionData = updatedSections.map((item, i) => ({
                  ...item,
                  localId: oldSections[i]?.Id,
                }));
              }
              const currentSectionItemIndex =
                localSectionData && localSectionData?.length > 0
                  ? localSectionData?.findIndex(
                      item => item?.localId === sectionItem?.Id,
                    )
                  : -1;
              if (currentSectionItemIndex >= 0) {
                callback &&
                  callback(updatedSections[currentSectionItemIndex]?.Id);
              }
              if (mutationResult.Id) {
                switchTab && switchTab('EditForm', mutationResult.Id);
              }
              showSuccessToast({
                message: 'Your changes were successfully saved.',
                autoClose: 3000,
              });
            }
          });
        },
      },
    );
  }

  function handleSave(
    newSections?: Array<SectionTypeInput>,
    questionParams?: any,
    callback?: (isSuccess: boolean, newSectionId?: number) => void,
  ) {
    const saveSections = formatDataToSave(newSections);

    const disabledSurveyStatus = {
      Description: 'Disabled',
      Id: 1,
    };
    saveSections.map(item => {
      if (
        item.$type ===
          'Proteus2.Api.Domain.Surveys.GlobalSectionReference, Proteus2.Api.Domain' &&
        item.Id === 0
      ) {
        delete item.Section;
      }
    });

    saveForm(
      {
        Account: accountInfoReturn,
        AccountId: accountId,
        Notes: formNotes,
        Sections: saveSections,
        SurveyStatusId: formDetails ? formDetails?.SurveyStatusId : 1,
        SurveyStatus: formDetails
          ? formDetails?.SurveyStatus
          : disabledSurveyStatus,
        Id: formDetails ? formDetails?.Id : 0,
        LastModified: formDetails ? formDetails?.LastModified : '',
        LastModifiedBy: formDetails ? formDetails?.LastModifiedBy : '',
      },
      {
        onSuccess: mutationResult => {
          if (mutationResult && newSections && questionParams) {
            const updatedSections = mutationResult?.Sections && [
              ...mutationResult.Sections,
            ];
            let localSectionData;
            if (updatedSections && updatedSections.length > 0 && newSections) {
              localSectionData = updatedSections.map((item, i) => ({
                ...item,
                localId: newSections[i]?.Id,
              }));
            }

            const currentSectionItemIndex =
              localSectionData && localSectionData?.length > 0
                ? localSectionData?.findIndex(
                    item => item?.localId === currentSection?.Id,
                  )
                : -1;
            if (currentSectionItemIndex >= 0) {
              const currentSectionItem =
                updatedSections[currentSectionItemIndex];
              const currentSectionQuestions = currentSectionItem?.Questions;
              const currentQuestionItem = currentSectionQuestions?.find(
                (item: any) => item?.QuestionTemplateId === questionParams?.Id,
              );

              const currentQuestionItemIndex =
                currentSectionQuestions?.findIndex(
                  (item: any) =>
                    item?.QuestionTemplateId === questionParams?.Id,
                );

              if (currentSectionItem) handleCurrentSection(currentSectionItem);
              if (currentQuestionItem)
                handleCurrentQuestion({
                  ...currentQuestionItem,
                  QuestionTemplate: {
                    ...newSections[currentSectionItemIndex].Questions[
                      currentQuestionItemIndex
                    ].QuestionTemplate,
                  },
                });
            }
          }
          setIsAdd(false, () => {
            if (mutationResult.Id) {
              switchTab && switchTab('EditForm', mutationResult.Id);
            }

            callback && callback(true);
          });
          showSuccessToast({
            message: 'Your changes were successfully saved.',
            autoClose: 3000,
          });
        },
        onError: e => {
          showErrorToast({ message: e.Message, autoClose: 10000 });
          callback && callback(false);
        },
      },
    );
  }

  const handleCancel = () => {
    if (isAdd)
      confirmAlert({
        message:
          'Any unsaved changes will be lost, do you want to leave this page and discard your changes or stay on this page?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              setIsAdd(false, () => switchTab && switchTab('FormList'));
              // if (formId) {

              // history.push('/home/forms', { ID: accountId, formId: formId });
              // }
            },
            className: 'pr-btn-primary',
          },
          {
            label: 'No',
            onClick: () => {
              //
            },
          },
        ],
      });
    else {
      // if (formId) {
      switchTab && switchTab('FormList');
      // history.push('/home/forms', { ID: accountId, formId: formId });
      // }
    }
  };

  const handleMoveSectionToTop = (index: number) => {
    const allSections = [...sections];
    if (index === 0) return;
    allSections.splice(0, 0, allSections.splice(index, 1)[0]);
    setSections([...allSections]);
    setIsAdd(true);
  };

  const handleMoveSectionToBottom = (index: number) => {
    const allSections = [...sections];
    if (index === allSections.length - 1) return;
    allSections.splice(allSections.length, 0, allSections.splice(index, 1)[0]);
    setSections([...allSections]);
    setIsAdd(true);
  };

  const handleMoveSectionUp = (index: number) => {
    const allSections = [...sections];
    if (index === 0) return;
    allSections.splice(index - 1, 0, allSections.splice(index, 1)[0]);
    setSections([...allSections]);
    setIsAdd(true);
  };

  const handleMoveSectionDown = (index: number) => {
    const allSections = [...sections];
    if (index === allSections.length - 1) return;
    allSections.splice(index + 1, 0, allSections.splice(index, 1)[0]);
    setSections([...allSections]);
    setIsAdd(true);
  };

  const handleSectionDragAndDrop = (
    section: SectionTypeInput,
    index: number,
  ) => {
    const allSections = [...sections];
    allSections[index] = { ...section };
    setSections([...allSections]);
    setIsAdd(true);
  };

  function isPreviewEnabled(): boolean {
    if (!formDetails) return false;
    if (formDetails.Notes !== formNotes) return false;
    if (JSON.stringify(formDetails.Sections) !== JSON.stringify(sections))
      return false;

    return true;
  }

  const formActions = [
    {
      action: 'Preview',
      icon: <Icon.Preview />,
      disabled: !isPreviewEnabled(),
      handleButtonClick: () => {
        window.open(
          `${process.env.REACT_APP_CALLCENTER_REDIRECT_URL}/Calls/Call/00000000-0000-0000-0000-000000000000?accountId=${accountId}&formId=${formId}`,
        );
      },
    },
    {
      action: 'Check Links',
      icon: <Icon.Link />,
      handleButtonClick: () =>
        window.open(
          `/home/forms/check-links/${accountNumber}?accountId=${accountId}&surveyId=${formId}`,
        ),
    },
    {
      action: 'Cancel',
      icon: <Icon.Cancel />,
      handleButtonClick: () => handleCancel(),
    },
    {
      action: 'Save',
      icon: <Icon.Check />,
      handleButtonClick: () => handleSave(),
    },
  ];

  const listActions = () => {
    const showCollapsed = Object.values(expandAll).find(item => item === true);
    return [
      {
        action: 'Add New Section',
        icon: <Icon.Add />,
        handleButtonClick: () => {
          setNewSection(true);
        },
      },
      {
        action: 'Copy Section',
        icon: <Icon.Copy />,
        handleButtonClick: () => {
          if (!match) {
            setCopySection(true);
          } else {
            confirmAlert({
              message: 'Please save the form to continue with copy section.',
              buttons: [
                {
                  label: 'Save',
                  onClick: () => {
                    handleSave(undefined, null, () => {
                      setCopySection(true);
                    });
                  },
                  className: 'pr-btn-primary',
                },
                {
                  label: 'Cancel',
                  onClick: () => {
                    //
                  },
                },
              ],
            });
          }
        },
      },
      {
        action: 'Add Global Section',
        icon: <Icon.Add />,
        handleButtonClick: () => {
          setGlobalSection(true);
        },
      },
      {
        action: showCollapsed ? 'Collapse All' : 'Expand All',
        icon: !showCollapsed ? (
          <Icon.ChevronDown title="" />
        ) : (
          <Icon.ChevronUp title="" />
        ),
        handleButtonClick: () => {
          allSectionExpandCollapse();
        },
      },
    ];
  };

  function copyQuestion(Q: Questions, cb: () => void) {
    //
    // const allSections = [...sections];
    // const index = allSections.findIndex(
    //   section => section.Id === showCopyQuestionPanel,
    // );
    // const sectionItem = { ...allSections[index] };
    // const allQuestions = [...sectionItem?.Questions];
    // const qIndex =
    //   allQuestions && allQuestions.length > 0
    //     ? allQuestions.findIndex(
    //         (item: QuestionTypeInput) =>
    //           item.QuestionTemplateId === Q?.QuestionTemplateId,
    //       )
    //     : -1;
    // // console.log('Q: Questions', Q, sectionItem?.Questions);

    // if (qIndex >= 0) {
    //   // qn already exists in the section
    //   confirmAlert({
    //     message: 'This question has already been added to this section.',
    //     buttons: [
    //       {
    //         label: 'Ok',
    //         onClick: () => {
    //           //
    //         },
    //         className: 'pr-btn-primary',
    //       },
    //     ],
    //   });
    //   return;
    // }
    // sectionItem.Questions = [
    //   ...sectionItem.Questions,
    //   {
    //     ...Q,
    //     Id: 0,
    //     SectionId: allSections[index].Id,
    //   },
    // ];
    // if (index >= 0) {
    //   const newSections = [
    //     ...allSections.slice(0, index),
    //     { ...sectionItem },
    //     ...allSections.slice(index + 1),
    //   ];
    //   setSections([...newSections]);
    //   cb && cb();
    //   toggleCopyQuestionPanel();
    // }
    if (formId)
      copyQuestionApi(
        {
          id: formId,
          sectionId: showCopyQuestionPanel,
          questionId: Q.Id,
        },
        {
          onSettled: cb,
        },
      );
  }

  function copySection(sections: Array<SectionTypeInput>, cb: () => void) {
    // const allSections = [...sections];
    // const index =
    //   allSections && allSections.length > 0
    //     ? allSections.findIndex(item => item.Id === section.Id)
    //     : -1;
    // if (index === -1) {
    //   allSections && allSections.push({ ...section });
    //   setSections([...allSections]);
    //   cb && cb();
    //   toggleCopySectionPanel();
    // } else {
    //   confirmAlert({
    //     message: 'This section already exists in the form.',
    //     buttons: [
    //       {
    //         label: 'Ok',
    //         onClick: () => {
    //           //
    //         },
    //         className: 'pr-btn-primary',
    //       },
    //     ],
    //   });
    // }
    if (formId)
      copySectionApi(
        {
          id: formId,
          newSectionIds: sections.map(item => item.Id),
        },
        {
          // onSuccess: () => {
          //   showSuccessToast({
          //     message: 'Your changes were successfully saved.',
          //   });
          //   cb();
          onSuccess: cb,

          onError: (message: any) => {
            showErrorToast({ message: message, autoClose: false });
          },
        },
      );
  }

  const index =
    sections &&
    sections.findIndex(section => section.Id === showCopyQuestionPanel);
  let allQuestionsInSection: Array<Questions> = [];
  if (index >= 0) {
    const sectionItem = { ...sections[index] };
    allQuestionsInSection = [...sectionItem?.Questions];
  }

  return (
    <>
      <MainContent flexDirection="column" m={-4} {...StyleProps}>
        <PageHeader
          title={`${!formId ? 'Add New Form' : 'Edit Form'}`}
          actions={formActions}
          hasBackNav
          backNavAction={handleCancel}
          backNavTooltip="Go back to Forms List"
        />
        <Flex
          p={4}
          sx={{
            background: 'var(--color-neutral-contrast-00)',
            '& > * + *': { marginLeft: '1.2rem' },
          }}
        >
          <FormInput
            style={{ flex: 1 }}
            label="Account Number"
            disabled
            value={accountNumber}
          />
          <FormInput
            style={{ flex: 1 }}
            label="Account Name"
            disabled
            value={accountInfoReturn?.Name}
          />
          <FormInput
            style={{ flex: 2 }}
            label="Form Notes"
            value={formNotes}
            onTextChange={(text: string) => {
              setFormNotes(text);
              setIsAdd(true);
            }}
          />
        </Flex>
        <PageHeader title="List of Sections" actions={listActions()} />
        <SectionList
          isAdd={isAdd}
          toggleQuestionPanel={toggleQuestionPanel}
          toggleNewSection={toggleNewSection}
          sections={sections}
          handleCurrentSection={handleCurrentSection}
          handleDeleteSection={handleDeleteSection}
          handleMoveSectionToTop={handleMoveSectionToTop}
          handleMoveSectionToBottom={handleMoveSectionToBottom}
          handleMoveSectionUp={handleMoveSectionUp}
          handleMoveSectionDown={handleMoveSectionDown}
          handleCurrentQuestion={handleCurrentQuestion}
          handleDeleteQuestion={handleDeleteQuestion}
          toggleCopyQuestionPanel={toggleCopyQuestionPanel}
          handleSectionDragAndDrop={handleSectionDragAndDrop}
          openCloseSection={openCloseSection}
          expandedState={expandAll}
          // handleCopySection={toggleCopyQuestionPanel}
          isCopyQnEnabled={isPreviewEnabled()}
          handleSaveForm={handleSaveForm}
        />
      </MainContent>

      <AddEditSection
        showPanel={showNewSection}
        togglePanel={toggleNewSection}
        handleAddEditSections={handleAddEditSections}
        currentSection={currentSection}
        accountInfoReturn={accountInfoReturn}
        isNewForm={match}
      />

      <AddGlobalSection
        showPanel={showGlobalSection}
        togglePanel={toggleGlobalSection}
        handleAddEditGlobalSection={handleAddEditGlobalSection}
      />

      <AddEditQuestion
        showPanel={showQuestionPanel}
        togglePanel={toggleQuestionPanel}
        handleAddEditQuestion={handleAddEditQuestion}
        currentSection={currentSection}
        selectedQuestionType={selectedQuestionType}
        setQuestionType={setQuestionType}
        currentQuestion={currentQuestion}
        accountId={accountId}
        accountInfoReturn={accountInfoReturn}
        sections={sections}
        handleSaveForm={handleSave}
        url={url}
        setUrl={setUrl}
      />

      <CopySection
        showPanel={showCopySection}
        togglePanel={toggleCopySectionPanel}
        copySection={copySection}
        isCopyQuestion={false}
      />

      {/* copy question */}

      <CopySection
        showPanel={showCopyQuestionPanel !== 0}
        togglePanel={toggleCopyQuestionPanel}
        copyQuestion={copyQuestion}
        isCopyQuestion={true}
        allQuestionsInSection={allQuestionsInSection}
      />

      {isLoading && <Loader />}
    </>
  );
}
