import React, { ReactElement } from 'react';
import { Text } from 'rebass/styled-components';
import { Card, CardHeader } from 'ui/patterns';
import {
  StickyListWrap,
  StickyList,
  StickyListItem,
  Button,
} from 'ui/components';
import * as Icon from 'assets/icons';
import { ProcedureListType } from 'types/procedureBuilder';
import { SelectedProceduresListType } from '../procedureTypes';

export default function SelectedProceduresList({
  selectedProcedures,
  setSelectedProcedures,
  procedureList,
}: SelectedProceduresListType): ReactElement {
  const removeProceduresfromSelectionList = (id: number) => {
    const selectedProc = [...selectedProcedures];
    const index =
      selectedProc && selectedProc.length > 0
        ? selectedProc.findIndex(item => item === id)
        : -1;
    if (index >= 0) {
      selectedProc && selectedProc.splice(index, 1);
    }
    setSelectedProcedures([...selectedProc]);
  };

  return (
    <Card
      flex="1"
      ml={3}
      position="relative"
      style={{ display: 'flex', flexDirection: 'column' }}
      // bg="var(--color-neutral-contrast-01)"
    >
      <CardHeader p={3} bg="var(--color-neutral-contrast-02)">
        <Text as="h4" fontWeight="medium" lineHeight={1} color="text.body">
          {`${selectedProcedures?.length} procedures selected`}
        </Text>
      </CardHeader>

      <StickyListWrap height="1px" overflowY="auto" flex="1 0 auto">
        <StickyList>
          {selectedProcedures &&
            selectedProcedures.length >= 0 &&
            selectedProcedures?.map((item, index) => {
              const sItem = procedureList?.find(
                pItem => pItem.ProcedureId === item,
              );
              return (
                <StickyListItem key={index} tabIndex={index}>
                  {sItem?.Procedure?.Name}
                  <Button
                    variant="subtle"
                    size="sm"
                    title="Remove procedure"
                    onClick={() => removeProceduresfromSelectionList(item)}
                  >
                    <Icon.X title="Remove procedure" />
                  </Button>
                </StickyListItem>
              );
            })}
        </StickyList>
      </StickyListWrap>
    </Card>
  );
}
