import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

type StyledColorModeToggleProps = {
  onClick?: () => void;
  colorMode?: string;
};

const StyledColorModeToggle = styled.button<StyledColorModeToggleProps>`
  --spacing-unit: 0.8rem;
  --size-multiplier: 1.5;
  --accent: #f9ca24;
  --bg: var(--color-neutral-contrast-00);

  display: inline-block;
  border: 0;
  width: calc(var(--spacing-unit) * var(--size-multiplier));
  height: calc(var(--spacing-unit) * var(--size-multiplier));
  padding: 0;
  border-radius: 50%;
  background: var(--accent);
  cursor: pointer;
  position: relative;
  transition: all 240ms ease-out 0ms;

  .c-theme-btn__inner {
    transform: scale(1.5);
    color: var(--accent);
    transition: all 120ms ease-out 240ms;
  }

  &:hover {
    transform: scale(1.05) rotate(-15deg);
  }

  &:before {
    content: '';
    position: absolute;
    width: calc(var(--spacing-unit) * var(--size-multiplier));
    height: calc(var(--spacing-unit) * var(--size-multiplier));
    background: var(--bg);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: scale(0.5) translate(50%, -50%);
    transform-origin: top right;
    transition: transform 320ms ease-out 120ms;
  }
  &:active,
  &:focus {
    outline: 0;
  }

  &:focus {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  }

  ${({ colorMode }) =>
    colorMode === 'dark' &&
    css`
      --accent: ${({ theme }) => theme?.colors?.text?.body};
      --bg: var(--color-neutral-contrast-02);
      transform: rotate(-45deg);
      .c-theme-btn__inner {
        transform: scale(0.5);
        opacity: 0;
      }
      &:focus,
      &:hover {
        transform: scale(1.05) rotate(-60deg);
      }
      &:before {
        transform: scale(1) translate(0%, -50%);
        transition: transform 320ms ease-out 0ms;
      }
    `}
`;

type ColorModeToggleProps = {
  onClick?: () => void;
  colorMode?: string;
};

function ColorModeToggle({
  onClick,
  colorMode,
}: ColorModeToggleProps): ReactElement {
  return (
    <StyledColorModeToggle onClick={onClick} colorMode={colorMode}>
      <svg
        className="c-theme-btn__inner"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Color Mode</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.9731 21.047L35.9784 20.841C35.9927 20.5628 36 20.2824 36 20C36 19.7176 35.9927 19.4372 35.9784 19.159L39.9731 18.953C39.991 19.2998 40 19.6488 40 20C40 20.3512 39.991 20.7002 39.9731 21.047ZM37.8239 10.9182L34.2613 12.7371C34.0068 12.2385 33.7268 11.7549 33.4228 11.2878L36.7755 9.10608C37.1553 9.68978 37.5055 10.2945 37.8239 10.9182ZM30.8939 3.22453L28.7122 6.57719C28.2451 6.27323 27.7615 5.99317 27.2629 5.73865L29.0818 2.1761C29.7055 2.49452 30.3102 2.84471 30.8939 3.22453ZM21.047 0.0269329L20.841 4.02162C20.5628 4.00728 20.2824 4 20 4C19.7176 4 19.4372 4.00728 19.159 4.02162L18.953 0.026933C19.2998 0.00905119 19.6488 0 20 0C20.3512 0 20.7002 0.00905116 21.047 0.0269329ZM10.9182 2.1761L12.7371 5.73866C12.2385 5.99317 11.7549 6.27323 11.2878 6.5772L9.10608 3.22453C9.68978 2.84471 10.2945 2.49452 10.9182 2.1761ZM3.22453 9.10609L6.57719 11.2878C6.27323 11.7549 5.99317 12.2385 5.73865 12.7371L2.1761 10.9182C2.49452 10.2945 2.84471 9.68978 3.22453 9.10609ZM0.0269329 18.953C0.00905116 19.2998 0 19.6488 0 20C0 20.3512 0.00905119 20.7002 0.026933 21.047L4.02162 20.841C4.00728 20.5628 4 20.2824 4 20C4 19.7176 4.00728 19.4372 4.02162 19.159L0.0269329 18.953ZM2.1761 29.0818L5.73866 27.2629C5.99317 27.7615 6.27323 28.2451 6.5772 28.7122L3.22453 30.8939C2.84471 30.3102 2.49452 29.7055 2.1761 29.0818ZM9.10609 36.7755L11.2878 33.4228C11.7549 33.7268 12.2385 34.0068 12.7371 34.2613L10.9182 37.8239C10.2945 37.5055 9.68978 37.1553 9.10609 36.7755ZM18.953 39.9731L19.159 35.9784C19.4372 35.9927 19.7176 36 20 36C20.2824 36 20.5628 35.9927 20.841 35.9784L21.047 39.9731C20.7002 39.991 20.3512 40 20 40C19.6488 40 19.2998 39.991 18.953 39.9731ZM29.0818 37.8239L27.2629 34.2613C27.7615 34.0068 28.2451 33.7268 28.7122 33.4228L30.8939 36.7755C30.3102 37.1553 29.7055 37.5055 29.0818 37.8239ZM36.7755 30.8939L33.4228 28.7122C33.7268 28.2451 34.0068 27.7615 34.2613 27.2629L37.8239 29.0818C37.5055 29.7055 37.1553 30.3102 36.7755 30.8939Z"
          fill="currentColor"
        ></path>
      </svg>
    </StyledColorModeToggle>
  );
}

export { ColorModeToggle };
