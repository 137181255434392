import { useQuery, QueryResult } from 'react-query';
import { getGlobalPrtocedures } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { ProcedureDetailsType } from 'types/procedureBuilder';

async function fetchGlobalProcedures({
  accountId,
}: TVariables): Promise<TResult> {
  const response = await fetch(getGlobalPrtocedures(accountId), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = Array<ProcedureDetailsType> | undefined;

export type TError = { message: string };
export type TVariables = {
  accountId: number;
};

function useGetGlobalProcedures(
  accountId: number,
): QueryResult<TResult, TError> {
  return useQuery(
    ['get_global_procedures', accountId],
    async () => {
      if (accountId)
        return await fetchGlobalProcedures({
          accountId,
        });
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetGlobalProcedures };
