import React, {
  useReducer,
  ReactNode,
  Dispatch,
  ReactElement,
  useEffect,
} from 'react';
import { GlobalActions, AppReducerType, AuthState } from './states';

export type Props = {
  children: ReactNode;
};

const defaultDispatcher: Dispatch<GlobalActions> = () => {
  // empty arrow
};

export function makeStoreContext(
  reducer: AppReducerType,
  initialState: AuthState,
): {
  StoreProvider: ({ children }: Props) => ReactElement;
  useStore: () => AuthState;
  useDispatch: () => React.Dispatch<GlobalActions>;
} {
  const StoreContext = React.createContext(initialState);
  const DispatchContext = React.createContext(defaultDispatcher);

  function StoreProvider({ children }: Props): ReactElement {
    const persistedState = localStorage.getItem('GlobalState');
    const [store, dispatch] = useReducer(
      reducer,
      persistedState ? JSON.parse(atob(persistedState)) : initialState,
    );
    useEffect(() => {
      // console.log('STORE: ', store);
      localStorage.setItem('GlobalState', btoa(JSON.stringify(store)));
    }, [store]);
    return (
      <>
        <DispatchContext.Provider value={dispatch}>
          <StoreContext.Provider value={store}>
            {children}
          </StoreContext.Provider>
        </DispatchContext.Provider>
      </>
    );
  }

  function useStore(): AuthState {
    return React.useContext(StoreContext);
  }

  function useDispatch(): React.Dispatch<GlobalActions> {
    return React.useContext(DispatchContext);
  }

  return { StoreProvider, useStore, useDispatch };
}
