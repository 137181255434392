/* eslint-disable react/display-name */
import React from 'react';
import { Column, TableInstance, ColumnInstance } from 'react-table';
import { Flex, Text } from 'rebass/styled-components';
import { Contact } from 'types/contacts';
import Header from './tableHeader';
import ContactsOptions from './tableContactOptions';
import { Checkbox } from 'ui/components';

function contains(array: Array<string>, string: string): boolean {
  const index = array.findIndex(item => item === string);
  return index !== -1;
}

export default function useLocationsColumns(
  columnsList?: Array<string>,
): Array<Column<Contact>> {
  return React.useMemo((): Array<Column<Contact>> => {
    if (columnsList) {
      const columns = [];
      if (contains(columnsList, 'Name'))
        columns.push(getContactNameHeader(Header));
      if (contains(columnsList, 'Phone Number'))
        columns.push(getPhoneNumberHeader(Header));
      if (contains(columnsList, 'Used By Call Center'))
        columns.push(getUsedByCallCenterHeader(Header));
      if (contains(columnsList, 'Forwarded to ProtoCall'))
        columns.push(getForwardedToProtocallHeader(Header));
      if (contains(columnsList, 'Ok to give out number'))
        columns.push(getGiveOutHeader(Header));
      if (contains(columnsList, 'Notes')) columns.push(getNotesHeader(Header));

      columns.push(getOptionsHeader());
      return columns;
    }
    return [
      getContactNameHeader(Header),
      getPhoneNumberHeader(Header),
      getUsedByCallCenterHeader(Header),
      getForwardedToProtocallHeader(Header),
      getGiveOutHeader(Header),
      getNotesHeader(Header),
      getOptionsHeader(),
    ];
  }, [columnsList]);
}

function getContactNameHeader(
  Header: (
    props: TableInstance<Contact> & {
      column: ColumnInstance<Contact>;
    },
  ) => JSX.Element,
): Column<Contact> {
  return {
    Header: Header,
    accessor: 'Name',
    id: 'Name',
  };
}

function getPhoneNumberHeader(
  Header: (
    props: TableInstance<Contact> & {
      column: ColumnInstance<Contact>;
    },
  ) => JSX.Element,
): Column<Contact> {
  return {
    Header: Header,
    accessor: 'PhoneNumber',
    id: 'Phone Number',
  };
}

function getUsedByCallCenterHeader(
  Header: (
    props: TableInstance<Contact> & {
      column: ColumnInstance<Contact>;
    },
  ) => JSX.Element,
): Column<Contact> {
  return {
    id: 'Used by Call Center',
    Header: Header,
    accessor: 'IsUsedByCallCenter',
    Cell: rowInfo => {
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          style={{ height: '100%' }}
        >
          <Checkbox
            label=""
            checked={rowInfo.row.original.IsUsedByCallCenter}
          />
        </Flex>
      );
    },
  };
}

function getForwardedToProtocallHeader(
  Header: (
    props: TableInstance<Contact> & {
      column: ColumnInstance<Contact>;
    },
  ) => JSX.Element,
): Column<Contact> {
  return {
    id: 'Forwarded to ProtoCall',
    Header: Header,
    accessor: 'IsForwardedToProtoCall',
    Cell: rowInfo => {
      return (
        <Flex justifyContent="center" height="100%" alignItems="center">
          <Checkbox
            label=""
            checked={rowInfo.row.original.IsForwardedToProtoCall}
          />
        </Flex>
      );
    },
  };
}

function getGiveOutHeader(
  Header: (
    props: TableInstance<Contact> & {
      column: ColumnInstance<Contact>;
    },
  ) => JSX.Element,
): Column<Contact> {
  return {
    Header: Header,
    accessor: 'IsOkToGiveOutNumber',
    id: 'Ok to give out number',
    Cell: rowInfo => {
      return (
        <Flex justifyContent="center" height="100%" alignItems="center">
          <Checkbox
            label=""
            checked={rowInfo.row.original.IsOkToGiveOutNumber}
          />
        </Flex>
      );
    },
  };
}

function getNotesHeader(
  Header: (
    props: TableInstance<Contact> & {
      column: ColumnInstance<Contact>;
    },
  ) => JSX.Element,
): Column<Contact> {
  return {
    Header: Header,
    accessor: 'Notes',

    id: 'Notes',
    Cell: rowInfo => {
      return (
        <pre
          style={{
            whiteSpace: 'pre-wrap',
            fontFamily: 'system-ui,sans-serif',
            margin: '0',
          }}
        >
          {rowInfo?.row?.original?.Notes}
        </pre>
      );
    },
  };
}

function getOptionsHeader(): Column<Contact> {
  return {
    id: 'Options',
    Header: 'Action',
    Cell: ContactsOptions,
  };
}
