import { useQuery, QueryResult } from 'react-query';
import { getAccountService } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchAccountServices(): Promise<APIResult> {
  const response = await fetch(getAccountService, {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type AccountServicesItem = {
  Id: number;
  Description: string;
};
export type APIResult = Array<AccountServicesItem>;

export type ResultItem = {
  id: number;
  name: string;
};
export type TResult = Array<ResultItem>;
export type TError = { message: string };

function useAccountServices(): QueryResult<TResult, TError> {
  return useQuery(
    'get_account_services',
    async () => {
      const fetchResponse = await fetchAccountServices();
      const result = fetchResponse.map(item => ({
        id: item.Id,
        name: item.Description,
      }));
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useAccountServices };
