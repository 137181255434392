/* eslint-disable react/jsx-key */
import React, { ReactElement, useEffect, useCallback, useRef } from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { Loader } from '../index';
import { EmptyState } from '../emptyState';
import NoDataImage from 'assets/images/NoDataImage';

type TableWrapperProps = {
  className?: any;
  height?: string;
};

const TableWrapper = styled.div<TableWrapperProps>`
  --table-shadow: var(--color-neutral-contrast-00);
  flex: 1 1 auto;
  overflow: auto;
  height: ${({ height }) => (height ? height : '1px')};
  position: relative;
  background: linear-gradient(var(--table-shadow) 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), var(--table-shadow) 70%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: var(--table-shadow);
  background-size: 100% 4em, 100% 4em, 100% 1.4em, 100% 1.4em;
  background-attachment: local, local, scroll, scroll;
  border-radius: ${({ theme }) => theme.radii.soft};
  border: 0.1em solid ${({ theme }) => theme.colors.border.default};
  box-shadow: ${({ theme }) => theme.shadows.medium};

  html[data-color-mode='dark'] & {
    --table-shadow: var(--color-neutral-contrast-01);
  }

  &:focus {
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.2);
    border: 0.1em solid var(--color-neutral-contrast-05);
    outline: none;

    html[data-color-mode='dark'] & {
      border: 0.1em solid var(--color-neutral-contrast-08);
    }
  }

  .dd-wrapper {
    position: fixed;
    right: 38px;
    transform: translateY(13px);
    z-index: ${({ theme }) => theme.zIndices[4]};
  }

  .dd-list {
    & > * + * {
      margin-top: ${({ theme }) => theme.space[2]};
    }
  }

  .dd-header {
    border-bottom: solid 1px ${({ theme }) => theme.colors.border.default};
  }

  .dd-footer {
    border-top: solid 1px ${({ theme }) => theme.colors.border.default};
  }

  .dd-col-selection {
    /* top: -4px; */
    summary {
      background: none;
      border: none;
      padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[1]};
      line-height: 1.2;
      box-shadow: none;
      display: flex;
      align-items: center;

      &:focus,
      &:active {
        outline: none;
        background: ${({ theme }) => theme.colors.bg.grayDark};
        color: #fff;
      }
    }

    summary + div {
      top: 7px;
      right: 100%;
    }

    .dd-list {
      /* max-height: 290px; */
      max-height: calc(100vh - 350px);
      overflow: auto;
    }
  }

  .pr-loader,
  .pr-empty-state {
    top: 50px;
    height: calc(100% - 50px);
  }

  .pr-loader {
    z-index: 1;
  }
`;

type StyledTableProps = {
  noOptions?: boolean;
  hasInlineLoader?: boolean;
  alignVertical?: boolean;
};

const StyledTable = styled.table<StyledTableProps>`
  position: ${({ hasInlineLoader }) =>
    hasInlineLoader ? 'unset' : 'relative'};
  width: 100%;
  /* height: 100%; */
  border-collapse: collapse;
  font-size: ${({ theme }) => theme.fontSizes[1]};

  th,
  td {
    vertical-align: ${({ alignVertical }) =>
      alignVertical ? 'middle' : ' text-top'};
    padding: 1em;
    /* vertical-align: text-top; */
    text-align: left;
  }

  th {
    --bg: var(--color-neutral-contrast-03);
    padding: 0.5em 1em 1em;
    vertical-align: bottom;
    background-color: var(--bg);
    color: ${({ theme }) => theme.colors.text.body};
    border-bottom: solid 0.1em ${({ theme }) => theme.colors.border.default};
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 3;
    white-space: nowrap;

    th:first-child {
      border-top-left-radius: ${({ theme }) => theme.radii.soft};
    }

    th:last-child {
      border-top-right-radius: ${({ theme }) => theme.radii.soft};
    }

    html[data-color-mode='dark'] & {
      --bg: var(--color-neutral-contrast-02);
    }

    & > button {
      line-height: 1;
      margin-top: 0.5rem;
      margin-left: 0.3rem;
      padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[1]};
      background: none;
    }

    .floating-input {
      padding-left: 0;
      padding-right: 0;
    }
    &:last-child {
      text-align: ${({ noOptions }) => (noOptions ? 'left' : 'right')};
      width: ${({ noOptions }) => (noOptions ? 'auto' : '60px')};
      vertical-align: middle;
    }
  }

  /* th:last-child {
    text-align: right;
  } */

  td {
    color: ${({ theme }) => theme.colors.text.body};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    max-width: 200px;
    word-break: break-word;

    max-width: 200px;
    word-break: break-word;

    .dd-listitem-options summary > button {
      background: none;
      padding: ${({ theme }) => theme.space[1]};
      transition: background 200ms ease-in-out;

      html[data-color-mode='dark'] &:hover {
        background: ${({ theme }) => transparentize(0.9, theme.colors.white)};
      }

      &:hover {
        background: ${({ theme }) => theme.baseColors.gray[2]};
      }
    }

    &:last-child {
      text-align: ${({ noOptions }) => (noOptions ? 'left' : 'right')};
    }
  }

  tbody > * + * {
    margin-top: ${({ theme }) => theme.space[3]};
  }

  tbody tr {
    transition: all 50ms ease-in-out;

    html[data-color-mode='dark'] & {
      transition: all 200ms ease-in-out;
    }
  }

  tbody tr:hover {
    background: var(--color-neutral-contrast-02);

    html[data-color-mode='dark'] & {
      background: var(--color-neutral-contrast-00);
    }
  }

  tbody tr:active {
    box-shadow: inset 0 0 15px var(--color-neutral-contrast-04);

    html[data-color-mode='dark'] & {
      box-shadow: inset 0 0 15px var(--color-neutral-contrast-00);
      background: var(--color-neutral-contrast-01);
    }
  }

  tbody tr {
    --border: var(--color-neutral-contrast-03);
    border-bottom: solid 1px var(--border);

    html[data-color-mode='dark'] & {
      --border: var(--color-neutral-contrast-02);
    }
  }

  /* .has-columnSelection & {
    thead > tr > th:last-child,
    tbody > tr > td:last-child {
      padding-right: ${({ theme }) => theme.space[4]};
    }
  } */
`;

type SortItemType = {
  sortKey: string;
  sortOrder: string;
};

function Table({
  columns,
  data = [],
  isLoading,
  hasColumnSelection,
  // selectedColumns,
  userSettings,
  headerUpdateCallBack,
  handleSortChange,
  sortedItem,
  columnsSearch,
  onRowDoubleClick,
  onEditPress,
  onRowClick,
  onRecordEditPress,
  masterTableId,
  onEndReached,
  height,
  setAccounts,
  noOptions,
  selectedRowId,
  multiSortedItem,
  isFetching,
  hasInlineLoader,
  alignVertical,
  tooltipHidden,
  onRedirect,
  getHighlightedText,
}: {
  columns: Array<any>;
  data?: Array<any>;
  isLoading?: boolean;
  hasColumnSelection?: boolean;
  selectedColumns?: Array<any>;
  userSettings?: Array<any>;
  headerUpdateCallBack?: ({
    columnId,
    value,
  }: {
    columnId: string;
    value: string;
  }) => void;
  handleSortChange?: (item: string) => void;
  sortedItem?: { sortKey: string; sortOrder: string };
  columnsSearch?: Record<string, string>;
  onRowDoubleClick?: (row: any) => void;
  onEditPress?: (id: number) => void;
  onRowClick?: (row: any) => void;
  onRecordEditPress?: (row: any) => void;
  masterTableId?: number;
  onEndReached?: () => void;
  isFetchingMore?: boolean;
  height?: string;
  setAccounts?: (row: any, type: string) => void;
  noOptions?: boolean;
  selectedRowId?: number;
  multiSortedItem?: SortItemType | Array<SortItemType>;
  isFetching?: boolean;
  hasInlineLoader?: boolean;
  alignVertical?: boolean;
  tooltipHidden?: boolean;
  onRedirect?: (path: string, params: Record<string, number | string>) => void;
  getHighlightedText?: (content: string) => ReactElement;
}): ReactElement {
  const tbodyRef = useRef() as React.MutableRefObject<HTMLTableSectionElement>;

  const handleScroll = useCallback(
    (e: any) => {
      if (tbodyRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom) {
          onEndReached && onEndReached();
        }
      }
    },
    [onEndReached, tbodyRef],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        headerUpdateCallBack,
        handleSortChange,
        sortedItem,
        columnsSearch,
        userSettings,
        onEditPress,
        onRecordEditPress,
        masterTableId,
        setAccounts,
        selectedRowId,
        multiSortedItem,
        onRedirect,
        getHighlightedText,
      },
      // useFlexLayout,
      // useBlockLayout,
    );

  return (
    <TableWrapper
      role="region"
      aria-labelledby="HeadersRow"
      tabIndex={0}
      className={hasColumnSelection ? 'has-columnSelection' : null}
      ref={tbodyRef}
      height={height}
    >
      <StyledTable
        {...getTableProps()}
        noOptions={noOptions}
        hasInlineLoader={hasInlineLoader}
        alignVertical={alignVertical}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup?.getHeaderGroupProps()}>
              {headerGroup?.headers?.map(column => (
                <th {...column?.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        {!isLoading && (
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr
                  style={{
                    backgroundColor:
                      selectedRowId && row?.original?.Id === selectedRowId
                        ? 'var(--color-neutral-contrast-03)'
                        : '',
                  }}
                  {...row.getRowProps()}
                  onDoubleClick={() => {
                    onRowDoubleClick && onRowDoubleClick(row);
                  }}
                  onClick={() => onRowClick && onRowClick(row)}
                >
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.render(
                          'Cell',
                          cell?.column?.id === 'Search Snippet'
                            ? { tooltipHidden: tooltipHidden }
                            : {},
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {/* {isFetching && (
              <Text
                mb={2}
                fontSize={1}
                color="text.body"
                sx={{ height: '20px' }}
              >
                <Text mb={2}>{`${isFetching ? 'Processing' : ''}`}</Text>
              </Text>
            )} */}
          </tbody>
        )}
      </StyledTable>
      {isFetching && <Loader noBg />}

      {isLoading && <Loader />}
      {!isLoading && data && data.length <= 0 ? (
        <EmptyState>
          <NoDataImage feedback="No data available" />
        </EmptyState>
      ) : null}
    </TableWrapper>
  );
}

export { Table, TableWrapper, StyledTable };
