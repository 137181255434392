import styled, { css } from 'styled-components';
import { StyledFormInput } from 'ui/components';
import { CardFooter } from '../card';
import { Box } from 'rebass/styled-components';

const Container = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.border.default};
  border-radius: ${({ theme }) => theme.radii.soft};
`;

const Title = styled.span`
  flex: 1;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  color: ${({ theme }) => theme.colors.text.body};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

type HeaderProps = {
  opened?: boolean;
};

const Header = styled.header<HeaderProps>`
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  display: flex;
  align-items: center;
  background: var(--color-neutral-contrast-02);
  border-radius: calc(${({ theme }) => theme.radii.soft} - 1px);
  transition: border-radius 200ms ease-out, background 200ms ease-out;

  &:hover {
    cursor: pointer;
    background: var(--color-neutral-contrast-03);
  }

  ${({ opened }) =>
    opened &&
    css`
      border-bottom: solid 1px ${({ theme }) => theme.colors.border.default};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  border-bottom-left-radius: ${({ theme }) => theme.radii.soft};
  border-bottom-right-radius: ${({ theme }) => theme.radii.soft};

  overflow: hidden;
  transition: height ease-out 0.3s;
`;

const Content = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.space[3]};
  min-height: 300px;
`;

type ChevronProps = {
  opened?: boolean;
};

const Chevron = styled.span<ChevronProps>`
  align-self: center;
  line-height: 1;

  & .pr-icon {
    transition: transform 350ms ease-out;
    transform-origin: center;
  }

  ${({ opened }) =>
    opened &&
    css`
      & .pr-icon {
        transform: rotate(180deg);
      }
    `}
`;

const LayoutNameInput = styled(StyledFormInput)`
  /* --input-bg: var(--color-neutral-contrast-05); */
  /* width: auto; */
  font-size: ${({ theme }) => theme.fontSizes[1]};
  padding-block: 0.25rem !important;
  padding-inline: 0.5rem !important;
  height: 30px;
  background: var(--color-neutral-contrast-03);
  border-color: var(--color-neutral-contrast-04);
`;

const Footer = styled(CardFooter)`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.space[2]};
  padding: ${({ theme }) => theme.space[3]};
`;
const DDWrapper = styled(Box)`
  /* .dd-wrapper { */
  /* position: fixed; */
  /* right: 38px; */
  /* transform: translate(13px); */
  /* z-index: ${({ theme }) => theme.zIndices[4]}; */
  /* } */

  .dd-list {
    max-height: 40vh;
    overflow: auto;

    & > * + * {
      margin-top: ${({ theme }) => theme.space[1]};
    }
  }

  .dd-header {
    border-bottom: solid 1px ${({ theme }) => theme.colors.border.default};
  }

  .dd-footer {
    border-top: solid 1px ${({ theme }) => theme.colors.border.default};
  }

  .dd-col-selection {
    summary {
      background: none;
      border: none;
      padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[1]};
      line-height: 1.2;
      box-shadow: none;
      display: flex;
      align-items: center;

      &:focus,
      &:active {
        outline: none;
        background: ${({ theme }) => theme.colors.bg.grayDark};
        color: #fff;
      }
    }
  }
`;

const GroupNameInput = styled(StyledFormInput)`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  padding-block: 0.25rem !important;
  padding-inline: 0.5rem !important;
  height: 30px;
  background: var(--color-neutral-contrast-03);
  border-color: var(--color-neutral-contrast-04);
`;
export {
  Container,
  Header,
  Body,
  Title,
  Content,
  Chevron,
  LayoutNameInput,
  Footer,
  DDWrapper,
  GroupNameInput,
};
