export const authInitialState = {
  isLoggedIn: false,
  token: '',
  username: '',
};

export type AuthState = typeof authInitialState;

export type GlobalActions =
  | { type: 'login'; auth: AuthState }
  | { type: 'logout' };

export default function appReducer(
  state: AuthState,
  action: GlobalActions,
): AuthState {
  switch (action.type) {
    case 'login': {
      return {
        ...state,
        ...action.auth,
      };
    }
    case 'logout': {
      return {
        ...authInitialState,
      };
    }
  }
}

export type AppReducerType = typeof appReducer;
