import React, { ReactElement, useState, useEffect } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FormInput, Card, Loader, Alert } from 'ui/patterns';
import { Button, Stack, Toggle, Dropzone, Separator } from 'ui/components';
import {
  useGetFtpSettings,
  useSaveFtpSettings,
  useUpdateFingerPrint,
} from 'hooks';
import { FTPSettings as FTPSettingsType } from 'types/ftpSettings';
import { showErrorToast, showSuccessToast } from 'utils/showToast';
import usePrompt from 'hooks/usePrompt';
import { confirmAlert } from 'utils/confirm-alert';
import * as Icon from 'assets/icons';

export default function FTPSettings(): ReactElement {
  const [isPageDirty, setDirty] = useState<boolean>();

  const history = useHistory<{ ID: number }>();
  const { accountId } = useParams<{
    accountId: string;
    tabId?: string;
  }>();
  const { data: ftpInfo, isLoading: isGetLoading } = useGetFtpSettings(
    parseInt(accountId),
  );
  const [saveFtpSettings, { isLoading: isSaveLoading }] = useSaveFtpSettings();
  const [updateFingerPrint, { isLoading: isResettingFingerPrint }] =
    useUpdateFingerPrint();

  const isLoading = isGetLoading || isSaveLoading || isResettingFingerPrint;
  const [file, setFile] = useState<File>();
  const [superAdminPermission, setSuperAdminPermission] =
    useState<boolean>(false);
  const {
    watch,
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm();

  useEffect(() => {
    reset({
      ...ftpInfo,
    });
  }, [ftpInfo, reset]);

  useEffect(() => {
    if (isDirty) setDirty(isDirty);
  }, [setDirty, isDirty]);

  const [isEnable, setIsEnable] = useState(false);
  const [isPrivateKey, setIsPrivateKey] = useState(false);

  useEffect(() => {
    if (!isGetLoading && ftpInfo?.IsEnable === true) setIsEnable(true);
    else setIsEnable(false);
  }, [ftpInfo, isGetLoading]);

  useEffect(() => {
    if (!isGetLoading && ftpInfo?.AllowPrivateKeyAuthentication === true) {
      setIsPrivateKey(true);
    } else setIsPrivateKey(false);
  }, [ftpInfo, isGetLoading]);

  useEffect(() => {
    if (isEnable === false) {
      setIsPrivateKey(false);
    } else if (ftpInfo?.AllowPrivateKeyAuthentication === true)
      setIsPrivateKey(true);
  }, [isEnable, ftpInfo]);

  useEffect(() => {
    const permissions = localStorage.getItem('userPermissions');
    if (permissions) {
      const temp = JSON.parse(permissions);
      setSuperAdminPermission(temp.isSuperAdmin);
    }
  }, []);

  const serverValue = watch('Server');

  const handleSaveFtpSettings = (data: FTPSettingsType) => {
    let mode = '';
    if (ftpInfo?.AllowPrivateKeyAuthentication !== null) mode = 'edit';
    else mode = 'add';
    setDirty(false);
    saveFtpSettings(
      {
        ftpSettings: {
          ...data,
          Id: parseInt(accountId),
          PrivateKeyContent: null,
          PassPhrase: data.PassPhrase || null,
          Port: typeof data.Port === 'string' ? parseInt(data.Port) : data.Port,
          FingerPrintValidation: ftpInfo?.FingerPrintValidation
            ? ftpInfo?.FingerPrintValidation
            : 0,
        },
        accountId: parseInt(accountId),
        privateKey: file,
        mode,
      },
      {
        onSuccess: () => {
          reset();
          setFile(undefined);
          showSuccessToast({
            message: 'Your changes were successfully saved.',
            autoClose: 3000,
          });
        },
        onError: error => {
          showErrorToast({
            message: error,
          });
        },
      },
    );
  };
  function onSubmit(data: FTPSettingsType) {
    if (ftpInfo?.Server?.length && ftpInfo?.Server !== serverValue) {
      confirmAlert({
        message:
          "Because the server address has been changed, the database 'fingerprint' needs to be updated. Please contact your IT department to make this change. Changes will be saved, but call documents will not get uploaded to FTP until the fingerprint has been updated.",
        buttons: [
          {
            label: 'Continue',
            onClick: () => {
              handleSaveFtpSettings(data);
            },
            className: 'pr-btn-primary',
          },

          {
            label: 'Cancel',
            onClick: () => {
              //
            },
          },
        ],
      });
    } else {
      handleSaveFtpSettings(data);
    }
  }

  usePrompt(
    isPageDirty,
    'Are you sure you want to leave? Changes that you made may not be saved.',
  );

  const resetFingerPrint = () => {
    confirmAlert({
      message: 'This will reset the SFTP fingerprint for this server. Proceed?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            updateFingerPrint(
              {
                accountId: parseInt(accountId),
              },
              {
                onSuccess: () => {
                  showSuccessToast({
                    message: 'Your changes were successfully saved.',
                    autoClose: 3000,
                  });
                },
                onError: error => {
                  showErrorToast({
                    message: error[0],
                  });
                },
              },
            );
          },
          className: 'pr-btn-primary',
        },

        {
          label: 'No',
          onClick: () => {
            //
          },
        },
      ],
    });
  };
  return (
    <>
      <Flex as="form" flexDirection="column" height="100%">
        <Box
          style={{
            flex: '1 1 auto',
            flexGrow: 1,
            overflow: 'auto',
            height: '1px',
          }}
        >
          <Card p={4}>
            <Box mb={4}>
              {ftpInfo?.Server?.length &&
              ftpInfo?.FingerPrintValidation === 0 ? (
                <Alert variant="danger" icon={<Icon.Warning />}>
                  There were changes made in FTP settings by the customer and
                  fingerprint needs to be reset by IT Admin before FTP will be
                  functional again.
                </Alert>
              ) : null}
              {ftpInfo?.Server?.length &&
              ftpInfo?.FingerPrintValidation === 1 ? (
                <Alert variant="danger" icon={<Icon.Warning />}>
                  There were changes made in FTP settings by the staff and
                  fingerprint needs to be reset by IT Admin before FTP will be
                  functional again.
                </Alert>
              ) : null}
            </Box>
            <Stack direction="y" gap={4} style={{ maxWidth: '350px' }}>
              <Toggle
                label="Enable FTP Settings"
                name="IsEnable"
                onChange={() => {
                  setIsEnable(!isEnable);
                }}
                checked={isEnable}
                register={register}
                disabled={
                  ftpInfo?.Server?.length &&
                  ftpInfo?.FingerPrintValidation !== 2
                    ? true
                    : false
                }
              />
              <Stack direction="x" gap={4}>
                <Box sx={{ flexGrow: '2' }}>
                  <FormInput
                    type="text"
                    label="Server"
                    disabled={
                      !isEnable ||
                      (ftpInfo?.Server?.length &&
                        ftpInfo?.FingerPrintValidation !== 2)
                        ? true
                        : false
                    }
                    register={register}
                    name="Server"
                  />
                </Box>
                <Box sx={{ flexGrow: '1', maxWidth: '120px' }}>
                  <FormInput
                    type="text"
                    label="Port"
                    disabled={
                      !isEnable ||
                      (ftpInfo?.Server?.length &&
                        ftpInfo?.FingerPrintValidation !== 2)
                        ? true
                        : false
                    }
                    register={register}
                    name="Port"
                  />
                </Box>
              </Stack>
              <FormInput
                type="text"
                label="Directory"
                disabled={
                  !isEnable ||
                  (ftpInfo?.Server?.length &&
                    ftpInfo?.FingerPrintValidation !== 2)
                    ? true
                    : false
                }
                register={register}
                name="Directory"
              />
              <FormInput
                type="text"
                label="Username"
                disabled={
                  !isEnable ||
                  (ftpInfo?.Server?.length &&
                    ftpInfo?.FingerPrintValidation !== 2)
                    ? true
                    : false
                }
                register={register}
                name="UserName"
              />

              <Toggle
                label="Private key Authentication"
                disabled={
                  !isEnable ||
                  (ftpInfo?.Server?.length &&
                    ftpInfo?.FingerPrintValidation !== 2)
                    ? true
                    : false
                }
                name="AllowPrivateKeyAuthentication"
                onChange={() => {
                  setIsPrivateKey(!isPrivateKey);
                }}
                checked={isPrivateKey}
                register={register}
              />

              {isPrivateKey ? (
                <>
                  <Stack direction="y" gap={3}>
                    <Box>
                      <Dropzone
                        label="Upload Private key"
                        value={file && file.name}
                        onChange={(file?: File) => {
                          setFile(file);
                        }}
                      />
                    </Box>
                  </Stack>
                  <FormInput
                    type="password"
                    label="PassPhrase"
                    disabled={
                      !isEnable ||
                      (ftpInfo?.Server?.length &&
                        ftpInfo?.FingerPrintValidation !== 2)
                        ? true
                        : false
                    }
                    register={register}
                    name="PassPhrase"
                  />
                </>
              ) : null}

              {!isPrivateKey ? (
                <FormInput
                  type="password"
                  label="Password"
                  disabled={
                    !isEnable ||
                    (ftpInfo?.Server?.length &&
                      ftpInfo?.FingerPrintValidation !== 2)
                      ? true
                      : false
                  }
                  register={register}
                  name="Password"
                  placeholder={
                    ftpInfo?.AllowPrivateKeyAuthentication !== null
                      ? '*************'
                      : ''
                  }
                  // required
                />
              ) : null}
              <Separator direction="x" />
              <Stack direction="y" gap={1}>
                <Text as="label" style={{ fontWeight: 500 }}>
                  {' '}
                  Reset SFTP Fingerprint
                </Text>
                <Button
                  variant="outline"
                  onClick={() => resetFingerPrint()}
                  disabled={
                    superAdminPermission &&
                    ftpInfo?.Server?.length &&
                    ftpInfo?.FingerPrintValidation !== 2
                      ? false
                      : true
                  }
                >
                  Reset
                </Button>
              </Stack>
            </Stack>
          </Card>
        </Box>

        <Flex justifyContent="flex-end" mt={3}>
          <Stack direction="x" gap={2}>
            <Button
              variant="secondary"
              onClick={() => history.push('/home/accounts')}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </Stack>
        </Flex>
      </Flex>
      {isLoading ? <Loader /> : null}
    </>
  );
}
