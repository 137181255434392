import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

const StyledTextarea = styled.textarea``;

type AutoGrowTextareaProps = InputHTMLAttributes<HTMLInputElement> & {
  onTextChange?: (text: string) => void;
};

function AutoGrowTextarea({ onTextChange }: AutoGrowTextareaProps) {
  return (
    <StyledTextarea
      onChange={e => onTextChange && onTextChange(e.currentTarget.value)}
    />
  );
}

const AutoGrowTextareaWrap = styled.div`
  display: grid;

  &::after {
    content: attr(data-replicated-value) ' ';
    white-space: pre-wrap;
    visibility: hidden;
  }

  & > textarea {
    resize: none;
    overflow: hidden;
  }

  & > textarea,
  &::after {
    border: 1px solid black;
    padding: 0.5rem;
    font: inherit;
    grid-area: 1 / 1 / 2 / 2;
  }
`;

export { AutoGrowTextarea, AutoGrowTextareaWrap };
