import { useMutation, MutationResultPair } from 'react-query';
import { getReference } from 'utils/urls';
import { getTokenWithType } from './useAuth';

export async function fetchReferenceForProcedures({
  params,
}: {
  params: TVariables;
}): Promise<TResult> {
  const response = await fetch(
    getReference(params.accountId, params.isRemove),
    {
      method: 'PUT',
      headers: {
        Authorization: getTokenWithType(),
        'content-type': 'application/json',
      },
      body: JSON.stringify(params.procedureIds),
    },
  );
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = Array<string>;
export type TVariables = {
  procedureIds: Array<number>;
  isRemove: boolean;
  accountId: number;
};
export type TError = {
  message: string;
};
export type TSnapshot = unknown;

function useGetReferenceForProcedures(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: TVariables) => {
      const fetchResponse = await fetchReferenceForProcedures({
        params: params,
      });
      if (fetchResponse) return fetchResponse;
      return fetchResponse;
    },
    {
      throwOnError: true,
    },
  );
}

export { useGetReferenceForProcedures };
