import { useState, useEffect, useRef, useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useStateCallback<S>(initialState: S) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef<((state: S) => void) | null>(null);

  const setStateCallback = useCallback((state: S, cb?: (state: S) => void) => {
    if (cb) cbRef.current = cb;
    setState(state);
  }, []);

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback] as const;
}
