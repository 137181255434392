import React, {
  ReactElement,
  useState,
  useCallback,
  useEffect,
  SyntheticEvent,
  useMemo,
} from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { FormInput, Table, SearchInput, FormSelect } from 'ui/patterns';
import { Button, Stack, Checkbox, Grid } from 'ui/components';
import * as Icon from 'assets/icons';
import { useQuestions } from 'hooks';
import useQuestionColumns from '../../../Questions/useQuestionColumns';
import ChoiceQuestion from '../ChoiceQuestion';
import LinkQuestion from '../LinkQuestion';
import TextQuestion from '../TextQuestion';
import LookupQuestion from '../LookupQuestion';
import { getRenderTypes } from '../formatQuestionData';
import { QuestionDefinitionType } from '../questionTypes';

const sortItem = { sortKey: 'Field Name', sortOrder: 'asc' };

const questionSettings = ['Question Type', 'Field Name', 'Field Label'];

export default function QuestionDefinition(
  QuestionDefinitionProps: QuestionDefinitionType,
): ReactElement {
  const {
    showPanel,
    selectedQuestionType,
    setQuestionType,
    resetForm,
    currentQuestion,
    associatedPersons,
    register,
    control,
    Controller,
    watch,
    setValue,
    errors,
    getValues,
    reset,
    accountId,
    mappedColumns,
    setMappedColumns,
    questionTemplateOptions,
    setQuestionTemplateOptions,
    sections,
    setLookupDependency,
    lookupDependency,
    handleFormandQuestionSave,
    url,
    setUrl,
    errorMessage,
  } = QuestionDefinitionProps;
  const [columnsSearch, setColumnsSearch] = useState({}); // table header search
  const [currentSortItem, setCurrentSortItem] = useState(sortItem);
  const [keywordSearch, setKeywordSearch] = useState(''); //keyword search

  const renderTypes = getRenderTypes(selectedQuestionType);

  const {
    data,
    fetchMore,
    canFetchMore,
    // isFetchingMore,
    isLoading,
    isFetching,
  } = useQuestions({
    columnsSearch,
    sortItem: currentSortItem,
    keywordSearch,
  });

  const columns = useQuestionColumns(questionSettings, true);

  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch],
  );

  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (
        currentSortItem.sortKey === item &&
        currentSortItem.sortOrder === 'asc'
      ) {
        sortOrder = 'desc';
      }
      setCurrentSortItem({
        sortKey: item,
        sortOrder,
      });
    },
    [setCurrentSortItem, currentSortItem],
  );

  useEffect(() => {
    if (!showPanel) {
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPanel]);

  const handleKeywordSearch = (value: string): void => {
    setKeywordSearch(value);
  };

  const handleReset = (): void => {
    setKeywordSearch('');
    setColumnsSearch({});
    setCurrentSortItem(sortItem);
    resetForm();
  };

  const handleRowClick = (row: any): void => {
    const questionParams = {
      ...row.original,
      QuestionTemplateId: row.original.Id,
      QuestionDetail: {
        ExternalDocumentUri: '',
        HelpText: '',
        Id: 0,
        ProcedureUri: '',
        Type: 0,
      },
    };
    setQuestionType(questionParams);
  };

  const tableData = useMemo(() => {
    if (data) {
      return data.map(dataItem => dataItem.data).flat();
    }
  }, [data]);

  const totalItems = useMemo(() => {
    if (data) {
      return data[0].recordsFiltered;
    }
    return 0;
  }, [data]);

  const onEndReached = () => {
    if (tableData) {
      if (canFetchMore && tableData?.length < totalItems) fetchMore();
    }
  };

  return (
    <Flex flexDirection="column" height="100%" sx={{ position: 'relative' }}>
      <Stack
        direction="y"
        gap={5}
        pb="50px"
        style={{
          flex: '1 1 auto',
          overflow: 'auto',
          height: '1px',
          paddingRight: '10px',
        }}
      >
        {Object.keys(currentQuestion).length === 0 && (
          <Stack direction="y" gap={3}>
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
              <Text fontSize={1} color="text.body" sx={{ height: '20px' }}>
                {tableData && tableData.length > 0 && (
                  <Text mb={2}>{`Showing ${tableData.length} of ${totalItems}${
                    isFetching ? ' | Processing' : ''
                  }`}</Text>
                )}
              </Text>
              <Stack
                direction="x"
                gap={2}
                style={{ justifyContent: 'flex-end' }}
              >
                <SearchInput
                  placeholder="Search questions"
                  value={keywordSearch}
                  handleChange={handleKeywordSearch}
                />
                <Button
                  iconBefore={<Icon.Refresh title="" ariaHidden="true" />}
                  variant="secondary"
                  onClick={handleReset}
                >
                  Reset All
                </Button>
              </Stack>
            </Flex>
            <Table
              columns={columns}
              data={tableData}
              isLoading={isLoading}
              headerUpdateCallBack={headerUpdateCallBack}
              handleSortChange={handleSortChange}
              sortedItem={currentSortItem}
              columnsSearch={columnsSearch}
              height="300px"
              noOptions
              onRowClick={handleRowClick}
              selectedRowId={selectedQuestionType?.Id}
              onEndReached={onEndReached}
              // isFetching={!!isFetchingMore}
            />
          </Stack>
        )}
        {selectedQuestionType &&
          Object.keys(selectedQuestionType).length !== 0 &&
          !renderTypes && (
            <>
              <Grid minColWidth="300px" gap={3}>
                <FormInput
                  label="Field Name"
                  disabled
                  register={register}
                  name="FieldName"
                />
                <FormInput
                  label="Original Field Label"
                  disabled
                  register={register}
                  name="FieldLabel"
                />
                <FormInput
                  label="Description of Use"
                  disabled
                  register={register}
                  name="Description"
                />
                <FormInput
                  label="Field Label"
                  register={register}
                  name="UpdatedFieldLabel"
                />
                <Controller
                  render={(props: {
                    onChange: (value: number) => void;
                    value: number;
                  }) => {
                    return (
                      <FormSelect
                        disabled={
                          !selectedQuestionType?.QuestionType
                            ?.IsQuestionAssociable
                        }
                        value={props.value}
                        label="Person Association"
                        noSelectOption
                        options={associatedPersons}
                        onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                          const value = parseInt(e.currentTarget.value, 10);
                          props.onChange(value);
                        }}
                      />
                    );
                  }}
                  defaultValue={''}
                  name="PersonAssociation"
                  control={control}
                />
              </Grid>
              {/* {selectedQuestionType?.QuestionType?.Description !==
                'Preliminary Report' && ( */}
              <Grid minColWidth="250px" gap={3}>
                <Checkbox
                  label="Required"
                  register={register}
                  name="IsRequired"
                  disabled={
                    selectedQuestionType?.QuestionType?.Description ===
                    'Display Only'
                      ? true
                      : false
                  }
                />
                <Checkbox
                  label="Never send to Account"
                  register={register}
                  name="IsNeverSentToAccount"
                />
                <Checkbox
                  label="If no answer, don't send to Account"
                  register={register}
                  name="IsNotSentToAccountIfNoAnswer"
                />
              </Grid>
              {/* )} */}
            </>
          )}
        {selectedQuestionType?.QuestionType?.Description === 'Lookup' && (
          <LookupQuestion
            accountId={accountId}
            selectedQuestionType={selectedQuestionType}
            associatedPersons={associatedPersons}
            register={register}
            control={control}
            Controller={Controller}
            watch={watch}
            mappedColumns={mappedColumns}
            setMappedColumns={setMappedColumns}
            sections={sections}
            currentQuestion={currentQuestion}
            setLookupDependency={setLookupDependency}
            lookupDependency={lookupDependency}
            handleFormandQuestionSave={handleFormandQuestionSave}
          />
        )}

        {selectedQuestionType?.QuestionType?.Description === 'Choice' && (
          <ChoiceQuestion
            selectedQuestionType={selectedQuestionType}
            associatedPersons={associatedPersons}
            register={register}
            control={control}
            Controller={Controller}
            watch={watch}
            setValue={setValue}
            questionTemplateOptions={questionTemplateOptions}
            setQuestionTemplateOptions={setQuestionTemplateOptions}
            sections={sections}
            currentQuestion={currentQuestion}
            handleFormandQuestionSave={handleFormandQuestionSave}
          />
        )}

        {selectedQuestionType?.QuestionType?.Description === 'Text' && (
          <TextQuestion
            selectedQuestionType={selectedQuestionType}
            associatedPersons={associatedPersons}
            register={register}
            control={control}
            Controller={Controller}
            watch={watch}
            setValue={setValue}
            errors={errors}
            getValues={getValues}
            reset={reset}
            url={url}
            setUrl={setUrl}
            errorMessage={errorMessage}
          />
        )}

        {selectedQuestionType?.QuestionType?.Description === 'Link' && (
          <LinkQuestion
            selectedQuestionType={selectedQuestionType}
            associatedPersons={associatedPersons}
            register={register}
            control={control}
            Controller={Controller}
            watch={watch}
            setValue={setValue}
          />
        )}
      </Stack>
    </Flex>
  );
}
