import { useQuery, QueryResult } from 'react-query';
import { contactItem as getContactItem } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { Contact } from 'types/contacts';

async function fetchContact({ id, account }: TVariables): Promise<TResult> {
  const response = await fetch(getContactItem(account, id), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = Contact | undefined;

export type TError = { message: string };
export type TVariables = {
  account: number;
  id: number;
};

function useGetContactInfo({
  account,
  id,
}: {
  account: number;
  id?: number;
}): QueryResult<TResult, TError> {
  return useQuery(
    ['get_contact_info', account, id],
    async () => {
      if (id)
        return await fetchContact({
          id,
          account,
        });
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetContactInfo };
