import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { StyledCheckboxWrapper } from '../checkbox';

export const StyledRadioWrapper = styled(StyledCheckboxWrapper)`
  --radio-marker-size: 8px;

  &.radio-draggable-qn {
    display: inline-flex;
    position: relative;
    top: 2px;
  }

  input[type='radio'] {
    &:checked + label::before {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg class='nc-pr-iconper' fill='%23ffffff'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23ffffff'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
      background-size: var(--radio-marker-size);
    }

    & + label::before {
      border-radius: 50%;
    }
  }
`;

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

export type RadioButtonProps = {
  label?: string | any;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  name?: string;
  inline?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  register?: () => RefReturn;
  customId?: string;
  className?: string;
};
// { required }: { required?: boolean }

function RadioButton({
  label,
  value,
  checked,
  disabled,
  name,
  inline,
  onChange,
  register,
  customId,
  className,
}: RadioButtonProps): ReactElement {
  const id = customId || `cb-${label.replace(/ /g, '').toLowerCase()}${name}`;
  return (
    <StyledRadioWrapper inline={inline} className={className}>
      <input
        type="radio"
        id={id}
        // value={value}
        // defaultChecked={checked}
        checked={checked}
        disabled={disabled}
        name={name}
        onClick={onChange}
        // onChange={onChange}
        // ref={register}
      />

      <label htmlFor={id}>{label}</label>
    </StyledRadioWrapper>
  );
}

export { RadioButton };
