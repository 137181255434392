import { MutationResultPair, useMutation } from 'react-query';
import { saveAsPdf } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchHtmlContent({
  payload,
  accountId,
}: TVariables): Promise<APIResult> {
  const response = await fetch(saveAsPdf(accountId, payload), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.text();
}

export type APIResult = any;

export type TError = { message: string };
export type TVariables = {
  accountId: number;
  payload: string;
};

export type TSnapshot = unknown;

function useSaveAsWordHtmlContent(): MutationResultPair<
  void,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async ({ accountId, payload }: TVariables): Promise<void> => {
      const fetchResponse = await fetchHtmlContent({
        accountId,
        payload,
      });
      // return fetchResponse;
      if (fetchResponse) {
        // generatePdf({ htmlContent: fetchResponse });
        return fetchResponse;
      }
      return;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveAsWordHtmlContent };
