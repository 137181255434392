import { MutationResultPair, useMutation } from 'react-query';
import { saveAsPdf } from '../utils/urls';
import { getTokenWithType } from './useAuth';

function generatePdf({
  htmlContent,
  pListName,
}: {
  htmlContent: string;
  pListName: string;
}) {
  // const printWindow = window.open('height=800,width=1090', '_blank');
  const parser = new DOMParser();
  const document = parser.parseFromString(htmlContent, 'text/html');
  const docHead = document.querySelector('head');
  const title = document.createElement('title');
  const metaTag = document.createElement('meta');
  metaTag.setAttribute('charset', 'UTF-8');
  title.textContent = pListName;
  docHead?.appendChild(metaTag);
  docHead?.appendChild(title);
  const content = new XMLSerializer().serializeToString(document);
  const blob = new Blob([content], { type: 'text/html' });
  const url = URL.createObjectURL(blob);
  const newWindow = window.open(url, '_blank');

  setTimeout(() => {
    newWindow?.print();
  }, 100);
}

async function fetchHtmlContent({
  payload,
  accountId,
}: TVariables): Promise<APIResult> {
  const response = await fetch(saveAsPdf(accountId, payload), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.text();
}

export type APIResult = any;

export type TError = { message: string };
export type TVariables = {
  accountId: number;
  payload: string;
  pListName?: string;
};

export type TSnapshot = unknown;

function useSaveAsPDFHtmlContent(): MutationResultPair<
  void,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async ({ accountId, payload, pListName }: TVariables): Promise<void> => {
      const fetchResponse = await fetchHtmlContent({
        accountId,
        payload,
        pListName,
      });
      if (fetchResponse && pListName) {
        generatePdf({ htmlContent: fetchResponse, pListName });
      }
      return;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveAsPDFHtmlContent };
