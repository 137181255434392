import React, { ReactElement, useState, useEffect } from 'react';
import { Box, Text, Flex } from 'rebass/styled-components';
import { FormInput } from 'ui/patterns';
import {
  Button,
  Stack,
  Checkbox,
  RadioButton,
  DataTable,
  DataTableWrapper,
} from 'ui/components';
import * as Icon from 'assets/icons';

type QuestionTemplateItemType = {
  Id: number;
  IncludeByDefault: boolean;
  IsDefault: boolean;
  ItemLabel: string;
  ItemName: string;
  Order: number;
  QuestionTemplateId: number;
};

type ChoiceQuestionPropsType = {
  questionTemplateOptions: Array<QuestionTemplateItemType>;
  setQuestionTemplateOptions: (
    value:
      | Array<QuestionTemplateItemType>
      | ((
          prevState: Array<QuestionTemplateItemType>,
        ) => Array<QuestionTemplateItemType>),
  ) => void;
  Controller: any;
  control: any;
  type: number;
  mode: string;
};

export default function ChoiceQuestion(
  ChoiceQuestionProps: ChoiceQuestionPropsType,
): ReactElement {
  const {
    questionTemplateOptions,
    setQuestionTemplateOptions,
    Controller,
    control,
    type,
    mode,
  } = ChoiceQuestionProps;

  const [superAdminPermission, setSuperAdminPermission] =
    useState<boolean>(false);

  useEffect(() => {
    const permissions = localStorage.getItem('userPermissions');
    if (permissions) {
      const temp = JSON.parse(permissions);
      setSuperAdminPermission(temp.isSuperAdmin);
    }
  }, []);

  const handleAddChoiceOption = () => {
    const defaultChoiceOption = {
      Id: new Date().getTime(),
      IncludeByDefault: true,
      IsDefault: false,
      ItemLabel: '',
      ItemName: '',
      Order: 0,
      QuestionTemplateId: 0,
    };
    const templateOptions = [...questionTemplateOptions];
    templateOptions.push(defaultChoiceOption);
    setQuestionTemplateOptions(templateOptions);
  };

  function removeQuestionOption(templateItem: QuestionTemplateItemType) {
    const index = questionTemplateOptions?.findIndex(
      item => item.Id === templateItem.Id,
    );
    if (index !== -1) {
      setQuestionTemplateOptions([
        ...questionTemplateOptions.slice(0, index),
        ...questionTemplateOptions.slice(index + 1),
      ]);
    }
  }

  const handleIsDefaultChange = (templateItem: QuestionTemplateItemType) => {
    const tempOption =
      questionTemplateOptions &&
      questionTemplateOptions.map((item: QuestionTemplateItemType) => {
        if (item.Id === templateItem.Id) {
          return {
            ...item,
            IsDefault: true,
          };
        } else {
          return {
            ...item,
            IsDefault: false,
          };
        }
      });
    if (tempOption) setQuestionTemplateOptions([...tempOption]);
  };

  const handleIncludeByDefaultChange = (
    templateItem: QuestionTemplateItemType,
  ) => {
    const index =
      questionTemplateOptions &&
      questionTemplateOptions.findIndex(
        (item: QuestionTemplateItemType) => item.Id === templateItem.Id,
      );

    const tempOption = [...questionTemplateOptions];
    tempOption[index] = {
      ...tempOption[index],
      IncludeByDefault: !tempOption[index].IncludeByDefault,
    };
    setQuestionTemplateOptions([...tempOption]);
  };

  const handleChangeInput = (
    name: string,
    value: string,
    templateItem: QuestionTemplateItemType,
  ) => {
    const index =
      questionTemplateOptions &&
      questionTemplateOptions.findIndex(
        (item: QuestionTemplateItemType) => item.Id === templateItem.Id,
      );

    const tempOption = [...questionTemplateOptions];
    tempOption[index] = {
      ...tempOption[index],
      [name]: value,
    };
    setQuestionTemplateOptions([...tempOption]);
  };

  const isNoDefaultChecked = (): boolean => {
    const isDefaultCheckedArray =
      questionTemplateOptions &&
      questionTemplateOptions.map((templateItem: QuestionTemplateItemType) => {
        if (templateItem.IsDefault === true) return true;
        else return false;
      });

    const isDefaultChecked = isDefaultCheckedArray.includes(true)
      ? false
      : true;

    return isDefaultChecked;
  };

  const handleChangeNoDefault = () => {
    const tempOption =
      questionTemplateOptions &&
      questionTemplateOptions.map((item: QuestionTemplateItemType) => {
        return {
          ...item,
          IsDefault: false,
        };
      });
    if (tempOption) setQuestionTemplateOptions([...tempOption]);
  };

  return (
    <Box as="form" height="100%" mb="50px">
      <Stack direction="y" gap={3} mt={6}>
        <Stack direction="x" gap={5}>
          <Controller
            render={(props: {
              onChange: (value: number) => void;
              value: number;
            }) => {
              return (
                <RadioButton
                  onChange={() => {
                    props.onChange(0);
                  }}
                  inline
                  label="Radio Buttons"
                  name="Type"
                  checked={type === 0}
                />
              );
            }}
            defaultValue={1}
            name="Type"
            control={control}
          />
          <Controller
            render={(props: {
              onChange: (value: number) => void;
              value: number;
            }) => {
              return (
                <RadioButton
                  onChange={() => {
                    props.onChange(1);
                  }}
                  inline
                  label="Checkboxes"
                  name="Type"
                  checked={type === 1}
                />
              );
            }}
            defaultValue={1}
            name="Type"
            control={control}
          />
          <Controller
            render={(props: {
              onChange: (value: number) => void;
              value: number;
            }) => {
              return (
                <RadioButton
                  onChange={() => {
                    props.onChange(2);
                  }}
                  inline
                  label="Dropdown List"
                  name="Type"
                  checked={type === 2}
                />
              );
            }}
            defaultValue={1}
            name="Type"
            control={control}
          />
        </Stack>
        <Text fontWeight="bold" mb="-8px">
          List of Options
        </Text>
        <DataTableWrapper>
          <DataTable>
            <thead>
              <tr>
                <th>Is Default?</th>
                <th>Include</th>
                <th>Order</th>
                <th>Item Name</th>
                <th>Original Item Label</th>
                <th>Remove?</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={6}>
                  <RadioButton
                    label="No Default"
                    name="IsDefault"
                    checked={isNoDefaultChecked()}
                    onChange={handleChangeNoDefault}
                  />
                </td>
              </tr>
              {questionTemplateOptions?.length > 0 &&
                questionTemplateOptions.map((templateItem, templateIndex) => (
                  <tr key={templateIndex}>
                    <td style={{ verticalAlign: 'middle' }}>
                      <RadioButton
                        label=""
                        name="IsDefault"
                        checked={templateItem.IsDefault}
                        onChange={() => {
                          handleIsDefaultChange(templateItem);
                        }}
                        customId={`${templateItem.Id}`}
                      />
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <Checkbox
                        label=""
                        position="relative"
                        top="-3px"
                        inline
                        name={`IncludeByDefault${templateIndex}`}
                        checked={templateItem.IncludeByDefault}
                        handleChange={() =>
                          handleIncludeByDefaultChange(templateItem)
                        }
                      />
                    </td>
                    <td>
                      <FormInput
                        label=""
                        name={`Order${templateIndex}`}
                        value={templateItem.Order}
                        onTextChange={(text: string) => {
                          handleChangeInput('Order', text, templateItem);
                        }}
                      />
                    </td>
                    <td>
                      <FormInput
                        type="text"
                        label=""
                        name={`ItemLabel${templateIndex}`}
                        value={templateItem.ItemLabel}
                        onTextChange={(text: string) => {
                          handleChangeInput('ItemLabel', text, templateItem);
                        }}
                        disabled={
                          mode === 'Edit' &&
                          templateItem.QuestionTemplateId !== 0
                            ? superAdminPermission
                              ? false
                              : true
                            : false
                        }
                      />
                    </td>
                    <td>
                      <FormInput
                        label=""
                        name={`ItemName${templateIndex}`}
                        value={templateItem.ItemName}
                        onTextChange={(text: string) => {
                          handleChangeInput('ItemName', text, templateItem);
                        }}
                      />
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <Button
                        variant="danger"
                        size="sm"
                        iconAfter={<Icon.Delete />}
                        onClick={() => removeQuestionOption(templateItem)}
                        title="Remove"
                        disabled={
                          mode === 'Edit' &&
                          templateItem.QuestionTemplateId !== 0
                            ? superAdminPermission
                              ? false
                              : true
                            : false
                        }
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </DataTable>
        </DataTableWrapper>
        <Flex>
          <Button
            variant="primary"
            iconBefore={<Icon.Add />}
            onClick={handleAddChoiceOption}
          >
            Add Option
          </Button>
        </Flex>
      </Stack>
    </Box>
  );
}
