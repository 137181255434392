import React, { ReactNode, ReactElement } from 'react';
import { Flex } from 'rebass/styled-components';

type PageContentProps = {
  children: ReactNode;
};

const PageContent = ({ children }: PageContentProps): ReactElement => {
  return (
    <Flex flexDirection="column" flex="1 1 auto" p={4}>
      {children}
    </Flex>
  );
};

export { PageContent };
