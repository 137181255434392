import React, { useContext, ReactElement } from 'react';
import { Heading } from 'rebass/styled-components';
import { ThemeContext } from 'styled-components';

type PageTitleProps = {
  title: string;
};

function PageTitle({ title }: PageTitleProps): ReactElement {
  const theme = useContext(ThemeContext);
  return (
    <Heading
      fontSize={2}
      fontWeight="regular"
      color={theme.colors.white}
      sx={{
        display: 'flex',
        alignItems: 'center',
        textTransform: 'capitalize',
        lineHeight: 1,
        flexShrink: 0,
      }}
    >
      {title}
    </Heading>
  );
}

export { PageTitle };
