import React, { ReactElement } from 'react';
import { Box } from 'rebass';
import { Checkbox, Cluster } from 'ui/components';
import { SectionOptionsType } from 'types/accountsSearch';

export default function SectionSearchOptions({
  sectionOptions,
  toggleSectionOption,
}: {
  sectionOptions: SectionOptionsType;
  toggleSectionOption: any;
}): ReactElement {
  return (
    <Cluster gap={3} flex="1" p={2}>
      <Box>
        <Checkbox
          name="IncludeGlobalSections"
          label="Include Global Sections"
          checked={sectionOptions.includeGlobal}
          handleChange={toggleSectionOption('includeGlobal')}
        />
        <Checkbox
          name="IncludeInactive."
          label="Include Inactive"
          checked={sectionOptions.includeInactive}
          handleChange={toggleSectionOption('includeInactive')}
        />
        <Checkbox
          name="IncludeHidden."
          label="Include Hidden Accounts"
          checked={sectionOptions.includeHidden}
          handleChange={toggleSectionOption('includeHidden')}
        />
      </Box>
    </Cluster>
  );
}
