import React, { ReactElement } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { Callback } from './authRoutes/callback';
import { Logout } from './authRoutes/logout';
import { SilentRenew } from './authRoutes/silentRenew';
import Home from 'containers/Home';
import { AuthServiceSingleton } from 'utils/authenticationHelper/authServices';
import type AuthService from 'utils/authenticationHelper/authServices';
import {
  AUTH_EXPIRY_VISIBLE,
  LOGIN_TYPE,
  LOGIN_TYPES,
} from 'utils/authenticationHelper/authConstant';
import { retrieveAndParseToken, useAuth, AuthInfo } from 'hooks/useAuth';
import { useRenewTimer } from 'utils/authenticationHelper/renewTimer';
import { Loader } from 'ui/patterns';
import { useGetUserPermissions } from 'hooks';
import { Header, Footer, MainContent } from 'ui/patterns';
import HomeRouter from 'router/homeRoute';
import { getVersionIframe } from 'utils/urls';
import { otherTabsAvailable } from 'utils/otherTabsAvailability';

export function PrivatePage() {
  useRenewTimer();
  const [mounted, setMounted] = React.useState<boolean>(false);
  const [authCompleted, setAuthCompleted] = React.useState<boolean>(false);
  // const { token } = useAuth();
  const history = useHistory();
  const authServiceRef = React.useRef<AuthService>();
  const { userInfo }: any = useAuth();

  const { data, isLoading } = useGetUserPermissions();

  const userPermissionSet = React.useMemo(() => {
    if (data?.length) {
      const temp = [...data];
      const hasQuestionRepoPermission = temp.includes(
        'CanEditQuestionRepository',
      );
      const hasAccountBuilderPermission = temp.includes(
        'CanAccessAccountBuilder',
      );
      const hasEditFormPermission = temp.includes('CanEditForms');
      const hasAccountModifyPermission = temp.includes('CanModifyAllAccounts');
      const hasPurgePermissions = temp.includes('CanPurgeAccount');
      const isSuperAdmin = temp.includes('SuperAdministrators');
      const isTelephonyAdmin = temp.includes('CanModifyTelephonySettings');
      const userPermissions = {
        CanEditQuestionRepository: hasQuestionRepoPermission,
        CanAccessAccountBuilder: hasAccountBuilderPermission,
        CanEditForms: hasEditFormPermission,
        CanModifyAllAccounts: hasAccountModifyPermission,
        canPurgeAccount: hasPurgePermissions,
        isSuperAdmin: isSuperAdmin,
        isTelephonyAdmin: isTelephonyAdmin,
      };
      localStorage.setItem('userPermissions', JSON.stringify(userPermissions));
      return { ...userPermissions };
    }
  }, [data]);

  React.useEffect(() => {
    const authCondition = async () => {
      if (mounted) {
        const auth = retrieveAndParseToken(false);
        const windowsAvailable = await otherTabsAvailable();
        authServiceRef.current = AuthServiceSingleton.getInstance();
        const loginType = localStorage.getItem(LOGIN_TYPE);
        localStorage.removeItem(LOGIN_TYPE);
        sessionStorage.removeItem(AUTH_EXPIRY_VISIBLE);
        if (auth?.access_token && (windowsAvailable || loginType)) {
          setAuthCompleted(true);

          // history.push(history.location.pathname);
        } else {
          localStorage.setItem(LOGIN_TYPE, LOGIN_TYPES.FIRST_LOGIN);
          authServiceRef.current.signIn();
        }
      }
    };
    authCondition();
  }, [mounted, history]);
  React.useEffect(() => {
    setTimeout(() => setMounted(true), 1500);
  }, []);
  if (!authCompleted) return <Loader />;

  const userName = userInfo && `${userInfo.given_name} ${userInfo.family_name}`;
  const displayName =
    userInfo &&
    userInfo['http://schemas.microsoft.com/identity/claims/displayname'];

  return (
    <>
      {/* <Header name={loginName} /> */}
      <Header username={displayName} permissions={userPermissionSet} />
      {userPermissionSet !== undefined ? (
        <>
          <iframe src={getVersionIframe} style={{ display: 'none' }} />
          <HomeRouter />
        </>
      ) : (
        <MainContent></MainContent>
      )}
      {/* <AccountCreationRoute /> */}
      <Footer copyright="ProtoCall Services &copy; 2020" />
      {isLoading && <Loader />}
    </>
  );
}

const RefreshLanding = () => {
  const history = useHistory();
  const authServiceRef = React.useRef<AuthService>();
  React.useEffect(() => {
    authServiceRef.current = AuthServiceSingleton.getInstance();
    localStorage.setItem(LOGIN_TYPE, LOGIN_TYPES.REFRESH_LOGIN);
    authServiceRef.current?.signIn();
  }, [history]);

  return <Loader />;
};

const AppRouter = (): ReactElement => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact={true}
          path="/authentication/callback"
          component={Callback}
        />
        <Route exact={true} path="/logout" component={Logout} />
        <Route
          exact={true}
          path="/authentication/silent_callback"
          component={SilentRenew}
        />
        <Route path="/refreshToken" component={RefreshLanding} />
        <Route path="/" component={PrivatePage} />

        {/* <Route exact path="/">
          <Login />
        </Route>
        <Route path="/home">
          <OidcSecure>
            <Home />
          </OidcSecure>
        </Route> */}
      </Switch>
    </BrowserRouter>
  );
};
export default AppRouter;
