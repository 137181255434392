import React, { ReactElement } from 'react';
import styled from 'styled-components';

type FooterProps = {
  copyright: string;
};

const StyledFooter = styled.footer`
  --border-color: var(--color-neutral-contrast-03);
  flex-shrink: 0;
  text-align: center;
  border-top: solid 1px var(--border-color);

  html[data-color-mode='dark'] & {
    --border-color: var(--color-neutral-contrast-01);
  }

  small {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.text.body};
  }
`;

function Footer({ copyright }: FooterProps): ReactElement {
  return (
    <StyledFooter>
      <small>{copyright}</small>
    </StyledFooter>
  );
}

export { Footer };
