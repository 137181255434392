import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { saveProcedure } from '../utils/urls';
import { getTokenWithType } from './useAuth';

export async function postProcedure({
  accountId,
  params,
}: TArguments): Promise<TResult> {
  const response = await fetch(saveProcedure(accountId), {
    method: 'PUT',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  return response.json();
}

type ParamsType = {
  AccountProcedure: {
    Procedure: {
      FileId: string;
    };
    ProcedureId: number;
  };
  Content: string;
};
export type ValidationError = { Message?: string };
export type TResult = string & ValidationError;

export type TError = { Message: string };
export type TArguments = {
  accountId: number;
  params: ParamsType;
};
export type TVariables = {
  accountId: number;
  params: ParamsType;
  refetchList?: boolean;
  isAutoSave?: boolean;
};
export type TSnapshot = unknown;

function useSaveProcedure(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async ({
      accountId,
      params,
      refetchList = true,
      isAutoSave = false,
    }: TVariables) => {
      const postResult = await postProcedure({
        accountId,
        params: params,
      });
      if (postResult.Message) throw postResult;
      if (postResult) {
        //for broken link count updation
        queryCache.invalidateQueries(['get_broken_links', accountId], {
          refetchInactive: true,
        });
        //dismissal tab update
        queryCache.invalidateQueries(
          ['getBrokenLinksForDismissal', accountId, true, null],
          {
            refetchInactive: true,
          },
        );
      }
      return postResult;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveProcedure };
