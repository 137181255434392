import { AllHTMLAttributes } from 'react';
import styled from 'styled-components';
import {
  space,
  SpaceProps,
  flex,
  FlexProps,
  layout,
  LayoutProps,
  color,
  ColorProps,
} from 'styled-system';
import { getHSL } from 'utils/getHSL';

type NativeProps = AllHTMLAttributes<HTMLElement>;

const PhoneNumberChangeAlertWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 2;
  width: 320px;
  transform: translateY(calc(-100% - 4px));
`;

type StyledAlertProps = SpaceProps &
  LayoutProps &
  FlexProps &
  ColorProps & {
    style?: NativeProps['style'];
  };

const PhoneNumberChangeAlert = styled.div<StyledAlertProps>`
  ${space}
  ${layout}
  ${color}
  ${flex}

  --h: 204deg;
  --s: 94%;
  --l: 85%;
  --a: 100%;
  --color-alpha: 50%;
  --border-color: hsla(var(--h), var(--s), 70%, 100%);

  position: relative;
  display: flex;
  gap: ${({ theme }) => theme.space[2]};
  align-items: flex-start;
  border: solid 1px var(--border-color);
  border-radius: ${({ theme }) => theme.radii.soft};
  padding: ${({ theme }) => theme.space[3]};
  background: hsla(var(--h), var(--s), calc(var(--l) + 5%), var(--a));
  color: hsl(var(--h), var(--s), calc(var(--l) - var(--color-alpha)));
  text-wrap: balance;

  html[data-color-mode='dark'] & {
    --l: 10%;
    --a: 100%;
    --color-alpha: 5%;
    --border-color: hsla(var(--h), var(--s), 30%, 100%);
    color: hsl(var(--h), var(--s), 90%);
  }

  & > .pr-icon {
    margin-top: 0.15em;
    align-self: flex-start;
  }

  & > .pr-icon svg {
    fill: hsla(var(--h), var(--s), calc(var(--l) - 5%), 80%);
    margin-right: ${({ theme }) => theme.space[2]};
  }

  & .pr-alert-close {
    background: none;
    align-self: flex-start;
    fill: hsla(var(--h), var(--s), calc(var(--l) - 5%), 60%);
    margin-left: ${({ theme }) => theme.space[2]};
    transition: opacity 200ms ease-in-out;
    opacity: 0.5;
    margin-inline: 0px -8px;
    margin-block-start: -8px;

    &:hover {
      opacity: 0.85;
    }
  }

  &::before {
    --arrow-size: 8px;
    content: '';
    display: block;
    position: absolute;
    bottom: calc(var(--arrow-size) * -1);
    left: calc(var(--arrow-size) * 2);
    width: 0;
    height: 0;
    border-left: var(--arrow-size) solid transparent;
    border-right: var(--arrow-size) solid transparent;
    border-top: var(--arrow-size) solid var(--border-color);
  }

  .PhoneNumberChangeAlertWrapper--firstchild &::before {
    top: calc(calc(var(--arrow-size) * -2) + 1px);
    bottom: auto;
    border-top: var(--arrow-size) solid transparent;
    border-bottom: var(--arrow-size) solid var(--border-color);
  }
`;

export { PhoneNumberChangeAlertWrapper, PhoneNumberChangeAlert };
