import { useQuery, QueryResult } from 'react-query';
import { releaseNotes } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchReleaseNotes(): Promise<APIResult> {
  const response = await fetch(releaseNotes, {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type ReleaseNoteResult = {
  Version: string;
  ReleasedOn: string;
  ReleaseNotes: Array<string>;
};
export type APIResult = Array<ReleaseNoteResult>;

export type TError = { message: string };

function useGetReleaseNotes(): QueryResult<APIResult, TError> {
  return useQuery(
    'get_release_notes',
    async () => {
      const fetchResponse = await fetchReleaseNotes();
      return fetchResponse;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetReleaseNotes };
