// import { useHistory } from 'react-router-dom';
import { useMutation, MutationResultPair } from 'react-query';
// import { useDispatch } from 'store/authContext';
// import { AccountFormInputs } from 'types/accounts';
import { uploadExternalDocument } from 'utils/urls';
import { getTokenWithType } from './useAuth';

// export async function postAccountInfo({
//   token_type,
//   token,
//   account,
//   file,
// }: {
//   account: string;
//   file: File;
//   token_type: string;
//   token: string;
// }): Promise<TResult> {
//   const formData: FormData = new FormData();
//   formData.append('file', file);
//   //   const response =
//   await fetch(uploadExternalDocument(account, file.name), {
//     method: 'PUT',
//     headers: {
//       Authorization: `${token_type} ${token}`,
//       'content-type': 'multipart/form-data',
//     },
//     body: formData,
//   });
//   return `AccountFolders/${account}/Documents/${file.name}`;
// }

export type TResult = string;
export type TVariables = {
  account: string;
  file: File;
};
export type TError = {
  message: string;
};
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;

function useUploadExternalDocument(
  setProgress?: (progress: number) => void,
): MutationResultPair<TResult, TError, TVariables, TSnapshot> {
  // const [progress, setProgress] = useState(0);

  function updateProgress(evt: any) {
    console.log('evt,evt', evt);
    if (evt.lengthComputable) {
      // evt.loaded the bytes the browser received
      // evt.total the total bytes set by the header
      // jQuery UI progress bar to show the progress on screen
      const percentComplete = (evt.loaded / evt.total) * 100;
      setProgress && setProgress(percentComplete);
    }
  }

  return useMutation(
    async ({ account, file }: TVariables) => {
      // await postAccountInfo({
      //   ...params,
      //   token_type,
      //   token,
      // });
      try {
        await new Promise((res, rej) => {
          const formData: FormData = new FormData();
          formData.append('file', file);
          const xhr = new XMLHttpRequest();
          xhr.open('PUT', uploadExternalDocument(account, file.name));
          // for (var k in opts.headers || {})
          xhr.setRequestHeader('Authorization', getTokenWithType());
          // xhr.setRequestHeader('content-type', 'multipart/form-data');
          xhr.onreadystatechange = function (aEvt: any) {
            if (
              aEvt.currentTarget.status >= 200 &&
              aEvt.currentTarget.status <= 210
            ) {
              res(aEvt.currentTarget.response);
            } else {
              rej();
            }
          };
          // req.send();
          xhr.onprogress = updateProgress;
          xhr.upload.addEventListener('progress', updateProgress);
          // xhr.onload = e => e.target.responseText;
          xhr.onerror = e => rej(e);
          // if (xhr.upload) xhr.upload.onprogress = setProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
          xhr.send(formData);
        });
      } catch (e) {
        console.log('EEEEEE', e);
        throw e;
      }
      return `AccountFolders/${account}/Documents/${file.name}`;
    },
    {
      throwOnError: true,
    },
  );
}

export { useUploadExternalDocument };
